(function () {
  'use strict';

  angular.module('imat.viewer')
    .controller('ViewerCtrl', ViewerCtrl);

  ViewerCtrl.$inject = [
    '$mdDialog', '$q', '$sce', '$scope', '$transition$', '$timeout',
    'dateTimeSrv', 'psNotification', 'psReports', 'searchSrv'
  ];

  function ViewerCtrl (
    $mdDialog, $q, $sce, $scope, $transition$, $timeout,
    dateTimeSrv, psNotification, psReports, searchSrv
  ) {
    var vm = this;
    var ARTIFACT_DOCS = 'documentation.html';
    // var ARTIFACT_ERRORS = 'stderr.log';
    var ARTIFACT_OUTPUT = 'stdout.log';
    var ARTIFACT_RESULTS_TEXT = 'result.log';
    var ARTIFACT_RESULTS_JSON = 'results.json';
    var INPUT_TYPES = psReports.INPUT_TYPES;

    var artifactNames = [];
    var artifacts = [];
    var order = 4;

    // Properties
    vm.loaded = false;
    vm.loading = true;
    vm.report = {};
    vm.reportId = $transition$.params().reportId || '';
    vm.results = [{
      data: '<div></div>',
      file: '',
      name: 'Output',
      order: 0,
      type: 'html'
    }];
    vm.workspace = 'report-viewer';
    // Methods
    vm.cancelReport = cancelReport;
    vm.showParameters = showParameters;

    activate();

    function activate () {
      if (!vm.reportId) {
        vm.loaded = true;
        vm.loading = false;
        psNotification.error('A report ID is required.');
        return;
      }

      psReports.load(vm.reportId, vm.workspace)
        .then(function (report) {
          vm.report = report;
          if (vm.report.results[vm.workspace]) {
            Object.keys(vm.report.results[vm.workspace].parameters).forEach(function (param) {
              vm.report.results[vm.workspace].parameters[param].value = null;
            });
          }
          return report.getMeta();
        })
        .then(function (res) {
          return showParameters();
        })
        .catch(function () {
          psNotification.error('Unable to load the report.');
        })
        .finally(function () {
          vm.loaded = true;
          vm.loading = false;
        });
    }

    function cancelReport () {
      psReports.reset(vm.workspace, true)
        .then(function () {
          vm.report.cancel(vm.workspace);
        })
        .finally(function () {
          vm.loading = false;
        });
    }

    function loadArtifactNames (resultId) {
      var blacklist = [
        'summary.json',
        'scriptexit.json',
        'script.py',
        'Usage-Logs',
        'stderr.log'
      ];

      return searchSrv.getResultOutput(resultId)
        .then(function (artifacts) {
          artifactNames = artifacts.filter(function (artifact) {
            return blacklist.indexOf(artifact) === -1;
          });
        });
    }

    function loadArtifacts () {
      var artifactPromises = [];
      var blacklist = [
        'csv',
        'jpg',
        'png',
        'jpeg',
        'gif',
        'json'
      ];
      artifacts = [];
      vm.results = [];

      artifactNames.forEach(function (fileName) {
        var parts = fileName.toLowerCase().split('.');
        var extension = parts[parts.length - 1];
        if (fileName !== ARTIFACT_RESULTS_JSON && blacklist.indexOf(extension) >= 0) {
          return; // continue
        }
        artifactPromises.push(
          vm.report.getArtifact(vm.workspace, fileName, true)
            .then(function (data) {
              return $q.resolve(processFile(fileName, parts[0], extension, data));
            })
        );
      });

      return $q.all(artifactPromises)
        .finally(function () {
          vm.results = artifacts;
          if (!artifacts.length) {
            vm.results = [{
              data: '<div>No files found.</div>',
              file: '',
              name: 'Output',
              order: 0,
              type: 'html'
            }];
          }
        });
    }

    function processFile (file, fileName, fileExtension, fileData) { // eslint-disable-line complexity
      var artifact = {
        data: null,
        file: file,
        name: fileName.replace(/[_-]+/g, ' '),
        order: 0,
        type: 'string'
      };

      switch (file) {
        case ARTIFACT_DOCS:
          artifact.name = 'Documentation';
          artifact.order = 0;
          break;
        case ARTIFACT_OUTPUT:
          artifact.name = 'Output';
          artifact.order = 1;
          break;
        case ARTIFACT_RESULTS_TEXT: // No break
        case ARTIFACT_RESULTS_JSON:
          artifact.name = 'Results';
          artifact.order = 2;
          break;
          // Currently disabled from view
          // case ARTIFACT_ERRORS:
          //   artifact.name = 'Errors';
          //   artifact.order = 3;
          //   break;
        default:
          // Shorten the name because tab realestate
          if (artifact.name.length > 20) {
            artifact.name = artifact.name.substring(0, 20) + '...';
          }
          artifact.order = order++;
      }

      // Data Cleaning
      if (fileData === '') {
        fileData = 'File is empty.';
      }
      if (typeof fileData === 'string' && fileData.indexOf('Exception: must be') >= 0) {
        fileData = 'One or more parameters was given an invalid value.';
      }

      if (fileExtension === 'html' || (file === ARTIFACT_OUTPUT && /<\/?[^>]+>/.test(fileData))) {
        artifact.data = $sce.trustAsHtml('<pre>' + fileData + '</pre>');
        artifact.type = 'html';
      } else if (file === ARTIFACT_OUTPUT && typeof fileData === 'object') {
        artifact.data = '<pre>' + JSON.stringify(fileData, null, 2) + '</pre>';
      } else if (file === ARTIFACT_RESULTS_TEXT && fileData.response && fileData.response.hits && fileData.response.hits.hit) {
        formatResultData(artifact, fileData.response.hits.hit);
      } else {
        artifact.data = fileData;
      }

      // If it's an array, check if the first element's keys are found in the rest of the elements
      // If so, it's a hitlist
      // If not, check to see if it's an array of tables

      if (Array.isArray(artifact.data)) {
        if (file === ARTIFACT_RESULTS_JSON || isTable(artifact.data)) {
          artifact.type = 'hitlist';
          artifact.headers = Object.keys(artifact.data[0]);
        } else {
          artifact.type = 'html';
          artifact.data = '<pre>' + JSON.stringify(artifact.data, null, 2) + '</pre>';
        }
      }

      artifacts.push(artifact);
    }

    function formatResultData (artifact, hits) {
      var dataArr = [];
      hits.forEach(function (hit) {
        dataArr.push(hit.extract);
      });
      artifact.data = dataArr;
    }

    function isTable (data) {
      if (data.length <= 1) {
        return false;
      }
      var keys = Object.keys(data[0]);
      var missingKeys = data.filter(function (item, idx) {
        if (idx === 0) {
          return false;
        }
        var missingKey = false;
        keys.forEach(function (key) {
          if (!Object.prototype.hasOwnProperty.call(item, key)) {
            missingKey = true;
          }
        });
        return missingKey;
      });
      return (missingKeys.length === 0);
    }

    function showParameters (ev) {
      $mdDialog.show({
        controller: 'ViewerParametersModalCtrl',
        templateUrl: '/ui/viewer/modals/parameters.html',
        locals: { report: vm.report, reportName: vm.report.name },
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: true
      }).then(function (params) {
        angular.forEach(params, function (param, idx) {
          if (param.inputType === INPUT_TYPES.DATE) {
            params[idx].value = dateTimeSrv.getIsoDate(param.value);
          }
        });

        vm.loading = true;
        return vm.report.run(vm.workspace, params, true)
          .then(function () {
            return vm.report.results[vm.workspace].getPollPromise();
          })
          .catch(function () {
            if (vm.report.results[vm.workspace].status === vm.report.STATUS.CANCELED) {
              psNotification.error('Report canceled.');
            } else {
              psNotification.error('Report failed to run.');
            }
            return $q.reject();
          })
          .then(function () {
            return loadArtifactNames(vm.report.results[vm.workspace].resultId)
              .then(loadArtifacts)
              .catch(function () {
                psNotification.error('Failed to load artifacts.');
                return $q.reject();
              });
          }).finally(function () {
            $timeout(function () {
              vm.loading = false;
            });
          });
      }).catch(function () {
        // Dialog Closed
      });
    }
  }
})();
