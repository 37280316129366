(function () {
  'use strict';

  angular
    .module('imat.services')
    .factory('psNotification', psNotification);

  psNotification.$inject = ['$mdToast', '$mdDialog', '$q'];

  function psNotification ($mdToast, $mdDialog, $q) {
    var defaultHideDelay = 5000;
    var toastQueue = [];
    var service = {
      customHide: customHide,
      customShow: customShow,
      customShowCloseBtn: customShowCloseBtn,
      error: error,
      interrupt: interrupt,
      setDefaultDelay: setDefaultDelay,
      show: show,
      success: success,
      warn: warn
    };

    return service;

    //= ================================
    // Public interface
    //= ================================

    // Force hide a toast notification that would otherwise require interaction (holdOpen=true).
    function customHide (arg) {
      if (toastQueue.length && !toastQueue[0].hideDelay) {
        return $mdToast.hide(arg);
      }
      return $q.resolve((arguments.length ? arg : true));
    }

    // A toast notification that disappears without user interaction.
    // See $mdToast API Documentation at https://material.angularjs.org
    // Options include:
    // .hideDelay integer Milliseconds the toast should stay active before automatically closing.
    // .position string CSS positioning; any combination of 'bottom', 'left', 'top', 'right', 'end' and 'start'.
    // .message string The message to display inside the toast.
    // .type string ''|'error'|'success'|'warn'
    function customShow (options, holdOpen) {
      var i;

      // We ignore a toast request if it has the identical message as a previous request
      // (which is still in the queue because it has either not been shown or closed yet).
      for (i = toastQueue.length - 1; i >= 0; --i) {
        if (toastQueue[i].message === options.message) {
          return;
        }
      }

      setHideDelay(options, defaultHideDelay);
      setPosition(options, 'bottom right');

      if (holdOpen) {
        options.hideDelay = false;// Requires an action to dismiss.
        // TODO: what was the line below supposed to do?
        // options.actionKey
      }
      if (toastQueue.push(options) === 1) {
        processQueue();
      }
    }

    // TODO Replace the calls to this method (login component).
    function customShowCloseBtn (options) {
      customShow(options, true);
    }

    // Convenience to display an error type toast.
    function error (message, holdOpen) {
      customShow({ message: message, type: 'error' }, holdOpen);
    }

    // A dialog notification that requires user interaction.
    function interrupt (message, callback, btn) {
      callback = callback || angular.noop;
      $mdDialog.show($mdDialog.alert({
        title: 'Attention',
        textContent: message,
        ok: btn || 'Close'
      }))
        .finally(callback);
    }

    function setDefaultDelay (delay) {
      delay = parseInt(delay, 10);
      if (!isNaN(delay) && delay > 0) {
        defaultHideDelay = delay;
      }
    }

    // Convenience to display a toast.
    function show (message, holdOpen) {
      customShow({ message: message }, holdOpen);
    }

    // Convenience to display a success type toast.
    function success (message, holdOpen) {
      customShow({ message: message, type: 'success' }, holdOpen);
    }

    // Convenience to display a warn type toast.
    function warn (message, holdOpen) {
      customShow({ message: message, type: 'warn' }, holdOpen);
    }

    //= ================================
    // Private interface
    //= ================================

    function basicToast (options) {
      var toastClass;
      var toaster = {};

      switch (options.type) {
        case 'error':
          toastClass = 'notification-toast-error';
          break;
        case 'success':
          toastClass = 'notification-toast-success';
          break;
        case 'warn':
          toastClass = 'notification-toast-warn';
          break;
        default:
          toastClass = null;
      }

      toaster = $mdToast.simple()
        // .capsule(true)
        .hideDelay(options.hideDelay)
        .position(options.position)
        .textContent(options.message)
        .toastClass(toastClass);

      if (!options.hideDelay) {
        toaster.action('Close');
      }

      return toaster;
    }

    // We hold a queue of toasts and display each only when the previous one closes.
    // This is to prevent the toasts from stacking/overlapping and becoming unreadable.
    function processQueue () {
      // Keep the toast in the queue for de-duping until it closes.
      $mdToast.show(basicToast(toastQueue[0]))
        .finally(function () {
          toastQueue.shift();
          if (toastQueue.length) {
            processQueue();
          }
        });
    }

    function setHideDelay (options, delay) {
      options.hideDelay = parseInt(options.hideDelay, 10);
      if (isNaN(options.hideDelay) || options.hideDelay < 0) {
        options.hideDelay = delay;
      }
    }

    function setPosition (options, position) {
      if (angular.isUndefined(options.position)) {
        options.position = position;
      }
      if (!/^((bottom|top) (left|right|end|start)|(left|right|end|start) (bottom|top))$/.test(options.position)) {
        options.position = position;
      }
    }
  }
})();
