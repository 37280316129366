(function () {
  'use strict';

  angular.module('imat.services')
    .factory('ConfigurationSrv', ConfigurationSrv);

  ConfigurationSrv.$inject = ['$http', '$q', 'configurationApiSrv'];

  function ConfigurationSrv ($http, $q, configurationApiSrv) {
    var service = {
      getConfigFile: getConfigFile,
      getConfigFiles: getConfigFiles,
      saveConfigFile: saveConfigFile
    };

    return service;

    function getConfigFiles () {
      return configurationApiSrv.getConfigFiles();
    }

    function getConfigFile (fileName) {
      return configurationApiSrv.getConfigFile(fileName);
    }

    function saveConfigFile (fileName, fileContent) {
      var content = fileContent;

      if (typeof content === 'object') {
        content = JSON.stringify(content);
      }

      return configurationApiSrv.saveConfigFile(fileName, content);
    }
  }
})();
