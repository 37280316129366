(function () {
  'use strict';

  angular.module('admin')
    .controller('EditCollectionCriteriaModalCtrl', EditCollectionCriteriaModalCtrl);

  EditCollectionCriteriaModalCtrl.$inject = ['$mdDialog', '$scope', 'modalCollection'];
  function EditCollectionCriteriaModalCtrl ($mdDialog, $scope, modalCollection) {
    $scope.details = modalCollection;
    $scope.accessType = modalCollection.includeAll ? 'full' : 'query';

    // Methods
    $scope.cancel = cancel;
    $scope.updateCollectionCriteria = updateCollectionCriteria;

    function cancel () {
      $mdDialog.cancel();
    }

    function updateCollectionCriteria () {
      $scope.details.includeAll = $scope.accessType === 'full';
      $mdDialog.hide($scope.details);
    }

    //= ================================
    // Private interface
    //= ================================
  }
})();
