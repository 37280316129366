(function () {
  'use strict';

  angular.module('admin')
    .controller('EditGroupDescriptionModalCtrl', EditGroupDescriptionModalCtrl);

  EditGroupDescriptionModalCtrl.$inject = ['$mdDialog', '$scope', 'modalGroup'];
  function EditGroupDescriptionModalCtrl ($mdDialog, $scope, modalGroup) {
    // Properties
    $scope.details = modalGroup;
    $scope.original = angular.copy(modalGroup);

    // Methods
    $scope.cancel = cancel;
    $scope.resetDescription = resetDescription;
    $scope.updateGroupDescription = updateGroupDescription;

    function cancel () {
      resetDescription();
      $mdDialog.cancel();
    }

    function resetDescription () {
      $scope.details.description = $scope.original.description;
    }

    function updateGroupDescription () {
      $mdDialog.hide($scope.details);
    }

    //= ================================
    // Private interface
    //= ================================
  }
})();
