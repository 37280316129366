(function () {
  'use strict';

  angular.module('scroll-to', [])
    .config(['$anchorScrollProvider',
      function ($anchorScrollProvider) {
        $anchorScrollProvider.disableAutoScrolling();
      }]
    )
    .directive('scrollTo', ['$anchorScroll', function ($anchorScroll) {
      return function (scope, element, attrs) {
        element.bind('click', function (event) {
          scope.$on('$locationChangeStart', function (ev) {
            ev.preventDefault();
          });
          event.stopPropagation();
          $anchorScroll(attrs.scrollTo);
        });
      };
    }]);
})();
