(function () {
  'use strict';
  angular
    .module('imat.directives')
    .directive('iconSelect', function () {
      iconSelectController.$inject = ['$filter', '$mdSelect', '$scope', 'DASHBOARD', 'DashboardItemClass'];

      function iconSelectController ($filter, $mdSelect, $scope, DASHBOARD, DashboardItemClass) {
        var vm = this; // eslint-disable-line no-invalid-this
        var IMAGE_TYPES = DashboardItemClass.prototype.IMAGE;

        // Properties
        $scope.IMAGE_TYPES = IMAGE_TYPES;
        vm.iconFilterSet = '';
        vm.iconFilterText = '';
        vm.iconSets = [IMAGE_TYPES.GLYPHICON, IMAGE_TYPES.MATERIAL, IMAGE_TYPES.PS];
        vm.icons = [].concat(
          DASHBOARD.ICONS.GLYPHICONS.map(function (i) { return { name: i, type: IMAGE_TYPES.GLYPHICON }; }),
          DASHBOARD.ICONS.MATERIALICONS.map(function (i) { return { name: i, type: IMAGE_TYPES.MATERIAL }; }),
          DASHBOARD.ICONS.PSICONS.map(function (i) { return { name: i, type: IMAGE_TYPES.PS }; })
        );
        vm.filtered = [];
        vm.typeLabels = {};
        vm.typeLabels[IMAGE_TYPES.GLYPHICON] = 'Legacy';
        vm.typeLabels[IMAGE_TYPES.MATERIAL] = 'Material';
        vm.typeLabels[IMAGE_TYPES.PS] = 'Custom';

        // Methods
        vm.filterIcons = filterIcons;
        vm.resetData = resetData;
        vm.updateIcon = updateIcon;

        $scope.$on('$mdMenuOpen', function () {
          resetData();
        });

        function filterIcons () { // eslint-disable-line no-unused-vars
          if (vm.iconFilterSet && vm.iconFilterText) {
            vm.filtered = $filter('filter')(vm.icons, function (icon) {
            // check if matches selected set and icon name
              return (icon.type === vm.iconFilterSet) && (icon.name.toLowerCase().indexOf(vm.iconFilterText.toLowerCase().replace(/_/g, ' ')) >= 0);
            });
          } else if (vm.iconFilterSet) {
            vm.filtered = $filter('filter')(vm.icons, function (icon) {
              return vm.iconFilterSet === icon.type;
            });
          } else if (vm.iconFilterText) {
            vm.filtered = $filter('filter')(vm.icons, function (icon) {
            // check if matches a set type or a set name
              return icon.name.toLowerCase().indexOf(vm.iconFilterText.toLowerCase().replace(/_/g, ' ')) >= 0;
            });
          } else {
            vm.filtered = angular.copy(vm.icons);
          }
        }

        function resetData () {
          vm.iconFilterSet = '';
          vm.iconFilterText = '';
          filterIcons();
        }

        function updateIcon (icon) {
          vm.onSelect({ icon: icon });
        }
      }

      return {
        bindToController: true,
        controller: iconSelectController,
        controllerAs: 'ctrl',
        restrict: 'E',
        scope: {
          img: '=',
          onSelect: '&'
        },
        templateUrl: '/ui/_shared/directives/icon-select/icon-select.html',
        link: function ($scope, element, attrs, ctrl) {
          $scope.$watch(function () { return attrs.disabled; }, function (curr) {
            ctrl.disabled = curr;
          });
        }
      };
    });
})();
