(function () {
  'use strict';

  angular.module('imat.services')
    .factory('dashboardItemsApiSrv', dashboardItemsApiSrv);

  dashboardItemsApiSrv.$inject = [];

  function dashboardItemsApiSrv () {
    var service = {};

    return service;
  }
})();
