(function () {
  'use strict';

  angular.module('imat.http')
    .factory('errorInterceptor', errorInterceptor);

  errorInterceptor.$inject = ['$q', '$window'];

  function errorInterceptor ($q, $window) {
    var service = {
      responseError: responseError
    };

    return service;

    function parseResponseError (data) { // eslint-disable-line complexity
      var message; var messageArray = [];

      // If JSON response
      if (typeof data === 'object' && data !== null) {
        if (data.msg) {
          message = data.msg;
        } else if (data.errors) { // health exchange format
          message = data.errors;
          data.errors.forEach(function (msg) {
            if (typeof msg.error !== 'undefined') {
              messageArray.push(msg.error);
            }
          });
          message = messageArray.join(',');
        } else {
          message = void (0); // eslint-disable-line no-void
        }
      // if html/xml response
      } else {
        // Regex note- missing q param search error does not wrap message in error tags, boolean syntax error does
        message = data && data.match(/<error>(.+)<\/error><\/p>|<p errormsg="1">(.+)<\/p>|<span id="message">(.+)<\/span>/);
        if (message && message[1]) {
          message = message[1];
        } else if (message && message[2]) {
          message = message[2].replace(/<error>|<\/error>/g, '');
        } else if (message && message[3]) {
          message = message[3];
        } else {
          message = void (0); // eslint-disable-line no-void
        }
        if (message && message.indexOf(';') > 1) {
          message = message.substring(0, message.indexOf(';'));
          if (message.indexOf(', position') > 1) {
            message = message.substring(0, message.indexOf(', position'));
          }
        }
      }
      return message;
    }

    function responseError (response) { // eslint-disable-line complexity
      if (response instanceof Error) {
        // A response interceptor (probably the default JSON deserializer) threw an Error.
        // AngularJS < 1.6.0 always console logs a throw from a promise, i.e., can't squelch it :(
        return response;
        // Past this point, response is (usually) a normal 4xx/5xx response.
      }

      var message = '';
      message = parseResponseError(response.data);// XXX STOP RELAYING API ERRORS TO USERS!
      response = response || {};

      // running $window.location.reload(true) in any of the cases below will cause it to loop in FireFox
      switch (response.status) {
        case 401: // Unauthorized (i.e., Unauthenticated)
        case 419: // Authentication Timeout
          break;
        case 404:// Not Found
          if (response.config.url.indexOf('contact-about') < 0) {
            message = 'Resource not found: ' + response.config.url;
          }
          break;
        case 0:// ???
        case 403:// Forbidden (i.e., Unauthorized)
          message = message || 'Resource Forbidden.';
          break;
        case 500:
          message = message || 'A server error occurred.';
          break;
        default:
          message = message || 'An unknown error occurred.';
      }

      response.error = new Error(message);
      return $q.reject(response);
    }
  }
})();
