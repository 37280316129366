(function () {
  'use strict';

  angular.module('admin')
    .controller('EditCollectionDescriptionModalCtrl', EditCollectionDescriptionModalCtrl);

  EditCollectionDescriptionModalCtrl.$inject = ['$mdDialog', '$scope', 'modalCollection'];
  function EditCollectionDescriptionModalCtrl ($mdDialog, $scope, modalCollection) {
    $scope.details = modalCollection;

    // Methods
    $scope.cancel = cancel;
    $scope.updateCollectionDescription = updateCollectionDescription;

    function cancel () {
      $mdDialog.cancel();
    }

    function updateCollectionDescription () {
      $mdDialog.hide($scope.details);
    }

    //= ================================
    // Private interface
    //= ================================
  }
})();
