(function () {
  'use strict';

  angular.module('imat.services')
    .factory('psPhiAccess', psPhiAccess);

  psPhiAccess.$inject = ['_moment', '$http', '$q', '$mdDialog', '$timeout', 'dateTimeSrv'];

  function psPhiAccess (_moment, $http, $q, $mdDialog, $timeout, dateTimeSrv) {
    // Constants
    var CUSTOM_FILE_PATH = '/branding/';
    var PHI_API = '/api/phiaccess';
    var RESPONSE_TYPES = {
      SUCCESS: 'success',
      FAIL: 'failure',
      PROMPT: 'prompt'
    };
    var ACCESS_SUCCESS = 'Access Granted';
    var ERR_MPID_REQUIRED = 'A patient ID (MPID) is required for access to data.';
    var ERR_SERVER = 'An error occurred while verifying your access.';
    var ERR_ACCESS_FAIL = 'You do not have permission to access the patient data.';

    // Variables
    var accessStore = {};
    var dialog = {
      clickOutsideToClose: false,
      controller: [],
      locals: {},
      parent: angular.element(document.body),
      templateUrl: '/ui/_shared/services/phiAccess/phiAccess.html'
    };
    var service;
    var verification = $q.defer();
    var consentRecord = {};

    // Interface
    service = {
      clearConsent: clearConsent,
      getConsentRecord: getConsentRecord,
      verify: verify
    };

    function verify (mpid) {
      verification = $q.defer();
      if (!mpid) {
        return $q.reject({ message: ERR_MPID_REQUIRED });
      }
      var record = checkStore(mpid);
      if (record) {
        return $q.resolve(record);
      }
      setDialog(mpid);
      $mdDialog.show(dialog).then(function () {
        verification.resolve();
      }, function (err) {
        verification.reject(err);
      });
      return verification.promise;
    }

    function checkStore (mpid) {
      if (Object.prototype.hasOwnProperty.call(accessStore, mpid)) {
        var expiration = new Date(accessStore[mpid].expiration);
        var now = new Date();
        if (expiration.getTime() > now.getTime()) {
          return accessStore[mpid];
        }
        delete accessStore[mpid];
      }
    }

    function checkAccess (mpid, data, action, formType) {
      var payload = null;
      var record = checkStore(mpid);
      if (record) {
        return $q.resolve(record);
      }
      if (data) {
        // normalize radio button data
        data = data.selected || data;
        payload = [];
        if (formType !== 'text' && formType !== 'radio') {
          angular.forEach(data, function (value, key) {
            if (value) {
              payload.push(key);
            }
          });
        } else {
          payload.push(data);
        }
      }
      return $http({
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
        data: { mpid: mpid, input: payload, action: action },
        url: PHI_API
      }).then(function (res) {
        if (Object.prototype.hasOwnProperty.call(res.data, 'expiration') && typeof res.data.expiration === 'string') {
          //if now() date is already more then the expiration, use now + "config" option
          var now = new Date();
          var temp = new Date(dateTimeSrv.getIsoDatetime(res.data.expiration));
          if (now.getTime() > temp.getTime()) {
            //add config value to now and use it as expire time
            //TODO: hard coding to 15 minutes for now
            temp = _moment(now).add(15, 'm').toDate();
          }
          //res.data.expiration = dateTimeSrv.getIsoDatetime(res.data.expiration);
          res.data.expiration = dateTimeSrv.getIsoDatetime(temp.toString());
        }
        if (Object.prototype.hasOwnProperty.call(res.data, 'type')) {
          if (res.data.type === RESPONSE_TYPES.SUCCESS) {
            accessStore[mpid] = res.data;
          }
        }
        return res.data;
      });
    }

    function clearConsent () {
      consentRecord = {};
    }

    function getConsentRecord (mpid) {
      return $http.get(PHI_API + '/attestation/' + mpid)
        .then(function (result) {
          consentRecord = result.data;
          consentRecord.purpose_of_use = consentRecord.purpose_of_use || 'TREATMENT';
          return consentRecord;
        });
    }

    // TODO Move this controller to its own file.
    function setDialog (mpid) {
      dialog.controller = ['$scope', '$mdDialog', function ($scope, $mdDialogInner) {
        var vm = $scope;

        // Interface
        vm.answer = answer;
        vm.formData = '';
        vm.formType = 'none';
        vm.pendingPost = true;

        answer(null);

        function answer (action) {
          vm.pendingPost = true;
          vm.title = (vm.patientName) ? 'Verifying access to ' + vm.patientName + '...' : 'Verifying access...';
          checkAccess(mpid, vm.formData, action, vm.formType).then(function (res) {
            // These .hide/.cancel calls complement the .show call in verify(). ($mdDialog is a singleton.)
            if (!Object.prototype.hasOwnProperty.call(res, 'type')) {
              $mdDialogInner.cancel({ message: ERR_SERVER, err: 'Response missing type property.' });
              return;
            }
            switch (res.type) {
              case RESPONSE_TYPES.SUCCESS:
                $mdDialogInner.hide({ message: ACCESS_SUCCESS });
                break;
              case RESPONSE_TYPES.FAIL:
                $mdDialogInner.cancel({ message: ERR_ACCESS_FAIL, err: res.reason });
                break;
              case RESPONSE_TYPES.PROMPT:
                setContent(res.content, res.patient_name, res.patient_date_of_birth, res.patient_sex);
                break;
              default:
                $mdDialogInner.cancel({ message: ERR_SERVER, err: 'Unknown response type: ' + res.type });
            }
          }).catch(function (err) {
            $mdDialogInner.cancel({ message: ERR_SERVER, err: err });
          });
        }

        function setContent (content, patientName, patientDateOfBirth, patientSex) {
          // Clear old data
          vm.confirmLabel = '';
          vm.content = '';
          vm.formData = '';
          vm.formOptions = '';
          vm.formType = 'none';
          vm.rejectLabel = '';
          vm.showReject = false;
          vm.patientName = patientName.replace(/\|/,'');  // eslint-disable-line
          vm.patientDateOfBirth = patientDateOfBirth ? _moment(patientDateOfBirth).format('ll') : '';
          vm.patientAge = patientDateOfBirth ? _moment().diff(_moment(patientDateOfBirth), 'years') : '';
          vm.patientSex = patientSex;
          vm.formValid = false;
          vm.checkValid = checkValid;

          // Set new data
          if (content.body && content.body.type === 'text') {
            vm.content = content.body.value;
          }
          if (content.body && content.body.type === 'file') {
            $http.get(CUSTOM_FILE_PATH + content.body.value).then(function (res) {
              vm.content = res.data;
            });
          }
          if (content.input && content.input.type !== 'none') {
            vm.formType = content.input.type;
            if (content.input.type !== 'text') {
              vm.formData = {};
              if (content.input.type === 'checkbox') {
                content.input.options.forEach(function (option) {
                  vm.formData[option] = false;
                });
              }
              if (content.input.type === 'radio') {
                vm.formOptions = content.input.options;
                vm.formData.selected = false; // have to do this in order to get radio buttons to work
              }
            }
            $scope.$watch(function () { return vm.formData; }, function () {
              vm.formValid = checkValid(vm.formType);
            }, true);
          }
          vm.title = content.title;
          vm.confirmLabel = (content.btn_confirm) ? content.btn_confirm.label : '';
          vm.rejectLabel = '';
          if (content.btn_reject && content.btn_reject.visible) {
            vm.rejectLabel = content.btn_reject.label;
            vm.showReject = true;
          }
          vm.pendingPost = false;
        }

        function checkValid (type) {
          if (type === 'checkbox') {
            return !!Object.keys(vm.formData).filter(function (option) {
              return vm.formData[option] !== false;
            }).length;
          }
          if (type === 'radio') {
            return !!vm.formData.selected;
          }
          if (vm.formData && vm.formData !== undefined) {
            return true;
          }

          return false;
        }
      }];
    }

    return service;
  }
})();
