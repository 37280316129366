(function () {
  'use strict';

  angular.module('admin')
    .controller('AdminSymbolsCategoriesCtrl', AdminSymbolsCategoriesCtrl);

  AdminSymbolsCategoriesCtrl.$inject = [
    '$http', '$mdDialog', '$q', '$scope', '$window',
    'SymbolsSrv', 'psNotification'
  ];

  function AdminSymbolsCategoriesCtrl (
    $http, $mdDialog, $q, $scope, $window,
    SymbolsSrv, psNotification
  ) {
    var vm = this;
    var httpConfig = { headers: { Accept: 'application/xml, text/xml', 'Content-Type': 'application/xml' } };
    var userAlertConfig = $mdDialog.alert().title('Alert').ok('OK');
    var userConfirmConfig = $mdDialog.confirm().title('Confirm').ok('Yes').cancel('No');

    // Properties
    vm.categories = [];
    vm.categoryFilter = '';
    vm.categorySymbols = { fields: 0, values: 1, both: 2 };
    vm.categoryTemplate = { name: '', symbols: vm.categorySymbols.fields };
    vm.filteredCategories = [];
    vm.fieldSymbolXml = '';
    vm.newCategory = angular.copy(vm.categoryTemplate);
    vm.selectedCategory = '';
    vm.valueSymbolXml = '';

    vm.editorConfig = {
      mode: 'xml',
      lineWrapping: true,
      lineNumbers: true,
      indentUnit: 2,
      theme: 'eclipse',
      extraKeys: {
        'Ctrl-F': 'findPersistent',
        'Cmd-F': 'findPersistent'
      },
      highlightSelectionMatches: {
        showToken: true,
        annotateScrollbar: true
      }
    };

    // Methods
    vm.addCategory = addCategory;
    vm.cancelDialog = cancelDialog;
    vm.checkCategoryName = checkCategoryName;
    vm.createCategory = createCategory;
    vm.filterCategories = filterCategories;
    vm.selectCategory = selectCategory;
    vm.submitDialog = submitDialog;
    vm.updateFieldSymbols = updateFieldSymbols;
    vm.updateValueSymbols = updateValueSymbols;
    // vm.validateXml = validateXml;

    activate();

    function activate () {
      SymbolsSrv.getList(true)
        .then(function () {
          getCategories();
        });
    }

    //= ================================
    // Public interface
    //= ================================

    function filterCategories () {
      var rexCompleting = new RegExp('^' + vm.categoryFilter, 'i');
      vm.filteredCategories = vm.categories.filter(function (c) { return rexCompleting.test(c); });
    }

    function selectCategory () {
      getSymbols(vm.selectedCategory);
    }

    function updateFieldSymbols () {
      validateXml(vm.fieldSymbolXml)
        .catch(function () {
          return $q.reject();
        })
        .then(function () {
          return $mdDialog.show(userConfirmConfig.textContent('Are you sure you want to update the fields?'))
            .then(function () {
              $http.put('/search/symbols/fields/categories/' + vm.selectedCategory, vm.fieldSymbolXml, httpConfig)
                .then(function () {
                  psNotification.success('Updated the field symbol XML.');
                });
            });
        });
    }

    function updateValueSymbols () {
      validateXml(vm.valueSymbolXml)
        .catch(function () {
          return $q.reject();
        })
        .then(function () {
          return $mdDialog.show(userConfirmConfig.textContent('Are you sure you want to update the values?'))
            .then(function () {
              $http.put('/search/symbols/values/categories/' + vm.selectedCategory, vm.valueSymbolXml, httpConfig)
                .then(function () {
                  psNotification.success('Updated the value symbol XML.');
                });
            });
        });
    }

    function addCategory (ev) {
      $mdDialog.show({
        contentElement: '#createCategoryDialog',
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: true
      })
        .finally(function () {
          vm.newCategory = angular.copy(vm.categoryTemplate);
        });
    }

    function cancelDialog () {
      $mdDialog.cancel();
    }

    function checkCategoryName () {
      $scope.addCategoryForm.categoryName.$setValidity('duplicate', vm.categories.indexOf(vm.newCategory.name) === -1);
    }

    function createCategory () {
      // TODO Minify minimum document required to create symbols.
      var xml = '<symbols>\n' +
                '  <symbol desc="" name="' + vm.newCategory.name + '">\n' +
                '    <items>\n' +
                '      <item name="placeholder"></item>\n' +
                '    </items>\n' +
                '  </symbol>\n' +
                '</symbols>\n';

      return validateXml(xml)
        .then(function () {
          var promises = [];

          if (vm.newCategory.symbols === vm.categorySymbols.fields || vm.newCategory.symbols === vm.categorySymbols.both) {
            promises.push(SymbolsSrv.createSymbolCategory({ name: vm.newCategory.name, type: 'fields' }, xml));
          }
          if (vm.newCategory.symbols === vm.categorySymbols.values || vm.newCategory.symbols === vm.categorySymbols.both) {
            promises.push(SymbolsSrv.createSymbolCategory({ name: vm.newCategory.name, type: 'values' }, xml));
          }
          return $q.all(promises);// XXX This does not differentiate between 1 failure and 2 failures.
        })
        .then(function () {
          getCategories();
        });
    }

    function submitDialog () {
      var catStr = vm.newCategory.symbols === vm.categorySymbols.both ? 'Categories' : 'Category';

      createCategory()
        .then(function () {
          psNotification.success(catStr + ' created.');
          vm.selectedCategory = vm.newCategory.name;
        })
        .catch(function () {
          psNotification.error('Unable to create ' + catStr.toLowerCase() + '.');
        })
        .finally($mdDialog.hide);
    }

    //= ================================
    // Private interface
    //= ================================

    function formatXml (xml) {
      xml = xml.replace(/<symbols><symbol/g, '<symbols>\n  <symbol');
      xml = xml.replace(/<\/symbol><symbol/g, '</symbol>\n  <symbol');
      xml = xml.replace(/<\/symbol><\/symbols/g, '</symbol>\n</symbols');
      return xml;
    }

    function getCategories () {
      return SymbolsSrv.getCategoryList(true)
        .then(function (categories) {
          vm.categories = categories;
          filterCategories();
        })
        .catch(function () {
          psNotification.error('Unable to get the category list.');
        });
    }

    function getFields (category) {
      if (!category) { return $q.reject('field'); }
      return $http.get('/search/symbols/fields/categories/' + category, httpConfig)
        .then(function (response) {
          return response.data.original_response;
        });
    }

    function getValues (category) {
      if (!category) { return $q.reject('value'); }
      return $http.get('/search/symbols/values/categories/' + category, httpConfig)
        .then(function (response) {
          return response.data.original_response;
        });
    }

    function getSymbols (category) {
      getFields(category)
        .then(function (xml) {
          vm.fieldSymbolXml = formatXml(xml);
        })
        .catch(function (reason) {
          vm.fieldSymbolXml = '';
          if (reason.malformedResponse) {
            psNotification.warn('The field symbol XML is invalid.');
            vm.fieldSymbolXml = reason.data.data;
          }
        });

      getValues(category)
        .then(function (xml) {
          vm.valueSymbolXml = formatXml(xml);
        })
        .catch(function (reason) {
          vm.valueSymbolXml = '';
          if (reason.malformedResponse) {
            psNotification.warn('The value symbol XML is invalid.');
            vm.valueSymbolXml = reason.data.data;
          }
        });
    }

    function showAlert (config) {
      return $mdDialog.show(config).then(function () { return $q.reject(); });
    }

    function validateXml (xmlStr) {
      var parser;
      var xmlDoc;
      var xmlErrMessage;
      var xmlErrPartial;

      if (!document.implementation.createDocument) {
        return showAlert(userAlertConfig.htmlContent('Your browser does not support XML validation, and the document was not validated.'));
      }

      parser = new $window.DOMParser();

      try {
        xmlDoc = angular.element(parser.parseFromString(xmlStr, 'application/xml'));
      } catch (e) {
        // Ignored.
      }

      xmlErrMessage = xmlDoc.find('parsererror').detach();
      xmlErrPartial = xmlDoc.find('symbols');

      if (xmlErrMessage.length) {
        xmlErrMessage = xmlErrMessage.get(0).innerHTML;
        // xmlErrMessage = xmlErrMessage.replace(/<(\w+) ?[^>]*>/g, '<$1>').replace(/<\/(\w+) ?[^>]*>/g, '</$1>');

        if (xmlErrPartial.length) {
          xmlErrPartial = xmlErrPartial.get(0).outerHTML;
          xmlErrPartial = xmlErrPartial.replace(/<([^>/]*)(\/?) *>(\n?)/g, '&lt;$1$2&gt;$3').replace(/<\/([^>/]*) *>\n?/g, '&lt;/$1&gt;\n');
        } else {
          xmlErrPartial = xmlStr.replace(/</g, '&lt;').replace(/>/g, '&gt;');
        }

        return showAlert(userAlertConfig.htmlContent(xmlErrMessage + '<pre>' + xmlErrPartial + '</pre>'));
      }
      return $q.resolve();
    }
  }
})();
