(function () {
  'use strict';

  angular
    .module('vhr')
    .controller('CxmlAdtModalCtrl', CxmlAdtModalCtrl);

  CxmlAdtModalCtrl.$inject = [
    'VHR_REPORT',
    '$scope', '$anchorScroll', '$location', '$log', '$mdDialog', '$q', '$timeout', '$window',
    'DownloaderSrv', 'psNotification', 'psReports', 'vhrConfigSrv', 'vhrGridSrv', 'vhrPatientSrv', 'vhrPrintSrv',
    'vhrRecordCollectionSrv', 'vhrUserSrv'
  ];

  function CxmlAdtModalCtrl (
    VHR_REPORT,
    $scope, $anchorScroll, $location, $log, $mdDialog, $q, $timeout, $window,
    DownloaderSrv, psNotification, psReports, vhrConfigSrv, vhrGridSrv, vhrPatientSrv, vhrPrintSrv,
    vhrRecordCollectionSrv, vhrUserSrv
  ) {
    var vm = this;
    var defaultConcept = {
      hasNotes: false,
      id: '',
      noteContext: '',
      noteCount: 0,
      ready: { export: false }
    };
    var CONCEPT_ALLERGIES_GRID = 'concepts-allergy';
    var CONCEPT_ALLERGIES_LABEL = 'Allergies';
    var CONCEPT_PROBLEM_GRID = 'concepts-problem';
    var CONCEPT_PROBLEM_LABEL = 'Diagnosis/Problem';
    var CONCEPT_INSURANCE_GRID = 'concepts-medical-insurance';
    var CONCEPT_INSURANCE_LABEL = 'Medical Insurance';
    var CONCEPT_PROCEDURE_GRID = 'concepts-procedure';
    var CONCEPT_PROCEDURE_LABEL = 'Procedures';
    var CONCEPT_CONTACT_GRID = 'concepts-contact';
    var CONCEPT_CONTACT_LABEL = 'Next of Kin';
    var CONCEPT_GUARANTOR_GRID = 'concepts-guarantor';
    var CONCEPT_GUARANTOR_LABEL = 'Guarantors';
    var CONCEPT_DEMOGRAPHICS_GRID = 'concepts-demographics';
    var CONCEPT_DEMOGRAPHICS_LABEL = 'Demographics';
    /************************************/
    /* IF YOU ADD CONCEPTS, REMEMBER TO */
    /* ADD THEM TO THE BATCH PRINTER:   */
    /* RecordCollectionCtrl             */
    /************************************/
    var concepts = [
      angular.extend({}, defaultConcept, { gridName: CONCEPT_ALLERGIES_GRID, label: CONCEPT_ALLERGIES_LABEL }),
      angular.extend({}, defaultConcept, { gridName: CONCEPT_PROBLEM_GRID, label: CONCEPT_PROBLEM_LABEL }),
      angular.extend({}, defaultConcept, { gridName: CONCEPT_INSURANCE_GRID, label: CONCEPT_INSURANCE_LABEL }),
      angular.extend({}, defaultConcept, { gridName: CONCEPT_PROCEDURE_GRID, label: CONCEPT_PROCEDURE_LABEL }),
      angular.extend({}, defaultConcept, { gridName: CONCEPT_CONTACT_GRID, label: CONCEPT_CONTACT_LABEL }),
      angular.extend({}, defaultConcept, { gridName: CONCEPT_GUARANTOR_GRID, label: CONCEPT_GUARANTOR_LABEL }),
      angular.extend({}, defaultConcept, { gridName: CONCEPT_DEMOGRAPHICS_GRID, label: CONCEPT_DEMOGRAPHICS_LABEL })
    ];

    // Properties
    vm.adtLabels = vhrConfigSrv.getADTLabels();
    vm.concepts = [];
    vm.conceptsIndex = {};// To re-use print templates.
    vm.gridApi = {};
    vm.gridApiIndex = {};// To re-use print templates.
    vm.gridConfig = {};
    vm.hide_details_info = false;
    vm.hide_original_doc_info = true;
    vm.hide_patient_info = false;
    vm.loaded = { concepts: false, record: false, report: false };
    vm.patient = vhrPatientSrv;
    vm.printSource = vhrConfigSrv.getPrintSource();
    vm.record = vhrRecordCollectionSrv.stashed();
    vm.recordQueued = false;
    vm.report = null;
    vm.reportParams = null;
    vm.showPdfFirst = false;
    vm.user = vhrUserSrv;// Used in print template.
    vm.workspace = 'cxml-record-modal';

    // Methods
    vm.cancel = cancel;
    vm.dequeue = dequeue;
    vm.download = download;
    vm.print = print;
    vm.queue = queue;
    $scope.scrollToLocation = scrollToLocation;
    vm.toggleDetailsInfo = toggleDetailsInfo;
    vm.toggleOriginalDocumentInfo = toggleOriginalDocumentInfo;
    vm.togglePatientInfo = togglePatientInfo;
    vm.userCanDownloadDoc = vhrUserSrv.canDownloadOriginalDocuments();
    vm.userCanViewDoc = vhrUserSrv.canViewOriginalDocuments();

    activate();

    // Implementation

    function activate () {
      vm.recordQueued = (typeof vhrRecordCollectionSrv.find('printQueue', vm.record) !== 'undefined');
      loadReport()
        .then(function () {
          return loadReportData(angular.copy(vm.reportParams));
        })
        .then(function () {
          return loadDataArtifact();
        })
        .then(function () {
          vm.loaded.record = true;
          if (vhrUserSrv.isAdmin()) {
            loadDocumentArtifact()
              .catch(function (reason) {
                psNotification.error(reason);
              });
          }
        })
        .catch(function (reason) {
          reason = reason || 'Failed to load the record.';
          psNotification.error(reason);
        })
        .finally(function () {
          vm.loaded.report = true;
        });

      // Capture Ctrl+P and route through vhrPrintSrv.print().
      angular.element($window.document).on('keyup keydown', function (evt) {
        if (evt.ctrlKey && evt.keyCode === 80) {
          evt.preventDefault();
          print();
          return false;
        }
      });
    }

    function cancel () {
      $mdDialog.cancel();
    }

    function checkAllConceptsReady () {
      if (!vm.loaded.concepts) {
        vm.loaded.concepts = (vm.concepts.filter(function (c) {
          return !(c.ready.export);
        }).length === 0);
      }
      if (!vm.loaded.concepts) {
        $timeout(checkAllConceptsReady, 0, false);
      }
    }

    function dequeue () {
      vhrRecordCollectionSrv.construct('printQueue').remove(vm.record);
      vm.recordQueued = false;
    }

    function download () {
      DownloaderSrv.download(vm.record.getXmlDocument(), {
        filename: 'HL7_' + vm.record.getUri(),
        processors: [
          function (doc) { return doc == null ? '' : doc.replace(/<\/?document>/g, ''); },
          DownloaderSrv.PROCESS.revertXmlEntities
        ],
        type: DownloaderSrv.MIME.TEXT
      });
    }

    function loadDataArtifact () {
      var artifactName = vm.record.getUri() + '.json';

      return vm.report.getArtifact(vm.workspace, artifactName)
        .then(function (response) { // eslint-disable-line complexity
          var raw = response.pop();
          var rec = angular.extend({}, vm.record.raw, raw);
          var reMrnAtFac = /^\s*([^\@]+)\s*\@\s*(.+)\s*$/; // eslint-disable-line no-useless-escape

          if (!angular.isArray(rec.allergies)) { rec.allergies = null; }
          if (!angular.isArray(rec.medical_insurance)) { rec.medical_insurance = null; }
          if (!angular.isArray(rec.problems)) { rec.problems = null; }
          if (!angular.isArray(rec.procedures)) { rec.procedures = null; }
          if (!angular.isArray(rec.contacts)) { rec.contacts = null; }
          if (!angular.isArray(rec.guarantor)) { rec.guarantor = null; }

          vm.record.set(rec);

          if (rec.patient_local_id && typeof rec.patient_local_id === 'string') {
            vm.record.mrnList = rec.patient_local_id.split(/\|/).map(function (fmrn) { return fmrn.replace(reMrnAtFac, '$1 [$2]'); });
          }
          if (rec.attachments && rec.attachments.length) {
            rec.attachments.forEach(function (attachment) {
              if (attachment.type === 'application/pdf') {
                vm.showPdfFirst = true;
              }
            });
          }
          setupConcepts(vm.record);
          checkAllConceptsReady();
        })
        .catch(function (reason) {
          $log.debug(reason);
          return $q.reject('Failed to load the record data.');
        });
    }

    function loadDocumentArtifact () {
      var artifactName = vm.record.getUri() + '.xml';

      return vm.report.getArtifact(vm.workspace, artifactName)
        .then(function (response) {
          vm.record.setDocument(response.original_response);
        })
        .catch(function (reason) {
          $log.debug(reason);
          return $q.reject('Failed to load the record document.');
        });
    }

    function loadReport () {
      var reportId = vm.record.getReportId();

      return psReports.load(reportId, VHR_REPORT.CALLER, { initParams: vhrConfigSrv.getReportParameters(reportId) })
        .then(function (report) {
          vm.report = report;
          vm.reportParams = report.getParameterDefaults();
        })
        .catch(function (reason) {
          $log.debug(reason);
          return $q.reject('Failed to prepare the record.');
        });
    }

    function loadReportData (params) {
      params.records = {};
      params.records[vm.record.getUri()] = {
        store: vm.record.getStore(),
        view_type: vm.record.getType()
      };
      params = angular.extend({}, vm.record.getReportParams(), params);

      return vm.report.run(vm.workspace, params)
        .then(function () {
          return vm.report.results[vm.workspace].getPollPromise();
        })
        .catch(function (reason) {
          $log.debug(reason);
          return $q.reject('Failed to load the record.');
        });
    }

    function print () {
      vhrPrintSrv.print();
    }

    function queue () {
      vhrRecordCollectionSrv.construct('printQueue').insert(vm.record);
      vm.recordQueued = true;
    }

    function scrollToLocation (locationRow, noteText) {
      var location = 'uiGrid' + locationRow.grid.id + '_' + noteText.substring(noteText.lastIndexOf(' ')).trim();
      $anchorScroll(location);
    }

    // ---------------------------------
    // Concepts and ui-grids
    // ---------------------------------

    function setupConcepts (record) {
      var gridMeta;

      var misc = [{
        patient_marital_status: record.raw.patient_marital_status,
        patient_race: record.raw.patient_race,
        patient_language: record.raw.patient_language,
        patient_ethnic_group: record.raw.patient_ethnic_group
      }];

      switch (record.getType()) {
        case VHR_REPORT.RECORD_TYPE.ADT:
          gridMeta = concepts.filter(function (c) { return c.label === CONCEPT_ALLERGIES_LABEL; }).pop();
          setupConcept(gridMeta, record.locator, record.raw.allergies);
          gridMeta = concepts.filter(function (c) { return c.label === CONCEPT_PROBLEM_LABEL; }).pop();
          setupConcept(gridMeta, record.locator, record.raw.problems);
          gridMeta = concepts.filter(function (c) { return c.label === CONCEPT_INSURANCE_LABEL; }).pop();
          setupConcept(gridMeta, record.locator, record.raw.medical_insurance);
          gridMeta = concepts.filter(function (c) { return c.label === CONCEPT_PROCEDURE_LABEL; }).pop();
          setupConcept(gridMeta, record.locator, record.raw.procedures);
          gridMeta = concepts.filter(function (c) { return c.label === CONCEPT_CONTACT_LABEL; }).pop();
          setupConcept(gridMeta, record.locator, record.raw.contacts);
          gridMeta = concepts.filter(function (c) { return c.label === CONCEPT_GUARANTOR_LABEL; }).pop();
          setupConcept(gridMeta, record.locator, record.raw.guarantor);
          gridMeta = concepts.filter(function (c) { return c.label === CONCEPT_DEMOGRAPHICS_LABEL; }).pop();
          setupConcept(gridMeta, record.locator, misc);
          break;
        default:
          // Nothing to do?
          break;
      }
    }

    function setupConcept (gridMeta, recLoc, gridData, idx, cnt) {
      var len;

      if (!gridMeta || !recLoc || gridData === null) {
        return;
      }

      len = vm.concepts.push(angular.copy(gridMeta));
      gridMeta = vm.concepts[len - 1];

      if (typeof idx === 'undefined' || cnt < 2) {
        idx = 0;
      } else {
        gridMeta.label += ' (' + (idx + 1) + ')';
      }

      gridMeta.id = gridMeta.gridName + '-' + recLoc + '-' + idx;// Must be unique across all concepts in the template.
      setupConceptGrid(gridMeta, recLoc, gridData);
      setupConceptsIndex(recLoc, (len - 1));// To re-use print templates.
    }

    function setupConceptGrid (gridMeta, recLoc, gridData) {
      var gridDef = vhrConfigSrv.raw.grids[gridMeta.gridName] || { fieldOrder: [] };
      var gridConfig = angular.extend({ data: gridData }, gridDef.uiGridConfig || {});
      var gridType = gridMeta.id;

      vm.gridConfig[gridType] = {};

      gridConfig = vhrGridSrv.getGridConfig($scope, gridType, vhrGridSrv.CONFIG.CONCEPT, gridConfig, gridDef.fieldOrder);
      gridConfig.onRegisterApi = function (api) {
        vhrGridSrv.setGridFootnotes(api.grid, gridMeta);
        vhrGridSrv.setGridHeight(api.grid);
        vm.gridApi[gridType] = api;
        setupGridApiIndex(gridMeta, recLoc, api);// To re-use print templates.
      };
      angular.extend(vm.gridConfig[gridType], gridConfig);
    }

    function setupConceptsIndex (recLoc, idx) {
      if (typeof vm.conceptsIndex[recLoc] === 'undefined') {
        vm.conceptsIndex[recLoc] = [];
      }
      vm.conceptsIndex[recLoc].push(vm.concepts[idx]);
    }

    function setupGridApiIndex (gridMeta, recLoc, api) {
      if (typeof vm.gridApiIndex[recLoc] === 'undefined') {
        vm.gridApiIndex[recLoc] = {};
      }
      vm.gridApiIndex[recLoc][gridMeta.id] = api;
      gridMeta.ready.export = true;
    }

    function toggleDetailsInfo () {
      vm.hide_details_info = !vm.hide_details_info;
    }

    function toggleOriginalDocumentInfo () {
      vm.hide_original_doc_info = !vm.hide_original_doc_info;
    }

    function togglePatientInfo () {
      vm.hide_patient_info = !vm.hide_patient_info;
    }
  }
})();
