(function () {
  'use strict';

  angular.module('imat.services.config')
    .factory('ImatConfigClass', ImatConfigClass);

  ImatConfigClass.$inject = ['$parse'];

  function ImatConfigClass ($parse) {
    var rexIndex = /^\d+$/;

    // A simple wrapper for JSON configurations with .get and .set methods for
    // easy and consistent access to (nested) properties.
    function ImatConfig (config) {
      angular.extend(this, config);
    }

    ImatConfig.prototype.get = function (property) {
      return $parse(getPropertyExpression(property))(this);
    };

    ImatConfig.prototype.set = function (property, setting) {
      var getter = $parse(getPropertyExpression(property));
      var setter = getter.assign;

      if (angular.isFunction(setter)) {
        setter(this, setting);
        return setting;
      }
      throw Error('The property "' + property + '" cannot be overwritten.');
    };

    ImatConfig.prototype.toArray = function () {
      var self = this;
      return Object.keys(self)
        .map(function (key) { return parseInt(key, 10); })
        .filter(function (key) { return !isNaN(key); })
        .sort(function (a, b) { return a - b; })
        .map(function (key) { return self[key]; });
    };

    function getPropertyExpression (property) {
      // The $parse argument is evaluated as an angular expression, but since
      // we expect only property names, we repackage array indices so that they
      // evaluate as property names (rather than just constants).
      return (rexIndex.test(property) ? "this['" + property + "']" : property);
    }

    return ImatConfig;
  }
})();
