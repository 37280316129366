(function () {
  'use strict';

  angular
    .module('imat')
    .controller('LeftSidebarCtrl', LeftSidebarCtrl);

  LeftSidebarCtrl.$inject = ['$mdSidenav', '$scope', '$state', '$transitions', '$window'];

  function LeftSidebarCtrl ($mdSidenav, $scope, $state, $transitions, $window) {
    var vm = this;
    var deregisterStateChangeSuccess = $transitions.onSuccess({ to: 'app.**' }, stateChangeSuccess);

    // Methods.
    vm.help = help;
    vm.goBack = goBack;
    vm.showSidenav = showSidenav;

    activate();

    function activate () {
      setLinks();

      $scope.$on('$destroy', function () {
        deregisterStateChangeSuccess();
      });

      $scope.$watch($state.$current.data, function () {
        setLinks();
      });
    }

    function goBack () {
      if (vm.backState !== true) {
        $state.go(vm.backState);
      } else {
        window.history.back();
      }
    }

    function help () {
      $window.open(vm.helpLink, '_blank');
    }

    function setLinks () {
      vm.backState = ($state.$current.data && $state.$current.data.back) || '';// State name.
      vm.helpLink = ($state.$current.data && $state.$current.data.help) || '';// HREF.
      vm.pageTitle = ($state.$current.data && $state.$current.data.pageTitle) || '';
    }

    function showSidenav () {
      $mdSidenav('sidenav').toggle();
    }

    function stateChangeSuccess (trans) {
      var toState = trans.to();

      vm.backState = (toState.data && toState.data.back) || '';// State name.
      vm.helpLink = (toState.data && toState.data.help) || '';// HREF.
      vm.pageTitle = (toState.data && toState.data.pageTitle) || '';
    }
  }
})();
