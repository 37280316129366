(function () {
  'use strict';

  angular
    .module('vhr.services')
    .factory('vhrRecordCollectionSrv', vhrSrv);

  vhrSrv.$inject = ['$rootScope', '$filter'];

  function vhrSrv ($rootScope, $filter) {
    var service;
    var srvRecord;
    var collections = {};

    service = {
      construct: construct,
      find: find,
      findAll: findAll,
      reset: reset,
      stash: stash,
      stashed: stashed
    };

    // ----------------------------------------
    // Public interface
    // ----------------------------------------

    function construct (type) {
      if (typeof collections[type] === 'undefined') {
        switch (type) {
          case 'recentViews':
            collections[type] = new RecentViewsRecordCollection();
            break;
          case 'printQueue':
            collections[type] = new PrintQueueRecordCollection();
            break;
          default:
            throw new Error('Record collection type is not valid.');
        }
      }
      return collections[type];
    }

    function find (coll, record) {
      if (collections[coll]) {
        return collections[coll].find(record);
      }
    }

    function findAll (coll, type) {
      if (collections[coll]) {
        return collections[coll].findAll(type);
      }
    }

    function reset () {
      angular.forEach(collections, function (coll, type) {
        coll.reset();
        delete collections[type];
      });
      srvRecord = void (0); // eslint-disable-line no-void
    }

    function stash (record) {
      srvRecord = record;
    }

    function stashed () {
      return srvRecord;
    }

    // ----------------------------------------
    // Private interface
    // ----------------------------------------

    // ----------------------------------------
    // Generic Collection
    // ----------------------------------------

    function RecordCollection () {
      this._collection = [];
    }

    RecordCollection.prototype.find = function (record) {
      var idx = this._indexOf(record);

      if (idx >= 0) {
        return this._collection[idx];
      }
    };

    RecordCollection.prototype.findAll = function (type) {
      if (!type) {
        return this._collection;
      }
      return this._collection.filter(function (record) {
        return record.is(type);
      });
    };

    RecordCollection.prototype.getType = function () {
    };

    RecordCollection.prototype.insert = function (record) {
      var idx = this._indexOf(record);

      if (idx < 0) {
        this._collection.push(record);
        this._broadcastChange('insert', record, (this._collection.length - 1));
      }
    };

    RecordCollection.prototype.remove = function (record) {
      var idx = this._indexOf(record);

      if (idx >= 0) {
        this._collection.splice(idx, 1);
        this._broadcastChange('remove', record, idx);
      }
    };

    RecordCollection.prototype.reset = function () {
      this._collection.splice(0);
      this._broadcastChange('reset');
    };

    RecordCollection.prototype._broadcastChange = function (how, record, idx) {
      $rootScope.$broadcast('vhrRecordCollectionChanged', this, how, record, idx);
    };

    RecordCollection.prototype._indexOf = function (record) {
      var coll = this._collection;
      var i;

      if (record && record.raw && record.raw.store && record.raw.uri) {
        for (i = coll.length - 1; i >= 0; --i) {
          if (coll[i].raw.store === record.raw.store && coll[i].raw.uri === record.raw.uri) {
            return i;
          }
        }
      }
      return -1;
    };

    // ----------------------------------------
    // Recent Views Collection
    // ----------------------------------------

    function RecentViewsRecordCollection () {
      RecordCollection.call(this);

      this.HISTORY_SIZE = 10;

      this.findById = function (id) {
        return this._collection.filter(function (rec) {
          return rec._id === id;
        }).pop();
      };

      this.getType = function () {
        return 'recentViews';
      };

      this.insert = function (record) {
        this.remove(record);
        if (this._collection.unshift(record) > this.HISTORY_SIZE) {
          this._collection.pop();
        }
        this._broadcastChange('insert', record, 0);
      };

      this.resequence = function (record) {
        var idx = this._indexOf(record);

        if (idx === -1) {
          idx = 0;
          this.insert(record);
        }

        this._collection[idx].viewedAt = new Date().getTime();
        this._collection = $filter('orderBy')(this._collection, '-viewedAt');
        this._broadcastChange('resequence', record, idx);
        return this._collection;
      };

      this.selected = function () {
        if (this._collection.length) {
          return this._collection[0];
        }
      };
    }

    RecentViewsRecordCollection.prototype = Object.create(RecordCollection.prototype);
    RecentViewsRecordCollection.prototype.constructor = RecentViewsRecordCollection;

    // ----------------------------------------
    // Print Queue Collection
    // ----------------------------------------

    function PrintQueueRecordCollection () {
      RecordCollection.call(this);

      this.getType = function () {
        return 'printQueue';
      };

      this.selected = function () {
        return this._collection;
      };
    }

    PrintQueueRecordCollection.prototype = Object.create(RecordCollection.prototype);
    PrintQueueRecordCollection.prototype.constructor = PrintQueueRecordCollection;

    // ----------------------------------------
    return service;// vhrRecordCollectionSrv
  }
})();
