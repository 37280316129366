(function () {
  'use strict';

  angular.module('vhr.services')
    .service('vhrCxmlLabcorpRecordClass', vhrCxmlLabcorpRecordClass);

  vhrCxmlLabcorpRecordClass.$inject = [
    'VhrCxmlRecordClass'
  ];

  function vhrCxmlLabcorpRecordClass (
    VhrCxmlRecordClass
  ) {
    function CxmlLabRecord (cfg) {
      VhrCxmlRecordClass.call(this, cfg);
      this.controller = 'CxmlLabcorpModalCtrl';
      this.printTemplateUrl = '/ui/vhr/templates/cxml/print/labcorp.html';
      this.templateUrl = '/ui/vhr/templates/cxml/cxml-labcorp-modal.html';
    }

    CxmlLabRecord.prototype = Object.create(VhrCxmlRecordClass.prototype);
    CxmlLabRecord.prototype.constructor = CxmlLabRecord;

    CxmlLabRecord.prototype.set = function (raw, setFields) {
      if (setFields === true) {
        setFields = ['result', 'ordered_by', 'clinical_date', 'attending_physician'];
      }
      VhrCxmlRecordClass.prototype.set.call(this, raw, setFields);
    };

    return CxmlLabRecord;
  }
})();