(function () {
  'use strict';

  angular.module('queries')
    .controller('ExportResultsModalCtrl', ExportResultsModalCtrl);

  ExportResultsModalCtrl.$inject = ['$log', '$mdDialog', 'psNotification', 'QueriesSrv', 'ResultDisplaySrv'];

  function ExportResultsModalCtrl ($log, $mdDialog, psNotification, QueriesSrv, ResultDisplaySrv) {
    var vm = this;

    vm.formFields = {
      saveresults: '',
      desc: '',
      fields: [],
      exportResults: true,
      format: 'csv',
      start: 0,
      limit: -1
    };

    vm.cancel = cancel;
    vm.submit = submit;
    vm.showMoreOptions = false;

    activate();

    function activate () {
      // set fields to match current result display columns
      vm.formFields.fields = ResultDisplaySrv.getFields(QueriesSrv.query.resultDisplay, false);
    }

    function cancel () {
      $mdDialog.cancel();
    }
    function submit () {
      vm.formFields.saveresults = (vm.formFields.saveresults.trim().length > 0) ? vm.formFields.saveresults : 'Unnamed';
      QueriesSrv.saveResults(vm.formFields)
        .then(function () {
          psNotification.success("Your export is being generated. To view, select '" + vm.formFields.saveresults + "' from the 'Exports' list.");
        })
        .catch(function (errorResponse) {
          psNotification.error('Unable to export your results.');
          $log.debug(errorResponse);
        })
        .finally(function () {
          $mdDialog.hide(vm.formFields);
        });
    }
  }
})();
