(function () {
  'use strict';

  angular.module('vhr.constants', [])
    .constant('VHR_ADMIN_GROUP', 'searchappliance_vhr_admin')
    .constant('VHR_APP_NAME', 'vhr')
    .constant('VHR_REPORT', {
      CALLER: 'vhr',
      RECORD_TYPE: {
        ADT: 'ADT',
        ADVANCE_DIRECTIVE: 'ADVANCE_DIRECTIVE',
        CCD: 'CCD',
        LAB: 'LAB',
        MED_ADMINISTER: 'MED_ADMINISTER',
        MED_DISPENSE: 'MED_DISPENSE',
        MED_REQUEST: 'MED_REQUEST',
        MED_STATEMENT: 'MED_STATEMENT',
        PATHOLOGY: 'PATHOLOGY',
        RADIOLOGY: 'RADIOLOGY',
        TRANSCRIPTION: 'TRANSCRIPTION',
        UNKNOWN: 'UNKNOWN'
      }
    })
    .constant('MODE', {
      VIEW: 0,
      PRINT: 1
    })
    .constant('MODE_META', {
      VIEW: {
        ICON: 'pageview',
        ICONCODE: '\\E8A0',
        LABEL: 'View Records',
        TOOLTIP: 'View record details'
      },
      PRINT: {
        ICON: 'check',
        ICONCODE: '\\E5CA',
        LABEL: 'Select to Print',
        TOOLTIP: 'Select records for printing'
      }
    })
    .constant('HIE', {
      PURPOSE_OF_USE: {
        ABUSE: 'ABUSE',
        COVERAGE: 'COVERAGE',
        DECEASED: 'DECEASED',
        DIRECTORY: 'DIRECTORY',
        DISASTER: 'DISASTER',
        DONATION: 'DONATION',
        EMERGENCY: 'EMERGENCY',
        FAMILY: 'FAMILY',
        FRAUD: 'FRAUD',
        GOVERNMENT: 'GOVERNMENT',
        JUDICIAL: 'JUDICIAL',
        LAW: 'LAW',
        LEGAL: 'LEGAL',
        MARKETING: 'MARKETING',
        OPERATIONS: 'OPERATIONS',
        OVERSIGHT: 'OVERSIGHT',
        PAYMENT: 'PAYMENT',
        PRESENT: 'PRESENT',
        PSYCHOTHERAPY: 'PSYCHOTHERAPY',
        PUBLICHEALTH: 'PUBLICHEALTH',
        REQUEST: 'REQUEST',
        RESEARCH: 'RESEARCH',
        SYSADMIN: 'SYSADMIN',
        THREAT: 'THREAT',
        TRAINING: 'TRAINING',
        TREATMENT: 'TREATMENT',
        WORKERSCOMP: 'WORKERSCOMP'
      }
    })
  ;
})();
