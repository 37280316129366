(function () {
  'use strict';

  angular
    .module('vhr.directives')
    .directive('vhrFootnotes', vhrCmp);

  vhrCmp.$inject = ['$timeout', 'vhrFootnoteSrv'];

  function vhrCmp ($timeout, vhrFootnoteSrv) {
    var component = {
      restrict: 'E',
      link: postLink,
      scope: {
        context: '@'
      }
    };

    return component;

    function postLink (scope, element) {
      scope.$watch(function () { return vhrFootnoteSrv.count(scope.context); }, function () {
        var footnotes = vhrFootnoteSrv.fetch(scope.context);
        var li = angular.element('<li>');
        var ol = angular.element('<ol>');

        footnotes.forEach(function (footnote, index) {
          var position = index + 1;
          ol.append((li.clone()).text(footnote).attr('id', scope.context + '_' + position));
        });

        element.empty();
        if (footnotes.length) {
          element.append(ol);
        }
      });
    }
  }
})();
