(function () {
  'use strict';

  angular
    .module('vhr')
    .controller('CcdModalCtrl', CcdModalCtrl);

  CcdModalCtrl.$inject = [
    'VHR_REPORT',
    '$filter', '$log', '$mdDialog', '$q',
    'DownloaderSrv', 'psNotification', 'psReports',
    'vhrConfigSrv', 'vhrPrintSrv', 'vhrRecordCollectionSrv', 'vhrUserSrv', 'vhrXsl'
  ];

  function CcdModalCtrl (
    VHR_REPORT,
    $filter, $log, $mdDialog, $q,
    DownloaderSrv, psNotification, psReports,
    vhrConfigSrv, vhrPrintSrv, vhrRecordCollectionSrv, vhrUserSrv, vhrXsl
  ) {
    var vm = this;

    // If xslt fails, will output this string
    var NO_XML = 'No XML';

    // Messages
    var XML_NOT_AVAILABLE = 'Unable to retrieve the document.';

    // Properties
    vm.loaded = {
      printView: false,
      record: false,
      report: false
    };
    vm.documentXsl = null;
    vm.markup = null;
    vm.printSource = vhrConfigSrv.getPrintSource();
    vm.record = vhrRecordCollectionSrv.stashed();
    vm.recordQueued = false;
    vm.report = null;
    vm.reportParams = null;
    vm.user = vhrUserSrv;// Used in print template.
    vm.workspace = 'ccd-record-modal';

    // Methods
    vm.cancel = cancel;
    vm.dequeue = dequeue;
    vm.download = download;
    vm.print = print;
    vm.queue = queue;
    vm.userCanDownloadDoc = vhrUserSrv.canDownloadOriginalDocuments();

    activate();

    // Implementation

    function activate () {
      vm.recordQueued = (typeof vhrRecordCollectionSrv.find('printQueue', vm.record) !== 'undefined');
      loadReport()
        .then(function () {
          return loadReportData(angular.copy(vm.reportParams));
        })
        .then(function () {
          return loadXslDocument();
        })
        .then(function () {
          return loadDataArtifact();
        })
        .then(function () {
          return loadCcdArtifact();
        })
        .then(function () {
          vm.markup = vm.record.getDocument();
          if (vm.markup === NO_XML) {
            vm.markup = XML_NOT_AVAILABLE;
            return $q.reject();
          }
          return vm.markup;
        })
        .then(function () {
        // TODO see if loaded here works ok
          vm.loaded.record = true;
          vm.loaded.printView = true;
        })
        .catch(function (reason) {
          reason = reason || 'Failed to load the record.';
          psNotification.error(reason);
        })
        .finally(function () {
          vm.loaded.report = true;
        });
    }

    function cancel () {
      $mdDialog.cancel();
    }

    function dequeue () {
      vhrRecordCollectionSrv.construct('printQueue').remove(vm.record);
      vm.recordQueued = false;
    }

    function download () {
      DownloaderSrv.downloadXml(vm.record.getXmlDocument(), {
        filename: 'CCD_' + vm.record.getUri(),
        processors: []
      });
    }

    function loadCcdArtifact () {
      var artifactName = vm.record.getUri() + '.xml';

      return vm.report.getArtifact(vm.workspace, artifactName)
        .then(function (response) {
          return vm.record.setDocument(response.original_response, vm.documentXsl);
        })
        .catch(function (reason) {
          $log.debug(reason);
          return $q.reject('Failed to load the record CCD.');
        });
    }

    function loadDataArtifact () {
      var artifactName = vm.record.getUri() + '.json';

      return vm.report.getArtifact(vm.workspace, artifactName)
        .then(function (response) {
          var raw = response.pop();
          var rec = angular.extend({}, vm.record.raw, raw);
          return vm.record.set(rec);
        })
        .catch(function (reason) {
          $log.debug(reason);
          return $q.reject('Failed to load the record data.');
        });
    }

    function loadReport () {
      var reportId = vm.record.getReportId();

      return psReports.load(reportId, VHR_REPORT.CALLER, { initParams: vhrConfigSrv.getReportParameters(reportId) })
        .then(function (report) {
          vm.report = report;
          vm.reportParams = report.getParameterDefaults();
        })
        .catch(function (reason) {
          $log.debug(reason);
          return $q.reject('Failed to prepare the record.');
        });
    }

    function loadReportData (params) {
      params.records = {};
      params.records[vm.record.getUri()] = {
        store: vm.record.getStore(),
        view_type: vm.record.getType()
      };
      params = angular.extend({}, vm.record.getReportParams(), params);

      return vm.report.run(vm.workspace, params)
        .then(function () {
          return vm.report.results[vm.workspace].getPollPromise();
        })
        .catch(function (reason) {
          $log.debug(reason);
          return $q.reject('Failed to load the record.');
        });
    }

    function loadXslDocument () {
      return vhrXsl.getXslFile()// ("/ui/assets/xsl/cda/imat-cda.xsl", "ccd-record-detail")
        .then(function (xslResponse) {
          vm.documentXsl = xslResponse;
        })
        .catch(function (reason) {
          $log.debug(reason);
          return $q.reject('Failed to load the XSL file.');
        });
    }

    function print () {
      vhrPrintSrv.print();
    }

    function queue () {
      vhrRecordCollectionSrv.construct('printQueue').insert(vm.record);
      vm.recordQueued = true;
    }
  }
})();
