// DEV NOTES
// TODO: setup store to be in config? use mpi-data instead of mpi-persona?
(function () {
  'use strict';

  angular.module('queries')
    .controller('QueryBuilderCtrl', QueryBuilderCtrl);

  QueryBuilderCtrl.$inject = [
    'QUERY',
    '$filter', '$log', '$mdDialog', '$q', '$scope', '$state', 'imatConfig', 'psNotification', 'QueriesSrv', 'ResultDisplaySrv'
  ];

  function QueryBuilderCtrl (
    QUERY,
    $filter, $log, $mdDialog, $q, $scope, $state, imatConfig, psNotification, QueriesSrv, ResultDisplaySrv
  ) {
    var vm = this;

    $scope.FILTER_CATEGORY_LAB = QUERY.FILTER_CATEGORY_LAB;
    $scope.FILTER_CATEGORY_SET = QUERY.FILTER_CATEGORY_SET;

    // Properties
    vm.banner = {
      icon: 'info',
      message: 'Fill out your search criteria below and then click on <b>VIEW RESULTS</b>.'
    };
    vm.cancelSearch = false;
    vm.fields = {};
    vm.filters = {};
    vm.filterCategory = QUERY.FILTER_CATEGORY_DEFAULT;
    vm.filterCategories = [
      QUERY.FILTER_CATEGORY_CODE_SETS,
      QUERY.FILTER_CATEGORY_DATE,
      QUERY.FILTER_CATEGORY_DEFAULT,
      QUERY.FILTER_CATEGORY_LAB,
      QUERY.FILTER_CATEGORY_NLP,
      QUERY.FILTER_CATEGORY_NUMERIC,
      QUERY.FILTER_CATEGORY_SET,
      QUERY.FILTER_CATEGORY_TEXT
    ];
    vm.helpURL = imatConfig.get('apps.queries.customerHelpLink');
    vm.loading = {
      filters: true,
      results: false
    };
    vm.operators = [];
    vm.query = QueriesSrv.query;
    vm.queries = QueriesSrv.queries;
    vm.queryResult = QueriesSrv.result;
    // TODO: setup store to be in config? use mpi-data instead of mpi-persona?
    vm.resultDisplay = ResultDisplaySrv.resultDisplay;
    vm.stores = [];

    // Methods
    vm.cancelRunQuery = cancelRunQuery;
    vm.addGroup = addGroup;
    vm.addRule = addRule;
    vm.changeFilterCategory = changeFilterCategory;
    vm.codeSearch = codeSearch;
    vm.deleteQuery = deleteQuery;
    vm.formatResultDisplayField = formatResultDisplayField;
    vm.isQuerySaved = isQuerySaved;
    vm.newQuery = newQuery;
    vm.removeGroup = removeGroup;
    vm.removeRule = removeRule;
    vm.resetResultDisplay = resetResultDisplay;
    vm.resetValues = resetValues;
    vm.runQuery = runQuery;
    vm.saveQuery = saveQuery;
    vm.searchFilters = searchFilters;
    vm.searchResultDisplayFields = searchResultDisplayFields;
    vm.showQueryInfo = showQueryInfo;
    vm.transformChip = transformChip;
    vm.updateFieldName = updateFieldName;
    vm.viewResults = viewResults;

    activate();

    function activate () {
      setupFilters();
      setupOperators();
      setupStores();

      $scope.$watch(function () { return vm.query.stores; }, function (curr, prev) {
        var currAllIdx;
        var currStores = curr;
        var prevStores = prev;

        if (angular.isArray(currStores)) {
          if (currStores.length === 0) {
            vm.query.stores = [QUERY.ALL_STORES];
          } else if (currStores.length > 1) {
            currAllIdx = currStores.indexOf(QUERY.ALL_STORES);
            if (currAllIdx >= 0) {
              if (prevStores && prevStores.indexOf(QUERY.ALL_STORES) >= 0) {
                vm.query.stores.splice(currAllIdx, 1);
              } else if (prevStores) {
                vm.query.stores = [QUERY.ALL_STORES];
              }
            }
          }
        }
      });
    }

    function addGroup () {
      vm.query.expression.groups.push({
        operator: 'AND',
        filter: {
          selected: '',
          searchText: ''
        },
        rules: [
        ]
      });
      return true;
    }

    function addRule (group) {
      var rule = angular.copy(group.filter.selected);

      // if selection isn't empty, add in selected filter
      if (rule && rule !== '') {
        QueriesSrv.getFilterProperties(rule.type)
          .then(function (properties) {
            angular.extend(rule, properties);
            group.rules.push(rule);
          })
          .finally(function () {
            group.filter.selected = '';
          // $log.debug(group);
          // $log.debug(vm.query);
          });
      }
    }

    function cancelRunQuery () {
      vm.cancelSearch = true;
    }

    function changeFilterCategory (group) {
      if (!group.filter.selected && group.filter.searchText) {
        group.filter.searchText = '';
      }
    }

    function codeSearch (searchText, type, lookupValue) {
      return QueriesSrv.codeSearch(searchText, type, lookupValue)
        .then(function (codes) {
          if (type === 'NLP_CLINICAL') {
            angular.forEach(codes, function (code) {
              code.title = code.extract.snmcode;
              code.description = code.extract.snmterm;
            });
          }
          return codes;
        })
        .catch(function (errorResponse) {
          return $q.reject(errorResponse);
        });
    }

    function deleteQuery () {
      var confirm = $mdDialog.confirm()
        .title('Confirm Delete')
        .textContent('Delete query ' + vm.query.name + '?')
        .ariaLabel('Confirm Delete')
        .ok('Delete')
        .cancel('Cancel');

      $mdDialog.show(confirm)
        .then(function () {
          QueriesSrv.deleteQuery(vm.query.id)
            .then(function () {
              psNotification.success('Query deleted.');
            }, function (errorResponse) {
              psNotification.error('Unable to delete the query.');
              $log.debug('Deleting the query failed', errorResponse);
            });
        });
    }

    function formatResultDisplayField (type, index) {
      // Replace spaces with _ for valid field extract name
      vm.resultDisplay[type].fields[index] = vm.resultDisplay[type].fields[index].replace(/\s/g, '_');
    }

    function isQuerySaved () {
      return vm.query.saved;
    }

    function newQuery () {
      QueriesSrv.resetQuery();
    }

    function removeGroup (groupIndex) {
      vm.query.expression.groups.splice(groupIndex, 1);
    }

    function removeRule (group, ruleIndex) {
      group.rules.splice(ruleIndex, 1);
    }

    function resetResultDisplay () {
      ResultDisplaySrv.resetFields();
    }

    function resetValues () {
      vm.query.expression.groups.forEach(function (group) {
        group.rules.forEach(function (rule) {
          rule.value = angular.copy(rule.valueDefault);
          rule.operator = rule.operatorDefault;
          if (rule.nlpFields) {
            rule.nlpFields = angular.copy(rule.nlpFieldsDefault);
          }
        });
      });
    }

    function runQuery () {
      var parameters = {
        q: vm.query.built,
        store: vm.query.stores,
        limit: QUERY.PAGE_SIZE,
        usesession: true,
        fields: ResultDisplaySrv.getFields(QUERY.RESULT_DISPLAY_DEFAULT, true) // get combined - required & user selected
      };
      vm.loading.results = true;
      QueriesSrv.runQuery(parameters)
        .then(function (results) {
          if (results.length > 0 && !vm.cancelSearch) {
            $state.go('app.queries.results');
          } else {
            if (vm.cancelSearch) {
              vm.cancelSearch = false;
              psNotification.show('Query cancelled.');
            } else {
              psNotification.warn('No results found.');
            }
          }
        })
        .catch(function (errorResponse) {
          if (errorResponse.indexOf('Missing required parameter q.') > -1) {
            errorResponse = 'Query filter values are empty.';
          }
          psNotification.error(errorResponse);
          return $q.reject(errorResponse);
        })
        .finally(function () {
          vm.loading.results = false;
        });
    }

    function runQuickFilterQuery (quickFilter) {
      // Use patientidn quickfilter to return unique patient list
      // SET documentation: /ui/documentation/#/reports/basic/sets
      // create set name for record hit results
      var setName = QUERY.SET_PREFIX + Date.now(); // [not sure if what set per name yet] + '_' + vm.query.name.replace(' ', '_');
      var recordParams = {
        q: vm.query.built,
        store: vm.query.stores,
        limit: 1,
        usesession: true,
        fields: 'uri',
        // add quickfilter to set param
        outputset: setName + '(' + quickFilter + ')',
        result_display: quickFilter
      };

      // Patient search with set - search?q=()FILTER(PatientIDN:SET:LastNameSmith)&usesession&fields=mpid&store=mpi-persona
      var patientParams = {
        q: '()FILTER(' + quickFilter + ':SET:' + setName + ')',
        store: ResultDisplaySrv.getStores(quickFilter),
        limit: QUERY.PAGE_SIZE,
        usesession: true,
        fields: ResultDisplaySrv.getFields(quickFilter, true),
        result_display: quickFilter,
        initial_query: recordParams.q,
        initial_store: recordParams.store
      };

      vm.loading.results = true;
      QueriesSrv.runQuery(recordParams)
        .then(function (initialResults) {
          // check if have results and cancel not clicked on
          if (initialResults.length > 0 && !vm.cancelSearch) {
            return true;
          } else if (vm.cancelSearch) {
            vm.cancelSearch = false;
            psNotification.show('Query cancelled.');
          } else {
            psNotification.warn('No results found.');
          }
          return false;
        })
        .then(function (proceed) {
          if (proceed) {
            return QueriesSrv.runQuery(patientParams)
              .then(function (secondResults) {
                if (secondResults.length > 0 && !vm.cancelSearch) {
                  $state.go('app.queries.results');
                } else if (vm.cancelSearch) {
                  vm.cancelSearch = false;
                  psNotification.show('Query cancelled.');
                } else {
                  psNotification.warn('No results found.');
                }
              });
          }
        })
        .catch(function (errorResponse) {
          if (errorResponse.indexOf('Missing required parameter q') > -1) {
            errorResponse = 'Query filter values are empty';
          }
          psNotification.error(errorResponse);
          return $q.reject(errorResponse);
        })
        .finally(function () {
          vm.loading.results = false;
        });
    }

    function saveQuery () {
      // 1: build query first, check if needs default query syntax
      // 2: prompt user to enter name etc
      QueriesSrv.buildQuery(vm.query)
        .then(function (builtExpression) {
        // populate query object built property with new syntax
          vm.query.built = (builtExpression.length > 0) ? builtExpression : QUERY.DEFAULT_QUERY;
          // update fields with current result display selection
          vm.query.fields = ResultDisplaySrv.getFields(vm.query.resultDisplay, false);
          $mdDialog.show({
            title: 'Save your query',
            controller: 'saveQueryDialogCtrl',
            controllerAs: 'vm',
            locals: { query: vm.query },
            templateUrl: '/ui/queries/save-query/save-query.html',
            parent: angular.element(document.body)
          }).then(function (response) {
            if (response.query.built && response.query.id && !response.save_as) {
              QueriesSrv.updateQuery(response.query)
                .then(function () {
                  psNotification.success('Query saved.');
                  response.query.saved = true;
                  QueriesSrv.setQuery(response.query);
                  QueriesSrv.setActiveId('query_' + response.query.id); // update active id for sidenav selection
                })
                .catch(function (error) {
                  $log.debug('Unable to save query', error);
                  psNotification.error('Unable to save the query.');
                });
            } else {
              if (response.query.built) {
                QueriesSrv.saveQuery(QUERY.TYPE, response.query)
                  .then(function (res) {
                    psNotification.success('Query saved.');
                    response.query.saved = true;
                    response.query.id = res;
                    QueriesSrv.setQuery(response.query);
                    QueriesSrv.setActiveId('query_' + response.query.id); // update active id for sidenav selection
                  })
                  .catch(function (error) {
                    $log.debug('Unable to save query', error);
                    psNotification.error('Unable to save the query.');
                  });
              }
            }
          });
        });
    }

    function searchResultDisplayFields (searchText) {
      return QueriesSrv.searchFieldExtracts(searchText)
        .catch(function (errorResponse) {
          return $q.reject(errorResponse);
        });
    }

    function searchFilters (searchText, filterCategory) {
      if (filterCategory === QUERY.FILTER_CATEGORY_LAB) {
        return QueriesSrv.searchFilterTypesForLabs(searchText);
      }
      return QueriesSrv.searchFilterTypes(filterCategory, searchText, vm.filters);
    }

    function setupFilters () {
      vm.loading.filters = true;
      QueriesSrv.getFields()
        .then(function (fields) {
          vm.fields = fields;
          return QueriesSrv.getFilters();
        })
        .then(function (filters) {
          vm.filters = filters.concat(vm.fields);
          vm.filters = $filter('orderBy')(vm.filters, 'label');
          return vm.filters;
        })
        .catch(function (errorResponse) {
          psNotification.error('Unable to load the filters.');
          return $q.reject(errorResponse);
        })
        .finally(function () {
          vm.loading.filters = false;
        });
    }

    function setupOperators () {
      vm.operators = [
        {
          value: 'AND',
          label: 'All',
          itemLabel: 'Require <strong>all</strong> (AND)',
          groupLabel: '<strong>All</strong> of the group expressions above are required',
          filterLabel: '<strong>All</strong> of the filters in this group are required',
          desc: 'All of the items are included'
        },
        {
          value: 'OR',
          label: 'Any',
          itemLabel: 'Include <strong>any</strong> (OR/IN)',
          groupLabel: '<strong>Any</strong> of the group expressions above are included',
          filterLabel: '<strong>Any</strong> of the filters in this group are included',
          desc: 'Any of the items are included'
        },
        {
          value: 'NOT',
          label: 'None',
          itemLabel: 'Include <strong>none</strong> (NOT)',
          groupLabel: '<strong>None</strong> of the group expressions above are included',
          filterLabel: '<strong>None</strong> of the filters in this group are included',
          desc: 'None of the items are included'
        }
      ];

      return true;
    }

    function setupStores () {
      QueriesSrv.getStores()
        .then(function (stores) {
          vm.stores = stores;
        })
        .catch(function (errorResponse) {
          return $q.reject(errorResponse);
        });
    }

    function showQueryInfo () {
      $mdDialog.show({
        controller: 'QueryInfoModalCtrl',
        controllerAs: 'ctrl',
        locals: { queryId: vm.query.id },
        templateUrl: '/ui/queries/query-builder/query-info-modal.html',
        parent: angular.element(document.body),
        clickOutsideToClose: true
      });
    }

    function transformChip (chip) {
      // If an object, it is a chip so return it
      if (angular.isObject(chip)) {
        return chip;
      }
      // Otherwise reject
      return null;
    }

    function updateFieldName (rule) {
      QueriesSrv.updateFieldName(rule.nlpFields)
        .then(function (fieldName) {
          rule.field = fieldName;
          return fieldName;
        })
        .catch(function (errorResponse) {
          return $q.reject(errorResponse);
        });
    }

    function viewResults () {
      QueriesSrv.buildQuery(vm.query)
        .then(function (builtExpression) {
        // populate query object built with new syntax
          vm.query.built = (builtExpression.length > 0) ? builtExpression : QUERY.DEFAULT_QUERY;
          if (vm.query.resultDisplay === QUERY.RESULT_DISPLAY_DEFAULT) {
            runQuery();
          } else {
            runQuickFilterQuery(vm.query.resultDisplay);
          }
        });
    }
  }
})();
