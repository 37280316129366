(function () {
  'use strict';

  angular
    .module('vhr.directives')
    .directive('vhrMedInsPlan', vhrDir);

  vhrDir.$inject = [];

  function vhrDir () {
    var directive = {
      link: { pre: postLink },
      restrict: 'E',
      scope: {
        json: '='
      },
      templateUrl: '/ui/vhr/directives/uiGridTemplates/medicalInsurance/medical-insurance-plan.html'
    };
    var json = {
      group_name: '',
      group_number: '',
      plan_name: '',
      policy_number: ''
    };

    return directive;

    function postLink (scope, element, attrs, ctrl) { // eslint-disable-line no-unused-vars
      var plan = angular.extend({}, json, scope.json || {});

      scope.planName = plan.plan_name;
      scope.planGroupName = plan.group_name;
      scope.planGroupNumber = plan.group_number;
      scope.planPolicyNumber = plan.policy_number;
    }
  }
})();
