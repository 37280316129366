(function () {
  'use strict';

  angular.module('queries.utils')
    .directive('cxmlHighlight', cxmlHighlight);

  cxmlHighlight.$inject = ['$q', '$interval'];

  function cxmlHighlight ($q, $interval) {
    var directive = {
      restrict: 'A',
      link: postLink,
      scope: {
        path: '=cxmlHighlight',
        start: '=cxmlStart',
        end: '=cxmlEnd',
        scroll: '=cxmlScroll'
      }
    };

    return directive;

    function postLink (scope, element) {
      scope.$watch('path', function (path) {
        var xpath = getXpath(path);

        if (xpath) {
          scope.$applyAsync(function () {
            highlightContent(element, xpath, scope.start, scope.end).then(scrollToHighlight);
          });
        }
      });

      scope.$watch('scroll', function (curr, prev) {
        if (curr === true && prev === false) {
          scrollToHighlight();
        }
      });
    }

    function getXpath (path) {
      return path && angular.isString(path) ? '/' + path.replace(/\/cxml\/document\[1\]/g, '').replace(/csmk:div/g, 'csmkdiv') : '';
    }

    function getXpathElement (element, xpath, start, end, xpathElement, counter, deferred) {
      var locationResult = document.evaluate(xpath, element[0], null, XPathResult.ANY_TYPE, null);
      xpathElement = locationResult.iterateNext();

      if (xpathElement || counter > 25) {
        if (processResult(xpathElement, start, end)) {
          deferred.resolve();
        } else {
          deferred.reject();
        }
      } else {
        getXpathElement(element, xpath, start, end, xpathElement, ++counter, deferred);
      }
    }

    function highlightContent (element, xpath, start, end) {
      var deferred = $q.defer();

      // Remove existing highlights.
      element[0].innerHTML = element.html().replace(/<span class="highlight">|<\/span>/g, '');
      getXpathElement(element, xpath, start, end, null, 0, deferred);

      return deferred.promise;
    }

    function processResult (xpathElement, start, end) { // eslint-disable-line complexity
      var elementCount;
      var highlightEl, highlightHtml, markup, nodes, node, section;
      var hstart = '<span class="highlight">';
      var hend = '</span>';
      var parentNode, markupNode;

      try {
        if (xpathElement) {
          if ((start === '' || start === undefined) && (end === '' || end === undefined)) {
            // CASE 1: if no start or end provided, then highlight all content from the xpath node, ex: measurements
            // TODO verify doesn't cause memory leak
            highlightEl = angular.element(xpathElement);
            highlightHtml = highlightEl.html();
            highlightHtml = hstart + highlightHtml + hend;
            highlightEl.empty();
            highlightEl.append(highlightHtml);
          } else {
            elementCount = xpathElement.childElementCount || (xpathElement.children) ? xpathElement.children.length : undefined;
            if (elementCount === undefined) {
              // CASE 2: text()[x] path provided
              markup = xpathElement.nodeValue;
            } else {
              // CASE 3: something not expected, should not get here, code left in from previous setup
              // elementCount is zero, but had start/end
              // elementCount is > 0, so missing text()[x] in xpath
              if (elementCount === 0) {
                markup = xpathElement.innerHTML;
              } else {
                // TODO one of the child nodes has content, need to fix this if still needed so grabs from correct node
                nodes = xpathElement.childNodes;
                node = nodes.item(nodes.length - 1);// get last node
                markup = node.nodeValue;
              }
            }
            // highlight section
            section = markup.substring(start, end);
            markup = markup.substring(0, start) + hstart + section + hend + markup.substring(end);
            parentNode = xpathElement.parentNode;
            markupNode = document.createTextNode(markup);
            parentNode.replaceChild(markupNode, xpathElement);
            parentNode.innerHTML = parentNode.innerHTML.replace('&lt;span class="highlight"&gt;', hstart).replace('&lt;/span&gt;', hend);
          }
        }
        return true;
      } catch (e) {
        // Ignored.
        return false;
      }
    }

    function scrollToHighlight () {
      var interval;
      var origContent = document.querySelector('.js_origDoc');
      var highlight = origContent.querySelector('span.highlight');
      var delay = 50;
      var limit = 2000;
      var ticks = 0;
      var top;

      if (origContent && highlight) {
        top = Math.max(0, highlight.offsetTop - Math.round(origContent.offsetHeight / 2));

        interval = $interval(function () {
          if (origContent.scrollTop === top || ticks >= limit) {
            ticks = 0;
            $interval.cancel(interval);
          } else {
            ticks += delay;
            origContent.scrollTop = top;
          }
        }, delay);
      }
    }
  }
})();
