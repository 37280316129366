(function () {
  'use strict';

  angular.module('account')
    .controller('AccountSubscriptionsCtrl', AccountSubscriptionsCtrl);

  AccountSubscriptionsCtrl.$inject = ['$q', '$scope', '$transition$', 'imatConfig', 'psNotification', 'subscriptionsSrv'];

  function AccountSubscriptionsCtrl ($q, $scope, $transition$, imatConfig, psNotification, subscriptionsSrv) {
    var vm = this;

    vm.settings = { alerts: [] };

    vm.reset = reset;
    vm.submit = submit;
    vm.test = test;

    var ACCOUNT_NOTIFICATION_SUBMIT_SUCCESS = 'User subscription settings updated.';
    var ACCOUNT_NOTIFICATION_SUBMIT_ERROR = 'Unable to update user subscription settings.';
    var ACCOUNT_NOTIFICATION_TEST_SUCCESS = 'Test notification requested.';
    var ACCOUNT_NOTIFICATION_TEST_ERROR = 'Unable to test user subscription settings.';
    var ACCOUNT_NOTIFICATION_MISSING_REQUIRED = 'At least one means of contact and one subscription is required.';
    var ACCOUNT_NOTIFICATION_NO_SETTINGS = 'Invalid subscription settings detected. At least one means of contact and one subscription is required.';
    var ACCOUNT_NOTIFICATION_NO_REPORTS = 'No subscribable reports. Please contact your system administrator.';
    var ACCOUNT_NOTIFICATION_SUBSCRIPTION_LOAD_FAIL = 'Unable to load subscription information.';

    vm.noSettingsMsg = ACCOUNT_NOTIFICATION_NO_SETTINGS;
    vm.noReportsMsg = ACCOUNT_NOTIFICATION_NO_REPORTS;

    activate();

    function activate () {
      subscriptionsSrv.loadConfig()
        .then(function (config) {
          vm.contactMeans = config.contactMeans;
        });

      $scope.$watch(function () { return vm.settings; }, function (curr) {
        vm.changes = !angular.equals(curr, vm.settingsCopy);
        vm.submittable = checkValidity();
      }, true);

      load()
        .catch(function () {
          psNotification.error(ACCOUNT_NOTIFICATION_SUBSCRIPTION_LOAD_FAIL);
        });
    }

    function checkValidity () {
      // Requires at least one contact method and at least one subscription.
      var contactMethod = vm.settings.email || vm.settings.phone;
      var subscribedReports = vm.settings.alerts.filter(function (alert) { return alert.subscribed; }).length > 0;
      return contactMethod && subscribedReports;
    }

    function load () {
      vm.loading = true;

      return subscriptionsSrv.checkNotificationSettings()
        .then(function (settingsExist) {
          vm.settingsExist = settingsExist;
          return (settingsExist ? subscriptionsSrv.getUserNotificationSettings() : subscriptionsSrv.getReports());
        })
        .then(function (settings) {
          vm.changes = false;
          vm.settings = angular.extend({ alerts: [] }, settings);
          vm.settingsCopy = angular.copy(vm.settings);
          return settings;
        })
        .finally(function () {
          vm.loading = false;
        });
    }

    function reset () {
      vm.changes = false;
      vm.settings = angular.copy(vm.settingsCopy);
    }

    function submit () {
      if (!checkValidity()) {
        psNotification.error(ACCOUNT_NOTIFICATION_MISSING_REQUIRED);
        return;
      }

      vm.isEditing = true;
      subscriptionsSrv.editUserNotificationSettings(vm.settings)
        .then(function (response) {
          if (response && response.success === true) {
            psNotification.success(ACCOUNT_NOTIFICATION_SUBMIT_SUCCESS);
            load();
          } else {
            return $q.reject();
          }
        })
        .catch(function () {
          psNotification.error(ACCOUNT_NOTIFICATION_SUBMIT_ERROR);
        })
        .finally(function () {
          vm.isEditing = false;
        });
    }

    function test () {
      subscriptionsSrv.testNotifications()
        .then(function () {
          psNotification.success(ACCOUNT_NOTIFICATION_TEST_SUCCESS);
        })
        .catch(function () {
          psNotification.error(ACCOUNT_NOTIFICATION_TEST_ERROR);
        });
    }
  }
})();
