(function () {
  'use strict';

  angular.module('admin')
    .controller('DeleteUserModalCtrl', DeleteUserModalCtrl);

  DeleteUserModalCtrl.$inject = ['$mdDialog', '$scope', 'modalUser'];
  function DeleteUserModalCtrl ($mdDialog, $scope, modalUser) {
    $scope.deleteConfirm = '';
    $scope.user = modalUser;

    $scope.confirm = function () {
      $mdDialog.hide(true);
    };

    $scope.cancel = function () {
      $mdDialog.cancel();
    };
  }
})();
