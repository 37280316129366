(function () {
  'use strict';

  angular.module('imat.services')
    .factory('idleTimerSrv', idleTimerSrv);

  idleTimerSrv.$inject = ['APP_TIMEOUT', '$document', '$interval', '$rootScope'];

  function idleTimerSrv (APP_TIMEOUT, $document, $interval, $rootScope) {
    var E_SESSION_IDLE = 'ImatSessionIdle';
    var E_SESSION_RESUMED = 'ImatSessionResumed';
    var E_SESSION_EXPIRED = 'ImatSessionExpired';
    var expiration; // eslint-disable-line no-unused-vars
    var listener;
    var poller;
    var timeInSession; // eslint-disable-line no-unused-vars
    var timeUntilIdle;
    var timer;

    var service = {
      duration: 0,
      remaining: 0,

      cancel: cancelTimer,
      listen: listenForMousemove,
      set: setTimer
    };

    return service;

    //= ================================

    function cancelTimer () {
      if (timer) {
        $interval.cancel(timer);
        timer = null;
      }
      if (poller) {
        $interval.cancel(poller);
        poller = null;
      }
      service.duration = service.remaining = 0;
    }

    function setTimer (sessionExpiration) {
      if (!sessionExpiration) {
        return restartTimer();
      }

      if (resetTimer(sessionExpiration)) {
        expiration = sessionExpiration;
        service.duration = service.remaining = timeUntilIdle;
        return restartTimer();
      }

      return false;
    }

    // ---------------------------------

    function calcTimeInSession (sessionExpiration) {
      var slippingslippingslipping = new Date();
      var intothefuturrrrrrrrrrrre = new Date(sessionExpiration);
      // sessionExpiration should be the timestamp whereupon the identity
      // provider (e.g., LDAP) will expire the session.
      return Math.floor((intothefuturrrrrrrrrrrre - slippingslippingslipping) / 1000);
    }

    function calcTimeUntilIdle (timeInSession) { // eslint-disable-line no-shadow
      // If the user were to stop interacting immediately, he would be
      // considered idle after timeInSeconds seconds, which is the time between
      // now and the session expiration. However, because we need to be logged
      // in to log out, we subtract some buffer time to make sure that we can
      // do the needful and still log out.
      return Math.max(0, Math.floor(timeInSession - APP_TIMEOUT.BUFFER));
    }

    function handleMousemove (e) {
      if (e.originalEvent && e.originalEvent.movementX === 0 && e.originalEvent.movementY === 0) {
        return; // Fix for Chrome desktop notifications, triggering mousemove event.
      }

      $interval.cancel(listener);
      $document.find('html').off('mousemove', handleMousemove);
      $rootScope.$emit(E_SESSION_RESUMED);
    }

    function listenForMousemove () {
      $document.find('html').on('mousemove', handleMousemove);
      // Start the logout countdown...
      listener = $interval(function () {
        $interval.cancel(listener);
        $document.find('html').off('mousemove', handleMousemove);
        $rootScope.$emit(E_SESSION_EXPIRED);
      }, APP_TIMEOUT.COUNTDOWN * 1000, 1, false);
    }

    function resetTimer (sessionExpiration) {
      var tempTimeInSession = calcTimeInSession(sessionExpiration);
      var tempTimeUntilIdle = calcTimeUntilIdle(tempTimeInSession);

      if (tempTimeUntilIdle && tempTimeInSession > APP_TIMEOUT.BUFFER) {
        timeInSession = tempTimeInSession;
        timeUntilIdle = tempTimeUntilIdle;
        return true;
      }
      return false;
    }

    function restartTimer () {
      cancelTimer();
      timer = $interval(timeoutTimer, timeUntilIdle * 1000, 1, false);
      poller = $interval(function () { --service.remaining; }, 1000, 0, false);
      return true;
    }

    function timeoutTimer () {
      cancelTimer();
      $rootScope.$emit(E_SESSION_IDLE);
    }
  }
})();
