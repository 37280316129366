(function () {
  'use strict';

  angular.module('queries.services')
    .factory('NlpSrv', NlpSrv);

  NlpSrv.$inject = ['$http', '$q'];

  function NlpSrv ($http, $q) {
    var loaded = {
      sections: false
    };
    var sections;
    var service = {
      buildFieldName: buildFieldName,
      getRefinementOptions: getRefinementOptions,
      loadSections: loadSections
    };

    return service;

    function buildFieldName (options) {
      var type = options.type;
      var section = options.section;
      var subject = options.subject;
      var certainty = options.certainty;
      var polarity = options.polarity;
      var concept = options.concept;
      var qualifier = '';

      section = (section.length > 0) ? '.' + section : '';
      subject = (subject.length > 0) ? subject : '';
      certainty = (certainty.length > 0) ? '.' + certainty : '';
      concept = (concept.length > 0) ? '.' + concept : '';
      polarity = (polarity.length > 0) ? '.' + polarity + '.' : '';
      qualifier += (section.length > 0) ? 's' : '';
      qualifier += (concept.length > 0) ? 'c' : '';
      qualifier += (certainty.length > 0) ? 'a' : '';
      qualifier = (qualifier.length > 0) ? '.' + qualifier : '';

      return $q.resolve(type + qualifier + polarity + subject + section + concept + certainty);
    }

    function getRefinementOptions () {
      return loadSections()
        .then(function (sectionList) {
          sections = sectionList;
          return {
            certainties: _getCertainties(),
            concepts: _getConcepts(),
            polarities: _getPolarities(),
            sections: _getSections(),
            subjects: _getSubjects()
          };
        })
        .catch(function (errorResponse) {
          return $q.reject(errorResponse);
        });
    }

    function loadSections () {
      if (loaded.sections) {
        return $q.resolve(sections);
      }
      loaded.sections = true;
      return $http.get('/extensions/clinical-reports/conf/sections.xml')
        .then(function (response) {
          sections = response.data.sections.section;
          return sections;
        })
        .catch(function (errorResponse) {
          loaded.sections = false;
          return $q.reject(errorResponse);
        });
    }

    function _getCertainties () {
      return [
        { label: 'All certainties', value: '' },
        { label: 'Certain', value: 'c' },
        { label: 'Uncertain', value: 'u' }
      ];
    }

    function _getConcepts () {
      return [
        { label: 'All concepts', value: '' },
        { label: 'Allergies', value: 'allergy' },
        { label: 'Devices', value: 'device' },
        { label: 'Labs', value: 'lab' },
        { label: 'Measures', value: 'Measure' },
        { label: 'Medications', value: 'med' },
        { label: 'Problems', value: 'problem' },
        { label: 'Procedures', value: 'procedure' },
        { label: 'Symptoms', value: 'symptom' }
      ];
    }

    function _getPolarities () {
      return [
        { label: 'Positive', value: 'p' },
        { label: 'Negative', value: 'n' },
        { label: 'Unknown', value: 'u' }
      ];
    }

    function _getSections () {
      return sections;
    }

    function _getSubjects () {
      return [
        { label: 'Patient', value: 'patient' },
        { label: 'Family', value: 'family' }
      ];
    }
  }
})();
