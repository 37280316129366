(function () {
  'use strict';

  angular
    .module('vhr.directives')
    .directive('vhrPdf', vhrPdf);

  vhrPdf.$inject = ['$timeout', '$window', 'vhrPdfSrv'];

  function vhrPdf ($timeout, $window, vhrPdfSrv) {
    var service = {
      link: postLink,
      restrict: 'E',
      scope: {
        src: '=?',
        loader: '=?',
        scale: '=?',
        page: '=?',
        ready: '=?'
      },
      transclude: true
    };

    return service;

    function postLink (scope, element, attrs, ctrl, transcludeFn) {
      var hasCanvas = false;
      var hasTmpl = false;
      var renderTask;
      var transcope;

      if (typeof attrs.loader !== 'undefined' && typeof attrs.src !== 'undefined') {
        throw new Error('The vhrPdf directive must not have both `loader` and `src` attributes.');
      }

      transcludeFn(function (tnodes, tscope) {
        angular.forEach(tnodes, function (node) {
          var tagName = node.tagName;

          hasTmpl = hasTmpl || !!tagName;
          hasCanvas = hasCanvas || tagName === 'CANVAS';
        });
        element.append(tnodes);
        transcope = tscope;
      });

      if (!hasCanvas && typeof attrs.noCanvas === 'undefined') {
        element.append('<canvas class="rotate0"></canvas>');
      }

      transcope.pdfAborted = false;
      transcope.pdfCanvas = element[0].querySelector('canvas');
      transcope.pdfDocument = null;
      transcope.pdfElement = element[0];
      transcope.pdfFitPage = (attrs.scale === 'fit-page');
      transcope.pdfFitWidth = (attrs.scale === 'fit-width');
      transcope.pdfLoaded = false;
      transcope.pdfPage = (isFinite(scope.page) ? parseInt(scope.page, 10) : 1);
      transcope.pdfReady = false;
      transcope.pdfScale = (scope.scale > 0 ? scope.scale : 1);
      transcope.pdfSrc = null;

      scope.$watchGroup(['loader.docId', 'src'], function (curr) {
        var loader = curr[0];
        var source = curr[1];

        transcope.pdfLoaded = false;
        transcope.pdfReady = false;

        if (loader) {
          scope.loader
            .then(afterLoad)
            .catch(function () {
            // angular.element(transcope.pdfCanvas).css({display: 'none'});
              transcope.pdfAborted = true;
            });
        } else if (PDFJS.isValidUrl(source, true)) {
          transcope.pdfSrc = source;
          vhrPdfSrv.get(scope.src)
            .then(afterLoad)
            .catch(function () {
            // angular.element(transcope.pdfCanvas).css({display: 'none'});
              transcope.pdfAborted = true;
            });
        } else {
          transcope.pdfAborted = true;
        }
      });

      // -------------------------------

      function afterLoad (pdf) {
        transcope.pdfDocument = pdf;
        transcope.pdfLoaded = true;
        transcope.pdfReady = false;
        if (!hasTmpl) {
          transcope.$watch('pdfReady', function (curr) {
            if (curr) {
              transcope.pdfDocument.getPage(1).then(render);
            }
          });
        }
        getReady();
        transcope.$digest();
        return pdf;
      }

      /**
       * Determines when the PDF is ready to be rendered.
       */
      function getReady () {
        var display, // eslint-disable-line no-unused-vars
          unwatchRender;

        // For a defined scale (non fit-* scales), we don't have
        // to measure any boxes so we can render immediately.
        if (!transcope.pdfFitWidth && !transcope.pdfFitPage) {
          transcope.$evalAsync('pdfReady = true');
          return;
        }

        // For fit-* scales, we have to measure a box that has size
        // only when it becomes visible. If the `ready` attribute
        // is undefined or is an integer, it is the delay between
        // calls to renderWhenVisible() (the default is 333ms).
        // Otherwise, the `ready` attribute represents the condition
        // under which the directive will become visible.

        display = element.css('dislay');// XXX Should we ever restore this?
        element.css({ display: 'block' });

        if (typeof attrs.ready === 'undefined' || parseInt(attrs.ready, 10) === scope.ready) {
          renderWhenVisible();
          return;
        }

        unwatchRender = scope.$watch('ready', function (curr) {
          if (curr) {
            transcope.$evalAsync(function (s) {
              s.pdfReady = true;
              unwatchRender();
            });
          }
        });

        function renderWhenVisible () {
          if (!!element[0].getBoundingClientRect().width) { // eslint-disable-line no-extra-boolean-cast
            transcope.$evalAsync('pdfReady = true');
          } else {
            $timeout(renderWhenVisible, scope.ready || 333, false);
          }
        }
      }

      /**
       * Basic rendering when the directive has no internal template
       */
      function render (page) {
        var parent = angular.element(transcope.pdfCanvas).parent();

        return renderPage(transcope, page, parent);
      }

      /**
       * Basic rendering, when not delegated to another directive.
       * Separated from render(), anticipating a future refactor.
       */
      function renderPage (aScope, page, container) {
        var clientRect,
          height,
          viewport;

        if (renderTask && renderTask._internalRenderTask.running) {
          renderTask._internalRenderTask.cancel();
          renderTask = null;
        }

        if (aScope.pdfFitPage) {
          height = $window.innerHeight || $window.document.documentElement.offsetHeight;
          viewport = page.getViewport(1);
          aScope.pdfScale = Math.max(vhrPdfSrv.PDF_SCALE_MIN, (height / viewport.height));
        }

        if (aScope.pdfFitWidth) {
          clientRect = container[0].getBoundingClientRect();
          viewport = page.getViewport(1);
          aScope.pdfScale = Math.max(vhrPdfSrv.PDF_SCALE_MIN, (clientRect.width / viewport.width));
        }

        viewport = page.getViewport(aScope.pdfScale);
        vhrPdfSrv.setCanvasDimensions($window, aScope.pdfCanvas, viewport.width, viewport.height);
        renderTask = page.render({ canvasContext: aScope.pdfCanvas.getContext('2d'), viewport: viewport });
        return renderTask;
      }

      // -------------------------------

      scope.$on('$destroy', function () {
        transcope.$destroy();
        transcope = null;
      });
    }
  }
})();
