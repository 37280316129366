(function () {
  'use strict';

  angular.module('queries')
    .controller('saveQueryDialogCtrl', saveQueryDialogCtrl);

  saveQueryDialogCtrl.$inject = ['$mdDialog', 'query'];

  function saveQueryDialogCtrl ($mdDialog, query) {
    var vm = this; // eslint-disable-line no-invalid-this

    vm.query = angular.copy(query);
    vm.cancelDialog = cancelDialog;
    vm.submitDialog = submitDialog;
    vm.save_as = false;

    function cancelDialog () {
      $mdDialog.cancel();
    }
    function submitDialog () {
      $mdDialog.hide({ query: vm.query, save_as: vm.save_as });
    }
  }
})();
