(function () {
  'use strict';

  angular.module('mpiApp', [
    /* Angular modules */
    'ngMaterial',

    /* 3rd Party Modules */
    'dndLists',
    'ui.codemirror',
    'ui.router',

    /* IMAT Modules */
    'imat.fhir', // FHIR_EXTENSION_URLS, FHIR_VALUE_SETS, IMAT_FHIR_EXTENSION, fhirExtensionFactory, fhirExtensionSrv, fhirResourceSrv, imatFhirSrv
    'imat.services', // dateTimeSrv, imatFieldsSrv, imatStoresSrv, psNotification

    /* App modules */
    'mpiApp.constants',
    'mpiApp.piLinkEditor',
    'mpiApp.personas',
    'mpiApp.services',
    'mpiApp.search'
  ]);
})();
