(function () {
  'use strict';

  angular.module('reports')
    .controller('ResultsCtrl', ResultsCtrl);

  ResultsCtrl.$inject = ['$mdDialog', '$mdSidenav', 'psNotification', 'searchSrv'];

  function ResultsCtrl ($mdDialog, $mdSidenav, psNotification, searchSrv) {
    var vm = this;

    // Properties
    vm.filterText = '';
    vm.isLoading = false;
    vm.results = [];

    // Methods
    vm.clearSelection = clearSelection;
    vm.deleteResult = deleteResult;
    vm.filterResults = filterResults;
    vm.getResults = getResults;
    vm.resetFilters = resetFilters;

    activate();

    function activate () {
      getResults();
    }

    function clearSelection () {
      if (vm.selected) {
        vm.selected = {};
      }
      $mdSidenav('resultDetails').close();
    }

    function deleteResult (resultObj) {
      var confirm = $mdDialog.confirm()
        .title('Confirm Delete')
        .textContent('Delete result ' + resultObj.name + '?')
        .ariaLabel('Confirm Delete')
        .ok('Delete')
        .cancel('Cancel');

      $mdDialog.show(confirm)
        .then(function () {
          // delete stuff for server and results array
          return true;
        })
        .then(function () {
          psNotification.success('Result deleted.');
        });
    }

    function filterResults (result) {
      var text;
      if (!vm.filterText) {
        return true;
      }
      // Check if entered input found in name, id or description of report (case insensitive)
      text = vm.filterText.toLowerCase();
      return (result.name.toLowerCase().indexOf(text) !== -1) ? true
        : (result.id.toLowerCase().indexOf(text) !== -1) ? true
          : (result.description.toLowerCase().indexOf(text) !== -1);
    }

    function getResults () {
      vm.isLoading = true;
      return searchSrv.getSearches({ admin: true, named_only: true })
        .then(function (response) {
          if (response.searches && response.searches.length) {
            vm.results = response.searches.filter(function (search) {
              return search.type === 'python';
            });
          }
        })
        .finally(function () {
          vm.isLoading = false;
        });
    }

    function resetFilters () {
      vm.filterText = '';
    }
  }
})();
