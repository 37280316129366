(function () {
  'use strict';

  angular
    .module('dashboardApp.test')
    .factory('dashboardFix', dashboardFix);

  dashboardFix.$inject = ['$q'];

  function dashboardFix ($q) {
    var service;

    service = {
      getFoo: getFoo
    };

    function getFoo (promise) {
      var fix = 'foo';
      return (promise) ? $q.resolve(fix) : fix;
    }

    return service;
  }
})();
