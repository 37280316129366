(function () {
  'use strict';

  angular.module('admin')
    .controller('AdminHomeCtrl', AdminHomeCtrl);

  AdminHomeCtrl.$inject = ['$http', '$mdDialog', '$state', 'licensesSrv', 'psNotification', 'userSession'];

  function AdminHomeCtrl ($http, $mdDialog, $state, licensesSrv, psNotification, userSession) {
    var vm = this;
    var byteCount = 1024;
    var docCountRoundAmount = 1000;
    var bitsInMegabit = 1048576;
    var sections = [
      { title: 'Data Management', description: 'Commits or deletes individual items as well as clears data from stores', state: 'app.admin.data-management' },
      { title: 'Uploader', description: 'Allows uploads of CSV files for searching', state: 'app.admin.uploader' },
      { title: 'Users', description: 'Configures and edits user accounts', state: 'app.admin.users' },
      { title: 'Groups', description: 'Configures and edits groups', state: 'app.admin.groups' },
      { title: 'Dashboards', description: 'Allows creating and editing of users\' dashboards', state: 'app.admin.dashboards' },
      { title: 'Security Collections', description: 'Allows administrators to give groups access to documents', state: 'app.admin.collections' },
      { title: 'Set Definitions', description: 'Creates collections of data that can be saved to use in other queries', state: 'app.admin.sets' },
      { title: 'Symbols', description: 'Defines a named symbol that resolves to a list of words or values', state: 'app.admin.symbols' },
      { title: 'Sharing', description: 'Shares reports and queries with other users', state: 'app.admin.sharing' },
      { title: 'Report Containers', description: 'Allows an administrative user to view report containers on the server', state: 'app.admin.report-containers' },
      { title: 'Access Tokens', description: 'Allows creation of password-like tokens for users', state: 'app.admin.access-tokens' },
      { title: 'Configuration', description: 'Provides access to several configuration files used for specific settings', state: 'app.admin.configuration' },
      { title: 'NLP Dictionary Editor', description: 'Customizes the NLP dictionary vocabulary', state: 'app.admin.ctakes' }
    ];

    // Properties
    vm.activateDisabled = false;
    vm.license = {
      email: '',
      licenseBody: '',
      licenseKey: '',
      upload: null
    };
    vm.licenses = [];

    // Methods
    vm.isValidLicense = isValidLicense;
    vm.activateLicense = activateLicense;

    activate();

    function activate () {
      vm.adminPages = sections.filter(function (section) {
        var stateDecl = $state.get(section.state);
        return !!stateDecl && userSession.hasRoles(stateDecl.data.roles);
      });

      if (userSession.hasRoles(['system'])) {
        licensesSrv.getLicenses()
          .then(function (res) {
            processLicenses(res);
          })
          .catch(function () {
            psNotification.error('Unable to retrieve the license list.');
          });
      }
    }

    function processLicenses (res) {
      if (!res) { return false; }
      vm.licenses = res.license_information.component;
      angular.forEach(vm.licenses, function (license) {
        setDocumentDisplay(license);
        setStoresDisplay(license);
        setIndexDisplay(license);

        license.valid = isValidLicense(license);
      });

      var ssLicense = vm.licenses.filter(function (value) {
        return value.component_name[0] === 'SearchServer' || value.component_name === 'SearchServer';
      })[0] || {};

      licensesSrv.loadDefaultLicense()
        .then(function () {
          vm.activateDisabled = ssLicense.valid;
        })
        .catch(function () {
          psNotification.error('Unable to retrieve the default license key.');
        });
    }

    function setDocumentDisplay (license) {
      license.usedNRefsString = numberDocs_(parseInt(license.used_nrefs, 10)) || 'N/A';
      license.nrefsRemaining = numberDocs_(parseInt(license.current_max_nrefs, 10) - license.used_nrefs);
      license.docWarning = isDocumentCountAlmostFull(license);
    }

    function setIndexDisplay (license) {
      if (license.current_max_data_mb && license.current_max_data_mb !== '0') {
        license.current_data_mb_remaining = license.current_max_data_mb - license.used_mb;
        license.maxDataMb = fileSizePS_(bitsInMegabit * license.current_max_data_mb);
        license.dataMbRemaining = fileSizePS_(bitsInMegabit * license.current_data_mb_remaining);
        license.dataWarning = isLicenseSizeAlmostFull(license);
      } else {
        license.maxDataMb = 'Unlimited';
        license.dataMbRemaining = 'N/A';
      }

      if (license.used_mb === '0' || license.used_mb === '') {
        license.used_mb = '< 1 MB';
      } else {
        license.used_mb = fileSizePS_(bitsInMegabit * license.used_mb);
      }
    }

    function setStoresDisplay (license) {
      license.used_feeds = license.used_feeds || 0;
      license.feedsRemaining = license.current_max_feeds - license.used_feeds;
      license.current_max_feeds = license.current_max_feeds || 'Unlimited';
      license.storeWarning = isStoreCountAlmostFull(license);
    }

    function isDocumentCountAlmostFull (license) {
      if (license.used_nrefs) {
        var used = parseInt(license.used_nrefs, 10);
        var max = parseInt(license.current_max_nrefs, 10);

        if (used >= max) {
          return true;
        }
      }
      return false;
    }

    function isLicenseSizeAlmostFull (license) {
      return (license.used_mb / license.maxDataMb) >= 0.9;
    }

    function isStoreCountAlmostFull (license) {
      return license.feedsRemaining <= 0;
    }

    function isValidLicense (license) {
      var displayValid = license.valid;

      if (isLicenseSizeAlmostFull(license) || license.storeWarning || license.docWarning) {
        displayValid = false;
      }

      return displayValid;
    }

    function fileSizePS_ (v) {
      if (v < 0) {
        return '-' + fileSizePS(-v);
      }

      return fileSizePS(v);
    }

    function numberDocs_ (v) {
      if (v < 0) {
        return '-' + numberDocs(-v);
      }

      return numberDocs(v);
    }

    function fileSizePS (size) {
      var i = -1;
      var units = [' kB', ' MB', ' GB', ' TB', 'PB'];

      if (size < byteCount) {
        return size;
      }
      do {
        size = size / byteCount;
        i++;
      } while (size > byteCount);

      return Math.max(size, 0.1).toFixed(1) + units[i];
    }

    function numberDocs (nRef) {
      var i = -1;
      var units = [' K', ' M', ' B', ' T', 'Q'];

      if (nRef < docCountRoundAmount) {
        return nRef;
      }
      do {
        nRef = nRef / docCountRoundAmount;
        i++;
      } while (nRef > docCountRoundAmount);

      return Math.max(nRef, 0.1).toFixed(1) + units[i];
    }

    function activateLicense () {
      return licensesSrv.activateLicense()
        .then(function () {
          showEulaConfirm();
        })
        .catch(function (error) {
          if (error.status === 503) {
            $http({
              method: 'GET',
              url: '/appliance/id'
            })
              .then(function (res) {
                showConnectionDialog(res.data);
              });
          }
        });
    }

    function showConnectionDialog (machineID) {
      var dialog = $mdDialog.alert({
        title: 'Connection Problem',
        htmlContent: [
          '<p>Unable to activate the license. Please copy the following infomation and go to the license website to complete your request.</p>',
          '<p>License website URL: <a href="http://perfectsearch.mysearchappliance.com/license" target="_blank">http://perfectsearch.mysearchappliance.com/license</a>.</p>',
          '<p><strong>Requestor ID: ' + machineID + '</strong></p>'
        ].join(''),
        ok: 'Ok'
      });

      return $mdDialog.show(dialog);
    }

    function showEulaConfirm () {
      var eulaConfirm = $mdDialog.confirm()
        .title('End User License Agreement')
        .htmlContent('I accept the terms of the <a href="http://perfectsearch.mysearchappliance.com/license/eula/2" target="_blank">End User License Agreement</a>.')
        .ariaLabel('End User License Agreement')
        .ok('Accept')
        .cancel('Decline');

      $mdDialog.show(eulaConfirm)
        .then(function () {
          licensesSrv.acceptEULA(true)
            .then(function () {
              psNotification.success('License activated. Restart Search Server for changes to take effect.');
              activate();
            })
            .catch(function () {
              psNotification.error('Unable to activate the license.');
            });
        })
        .catch(function () {
          psNotification.error('License was not activated.');
        });
    }
  }
})();
