(function () {
  'use strict';

  angular
    .module('imat.services')
    .factory('releaseSrv', releaseSrv);

  releaseSrv.$inject = ['$q', 'releaseApiSrv'];

  function releaseSrv ($q, releaseApiSrv) {
    // Variables
    let versionObj = {};
    let components = null;

    // Interface
    var service = {
      getComponents: getComponents,
      getVersionNum: getVersionNum,
      getVersionBuildTimestamp: getVersionBuildTimestamp
    };

    return service;

    // Methods

    function getComponents (force) {
      if (!force && components) {
        return $q.resolve(components);
      }

      return releaseApiSrv.getComponents()
        .then(function (response) {
          components = response.data;
          return components;
        });
    }

    function getVersionBuildTimestamp () {
      return _loadVersionObj()
        .then(function (response) {
          return response.build_timestamp;
        });
    }

    function getVersionNum () {
      return _loadVersionObj()
        .then(function (response) {
          return response.version;
        });
    }

    function _loadVersionObj () {
      if (versionObj.version) {
        return $q.resolve(versionObj);
      }
      return releaseApiSrv.getVersion()
        .then(function (response) {
          versionObj = response.data;
          return versionObj;
        });
    }
  }
})();
