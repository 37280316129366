(function () {
  'use strict';

  angular
    .module('mpiApp.test')
    .factory('mpiConfigFixtures', mpiConfigFixtures);

  mpiConfigFixtures.$inject = [];

  function mpiConfigFixtures () {
    var service;

    service = {
      getConfig: getConfig
    };

    return service;

    function getConfig () {
      return {
        apps: {
          mpi: {
            title: 'MPI',
            customerHelpLink: '/ui/documentation/#/guides/mpi',
            limboStore: 'Limbo',
            rawRecordNode: 'originalhl7',
            personaView: {
              preferredPersona: {
                displayInactiveFmrns: true
              }
            },
            createpi: {
              title: 'Create PI',
              customerHelpLink: '/ui/documentation/#/guides/pi-create'
            },
            pilinkeditor: {
              title: 'PI Link Editor',
              customerHelpLink: '/ui/documentation/#/guides/pi-link-editor'
            }
          }
        }
      };
    }

    // TODO: remove function? being used for testing?
    function getPersonaSearchConfig () { // eslint-disable-line no-unused-vars
      return {
        caller: 'mpi',
        results_display: 'app.mpi.query.results',
        store: 'mpi-persona',
        report: 'exacttotal',
        limit: '25',
        fields: 'uri,rawrecord',
        clear_button: true,
        form_fields: {
          mpid: {
            display_name: 'MPID',
            name: 'mpid',
            type: 'number',
            placeholder: 'e.g. 192837'
          },
          facility: {
            display_name: 'Facility',
            name: 'facility',
            type: 'text',
            use_wildcards: true,
            placeholder: 'e.g. Community Clinic or "1.2.34.567.891.0.1"',
            linked_field: {
              name: 'mrn',
              linked_field_name: 'facility_mrn',
              linked_field_order: 2
            }
          },
          mrn: {
            display_name: 'MRN',
            name: 'mrn',
            type: 'text',
            placeholder: 'e.g. 12345',
            linked_field: {
              name: 'facility',
              linked_field_name: 'facility_mrn',
              linked_field_order: 1
            }
          },
          first_name: {
            display_name: 'First Name',
            name: 'first_name',
            type: 'text',
            placeholder: 'e.g. John'
          },
          middle_initial: {
            display_name: 'Middle Initial',
            name: 'middle_initial',
            type: 'text',
            placeholder: 'e.g. J'
          },
          family_name: {
            display_name: 'Last Name',
            name: 'family_name',
            type: 'text',
            placeholder: 'e.g. Smith'
          },
          suffix: {
            display_name: 'Suffix',
            name: 'suffix',
            type: 'text',
            placeholder: 'e.g. Jr'
          },
          gender: {
            display_name: 'Gender',
            name: 'gender',
            type: 'select',
            options: ['male', 'female', 'other', 'unknown'],
            placeholder: 'e.g. male'
          },
          race: {
            display_name: 'Race',
            name: 'race',
            type: 'text',
            placeholder: 'e.g. White'
          },
          ethnicity: {
            display_name: 'Ethnicity',
            name: 'ethnicity',
            type: 'text',
            placeholder: 'e.g. Caucasian'
          },
          birth_date: {
            display_name: 'Date of Birth',
            name: 'birth_date',
            type: 'text',
            placeholder: 'e.g. 1966-01-30'
          },
          ssn_rendered_value: {
            display_name: 'Social Security Number',
            name: 'ssn_rendered_value',
            type: 'ssn',
            placeholder: 'e.g. 1234'
          },
          phone: {
            display_name: 'Phone Number',
            name: 'phone',
            type: 'phone',
            placeholder: 'e.g. 800-393-8687'
          },
          address: {
            display_name: 'Address',
            name: 'address',
            type: 'text',
            placeholder: 'e.g. 123 E South Street Nashville TN 37010'
          },
          default_fields: ['mpid', 'facility', 'mrn']
        }
      };
      // TODO: remove function? being used for testing?
      function getLimboSearchConfig () { // eslint-disable-line no-unused-vars
        return {
          caller: 'limbo',
          results_display: 'app.mpi.limboQuery.limboResults',
          store: 'Limbo',
          report: 'exacttotal',
          limit: '25',
          fields: 'date_of_service,record_type,patient_local_id,patient_full_name,patient_sex,patient_date_of_birth,patient_ssn,patient_phone,patient_street_address,patient_city,patient_state,patient_zip,patient_race,patient_ethnic_group,uri',
          clear_button: true,
          form_fields: {
            date_of_service: {
              display_name: 'Date of service',
              name: 's.date_of_service',
              type: 'text',
              placeholder: 'e.g. 1966-01-30'
            },
            record_type: {
              display_name: 'Record Type',
              name: 's.record_type',
              type: 'text',
              placeholder: 'e.g. ADT'
            },
            mrn: {
              display_name: 'MRN',
              name: 's.patient_local_id',
              type: 'text',
              placeholder: 'e.g. 12345'
            },
            first_name: {
              display_name: 'First Name',
              name: 's.patient_first_name',
              type: 'text',
              placeholder: 'e.g. John'
            },
            middle_name: {
              display_name: 'Middle Name',
              name: 's.patient_middle_name',
              type: 'text',
              placeholder: 'e.g. J'
            },
            family_name: {
              display_name: 'Last Name',
              name: 's.patient_last_name',
              type: 'text',
              placeholder: 'e.g. Smith'
            },
            suffix: {
              display_name: 'Suffix',
              name: 's.patient_name_suffix',
              type: 'text',
              placeholder: 'e.g. Jr'
            },
            gender: {
              display_name: 'Gender',
              name: 's.patient_sex',
              type: 'select',
              options: ['male', 'female', 'other', 'unknown'],
              placeholder: 'e.g. male'
            },
            race: {
              display_name: 'Race',
              name: 's.patient_race',
              type: 'text',
              placeholder: 'e.g. White'
            },
            ethnicity: {
              display_name: 'Ethnicity',
              name: 's.patient_ethnic_group',
              type: 'text',
              placeholder: 'e.g. Caucasian'
            },
            birth_date: {
              display_name: 'Date of Birth',
              name: 's.patient_date_of_birth',
              type: 'text',
              placeholder: 'e.g. 1966-01-30'
            },
            ssn: {
              display_name: 'Social Security Number',
              name: 's.patient_ssn',
              type: 'ssn',
              placeholder: 'e.g. 1234'
            },
            phone: {
              display_name: 'Phone Number',
              name: 's.patient_phone',
              type: 'phone',
              placeholder: 'e.g. 800-393-8687'
            },
            street_address: {
              display_name: 'Street Address',
              name: 's.patient_street_address',
              type: 'text',
              placeholder: 'e.g. 123 E South Street'
            },
            state: {
              display_name: 'State',
              name: 's.patient_state',
              type: 'text',
              placeholder: 'e.g. TN'
            },
            patient_city: {
              display_name: 'City',
              name: 's.patient_city',
              type: 'text',
              placeholder: 'e.g. Nashville'
            },
            zip_code: {
              display_name: 'Zip Code',
              name: 's.patient_zip ',
              type: 'text',
              placeholder: 'e.g. 37010'
            },
            default_fields: ['date_of_service', 'record_type', 'mrn', 'gender']
          }
        };
      }
    }
  }
})();
