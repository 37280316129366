(function () {
  'use strict';

  angular.module('admin')
    .controller('AddEntryModalCtrl', AddEntryModalCtrl);

  AddEntryModalCtrl.$inject = ['$mdDialog', 'ctakesSrv', 'psNotification', 'code', 'details'];
  function AddEntryModalCtrl ($mdDialog, ctakesSrv, psNotification, code, details) {
    var vm = this;

    vm.translated = '';

    // Methods
    vm.addEntry = addEntry;
    vm.cancel = cancel;
    vm.translateText = translateText;

    function addEntry () {
      const payload = {
        cui: details.cui,
        tuis: details.tui,
        description: vm.translated
      };
      if (details.body[vm.translated]) {
        if (!details.body[vm.translated].deactive) {
          psNotification.error(code + ' already has an active alpha-normalized entry of ' + vm.translated + '.');
        } else {
          ctakesSrv.putAlphanorm(payload).then(function () {
            $mdDialog.hide('Activated entry ' + vm.translated + ' for ' + code);
          });
        }
      } else {
        ctakesSrv.putAlphanorm(payload).then(function (resp) {
          $mdDialog.hide(resp);
        });
      }
    }

    function cancel () {
      $mdDialog.cancel();
    }

    function translateText (text) {
      var payload = {
        string: text
      };
      if (text.length < 3) {
        vm.translated = '';
        return;
      }
      ctakesSrv.postTranslate(payload)
        .then(function (data) {
          vm.translated = data.string;
        })
        .catch(function (err) {
          psNotification.error(err.data.error.message);
        });
    }
  }
})();
