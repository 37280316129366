(function () {
  'use strict';

  angular.module('queries.constants', [])
    .constant('QUERY', {
      ALL_STORES: '-1',
      CALLER: 'clinical-reports',
      DEFAULT_QUERY: '()UNIVERSE',
      FILTER_CATEGORY_CODE_SETS: 'Code sets',
      FILTER_CATEGORY_DATE: 'Dates',
      FILTER_CATEGORY_DEFAULT: 'Default',
      FILTER_CATEGORY_LAB: 'Labs',
      FILTER_CATEGORY_NLP: 'NLP',
      FILTER_CATEGORY_NUMERIC: 'Numeric',
      FILTER_CATEGORY_SET: 'Sets',
      FILTER_CATEGORY_TEXT: 'Text',
      PAGE_SIZE: 200,
      RESULT_DISPLAY_DEFAULT: 'clinical',
      SET_PREFIX: 'qb_temp',
      TYPE: 'pslucene'
    });
})();
