(function () {
  'use strict';

  angular.module('admin')
    .controller('CtakesCtrl', CtakesCtrl);

  CtakesCtrl.$inject = ['$mdDialog', '$scope', '$timeout', 'ctakesSrv', 'imatConfig', 'psNotification'];

  function CtakesCtrl ($mdDialog, $scope, $timeout, ctakesSrv, imatConfig, psNotification) {
    var vm = this;
    var IncludedTuis;

    // Properties
    vm.Codetype = '';
    vm.CodeTypeOptions = [];
    vm.ctakesData = {};
    vm.ctakesSearch = [];
    vm.loadedList = false;
    vm.loading = false;
    // Methods
    vm.addCode = addCode;
    vm.addEntry = addEntry;
    vm.clearSearchInput = clearSearchInput;
    vm.codeTypeChange = codeTypeChange;
    vm.refreshExpertise = refreshExpertise;
    vm.removeCode = removeCode;
    vm.toggleActive = toggleActive;
    vm.transformChip = transformChip;

    activate();

    function activate () {
      var data = imatConfig.get('apps.ctakes');

      vm.CodeTypeOptions = data.code_types;
      vm.Codetype = data.code_types[0];
      IncludedTuis = data.included_tuis_query;
      vm.loadedList = true;
      vm.ctakesData = ctakesSrv.getSelected();
      vm.ctakesSearch = Object.keys(vm.ctakesData);
    }

    function addCode (code) {
      vm.loading = true;
      ctakesSrv.getAlphanorm(vm.Codetype, [code]).then(function () {
        vm.loading = false;
      });
    }

    function addEntry (code) {
      $mdDialog.show({
        controller: 'AddEntryModalCtrl',
        controllerAs: 'modal',
        locals: { code: code, details: angular.copy(vm.ctakesData[code]) },
        templateUrl: '/ui/admin/ctakes/addEntryModal.html',
        parent: angular.element(document.body),
        clickOutsideToClose: true
      })
        .then(function (resp) {
          if (typeof resp === 'string') {
            psNotification.success(resp);
          }
          ctakesSrv.getAlphanorm(vm.Codetype, [code]);
        });
    }

    function clearSearchInput () {
      ctakesSrv.removeAllSelected();
      vm.ctakesSearch = [];
    }

    function codeTypeChange () {
      vm.loadedList = false;
      clearSearchInput();
      $scope.$broadcast('clearSearchInput');
      $timeout(function () { vm.loadedList = true; });
    }

    function refreshExpertise (search) {
      if (!search || !search.trim()) {
        return;
      }
      return ctakesSrv.wildcardSearch(vm.Codetype, search, IncludedTuis)
        .then(function (hits) {
          if (!parseInt(hits._total, 10)) { return []; }
          return filterSearchHits(vm.ctakesSearch, hits.hit.length ? hits.hit : [hits.hit]);
        })
        .catch(function () {
          psNotification.error('Unable to retrieve the search results.');
        });
    }

    function removeCode (code) {
      ctakesSrv.removeSelectedCode(code);
    }

    function toggleActive (obj, cui, tui, alphanorm) {
      var payload = {
        cui: cui,
        tuis: tui
      };
      payload.description = alphanorm || '';

      return (obj.deactive ? ctakesSrv.postAlphanorm(payload) : ctakesSrv.putAlphanorm(payload)).catch(function () {
        psNotification.error('Unable to toggle the status.');
        obj.deactive = !obj.deactive;
      });
    }

    function transformChip (chip) {
      // Typically {"title":"123456789","description":"Some string of text","_ref":"15938","_score":"0","_store":"63","_space":"63"}
      return (angular.isObject(chip) ? chip.title : chip);// XXX What is it if not an object??
    }

    // =======================
    //    Private Methods
    // =======================

    function filterSearchHits (selectedCodes, hits) {
      return hits.filter(function (hit) {
        return selectedCodes.indexOf(hit.title) === -1;
      });
    }
  }
})();
