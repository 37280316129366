(function () {
  'use strict';

  angular.module('account', [
    /* Angular modules */
    'ngAnimate',
    'ngMaterial',
    'ngMessages',
    'ngSanitize',

    /* 3rd Party Modules */
    'focus-if',
    'ui.router',

    /* IMAT Modules */
    'imat.directives', // psMaskedInput
    'imat.services' // Preferences, psNotification, subscriptionsSrv, userSession

    /* App modules */

  ]);
})();
