(function () {
  'use strict';

  angular.module('imat.services')
    .factory('ctakesApiSrv', ctakesApiSrv);

  ctakesApiSrv.$inject = ['$http'];

  function ctakesApiSrv ($http) {
    var baseUrl = '/extensions/ctakes';
    var defaultConfig = { headers: { Accept: 'application/json', 'Content-Type': 'application/json' } };

    function getAlphanorm (codeType, title) {
      return $http.get([baseUrl, codeType].join('/') + '?codes=' + title, defaultConfig);
    }

    function putAlphanorm (payload) {
      return $http.put(baseUrl, payload, defaultConfig);
    }

    function postAlphanorm (payload, action) {
      var url = action ? baseUrl + '?action=' + action : baseUrl;
      return $http.post(url, payload, defaultConfig);
    }

    function deleteAlphanorm (payload) {
      return $http.delete(baseUrl, payload, defaultConfig);
    }

    function wildcardSearch (params) {
      var config = angular.copy(defaultConfig);
      config.params = params;

      return $http.post('/search', null, config);
    }

    return {
      getAlphanorm: getAlphanorm,
      putAlphanorm: putAlphanorm,
      postAlphanorm: postAlphanorm,
      deleteAlphanorm: deleteAlphanorm,
      wildcardSearch: wildcardSearch
    };
  }
})();
