(function () {
  'use strict';

  angular.module('queries.services')
    .factory('ResultDisplaySrv', ResultDisplaySrv);

  ResultDisplaySrv.$inject = [];

  function ResultDisplaySrv () {
    // TODO: result display options need to be configurable
    var resultDisplay = getDefaultResultDisplay();

    var service = {
      getFields: getFields,
      getStores: getStores,
      isIncluded: isIncluded,
      resultDisplay: resultDisplay,
      resetFields: resetFields,
      setFields: setFields
    };

    return service;

    function getDefaultResultDisplay () {
      return {
        clinical: {
          type: 'clinical',
          label: 'Clinical records',
          fields: ['patient_id', 'patient_full_name', 'patient_date_of_birth', 'date_of_service', 'sending_facility', 'record_type'],
          requiredFields: ['uri', 'patient_id', 'mpid']
        },
        patientidn: {
          type: 'patientidn',
          label: 'Patients',
          fields: ['patient_id', 'patient_full_name', 'patient_date_of_birth'],
          requiredFields: ['uri', 'patient_id', 'mpid'],
          stores: ['mpi-persona']
        }
      };
    }

    function getFields (type, combined) {
      var fields;
      if (combined) {
        // Get unique list of fields from fields and requiredFields
        fields = resultDisplay[type].fields.concat(resultDisplay[type].requiredFields);
        return fields.filter(function (value, index) {
          return fields.indexOf(value) === index;
        });
      }
      // Return only the user selected fields
      return resultDisplay[type].fields;
    }

    function getStores (type) {
      return resultDisplay[type].stores;
    }

    function isIncluded (type, item) {
      return resultDisplay[type].fields.indexOf(item.toLowerCase()) >= 0;
    }

    function resetFields () {
      angular.copy(getDefaultResultDisplay(), service.resultDisplay);
    }

    function setFields (type, dataString) {
      var fields = dataString.split(',');
      var i, l;
      service.resultDisplay[type].fields.length = 0;
      for (i = 0, l = fields.length; i < l; i++) {
        service.resultDisplay[type].fields.push(fields[i]);
      }
    }
  }
})();
