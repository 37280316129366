(function () {
  'use strict';

  angular.module('admin')
    .controller('AdminGroupsCtrl', AdminGroupsCtrl);

  AdminGroupsCtrl.$inject = [
    '$filter', '$mdDialog', '$mdSidenav', '$q', '$scope',
    'DownloaderSrv', 'GroupsSrv', 'ProvidersSrv', 'psNotification'
  ];

  function AdminGroupsCtrl (
    $filter, $mdDialog, $mdSidenav, $q, $scope,
    DownloaderSrv, GroupsSrv, ProvidersSrv, psNotification
  ) {
    var vm = this;
    var GROUP_DELETE_ERROR = 'Unable to delete ';
    var GROUP_DELETE_SUCCESS = ' deleted.';
    var GROUP_GET_ERROR = 'Unable to retrieve the group details.';
    var GROUP_PUT_ERROR = 'Unable to update the group description.';
    var GROUP_PUT_SUCCESS = 'Updated the group description.';
    var GROUPS_GET_ERROR = 'Unable to retrieve the group list.';
    var GROUPS_GET_MEMBERS_ERROR = 'Unable to retrieve the group members.';
    var GROUPS_PUT_MEMBERS_ERROR = 'Unable to update the group members.';
    var GROUPS_PUT_MEMBERS_SUCCESS = 'Updated group members.';

    // Properties
    vm.groupListFilters = [];
    vm.groups = [];
    vm.loadingGroupData = false;
    vm.providerCount = 1;
    vm.searchGroupText = '';
    vm.selected = {};
    vm.selectedFilter = '';
    vm.total = 0;
    vm.loadingList = true;

    // Methods
    vm.clearSelection = clearSelection;
    vm.deleteGroup = deleteGroup;
    vm.downloadCSV = downloadCSV;
    vm.editGroupDescription = editGroupDescription;
    vm.filterGroupList = filterGroupList;
    vm.getGroup = getGroup;
    vm.groupsByName = groupsByName;
    vm.manageUsers = manageUsers;
    vm.resetFilters = resetFilters;
    vm.refreshList = refreshList;
    vm.selectGroup = selectGroup;

    activate();

    function activate () {
      filterGroupList(true)
        .then(ProvidersSrv.getProviderList)
        .then(function (providers) {
          vm.providerCount = providers.length;
          if (vm.providerCount > 1) {
            providers.forEach(function (provider) {
              vm.groupListFilters.push({
                name: 'Provider: ' + provider,
                key: 'provider',
                value: provider
              });
            });
          }
        });
    }

    function clearSelection () {
      if (vm.selected) {
        delete vm.selected.selected;
        vm.selected = {};
      }
      $mdSidenav('groupDetails').close();
    }

    function deleteGroup (event) {
      var confirm;

      if (vm.selected.name.indexOf('searchappliance_') > -1) {
        confirm = {
          controller: 'deleteSystemGroupCtrl',
          templateUrl: '/ui/admin/groups/deleteSystemGroupModal.html',
          locals: { modalGroup: vm.selected },
          parent: angular.element(document.body),
          targetEvent: event,
          clickOutsideToClose: true
        };
      } else {
        confirm = $mdDialog.confirm()
          .title('Delete ' + vm.selected.name + '?')
          .textContent('Are you sure you want to delete this group?')
          .targetEvent(event)
          .ok('Delete')
          .cancel('Cancel');
      }

      $mdDialog.show(confirm)
        .then(function () {
          return GroupsSrv.deleteGroup(vm.selected)
            .catch(function () {
              psNotification.error(GROUP_DELETE_ERROR + vm.selected.name + '.');
            })
            .then(function () {
              psNotification.success(vm.selected.name + GROUP_DELETE_SUCCESS);
              clearSelection();
              filterGroupList(true);
            });
        });
    }

    function downloadCSV () {
      var collection = vm.groups.filter(vm.groupsByName);

      return DownloaderSrv.downloadCsv(collection, {
        columns: ['name', 'provider', 'description'],
        filename: 'groups.csv',
        headers: {
          name: 'Name',
          provider: 'Provider',
          description: 'Description'
        },
        processors: {
          name: DownloaderSrv.PROCESS.nullToEmptyString,
          provider: DownloaderSrv.PROCESS.nullToEmptyString,
          description: DownloaderSrv.PROCESS.nullToEmptyString
        }
      });
    }

    function editGroupDescription (ev) {
      $mdDialog.show({
        controller: 'EditGroupDescriptionModalCtrl',
        controllerAs: 'modal',
        locals: { modalGroup: vm.selected },
        templateUrl: '/ui/admin/groups/editGroupDescriptionModal.html',
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: true
      })
        .then(function (group) {
          GroupsSrv.updateGroup(group)
            .then(function () {
              psNotification.success(GROUP_PUT_SUCCESS);
            })
            .catch(function () {
              psNotification.error(GROUP_PUT_ERROR);
            });
        })
        .catch(function () {
        // Cancel logic
        });
    }

    function filterGroupList (force) {
      vm.loadingList = true;
      return GroupsSrv.getList(force)
        .then(function (groups) {
          if (vm.selectedFilter && vm.selectedFilter.key !== 'provider') {
            vm.groups = $filter('filter')(groups, function (value) {
              return value[vm.selectedFilter.key] === 'true';
            });
          } else {
            vm.groups = $filter('filter')(groups, function (value) {
              return (vm.selectedFilter) ? value[vm.selectedFilter.key] === vm.selectedFilter.value : true;
            });
          }
        })
        .catch(function () {
          psNotification.error(GROUPS_GET_ERROR);
        })
        .finally(function () {
          vm.loadingList = false;
        });
    }

    function getGroup (group) {
      vm.loadingGroupData = true;

      vm.selected = group;

      return GroupsSrv.getUsers(vm.selected)
        .catch(function () {
          return $q.reject(GROUPS_GET_MEMBERS_ERROR);
        })
        .then(function (data) {
          vm.selected.members = data.members;
        })
        .catch(function (reason) {
          reason = reason || GROUP_GET_ERROR;
          psNotification.error(reason);
          clearSelection();
        })
        .finally(function () {
          vm.loadingGroupData = false;
        });
    }

    function groupsByName (group) {
      return (!vm.searchGroupText || !vm.searchGroupText.length || group.name.toLowerCase().indexOf(vm.searchGroupText.toLowerCase()) !== -1 || group.description.toLowerCase().indexOf(vm.searchGroupText.toLowerCase()) !== -1);
    }

    function manageUsers (ev) {
      $mdDialog.show({
        controller: 'ManageGroupUsersModalCtrl',
        controllerAs: 'vm',
        locals: { modalUsers: vm.selected.members.map(function (m) { return m.name; }), provider: vm.selected.provider },
        templateUrl: '/ui/admin/groups/manageGroupUsersModal.html',
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: true
      })
        .then(function (members) {
          GroupsSrv.updateUsers(members.added, members.removed, vm.selected)
            .catch(function () {
              psNotification.error(GROUPS_PUT_MEMBERS_ERROR);
            })
            .then(function () {
              psNotification.success(GROUPS_PUT_MEMBERS_SUCCESS);
              getGroup(vm.selected);
            });
        });
    }

    function resetFilters () {
      clearSelection();
      vm.searchGroupText = '';
      vm.selectedFilter = '';
    }

    function refreshList () {
      filterGroupList(true);
    }

    function selectGroup (group) {
      if (vm.selected) {
        clearSelection();
      }
      getGroup(group)
        .then(function () {
          vm.selected.selected = true;
        });
      $mdSidenav('groupDetails').open();
    }
  }
})();
