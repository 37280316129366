(function () {
  'use strict';

  angular.module('imat.services')
    .factory('ArrayFnSrv', ArrayFnSrv);

  ArrayFnSrv.$inject = [];

  function ArrayFnSrv () {
    var service = {
      diff: diff,
      sortNames: sortNames
    };

    // diff returns contents of array1 that are not found in array2
    function diff (array1, array2) {
      return array1.filter(function (element) {
        for (var i in array2) {
          if (element === array2[i]) { return false; }
        }
        return true;
      });
    }

    function sortNames (a, b) {
      var aName = a.toLowerCase();
      var bName = b.toLowerCase();
      if (aName < bName) { return -1; }
      if (aName > bName) { return 1; }
      return 0;
    }

    return service;
  }
})();
