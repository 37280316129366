(function () {
  'use strict';

  angular
    .module('imat.services')
    .factory('SetsSrv', SetsSrv);

  SetsSrv.$inject = ['SET_STATUSES', '_moment', '$http', '$q', 'CachingClass'];

  function SetsSrv (SET_STATUSES, _moment, $http, $q, CachingClass) {
    var baseUrl = '/api/sets/definitions';
    var loaded = { collection: false };
    var filter = '';
    var setsBySession = {};

    var service = {
      checkStatuses: checkStatuses,
      createSet: createSet,
      createSetDefinition: createSetDefinition,
      deactivateSet: deactivateSet,
      deleteSet: deleteSet,
      ejectCachedItem: ejectCachedItem,
      getFilter: getFilter,
      getSelected: getSelected,
      getSet: getSet,
      getSetsForSession: getSetsForSession,
      getWorld: getWorld,
      saveSetDefinition: saveSetDefinition,
      setFilter: setFilter
    };

    // ====================
    //    Public Methods
    // ====================

    function checkStatuses () {
      const setGroups = service.getCached();
      const calls = [];
      setGroups.forEach(function (set) {
        if (!set.subheader) {
          calls.push(service.getSet(set.id).then(function (resp) {
            set.set_status = resp.set_status;
            set.set_activated_at = resp.set_activated_at ? _moment(resp.set_activated_at).format('M/D/Y h:mm A') : '';
          }));
        }
      });
      return $q.all(calls);
    }

    // (Activate)
    function createSet (id) {
      const set = findDefinitionInList(id);
      if (set.query_type === 'q') {
        return $http.put([baseUrl, id, 'activate'].join('/'))
          .then(function () {
            set.set_status = SET_STATUSES.CREATED;
            set.set_activated_at = _moment().format('M/D/Y h:mm A');
            set.updated_at = _moment().format('M/D/Y h:mm A');
          });
      }
      var deferred = $q.defer();
      io.socket.put('/api/sets/definitions/' + id + '/activate');
      io.socket.on('status:' + id, function (res) {
        if (res.status === 200) {
          set.set_status = SET_STATUSES.REQUESTED;
          deferred.resolve();
        } else {
          deferred.reject('There was a problem activating the set.');
        }
        io.socket.off('status:' + id);
      });
      return deferred.promise;
    }

    // (Create)
    function createSetDefinition (set, activate) {
      return $http.post(
        baseUrl,
        set,
        {
          params:
          { activate: activate }
        })
        .then(function () {
          return getWorld(true);
        });
    }

    function deactivateSet (id) {
      return $http.delete([baseUrl, id, 'activate'].join('/'))
        .then(function () {
          const set = findDefinitionInList(id);
          set.set_status = SET_STATUSES.PENDING;
          set.set_activated_at = '';
          set.updated_at = _moment(new Date()).format('M/D/Y h:mm A');
        });
    }

    function deleteSet (id) {
      return $http.delete([baseUrl, id].join('/'))
        .then(function () {
          ejectCachedItem(id, true);
        });
    }

    function ejectCachedItem (id, delist) {
      delete service._cache.items[id];

      if (delist) {
        for (let i = 0; i < service._cache.list.length; i++) {
          if (service._cache.list[i].id === id) {
            service._cache.list.splice(i, 1);
          }
        }
      }
    }

    function getFilter () {
      return filter;
    }

    function getSelected (ctx) {
      var id = service._cache.ids[ctx];
      if (id) {
        return findDefinitionInList(id);
      }
      return null;
    }

    function getSet (setid) {
      return $http.get([baseUrl, setid].join('/'))
        .then(function (response) {
          var data = response.data;
          if (service.isCached()) {
            const listDef = findDefinitionInList(data.id);
            listDef.set_status = data.set_status;
            listDef.set_activated_at = data.set_activated_at ? _moment(data.set_activated_at).format('M/D/Y h:mm A') : '';
            listDef.query_string = data.query_string;
            return listDef;
          }
          return data;
        });
    }

    function getSetsForSession (sessionid) {
      return setsBySession[sessionid];
    }

    function getWorld (force, checkStatus) {
      if (!force && service.isCached()) {
        return $q.resolve(service.getCached());
      }
      return $http.get(baseUrl, { params: { checkStatus: checkStatus } })
        .then(function (response) {
          return service.cache(formatSetList(response.data));
        });
    }

    function saveSetDefinition (id, payload) {
      loaded.collection = false;
      return $http.put([baseUrl, id].join('/'), payload)
        .then(function () {
          angular.extend(findDefinitionInList(id), payload);
        });
    }

    function setFilter (newFilter) {
      filter = newFilter;
    }

    // ====================
    //    Private Methods
    // ====================

    function findDefinitionInList (id) {
      for (var i = 0; i < service._cache.list.length; i++) {
        if (service._cache.list[i].id === id) {
          return service._cache.list[i];
        }
      }
    }

    function formatSetList (collection) {
      var flatList = [];
      collection.forEach(function (obj) {
        obj.definitions.forEach(function (def) {
          def.set_activated_at = def.set_activated_at ? _moment(def.set_activated_at).format('M/D/Y h:mm A') : '';
          def.updated_at = def.updated_at ? _moment(def.updated_at).format('M/D/Y h:mm A') : '';
          def.query_type_label = (def.query_type === 'c') ? 'Compound' : 'Single';
        });
        obj.subheader = true;
        flatList.push(obj);
        var defCopy = angular.copy(obj.definitions);
        flatList = flatList.concat(defCopy);
        setsBySession[obj.name] = defCopy;
        delete obj.definitions;
      });

      return flatList;
    }

    service = angular.extend((new CachingClass()), service);
    return service;
  }
})();
