(function () {
  'use strict';

  angular
    .module('vhr.services')
    .factory('vhrRecordSrv', vhrRecordSrv);

  vhrRecordSrv.$inject = [
    'VHR_REPORT',
    '$filter',
    'imatConfig',
    'vhrAdvDirRecordClass', 'vhrCcdRecordClass', 'VhrCxmlRecordClass', 'vhrCxmlAdtRecordClass',
    'vhrCxmlLabRecordClass', 'vhrCxmlLabcorpRecordClass',
    'vhrCxmlMedAdministerRecordClass', 'vhrCxmlMedDispenseRecordClass', 'vhrCxmlMedRequestRecordClass', 'vhrCxmlMedStatementRecordClass',
    'vhrCxmlPathologyRecordClass', 'vhrCxmlRadiologyRecordClass', 'VhrRecordClass', 'vhrCxmlTranscriptionRecordClass'
  ];

  function vhrRecordSrv (
    VHR_REPORT,
    $filter,
    imatConfig,
    vhrAdvDirRecordClass, vhrCcdRecordClass, VhrCxmlRecordClass, vhrCxmlAdtRecordClass,
    vhrCxmlLabRecordClass, vhrCxmlLabcorpRecordClass,
    vhrCxmlMedAdministerRecordClass, vhrCxmlMedDispenseRecordClass, vhrCxmlMedRequestRecordClass, vhrCxmlMedStatementRecordClass,
    vhrCxmlPathologyRecordClass, vhrCxmlRadiologyRecordClass, VhrRecordClass, vhrCxmlTranscriptionRecordClass
  ) {
    var service;
    var identifierTypes = [];
    var identifierTypeString = '';
    var identifierTypeRegexp = null;
    var RECORD_TYPE_CFG;
    var RECORD_TYPE_MAP;

    service = {
      construct: construct,
      getTypeConfig: getTypeConfig,
      labelize: labelize,
      load: load,
      TYPE: VHR_REPORT.RECORD_TYPE
    };

    return service;

    // ----------------------------------------
    // Public interface
    // ----------------------------------------

    function construct (store, type, raw) { // eslint-disable-line complexity
      var cfg = getConfig(raw);
      var record;
      var recordClass;

      if (cfg.type && RECORD_TYPE_CFG[cfg.type] && RECORD_TYPE_CFG[cfg.type].class) {
        if (/^[a-zA-Z0-9_]+Class$/.test(RECORD_TYPE_CFG[cfg.type].class)) {
          /* eslint-disable new-cap, no-eval */
          try {
            recordClass = eval(RECORD_TYPE_CFG[cfg.type].class);
            if (typeof recordClass === 'function') {
              record = new recordClass(cfg);
              record.set(raw);
              return record;
            }
          } catch (e) {
            // Ignored
          }
          /* eslint-enable new-cap, no-eval */
        }
      }

      record = new VhrCxmlRecordClass(cfg);
      record.set(raw);
      return record;
    }

    function getTypeConfig (type) {
      var rv = null;

      if (Object.prototype.hasOwnProperty.call(RECORD_TYPE_CFG, type)) {
        rv = angular.copy(RECORD_TYPE_CFG[type]);
        delete rv.identifiers;
      }
      return rv;
    }

    function labelize (key) {
      return $filter('imatLabelize')(key);
    }

    function load () {
      return imatConfig.loadConfigFile('vhr-records.json')
        .then(function (response) {
          return response.RECORD_TYPE;
        })
        .catch(function () {
          return {};
        })
        .then(function (definedTypes) { // From vhr-records.json file.
          RECORD_TYPE_CFG = getSupportedRecordTypes();// Should match VHR_REPORT.RECORD_TYPE constant.
          Object.keys(service.TYPE).forEach(function (type) { // From VHR_REPORT.RECORD_TYPE constant.
            if (Object.prototype.hasOwnProperty.call(definedTypes, type)) {
              if (!definedTypes[type].class) {
                delete definedTypes[type].class;
              }
              angular.extend(RECORD_TYPE_CFG[type], definedTypes[type]);
            }
          });

          setRecordTypeMap(RECORD_TYPE_CFG);
        });
    }

    // ----------------------------------------
    // Private interface
    // ----------------------------------------

    function getConfig (record) {
      var type = getConfigType(record);
      var rv = { type: type, config: angular.copy(RECORD_TYPE_CFG[type]) };

      delete rv.config.identifiers;
      return rv;
    }

    function getConfigType (record) {
      var prop = 'unknown';
      var type = record.record_type && 't' + getIdentifierType(record.record_type);
      var store = record.store && ('s' + record.store).toLowerCase();// Stores may be numeric.

      if (Object.prototype.hasOwnProperty.call(RECORD_TYPE_MAP, type + store)) {
        prop = type + store;
      } else if (Object.prototype.hasOwnProperty.call(RECORD_TYPE_MAP, type)) {
        prop = type;
      } else if (Object.prototype.hasOwnProperty.call(RECORD_TYPE_MAP, store)) {
        prop = store;
      }

      return RECORD_TYPE_MAP[prop];
    }

    function getIdentifierType (type) {
      // If `type` can be matched to a type that appears in the configuration, return that type.
      var match = identifierTypeRegexp.exec(type);
      var itype = match && (new RegExp(match[0], 'i')).exec(identifierTypeString);

      return (itype) ? itype[0] : null;
    }

    // Convert the record type configuration into a POJO that we can use to
    // quickly index into the config definitions. The keys are t<Type>s<Store>
    // or t<Type> or s<Store> or "unknown" and the values are strings matching
    // one of the record type config keys (which in turn should be constrained
    // to match a key in the VHR_REPORT.RECORD_TYPE constant).
    function setRecordTypeMap (config) {
      var map = { unknown: 'UNKNOWN' };

      angular.forEach(config, function (def, key) {
        if (def.identifiers && angular.isArray(def.identifiers)) {
          def.identifiers.forEach(function (id) { // eslint-disable-line complexity
            var prop;
            var store;
            var type;

            if (angular.isString(id)) {
              if (id && identifierTypes.indexOf(id) < 0) { identifierTypes.push(id); }
              prop = 't' + id.toLowerCase();
            } else if (angular.isObject(id)) {
              if (id.record_type && identifierTypes.indexOf(id.record_type) < 0) { identifierTypes.push(id.record_type); }
              type = id.record_type && 't' + id.record_type.toLowerCase();
              store = id.store && ('s' + id.store).toLowerCase();// Stores may be numeric.
              if (type && store) {
                prop = type + store;
              } else if (type) {
                prop = type;
              } else if (store) {
                prop = store;
              }
            }

            if (prop && Object.prototype.hasOwnProperty.call(map, prop) && map[prop] !== key) {
              map[prop] = 'UNKNOWN';// Don't try to disambiguate a misconfiguration.
            } else if (prop) {
              map[prop] = key;
            }
          });
        }
      });

      identifierTypes = identifierTypes.filter(function (t) { return Object.prototype.hasOwnProperty.call(config, t) ? config[t].identifiers.length > 0 : true; });
      identifierTypes = identifierTypes.map(function (t) { return t.toLowerCase(); });
      identifierTypes.sort();
      identifierTypes.sort(function (a, b) { return b.length - a.length; });// Sort decscending length (i.e., compare more specific first).
      identifierTypeString = identifierTypes.join('|');
      identifierTypeRegexp = new RegExp('^(?:' + identifierTypeString + ')', 'i');// An identifierType must appear at the front of the raw record_type!

      RECORD_TYPE_MAP = map;
    }

    function getSupportedRecordTypes () {
      return {
        // WARNING! THIS LIST MUST MATCH EXACTLY THE KEYS IN VHR_REPORT.RECORD_TYPE!
        ADT: {
          class: 'vhrCxmlAdtRecordClass',
          identifiers: [],
          label: 'ADT',
          report: '',
          parameters: {},
          titleFormat: 'ADT results from {{facility}}'
        },
        // WARNING! THIS LIST MUST MATCH EXACTLY THE KEYS IN VHR_REPORT.RECORD_TYPE!
        ADVANCE_DIRECTIVE: {
          class: 'vhrAdvDirRecordClass',
          identifiers: [],
          label: 'Advance Directive',
          report: '',
          parameters: {},
          titleFormat: 'Advance Directive [{{print_label}}] from {{facility}}'
        },
        // WARNING! THIS LIST MUST MATCH EXACTLY THE KEYS IN VHR_REPORT.RECORD_TYPE!
        CCD: {
          class: 'vhrCcdRecordClass',
          identifiers: [],
          label: 'CCD',
          report: '',
          parameters: {},
          titleFormat: 'CCD from {{facility}}'
        },
        // WARNING! THIS LIST MUST MATCH EXACTLY THE KEYS IN VHR_REPORT.RECORD_TYPE!
        LAB: {
          class: 'vhrCxmlLabRecordClass',
          identifiers: [],
          label: 'Lab',
          report: '',
          parameters: {},
          titleFormat: 'Lab results from {{facility}}'
        },
        // WARNING! THIS LIST MUST MATCH EXACTLY THE KEYS IN VHR_REPORT.RECORD_TYPE!
        MED_ADMINISTER: {
          class: 'vhrCxmlMedAdministerRecordClass',
          identifiers: [],
          label: 'Medication',
          report: '',
          parameters: {},
          titleFormat: 'Medication [{{print_label}}] from {{facility}}'
        },
        // WARNING! THIS LIST MUST MATCH EXACTLY THE KEYS IN VHR_REPORT.RECORD_TYPE!
        MED_DISPENSE: {
          class: 'vhrCxmlMedDispenseRecordClass',
          identifiers: [],
          label: 'Medication',
          report: '',
          parameters: {},
          titleFormat: 'Medication [{{print_label}}] from {{facility}}'
        },
        // WARNING! THIS LIST MUST MATCH EXACTLY THE KEYS IN VHR_REPORT.RECORD_TYPE!
        MED_REQUEST: {
          class: 'vhrCxmlMedRequestRecordClass',
          identifiers: [],
          label: 'Medication',
          report: '',
          parameters: {},
          titleFormat: 'Medication [{{print_label}}] from {{facility}}'
        },
        // WARNING! THIS LIST MUST MATCH EXACTLY THE KEYS IN VHR_REPORT.RECORD_TYPE!
        MED_STATEMENT: {
          class: 'vhrCxmlMedStatementRecordClass',
          identifiers: [],
          label: 'Medication',
          report: '',
          parameters: {},
          titleFormat: 'Medication [{{print_label}}] from {{facility}}'
        },
        // WARNING! THIS LIST MUST MATCH EXACTLY THE KEYS IN VHR_REPORT.RECORD_TYPE!
        PATHOLOGY: {
          class: 'vhrCxmlPathologyRecordClass',
          identifiers: [],
          label: 'Pathology',
          report: '',
          parameters: {},
          titleFormat: 'Pathology results from {{facility}}'
        },
        // WARNING! THIS LIST MUST MATCH EXACTLY THE KEYS IN VHR_REPORT.RECORD_TYPE!
        RADIOLOGY: {
          class: 'vhrCxmlRadiologyRecordClass',
          identifiers: [],
          label: 'Radiology',
          report: '',
          parameters: {},
          titleFormat: 'Radiology results from {{facility}}'
        },
        // WARNING! THIS LIST MUST MATCH EXACTLY THE KEYS IN VHR_REPORT.RECORD_TYPE!
        TRANSCRIPTION: {
          class: 'vhrCxmlTranscriptionRecordClass',
          identifiers: [],
          label: 'Transcription',
          report: '',
          parameters: {},
          titleFormat: 'Transcription results from {{facility}}'
        },
        // WARNING! THIS LIST MUST MATCH EXACTLY THE KEYS IN VHR_REPORT.RECORD_TYPE!
        UNKNOWN: {
          class: 'VhrCxmlRecordClass',
          identifiers: [],
          label: 'Unknown',
          report: '',
          parameters: {},
          titleFormat: 'Unknown record from {{facility}}'
        }
      };
    }
  }
})();
