(function () {
  'use strict';

  angular.module('vhr.directives', [
    'ngMaterial',
    'ui.grid.exporter', // uiGridExporterConstants, uiGridExporterService
    'vhr.filters', // vhrJsonRange
    'vhr.services'// vhrApp, vhrConfigSrv, vhrFootnoteSrv, vhrPatientSrv, vhrPdfSrv, vhrPrintSrv, vhrUserSrv
  ]);
})();
