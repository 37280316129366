(function () {
  'use strict';

  angular
    .module('vhr')
    .controller('WidgetModalCtrl', WidgetModalCtrl);

  WidgetModalCtrl.$inject = ['$mdDialog', '$scope', 'imatConfig', 'psNotification', 'widgetId'];

  function WidgetModalCtrl ($mdDialog, $scope, imatConfig, psNotification, widgetId) {
    $scope.loading = true;
    $scope.message = null;
    $scope.close = $mdDialog.hide;

    imatConfig.loadConfigFile('vhr-widgets-' + widgetId + '.html')
      .then(function (config) {
        $scope.message = config.value;
        $scope.loading = false;
      })
      .catch(function () {
        $mdDialog.hide();
        psNotification.error('Failed to load information');
      });
  }
})();
