(function () {
  'use strict';

  angular.module('mpiApp')
    .controller('DeleteLimboRecordModalCtrl', DeleteLimboRecordModalCtrl);

  DeleteLimboRecordModalCtrl.$inject = ['$mdDialog'];

  function DeleteLimboRecordModalCtrl ($mdDialog) {
    var vm = this;

    vm.deleteComment = null;
    vm.commenceScrub = false;

    vm.cancelDialog = cancelDialog;
    vm.submitDelete = submitDelete;

    function cancelDialog () {
      $mdDialog.cancel();
    }

    function submitDelete () {
      $mdDialog.hide({ comment: vm.deleteComment, scrub: vm.commenceScrub });
    }
  }
})();
