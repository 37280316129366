(function () {
  'use strict';

  angular.module('admin')
    .controller('recordsCtrl', recordsCtrl);

  recordsCtrl.$inject = [
    '$filter', '$mdDialog', '$mdSidenav', '$q', '$scope',
    'dateTimeSrv', 'imatStoresSrv', 'psNotification', 'searchSrv'
  ];

  function recordsCtrl (
    $filter, $mdDialog, $mdSidenav, $q, $scope, dateTimeSrv,
    imatStoresSrv, psNotification, searchSrv
  ) {
    var vm = this; // eslint-disable-line no-invalid-this
    var DELETE_RECORD_ERROR = 'Unable to delete the record.';
    var DELETE_RECORD_MISSING = 'Record not found.';
    var DELETE_RECORD_SUCCESS = 'Record deleted.';
    var GET_STORES_ERROR = 'Unable to retrieve the stores.';
    var LOOKUP_RECORD_ERROR = 'Unable to retrieve the record.';
    var NO_RECORD_FOUND = 'Record not found.';
    var SEARCH_RECORDS_ERROR = 'Unable to search for records.';

    $scope.STORES = imatStoresSrv.STORE;

    // Properties
    vm.hits = null;
    vm.loaded = { detail: true, list: true };
    vm.query = '';
    vm.selected = [imatStoresSrv.STORE.ALL];
    vm.selectedRecord = {};
    vm.stores = [];

    // Methods
    vm.clearSelectedRecord = clearSelectedRecord;
    vm.deleteStoreRecord = deleteStoreRecord;
    vm.resetRecordFilters = resetRecordFilters;
    vm.searchRecords = searchRecords;
    vm.selectRecord = selectRecord;
    vm.viewRawRecord = viewRawRecord;

    activate();

    function activate () {
      filterStores();

      $scope.$watchCollection(function () { return vm.selected; }, function (curr, prev) {
        if (angular.isArray(curr)) {
          if (curr.length === 0) {
            vm.selected = [imatStoresSrv.STORE.ALL];
          } else if (curr.length > 1) {
            const currAllIdx = curr.indexOf(imatStoresSrv.STORE.ALL);
            if (currAllIdx >= 0) {
              if (prev && prev.indexOf(imatStoresSrv.STORE.ALL) >= 0) {
                vm.selected.splice(currAllIdx, 1);
              } else if (prev) {
                vm.selected = [imatStoresSrv.STORE.ALL];
              }
            }
          }
        } else if (!curr) {
          vm.selected = [imatStoresSrv.STORE.ALL];
        }
      });
    }

    function clearSelectedRecord () {
      if (vm.selectedRecord.store) {
        delete vm.selectedRecord.selected;
        vm.selectedRecord = {};
      }
      $mdSidenav('recordDetails').close();
    }

    function deleteStoreRecord (selectedRecord) {
      $mdDialog.show({
        templateUrl: '/ui/admin/data-management/records/delete-record-modal.html',
        clickOutsideToClose: false,
        controller: 'DeleteIndexRecordModalCtrl'
      })
        .then(function () {
          imatStoresSrv.deleteRecord(selectedRecord.store, selectedRecord.uri)
            .then(function () {
              psNotification.success(DELETE_RECORD_SUCCESS);
              for (var i = 0, ii = vm.hits.length; i < ii; ++i) {
                if (vm.hits[i].selected === true) {
                  vm.hits.splice(i, 1);
                  clearSelectedRecord();
                  $mdSidenav('recordDetails').close();
                  break;
                }
              }
            }, function (error) {
              psNotification.error(error.status === 404 ? DELETE_RECORD_MISSING : DELETE_RECORD_ERROR);
            });
        });
    }

    function filterStores () {
      return _getStoresList()
        .then(function (stores) {
          if (!vm.selected.length || vm.selected[0] === imatStoresSrv.STORE.ALL) {
            vm.stores = stores;
          } else {
            vm.stores = $filter('filter')(stores, function (value) {
              return vm.selected.indexOf(value.number) >= 0;
            });
          }
        });
    }

    function resetRecordFilters () {
      vm.selected = [];
      vm.query = '';
    }

    function searchRecords (selectedStores, query) {
      var params = { limit: 100 };

      params.q = !query ? '()UNIVERSE' : '()' + query;
      params.store = selectedStores.join(',');
      params.fields = 'uri,indextime,updatetime,store,title,description';
      params.usesession = true;

      vm.hits = null;
      vm.loaded.list = false;
      searchSrv.search(params, {}, true)
        .then(function (response) {
          if (response.response.hits._count === '0') {
            vm.hits = [];
            return false;
          }

          if (!angular.isArray(response.response.hits.hit) && angular.isDefined(response.response.hits.hit)) {
            vm.hits = [response.response.hits.hit];
          } else {
            vm.hits = response.response.hits.hit;
          }

          vm.hits.forEach(function (hit) {
            hit.indextime = new Date(hit.indextime.replace(/-/g, '/'));
          });
        })
        .catch(function () {
          psNotification.error(SEARCH_RECORDS_ERROR);
          return $q.reject();
        })
        .finally(function () {
          vm.loaded.list = true;
        });
    }

    function selectRecord (record) {
      var params = {
        q: '()psuri:"' + record.uri + '"',
        store: record.store,
        fields: 'uri,indextime,updatetime,title,store,description,rawrecord',
        usesession: true
      };

      searchSrv.search(params, {}, true)
        .then(function (response) {
          if (response.response.hits._count === '0') {
            psNotification.show(NO_RECORD_FOUND);
          } else {
          // Filter original response so we just get the raw record data.
            var iTime = new Date(record.indextime);
            var uTime = new Date(record.updatetime);
            record.original_response = $filter('rawRecordFilter')(response.original_response);
            record.indextime = dateTimeSrv.getIsoDatetime(iTime, true);
            record.updatetime = dateTimeSrv.getIsoDatetime(uTime, true);
            clearSelectedRecord();
            vm.selectedRecord = record;
            vm.selectedRecord.selected = true;
          }
        })
        .catch(function () {
          psNotification.show(LOOKUP_RECORD_ERROR);
        })
        .finally(function () {
          $mdSidenav('recordDetails').open();
        });
    }

    function viewRawRecord (selectedRecord) {
      $mdDialog.show({
        controller: 'ViewIndexRecordModalCtrl',
        controllerAs: 'vm',
        locals: { selectedRecord: selectedRecord },
        templateUrl: '/ui/admin/data-management/records/view-record-modal.html'
      });
    }

    function _getStoresList () {
      return imatStoresSrv.getAll()
        .then(function (stores) {
          return stores;
        })
        .catch(function () {
          psNotification.error(GET_STORES_ERROR);
        });
    }
  }
})();
