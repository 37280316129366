(function () {
  'use strict';

  angular.module('reports')
    .controller('OpenReportCtrl', OpenReportCtrl);

  OpenReportCtrl.$inject = ['$mdDialog', '$scope', 'ReportsSrv'];

  function OpenReportCtrl ($mdDialog, $scope, ReportsSrv) {
    $scope.filterText = '';
    $scope.loaded = { list: false };
    $scope.reportList = [];

    activate();

    function activate () {
      // Check to make sure the queries list is not waiting on an update
      if (ReportsSrv.refreshing) {
        _pollQueriesList();
      } else {
        _getList();
      }
    }

    $scope.cancel = function () {
      $mdDialog.cancel();
    };

    $scope.open = function (report) {
      $mdDialog.hide(report);
    };

    $scope.filterReports = function (report) {
      var text;
      if (!$scope.filterText) {
        return true;
      }
      // Check if entered input found in name, id or description of report (case insensitive)
      text = $scope.filterText.toLowerCase();
      return (report.name.toLowerCase().indexOf(text) !== -1) ? true
        : (report.id.toLowerCase().indexOf(text) !== -1) ? true
          : (report.description.toLowerCase().indexOf(text) !== -1);
    };

    function _pollQueriesList () {
      var unwatch = $scope.$watch(function () { return ReportsSrv.refreshing; }, function (curr) {
        if (curr === false) {
          unwatch();
          _getList();
        }
      });
    }

    function _getList () {
      $scope.loaded.list = false;
      return ReportsSrv.getList()
        .then(function (list) {
          $scope.reportList = list.filter(function (report) {
            return report.type === 'python';
          });
        })
        .finally(function () {
          $scope.loaded.list = true;
        });
    }
  }
})();
