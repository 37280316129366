(function () {
  'use strict';

  angular.module('dashboardApp')
    .controller('ItemParameterizedConfigCtrl', ItemParameterizedConfigCtrl);

  ItemParameterizedConfigCtrl.$inject = ['DASHBOARD', '$mdDialog', '$scope', '$state', '$timeout', 'DashboardItemClass', 'modalDashboard', 'modalItem', 'ReportsSrv', 'userSession'];

  function ItemParameterizedConfigCtrl (DASHBOARD, $mdDialog, $scope, $state, $timeout, DashboardItemClass, modalDashboard, modalItem, ReportsSrv, userSession) {
    var modal = this;

    // Properties
    $scope.IMAGE_TYPES = DashboardItemClass.prototype.IMAGE;
    $scope.canSaveInvalid = false;
    $scope.colors = DASHBOARD.COLORS;
    $scope.icons = [].concat(
      DASHBOARD.ICONS.GLYPHICONS.map(function (i) { return { name: i, type: $scope.IMAGE_TYPES.GLYPHICON }; }),
      DASHBOARD.ICONS.MATERIALICONS.map(function (i) { return { name: i, type: $scope.IMAGE_TYPES.MATERIAL }; }),
      DASHBOARD.ICONS.PSICONS.map(function (i) { return { name: i, type: $scope.IMAGE_TYPES.PS }; })
    );
    $scope.image = { color: '', icon: '', src: '' };
    $scope.imgType = '';
    $scope.loading = true;
    $scope.savedList = [];
    $scope.selected = null;
    $scope.tile = modalItem;
    $scope.user = userSession.getUserData();
    // Methods
    $scope.cancel = $mdDialog.cancel;
    $scope.submit = $mdDialog.hide;
    modal.changeImgType = changeImgType;
    modal.updateColor = updateColor;
    modal.updateIcon = updateIcon;
    modal.updateSrc = updateSrc;

    activate();

    refresh()
      .then(function () {
        initWithId($scope.tile.link.params.reportId);
      });

    function activate () {
      switch (modalItem.image.type) {
        case $scope.IMAGE_TYPES.PS:// No break.
        case $scope.IMAGE_TYPES.GLYPHICON:// No break.
        case $scope.IMAGE_TYPES.MATERIAL:
          $scope.image.color = modalItem.image.color || $scope.colors[0];
          $scope.image.icon = { name: modalItem.image.value, type: modalItem.image.type };
          $scope.image.src = '';
          $scope.imgType = '0';
          break;
        case $scope.IMAGE_TYPES.IMGSRC:
          $scope.image.color = $scope.colors[0];
          $scope.image.icon = $scope.icons[0];
          $scope.image.src = modalItem.image.value || '';
          $scope.imgType = '1';
          break;
        default:
          break;
      }
    }

    function changeImgType () {
      switch ($scope.imgType) {
        case '0':
          updateIcon($scope.image.icon);
          break;
        case '1':
          updateSrc();
          break;
        default:
          break;
      }
    }

    function updateColor (color) {
      modalItem.image.color = color;
      $scope.image.color = color;
    }

    function updateIcon (icon) {
      modalItem.image.type = icon.type;
      modalItem.image.value = icon.name;
      $scope.image.icon = icon;
    }

    function updateSrc () {
      modalItem.image.type = $scope.IMAGE_TYPES.IMGSRC;
      modalItem.image.value = $scope.image.src;
      // $scope.src updated via ngModal.
    }

    function refresh () {
      var user = $scope.user;
      var dashboard = modalItem.ui.ref;

      return ReportsSrv.getList(getParams(user, dashboard))
        .then(function (list) {
          $scope.savedList = list.sort(compareName);

          if (!list.length) {
            $scope.canSaveInvalid = !!modalItem.data.link.href;
            $timeout(function () {
              $scope.dashboardItemConfig.savedList.$setValidity('savedListEmpty', false);
              $scope.dashboardItemConfig.savedList.$setValidity('savedListError', true);
              $scope.dashboardItemConfig.savedList.$setTouched();
            });
          }
        })
        .catch(function () {
          $scope.savedList = [];
          $scope.canSaveInvalid = !!modalItem.data.link.href;
          $timeout(function () {
            $scope.dashboardItemConfig.savedList.$setValidity('savedListEmpty', true);
            $scope.dashboardItemConfig.savedList.$setValidity('savedListError', false);
            $scope.dashboardItemConfig.savedList.$setTouched();
          });
        })
        .finally(function () {
          $scope.loading = false;
        });
    }

    function compareName (a, b) {
      var aName = a.name.toLowerCase();
      var bName = b.name.toLowerCase();
      if (aName < bName) { return -1; }
      if (aName > bName) { return 1; }
      return 0;
    }

    function getParams (user, dashboard) {
      var params = {
        admin: true,
        provider: dashboard.provider
      };

      if (user.systemName.toLowerCase() === dashboard.owner.toLowerCase()) {
        return {};
      }

      if (dashboard.level === dashboard.LEVELS.USER) {
        params.user = user.username;
      } else if (dashboard.level === dashboard.LEVELS.GROUP) {
        params.group = dashboard.group;
      } else if (dashboard.level === dashboard.LEVELS.SYSTEM) {
        params.group = 'searchappliance_search';
      }

      return params;
    }

    function initWithId (id) {
      $scope.selected = findIdInList(id, $scope.savedList);
      startWatchingForChanges();
    }

    function findIdInList (id, list) {
      if (!id || !list) { return; }

      for (var i = 0, ii = list.length; i < ii; ++i) {
        if (id === list[i].id) {
          return list[i];
        }
      }
    }

    function startWatchingForChanges () {
      $scope.$watch('selected.id', function (curr) {
        if (curr) updateTileUrl();
      });
    }

    function updateTileUrl () {
      $scope.tile.link.sref = 'app.viewer';
      $scope.tile.link.params = { reportId: $scope.selected.id };
      $scope.tile.link.href = $state.href($scope.tile.link.sref, $scope.tile.link.params);
    }
  }
})();
