(function () {
  'use strict';

  angular
    .module('imat.services')
    .factory('licensesSrv', licensesSrv);

  licensesSrv.$inject = ['$http', '$log', '$q', 'imatConfig'];

  function licensesSrv ($http, $log, $q, imatConfig) {
    var baseUrl = '/appliance/licenses';

    var service = {
      acceptEULA: acceptEULA,
      activateLicense: activateLicense,
      getLicenses: getLicenses,
      loaded: false,
      defaultLicense: null,
      loadDefaultLicense: loadDefaultLicense
    };

    return service;

    function getLicenses () {
      return $http.get(baseUrl).then(function (res) {
        return res.data;
      });
    }

    function loadDefaultLicense () {
      return imatConfig.loadConfigFile('license.json')
        .then(function (res) {
          service.loaded = true;
          service.defaultLicense = res;
        })
        .catch(function () {
          $log.debug('Unable to load license key');
          return $q.reject();
        });
    }

    function activateLicense () {
      return $http({
        method: 'POST',
        url: baseUrl,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        data: service.defaultLicense
      })
        .catch(function (error) {
          $log.debug('Error activating license');
          return $q.reject(error);
        });
    }

    function acceptEULA (accept) {
      return $http({
        method: 'POST',
        url: baseUrl + '/' + service.defaultLicense.license_key + '/eula?accept=' + accept,
        headers: {}
      });
    }
  }
})();
