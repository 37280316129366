(function () {
  'use strict';

  angular
    .module('mpiApp.services')
    .factory('mpiSearchSrv', mpiSearchSrv);

  mpiSearchSrv.$inject = ['$q', 'searchSrv'];

  function mpiSearchSrv ($q, searchSrv) {
    var cache = {};
    var srv = {
      clearAllCache: clearAllCache,
      clearCachedResults: clearCachedResults,
      getCachedResults: getCachedResults,
      selectRecord: selectRecord,
      clearSelection: clearSelection,
      run: run
    };

    return srv;

    function run (query, store) {
      store = store || query.store;
      if (!store) {
        return $q.reject();
      }
      return searchSrv.search(query, null, true)
        .then(function (res) {
          if (res.response.hits.hit !== undefined) {
            res.response.hits.hit = angular.isArray(res.response.hits.hit) ? res.response.hits.hit : [res.response.hits.hit];
          }
          cache[store] = {};
          cache[store].meta = angular.copy(res.response.hits);
          cache[store].meta.onspace = (res.response.paging && res.response.paging._onspace) || 0;
          cache[store].meta.onref = (res.response.paging && res.response.paging._onref) || 0;
          cache[store].results = angular.copy(res.response.hits.hit);
          cache[store].formFields = query.formFields;
          return cache[store];
        });
    }

    function getCachedResults (store) {
      return cache[store] || {};
    }

    function clearCachedResults (store) {
      cache[store] = {};
    }

    function clearAllCache () {
      cache = {};
    }

    function selectRecord (store, selected, index) {
      cache[store].selected = {};
      cache[store].selected.data = selected;
      if (index) {
        cache[store].selected.index = index;
      }
    }

    function clearSelection (store) {
      if (cache[store] && cache[store].selected) {
        delete cache[store].selected;
      }
    }
  }
})();
