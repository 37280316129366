(function () {
  angular
    .module('admin')
    .controller('ConfigDialogController', ConfigDialogController);

  ConfigDialogController.$inject = ['$scope', '$mdDialog', 'ConfigurationSrv', 'psNotification', 'configFiles', 'reload', 'serverResponse', 'stores'];

  function ConfigDialogController ($scope, $mdDialog, ConfigurationSrv, psNotification, configFiles, reload, serverResponse, stores) {
    // Variables.
    $scope.fileTypes = ['Taxonomy file via field name', 'Taxonomy file via store', 'QuickTable file'];
    $scope.stores = stores;
    $scope.configFiles = configFiles;
    $scope.serverResponse; // eslint-disable-line no-unused-expressions

    var SAVE_FILE_ERROR = 'Unable to save the file.';

    // Methods.
    $scope.closeDialog = closeDialog;
    $scope.buildFileName = buildFileName;
    $scope.createConfigFile = createConfigFile;
    $scope.reload = reload;

    $scope.$watch('type', function (newValue, oldValue) {
      if (newValue !== oldValue) {
        $scope.configFileName = '';
        $scope.fieldname = '';
        $scope.store = '';
      }
    });

    activate();

    function activate () {
      if (serverResponse) {
        $scope.serverResponse = serverResponse;
      }
      if ($scope.serverResponse && $scope.serverResponse.status === 200) {
        var serverMessageArray = $scope.serverResponse.data.split(' ');
        $scope.fileName = serverMessageArray[0];
        $scope.serverResponseMessage = serverMessageArray.slice(1).join(' ');
      }
    }

    function buildFileName (nameOrStore) {
      var store;
      if ($scope.type === 'Taxonomy file via field name' && nameOrStore) {
        $scope.configFileName = 'tax.' + nameOrStore + '.txt';
      }
      if ($scope.type === 'Taxonomy file via store' && nameOrStore) {
        store = JSON.parse(nameOrStore);
        $scope.configFileName = 'tax.' + store.number + '.txt';
      }
      if ($scope.type === 'QuickTable file' && nameOrStore) {
        store = JSON.parse(nameOrStore);
        $scope.configFileName = 'qx.' + store.number + '.def';
      }
    }

    function closeDialog () {
      $mdDialog.hide();
      $scope.configFileName = '';
      $scope.fieldname = '';
      $scope.store = '';
      $scope.type = '';
    }

    function createConfigFile (configFileName) {
      var fileExists = $scope.configFiles.some(
        function (group) {
          return group.files.some(
            function (fname) {
              return fname === configFileName;
            }
          );
        }
      );

      if (!fileExists) {
        showFileSave(configFileName);
      } else {
        showExistingFile(configFileName);
      }
    }

    function showExistingFile (configFileName) {
      $mdDialog.show({
        scope: $scope,
        preserveScope: true,
        clickOutsideToClose: true,
        template:
            '<md-dialog id="dialog">' +
            '  <md-toolbar layout="row" layout-align="start center">' +
            '    <p flex="auto">Info</p>' +
            '    <md-button class="md-icon-button md-hue-1" ng-click="closeDialog()">' +
            '      <md-icon>close</md-icon>' +
            '    </md-button>' +
            '  </md-toolbar>' +
            '  <md-dialog-content style="margin: 16px;">' +
            '    <div>File <b>' + configFileName + '</b> already exists.</div>' +
            '    <br>' +
            '    <div>You cannot create the file again. To edit the file; select it from the list of files.</div>' +
            '  </md-dialog-content>' +
            '  <md-dialog-actions class="margin-right-8" layout="row" layout-align="end">' +
            '    <md-button class="md-primary" ng-click="closeDialog()">' +
            '      OK' +
            '    </md-button>' +
            '  </md-dialog-actions>' +
            '</md-dialog>',
        controller: ConfigDialogController,
        locals: {
          serverResponse: $scope.serverResponse,
          stores: $scope.stores,
          configFiles: $scope.configFiles,
          reload: $scope.reload
        }
      });
    }

    function showFileSave (configFileName) {
      ConfigurationSrv.saveConfigFile(configFileName, '').then(
        function (response) {
          if (response.status === 201) {
            $mdDialog.show({
              scope: $scope,
              preserveScope: true,
              clickOutsideToClose: true,
              template:
                  '<md-dialog id="dialog">' +
                  '  <md-toolbar layout="row" layout-align="start center">' +
                  '    <p flex="auto">Info</p>' +
                  '    <md-button class="md-icon-button md-hue-1" ng-click="closeDialog()">' +
                  '      <md-icon>close</md-icon>' +
                  '    </md-button>' +
                  '  </md-toolbar>' +
                  '  <md-dialog-content style="margin: 16px;">' +
                  '    <div><b>Server Response:</b> <i>' + response.data + '</i></div>' +
                  '    <br>' +
                  '    <div>Please select the file from the list to edit it.</div>' +
                  '  </md-dialog-content>' +
                  '  <md-dialog-actions class="margin-right-8" layout="row" layout-align="end">' +
                  '    <md-button ng-click="closeDialog()" class="md-primary">' +
                  '      OK' +
                  '    </md-button>' +
                  '  </md-dialog-actions>' +
                  '</md-dialog>',
              controller: ConfigDialogController,
              locals: {
                serverResponse: $scope.serverResponse,
                stores: $scope.stores,
                configFiles: $scope.configFiles,
                reload: $scope.reload
              }
            })
              .finally(function () {
                // refresh the list for new filename value
                $scope.reload();
              });
          }
        }
      ).catch(
        function () {
          psNotification.error(SAVE_FILE_ERROR);
        }
      );
    }
  } // End Dialog Controller.
})();
