(function () {
  'use strict';

  angular.module('admin')
    .controller('SetsMainCtrl', SetsMainCtrl);

  SetsMainCtrl.$inject = ['$q', 'SET_STATUSES', '$filter', '$mdDialog', '$mdSidenav', '$scope', '$state', '$timeout', 'psNotification', 'SetsSrv'];

  function SetsMainCtrl ($q, SET_STATUSES, $filter, $mdDialog, $mdSidenav, $scope, $state, $timeout, psNotification, SetsSrv) {
    var vm = this;
    var ctx = this.constructor.name;
    var allSets = [];

    // Variables
    $scope.filteredDefs = {};
    vm.loading = false;
    vm.searchFilterText = '';
    vm.selectedFilter = '';
    vm.sets = [];
    vm.statuses = SET_STATUSES;

    // Methods
    vm.clearSelection = clearSelection;
    vm.createSet = createSet;
    vm.deactivateSet = deactivateSet;
    vm.deleteSet = deleteSet;
    vm.getFilteredCount = getFilteredCount;
    vm.filterList = filterList;
    vm.reloadList = reloadList;
    vm.resetFilters = resetFilters;
    vm.selectFilterList = selectFilterList;
    vm.selectSet = selectSet;
    vm.showStaleWarning = showStaleWarning;

    vm.filters = [
      { name: 'Type: Single', key: 'query_type_label', value: 'Single' },
      { name: 'Type: Compound', key: 'query_type_label', value: 'Compound' },
      { name: 'Status: ' + $filter('statusLabel')(SET_STATUSES.CREATED), key: 'set_status', value: SET_STATUSES.CREATED },
      { name: 'Status: ' + $filter('statusLabel')(SET_STATUSES.FAILED), key: 'set_status', value: SET_STATUSES.FAILED },
      { name: 'Status: ' + $filter('statusLabel')(SET_STATUSES.PENDING), key: 'set_status', value: SET_STATUSES.PENDING },
      { name: 'Status: ' + $filter('statusLabel')(SET_STATUSES.OUTDATED), key: 'set_status', value: SET_STATUSES.OUTDATED },
      { name: 'Status: ' + $filter('statusLabel')(SET_STATUSES.REQUESTED), key: 'set_status', value: SET_STATUSES.REQUESTED },
      { name: 'Field Name: PatientIDN', key: 'fieldname', value: 'PatientIDN' },
      { name: 'Field Name: NRef', key: 'fieldname', value: '' }
    ];

    activate();

    function activate () {
      var refresh = $state.params && $state.params.refresh ? $state.params.refresh : false;
      loadFilter();
      reloadList(refresh);
    }

    //* ***************
    // PUBLIC METHODS
    //* ***************

    function clearSelection () {
      SetsSrv.clearSelected(ctx);
      vm.selected = {};
      $mdSidenav('setDetails').close();
    }

    function createSet (id) {
      SetsSrv.createSet(id)
        .then(function () {
          psNotification.success('Set creation request received.');
        }).catch(function (resp) {
          if (resp.status === 403) {
            psNotification.warn('You are not authorized to run this query.');
          } else {
            psNotification.warn('Unable to create set. Please check your query, fieldname, and stores, and try again.');
          }
        });
    }

    function deactivateSet (id) {
      SetsSrv.deactivateSet(id)
        .then(function () {
          psNotification.success('Set deactivation request received.');
        }).catch(function () {
          psNotification.warn('Unable to disable set at this time. Please try again later.');
        });
    }

    function deleteSet (id) {
      var confirm = $mdDialog.confirm()
        .title('Delete Set Definition')
        .textContent('Are you sure you want to delete this set definition?')
        .ok('Delete')
        .cancel('Cancel');

      $mdDialog.show(confirm)
        .then(function () {
          SetsSrv.deleteSet(id)
            .then(function () {
              $mdDialog.hide();
              psNotification.success('Set definition deleted.');
              clearSelection();
              reloadList(true);
            });
        });
    }

    function filterList (set) {
      var regex = new RegExp(vm.searchFilterText, 'i');

      if (!set.subheader) {
        return vm.searchFilterText ? regex.test(set.name) : true;
      }
      return (SetsSrv.getSetsForSession(set.name)
        .filter(function (def) { return (vm.searchFilterText ? regex.test(def.name) : true) && matchesFilter(def); }).length > 0);
    }

    function getFilteredCount (defs) {
      if (defs) {
        return defs.filter(function (d) { return !d.subheader; }).length;
      }
      return 0;
    }

    function reloadList (force) {
      vm.loading = true;
      loadList(force)
        .then(function () {
          return loadList(true, true);
        })
        .catch(function () {
          psNotification.error('Error retrieving set definitions.');
        })
        .finally(function () {
          vm.loading = false;
        });
    }

    function resetFilters () {
      vm.searchFilterText = '';
      vm.selectedFilter = '';
      selectFilterList();
    }

    function selectFilterList () {
      SetsSrv.setFilter(vm.selectedFilter.name);
      vm.sets = $filter('filter')(allSets, function (value) {
        return matchesFilter(value);
      });
    }

    function selectSet (set) {
      SetsSrv.getSet(set.id).then(function (setDef) {
        if (vm.selected) {
          clearSelection();
        }
        vm.selected = setDef;
        SetsSrv.setSelected(ctx, setDef.id);
        $mdSidenav('setDetails').open();
      });
    }

    function showStaleWarning (def) {
      return def.set_status === SET_STATUSES.CREATED &&
          def.set_activated_at &&
          (new Date(def.set_activated_at).getTime() < (new Date().getTime() - 24 * 60 * 60 * 1000));
    }

    //* *****************
    // PRIVATE METHODS
    //* *****************

    function loadFilter () {
      var savedFilter = SetsSrv.getFilter();
      if (!savedFilter) {
        vm.selectedFilter = '';
      } else {
        vm.filters.forEach(function (f) {
          if (savedFilter === f.name) {
            vm.selectedFilter = f;
          }
        });
      }
    }

    function loadList (force, checkStatus) {
      return SetsSrv.getWorld(force, checkStatus)
        .then(function (data) {
          vm.selected = SetsSrv.getSelected(ctx);
          if (vm.selected) {
            $mdSidenav('setDetails').open();
          }
          allSets = data;
          selectFilterList();
        });
    }

    function matchesFilter (value) {
      if (value.subheader) {
        return true;
      }
      return (vm.selectedFilter) ? value[vm.selectedFilter.key] === vm.selectedFilter.value : true;
    }
  }
})();
