(function () {
  'use strict';

  angular.module('tools', [
    'imat.services', // releaseSrv
    'ngAnimate',
    'ngMaterial', // $mdSidenav
    'ui.router'
  ]);
})();
