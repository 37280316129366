(function () {
  'use strict';

  angular.module('dashboardApp')
    .controller('AddDashboardItemModalCtrl', AddDashboardItemModalCtrl);

  AddDashboardItemModalCtrl.$inject = ['$mdDialog', '$scope', 'DashboardItemClass', 'DashboardItemsSrv', 'modalInternalItems', 'modalUserRoles'];

  function AddDashboardItemModalCtrl ($mdDialog, $scope, DashboardItemClass, DashboardItemsSrv, modalInternalItems, modalUserRoles) {
    $scope.IMAGE_TYPES = DashboardItemClass.prototype.IMAGE;
    $scope.cancel = $mdDialog.cancel;
    $scope.submit = $mdDialog.hide;
    $scope.tiles = [];

    activate();

    function activate () {
      DashboardItemsSrv.getItems(modalUserRoles)
        .then(function (tiles) {
          tiles.forEach(function (i) { i.disabled = (i.cfg.category === 'internal' && modalInternalItems.indexOf(i.link.href) >= 0); });
          $scope.tiles = tiles.sort(sortTileTitle);
        });
    }

    function sortTileTitle (a, b) {
      var aName = a.link.text.toLowerCase();
      var bName = b.link.text.toLowerCase();
      if (aName < bName) { return -1; }
      if (aName > bName) { return 1; }
      return 0;
    }
  }
})();
