(function () {
  'use strict';

  angular
    .module('vhr')
    .controller('GenericSummaryCtrl', GenericSummaryCtrl);

  GenericSummaryCtrl.$inject = [
    'MODE', 'VHR_REPORT', 'uiGridConstants',
    '$scope', '$state', '$log', '$q', '$timeout',
    'psNotification', 'psReports', 'vhrApp', 'vhrConfigSrv', 'vhrGridSrv', 'vhrPatientSrv'
  ];

  function GenericSummaryCtrl (
    MODE, VHR_REPORT, uiGridConstants,
    $scope, $state, $log, $q, $timeout,
    psNotification, psReports, vhrApp, vhrConfigSrv, vhrGridSrv, vhrPatientSrv
  ) {
    var vm = this;

    $scope.INPUT_TYPES = psReports.INPUT_TYPES;

    // Properties
    vm.config = vhrConfigSrv.getPageByState($state.current.name);
    vm.generating = false;
    vm.gridApi = {};
    vm.gridConfig = {};
    vm.gridRouting = {};
    vm.grids = [];
    vm.loaded = { filter: false, report: false };
    vm.recordTypes = [];
    vm.report = {};
    vm.reportFilters = {};
    vm.reportParams = {};
    vm.showFilter = vhrApp.showFilter();
    vm.workspace = vm.config.stateName;

    // Methods
    vm.redirectToDetail = redirectToDetail;
    vm.resetFilter = resetFilter;
    vm.submitFilter = submitFilter;

    activate();

    // Implementation

    function activate () {
      vhrApp.enableModes(true);
      vhrApp.setMode(MODE.VIEW);

      $scope.$on('vhrFilterShown', function (evt, showFilter) { vm.showFilter = showFilter; });
      $scope.$on('vhrRefreshed', function () { refresh(); });
      vhrApp.enableFilter(true);
      vhrApp.enableRefresh(false);

      vm.generating = true;
      setGridConfig();
      setRecordTypes();

      loadReport()
        .then(function () {
          return loadReportData(angular.copy(vm.reportParams));
        })
        .catch(function (reason) {
          reason = reason || 'Failed to load the report.';
          psNotification.error(reason);
        })
        .finally(function () {
          ready();
          handleGridOnRegisterApi();
        });
    }

    function handleGridOnRegisterApi () {
      angular.forEach(vm.gridApi, function (grid) {
        grid.core.handleWindowResize();
      });
    }

    function loadReport () {
      return psReports.load(vm.config.reportId, VHR_REPORT.CALLER, { initParams: vhrConfigSrv.getReportParameters(vm.config.reportId) })
        .then(function (report) {
          vm.report = report;
          vm.reportParams = report.getLastParameters(vm.workspace, true) || report.getParameterDefaults(true);
          setReportFilters(vm.reportParams);
        })
        .catch(function (reason) {
          $log.debug(reason);
          return $q.reject('Failed to prepare the report.');
        })
        .finally(function () {
          vhrApp.enableRefresh((vm.report && !!vm.report.id));
        });
    }

    function loadReportData (params, force) {
      if (vm.config.parameters) {
        angular.forEach(vm.config.parameters, function (param, paramName) {
          if (typeof params[paramName] === 'undefined') {
            $log.debug('The page configuration is trying to set an unexpected parameter: ' + paramName);
            return;// continue
          }
          params[paramName] = param;
        });
      }

      params.limit = params.limit || 5;
      params.patient_id = vhrPatientSrv.raw.mpid;
      params.record_types = params.record_types || { value: vm.recordTypes };
      params.search = params.search || '';
      params.summary = true;

      return vm.report.run(vm.workspace, params, force)
        .then(function () {
          return vm.report.results[vm.workspace].getPollPromise();
        })
        .then(function () {
          return vm.report.getArtifact(vm.workspace, 'results.json');
        })
        .then(normalizeVhrReportData)
        .then(function (reportData) {
          reportData.forEach(function (gridData) {
            angular.forEach(gridData, function (data, source) {
              if (vm.recordTypes.indexOf(source) >= 0) {
                if (Object.prototype.hasOwnProperty.call(vm.gridConfig, source)) {
                  vm.gridConfig[source].data = data || [];
                  vhrGridSrv.setGridHeight(vm.gridApi[source].grid);
                }
              }
            });
          });
        })
        .catch(function (reason) {
          $log.debug(reason);
          return $q.reject('Failed to load the patient data.');
        });
    }

    function normalizeVhrReportData (data) {
      var normalized = [];

      // Assume legacy format.
      // [{ sections: { query: 'foo', record_type: 'bar', records: [...] } }]
      if (data.length && Object.prototype.hasOwnProperty.call(data[0], 'sections')) {
        data.forEach(function (obj) {
          var packed = {};
          var sec = obj.sections;
          var source = sec.record_type.toUpperCase();// Ignore config/report case mismatches for record types.

          packed[source] = sec.records || [];
          normalized.push(packed);
        });
        return normalized;// [{ 'SOURCEA': [recordsA] }, { 'SOURCEB': [recordsB] }, ...]
      }

      // Assume desired format.
      data.forEach(function (obj) {
        angular.forEach(obj, function (rows, source) {
          var packed = {};
          // Ignore config/report case mismatches for record types.
          source = source.toUpperCase();
          packed[source] = Array.isArray(rows) ? rows : [];
          normalized.push(packed);
        });
      });
      return normalized;// [{ 'SOURCEA': [recordsA] }, { 'SOURCEB': [recordsB] }, ...]
    }

    function ready () {
      vm.generating = false;
      vm.loaded.report = true;
      $timeout(function () {
        setGridRowSelections(vhrApp.getMode());
      });
    }

    function redirectToDetail (recordType) {
      var route = vm.gridRouting[recordType];

      $state.get(route.stateName).data.useWorkspaceParams = vm.workspace;
      $state.go(route.stateName);
    }

    function refresh () {
      vm.generating = true;
      vm.loaded.report = false;

      angular.forEach(vm.gridConfig, function (cfg) {
        cfg.data.splice(0);
      });

      loadReportData(angular.copy(vm.reportParams), true)
        .catch(function (reason) {
          psNotification.error(reason);
        })
        .finally(function () {
          ready();
          angular.forEach(vm.gridApi, function (gridApi) {
            $timeout(gridApi.core.handleWindowResize);
          });
        });
    }

    function resetFilter () {
      vm.reportParams = vm.report.getParameterDefaults(true);
      setReportFilters(vm.reportParams);
      submitFilter();
    }

    function setReportFilters (params) {
      var blacklist = ['limit', 'patient_id', 'record_types', 'summary'];
      vm.reportFilters = [];

      angular.forEach(params, function (param, paramName) {
        var def = angular.copy(param);

        if (param.visible === false || blacklist.indexOf(paramName) !== -1) {
          return;// continue
        }
        if (vm.config.parameters && vm.config.parameters[paramName]) {
          def.value = vm.config.parameters[paramName];
        }
        // If input a date, update value to Date instead of string
        if (param.type === psReports.PARAM_TYPES.DATE) {
          def.dateOptions = {
            maxDate: new Date(),
            minDate: new Date(1900, 0, 1)
          };
          def.value = moment(param.value, 'YYYY-MM-DD').toDate();
        }
        vm.reportFilters.push({ name: paramName, value: def.value, def: def });
      });
      vm.loaded.filter = true;
    }

    function setGridConfig () {
      vm.config.grids2.forEach(function (grid, idx) {
        var gridDef = vhrConfigSrv.raw.grids[grid.grid] || { fieldOrder: [] };
        var gridConfig = gridDef.uiGridConfig || {};
        var gridRoute;
        var gridType = grid.source.toUpperCase();// Ignore config/report case mismatches for source.

        if (Array.isArray(vm.config.gridPages) && vm.config.gridPages.length) {
          gridRoute = vhrConfigSrv.getRouteByPage(vm.config.gridPages[idx]);
        }

        vm.gridConfig[gridType] = {};
        vm.gridRouting[gridType] = gridRoute || { pageTitle: vm.config.gridPages[idx], stateName: '' };
        vm.grids.push(gridType);

        gridConfig = vhrGridSrv.getGridConfig($scope, gridType, vhrGridSrv.CONFIG.RECORD_LIST, gridConfig, gridDef.fieldOrder);
        gridConfig.enableHorizontalScrollbar = uiGridConstants.scrollbars.NEVER;
        gridConfig.enableVerticalScrollbar = uiGridConstants.scrollbars.NEVER;
        angular.extend(vm.gridConfig[gridType], gridConfig);

        vhrGridSrv.setOnRegisterApi($scope, vm.gridConfig, vm.gridApi, gridType);
      });
    }

    function setGridRowSelections (mode) {
      switch (mode) {
        case MODE.PRINT:
          angular.forEach(vm.gridConfig, function (ignored, gridType) {
            vhrGridSrv.selectRowsInPrintQueue(vm.gridApi[gridType], vm.gridConfig[gridType]);
          });
          break;
        case MODE.VIEW:
          angular.forEach(vm.gridConfig, function (ignored, gridType) {
            vhrGridSrv.selectRowsInRecentViews(vm.gridApi[gridType]);
          });
          break;
        default:
          // Nothing to do.
          break;
      }
    }

    function setRecordTypes () {
      // This is what is expected to be seen, regardless of what the report can
      // return or what is actually requested (e.g., page "parameters" config).
      vm.recordTypes = Object.keys(vm.gridConfig);
    }

    function submitFilter () {
      vm.generating = true;
      vm.loaded.report = false;

      angular.forEach(vm.gridConfig, function (cfg) {
        cfg.data.splice(0);
      });

      // Wait for the datepicker to update its input.
      $timeout(function () {
        var params = angular.copy(vm.reportParams);

        vm.reportFilters.forEach(function (filter) {
          if (params[filter.name]) {
            if (params[filter.name].type === psReports.PARAM_TYPES.DATE) {
              params[filter.name].value = vhrConfigSrv.getIsoDate(filter.value);
            } else {
              params[filter.name].value = filter.value;
            }
          }
        });

        loadReportData(params, true)
          .catch(function (reason) {
            psNotification.error(reason);
          })
          .finally(function () {
            ready();
          });
      }, 550);
    }
  }
})();
