(function () {
  'use strict';

  angular
    .module('imat.services')
    .factory('UsersSrv', UsersSrv);

  UsersSrv.$inject = ['$http', '$q', 'ProvidersSrv', 'searchApiSrv', 'usersApiSrv'];

  function UsersSrv ($http, $q, ProvidersSrv, searchApiSrv, usersApiSrv) {
    // Variables
    var users = [];

    // Interface
    var service = {
      addGroups: addGroups,
      createUser: createUser,
      deleteUser: deleteUser,
      getUsersByUsername: getUsersByUsername,
      getList: getUserList,
      getUserGroups: getUserGroups,
      removeGroups: removeGroups,
      resetSecurityAnswers: resetSecurityAnswers,
      toggleUserDisable: toggleUserDisable,
      toggleUserLock: toggleUserLock,
      updateUserData: updateUserData,
      updateUserGroups: updateUserGroups,
      updateUserPasswordWithTemporaryEndpoint: updateUserPasswordWithTemporaryEndpoint
    };

    // Methods

    function addGroups (user, groups) {
      return usersApiSrv.updateUserGroups({ add: groups }, user.provider, user.username);
    }

    function createUser (user) {
      return usersApiSrv.createUser(user)
        .then(function (resp) {
          // TODO Wait for this to return?
          searchApiSrv.ensureSession('user_' + user.username + '@' + user.provider);
          return resp;
        });
    }

    function deleteUser (user) {
      if (!user.provider || !user.username) {
        return $q.reject();
      }
      return usersApiSrv.deleteUser(user.provider, user.username);
    }

    function getUsersByUsername (usernameList) {
      if (!angular.isArray(usernameList)) {
        return $q.reject();
      }
      if (users.length === 0) {
        return getUserList().then(function () {
          return getUsersFromUsernames(usernameList);
        });
      }
      return $q.resolve(getUsersFromUsernames(usernameList));
    }

    function getUsersFromUsernames (usernameList) {
      var userList = [];
      usernameList.forEach(function (username) {
        userList.push(
          users.filter(function (user) {
            return user.username === username;
          })
        );
      });
      return userList;
    }

    function getUserGroups (user) {
      if (!user.provider || !user.username) {
        return $q.reject();
      }
      return usersApiSrv.getUserGroups(user.provider, user.username).then(function (groupsRes) {
        user.groups = parseUserGroupsResponse(groupsRes);
        return user;
      });
    }

    function getUserList (force, config) {
      if (!force && users.length) {
        return $q.resolve(users);
      }

      return ProvidersSrv.getProviderList().then(function (providers) {
        var promises = [];

        providers.forEach(function (provider) {
          promises.push(
            usersApiSrv.getList(provider, config).then(parseUserListReponse)
          );
        });

        return $q.all(promises)
          .then(function (res) {
            users = [];
            res.forEach(function (userList) {
              users = users.concat(userList);
            });
            return users;
          });
      });
    }

    function removeGroups (user, groups) {
      return usersApiSrv.updateUserGroups({ delete: groups }, user.provider, user.username);
    }

    function resetSecurityAnswers (user) {
      if (!user.provider || !user.username) {
        return $q.reject();
      }
      return usersApiSrv.resetSecurityAnswers(user.provider, user.username);
    }

    function toggleUserDisable (user) {
      if (!user.provider || !user.username) {
        return $q.reject();
      }
      return usersApiSrv.toggleDisable(user.disabled, user.provider, user.username);
    }

    function toggleUserLock (user) {
      if (!user.provider || !user.username) {
        return $q.reject();
      }
      return usersApiSrv.toggleLock(user.locked, user.provider, user.username);
    }

    function updateUserData (data, user) {
      if (!user.provider || !user.username) {
        return $q.reject();
      }
      return usersApiSrv.updateUserData(data, user.provider, user.username);
    }

    function updateUserPasswordWithTemporaryEndpoint (data, user) {
      if (!user.provider || !user.username) {
        return $q.reject();
      }
      return usersApiSrv.updateUserPasswordWithTemporaryEndpoint(data, user.provider, user.username);
    }

    function updateUserGroups (groups, user) {
      if (!user.provider || !user.username) {
        return $q.reject();
      }
      return usersApiSrv.updateUserGroups(groups, user.provider, user.username);
    }

    function parseUserListReponse (usersRes) {
      var userList = [];
      if (usersRes.data && usersRes.data.users) {
        userList = usersRes.data.users;
      }
      return userList;
    }

    function parseUserGroupsResponse (groupsRes) {
      var groups = [];
      if (groupsRes.data && groupsRes.data.groups && groupsRes.data.groups.group) {
        groups = angular.isArray(groupsRes.data.groups.group) ? groupsRes.data.groups.group : [groupsRes.data.groups.group];
      }
      return groups;
    }

    return service;
  }
})();
