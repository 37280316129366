(function () {
  'use strict';

  angular
    .module('vhr')
    .controller('CxmlModalCtrl', CxmlModalCtrl);

  CxmlModalCtrl.$inject = [
    'VHR_REPORT',
    '$scope', '$log', '$mdDialog', '$q', '$timeout', '$window',
    'DownloaderSrv', 'psNotification', 'psReports', 'vhrConfigSrv', 'vhrPatientSrv', 'vhrPrintSrv',
    'vhrRecordCollectionSrv', 'vhrUserSrv'
  ];

  function CxmlModalCtrl (
    VHR_REPORT,
    $scope, $log, $mdDialog, $q, $timeout, $window,
    DownloaderSrv, psNotification, psReports, vhrConfigSrv, vhrPatientSrv, vhrPrintSrv,
    vhrRecordCollectionSrv, vhrUserSrv
  ) {
    var vm = this;

    // Properties
    vm.dicomCheckEnabled = false;
    vm.enableDicom = false;
    vm.hide_attachments_info = false;
    vm.hide_details_info = false;
    vm.hide_original_doc_info = true;
    vm.hide_patient_info = false;
    vm.imageViewerHref = '';
    vm.loaded = { record: false, report: false, printView: false };
    vm.mpid = vhrPatientSrv.raw.mpid;
    vm.patient = vhrPatientSrv;
    vm.printSource = vhrConfigSrv.getPrintSource();
    vm.record = vhrRecordCollectionSrv.stashed();
    vm.recordQueued = false;
    vm.report = null;
    vm.reportParams = null;
    vm.showPdfFirst = false;
    vm.store = null;
    vm.uri = null;
    vm.user = vhrUserSrv;// Used in print template.
    vm.workspace = 'cxml-record-modal';

    // Methods
    vm.cancel = cancel;
    vm.dequeue = dequeue;
    vm.download = download;
    vm.print = print;
    vm.queue = queue;
    vm.toggleAttachmentsInfo = toggleAttachmentsInfo;
    vm.toggleDetailsInfo = toggleDetailsInfo;
    vm.toggleOriginalDocumentInfo = toggleOriginalDocumentInfo;
    vm.togglePatientInfo = togglePatientInfo;
    vm.userCanDownloadDoc = vhrUserSrv.canDownloadOriginalDocuments();
    vm.userCanViewDoc = vhrUserSrv.canViewOriginalDocuments();

    activate();

    // Implementation

    function activate () {
      vm.recordQueued = (typeof vhrRecordCollectionSrv.find('printQueue', vm.record) !== 'undefined');
      loadReport()
        .then(function () {
          return loadReportData(angular.copy(vm.reportParams));
        })
        .then(function () {
          return loadDataArtifact();
        })
        .then(function () {
          vm.loaded.record = true;
          if (vhrUserSrv.isAdmin()) {
            loadDocumentArtifact()
              .catch(function (reason) {
                psNotification.error(reason);
              });
          }
        })
        .then(checkDicom)
        .catch(function (reason) {
          reason = reason || 'Failed to load the record.';
          psNotification.error(reason);
        })
        .finally(function () {
          vm.loaded.report = true;
          vm.loaded.printView = true;
        });

      // Capture Ctrl+P and route through vhrPrintSrv.print().
      angular.element($window.document).on('keyup keydown', function (evt) {
        if (evt.ctrlKey && evt.keyCode === 80) {
          evt.preventDefault();
          print();
          return false;
        }
      });
    }

    function cancel () {
      $mdDialog.cancel();
    }

    function checkDicom () {
      //if dicom workflow is enabled, we need to find out if button should be enabled
      vm.dicomCheckEnabled = vm.record.cfg.parameters.dicom_check_enabled || false;
      if (vm.dicomCheckEnabled) {
        return vm.report.getArtifact(vm.workspace, 'dicom.json')
        .then(function (response) {
          vm.enableDicom = response.enableDicom || false;
          vm.store = vm.record.getStore();
          vm.uri = vm.record.getUri();
          return;
        });
      }
      return;
    }

    function dequeue () {
      vhrRecordCollectionSrv.construct('printQueue').remove(vm.record);
      vm.recordQueued = false;
    }

    function download () {
      DownloaderSrv.download(vm.record.getXmlDocument(), {
        filename: 'HL7_' + vm.record.getUri(),
        processors: [
          function (doc) { return doc == null ? '' : doc.replace(/<\/?document>/g, ''); },
          DownloaderSrv.PROCESS.revertXmlEntities
        ],
        type: DownloaderSrv.MIME.TEXT
      });
    }

    function loadDataArtifact () {
      var artifactName = vm.record.getUri() + '.json';

      return vm.report.getArtifact(vm.workspace, artifactName)
        .then(function (response) {
          var raw = response.pop();
          var rec = angular.extend({}, vm.record.raw, raw);
          var reMrnAtFac = /^\s*([^\@]+)\s*\@\s*(.+)\s*$/; // eslint-disable-line no-useless-escape

          vm.record.set(rec);

          if (rec.patient_local_id && typeof rec.patient_local_id === 'string') {
            vm.record.mrnList = rec.patient_local_id.split(/\|/).map(function (fmrn) { return fmrn.replace(reMrnAtFac, '$1 [$2]'); });
          }
          if (rec.attachments && rec.attachments.length) {
            rec.attachments.forEach(function (attachment) {
              if (attachment.type === 'application/pdf') {
                vm.showPdfFirst = true;
              }
            });
          }
          setupImageViewerHref(rec);
        })
        .catch(function (reason) {
          $log.debug(reason);
          return $q.reject('Failed to load the record data.');
        });
    }

    function loadDocumentArtifact () {
      var artifactName = vm.record.getUri() + '.xml';

      return vm.report.getArtifact(vm.workspace, artifactName)
        .then(function (response) {
          vm.record.setDocument(response.original_response);
        })
        .catch(function (reason) {
          $log.debug(reason);
          return $q.reject('Failed to load the record document.');
        });
    }

    function loadReport () {
      var reportId = vm.record.getReportId();

      return psReports.load(reportId, VHR_REPORT.CALLER, { initParams: vhrConfigSrv.getReportParameters(reportId) })
        .then(function (report) {
          vm.report = report;
          vm.reportParams = report.getParameterDefaults();
        })
        .catch(function (reason) {
          $log.debug(reason);
          return $q.reject('Failed to prepare the record.');
        });
    }

    function loadReportData (params) {
      params.records = {};
      params.records[vm.record.getUri()] = {
        store: vm.record.getStore(),
        view_type: vm.record.getType()
      };
      params = angular.extend({}, params, vm.record.getReportParams());

      return vm.report.run(vm.workspace, params)
        .then(function () {
          return vm.report.results[vm.workspace].getPollPromise();
        })
        .catch(function (reason) {
          $log.debug(reason);
          return $q.reject('Failed to load the record.');
        });
    }

    function print () {
      vhrPrintSrv.print();
    }

    function queue () {
      vhrRecordCollectionSrv.construct('printQueue').insert(vm.record);
      vm.recordQueued = true;
    }

    function setupImageViewerHref (record) {
      if (record && record.dicom_grid_link) {
        vm.imageViewerHref = record.dicom_grid_link;
      }
    }

    function toggleAttachmentsInfo () {
      vm.hide_attachments_info = !vm.hide_attachments_info;
    }

    function toggleDetailsInfo () {
      vm.hide_details_info = !vm.hide_details_info;
    }

    function toggleOriginalDocumentInfo () {
      vm.hide_original_doc_info = !vm.hide_original_doc_info;
    }

    function togglePatientInfo () {
      vm.hide_patient_info = !vm.hide_patient_info;
    }
  }
})();
