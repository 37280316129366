(function () {
  'use strict';

  angular.module('admin')
    .controller('shareCtrl', shareCtrl);

  shareCtrl.$inject = [
    'ACCESS_LEVELS',
    '$animate', '$mdDialog', '$q', '$state', '$timeout', '$transition$', '$transitions',
    'Permissions', 'ProvidersSrv', 'psNotification', 'ReportsSrv'
  ];

  function shareCtrl (
    ACCESS_LEVELS,
    $animate, $mdDialog, $q, $state, $timeout, $transition$, $transitions,
    Permissions, ProvidersSrv, psNotification, ReportsSrv
  ) {
    var PERMISSION_GET_ERROR = 'Unable to load permissions.';
    var PROVIDER_GET_ERROR = 'Unable to load providers.';
    var deregisterOnExit = $transitions.onExit({ exiting: 'app.admin.sharing.share' }, confirmExit);
    var vm = this; // eslint-disable-line no-invalid-this

    // Properties
    vm.access = ACCESS_LEVELS;
    vm.deleteAllState = false;
    vm.filteredList = [];
    vm.filters = [
      {
        name: 'Groups and Users',
        label: 'Group name or username'
      },
      {
        property: 'groupname',
        name: 'Groups',
        label: 'Group name or username'
      },
      {
        property: 'username',
        name: 'Users',
        label: 'Username'
      }
    ];
    vm.itemFinder = '';
    vm.listDisplay = {
      'Full Access': [],
      'Read/Execute': [],
      Execute: [],
      Invalid: []
    };
    vm.loading = {
      lists: true,
      permissions: true
    };
    vm.owner = {};
    vm.permissions = [];
    vm.permissionsStaging = [];
    vm.providers = [];
    vm.report = {
      id: $transition$.params().reportId,
      name: $transition$.params().reportName || $transition$.params().reportId,
      owner: $transition$.params().reportOwner
    };
    vm.selectedFilter = vm.filters[0];

    // Methods
    vm.addPermissions = addPermissions;
    vm.changeList = changeList;
    vm.changes = changes;
    vm.chipsUpdated = chipsUpdated;
    vm.deleteAllPermissions = deleteAllPermissions;
    vm.deletePermission = deletePermission;
    vm.execute = execute;
    vm.filterItems = filterItems;
    vm.full = full;
    vm.invalid = invalid;
    vm.isChanged = isChanged;
    vm.isOwner = isOwner;
    vm.readExecute = readExecute;
    vm.resetPermissions = resetPermissions;
    vm.sansProvider = sansProvider;
    vm.savePermissions = savePermissions;
    vm.transformChip = transformChip;
    vm.updateAccessRights = updateAccessRights;
    vm.updateAllAccessRights = updateAllAccessRights;

    activate();

    $animate.enabled(false);

    function activate () {
      ProvidersSrv.getProviderList()
        .then(function (providers) {
          vm.providers = providers;
        })
        .catch(function () {
          psNotification.error(PROVIDER_GET_ERROR);
        });

      refresh(true);
    }

    /// /////////////////
    // Public Methods //
    /// /////////////////

    function addPermissions (items) {
      if (items.length) {
        items.forEach(function (item) {
          updateAccessRights(item.permission, vm.addAs);
          highlight(item.permission);
          vm.permissions.push(item.permission);
          vm.itemFinder = '';
          vm.permissionsStaging = [];
        });
        vm.list = flagExistingPermissions(vm.list, vm.permissions);
      }
    }

    function changeList (filter) {
      vm.filteredList = filter.property ? vm.list.filter(function (i) { return i.permission.authentication_type === filter.property; }) : vm.list;
      filterItems(vm.itemFinder);
    }

    function changes () {
      var changed = false;
      if (!vm.permissions.length) {
        return changed;
      }
      if (vm.permissionsOriginal.length !== vm.permissions.length) {
        return true;
      }
      for (var i = 0; i < vm.permissions.length; i++) {
        if (isChanged(vm.permissions[i])) {
          changed = true;
          break;
        }
      }
      return changed;
    }

    function chipsUpdated () {
      vm.list = flagExistingPermissions(vm.list, vm.permissions);
      vm.changeList(vm.selectedFilter);
      vm.filteredItems = [];
    }

    function confirmExit (ev) {
      var confirm = $mdDialog.confirm()
        .title('Unsaved Changes')
        .textContent('You have unsaved changes. Do you wish to discard them?')
        .ok('Discard')
        .cancel('Cancel')
        .clickOutsideToClose(true);
      if (changes() && !ev._targetState._params.bypass) {
        return $mdDialog.show(confirm)
          .then(function () {
            deregisterOnExit();
            return true;
          })
          .catch(function () {
            return false;
          });
      }
    }

    function deleteAllPermissions () {
      if (vm.permissions.length > 1) { // If more than just the owner is in permissions list
        vm.deleteAllState = !vm.deleteAllState;
        vm.permissions.forEach(function (permission) {
          if (!isOwner(permission)) {
            deletePermission(permission, true);
          }
        });
      }
    }

    function deletePermission (permission, all) {
      permission.pendingDelete = all ? vm.deleteAllState : !permission.pendingDelete;
      if (!all) {
        var deleted = vm.permissions.filter(function (p) {
          return p.pendingDelete;
        });
        vm.deleteAllState = deleted.length + 1 === vm.permissions.length;
      }
    }

    function filterItems (filter) {
      var rexFilter = new RegExp(filter, 'i');
      vm.filteredItems = vm.filteredList.filter(function (item) {
        if (item.exists) { return false; }
        return rexFilter.test(item.username || item.name);
      });
    }

    function full (value) {
      return value.access_rights === ACCESS_LEVELS.FULL;
    }

    function invalid (item) {
      return item.access_rights !== ACCESS_LEVELS.FULL && item.access_rights !== ACCESS_LEVELS.READ_EXECUTE && item.access_rights !== ACCESS_LEVELS.EXECUTE;
    }

    function isChanged (item) {
      var index = findPermissionIndex(vm.permissionsOriginal, item);
      return index === -1 || item.pendingDelete || vm.permissionsOriginal[index].access_rights !== item.access_rights;
    }

    function isOwner (item) {
      var p = item.authentication_id.split(/@[\w]+$/);
      var o = vm.report.owner.split(/@[\w]+$/);
      // Normalize empty provider
      [p, o].forEach(function (arr) {
        if (!arr[1]) {
          arr.push('');
        }
      });
      return p[0] === o[0] && p[1] === o[1] && item.authentication_type !== 'groupname';
    }

    function readExecute (value) {
      return value.access_rights === ACCESS_LEVELS.READ_EXECUTE;
    }

    function resetPermissions () {
      refresh();
      vm.deleteAllState = false;
    }

    function execute (value) {
      return value.access_rights === ACCESS_LEVELS.EXECUTE;
    }

    function sansProvider (str) {
      return str.split(/@[\w]+$/)[0];
    }

    function savePermissions () {
      var updated;

      // Filter out permissions tagged for deletion
      updated = vm.permissions.filter(function (permission) {
        return !permission.pendingDelete;
      });

      return Permissions.savePermissions(vm.report.id, updated, { admin: true })
        .then(function () {
          psNotification.success('Permissions updated.');
        })
        .then(function () {
          ReportsSrv.cacheItem(vm.report);
          ReportsSrv.ejectCachedItem(vm.report.id);
          $state.go('app.admin.sharing');
        })
        .catch(function (response) {
          psNotification.error('Unable to update the report.');
          return $q.reject(response);
        })
        .finally(function () {
          refresh(true);
        });
    }

    function transformChip (chip) {
      if (angular.isObject(chip)) {
        return chip;
      }
      return null;
    }

    function updateAccessRights (item, value) {
      if (item && !isOwner(item) && !item.pendingDelete) {
        if (value) {
          item.access_rights = value;
        }
        switch (item.access_rights) {
          case 7:
            item.updatedRead = true;
            item.updatedWrite = true;
            item.updatedExecute = true;
            break;

          case 5:
            item.updatedRead = true;
            item.updatedWrite = false;
            item.updatedExecute = true;
            break;

          case 1:
            item.updatedRead = false;
            item.updatedWrite = false;
            item.updatedExecute = true;
            break;

          default:
            item.updatedRead = false;
            item.updatedWrite = false;
            item.updatedExecute = false;
        }
      }
    }

    function updateAllAccessRights (value) {
      vm.permissions.forEach(function (permission) {
        if (!isOwner(permission)) {
          updateAccessRights(permission, value);
        }
      });
    }

    /// //////////////////
    // Private Methods //
    /// //////////////////

    function checkForInvalidPermissions (permissions, list) {
      // Check if group or username associated with permission exists anymore
      // and flag permission if it doesn't
      return permissions.map(function (p) {
        var invalidType = list.findIndex(function (i) {
          return i.permission.authentication_id === p.authentication_id;
        }) === -1;
        if (invalidType) {
          p.invalid_type = true;
        }
        return p;
      });
    }

    function findPermissionIndex (list, permission) {
      return list.findIndex(function (item) {
        return item.authentication_id === permission.authentication_id && item.authentication_type === permission.authentication_type;
      });
    }

    function getReport () {
      return ReportsSrv.getReport(vm.report.id, { admin: true })
        .then(function (report) {
          return report.permissions || Permissions.getCurrentAccess(vm.report.id);
        })
        .catch(function () {
          return $q.reject(PERMISSION_GET_ERROR);
        })
        .finally(function () {
          vm.loading.permissions = false;
        });
    }

    function highlight (item) {
      item.highlight = true;
      $timeout(function () {
        item.highlight = false;
      }, 1500);
    }

    function flagExistingPermissions (list, permissions) {
      list.forEach(function (item) {
        item.exists = checkExistingPermissions(permissions, item);
      });
      list.sort(function (a, b) {
        var aName = a.name.toLowerCase().replace(/[ _]/g, '.') || a.username.toLowerCase().replace(/[ _]/g, '.');
        var bName = b.name.toLowerCase().replace(/[ _]/g, '.') || b.username.toLowerCase().replace(/[ _]/g, '.');
        if (aName < bName) { return -1; }
        if (aName > bName) { return 1; }
        return 0;
      });
      return list;
    }

    function loadLists (force) {
      if (!force && vm.listOriginal) {
        return vm.listOriginal;
      }
      var promises = [Permissions.getUsers(vm.providers), Permissions.getGroups(vm.providers)];
      return $q.all(promises)
        .then(function (results) {
          return results[0].concat(results[1]);
        });
    }

    function refresh (force) {
      if (!force) {
        vm.list = angular.copy(vm.listOriginal);
        vm.permissions = angular.copy(vm.permissionsOriginal);
        vm.filteredList = angular.copy(vm.list);
        filterItems(vm.itemFinder);
        return;
      }
      vm.list = [];
      vm.permissions = [];
      vm.loading.lists = true;
      vm.loading.permissions = true;
      var promises = [loadLists(true), getReport()];
      return $q.all(promises)
        .then(function (results) {
          vm.listOriginal = flagExistingPermissions(results[0], results[1]);
          vm.permissionsOriginal = checkForInvalidPermissions(results[1], results[0]);
          vm.owner = getOwner(vm.permissionsOriginal);
          vm.list = angular.copy(vm.listOriginal);
          vm.permissions = angular.copy(vm.permissionsOriginal);
          vm.filteredList = angular.copy(vm.list);
        })
        .catch(function () {
          return $q.reject('Error retrieving users and groups lists.');
        })
        .finally(function () {
          vm.loading.lists = false;
          vm.loading.permissions = false;
        });
    }

    function getOwner (permissions) {
      // Remove owner from permissions so it gets sorted first every time
      var ownerIndex = permissions.findIndex(function (p) {
        // Have to check because some owners show up without @provider
        // and owners can have an @ symbol in the username or group name :(
        return isOwner(p);
      });
      return permissions[ownerIndex] || {};
    }

    function checkExistingPermissions (permissions, item) {
      return permissions.findIndex(function (p) {
        return (p.authentication_id === item.permission.authentication_id &&
                p.authentication_type === item.permission.authentication_type) ||
                (vm.owner.authentication_id === item.permission.authentication_id &&
                vm.owner.authentication_type === item.permission.authentication_type);
      }) > -1;
    }
  } // End controller.
})();
