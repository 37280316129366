(function () {
  'use strict';

  angular
    .module('vhr.directives')
    .directive('vhrReportTimestamp', vhrDir);

  vhrDir.$inject = ['$filter'];

  function vhrDir ($filter) {
    var component = {
      restrict: 'E',
      link: postLink,
      scope: {
        ngHide: '=',
        ngIf: '=',
        ngShow: '=',
        watch: '=',
        ts: '='
      },
      templateUrl: '/ui/vhr/directives/vhrReportTimestamp/vhr-report-timestamp.html'
    };

    return component;

    function postLink (scope, element, attributes) {
      if (attributes.watch) {
        scope.$watch('watch', function (curr) {
          if (curr) { setTs(scope); }
        });
      } else if (attributes.ngIf) {
        scope.$watch('ngIf', function (curr) {
          if (curr) { setTs(scope); }
        });
      } else if (attributes.ngShow) {
        scope.$watch('ngShow', function (curr) {
          if (curr) { setTs(scope); }
        });
      } else if (attributes.ngHide) {
        scope.$watch('ngHide', function (curr) {
          if (!curr) { setTs(scope); }
        });
      } else {
        scope.$watch('ts', function (curr) {
          if (curr) { setTs(scope); }
        });
      }

      scope.$on('vhrRefreshed', function () {
        setTs(scope);
      });

      function setTs (scope) { // eslint-disable-line no-shadow
        scope.timestamp = $filter('date')(scope.ts, 'MM/dd/yyyy hh:mm:ss a');
      }
    }
  }
})();
