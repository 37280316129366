(function () {
  'use strict';

  angular.module('vhr')
    .controller('GenericLinksCtrl', GenericLinksCtrl);

  GenericLinksCtrl.$inject = [
    '$mdSidenav', '$state',
    'vhrApp', 'vhrConfigSrv'
  ];

  function GenericLinksCtrl (
    $mdSidenav, $state,
    vhrApp, vhrConfigSrv
  ) {
    var vm = this;

    // Properties
    vm.config = {};
    vm.links = {};
    // Methods
    vm.toggleNav = toggleNav;

    function toggleNav () {
      $mdSidenav('sidenav').toggle();
    }

    activate();

    function activate () {
      vm.config = vhrConfigSrv.getPageByState($state.current.name);
      vm.links = vm.config.links || {};

      vhrApp.enableFilter(false);
      vhrApp.enableRefresh(false);
    }
  }
})();
