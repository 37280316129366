(function () {
  'use strict';

  angular.module('queries')
    .config(config);

  config.$inject = ['$stateRegistryProvider'];

  function config ($stateRegistryProvider) {
    $stateRegistryProvider.register({
      name: 'app.queries',
      abstract: true,
      url: '/queries/',
      data: {
        appKey: 'queries',
        beta: true,
        roles: ['system', 'search']
      },
      views: {
        'content@app': {
          templateUrl: '/ui/queries/layout/queries-layout.html',
          controller: 'QueriesLayoutCtrl',
          controllerAs: 'ctrl'
        }
      }
    });
    $stateRegistryProvider.register({
      name: 'app.queries.builder',
      url: '',
      views: {
        'page-content@app.queries': {
          templateUrl: '/ui/queries/query-builder/query-builder.html',
          controller: 'QueryBuilderCtrl',
          controllerAs: 'ctrl'
        }
      }
    });
    $stateRegistryProvider.register({
      name: 'app.queries.exports',
      url: 'exports/',
      params: {
        id: null,
        action: null,
        cache: null
      },
      views: {
        'page-content@app.queries': {
          templateUrl: '/ui/queries/query-exports/query-exports.html',
          controller: 'QueriesExportsCtrl',
          controllerAs: 'ctrl'
        }
      }
    });
    $stateRegistryProvider.register({
      name: 'app.queries.results',
      url: 'results/',
      params: {
        id: null,
        action: null,
        cache: null
      },
      views: {
        'page-content@app.queries': {
          templateUrl: '/ui/queries/query-results/query-results.html',
          controller: 'QueryResultsCtrl',
          controllerAs: 'ctrl'
        }
      }
    });
    $stateRegistryProvider.register({
      name: 'app.queries.records',
      url: 'record/',
      views: {
        'content@app': {
          templateUrl: '/ui/queries/layout/records-layout.html',
          controller: 'RecordsLayoutCtrl',
          controllerAs: 'ctrl'
        },
        'page-content@app.queries.records': {
          templateUrl: '/ui/queries/record-details/record-details.html',
          controller: 'RecordDetailsCtrl',
          controllerAs: 'ctrl'
        }
      }
    });
  }
})();
