(function () {
  'use strict';

  angular.module('admin')
    .controller('UploaderSettingsDialogCtrl', UploaderSettingsDialogCtrl);

  UploaderSettingsDialogCtrl.$inject = ['$mdDialog', 'uri'];

  function UploaderSettingsDialogCtrl ($mdDialog, uri) {
    var vm = this;

    vm.uri = uri;

    vm.cancelDialog = cancelDialog;
    vm.submitDialog = submitDialog;

    function cancelDialog () {
      $mdDialog.cancel();
    }

    function submitDialog () {
      $mdDialog.hide(vm.uri);
    }
  }
})();
