(function () {
  'use strict';

  angular.module('imat.services')
    .factory('ContainersSrv', ContainersSrv);

  ContainersSrv.$inject = ['$http', '$q', 'containersApiSrv'];

  function ContainersSrv ($http, $q, containersApiSrv) {
    var containers = [];
    var selectedItem;

    var service = {
      clearSelected: clearSelected,
      getContainersList: getContainersList,
      getSelected: getSelected,
      setContainerState: setContainerState,
      setSelected: setSelected
    };

    function checkSelected () {
      if (selectedItem) {
        for (var i = 0; i < containers.length; i++) {
          if (containers[i].id === selectedItem.id) {
            selectedItem = containers[i];
            selectedItem.selected = true;
            return;
          }
        }
        clearSelected();
      }
    }

    function clearSelected () {
      if (selectedItem) {
        delete selectedItem.selected;
        selectedItem = {};
      }
    }

    function getContainersList (force) {
      if (!force && containers.length) {
        return $q.resolve(containers);
      }

      return containersApiSrv.getList()
        .then(function (response) {
          containers = _parseContainers(response.data.status.containers);
          checkSelected();
          return containers;
        });
    }

    function getSelected () {
      return selectedItem;
    }

    function setContainerState (provider, username, state) {
      return containersApiSrv.setState(provider, username, state);
    }

    function setSelected (container) {
      clearSelected();
      selectedItem = container;
      selectedItem.selected = true;
    }

    function _parseContainers (obj) {
      var arr = [];
      var keys = Object.keys(obj);

      // Add object key to object as a property, then parse object to array.
      keys.forEach(function (key) {
        if (obj[key]) {
          obj[key].id = key;
          arr.push(obj[key]);
        } else {
          obj[key] = {
            id: key,
            status: {},
            unavailable: true
          };
          arr.push(obj[key]);
        }
      });

      // Sort array by up_time as well as idle_time
      arr.sort(function (a, b) {
        return a.status.up_time - b.status.up_time || a.status.idle_time - b.status.idle_time;
      });

      return arr;
    }

    return service;
  }
})();
