(function () {
  'use strict';

  angular.module('vhr.services')
    .service('vhrCxmlMedStatementRecordClass', vhrCxmlMedStatementRecordClass);

  vhrCxmlMedStatementRecordClass.$inject = [
    'VhrCxmlRecordClass'
  ];

  function vhrCxmlMedStatementRecordClass (
    VhrCxmlRecordClass
  ) {
    function CxmlMedStatementRecord (cfg) {
      VhrCxmlRecordClass.call(this, cfg);
      this.printTemplateUrl = '/ui/vhr/templates/medications/print/med-statement.html';
      this.templateUrl = '/ui/vhr/templates/medications/cxml-med-statement-modal.html';
    }

    CxmlMedStatementRecord.prototype = Object.create(VhrCxmlRecordClass.prototype);
    CxmlMedStatementRecord.prototype.constructor = CxmlMedStatementRecord;

    CxmlMedStatementRecord.prototype.set = function (raw, setFields) {
      if (setFields === true) {
        setFields = [];
      }
      VhrCxmlRecordClass.prototype.set.call(this, raw, setFields);
    };

    return CxmlMedStatementRecord;
  }
})();
