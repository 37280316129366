(function () {
  'use strict';

  angular
    .module('vhr.directives')
    .directive('datepickerLabel', vhrCmp);

  vhrCmp.$inject = ['$timeout'];

  function vhrCmp ($timeout) {
    var component = {
      restrict: 'E',
      scope: {
        onClose: '=',
        onBlur: '=',
        onFocus: '=',
        onOpen: '='
      },
      link: postLink,
      transclude: true
    };

    function postLink (scope, element, attributes, controller, transcludeFn) {
      transcludeFn(function (content) {
        var dp, dpi, i, label;

        // Find the label...
        for (i = 0; i < content.length; ++i) {
          if (content[i].nodeName === 'LABEL') {
            label = angular.element(content[i]);
            break;
          }
        }
        if (label) {
          label.addClass('datepicker-label');
          // Get DOM representations of the datepicker and its input...
          dp = angular.element(document.getElementById(label.attr('for')));
          dpi = dp.find('input');
          // Inject the label, and remove the component wrapper...
          element.after(label);
          element.remove();
          // Hack the datepicker...
          hackTheDatepicker(scope, label, dp, dpi);
        }
      });
    }

    function hackTheDatepicker (scope, label, dp, dpi) {
      var closed = true;
      var tform = scope.$parent[angular.element(dpi[0].form).attr('name')];

      // In this function, tform/tdp are template representations of the
      // form/datepicker, whereas label/dp/dpi are jqLite/DOM representations.

      dpi
        .on('blur', function () {
          var tdp = tform[dp.attr('name')];

          scope.$apply(function () {
            if (scope.onBlur) { scope.onBlur(tdp, label, scope); }
          });
          $timeout(function () { blur(tdp, label, scope); });
        })
        .on('focus', function () {
          var tdp = tform[dp.attr('name')];

          scope.$apply(function () {
            if (scope.onFocus) { scope.onFocus(tdp, label, scope); }
          });
          $timeout(function () { focus(tdp, label, scope); });
        });

      scope.$watch(function () { return dpi.val(); }, function (val) {
        if (typeof val !== 'undefined') {
          $timeout(function () {
            var tdp = tform[dp.attr('name')];

            label.toggleClass('input-focused-alert', isInvalid(tdp));
          });
        }
      });

      scope.$watch(function () { return dp.hasClass('md-datepicker-open'); }, function (isOpen) {
        var tdp = tform[dp.attr('name')];

        if (isOpen === false && closed === false) {
          if (scope.onClose) { scope.onClose(tdp, label, scope); }
          $timeout(function () { close(tdp, label, scope); });
          closed = true;
        }
        if (isOpen === true && closed === true) {
          if (scope.onOpen) { scope.onOpen(tdp, label, scope); }
          $timeout(function () { open(tdp, label, scope); });
          closed = false;
        }
      });

      function isInvalid (tdp) {
        return Boolean(tdp.$invalid && (tdp.$touched || tdp.$dirty || dpi.val()));
      }

      function blur (tdp, label, scope) { // eslint-disable-line no-shadow
        close(tdp, label, scope);
      }

      function focus (tdp, label, scope) { // eslint-disable-line no-shadow
        open(tdp, label, scope);
      }

      function close (tdp, label) { // eslint-disable-line no-shadow
        if (isInvalid(tdp)) {
          label.removeClass('input-focused');
          label.addClass('input-focused-alert');
          label.find('span').addClass('signifier-required-alert');
        } else {
          label.removeClass('input-focused');
          label.removeClass('input-focused-alert');
          label.find('span').removeClass('signifier-required-alert');
        }
      }

      function open (tdp, label) { // eslint-disable-line no-shadow
        if (isInvalid(tdp)) {
          label.removeClass('input-focused');
          label.addClass('input-focused-alert');
          label.find('span').addClass('signifier-required-alert');
        } else {
          label.addClass('input-focused');
          label.removeClass('input-focused-alert');
          label.find('span').addClass('signifier-required-alert');
        }
      }
    }

    return component;
  }
})();
