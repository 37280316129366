(function () {
  'use strict';

  angular.module('reports')
    .controller('RunReportCtrl', RunReportCtrl);

  RunReportCtrl.$inject = ['$mdDialog', '$q', '$scope', 'psNotification', 'report', 'searchSrv'];

  function RunReportCtrl ($mdDialog, $q, $scope, psNotification, report, searchSrv) {
    $scope.reportDetails = {
      content: null,
      outputList: [],
      resultId: null
    };
    $scope.running = true;
    $scope.params = false;
    $scope.resultId = null;

    $scope.cancel = cancel;
    $scope.cancelReport = cancelReport;
    $scope.runReport = runReport;

    function activate () {
      runReport();
    }
    activate();

    function runReport () {
      $scope.running = true;
      var data = {
        c: report.content,
        usesession: true,
        saveresults: null,
        sourcetype: 'search',
        caller: 'clinical-reports'
      };

      if ($scope.params) {
        data.parameters = {
          mode: 'run',
          valid: true,
          parameters: $scope.params
        };
      }

      searchSrv.search(data, null, true)
        .then(function (res) {
          if (res.result_id) {
            $scope.resultId = res.result_id;
            return searchSrv.pollStatus($scope.resultId)
              .catch(function (error) {
                // Show error and exit modal
                psNotification.error(error);
                $mdDialog.cancel();
              });
          }
          psNotification.error('Error running the report.');
          return $q.reject();
        })
        .then(function () {
          return searchSrv.getResultOutput($scope.resultId, 'summary.json');
        })
        .then(function (artifactFile) {
          // Returns parameters that need to be filled in
          if (artifactFile.mode === 'params_only') {
            $scope.params = artifactFile.parameters;
            return $q.reject();
          }
          return $q.all([
            searchSrv.getResultOutput($scope.resultId),
            searchSrv.getResultOutput($scope.resultId, 'stdout.log'),
            searchSrv.getResultOutput($scope.resultId, 'summary.json')
          ]);
        })
        .then(function (res) {
          $scope.reportDetails.resultId = $scope.resultId;
          $scope.reportDetails.artifacts = res[0]; // artifactList
          $scope.reportDetails.content = res[1]; // artifactFile
          $scope.reportDetails.summary = res[2]; // artifactFile
          $mdDialog.hide($scope.reportDetails);
        })
        .catch(function () {
          cancel();
        })
        .finally(function () {
          $scope.running = false;
        });
    }

    function cancel () {
      $mdDialog.cancel();
    }

    function cancelReport () {
      if ($scope.resultId) {
        searchSrv.cancel($scope.resultId)
          .then(function () {
            $mdDialog.cancel();
          });
      }
    }
  }
})();
