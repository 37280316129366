(function () {
  'use strict';

  angular
    .module('vhr.services')
    .factory('vhrXsl', vhrXsl);

  vhrXsl.$inject = ['$http', '$log', '$q'];

  function vhrXsl ($http, $log, $q) {
    var service;
    var xsl = {};
    var defaultId = 'default';
    var defaultXsl = 'assets/xsl/cda/imat-cda.xsl';
    var defaultUrl = '/ui/';

    service = {
      getXsl: getXsl,
      getXslFile: getXslFile,
      xsl: xsl
    };

    return service;

    function getXsl (id) {
      return xsl[id];
    }

    function getXslFile (fileUrl, id) {
      if (getXsl(id)) {
        return $q.resolve(xsl[id]);
      }
      if (angular.isUndefined(fileUrl)) {
        fileUrl = defaultUrl + defaultXsl;
        id = defaultId;
      }
      return $http({
        method: 'GET',
        url: fileUrl
      })
        .then(function (response) {
          if (response.data && response.data.original_response) {
            xsl[id] = response.data.original_response;
          // xsl[id] = '<?xml version="1.0" encoding="UTF-8"?><xsl:stylesheet version="1.0" xmlns:xsl="http://www.w3.org/1999/XSL/Transform"><xsl:template match="/">hi</xsl:template></xsl:stylesheet>';
          } else {
            $log.debug('Unable to load the content of xsl file ' + fileUrl);
            return $q.reject();
          }

          return xsl[id];
        })
        .catch(function () {
          $log.debug('Unable to load xsl file ' + fileUrl);
          return $q.reject();
        });
    }
  }
})();
