(function () {
  'use strict';

  angular
    .module('imat.services')
    .factory('usersApiSrv', usersApiSrv);

  usersApiSrv.$inject = ['$http', '$q'];

  function usersApiSrv ($http, $q) {
    var baseUrl = '/appliance/users';
    var User = function (params) {
      params = params || {};
      [
        'modifiable',
        'removable',
        'membership_modifiable'
      ].forEach(function (key) {
        if (params[key]) {
          params[key] = params[key] === 'true';
        }
      });
      angular.extend(this, {
        username: '',
        name: ''
      }, params);
    };

    var service = {
      createUser: createUser,
      deleteUser: deleteUser,
      getList: getList,
      getUser: getUser,
      getUserGroups: getUserGroups,
      resetSecurityAnswers: resetSecurityAnswers,
      toggleLock: toggleLock,
      toggleDisable: toggleDisable,
      updateUserData: updateUserData,
      updateUserGroups: updateUserGroups,
      updateUserPasswordWithTemporaryEndpoint: updateUserPasswordWithTemporaryEndpoint
    };

    function createUser (user) {
      var url = [baseUrl, user.provider].join('/');

      return $http({
        method: 'POST',
        url: url,
        data: {
          username: user.username,
          name: user.name,
          password: user.password
        },
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      })
        .then(function () {
          return get(user.provider, user.username);
        });
    }

    function deleteUser (provider, username, config) {
      var url = [baseUrl, provider, username].join('/');
      return $http.delete(url, angular.extend({}, config || {}));
    }

    function get (provider, username) {
      var url = [baseUrl, provider, username].join('/');

      return $http({
        method: 'GET',
        url: url
      })
        .then(function (response) {
          var instance = new User(response.data.user);
          instance.username = username;
          instance.provider = provider;
          return instance;
        });
    }

    function getList (provider, config) {
      var url = baseUrl + '/' + provider;
      config = {
        params: angular.extend({ json: true }, config)
      };
      var defaultConfig = {
        params: {
          filter_by: 'username', // Only 'name' and 'username'
          group: '',
          limit: 12000,
          order: 'asc',
          sort_by: 'username', // Only 'name' and 'username'
          start: 0
        }
      };
      return $http.get(url, angular.extend(defaultConfig, config || {}));
    }

    function getUser (provider, username, config) {
      var url = [baseUrl, provider, username].join('/');
      return $http.get(url, angular.extend({}, config || {}));
    }

    function getUserGroups (provider, username, config) {
      var url = [baseUrl, provider, username, 'groups'].join('/');
      return $http.get(url, angular.extend({}, config || {}));
    }

    function toggleLock (lock, provider, username, config) {
      var url = [baseUrl, provider, username, 'locked'].join('/') + '?locked=' + lock;
      return $http.put(url, angular.extend({}, config || {}));
    }

    function toggleDisable (disable, provider, username, config) {
      var url = [baseUrl, provider, username, 'disabled'].join('/') + '?disabled=' + disable;
      return $http.put(url, angular.extend({}, config || {}));
    }

    function resetSecurityAnswers (provider, username, config) {
      var url = [baseUrl, provider, username, 'reset-answers'].join('/');
      return $http.get(url, angular.extend({}, config || {}));
    }

    function updateUserData (data, provider, username, config) {
      var url = [baseUrl, provider, username].join('/');

      if (!data) {
        return $q.reject();
      }

      var params = {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      };
      return $http.put(url, data, angular.extend(params, config));
    }

    function updateUserPasswordWithTemporaryEndpoint (data, provider, username, config) {
      var url = [baseUrl, provider, username, 'password--temporary-endpoint'].join('/');

      if (!data) {
        return $q.reject();
      }

      var params = {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      };
      return $http.put(url, data, angular.extend(params, config));
    }

    function updateUserGroups (groups, provider, username, config) {
      var url = [baseUrl, provider, username, 'groups'].join('/');

      if (!groups) {
        return $q.reject();
      }

      var params = {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      };
      return $http.put(url, groups, angular.extend(params, config));
    }

    return service;
  }
})();
