/* eslint-disable no-param-reassign */
(function () {
  'use strict';

  angular.module('imat.services')
    .factory('dateTimeSrv', dateTimeSrv);

  dateTimeSrv.$inject = [];

  function dateTimeSrv () {
    function getDateFilterParams (format, utc, formats) {
      var property;
      var tzMatch;
      var tzOffset = (utc) ? '+0000' : void (0); // eslint-disable-line no-void

      format = format || '';

      if (format) {
        if (angular.isObject(formats) && formats[format]) {
          property = format;
          format = formats[format];
        }
        // Capture timezone offset if present.
        if ((tzMatch = /([+-]\d\d:?\d\d)$/.exec(format))) {
          tzOffset = tzMatch[1];// Format overrides utc argument.
          if (/^-00:?00$/.test(tzOffset)) {
            // Since, for convenience, we use the absence of a timezone offset
            // as shorthand for UTC, we will use the "-00:00" notation to mean
            // "use the browser timezone."
            // See https://tools.ietf.org/html/rfc3339#section-4.3
            // or https://en.infogalactic.com/info/ISO_8601 under "Usage"
            tzOffset = getTimezoneOffsetStr();
          }
          format = format.replace(/\s?[+-]\d\d:?\d\d$/, '');
        }
      }
      return { format: format.replace(/'/g, "\\'"), property: property, tzOffset: tzOffset };
    }

    // Takes a Date object (or date/datetime string) and returns a string
    // formatted as "YYYY-MM-DD" (or an empty string).
    // The result will be in UTC or in the local timezone if `local` is truthy.
    // The format is a valid ISO 8601 date format.
    function getIsoDate (date, local) {
      var dateStr = getIsoDatetime(date, local);

      return dateStr.slice(0, 10);
    }

    // Takes a Date object (or date/datetime string) and returns a string
    // formatted as "YYYY-MM-DDTHH:mm:ss+00:00" (or an empty string).
    // The result will be in UTC or in the local timezone if `local` is truthy.
    // The format is a valid ISO 8601 date and time format. It is equivalent to
    // the default output of moment#format().
    function getIsoDatetime (date, local) {
      var dateStr = '';
      // This regex is primarily designed to strip out milliseconds.
      var cleaner = /^([\d-]+)(?: |T)([\d:]+)(?:\.\d+?)?([^\d].+)?$/;
      var matches;

      if (angular.isString(date)) {
        if ((matches = cleaner.exec(date))) {
          date = matches[1] + 'T' + matches[2] + (matches[3] || '');
        }
        date = new Date(normalizeDatePart(date.replace(/\s+/g, ' ').trim()));
      }
      if (angular.isDate(date) && !isNaN(date.getTime())) {
        if (local) {
          // The toLocale* methods use runtime time zone unless specified.
          dateStr = normalizeDatePart(date.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }));
          dateStr += 'T' + date.toLocaleTimeString('en-GB', { hour12: false });// "19:00:00" vs "7:00:00 PM" for en-US
          dateStr += getTimezoneOffsetStr(date);
        } else {
          dateStr = date.toISOString().replace(/\.\d\d\dZ$/, '+00:00');
        }
      }
      // UI-3502 IE may have odd unicode characters in the date string, so strip them out
      return dateStr.replace(/[^\x00-\x7F]/g, ''); // eslint-disable-line no-control-regex
    }

    function getLocaleDatetime (date) {
      var d = new Date(date);
      return d.toLocaleString();
    }

    // Takes a Date object or datestring and returns the datestring component for
    // the local timezone offset of that date. Uses "now" if no param is passed.
    // Returns null if a non-date parsable param is passed.
    function getTimezoneOffsetStr (date) {
      var offset = 0;
      if (date == null) {
        date = new Date();
      } else {
        date = new Date(date);
      }
      if (angular.isDate(date) && !isNaN(date.getTime())) {
        offset = date.getTimezoneOffset();
        var o = Math.abs(offset);
        return (offset < 0 ? '+' : '-') + ('00' + Math.floor(o / 60)).slice(-2) + ':' + ('00' + (o % 60)).slice(-2);
      }
      return null;
    }

    // Takes a date string and rearranges its date part according to ISO 8601
    // (i.e., yyyy-mm-dd). The argument must have a 4-digit year, a month
    // between 1-12, a day between 1-31, and (matching) separators of either
    // spaces, dots, slashes, or dashes. The 'en-US' locale format is assumed
    // when the year is right-most and the day and month are both under 13.
    function normalizeDatePart (dateStr) {
      var matches, pattern;

      // UI-3502 IE may have odd unicode characters in the date string, so strip them out
      dateStr = dateStr.replace(/[^\x00-\x7F]/g, ''); // eslint-disable-line no-control-regex

      // YMD (ISO)
      pattern = /^(\d\d\d\d)([ .\/-])(1[012]|0?[1-9])\2(3[01]|[12][0-9]|0?[1-9])/; // eslint-disable-line no-useless-escape
      matches = dateStr.match(pattern);
      if (matches && matches.length === 5) {
        return _normalizeDatePart(dateStr, matches, 1, 3, 4);
      }

      // MDY (USA)
      pattern = /^(1[012]|0?[1-9])([ .\/-])(3[01]|[12][0-9]|0?[1-9])\2(\d\d\d\d)/; // eslint-disable-line no-useless-escape
      matches = dateStr.match(pattern);
      if (matches && matches.length === 5) {
        return _normalizeDatePart(dateStr, matches, 4, 1, 3);
      }

      // DMY
      pattern = /^(3[01]|[12][0-9]|0?[1-9])([ .\/-])(1[012]|0?[1-9])\2(\d\d\d\d)/; // eslint-disable-line no-useless-escape
      matches = dateStr.match(pattern);
      if (matches && matches.length === 5) {
        return _normalizeDatePart(dateStr, matches, 4, 3, 1);
      }

      return dateStr;

      function _normalizeDatePart (dateString, matches2, y, m, d) {
        if (matches2[m].length === 1) { matches2[m] = '0' + matches2[m]; }
        if (matches2[d].length === 1) { matches2[d] = '0' + matches2[d]; }
        return matches2[y] + '-' + matches2[m] + '-' + matches2[d] + dateString.slice(matches2[0].length);
      }
    }

    return {
      getDateFilterParams: getDateFilterParams,
      getIsoDate: getIsoDate,
      getIsoDatetime: getIsoDatetime,
      getLocaleDatetime: getLocaleDatetime,
      getTimezoneOffsetStr: getTimezoneOffsetStr,
      normalizeDatePart: normalizeDatePart
    };
  }
})();
