(function () {
  'use strict';

  angular.module('admin')
    .controller('ManageSymbolItemsModalCtrl', ManageSymbolItemsModalCtrl);

  ManageSymbolItemsModalCtrl.$inject = ['$mdDialog', 'SymbolsSrv', 'items'];
  function ManageSymbolItemsModalCtrl ($mdDialog, SymbolsSrv, items) {
    var vm = this;

    vm.filterText = '';
    vm.items = items;
    vm.values = '';

    // Methods
    vm.addItems = addItems;
    vm.cancel = cancel;
    vm.clearFilter = clearFilter;
    vm.filterList = filterList;
    vm.removeItem = removeItem;
    vm.updateItems = updateItems;

    function addItems () {
      var newItems = vm.values.split(',');
      newItems.forEach(function (value) {
        value = value.trim();
        if (value.length > 0 && findItemIndex(value) === -1) {
          vm.items.push({ name: value, added: true });
        }
      });
      vm.values = '';
    }

    function cancel () {
      $mdDialog.cancel();
    }

    function clearFilter () {
      vm.filterText = '';
    }

    function filterList (item) {
      if (!vm.filterText) { return true; }
      return item.name.toLowerCase().indexOf(vm.filterText.toLowerCase()) >= 0;
    }

    function removeItem (item) {
      var index = findItemIndex(item.name);
      if (index > -1) {
        vm.items.splice(index, 1);
        return true;
      }
      return false;
    }

    function updateItems () {
      vm.items.forEach(function (item) {
        delete item.added;
      });
      $mdDialog.hide(vm.items);
    }

    //= ================================
    // Private interface
    //= ================================

    function findItemIndex (value) {
      if (vm.items && vm.items.length > 0) {
        for (var i = 0, l = vm.items.length; i < l; i++) {
          if (value === vm.items[i].name) {
            return i;
          }
        }
      }
      return -1;
    }
  }
})();
