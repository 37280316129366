(function () {
  'use strict';

  angular
    .module('dashboardApp.test')
    .factory('dashboardItemFix', dashboardItemFix);

  dashboardItemFix.$inject = ['$q'];

  function dashboardItemFix ($q) {
    var service;

    service = {
      getConfig: getConfig
    };

    function getConfig (promise) {
      var fix = [{
        name: 'link',
        title: 'Link',
        colors: ['#f2c553', '#11a4d3', '#a4c558', '#ea3131', '#00235d', '#000000'],
        icons: ['globe_af', 'heart', 'group', 'file', 'notes', 'picture', 'ok_2', 'paperclip', 'tag', 'link', 'stats', 'charts', 'pie_chart', 'warning_sign', 'cardio', 'pencil', 'bookmark', 'book', 'pushpin', 'show_thumbnails'],
        sizes: [
          [1, 1],
          [2, 1],
          [2, 2]
        ],
        views: {
          partial: '/ui/dashboard/items/config/external/view.html',
          config: '/ui/dashboard/items/config/external/config.html'
        },
        defaults: {
          color: '#11a4d3',
          title: 'My Url',
          icon: 'link',
          imgUrl: '',
          size: [2, 2],
          url: ''
        }
      }, {
        name: 'link-admin',
        title: 'Admin',
        roles: ['system'],
        icon: 'cogwheels',
        url: '/ui/#/admin',
        colors: ['#f3c554', '#11a4d3', '#a4c558', '#ea3131', '#00235d', '#000000'],
        sizes: [
          [1, 1],
          [2, 1],
          [2, 2]
        ],
        views: {
          partial: '/ui/dashboard/items/config/internal/view.html',
          config: '/ui/dashboard/items/config/internal/config.html'
        },
        defaults: {
          color: '#000000',
          size: [2, 2]
        }
      }];
      return (promise) ? $q.resolve(fix) : fix;
    }

    return service;
  }
})();
