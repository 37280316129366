(function () {
  'use strict';

  angular
    .module('vhr.filters')
    .filter('keepMarkup', ['$sce', function ($sce) {
      return function (input) {
        return $sce.trustAsHtml(input);
      };
    }]);
})();
