(function () {
  'use strict';

  angular.module('imat.fhir')
    .factory('fhirValueSetSrv', fhirValueSetSrv);

  fhirValueSetSrv.$inject = ['FHIR_VALUE_SETS'];

  function fhirValueSetSrv (FHIR_VALUE_SETS) {
    return {
      getValueSet: getValueSet,
      getValueSetCodes: getValueSetCodes,
      getValueSetCoding: getValueSetCoding,
      getValueSetRegex: getValueSetRegex,
      getValueSetValue: getValueSetValue
    };

    function getValueSet (vs) {
      return (Object.prototype.hasOwnProperty.call(FHIR_VALUE_SETS, vs) ? angular.copy(FHIR_VALUE_SETS[vs]) : null);
    }

    function getValueSetCodes (vs) {
      var valueSet = getValueSet(vs);

      return (valueSet ? valueSet.map(function (v) { return v.code; }) : null);
    }

    function getValueSetCoding (vs, value) {
      if (Object.prototype.hasOwnProperty.call(FHIR_VALUE_SETS, vs)) {
        return angular.copy(FHIR_VALUE_SETS[vs].filter(function (coding) {
          return coding.code === value || coding.display === value;
        })[0]);
      }
      return null;
    }

    function getValueSetRegex (vs) {
      var codes = getValueSetCodes(vs);

      return (codes ? new RegExp('^(?:' + codes.join('|') + ')$') : null);
    }

    function getValueSetValue (vs, code) {
      var valueSet = getValueSet(vs);

      return (valueSet ? valueSet.filter(function (v) { return v.code === code; }).pop() : null);
    }
  }
})();
