(function () {
  'use strict';

  angular.module('dashboardApp')
    .controller('ShowItemProvenanceModalCtrl', ShowItemProvenanceModalCtrl);

  ShowItemProvenanceModalCtrl.$inject = ['$mdDialog', '$scope', 'modalItem'];
  function ShowItemProvenanceModalCtrl ($mdDialog, $scope, modalItem) {
    // Properties
    $scope.tile = modalItem;
    // Methods
    $scope.cancel = $mdDialog.cancel;
    $scope.submit = $mdDialog.hide;
  }
})();
