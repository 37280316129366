(function () {
  'use strict';

  angular
    .module('vhr.directives')
    .directive('vhrMedInsPayer', vhrDir);

  vhrDir.$inject = [];

  function vhrDir () {
    var directive = {
      link: { pre: postLink },
      restrict: 'E',
      scope: {
        json: '='
      },
      templateUrl: '/ui/vhr/directives/uiGridTemplates/medicalInsurance/medical-insurance-payer.html'
    };
    var json = {
      name: '',
      address: {
        city: '',
        line: [],
        postalCode: '',
        resourceType: 'Address',
        state: ''
      },
      resourceType: 'Organization',
      telecom: {
        resourceType: 'ContactPoint',
        system: 'phone',
        value: ''
      }
    };

    return directive;

    function postLink (scope, element, attrs, ctrl) { // eslint-disable-line complexity, no-unused-vars
      var payer = angular.extend({}, json, scope.json || {});
      var address = payer.address;
      var locale = '';

      scope.repeatable = [];

      if (payer.name) {
        scope.repeatable.push(payer.name);
      }

      if (address.line instanceof Array) {
        address.line.forEach(function (line) {
          if (line) {
            scope.repeatable.push(line);
          }
        });
      }
      if (address.postalCode && typeof address.postalCode === 'string') {
        locale = address.postalCode;
      }
      if (address.state && typeof address.state === 'string') {
        locale = ', ' + address.state + ' ' + locale;
      } else {
        locale = ' ' + locale;
      }
      if (address.city && typeof address.city === 'string') {
        locale = address.city + locale;
      }

      scope.repeatable.push(locale.replace(/^[,\s]+|[,\s]+$/, ''));

      if (address.district && typeof address.district === 'string') {
        if (address.country && address.country.toUpperCase() !== 'USA') {
          scope.repeatable.push(address.district);
        }
      }
      if (address.country && typeof address.country === 'string') {
        if (address.country.toUpperCase() !== 'USA') {
          scope.repeatable.push(address.country);
        }
      }

      if (payer.telecom && payer.telecom.value) {
        scope.repeatable.push(payer.telecom.value);
      }
    }
  }
})();
