(function () {
  'use strict';

  angular
    .module('imat.services')
    .factory('queriesApiSrv', queriesApiSrv);

  queriesApiSrv.$inject = ['$http'];

  function queriesApiSrv ($http) {
    var baseUrl = '/search/queries/';

    var service = {
      deleteReport: deleteReport,
      getList: getList,
      getReport: getReport,
      saveReport: saveReport
    };

    return service;

    function deleteReport (id, admin) {
      return $http.delete(baseUrl + id, { params: { admin: !!admin || null } });
    }

    function getList (params) {
      return $http.get('/api/queries', { params: params });
    }

    function getReport (id, params) {
      return $http.get(baseUrl + id, { params: params });
    }

    function saveReport (data, id, admin) {
      return $http.put(baseUrl + id, data, { params: { admin: !!admin || null } });
    }
  }
})();
