(function () {
  'use strict';

  angular.module('admin')
    .controller('SidebarCtrl', SidebarCtrl);

  SidebarCtrl.$inject = ['$scope', '$state', '$transition$', '$timeout', 'sidenavSrv', 'userSession'];

  function SidebarCtrl ($scope, $state, $transition$, $timeout, sidenavSrv, userSession) {
    var vm = this;
    var navLinks = sidenavSrv.getNavLinks($state.current.data.appKey);
    // Properties
    vm.sidenavCollapsed = sidenavSrv.getCollapsed();
    // Methods
    vm.toggleSidenav = toggleSidenav;

    activate();

    function activate () {
      $scope.$watchCollection(function () { return navLinks; }, function (curr) {
        vm.navLinks = curr.filter(function (link) {
          var stateDecl = $state.get(link.state);
          return !!stateDecl && userSession.hasRoles(stateDecl.data.roles);
        });
      });

      vm.backLink = ($transition$.params() && $transition$.params().return) || '';// return url
    }

    function toggleSidenav () {
      var sidenav = angular.element('md-sidenav.sidebar-nav');
      var action = vm.sidenavCollapsed ? 'expanding' : 'collapsing';

      sidenav.addClass(action);

      $timeout(function () {
        sidenav.removeClass(action);
      }, 200);

      vm.sidenavCollapsed = !vm.sidenavCollapsed;
      sidenavSrv.toggle(vm.sidenavCollapsed);
    }
  }
})();
