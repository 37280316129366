/* eslint-disable no-invalid-this */
(function () {
  'use strict';

  angular.module('mpiApp.services')
    .factory('MpiPatientClass', MpiPatientClass);

  MpiPatientClass.$inject = ['FHIR_EXTENSION_URLS', 'MPI_FHIR', 'fhirIdentifierSrv', 'fhirResourceSrv', 'fhirValueSetSrv', 'mpiUtilitySrv'];

  function MpiPatientClass (FHIR_EXTENSION_URLS, MPI_FHIR, fhirIdentifierSrv, fhirResourceSrv, fhirValueSetSrv, mpiUtilitySrv) {
    var util = mpiUtilitySrv;

    function MpiPatient (rawRecord) {
      this._cache = {};
      this._invalid = false;
      this.__rawRecord = (rawRecord && rawRecord.Patient ? rawRecord : { Patient: rawRecord });
      this._rawRecord = fhirResourceSrv.getValidPatientJson(this.__rawRecord);
      if (this._rawRecord == null) {
        this._invalid = true;
        this._rawRecord = this.__rawRecord;
      }
      // XXX Legacy --------------------------
      this.mpid = '';// Only personas, although PI records can find the MPID to which they're attached.
      // -------------------------------------
      if (Object.getPrototypeOf(this) === MpiPatient.prototype) {
        this.pid = this.getPatientID();
        this.userModified = _getUserModified.call(this);
      }

      this.uri = this.getURI();
      angular.extend(this, _getPatientSummary.call(this));
    }

    MpiPatient.prototype = {

      getMPID: function () {
        var idObjs;

        if (this._cache.mpid != null && this._cache.mpid) {
          return this._cache.mpid;
        }

        idObjs = this.getPatientIdentifiers();
        for (var i = 0, ii = idObjs.length; i < ii; ++i) {
          if (fhirIdentifierSrv.isImatMpid(idObjs[i])) {
            return this._cache.mpid = idObjs[i].value; // eslint-disable-line no-return-assign
          }
        }

        return '';
      },

      getPatientID: function () {
        var idObjs;

        if (this._cache.pid != null && this._cache.pid) {
          return this._cache.pid;
        }

        if (this._rawRecord.id && angular.isString(this._rawRecord.id) && /\.PI$/.test(this._rawRecord.id)) {
          return this._cache.pid = this._rawRecord.id; // eslint-disable-line no-return-assign
        }

        idObjs = this.getPatientIdentifiers();
        for (var i = 0, ii = idObjs.length; i < ii; ++i) {
          if (fhirIdentifierSrv.isImatPi(idObjs[i])) {
            return this._cache.pid = idObjs[i].value; // eslint-disable-line no-return-assign
          }
        }

        return '';
      },

      isRawRecordEmpty: function () {
        return !(this._rawRecord.resourceType === 'Patient');
      },

      setURI: function (uri) {
        this.uri = uri;
      },

      getURI: function () {
        return this.uri || '';
      },

      // TODO Cache this?
      getPatientPrefix: function () {
        var names = this.getPatientName();
        var ret = [];

        names.forEach(function (name) {
          if (Array.isArray(name.prefix)) {
            name.prefix.forEach(function (n) {
              name.use ? ret.push({ value: n, use: name.use }) : ret.push({ value: n }); // eslint-disable-line no-unused-expressions
            });
          }
        });

        return ret.filter(util.removeDuplicates);
      },

      // TODO Cache this?
      getPatientFirstName: function () {
        var names = this.getPatientName();
        var ret = [];

        names.forEach(function (name) {
          if (Array.isArray(name.given) && name.given.length) {
            name.use ? ret.push({ value: name.given[0], use: name.use }) : ret.push({ value: name.given[0] }); // eslint-disable-line no-unused-expressions
          }
        });

        return ret.filter(util.removeDuplicates);
      },

      // TODO Cache this?
      getPatientMiddleInitial: function () {
        var names = this.getPatientName();
        var ret = [];

        names.forEach(function (name) {
          if (Array.isArray(name.given) && name.given.length > 1) {
            name.use ? ret.push({ value: name.given[1][0], use: name.use }) : ret.push({ value: name.given[1][0] }); // eslint-disable-line no-unused-expressions
          }
        });

        return ret.filter(util.removeDuplicates);
      },

      // TODO Cache this?
      getPatientMiddleName: function () {
        var names = this.getPatientName();
        var ret = [];

        names.forEach(function (name) {
          if (Array.isArray(name.given) && name.given.length > 1) {
            name.use ? ret.push({ value: name.given[1], use: name.use }) : ret.push({ value: name.given[1] }); // eslint-disable-line no-unused-expressions
          }
        });

        return ret.filter(util.removeDuplicates);
      },

      // TODO Cache this?
      getPatientFamilyName: function () {
        var names = this.getPatientName();
        var ret = [];

        names.forEach(function (name) {
          if (name.family && name.use) {
            ret.push({ value: name.family, use: name.use });
          } else if (name.family) {
            ret.push({ value: name.family });
          }
        });

        return ret.filter(util.removeDuplicates);
      },

      // TODO Cache this?
      getPatientSuffix: function () {
        var names = this.getPatientName();
        var ret = [];

        names.forEach(function (name) {
          if (Array.isArray(name.suffix)) {
            name.suffix.forEach(function (n) {
              name.use ? ret.push({ value: n, use: name.use }) : ret.push({ value: n }); // eslint-disable-line no-unused-expressions
            });
          }
        });

        return ret.filter(util.removeDuplicates);
      },

      // TODO Cache this?
      getPatientFullName: function () {
        var names = this.getPatientName();
        return getFirstElementPropertyValue(names, 'text');
        // TODO Make this do something similar to the other methods?
        // var names = this.getPatientName();
        // var ret = [];

        // names.forEach(function(name) {
        //   if (name.text && name.use) {
        //     ret.push({ value: name.text, use: name.use });
        //   } else if (name.text) {
        //     ret.push({ value: name.text })
        //   }
        // });

        // return ret.filter(util.removeDuplicates);
      },

      getPatientSSN: function () {
        var rv = [];

        if (this._cache.preferredSSN == null) {
          this.getPatientIdentifiers().forEach(function (idObj) {
            var renderedValue;
            // Look for the authoritative identifier system.
            if (fhirIdentifierSrv.isUsSsn(idObj)) {
              renderedValue = getRenderedValue(idObj);
              if (renderedValue) {
                rv.push(renderedValue);// Redacted (probably).
              } else if (angular.isString(idObj.value)) {
                rv.push(idObj.value);// Full.
              }
            } else if (fhirIdentifierSrv.hasTypeCode(idObj, 'SB') || // Social Beneficiary Identifier
                      fhirIdentifierSrv.hasTypeCode(idObj, MPI_FHIR.CODE.SS)) { // Social Security Number (deprecated)
              // Try to find an identifier type that we recognize.
              // We probably don't need to do this.
              renderedValue = getRenderedValue(idObj);
              if (renderedValue) {
                rv.push(renderedValue);// Redacted (probably).
              } else if (angular.isString(idObj.value)) {
                rv.push(idObj.value);// Full.
              }
            }
          });
          this._cache.preferredSSN = rv.filter(util.removeDuplicates);
        }
        return this._cache.preferredSSN;
      },

      getPatientEthnicity: function () {
        var rv = [];

        if (this._cache.preferredEthnicity == null) {
          this.getPatientExtensions().forEach(function (extObj) {
            var extIdx;

            if (extObj.url === FHIR_EXTENSION_URLS.US_CORE_ETHNICITY) {
              extIdx = getExtensionIndexByUrl(extObj, 'ombCategory');
              if (extIdx >= 0) {
                rv.push(extObj.extension[extIdx].valueCoding.display || extObj.extension[extIdx].valueCoding.code || '');
              }
            }
          });
          this._cache.preferredEthnicity = rv.filter(util.removeDuplicates);
        }
        return this._cache.preferredEthnicity;
      },

      getPatientRace: function () {
        var rv = [];

        if (this._cache.preferredRace == null) {
          this.getPatientExtensions().forEach(function (extObj) {
            var extIdx;

            if (extObj.url === FHIR_EXTENSION_URLS.US_CORE_RACE) {
              extIdx = getExtensionIndexByUrl(extObj, 'ombCategory');
              if (extIdx >= 0) {
                rv.push(extObj.extension[extIdx].valueCoding.display || extObj.extension[extIdx].valueCoding.code || '');
              }
            }
          });
          this._cache.preferredRace = rv.filter(util.removeDuplicates);
        }
        return this._cache.preferredRace;
      },

      getPatientBirthDate: function () {
        if (this._cache.preferredBirthDate == null) {
          this._cache.preferredBirthDate = this.getPatientItem('birthDate') || '';
        }
        return this._cache.preferredBirthDate;
      },

      getPatientDeceased: function () {
        var deceased = [];
        var deceasedBoolean;
        var deceasedDateTime;

        // FHIR specifies deceasedBoolean and deceasedDateTime to be mutually exclusive.
        // If our record has both, prefer deceasedDateTime since it is more informative.
        // The stored value is an array; if empty the patient is not dead, and otherwise
        // the boolean (true) is element 0 and the datetime (if known) is element 1.
        if (this._cache.preferredDeceased == null && !this.isRawRecordEmpty()) {
          deceasedDateTime = this._rawRecord.deceasedDateTime;
          if (angular.isDefined(deceasedDateTime) && !isNaN(new Date(deceasedDateTime).getTime())) {
            deceased.push(true);
            deceased.push(deceasedDateTime);
            this._cache.deceasedDateTime = deceasedDateTime;
          } else {
            if ((deceasedBoolean = this._rawRecord.deceasedBoolean)) {
              if (deceasedBoolean === true || /^(?:true|t|yes|y|1)$/i.test(deceasedBoolean || '')) {
                deceased.push(true);
              }
              this._cache.deceasedBoolean = !!deceased.length;
            }
          }
          this._cache.preferredDeceased = deceased;
        }
        return this._cache.preferredDeceased;
      },

      getPatientMultipleBirth: function () {
        var multipleBirth = [];
        var birthBoolean;
        var birthInteger;

        // FHIR specifies multipleBirthBoolean and multipleBirthInteger to be mutually exclusive.
        // If our record has both, prefer multipleBirthInteger since it is more informative.
        // The stored value is an array; if empty the patient is not a twin, and otherwise
        // the boolean (true) is element 0 and the integer (if known) is element 1.
        if (this._cache.preferredMultipleBirth == null && !this.isRawRecordEmpty()) {
          birthInteger = this._rawRecord.multipleBirthInteger;
          if (angular.isDefined(birthInteger) && birthInteger != '0' && birthInteger == parseInt(birthInteger, 10)) { // eslint-disable-line eqeqeq
            multipleBirth.push(true);
            multipleBirth.push(birthInteger);
            this._cache.multipleBirthInteger = birthInteger;
          } else {
            if ((birthBoolean = this._rawRecord.multipleBirthBoolean)) {
              if (birthBoolean === true || /^(?:true|t|yes|y|1)$/i.test(birthBoolean || '')) {
                multipleBirth.push(true);
              }
              this._cache.multipleBirthBoolean = !!multipleBirth.length;
            }
          }
          this._cache.preferredMultipleBirth = multipleBirth;
        }
        return this._cache.preferredMultipleBirth;
      },

      getPatientGender: function (display) {
        var coding;
        var gender;
        if (this._cache.preferredGender == null) {
          gender = this.getPatientItem('gender');
          coding = fhirValueSetSrv.getValueSetValue('ADMINISTRATIVE_GENDER', gender);
          this._cache.preferredGender = gender || '';
          this._cache.preferredGenderDisplay = (coding ? coding.display : (gender || ''));
        }
        return (display ? this._cache.preferredGenderDisplay : this._cache.preferredGender);
      },

      getPatientAddress1: function () {
        var addresses = this.getPatientAddress();
        return getFirstElementPropertyValueAtIndex(addresses, 'line', 0);
      },

      getPatientAddress2: function () {
        var addresses = this.getPatientAddress();
        return getFirstElementPropertyValueAtIndex(addresses, 'line', 1);
      },

      getPatientCity: function () {
        var addresses = this.getPatientAddress();
        return getFirstElementPropertyValue(addresses, 'city');
      },

      getPatientState: function () {
        var addresses = this.getPatientAddress();
        return getFirstElementPropertyValue(addresses, 'state');
      },

      getPatientPostalCode: function () {
        var addresses = this.getPatientAddress();
        return getFirstElementPropertyValue(addresses, 'postalCode');
      },

      getPatientFullAddress: function () {
        var addresses = this.getPatientAddress();
        return getFirstElementPropertyValue(addresses, 'text');
      },

      getPatientPhoneNumber: function () {
        var telecoms = this.getPatientTelecom();

        if (this._cache.preferredPhoneNumbers == null) {
          this._cache.preferredPhoneNumbers = telecoms.filter(function (t) { return t.system === 'phone'; }).filter(util.removeDuplicates);
        }
        return this._cache.preferredPhoneNumbers;
      },

      getPatientFacility: function () {
        var ret = [];

        if (this._cache.preferredFacility == null) {
          this.getPatientIdentifiers().forEach(function (idObj) {
            var facObj;

            if (fhirIdentifierSrv.isFacilityMrn(idObj)) {
              facObj = angular.copy(idObj);
              facObj.mrn = facObj.value;
              facObj.name = facObj.system;

              if (facObj.assigner && facObj.assigner.display) {
                facObj.name = facObj.assigner.display;
              }

              ret.push(facObj);
            }
          });

          this._cache.preferredFacility = util.processFmrnsByPeriod(ret);
        }
        return this._cache.preferredFacility;
      },

      getPatientDateOfService: function () {
        var meta = this.getPatientMeta();
        var ret;

        if (this._cache.preferredDateOfService == null) {
          ret = Date.parse(meta.lastUpdated);
          this._cache.preferredDateOfService = isNaN(ret) ? '' : ret;
        }
        return this._cache.preferredDateOfService;
      },

      getPatientItem: function (prop, isArray) {
        var item = this._rawRecord[prop];

        if (isArray) {
          item = (item == null ? [] : Array.isArray(item) ? item : [item]);
        }
        return item || null;// XXX angular.copy?
      },

      getPatientIdentifiers: function () {
        if (this._cache.preferredIdentifiers == null) {
          this._cache.preferredIdentifiers = this.getPatientItem('identifier', true);
        }
        return this._cache.preferredIdentifiers;
      },

      getPatientExtensions: function () {
        if (this._cache.preferredExtensions == null) {
          this._cache.preferredExtensions = this.getPatientItem('extension', true);
        }
        return this._cache.preferredExtensions;
      },

      getPatientName: function () {
        if (this._cache.preferredName == null) {
          this._cache.preferredName = this.getPatientItem('name', true);

          this._cache.preferredName.forEach(function (name) {
            var val = '';

            if (!name.text) {
              if (Object.prototype.hasOwnProperty.call(name, 'given') && Array.isArray(name.given)) {
                // TODO If we're building an official full name, we should use only official parts.
                val += name.given.join(' ');
              }
              if (Object.prototype.hasOwnProperty.call(name, 'family')) {
                // TODO If we're building an official full name, we should use only official parts.
                val += (' ' + name.family);
              }
              if (Object.prototype.hasOwnProperty.call(name, 'suffix') && Array.isArray(name.suffix)) {
                // TODO If we're building an official full name, we should use only official parts.
                val += (' ' + name.suffix.join(', '));
              }

              name.text = val;
            }
          });
        }
        return this._cache.preferredName;
      },

      getPatientAddress: function () {
        if (this._cache.preferredAddress == null) {
          this._cache.preferredAddress = this.getPatientItem('address', true);

          this._cache.preferredAddress.forEach(function (address) {
            var val = '';

            if (!address.text) {
              if (Object.prototype.hasOwnProperty.call(address, 'line') && Array.isArray(address.line)) {
                val += address.line.join(' ');
              }
              if (Object.prototype.hasOwnProperty.call(address, 'city')) {
                val += (val.length ? ', ' + address.city : address.city);
              }
              if (Object.prototype.hasOwnProperty.call(address, 'state')) {
                val += (val.length ? ', ' + address.state : address.state);
              }
              if (Object.prototype.hasOwnProperty.call(address, 'postalCode')) {
                val += (val.length ? ' ' + address.postalCode : address.postalCode);
              }

              address.text = val;
            }
          });
        }

        return this._cache.preferredAddress;
      },

      getPatientTelecom: function () {
        if (this._cache.preferredTelecom == null) {
          this._cache.preferredTelecom = this.getPatientItem('telecom', true);
        }

        return this._cache.preferredTelecom;
      },

      getPatientMeta: function () {
        if (this._cache.preferredMeta == null) {
          this._cache.preferredMeta = this.getPatientItem('meta') || {};
        }

        return this._cache.preferredMeta;
      }

    };

    MpiPatient.prototype.constructor = MpiPatient;

    return MpiPatient;

    //= ================================
    // MpiPatient Class Helpers
    //= ================================

    function _getUserModified () {
      var extIdx;
      var idObjs;
      var userModified = false;

      if (this._cache.entryInfo == null) {
        idObjs = this.getPatientIdentifiers();
        // A PI identifier with a Purpose extension indicates
        // a user-initiated transaction, so we can flag the
        // record accordingly.
        for (var i = 0, ii = idObjs.length; i < ii; ++i) {
          if (fhirIdentifierSrv.isImatPi(idObjs[i])) {
            extIdx = getExtensionIndexByUrl(idObjs[i], MPI_FHIR.EXT.IMAT_MPI_MATCH);
            if (extIdx >= 0 && getExtensionIndexByUrl(idObjs[i].extension[extIdx], 'Purpose') >= 0) {
              userModified = true;
              break;
            }
          }
        }
        this._cache.entryInfo = userModified;
      }
      return this._cache.entryInfo;
    }

    function getExtensionIndexByUrl (obj, url) {
      if (obj && Array.isArray(obj.extension)) {
        for (var i = 0, ii = obj.extension.length; i < ii; ++i) {
          if (obj.extension[i].url === url) {
            return i;
          }
        }
      }
      return -1;
    }

    // TODO Remove me
    function getFirstElementPropertyValue (arr, firstKey) {
      if (angular.isArray(arr) && arr.length > 0) {
        if (Object.prototype.hasOwnProperty.call(arr[0], firstKey) && arr[0][firstKey]) {
          return arr[0][firstKey];
        }
      }
      return '';
    }

    // TODO Remove me
    function getFirstElementPropertyValueAtIndex (arr, firstKey, itemNumber) {
      if (angular.isArray(arr) && arr.length > 0) {
        if (Object.prototype.hasOwnProperty.call(arr[0], firstKey) && arr[0][firstKey]) {
          if (!angular.isArray(arr[0][firstKey])) {
            return (itemNumber === 0 && arr[0][firstKey] ? arr[0][firstKey] : '');
          }
          if (arr[0][firstKey].length > itemNumber && arr[0][firstKey][itemNumber]) {
            return arr[0][firstKey][itemNumber];
          }
        }
      }
      return '';
    }

    function _getPatientSummary () {
      return {
        address_1: this.getPatientAddress1(),
        address_2: this.getPatientAddress2(),
        address: this.getPatientAddress(),
        birth_date: this.getPatientBirthDate(),
        city: this.getPatientCity(),
        deceased: this.getPatientDeceased(),
        ethnicity: this.getPatientEthnicity(),
        facility: this.getPatientFacility(),
        family_name: this.getPatientFamilyName(),
        first_name: this.getPatientFirstName(),
        full_address: this.getPatientFullAddress(),
        full_name: this.getPatientFullName(),
        gender: this.getPatientGender(),
        identifier: this.getPatientIdentifiers(),
        last_updated: this.getPatientDateOfService(),
        meta: this.getPatientMeta(),
        middle_initial: this.getPatientMiddleInitial(),
        middle_name: this.getPatientMiddleName(),
        multiple_birth: this.getPatientMultipleBirth(),
        name: this.getPatientName(),
        phone_number: this.getPatientPhoneNumber(),
        postal_code: this.getPatientPostalCode(),
        prefix: this.getPatientPrefix(),
        race: this.getPatientRace(),
        ssn: this.getPatientSSN(),
        state: this.getPatientState(),
        suffix: this.getPatientSuffix(),
        telecom: this.getPatientTelecom()
      };
    }

    function getRenderedValue (obj) {
      var extIdx;

      if (angular.isObject(obj._value)) {
        extIdx = getExtensionIndexByUrl(obj._value, FHIR_EXTENSION_URLS.RENDERED_VALUE);

        if (extIdx >= 0) {
          return obj._value.extension[extIdx].valueString;
        }
      }
      return null;
    }
  }
})();
