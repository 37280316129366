(function () {
  'use strict';

  angular.module('reports', [
    /* Angular modules */
    'ngAnimate',
    'ngMaterial',
    'ngMessages',
    'ngSanitize',

    /* IMAT modules */
    'imat.constants', // STORES
    'imat.directives', // materialLoader
    'imat.filters', // urlTitle
    'imat.services', // dateTimeSrv, Permissions, psNotification, psReports, ReportsSrv, searchSrv, userSession

    /* 3rd party modules */
    'md.data.table',
    // 'ui.grid',
    'ui.codemirror',
    'ui.router',

    /* Reports modules */
    'reports.services'
  ]);
})();
