(function () {
  'use strict';

  angular.module('queries')
    .controller('ExportsInfoModalCtrl', ExportsInfoModalCtrl);

  ExportsInfoModalCtrl.$inject = ['$mdDialog', 'exportObj'];

  function ExportsInfoModalCtrl ($mdDialog, exportObj) {
    var vm = this;

    vm.export = angular.copy(exportObj);
    vm.closeDialog = closeDialog;

    function closeDialog () {
      $mdDialog.cancel();
    }
  }
})();
