(function () {
  'use strict';
  angular
    .module('imat.directives')
    .directive('psMaskedInput', function () {
      return {
        templateUrl: '/ui/_shared/directives/masked-input/masked-input.html',
        restrict: 'E',
        require: ['^?form', 'ngModel'],
        replace: true,
        transclude: true,
        scope: {
          autocomplete: '@?',
          autofocus: '@?',
          id: '@?',
          label: '@?',
          mdNoFloat: '=?',
          name: '@?',
          ngDisabled: '=?',
          ngFocus: '=?',
          ngKeyup: '=?',
          ngModel: '=',
          ngPattern: '=?',
          placeholder: '=?',
          required: '@?',
          tabindex: '@?',
          type: '@?'
        },
        link: function (scope, element, attrs) { // eslint-disable-line no-unused-vars
          var context = element[0];
          var touched = /ng-touched/;
          var invalid = /ng-invalid/;
          var messages = [];
          var transclude = context.querySelector('.transcluded');
          scope.loaded = false;

          scope.toggleType = toggleType;

          activate();

          function activate () {
            element.addClass('ps-masked-input');

            if (!scope.id) {
              scope.id = 'input_' + (Math.floor(Math.random() * 1000));
            }

            // Pass focus through to input element
            context.addEventListener('focus', function () {
              context.querySelector('input').focus();
            });

            setTimeout(function () {
              transclude.classList.remove('not-visible');
              if (context.querySelectorAll('.transcluded > *').length) {
                context.querySelector('.transcluded').classList.add('active');
              }
            });

            scope.$watch(function () { return context.querySelectorAll('[ng-messages] > *').length; }, function (curr) {
              if (curr) {
                messages = context.querySelectorAll('[ng-messages] > *');
                for (var i = 0; i < messages.length; i++) {
                  messages[i].classList.add('md-input-message-animation');
                }
              }
            });

            // Allow default input type to be specified, otherwise type is password
            scope.type = scope.type || 'password';

            // Parse attributes that can be specified without a value
            scope.required = angular.isDefined(element.attr('required'));

            // Loop through the scope CONTAINER properties and only add the ones that are defined to the directive element
            ['mdNoFloat'].forEach(function (prop) {
              if (angular.isDefined(scope[prop])) {
                element.setAttribute(prop, scope[prop]);
              }
            });

            // Loop through the scope INPUT properties and only add the ones that are defined to the directive input
            ['autocomplete', 'autofocus', 'id', 'label', 'name', 'placeholder', 'tabindex'].forEach(function (prop) {
              if (angular.isDefined(scope[prop])) {
                // Some attributes like tabindex can get messy if applied to container element AND input element, so remove them
                context.removeAttribute(prop);

                var input = context.querySelector('input');
                input.setAttribute(prop, scope[prop]);
                if (prop === 'autofocus') {
                  input.focus();
                }
              }
            });

            // Try to match behavior of md-input-container md-input as closely as we can
            // Seems kind of redundant, but helps duplicate angular material styling in respect to angular form validation
            scope.$watch(function () { return context.querySelector('input').className; }, function (curr) {
              if (invalid.exec(curr) && touched.exec(curr)) {
                element.addClass('md-input-invalid');
                return;
              }
              element.removeClass('md-input-invalid ng-invalid');
            });
          }

          function toggleType () {
            if (scope.type === 'password') {
              scope.type = 'text';
              context.querySelector('input').focus();
            } else {
              scope.type = 'password';
            }
          }
        }
      };
    });
})();
