(function () {
  'use strict';

  angular.module('admin')
    .controller('UsersCtrl', UsersCtrl);

  UsersCtrl.$inject = [
    '$filter', '$mdDialog', '$mdSidenav', '$scope', '$q', 'userSession', 'DownloaderSrv', 'imatConfig',
    'ProvidersSrv', 'psNotification', 'UsersSrv'
  ];

  function UsersCtrl (
    $filter, $mdDialog, $mdSidenav, $scope, $q, userSession, DownloaderSrv, imatConfig,
    ProvidersSrv, psNotification, UsersSrv
  ) {
    var vm = this;
    var USER_GET_ERROR = 'Unable to get details for ';
    var USERS_GET_ERROR = 'Unable to retrieve the users list.';
    var USER_DELETE_SUCCESS = ' deleted.';
    var USER_DELETE_ERROR = 'Unable to delete ';
    var USER_LOCK_SUCCESS = ' has been ';
    var USER_LOCK_ERROR = 'Unable to update the lock status.';
    var USER_DISABLED_ERROR = 'Unable to update the disabled status.';
    var USER_PUT_GROUPS_ERROR = 'Unable to update the user groups.';
    var USER_PUT_GROUPS_SUCCESS = 'Updated user groups.';
    var USER_UPDATED_SUCCESS = ' updated.';
    var USER_UPDATED_ERROR = 'Unable to update user.';

    vm.disabling = false;
    vm.loading = false;
    vm.loadingGroupData = false;
    vm.locking = false;
    vm.providerCount = 1;
    vm.searchUserText; // eslint-disable-line no-unused-expressions
    vm.selected = {};
    vm.selectedFilter = '';
    vm.selectedGroups = [];
    vm.securityQuestionsEnabled = false;
    vm.users = [];
    vm.userListFilters = [{
      name: 'Password expired',
      key: 'password_expired'
    }, {
      name: 'Disabled',
      key: 'disabled'
    }, {
      name: 'Locked',
      key: 'locked'
    }
    ];

    vm.bulkAddUsers = bulkAddUsers;
    vm.changeName = changeName;
    vm.clearSelection = clearSelection;
    vm.deleteUser = deleteUser;
    vm.downloadCSV = downloadCSV;
    vm.filterUserList = filterUserList;
    vm.selectUser = selectUser;
    vm.manageGroups = manageGroups;
    vm.refreshList = refreshList;
    vm.resetFilters = resetFilters;
    vm.resetSecurityAnswers = resetSecurityAnswers;
    vm.resetPassword = resetPassword;
    vm.textFilterList = textFilterList;
    vm.toggleDisabled = toggleDisabled;
    vm.toggleLocked = toggleLocked;

    activate();

    function activate () {
      vm.loading = true;
      userSession.getSecurityQuestions()
        .then(function (res) {
          if (res.questions.length) {
            vm.securityQuestionsEnabled = true;
          }
        });

      filterUserList(true).then(function () {
        ProvidersSrv.getProviderList()
          .then(function (providers) {
            vm.providerCount = providers.length;
            if (vm.providerCount > 1) {
              providers.forEach(function (provider) {
                vm.userListFilters.push({
                  name: 'Provider: ' + provider,
                  key: 'provider',
                  value: provider
                });
              });
            }
          });
      })
        .catch(function () {
          psNotification.error(USERS_GET_ERROR);
        }).finally(function () {
          vm.loading = false;
        });
    }

    function changeName (ev) {
      $mdDialog.show({
        controller: 'ChangeNameModalCtrl',
        templateUrl: '/ui/admin/users/modals/changeNameModal.html',
        parent: angular.element(document.body),
        targetEvent: ev,
        locals: { name: vm.selected.name },
        clickOutsideToClose: true
      })
        .then(function (res) {
          vm.selected.name = res;
          UsersSrv.updateUserData(vm.selected, vm.selected)
            .then(function () {
              psNotification.success(vm.selected.username + USER_UPDATED_SUCCESS);
              if (vm.selected.username === userSession.getUserData().username) {
                userSession.load();
              }
              refreshList();
            })
            .catch(function () {
              psNotification.error(USER_UPDATED_ERROR);
            });
        });
    }

    function deleteUser (event) {
      var confirm = {
        controller: 'DeleteUserModalCtrl',
        templateUrl: '/ui/admin/users/modals/deleteUserModal.html',
        locals: { modalUser: vm.selected },
        parent: angular.element(document.body),
        targetEvent: event,
        clickOutsideToClose: true
      };

      $mdDialog.show(confirm)
        .then(function () {
          return UsersSrv.deleteUser(vm.selected)
            .then(function () {
              psNotification.success(vm.selected.username + USER_DELETE_SUCCESS);
              clearSelection();
              refreshList();
            })
            .catch(function () {
              psNotification.error(USER_DELETE_ERROR + vm.selected.username + '.');
            });
        });
    }

    function filterUserList (force) {
      return UsersSrv.getList(force)
        .then(function (users) {
          if (!vm.selectedFilter) {
            vm.users = users;
            return;
          }

          switch (vm.selectedFilter.key) {
            case 'provider':
              vm.users = $filter('filter')(users, function (value) {
                return value.provider === vm.selectedFilter.value;
              });
              break;

            default:
              vm.users = $filter('filter')(users, function (value) {
                return value[vm.selectedFilter.key];
              });
              break;
          }
        })
        .catch(function () {
          psNotification.error(USERS_GET_ERROR);
        });
    }

    function textFilterList (user) {
      if (!vm.searchUserText) {
        return true;
      }
      return (user.username.toLowerCase().indexOf(vm.searchUserText.toLowerCase()) !== -1) ? true : (user.name.toLowerCase().indexOf(vm.searchUserText.toLowerCase()) !== -1);
    }

    function refreshList () {
      vm.loading = true;
      filterUserList(true).finally(function () {
        vm.loading = false;
      });
    }

    function resetFilters () {
      vm.searchUserText = '';
      vm.selectedFilter = '';
      filterUserList(false);
    }

    function selectUser (user) {
      if (vm.selected) {
        clearSelection();
      }
      getUser(user)
        .then(function () {
          vm.selected.selected = true;
        });
      $mdSidenav('userDetails').open();
    }

    function getUser (selectedUser) {
      vm.loadingGroupData = true;

      // To eliminate "flashing" of no unselected record message.
      vm.selected = selectedUser;

      return UsersSrv.getUserGroups(selectedUser)
        .then(function (user) {
          vm.selected = user;
        })
        .catch(function () {
          psNotification.error(USER_GET_ERROR + selectedUser.username + '.');
          clearSelection();
        })
        .finally(function () {
          vm.loadingGroupData = false;
        });
    }

    function clearSelection () {
      if (vm.selected) {
        delete vm.selected.selected;
        vm.selected = {};
      }
      $mdSidenav('userDetails').close();
    }

    function resetSecurityAnswers (event) {
      var confirm = $mdDialog.confirm()
        .title('Confirm Reset')
        .textContent('Are you sure you want to reset the answers to the security questions for this user?')
        .targetEvent(event)
        .ok('Reset')
        .cancel('Cancel');

      $mdDialog.show(confirm)
        .then(function () {
          UsersSrv.resetSecurityAnswers(vm.selected)
            .then(function () {
              psNotification.success('Answers reset.');
            })
            .catch(function () {
              psNotification.error("Unable to reset the user's answers.");
            });
        });
    }

    function toggleLocked () {
      var stateString = vm.selected.locked ? 'locked.' : 'unlocked.';

      vm.locking = true;
      return UsersSrv.toggleUserLock(vm.selected)
        .then(function () {
          psNotification.success(vm.selected.username + USER_LOCK_SUCCESS + stateString);
        })
        .catch(function () {
          vm.selected.locked = !vm.selected.locked;// Undo UI toggle.
          psNotification.error(USER_LOCK_ERROR);
        })
        .finally(function () {
          vm.locking = false;
        });
    }

    function toggleDisabled () {
      var stateString = vm.selected.disabled ? 'disabled.' : 'enabled.';

      vm.disabling = true;
      return UsersSrv.toggleUserDisable(vm.selected)
        .then(function () {
          psNotification.success(vm.selected.username + USER_LOCK_SUCCESS + stateString);
        })
        .catch(function () {
          vm.selected.disabled = !vm.selected.disabled;// Undo UI toggle.
          psNotification.error(USER_DISABLED_ERROR);
        })
        .finally(function () {
          vm.disabling = false;
        });
    }

    function resetPassword (ev) {
      $mdDialog.show({
        controller: 'ResetPasswordModalCtrl',
        templateUrl: '/ui/admin/users/modals/resetPasswordModal.html',
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: true
      })
        .then(function (data) {
          return imatConfig.loadConfigFile('imat.json')
            .then(function (config) {
              data.useUnstableMethod = config.apps.admin.changePasswordsUsingFast389Method || false;
              return data;
            });
        })
        .then(function (data) {
          var user = vm.selected;
          var payload = {
            password: data.password
          };

          var promise;
          if (data.useUnstableMethod) {
            promise = UsersSrv.updateUserPasswordWithTemporaryEndpoint(payload, user);
          } else {
            promise = UsersSrv.updateUserData(payload, user);
          }

          promise
            .then(function () {
              psNotification.success('Password reset.');
            })
            .catch(function (error) {
              // TODO: Revisit error message handling for this once API has been refactored.
              if (error.error) { psNotification.error(error.error.toString()); }
              return $q.reject();
            });
        });
    }

    function manageGroups (ev) {
      $mdDialog.show({
        controller: 'ManageUserGroupsModalCtrl',
        controllerAs: 'vm',
        locals: { modalGroups: vm.selected.groups, provider: vm.selected.provider },
        templateUrl: '/ui/admin/users/modals/manageUserGroupsModal.html',
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: true
      })
        .then(function (groups) {
          UsersSrv.updateUserGroups(groups, vm.selected)
            .then(function () {
              psNotification.success(USER_PUT_GROUPS_SUCCESS);
              getUser(vm.selected);
            })
            .catch(function () {
              psNotification.error(USER_PUT_GROUPS_ERROR);
            });
        });
    }

    function bulkAddUsers (ev) {
      $mdDialog.show({
        controller: 'BulkAddUsersModalCtrl as modal',
        locals: { modalProvider: 'default' },
        templateUrl: '/ui/admin/users/modals/bulkAddUsersModal.html',
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: true
      })
        .then(refreshList);
    }

    function downloadCSV () {
      var collection = $filter('filter')(vm.users, textFilterList);

      return DownloaderSrv.downloadCsv(collection, {
        columns: ['username', 'name', 'password_expired', 'disabled', 'locked', 'provider'],
        filename: 'users.csv',
        headers: {
          username: 'Username',
          name: 'Full Name',
          password_expired: 'Password Expired',
          disabled: 'Disabled',
          locked: 'Locked',
          provider: 'Provider'
        },
        processors: {
          username: DownloaderSrv.PROCESS.nullToEmptyString,
          name: DownloaderSrv.PROCESS.nullToEmptyString,
          password_expired: processBoolToYesNo,
          disabled: processBoolToYesNo,
          locked: processBoolToYesNo,
          provider: DownloaderSrv.PROCESS.nullToEmptyString
        }
      });
    }

    function processBoolToYesNo (value) {
      return value ? 'Yes' : 'No';
    }
  }
})();
