(function () {
  'use strict';

  angular.module('imat.http')
    .factory('activityInterceptor', activityInterceptor);

  activityInterceptor.$inject = ['APP_TIMEOUT', '$interval', '$rootScope'];

  function activityInterceptor (APP_TIMEOUT, $interval, $rootScope) {
    var EVENT_NAME = 'HttpRequestActivity';
    var INTERVAL_APPLY = false; // No dirty scope checking.
    var INTERVAL_COUNT = 0;// Indefinite;
    var INTERVAL_DELAY = 30000;// 30 seconds.
    var actualHttpActivity = 0;
    var loggedHttpActivity = 0;
    var urlRex = /^\/(api|extensions|ui)\//;
    var service = {
      request: request
    };

    $interval(function () {
      if (loggedHttpActivity < actualHttpActivity) {
        loggedHttpActivity = actualHttpActivity;
        $rootScope.$emit(EVENT_NAME, loggedHttpActivity);
      }
    }, INTERVAL_DELAY, INTERVAL_COUNT, INTERVAL_APPLY);

    return service;

    function request (config) {
      if (urlRex.test(config.url) && config.url !== APP_TIMEOUT.SESSION_URL) {
        actualHttpActivity = Date.now();
      }
      return config;
    }
  }
})();
