(function () {
  'use strict';

  angular.module('vhr.services')
    .factory('vhrApp', vhrApp);

  vhrApp.$inject = [
    'MODE', 'MODE_META',
    '$q', '$log', '$rootScope',
    'Preferences'
  ];

  function vhrApp (
    MODE, MODE_META,
    $q, $log, $rootScope,
    Preferences
  ) {
    var service;
    var listeners = {};
    var appMode;
    var appModes = [
      MODE.VIEW, MODE.PRINT
    ];
    var deferred = $q.defer();
    var isFilterEnabled = false;
    var isFilterVisible = false;
    var isModesEnabled = true;
    var isRefreshEnabled = true;
    var isSidenavCollapsed = false;

    service = {
      enableFilter: enableFilter,
      enableModes: enableModes,
      enablePrinting: enablePrinting,
      enableRefresh: enableRefresh,
      getCollapsed: getCollapsed,
      getMode: getMode,
      getModes: getModes,
      getModeIcon: getModeIcon,
      getModeIconCode: getModeIconCode,
      getModeLabel: getModeLabel,
      getModeMeta: getModeMeta,
      getModeTooltip: getModeTooltip,
      getPrintPermission: getPrintPermission,
      isLoaded: isLoaded,
      on: on,
      refresh: refresh,
      setMode: setMode,
      showFilter: showFilter,
      toggleSidenav: toggleSidenav
    };

    function enableFilter (setting) {
      if (typeof setting === 'undefined') {
        return isFilterEnabled;
      }
      setting = !!setting;
      if (isFilterEnabled !== setting) {
        $rootScope.$broadcast('vhrFilterEnabled', isFilterEnabled = setting);
        if (setting) {
          $rootScope.$broadcast('vhrFilterShown', isFilterVisible);
        }
      }
    }

    function enableModes (setting) {
      if (typeof setting === 'undefined') {
        return isModesEnabled;
      }
      setting = !!setting;
      if (isModesEnabled !== setting) {
        $rootScope.$broadcast('vhrModesEnabled', isModesEnabled = setting);
      }
    }

    function enableRefresh (setting) {
      if (typeof setting === 'undefined') {
        return isRefreshEnabled;
      }
      setting = !!setting;
      if (isRefreshEnabled !== setting) {
        $rootScope.$broadcast('vhrRefreshEnabled', isRefreshEnabled = setting);
      }
    }

    function getCollapsed () {
      isSidenavCollapsed = Preferences.getPreferences().sidenavcollapsed;
      return isSidenavCollapsed;
    }

    function getMode () {
      return (typeof appMode !== 'undefined' ? appMode : appModes[0]);
    }

    function getModes () {
      return appModes;
    }

    function getModeIcon (mode) {
      var meta = getModeMeta(mode);
      return (Object.prototype.hasOwnProperty.call(meta, 'ICON')) ? meta.ICON : '';
    }

    function getModeIconCode (mode) {
      var meta = getModeMeta(mode);
      return (Object.prototype.hasOwnProperty.call(meta, 'ICONCODE')) ? meta.ICONCODE : '';
    }

    function getModeLabel (mode) {
      var meta = getModeMeta(mode);
      return (Object.prototype.hasOwnProperty.call(meta, 'LABEL')) ? meta.LABEL : '';
    }

    function getModeMeta (mode) {
      var meta = {};

      angular.forEach(MODE, function (_mode_, _modeKey_) {
        if (mode === _mode_) {
          meta = MODE_META[_modeKey_];
        }
      });
      return meta;
    }

    function getModeTooltip (mode) {
      var meta = getModeMeta(mode);
      return (Object.prototype.hasOwnProperty.call(meta, 'TOOLTIP')) ? meta.TOOLTIP : '';
    }

    function isLoaded (ready) {
      if (ready === true) {
        deferred.resolve();
      } else if (ready != null) {
        deferred.reject(ready);
      }
      return deferred.promise;
    }

    function on (id, callback) {
      if (typeof id !== 'string') {
        $log.error('Calling vhrApp.on() requires a string for the first parameter.');
        return false;
      }
      if (typeof callback !== 'function') {
        $log.error('Calling vhrApp.on() requires a function for the second parameter.');
        return false;
      }
      listeners[id] = $rootScope.$on(id, function (evt, data) {
        callback(data);
        listeners[id]();
        delete listeners[id];
      });
    }

    function refresh () {
      if (isRefreshEnabled) {
        $rootScope.$broadcast('vhrRefreshed');
      }
    }

    function getPrintPermission () {
      return $rootScope.printingAvailable;
    }

    function enablePrinting (setting) {
      $rootScope.printingAvailable = Boolean(setting);
    }

    function setMode (mode) {
      if (appMode !== mode && appModes.indexOf(mode) >= 0) {
        appMode = mode;
        $rootScope.$broadcast('vhrModeChanged', mode, getModeMeta(mode));
      }
    }

    function showFilter (setting) {
      if (typeof setting === 'undefined') {
        return isFilterEnabled && isFilterVisible;
      }
      setting = !!setting;
      if (isFilterEnabled && isFilterVisible !== setting) {
        $rootScope.$broadcast('vhrFilterShown', isFilterVisible = setting);
      }
    }

    function toggleSidenav () {
      isSidenavCollapsed = !isSidenavCollapsed;
      Preferences.setPreference('sidenavcollapsed', isSidenavCollapsed);
    }

    return service;
  }
})();
