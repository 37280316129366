(function () {
  'use strict';

  angular.module('admin', [
    /* Angular modules */
    'ngAnimate',
    'ngMaterial',
    'ngMessages',
    'ngSanitize',

    /* 3rd Party Modules */
    'angularFileUpload',
    'focus-if',
    'md.data.table',
    'ui.codemirror',
    'ui.router',

    /* IMAT Modules */
    'dashboardApp.constants',
    'imat.directives', // imatAsciiOnly, imatSizer, imatVirtualList, imatVirtualRemove, materialLoader, psMaskedInput
    'imat.filters', // spacesToUnderscores, ssQueryFieldname, statusLabel
    'imat.services'
  ]);
})();
