(function () {
  'use strict';
  angular
    .module('imat.directives')
    .directive('materialBanner', [function () {
      return {
        restrict: 'E',
        scope: {
          banner: '='
        },
        templateUrl: '/ui/_shared/directives/material-banner/banner.html',
        replace: true,
        controller: ['$scope', function ($scope) {
          var self = this; // eslint-disable-line no-invalid-this
          self.banner = $scope.banner;
        }],
        controllerAs: 'ctrl'
      };
    }]);
})();
