(function () {
  'use strict';

  angular.module('vhr.filters')
    .filter('vhrJsonRange', function () {
      return function (input, dictionary, unknown, path) { // eslint-disable-line complexity
        var key = input;

        if (typeof dictionary === 'undefined' && typeof unknown === 'undefined') { return key; }
        if (typeof dictionary === 'undefined' && typeof unknown !== 'undefined') { key = unknown; }
        if (typeof key !== 'string') { key += ''; }

        key = parseInt(key, 10);

        // Parse dictionary keys into ranges
        var range;
        var rangePattern;
        var rangePatterns = Object.keys(dictionary);
        var result;

        for (var i = 0; i < rangePatterns.length; ++i) {
          rangePattern = rangePatterns[i];

          if (/:/.test(rangePattern)) {
            range = rangePattern.split(':').map(convertRangeBoundaries);
          } else {
            range = Array(2).fill(parseInt(rangePattern, 10));
          }

          if (range.length === 2) {
            if (
              (range[0] === null && key <= range[1]) ||
            (key >= range[0] && key <= range[1]) ||
            (range[1] === null && key >= range[0])
            ) {
              result = dictionary[rangePattern];
              break;
            }
          }
        }

        if (typeof result === 'object' && typeof path === 'string' && typeof result[path] !== 'undefined') {
          return result[path];
        }
        if (typeof result !== 'undefined') {
          return result;
        }
        if (typeof unknown !== 'undefined') {
          return unknown;
        }
        return key;
      };
    });

  function convertRangeBoundaries (value) {
    var parsed = (value == null ? null : parseInt(value.trim(), 10));
    return (isNaN(parsed) ? null : parsed);
  }
})();
