(function () {
  'use strict';

  angular.module('mpiApp')
    .controller('limboRecordCtrl', limboRecordCtrl);

  limboRecordCtrl.$inject = [
    '$mdDialog', '$q', '$scope', '$state', '$transition$', '$window',
    'mpiApiSrv', 'imatStoresSrv', 'psNotification'
  ];

  function limboRecordCtrl (
    $mdDialog, $q, $scope, $state, $transition$, $window,
    mpiApiSrv, imatStoresSrv, psNotification
  ) {
    var vm = this; // eslint-disable-line no-invalid-this

    vm.editorConfig = {
      mode: { name: 'xml', htmlMode: false, matchClosing: false, alignCDATA: false },
      lineWrapping: true,
      lineNumbers: true,
      indentUnit: 4,
      smartIndent: true,
      theme: 'eclipse',
      readOnly: true,
      highlightSelectionMatches: {
        showToken: true,
        annotateScrollbar: true
      }
    };

    vm.deleteSingleLimboRecord = deleteSingleLimboRecord;
    vm.id = $transition$.params().id;
    vm.loaded = { record: false };
    vm.record = null;
    vm.store = imatStoresSrv.STORE.LIMBO;
    vm.displayedId = null;
    vm.codemirrorLoaded = codemirrorLoaded;

    activate();

    function activate () {
      load(vm.id);
    }

    //= ================================
    // Public interface
    //= ================================

    function codemirrorLoaded (editor) {
      editor.getDoc().markClean();

      // Events
      editor.on('focus', function () {
        $scope.$applyAsync(function () { vm.editorFocused = true; });
      });

      editor.on('blur', function () {
        $scope.$applyAsync(function () { vm.editorFocused = false; });
      });

      vm.editor = editor;
    }

    function deleteSingleLimboRecord (event) {
      if (!vm.id) {
        return psNotification.error('Unable to find the record to delete.');
      }

      $mdDialog.show({
        parent: angular.element($window.document.body),
        controller: 'DeleteLimboRecordModalCtrl',
        controllerAs: 'vm',
        targetEvent: event,
        templateUrl: '/ui/mpi/limbo-record/delete-limbo-record-modal.html'
      })
        .then(function (response) {
          var comment = response.comment;
          var deleteRecordPhi = response.scrub;

          return _deleteLimboRecords(vm.id, comment, deleteRecordPhi)
            .then(function () {
              psNotification.success('Request to delete received. The record may appear in search results until the request has been processed.');
            })
            .catch(function () {
              return $q.reject('Unable to delete the record.');
            });
        })
        .then(function () {
          vm.id = null;
          $state.go('app.mpi.limboQuery.limboResults');
        })
        .catch(function (reason) {
          if (reason) {
            psNotification.error(reason);
          }
        });
    }

    //= ================================
    // Private interface
    //= ================================

    function load (id) {
      vm.loaded.record = false;
      vm.record = null;
      vm.displayedId = vm.id;

      loadLimboRecord(id)
        .then(function (response) {
          vm.record = response;
        })
        .catch(function (err) {
          if (err.status === 422) {
            vm.record = {
              invalid: true,
              original_response: err.data.data.original_response
            };
            psNotification.error('Error: Invalid format');
            return;
          }
          psNotification.error('An error occurred while retrieving the record.');
        })
        .finally(function () {
          vm.loaded.record = true;
        });
    }

    function loadLimboRecord (id) {
      return mpiApiSrv.getLimboRecord(id, vm.store);
    }

    function _deleteLimboRecords (records, comment, scrub) {
      return mpiApiSrv.deleteLimboRecords(records, comment, scrub);
    }
  }
})();
