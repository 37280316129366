(function () {
  'use strict';

  angular.module('imat.services')
    .factory('DashboardItemClass', DashboardItemClass);

  DashboardItemClass.$inject = [];

  function DashboardItemClass () {
    function DashboardItem (data) {
      var self = this;
      var defaults = {
        cfg: {},
        data: {},
        error: true,
        image: {
          color: '',
          type: '',
          value: ''
        },
        link: {
          href: '',
          params: {},
          sref: '',
          text: ''
        },
        type: '',
        ui: {
          errs: [], // Error state of the item for each dashboard in `.refs`.
          filtered: false, // Whether the item should be filtered out of the user view.
          id: '', // A data-derived ID for filtering and ordering items (i.e., a patch until we get DB-assigned IDs).
          inherited: false, // Whether the item is provided by a group (or the system) dashboard.
          owned: false, // Whether the item is provided directly by the user's dashboard.
          ref: null, // Reference to the owning dashboard.
          refs: []// References to dashboards that provide this item to a user (.ref is .refs[0]).
        },
        unsupported: true
      };

      if (typeof data === 'string') {
        angular.extend(self, defaults, { type: data });
        return self;
      }
      if (!angular.isObject(data)) {
        angular.extend(self, defaults);
        return self;
      }
      if (!angular.isObject(data.data)) {
        data.data = {};
      }

      data = noneToNull(data);
      data.data.image = angular.extend({}, defaults.image, data.data.image || {});
      data.data.link = angular.extend({}, defaults.link, data.data.link || {});
      // Normalize to .type instead of .name.
      angular.extend(self, defaults, data, { type: data.type || data.name || '' });
      // Convenience references into self.data must remain intact.
      self.image = self.data.image;
      self.link = self.data.link;

      delete self.name;
    }

    DashboardItem.prototype.IMAGE = {
      GLYPHICON: 'glyphicon',
      IMGSRC: 'imgsrc',
      MATERIAL: 'material',
      PS: 'ps'
    };

    DashboardItem.prototype.CATEGORY = {
      EXTERNAL: 'external',
      INTERNAL: 'internal',
      PARAMETERIZED: 'parameterized',
      CR_REPORT: 'cr-report',
      CR_RESULT: 'cr-result'
    };

    DashboardItem.prototype.checkHasError = function () {
      // Should indicate whether the item will behave properly when clicked, and
      // NOT whether the item is supported or that the default config is correct.
      switch (this.cfg.category) {
        case this.CATEGORY.PARAMETERIZED:
          return !(this.link && this.link.sref && this.link.text && Object.keys(this.link.params).length);
        default:
          return !(this.link && this.link.href && this.link.text);
      }
    };

    DashboardItem.prototype.toJSON = function () {
      return {
        // this.image and this.link SHOULD be references into this.data, but if not...
        data: angular.extend({}, this.data, { image: this.image, link: this.link }),
        type: this.type
      };
    };

    function noneToNull (obj) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key) && obj[key] === 'None') {
          obj[key] = null;
        }
      }
      return obj;
    }

    return DashboardItem;
  }
})();
