(function () {
  'use strict';

  angular.module('imat.services', [
    'dashboardApp.constants',
    'imat.filters',
    'imat.http',
    'imat.services.config',
    'imat.shared.constants',
    'imat.x2js',
    'ngCookies',
    'ngMaterial',
    'ui.router'
  ]);
})();
