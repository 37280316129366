/* eslint-disable no-param-reassign */
(function () {
  'use strict';

  angular
    .module('imat.filters')
    .filter('imatLabelize', function () {
      return function (input) {
        if (/^_?[a-z]+_?$/.test(input)) {
          input = input.replace(/^_?([a-z]{2,})_?$/, uppercaseAcronyms);
        } else {
          input = input.replace(/_([a-z]{2,})_/g, uppercaseAcronyms);
          input = input.replace(/^_?([a-z]{2,})_/, uppercaseAcronyms);
          input = input.replace(/_([a-z]{2,})_?$/, uppercaseAcronyms);
        }
        input = input.replace(/_/g, ' ').trim().replace(/^[a-z]| [a-z]/g, uppercaseLeadingLetter);
        input = input.replace(/(F?MRN|M?PID)S/i, function (match, submatch) { return submatch.toUpperCase() + 's'; });
        return input;
      };

      function uppercaseAcronyms (match, submatch) {
        var words = ['id', 'dob', 'mpid', 'mpids', 'phi', 'pid', 'pids', 'sig'/* more acronyms with vowels */];
        // Acronym if it has no vowels or is in the whitelist.
        return (submatch && (!/a|e|i|o|u|y/i.test(submatch) || words.indexOf(submatch) >= 0) ? match.toUpperCase() : match);
      }

      function uppercaseLeadingLetter (match) {
        return match.toUpperCase();
      }
    });
})();
