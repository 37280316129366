(function () {
  'use strict';

  angular.module('imat.fhir')
    .factory('fhirUtilitySrv', fhirUtilitySrv);

  fhirUtilitySrv.$inject = ['FHIR_DATATYPES', 'IMAT_FHIR_EXTENSION', 'FhirExtensionClass', 'FhirJsonWrapperClass'];

  function fhirUtilitySrv (FHIR_DATATYPES, IMAT_FHIR_EXTENSION, FhirExtensionClass, FhirJsonWrapperClass) {
    var service;
    var debug = false;

    service = {
      analyzeResults: analyzeResults,
      andProperties: andProperties,
      fixJsonProperties: fixJsonProperties,
      isEmptyExtension: isEmptyExtension,
      isEmptyProperty: isEmptyProperty,
      isPrimitiveDatatype: isPrimitiveDatatype,
      notProperties: notProperties,
      removeProperties: removeProperties,
      verifyProperties: verifyProperties,
      xorProperties: xorProperties,
      getFhirJsonWrapper: getFhirJsonWrapper
    };

    function analyzeResults (json, results) {
      var failure = results.filter(function (r) { return r.isValid === false; }).length;
      var valid = !failure;

      if (failure && debug) {
        console.error('JSON', json); console.error('RESULTS', results);
      }
      return valid;
    }

    function andProperties (json, validations, rules) {
      rules.forEach(function (r) {
        if (Object.prototype.hasOwnProperty.call(json, r.property) && !isEmptyProperty(json[r.property])) {
          r.properties.forEach(function (p) {
            if (!Object.prototype.hasOwnProperty.call(json, p) || isEmptyProperty(json[p])) {
              for (var i = 0, ii = validations.length; i < ii; ++i) {
                if (validations[i].property === p) {
                  validations[i].isValid = false;
                  if (validations[i].reason) {
                    validations[i].reason += ' +AND';
                  } else {
                    validations[i].reason = 'AND';
                  }
                  break;
                }
              }
            }
          });
        }
      });
      return validations;
    }

    // This function assumes that `json` is JSON converted from XML where XML
    // attributes were converted to JSON properties with leading underscores.
    // It is preliminary cleanup for the attribute->property names. It also
    // transforms objects into primitive values for the simple case where the
    // object has no properties except _value. It also ensures that extension
    // properties are arrays. Other transformations are left for the validators
    // that have the necessary datatype information.
    // See the FHIR JSON representation: https://www.hl7.org/fhir/json.html.
    function fixJsonProperties (json) {
      var keys;
      var rexAttr = /^_/;// x2js moves XML attributes to _attr properties (<foo value="bar" /> => {foo: {_value: bar}}).

      if (json instanceof FhirExtensionClass) {
        return json;
      }

      if (angular.isArray(json)) {
        json = json.map(fixJsonProperties);// eslint-disable-line no-param-reassign
      } else if (angular.isObject(json)) {
        keys = Object.keys(json);
        if (keys.length === 1 && keys[0] === '_value') {
          json = json._value;
        } else {
          keys.forEach(function (_key) { // eslint-disable-line complexity
            var key = _key.replace(rexAttr, '');
            // Check for well-formed FHIR JSON...
            if (_key !== key && Object.prototype.hasOwnProperty.call(json, key)) {
              if (angular.isArray(json[_key]) && angular.isArray(json[key])) {
                return;// continue
              }
              if (angular.isObject(json[_key]) && !angular.isObject(json[key])) {
                return;// continue
              }
            }
            // Fix values...
            json[key] = fixJsonProperties(json[_key]);
            if (key === IMAT_FHIR_EXTENSION.ELEMENT && !angular.isArray(json[key])) {
              json[key] = [json[key]];
            }
            // Clean up...
            if (key !== _key) {
              delete json[_key];
            }
            if (key === 'xmlns') {
              delete json[key];
            }
          });
        }
      }

      return json;
    }

    function getFhirJsonWrapper (obj, key, idx) {
      return new FhirJsonWrapperClass(fixJsonProperties(obj), key, idx);
    }

    function isEmptyExtension (obj, removeEmpty) { // eslint-disable-line complexity
      var hasExt;
      var isEmpty = true;
      var removable = [];
      var values;

      if (obj instanceof FhirExtensionClass) {
        if (obj.extExtended && Array.isArray(obj.extension)) {
          isEmpty = obj.extension.filter(function (ext, idx) {
            removable[idx] = isEmptyExtension(ext, removeEmpty);
            return !removable[idx];
          }).length === 0;// Empty when filtered on !isEmpty.
          if (removeEmpty && removable.length) {
            obj.extension = obj.extension.filter(function (ext, idx) { return !removable[idx]; });
          }
          return isEmpty;
        }
        return isEmptyProperty(obj.extValue, removeEmpty);
      }

      if (angular.isObject(obj) && obj.url) {
        hasExt = angular.isArray(obj[IMAT_FHIR_EXTENSION.ELEMENT]);
        values = Object.keys(obj).filter(function (key) { return /^value/.test(key); });

        if (!hasExt && values.length === 1) {
          return isEmptyProperty(obj[values[0]], removeEmpty);
        }
        if (hasExt && values.length === 0) {
          return isEmptyProperty(obj, removeEmpty);
        }
      }
      return true;// Treat invalid constructions as empty.
    }

    function isEmptyProperty (value, removeEmpty) {
      var keys;

      if (value == null || value !== value) { // eslint-disable-line no-self-compare
        return true;// undefined, null, NaN
      }

      if (angular.isString(value)) {
        return value.length === 0;
      }

      if (angular.isArray(value)) {
        return value.filter(function (val) {
          return !isEmptyProperty(val, removeEmpty);
        }).length === 0;// Empty when filtered on !isEmpty.
      }

      if (angular.isObject(value)) {
        keys = Object.keys(value);
        return keys.filter(function (key) {
          // We make no distinction between "key" and "_key". The value of a
          // "_key" property is either an object or an array (see note below).
          // See the FHIR JSON representation: https://www.hl7.org/fhir/json.html#primitive.
          var isEmpty = true;
          var removable = [];

          if (key === IMAT_FHIR_EXTENSION.ELEMENT) {
            if (angular.isArray(value[key])) {
              isEmpty = value[key].filter(function (ext, idx) {
                removable[idx] = isEmptyExtension(ext, removeEmpty);
                return !removable[idx];
              }).length === 0;// Empty when filtered on !isEmpty.
            }
          } else if (key === '$$hashKey') {
            isEmpty = true;
          } else {
            // If this is an array, its size will not change; it will be
            // retained unchanged unless ALL of its elements are empty. In the
            // case of a "_key" value that is an array, it will be removed if
            // it contains only null values, in which case it need not exist.
            isEmpty = isEmptyProperty(value[key], removeEmpty);
          }

          if (removeEmpty) {
            if (isEmpty) {
              delete value[key];
            } else if (removable.length) {
              value[key] = value[key].filter(function (ext, idx) { return !removable[idx]; });
            }
          }

          return !isEmpty;// Filtering.
        }).length === 0;// Empty when filtered on !isEmpty.
      }

      return false;// Boolean, Number, ...
    }

    function isPrimitiveDatatype (datatype) {
      var primitives = [
        FHIR_DATATYPES.base64Binary,
        FHIR_DATATYPES.boolean,
        FHIR_DATATYPES.canonical,
        FHIR_DATATYPES.code,
        FHIR_DATATYPES.date,
        FHIR_DATATYPES.dateTime,
        FHIR_DATATYPES.decimal,
        FHIR_DATATYPES.id,
        FHIR_DATATYPES.instant,
        FHIR_DATATYPES.integer,
        FHIR_DATATYPES.markdown,
        FHIR_DATATYPES.oid,
        FHIR_DATATYPES.positiveInt,
        FHIR_DATATYPES.string,
        FHIR_DATATYPES.time,
        FHIR_DATATYPES.unsignedInt,
        FHIR_DATATYPES.uri,
        FHIR_DATATYPES.url,
        FHIR_DATATYPES.uuid
      ];
      return (new RegExp('^(' + primitives.join('|') + ')$', 'i')).test(datatype);
    }

    function notProperties (json, validations, rules) {
      var remove = json; // eslint-disable-line no-unused-vars
      var the = validations; // eslint-disable-line no-unused-vars
      var things = rules; // eslint-disable-line no-unused-vars

      return validations;
    }

    function removeProperties (obj) {
      isEmptyProperty(obj, true);
    }

    function verifyProperties (json, rules) {
      var results = [];

      // As we loop through each rule, we do as much repair as we can.
      // If the rule has isArray=true, we coerce to an array.
      // A validator will repair a value if it can.
      rules.forEach(function (rule) {
        var errors = [];
        var isValid;
        var property = rule.property;
        var result = { property: property, isValid: true, reason: 'undefined', value: json[property] };
        var ruleArgs = rule.args || [];
        var wrap;

        if (json[property] == null) {
          if (rule.isRequired) {
            result.isValid = false;
            result.reason = 'isRequired';
          }
          // TODO ? If the rule provides arguments, let the validator try to provide a value.
          results.push(result);
          return;// continue
        }

        if (rule.isArray && !angular.isArray(json[property])) {
          json[property] = [json[property]];// Repair
        }

        if (rule.isArray) {
          json[property].forEach(function (value, index) {
            // Do not call getFhirJsonWrapper
            wrap = new FhirJsonWrapperClass(json, property, index);
            isValid = rule.validate.apply(null, [wrap].concat(ruleArgs));
            if (!isValid || (rule.isRequired && !wrap.isDefined)) {
              errors.push('Element ' + index + ' did not validate.');
            }
          });
          result.invalid = errors;
          result.isValid = !errors.length;
        } else {
          // Do not call getFhirJsonWrapper
          wrap = new FhirJsonWrapperClass(json, property);
          isValid = rule.validate.apply(null, [wrap].concat(ruleArgs));
          if (!isValid || (rule.isRequired && !wrap.isDefined)) {
            result.isValid = false;
          }
        }

        result.reason = rule.validate.name;// Function name
        results.push(result);
      });

      return results;
    }

    function xorProperties (json, validations, prefix, datatypes) { // eslint-disable-line complexity
      // Examine properties named as <prefix><datatype> and remove all but one, choosing in order of preference if listed.
      var reTest = new RegExp('^' + prefix);
      var prop;
      var props = Object.keys(json).filter(function (key) { return reTest.test(key); });

      if (props.length <= 1) {
        return;
      }

      findpref: for (var i = 0, ii = datatypes.length; i < ii; ++i) { // eslint-disable-line no-labels
        prop = prefix + datatypes[i];
        if (props.indexOf(prop) >= 0) {
          // Found a preferred property.
          for (var j = 0, jj = validations.length; j < jj; ++j) {
            if (validations[j].property !== prop) {
              continue;// Next validation.
            }
            if (validations[j].isValid === false) {
              // Next property.
              continue findpref; // eslint-disable-line no-labels
            }
            // The preferred property/value is valid.
            // Remove the other properties and the validations associated with them.
            for (var k = 0, kk = props.length; k < kk; ++k) {
              if (props[k] !== prop) {
                delete json[props[k]];
                for (var m = 0, mm = validations.length; m < mm; ++m) {
                  if (validations[m].property === props[k]) {
                    validations.splice(m, 1);
                    break;
                  }
                }
              }
            }
            return;
          }
        }
      }

      // 2+ properties and could not determine a preference.
      props.forEach(function (p) {
        for (var index = 0, length = validations.length; index < length; ++index) {
          if (validations[index].property === p) {
            validations[index].isValid = false;
            if (validations[index].reason) {
              validations[index].reason += ' +XOR';
            } else {
              validations[index].reason = 'XOR';
            }
            break;
          }
        }
      });
    }
    return service;
  }
})();
