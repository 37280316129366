(function () {
  'use strict';

  angular.module('imat.fhir.constants')
    .constant('IMAT_FHIR_EXTENSION', {
      ELEMENT: 'extension'
    })
    .constant('FHIR_EXTENSION_URLS', {
    // DATA_ABSENT_REASON:          'http://hl7.org/fhir/StructureDefinition/data-absent-reason',
      PATIENT_MOTHERS_MAIDEN_NAME: 'http://hl7.org/fhir/StructureDefinition/patient-mothersMaidenName',
      PATIENT_RELIGION: 'http://hl7.org/fhir/StructureDefinition/patient-religion',
      RENDERED_VALUE: 'http://hl7.org/fhir/StructureDefinition/rendered-value',
      US_CORE_ETHNICITY: 'http://hl7.org/fhir/us/core/StructureDefinition/us-core-ethnicity',
      US_CORE_RACE: 'http://hl7.org/fhir/us/core/StructureDefinition/us-core-race'
    })
    .constant('FHIR_EXTENSION_URLS_BC', { // Backward compatibility for ETL errors.
      US_CORE_ETHNICITY_7_3: 'http://hl7.org/fhir/StructureDefinition/us-core-ethnicity',
      US_CORE_RACE_7_3: 'http://hl7.org/fhir/StructureDefinition/us-core-race'
    })
    .constant('FHIR_EXTENSION_VALUES', {
    // Primitive
      base64Binary: 'valueBase64Binary',
      boolean: 'valueBoolean',
      canonical: 'valueCanonical',
      code: 'valueCode', // string
      date: 'valueDate',
      dateTime: 'valueDateTime',
      decimal: 'valueDecimal',
      id: 'valueId', // string
      instant: 'valueInstant',
      integer: 'valueInteger',
      markdown: 'valueMarkdown', // string
      oid: 'valueOid', // uri
      positiveInt: 'valuePositiveInt', // integer
      string: 'valueString',
      time: 'valueTime',
      unsignedInt: 'valueUnsignedInt', // integer
      uri: 'valueUri',
      url: 'valueUrl',
      uuid: 'valueUuid',
      // Complex: Generic
      Address: 'valueAddress',
      Age: 'valueAge', // Quantity
      Annotation: 'valueAnnotation',
      Attachment: 'valueAttachment',
      CodeableConcept: 'valueCodeableConcept',
      Coding: 'valueCoding',
      ContactPoint: 'valueContactPoint',
      Count: 'valueCount', // Quantity
      Distance: 'valueDistance', // Quantity
      Duration: 'valueDuration', // Quantity
      HumanName: 'valueHumanName',
      Identifier: 'valueIdentifier',
      Money: 'valueMoney', // Quantity
      Period: 'valuePeriod',
      Quantity: 'valueQuantity',
      Range: 'valueRange',
      Ratio: 'valueRatio',
      Reference: 'valueReference',
      SampledData: 'valueSampledData',
      Signature: 'valueSignature',
      Timing: 'valueTiming',
      // Complex: Metadata
      ParameterDefinition: 'valueParameterDefinition',
      DataRequirement: 'valueDataRequirement',
      RelatedArtifact: 'valueRelatedArtifact',
      ContactDetail: 'valueContactDetail',
      Contributor: 'valueContributor',
      TriggerDefinition: 'valueTriggerDefinition',
      UsageContext: 'valueUsageContext',
      Dosage: 'valueDosage'
    })
    .constant('FHIR_EXTENSION_SCHEMAS', {
    // DATA_ABSENT_REASON: {
    //   url: 'http://hl7.org/fhir/StructureDefinition/data-absent-reason',
    //   id: 'data-absent-reason',
    //   name: 'Data Absent Reason',
    //   type: 'code',
    //   binding: 'DATA_ABSENT_REASON',
    //   contextType: 'datatype',
    //   context: ['*'],
    // },
      PATIENT_MOTHERS_MAIDEN_NAME: {
        url: 'http://hl7.org/fhir/StructureDefinition/patient-mothersMaidenName',
        id: 'patient-mothersMaidenName',
        name: "Mother's Maiden Name",
        type: 'string',
        binding: null,
        contextType: 'resource',
        context: ['Patient']
      },
      // PATIENT_RELIGION: {
      //   url: 'http://hl7.org/fhir/StructureDefinition/patient-religion',
      //   id: 'patient-religion',
      //   name: "Religious Affiliation",
      //   type: 'CodeableConcept',
      //   binding: 'RELIGIOUS_AFFILIATION',
      //   contextType: 'resource',
      //   context: ['Patient'],
      // },
      RENDERED_VALUE: {
        url: 'http://hl7.org/fhir/StructureDefinition/rendered-value',
        id: 'rendered-value',
        name: 'Rendered Value',
        type: 'string',
        binding: null,
        contextType: 'datatype',
        context: ['Identifier.value', 'date', 'dateTime', 'time', 'instant', 'integer', 'decimal', 'string', 'code']
      },
      US_CORE_ETHNICITY: {
        url: 'http://hl7.org/fhir/us/core/StructureDefinition/us-core-ethnicity',
        id: 'us-core-ethnicity',
        name: 'Ethnicity',
        type: null,
        binding: [
          { url: 'detailed', type: 'Coding', valueSet: 'US_DETAILED_ETHNICITY' },
          { url: 'ombCategory', type: 'Coding', valueSet: 'US_OMB_ETHNICITY' },
          { url: 'text', type: 'string', valueSet: null }
        ],
        contextType: 'resource',
        context: ['Patient']
      },
      US_CORE_RACE: {
        url: 'http://hl7.org/fhir/us/core/StructureDefinition/us-core-race',
        id: 'us-core-race',
        name: 'Race',
        type: null,
        binding: [
          { url: 'detailed', type: 'Coding', valueSet: 'US_DETAILED_RACE' },
          { url: 'ombCategory', type: 'Coding', valueSet: 'US_OMB_RACE' },
          { url: 'text', type: 'string', valueSet: null }
        ],
        contextType: 'resource',
        context: ['Patient']
      }
    });
})();
