(function () {
  'use strict';

  angular.module('admin')
    .controller('ManageGroupUsersModalCtrl', ManageGroupUsersModalCtrl);

  ManageGroupUsersModalCtrl.$inject = ['$filter', '$mdDialog', '$scope', 'ArrayFnSrv', 'UsersSrv', 'modalUsers', 'provider'];
  function ManageGroupUsersModalCtrl ($filter, $mdDialog, $scope, ArrayFnSrv, UsersSrv, modalUsers, provider) {
    var allUsers = [];
    var origUsers = angular.copy(modalUsers || []).sort(ArrayFnSrv.sortNames);

    $scope.excludedUsers = [];
    $scope.filterAvailableUsers = '';
    $scope.filterExistingUsers = '';
    $scope.includedUsers = angular.copy(origUsers);
    $scope.loading = true;

    // Methods
    $scope.addAllUsers = addAllUsers;
    $scope.addUser = addUser;
    $scope.cancel = cancel;
    $scope.clearAvailableUsersFilter = clearAvailableUsersFilter;
    $scope.clearExistingUsersFilter = clearExistingUsersFilter;
    $scope.removeAllUsers = removeAllUsers;
    $scope.removeUser = removeUser;
    $scope.resetUsers = resetUsers;
    $scope.updateGroupUsers = updateGroupUsers;

    UsersSrv.getList(true)
      .then(function (userList) {
        allUsers = [];
        userList.forEach(function (user) {
          if (user.provider === provider) {
            allUsers.push(user.username);
          }
        });
        $scope.excludedUsers = ArrayFnSrv.diff(allUsers, origUsers).sort(ArrayFnSrv.sortNames);
      })
      .finally(function () {
        $scope.loading = false;
      });

    function addAllUsers () {
      var visibleUsers = $filter('filter')($scope.excludedUsers, { $: $scope.filterAvailableUsers });

      $scope.excludedUsers = ArrayFnSrv.diff($scope.excludedUsers, visibleUsers).sort(ArrayFnSrv.sortNames);
      $scope.includedUsers = $scope.includedUsers.concat(visibleUsers).sort(ArrayFnSrv.sortNames);
      clearAvailableUsersFilter();
    }

    function addUser (user) {
      $scope.excludedUsers.splice($scope.excludedUsers.indexOf(user), 1);
      $scope.includedUsers.push(user);
      $scope.includedUsers.sort(ArrayFnSrv.sortNames);
    }

    function cancel () {
      $mdDialog.cancel();
    }

    function clearAvailableUsersFilter () {
      $scope.filterAvailableUsers = '';
    }

    function clearExistingUsersFilter () {
      $scope.filterExistingUsers = '';
    }

    function removeAllUsers () {
      var visibleUsers = $filter('filter')($scope.includedUsers, { $: $scope.filterExistingUsers });

      $scope.includedUsers = ArrayFnSrv.diff($scope.includedUsers, visibleUsers).sort(ArrayFnSrv.sortNames);
      $scope.excludedUsers = $scope.excludedUsers.concat(visibleUsers).sort(ArrayFnSrv.sortNames);
      clearExistingUsersFilter();
    }

    function removeUser (user) {
      $scope.excludedUsers.push(user);
      $scope.excludedUsers.sort(ArrayFnSrv.sortNames);
      $scope.includedUsers.splice($scope.includedUsers.indexOf(user), 1);
    }

    function resetUsers () {
      $scope.includedUsers = angular.copy(origUsers).sort(ArrayFnSrv.sortNames);
      $scope.excludedUsers = ArrayFnSrv.diff(allUsers, origUsers).sort(ArrayFnSrv.sortNames);
    }

    function updateGroupUsers () {
      var added = ArrayFnSrv.diff($scope.includedUsers, origUsers);
      var removed = ArrayFnSrv.diff(origUsers, $scope.includedUsers);

      if (added.length || removed.length) {
        $mdDialog.hide({ added: added, removed: removed });
      } else {
        $mdDialog.cancel();
      }
    }

    //= ================================
    // Private interface
    //= ================================
  }
})();
