(function () {
  'use strict';

  angular.module('admin')
    .controller('DashboardSystemCtrl', DashboardSystemCtrl);

  DashboardSystemCtrl.$inject = [
    '$mdDialog', '$q', '$scope', '$timeout', 'DashboardsSrv', 'psNotification'
  ];

  function DashboardSystemCtrl (
    $mdDialog, $q, $scope, $timeout, DashboardsSrv, psNotification
  ) {
    var vm = this;
    var ctx = this.constructor.name;

    $scope.ACCESS = DashboardsSrv.ACCESS;
    $scope.LEVELS = DashboardsSrv.LEVELS;

    // Properties
    vm.providerCount = 1;
    vm.loaded = { detail: true, list: true };
    vm.selected = {};
    vm.selectedDetail = { empty: true };
    vm.systemDashboards = [];

    // Methods
    vm.addDashboard = addDashboard;
    vm.clearSelection = clearSelection;
    vm.selectDashboard = selectDashboard;
    vm.refreshSystemDashboardList = refreshSystemDashboardList;

    activate();

    function activate () {
      refreshSystemDashboardList();
    }

    //= ==============================
    // Public interface
    //= ==============================

    function addDashboard () {
      var dialog = {
        controller: 'addDashboardModalCtrl as vm',
        templateUrl: '/ui/admin/dashboards/addDashboardModal.html',
        locals: { modalLevel: DashboardsSrv.LEVELS.SYSTEM, modalDashboards: vm.systemDashboards },
        parent: angular.element(document.body),
        targetEvent: event,
        clickOutsideToClose: true
      };

      $mdDialog.show(dialog)
        .then(function (modalDashboard) {
          createDashboardForSystem(modalDashboard);
        })
        .catch(function () {
        // Dismissed.
        });
    }

    function clearSelection () {
      DashboardsSrv.clearSelected(ctx);
      clearSelectionDetail();
      vm.selected = {};
    }

    function clearSelectionDetail () {
      vm.selectedDetail = { empty: true };
    }

    function selectDashboard (dashboard) {
      vm.selected = dashboard;
      DashboardsSrv.setSelected(ctx, dashboard.id);

      vm.loaded.detail = false;
      if (!DashboardsSrv.isCachedItem(dashboard.id)) {
        clearSelectionDetail();
      }

      DashboardsSrv.getSelectedItem(ctx)
        .then(function (mwDashboard) {
          vm.selectedDetail = mwDashboard;
        })
        .finally(function () {
          $timeout(function () {
            vm.loaded.detail = true;
          });
        });
    }

    //= ==============================
    // Private interface
    //= ==============================

    function createDashboardForSystem (dashboard) {
      DashboardsSrv.getDashboard(dashboard)
        .then(function () {
          psNotification.error('A system dashboard already exists for this provider.');
        }, function () {
          DashboardsSrv.save(dashboard)
            .then(function (mwDashboard) {
              DashboardsSrv.setSelected(ctx, mwDashboard.id);
              // The dashboard has been reloaded with default permsissions...
              mwDashboard.owner_permissions = DashboardsSrv.ACCESS.READ;
              return DashboardsSrv.savePermissions(mwDashboard);
            }, function (err) {
              psNotification.error('Unable to create the dashboard.');
              return $q.reject(err);
            })
            .then(function () {
              refreshSystemDashboardList(true);
            });
        });
    }

    function refreshSystemDashboardList (force) {
      var q = [];

      q.push(
        DashboardsSrv.getCompleteList(force)
      );

      vm.loaded.list = false;
      return $q.all(q)
        .then(function (all) {
          var dashboards;
          var providerDashboards = all[0];
          var reLevel = new RegExp('^' + DashboardsSrv.LEVELS.SYSTEM + '$', 'i');
          // Filter to system level dashboards.
          dashboards = providerDashboards.filter(function (dashboard) {
            return reLevel.test(dashboard.level);
          });
          vm.systemDashboards.splice(0);
          vm.systemDashboards = dashboards;
        })
        .finally(function () {
          vm.loaded.list = true;
          if (DashboardsSrv.getSelectedId(ctx)) {
            selectDashboard(DashboardsSrv.getSelected(ctx));
          } else if (vm.systemDashboards.length) {
            selectDashboard(vm.systemDashboards[0]);
          }
        });
    }
  }
})();
