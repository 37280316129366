(function () {
  'use strict';

  angular.module('vhr.services')
    .service('VhrRecordClass', VhrRecordClass);

  VhrRecordClass.$inject = [
    '$filter'
  ];

  function VhrRecordClass (
    $filter
  ) {
    function Record (cfg) {
      this._id = new Date().getTime();
      this.controller = 'GenericRecordModalCtrl';
      this.cfg = cfg.config;
      this.printTemplateUrl = '';
      this.templateUrl = '/ui/vhr/templates/generic/generic-record-modal.html';
      this.type = cfg.type;
      this.viewedAt = this._id;
    }

    Record.prototype._id = null;
    Record.prototype.fields = null;
    Record.prototype.locator = null;
    Record.prototype.raw = {};
    Record.prototype.store = null;
    Record.prototype.title = null;
    Record.prototype.type = null;
    Record.prototype.uri = null;
    Record.prototype.viewedAt = null;

    Record.prototype.formatFHIRResources = function (resources) {
      var fhirObj = {};
      var resourceObj;
      for (var i = 0, l = resources.length; i < l; i++) {
        resourceObj = resources[i].resource;
        fhirObj[resourceObj.resourceType] = resourceObj;
      }
      return fhirObj;
    };

    Record.prototype.getFields = function () {
      return this.fields;
    };
    Record.prototype.getLabel = function () {
      return this.cfg.label;
    };
    Record.prototype.getModalConfig = function () {
      return {
        controller: this.controller,
        controllerAs: 'modal',
        templateUrl: this.templateUrl,
        parent: angular.element(document.body),
        clickOutsideToClose: true,
        fullscreen: true
      };
    };
    Record.prototype.getReportId = function () {
      return this.cfg.report;
    };
    Record.prototype.getReportParams = function () {
      return this.cfg.parameters;
    };
    Record.prototype.getStore = function () {
      return this.store;
    };
    Record.prototype.getTitle = function () {
      var match;
      var reFieldToken = /\{\{([A-Za-z0-9_-]+)\}\}/g;
      var title = this.cfg.titleFormat;

      if (!this.title && this.cfg.titleFormat) {
        while ((match = reFieldToken.exec(this.cfg.titleFormat)) !== null) {
          if (typeof this.raw[match[1]] === 'undefined') {
            title = null;
            break;
          }
          title = title.replace(match[0], this.raw[match[1]]);
        }
        if (title) {
          this.title = title;
        }
      }
      return (this.title ? this.title : (this.cfg.label ? this.cfg.label + ' Record' : $filter('imatLabelize')(this.getType() + ' Record')));
    };
    Record.prototype.getType = function () {
      return this.type;
    };
    Record.prototype.getUri = function () {
      return this.uri;
    };
    Record.prototype.is = function (type) {
      return type === this.type;
    };
    Record.prototype.set = function (raw, setFields) {
      var keys;
      var labelize = $filter('imatLabelize');
      var reAngularAttached = /^\$+/;

      this.raw = angular.copy(raw);
      this.uri = this.raw.uri;
      this.store = this.raw.store;
      this.locator = '' + this.raw.store + this.raw.uri;
      this.fields = [];

      if (typeof setFields === 'undefined' || setFields) {
        keys = Object.keys(raw).sort();
        this.fields = keys.map(function (key) {
          // Remove $$hashKey and the like...
          if (reAngularAttached.test(key)) { return void (0); } // eslint-disable-line no-void
          return { isArray: angular.isArray(raw[key]), key: key, label: labelize(key), value: raw[key] };
        });
        this.fields = this.fields.filter(function (f) { return typeof f !== 'undefined'; });
      }
    };

    return Record;
  }
})();
