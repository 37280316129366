(function () {
  'use strict';

  angular
    .module('imat.services')
    .factory('symbolsApiSrv', symbolsApiSrv);

  symbolsApiSrv.$inject = ['$http'];

  function symbolsApiSrv ($http) {
    var baseUrl = '/search/symbols';
    var defaultConfig = {
      cache: true
    };

    var service = {
      createSymbolCategory: createSymbolCategory,
      deleteSymbol: deleteSymbol,
      getList: getSymbolList,
      getSymbol: getSymbol,
      postSymbolFields: postSymbolFields,
      postSymbolValues: postSymbolValues,
      putSymbol: putSymbol
    };

    function createSymbolCategory (category, body, config) {
      var url = [baseUrl, category.type, 'categories', category.name].join('/');

      return $http.put(url, body, angular.extend(defaultConfig, config || {}));
    }

    function deleteSymbol (symbol, config) {
      var url = [baseUrl, symbol.type, 'categories', symbol.category, symbol.name].join('/');

      return $http.delete(url, angular.extend(defaultConfig, config || {}));
    }

    function getSymbol (symbol, config) {
      var rexUnderscore = /^_/;
      var url = [baseUrl, symbol.type, 'categories', symbol.category, symbol.name].join('/');

      return $http.get(url, angular.extend(defaultConfig, config || {}))
        .then(function (response) {
          // Clean up x2js underscored properties (XML attributes) and array or obj response
          angular.forEach(response.data.symbol, function (prop, _propName) {
            if (_propName.search(rexUnderscore) > -1) {
              response.data.symbol[_propName.replace(rexUnderscore, '')] = prop;
              delete response.data.symbol[_propName];
            }
          });
          if (!response.data.symbol.items.item) {
            response.data.symbol.items = [];
          } else if (!angular.isArray(response.data.symbol.items.item)) {
            response.data.symbol.items = [response.data.symbol.items.item];
          } else {
            response.data.symbol.items = response.data.symbol.items.item;
          }
          angular.forEach(response.data.symbol.items, function (item) {
            angular.forEach(item, function (prop, _propName) {
              item[_propName.replace(rexUnderscore, '')] = prop;
              delete item[_propName];
            });
          });
          return response;
        });
    }

    function getSymbolList (config) {
      var rexUnderscore = /^_/;

      return $http.get(baseUrl, angular.extend(defaultConfig, config || {}))
        .then(function (response) {
          if (response.data && response.data.symbols && response.data.symbols.symbol) {
            if (!angular.isArray(response.data.symbols.symbol)) {
              response.data.symbols.symbol = [response.data.symbols.symbol];
            }
            // Clean up x2js underscored properties (XML attributes).
            response.data.symbols.symbol.forEach(function (symbol) {
              angular.forEach(symbol, function (prop, _propName) {
                symbol[_propName.replace(rexUnderscore, '')] = prop;
                delete symbol[_propName];
              });
            });
          }
          return response;
        });
    }

    function postSymbolFields () {
      return $http.post(baseUrl + '/fields');
    }

    function postSymbolValues () {
      return $http.post(baseUrl + '/values');
    }

    function putSymbol (symbol, config) {
      var url = [baseUrl, symbol.type, 'categories', symbol.category, symbol.name].join('/');

      return $http.put(url, symbol.body, angular.extend(defaultConfig, config || {}));
    }

    return service;
  }
})();
