(function () {
  'use strict';

  angular
    .module('vhr')
    .controller('RecordCollectionCtrl', vhrCtrl);

  vhrCtrl.$inject = [
    'MODE_META', 'VHR_REPORT',
    '$scope', '$state', '$log', '$mdDialog', '$q', '$timeout', '$window',
    'psNotification', 'psReports', 'vhrApp', 'vhrConfigSrv', 'vhrGridSrv', 'vhrPatientSrv', 'vhrPrintSrv',
    'vhrRecordSrv', 'vhrRecordCollectionSrv', 'vhrXsl'
  ];

  function vhrCtrl (
    MODE_META, VHR_REPORT,
    $scope, $state, $log, $mdDialog, $q, $timeout, $window,
    psNotification, psReports, vhrApp, vhrConfigSrv, vhrGridSrv, vhrPatientSrv, vhrPrintSrv,
    vhrRecordSrv, vhrRecordCollectionSrv, vhrXsl
  ) {
    var vm = this; // eslint-disable-line no-invalid-this
    var pqColl;
    var rvColl;
    var defaultConcept = {
      hasNotes: false,
      id: '',
      noteContext: '',
      noteCount: 0,
      ready: { export: false, exported: false }
    };
    // ADT Concepts
    var CONCEPT_ALLERGIES_GRID = 'concepts-allergy';
    var CONCEPT_ALLERGIES_LABEL = 'Allergies';
    var CONCEPT_PROBLEM_GRID = 'concepts-problem';
    var CONCEPT_PROBLEM_LABEL = 'Diagnosis/Problem';
    var CONCEPT_INSURANCE_GRID = 'concepts-medical-insurance';
    var CONCEPT_INSURANCE_LABEL = 'Medical Insurance';
    var CONCEPT_PROCEDURE_GRID = 'concepts-procedure';
    var CONCEPT_PROCEDURE_LABEL = 'Procedures';
    var CONCEPT_CONTACT_GRID = 'concepts-contact';
    var CONCEPT_CONTACT_LABEL = 'Next of Kin';
    var CONCEPT_GUARANTOR_GRID = 'concepts-guarantor';
    var CONCEPT_GUARANTOR_LABEL = 'Guarantors';
    var CONCEPT_DEMOGRAPHICS_GRID = 'concepts-demographics';
    var CONCEPT_DEMOGRAPHICS_LABEL = 'Demographics';
    // Lab Concepts
    var CONCEPT_LAB_GRID = 'concepts-lab';
    var CONCEPT_LAB_LABEL = 'Labs';
    // Medication Concepts
    var CONCEPT_DISPENSE_REQUEST_GRID = 'concepts-dispense-request';
    var CONCEPT_DISPENSE_REQUEST_LABEL = 'Dispense Request';
    var CONCEPT_DOSAGE_GRID = 'concepts-dosage';
    var CONCEPT_DOSAGE_LABEL = 'Dosage';
    var CONCEPT_DOSAGE_INSTRUCTION_GRID = 'concepts-dosage-instructions';
    var CONCEPT_DOSAGE_INSTRUCTION_LABEL = 'Dosage Instructions';
    var CONCEPT_INGREDIENT_GRID = 'concepts-ingredients';
    var CONCEPT_INGREDIENT_LABEL = 'Components';
    var concepts = [
      angular.extend({}, defaultConcept, { gridName: CONCEPT_ALLERGIES_GRID, label: CONCEPT_ALLERGIES_LABEL }),
      angular.extend({}, defaultConcept, { gridName: CONCEPT_PROBLEM_GRID, label: CONCEPT_PROBLEM_LABEL }),
      angular.extend({}, defaultConcept, { gridName: CONCEPT_INSURANCE_GRID, label: CONCEPT_INSURANCE_LABEL }),
      angular.extend({}, defaultConcept, { gridName: CONCEPT_PROCEDURE_GRID, label: CONCEPT_PROCEDURE_LABEL }),
      angular.extend({}, defaultConcept, { gridName: CONCEPT_CONTACT_GRID, label: CONCEPT_CONTACT_LABEL }),
      angular.extend({}, defaultConcept, { gridName: CONCEPT_GUARANTOR_GRID, label: CONCEPT_GUARANTOR_LABEL }),
      angular.extend({}, defaultConcept, { gridName: CONCEPT_DEMOGRAPHICS_GRID, label: CONCEPT_DEMOGRAPHICS_LABEL }),

      angular.extend({}, defaultConcept, { gridName: CONCEPT_LAB_GRID, label: CONCEPT_LAB_LABEL }),

      angular.extend({}, defaultConcept, { gridName: CONCEPT_DISPENSE_REQUEST_GRID, label: CONCEPT_DISPENSE_REQUEST_LABEL }),
      angular.extend({}, defaultConcept, { gridName: CONCEPT_DOSAGE_GRID, label: CONCEPT_DOSAGE_LABEL }),
      angular.extend({}, defaultConcept, { gridName: CONCEPT_DOSAGE_INSTRUCTION_GRID, label: CONCEPT_DOSAGE_INSTRUCTION_LABEL }),
      angular.extend({}, defaultConcept, { gridName: CONCEPT_INGREDIENT_GRID, label: CONCEPT_INGREDIENT_LABEL })
    ];

    // Properties
    vm.adtLabels = vhrConfigSrv.getADTLabels();
    vm.concepts = [];// ng-repeatable.
    vm.conceptsIndex = {};// To re-use print templates.
    vm.documentXsl = '';
    vm.errors = { concepts: false };
    vm.gridApi = {};
    vm.gridApiIndex = {};// To re-use print templates.
    vm.gridConfig = {};
    vm.loaded = { concepts: false, printView: false };
    vm.recordGroupIndex = {};
    vm.recordGroups = [];// ng-repeatable.
    vm.workspace = 'patient-record-print';

    vm.PRINT_LABEL = MODE_META.PRINT.LABEL;
    vm.VIEW_ICON = MODE_META.VIEW.ICON;
    vm.VIEW_TOOLTIP = MODE_META.VIEW.TOOLTIP;

    // Methods
    vm.back = back;
    vm.print = print;
    vm.remove = remove;
    vm.view = view;

    activate();

    // Implementation

    function activate () {
      vhrApp.enableFilter(false);
      vhrApp.enableModes(false);
      vhrApp.enableRefresh(false);

      // When a user removes a record from the queue, we have to re-load
      // the concepts for printing because footnotes suck...
      $scope.$on('vhrRecordCollectionChanged', function (evt, coll) {
        if (coll.getType() === 'printQueue') {
          load();
        }
      });

      pqColl = vhrRecordCollectionSrv.construct('printQueue');
      rvColl = vhrRecordCollectionSrv.construct('recentViews');

      load();
    }

    // ---------------------------------
    // Public interface
    // ---------------------------------

    function back () {
      $window.history.go(-1);
      return false;
    }

    function print () {
      vhrPrintSrv.print();
    }

    function remove (record) {
      pqColl.remove(record);
    }

    function view (record) {
      var cfg;

      vhrRecordCollectionSrv.stash(record);

      cfg = record.getModalConfig();
      cfg.onComplete = vhrPrintSrv.hideContent;
      cfg.onRemoving = vhrPrintSrv.showContent;

      $mdDialog.show(cfg)
        .then(function () {
        // Interaction
        }, function () {
        // Cancel
        })
        .finally(function () {
          rvColl.resequence(record);
        });
    }

    // ---------------------------------
    // Private interface
    // ---------------------------------

    function load () {
      setRecordGroups();
      loadXslDocument()
        .then(function () {
          return loadReports();
        })
        .then(function () {
          return loadRecords();
        })
        .catch(function (reason) {
          reason = reason || 'Failed to load one or more records.';
          psNotification.error(reason);
        });
    }

    function loadRecords () {
      var promise;
      var q = [];

      vm.concepts.splice(0);
      vm.conceptsIndex = {};
      vm.gridApi = {};
      vm.gridApiIndex = {};
      vm.gridConfig = {};
      vm.loaded.concepts = false;

      angular.forEach(vm.recordGroupIndex, function (recordGroup, recordType) {
        var loadFn = loadDataArtifact;
        var newParams = recordGroup.reportParams;
        var oldParams;

        if (!recordGroup.records.length || !recordGroup.report) {
          return;// continue
        }

        if ([VHR_REPORT.RECORD_TYPE.CCD].indexOf(recordType) >= 0) {
          loadFn = loadCCDArtifact;
        }

        // TODO Make this change detection more sophisticated so we can save time when the list is only shrinking.
        newParams.records = {};
        recordGroup.records.forEach(function (record) {
          newParams.records[record.getUri()] = {
            store: record.getStore(),
            view_type: record.getType()
          };
        });

        oldParams = recordGroup.report.getLastParameters(vm.workspace) || recordGroup.report.getParameters();

        if (angular.equals(newParams.records, oldParams.records)) {
          promise = loadFn(recordGroup);
        } else {
          promise = (loadReportData(recordGroup.report, newParams))
            .then(function () {
              return loadFn(recordGroup);
            });
        }

        q.push(promise);
      });

      return $q.all(q)
        .then(checkAllConceptsReady)
        .catch(function (reason) {
          $log.debug(reason);
          return $q.reject('Failed to load one or more records.');
        });
    }

    function loadReports () {
      var q = [];

      angular.forEach(vm.recordGroupIndex, function (recordGroup, recordType) {
        if (!recordGroup.report && recordGroup.config.report && recordGroup.records.length) {
          q.push(loadReport(recordGroup, recordType));
        }
      });

      return $q.all(q)
        .catch(function (reason) {
          $log.debug(reason);
          return $q.reject('Failed to prepare one or more records.');
        });
    }

    function loadXslDocument () {
      return vhrXsl.getXslFile()
        .then(function (xslResponse) {
          vm.documentXsl = xslResponse;
        })
        .catch(function (reason) {
          $log.debug(reason);
          return $q.reject('Failed to load the XSL file.');
        });
    }

    function setRecordGroups () {
      vm.recordGroups.splice(0);

      angular.forEach(VHR_REPORT.RECORD_TYPE, function (recordType) {
        var found = pqColl.findAll(recordType);

        if (Object.prototype.hasOwnProperty.call(vm.recordGroupIndex, recordType)) {
          vm.recordGroupIndex[recordType].records = found || [];
        } else {
          vm.recordGroupIndex[recordType] = {
            config: vhrRecordSrv.getTypeConfig(recordType),
            records: found || [],
            report: null,
            reportParams: null
          };
        }
        if (found && found.length && vm.recordGroupIndex[recordType].config.report) {
          vm.recordGroups.push(vm.recordGroupIndex[recordType]);
        }
      });

      vm.recordGroups.sort(function (a, b) {
        return a.config.label.trim().toLowerCase().replace(/\s+/g, '.') - b.config.label.trim().toLowerCase().replace(/\s+/g, '.');
      });
    }

    // ---------------------------------
    // Record loading
    // ---------------------------------

    function checkAllConceptsExported () {
      if (!vm.loaded.printView) {
        vm.loaded.printView = (vm.concepts.filter(function (c) {
          return !(c.ready.exported);
        }).length === 0);
      }
      if (!vm.loaded.printView) {
        $timeout(checkAllConceptsExported, 0, false);
      }
    }

    function checkAllConceptsReady () {
      if (!vm.loaded.concepts) {
        vm.loaded.concepts = (vm.concepts.filter(function (c) {
          return !(c.ready.export);
        }).length === 0);
      }
      if (!vm.loaded.concepts) {
        $timeout(checkAllConceptsReady, 0, false);
      } else {
        checkAllConceptsExported();
      }
    }

    function loadCCDArtifact (recordGroup) {
      return loadDataArtifact(recordGroup)
        .then(function () {
          return loadDocumentArtifact(recordGroup);
        });
    }

    function loadDataArtifact (recordGroup) {
      var promise;
      var q = [];

      recordGroup.records.forEach(function (record) {
        var artifactName = record.getUri() + '.json';

        promise = recordGroup.report.getArtifact(vm.workspace, artifactName)
          .then(function (response) {
            var raw = response.pop();
            var rec = angular.extend({}, record.raw, raw);
            var reMrnAtFac = /^\s*([^\@]+)\s*\@\s*(.+)\s*$/;  // eslint-disable-line

            record.set(rec);

            if (rec.patient_local_id && typeof rec.patient_local_id === 'string') {
              record.mrnList = rec.patient_local_id.split(/\|/).map(function (mrn) { return mrn.trim().replace(reMrnAtFac, '$1 [$2]'); });
            }

            return record;
          });
        q.push(promise);
      });

      return $q.all(q)
        .then(function (all) {
          all.forEach(function (record) {
            setupConcepts(record);
          });
        })
        .catch(function () {
          vm.errors.concepts = true;
          return $q.reject();
        });
    }

    function loadDocumentArtifact (recordGroup) {
      var promise;
      var q = [];

      recordGroup.records.forEach(function (record) {
        var artifactName = record.getUri() + '.xml';

        promise = recordGroup.report.getArtifact(vm.workspace, artifactName)
          .then(function (response) {
            record.setDocument(response.original_response, vm.documentXsl);
          })
          .catch(function (reason) {
            $log.debug(reason);
            return $q.reject('Failed to load the record document.');
          });
        q.push(promise);
      });

      return $q.all(q)
        .catch(function () {
          vm.errors.concepts = true;
          return $q.reject();
        });
    }

    function loadReport (recordGroup, recordType) {
      // Re-using a report will overwrite results unless we change either the
      // workspace or the caller. Re-using a report is typical (i.e., the same
      // report retrieves almost all of the record types), but when this code
      // was written, the problem was not properly anticipated. Changing the
      // caller seems lower impact, which we can do here, once.
      var disambiguateReports = VHR_REPORT.CALLER + '-' + recordType;

      return psReports.load(recordGroup.config.report, disambiguateReports, { initParams: vhrConfigSrv.getReportParameters(recordGroup.config.report) })
        .then(function (report) {
          recordGroup.report = report;
          recordGroup.reportParams = angular.extend(recordGroup.report.getParameterDefaults(), recordGroup.config.parameters);
        });
    }

    function loadReportData (report, params) {
      return report.run(vm.workspace, params)
        .then(function () {
          return report.results[vm.workspace].getPollPromise();
        });
    }

    // ---------------------------------
    // Concepts and ui-grids
    // ---------------------------------

    function setupConcepts (record) { // eslint-disable-line complexity
      var gridMeta;

      var misc = [{
        patient_marital_status: record.raw.patient_marital_status,
        patient_race: record.raw.patient_race,
        patient_language: record.raw.patient_language,
        patient_ethnic_group: record.raw.patient_ethnic_group
      }];

      switch (record.getType()) {
        case VHR_REPORT.RECORD_TYPE.ADT:
          gridMeta = concepts.filter(function (c) { return c.label === CONCEPT_ALLERGIES_LABEL; }).pop();
          if (!angular.isArray(record.raw.allergies)) { record.raw.allergies = null; }
          setupConcept(gridMeta, record.locator, record.raw.allergies);
          gridMeta = concepts.filter(function (c) { return c.label === CONCEPT_PROBLEM_LABEL; }).pop();
          if (!angular.isArray(record.raw.problems)) { record.raw.problems = null; }
          setupConcept(gridMeta, record.locator, record.raw.problems);
          gridMeta = concepts.filter(function (c) { return c.label === CONCEPT_INSURANCE_LABEL; }).pop();
          if (!angular.isArray(record.raw.medical_insurance)) { record.raw.medical_insurance = null; }
          setupConcept(gridMeta, record.locator, record.raw.medical_insurance);
          gridMeta = concepts.filter(function (c) { return c.label === CONCEPT_PROCEDURE_LABEL; }).pop();
          if (!angular.isArray(record.raw.procedures)) { record.raw.procedures = null; }
          setupConcept(gridMeta, record.locator, record.raw.procedures);
          gridMeta = concepts.filter(function (c) { return c.label === CONCEPT_CONTACT_LABEL; }).pop();
          if (!angular.isArray(record.raw.contacts)) { record.raw.contacts = null; }
          setupConcept(gridMeta, record.locator, record.raw.contacts);
          gridMeta = concepts.filter(function (c) { return c.label === CONCEPT_GUARANTOR_LABEL; }).pop();
          if (!angular.isArray(record.raw.guarantor)) { record.raw.guarantor = null; }
          setupConcept(gridMeta, record.locator, record.raw.guarantor);
          gridMeta = concepts.filter(function (c) { return c.label === CONCEPT_DEMOGRAPHICS_LABEL; }).pop();
          setupConcept(gridMeta, record.locator, misc);
          break;
        case VHR_REPORT.RECORD_TYPE.LAB:
          gridMeta = concepts.filter(function (c) { return c.label === CONCEPT_LAB_LABEL; }).pop();
          if (angular.isArray(record.raw.labs)) {
            record.raw.labs.forEach(function (lab, idx) {
              setupConcept(gridMeta, record.locator, lab, idx, record.raw.labs.length);
            });
          }
          break;
        case VHR_REPORT.RECORD_TYPE.MED_ADMINISTER:
          gridMeta = concepts.filter(function (c) { return c.label === CONCEPT_DOSAGE_LABEL; }).pop();
          if (!angular.isArray(record.raw.dosage)) { record.raw.dosage = null; }
          setupConcept(gridMeta, record.locator, record.raw.dosage);
          gridMeta = concepts.filter(function (c) { return c.label === CONCEPT_INGREDIENT_LABEL; }).pop();
          if (!angular.isArray(record.raw.compounds)) { record.raw.compounds = null; }
          setupConcept(gridMeta, record.locator, record.raw.compounds);
          break;
        case VHR_REPORT.RECORD_TYPE.MED_REQUEST:
          gridMeta = concepts.filter(function (c) { return c.label === CONCEPT_DOSAGE_INSTRUCTION_LABEL; }).pop();
          if (!angular.isArray(record.raw.dosage_instructions)) { record.raw.dosage_instructions = null; }
          setupConcept(gridMeta, record.locator, record.raw.dosage_instructions);
          gridMeta = concepts.filter(function (c) { return c.label === CONCEPT_DISPENSE_REQUEST_LABEL; }).pop();
          if (!angular.isArray(record.raw.dispense_request)) { record.raw.dispense_request = null; }
          setupConcept(gridMeta, record.locator, record.raw.dispense_request);
          gridMeta = concepts.filter(function (c) { return c.label === CONCEPT_INGREDIENT_LABEL; }).pop();
          if (!angular.isArray(record.raw.compounds)) { record.raw.compounds = null; }
          setupConcept(gridMeta, record.locator, record.raw.compounds);
          break;
        default:
          // Nothing to do?
          break;
      }
    }

    function setupConcept (gridMeta, recLoc, gridData, idx, cnt) {
      var len;

      if (!gridMeta || !recLoc || gridData === null) {
        return;
      }

      len = vm.concepts.push(angular.copy(gridMeta));
      gridMeta = vm.concepts[len - 1];

      if (typeof idx === 'undefined' || cnt < 2) {
        idx = 0;
      } else {
        gridMeta.label += ' (' + (idx + 1) + ')';
      }

      gridMeta.id = gridMeta.gridName + '-' + recLoc + '-' + idx;// Must be unique across all concepts in the template.
      setupConceptGrid(gridMeta, recLoc, gridData);
      setupConceptsIndex(recLoc, (len - 1));// To re-use print templates.
    }

    function setupConceptGrid (gridMeta, recLoc, gridData) {
      var gridDef = vhrConfigSrv.raw.grids[gridMeta.gridName] || { fieldOrder: [] };
      var gridConfig = angular.extend({ data: gridData }, gridDef.uiGridConfig || {});
      var gridType = gridMeta.id;

      vm.gridConfig[gridType] = {};

      gridConfig = vhrGridSrv.getGridConfig($scope, gridType, vhrGridSrv.CONFIG.CONCEPT, gridConfig, gridDef.fieldOrder);
      gridConfig.onRegisterApi = function (api) {
        vhrGridSrv.setGridFootnotes(api.grid, gridMeta);
        vhrGridSrv.setGridHeight(api.grid);
        vm.gridApi[gridType] = api;
        setupGridApiIndex(gridMeta, recLoc, api);// To re-use print templates.
      };
      angular.extend(vm.gridConfig[gridType], gridConfig);
    }

    function setupConceptsIndex (recLoc, idx) {
      if (typeof vm.conceptsIndex[recLoc] === 'undefined') {
        vm.conceptsIndex[recLoc] = [];
      }
      vm.conceptsIndex[recLoc].push(vm.concepts[idx]);
    }

    function setupGridApiIndex (gridMeta, recLoc, api) {
      if (typeof vm.gridApiIndex[recLoc] === 'undefined') {
        vm.gridApiIndex[recLoc] = {};
      }
      vm.gridApiIndex[recLoc][gridMeta.id] = api;
      gridMeta.ready.export = true;
    }
  }
})();
