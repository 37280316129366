/* APP INTERCEPTORS */
(function () {
  'use strict';

  angular.module('imat')
    .config(config);

  config.$inject = ['$httpProvider'];

  function config ($httpProvider) {
    // Global Interceptors
    // Processed top to bottom for requests and reversed for responses.
    $httpProvider.interceptors.push('activityInterceptor');
    $httpProvider.interceptors.push('errorInterceptor');
    $httpProvider.interceptors.push('xmlHttpInterceptor');
  }
})();
