(function () {
  'use strict';

  angular.module('imat.services')
    .factory('DashboardClass', DashboardClass);

  DashboardClass.$inject = ['DASHBOARD'];

  function DashboardClass (DASHBOARD) {
    function Dashboard (data) {
      var self = this;
      var defaults = {
        created_by: null,
        created_on: null,
        description: '',
        display_name: 'My Dashboard',
        effective_permissions: self.ACCESS.NONE,
        group: null,
        id: null,
        level: self.LEVELS.USER,
        modified_by: null,
        modified_on: null,
        name: 'my-dashboard',
        owner: null,
        owner_permissions: self.ACCESS.NONE,
        provider: null,
        tiles: []
      };

      if (typeof data === 'string') {
        data = { id: data };
      }
      if (!angular.isObject(data)) {
        data = {};
      }

      angular.extend(self, defaults, noneToNull(data));
    }

    Dashboard.prototype.ACCESS = {
      NONE: 0,
      EXECUTE: 1,
      WRITE: 2,
      READ: 4,
      FULL: 7
    };

    Dashboard.prototype.LEVELS = {
      BASE: 'base',
      GROUP: 'group',
      SYSTEM: 'system',
      USER: 'user'
    };

    Dashboard.prototype.toJSON = function () {
      var self = this;

      if (!angular.isArray(self.tiles)) {
        self.tiles = [];
      }

      var tiles = self.tiles.filter(function (t) { return t.type && [DASHBOARD.ITEMS.TYPES.FILTER, DASHBOARD.ITEMS.TYPES.SEQUENCE].indexOf(t.type) === -1; });
      var filters = self.tiles.filter(function (t) { return t.type === DASHBOARD.ITEMS.TYPES.FILTER; }).map(function (i) { return JSON.parse(JSON.stringify(i)); });
      var sequences = self.tiles.filter(function (t) { return t.type === DASHBOARD.ITEMS.TYPES.SEQUENCE; }).map(function (i) { return JSON.parse(JSON.stringify(i)); });
      var arr = tiles.map(function (t) {
        return t.toJSON();
      }).concat(filters, sequences);

      return {
        name: this.name,
        display_name: this.display_name,
        tiles: arr
      };
    };

    function noneToNull (obj) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key) && obj[key] === 'None') {
          obj[key] = null;
        }
      }
      return obj;
    }

    return Dashboard;
  }
})();
