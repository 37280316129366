(function () {
  'use strict';

  angular.module('admin')
    .controller('AdminSymbolsAddCtrl', AdminSymbolsAddCtrl);

  AdminSymbolsAddCtrl.$inject = ['$q', '$state', 'SymbolsSrv', 'psNotification'];

  function AdminSymbolsAddCtrl ($q, $state, SymbolsSrv, psNotification) {
    var vm = this;

    // Properties
    vm.categories = [];
    vm.filterText = '';
    vm.symbol = {
      category: '',
      desc: '',
      items: [],
      name: ''
    };
    vm.values = '';

    // Methods
    vm.addItems = addItems;
    vm.addSymbol = addSymbol;
    vm.clearFilter = clearFilter;
    vm.filterList = filterList;
    vm.removeItem = removeItem;

    activate();

    function activate () {
    // debug values for form
    // vm.symbol.category = "test cat";
    // vm.symbol.name = "test name";
    // vm.symbol.desc = "test desc";
    // vm.symbol.items = [
    //  {name:"testing values"}
    // ];
    }

    //= ================================
    // Public interface
    //= ================================

    function addItems () {
      var newItems = vm.values.split(',');
      newItems.forEach(function (value) {
        value = value.trim();
        if (value.length > 0 && findItemIndex(value) === -1) {
          vm.symbol.items.push({ name: value });
        }
      });
      vm.values = '';
    }

    function addSymbol () {
      var promises = [];
      var fieldsSymbol = angular.copy(vm.symbol);
      var valuesSymbol = angular.copy(vm.symbol);
      fieldsSymbol.type = 'fields';
      valuesSymbol.type = 'values';
      promises.push(SymbolsSrv.createSymbol(fieldsSymbol));
      promises.push(SymbolsSrv.createSymbol(valuesSymbol));
      $q.all(promises)
        .then(function () {
          psNotification.success('Symbol added.');
          $state.go('app.admin.symbols');
        })
        .catch(function () {
          psNotification.error('Unable to add symbol.');
        });
    }

    function clearFilter () {
      vm.filterText = '';
    }

    function filterList (item) {
      var regex = new RegExp(vm.filterText, 'i');
      return !vm.filterText || regex.test(item.name);
    }

    function removeItem (item) {
      var index = findItemIndex(item.name);
      if (index > -1) {
        vm.symbol.items.splice(index, 1);
        return true;
      }
      return false;
    }

    //= ================================
    // Private interface
    //= ================================

    function findItemIndex (value) {
      if (vm.symbol.items && vm.symbol.items.length > 0) {
        for (var i = 0, l = vm.symbol.items.length; i < l; i++) {
          if (value === vm.symbol.items[i].name) {
            return i;
          }
        }
      }
      return -1;
    }
  }
})();
