(function () {
  'use strict';

  angular
    .module('imat.services')
    .factory('providersApiSrv', providersApiSrv);

  providersApiSrv.$inject = ['$http'];

  function providersApiSrv ($http) {
    var baseUrl = '/appliance/providers';
    var defaultConfig = {
      cache: true
    };

    var service = {
      getList: getList
    };

    function getList (config) {
      return $http.get(baseUrl, angular.extend(defaultConfig, config || {}));
    }

    return service;
  }
})();
