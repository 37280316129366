(function () {
  'use strict';
  angular
    .module('imat.directives')
    .directive('stopPropagation', function () {
      return {
        restrict: 'A',
        link: function ($scope, $element) {
          $element.on('keydown', function (ev) {
            ev.stopPropagation();
          });
        }
      };
    });
})();
