(function () {
  'use strict';

  angular.module('imat.services')
    .constant('PS_REPORT', {
      INPUT_TYPES: {
        DATE: 'date',
        LIST: 'multiselect',
        SELECT: 'select',
        TEXT: 'text',
        TEXTAREA: 'textarea',
        TOGGLE: 'checkbox',
        TYPEAHEAD: 'typeahead'
      },
      PARAM_TYPES: {
        BOOL: 'bool',
        DATE: 'date',
        INT: 'int',
        LIST: 'list',
        OBJ: 'dict',
        TEXT: 'str'
      }
    })
    .factory('psReports', psReports);

  psReports.$inject = ['PS_REPORT', '$http', '$q', '$timeout', 'ImatReportClass', 'searchApiSrv'];

  function psReports (PS_REPORT, $http, $q, $timeout, ImatReportClass, searchApiSrv) {
    var service;
    var collection = {};

    service = {
      INPUT_TYPES: PS_REPORT.INPUT_TYPES,
      PARAM_TYPES: PS_REPORT.PARAM_TYPES,
      load: load,
      reset: reset
    };

    function load (reportId, caller, options) {
      var reportPromise;

      if (!reportId) {
        return $q.reject('The reportId parameter is required.');
      }
      if (!caller) {
        return $q.reject('The caller parameter is required.');
      }
      if (Object.prototype.hasOwnProperty.call(collection, caller)) {
        reportPromise = collection[caller][reportId];
      }
      return (reportPromise) || construct(reportId, caller, options);
    }

    function reset (caller, retain) {
      var promises = [];

      if (!caller) {
        return $q.reject('The caller parameter is required.');
      }

      angular.forEach(collection[caller], function (reportPromise) {
        if (reportPromise) {
          reportPromise.then(function (report) {
            angular.forEach(report.results, function (workspaceResults, workspaceKey) {
              if (workspaceResults.status === report.STATUS.RUNNING) {
                workspaceResults.status = report.STATUS.CANCELED;
              }
              promises.push(report.deleteResult(workspaceKey));
            });
          });
        }
      });
      if (!retain) {
        collection[caller] = {};
      }
      return $q.all(promises);
    }

    //= ================================
    // Private interface
    //= ================================

    function construct (reportId, caller, userOptions) {
      var defaultOptions = {
        canArtifactPoll: false,
        initParams: null,
        mergeParams: null
      };
      var deferred = $q.defer();
      var options = angular.extend(defaultOptions, userOptions);
      var rawReport;

      if (!Object.prototype.hasOwnProperty.call(collection, caller)) {
        collection[caller] = {};
        collection[caller][reportId] = deferred.promise;
      } else if (!collection[caller][reportId]) {
        collection[caller][reportId] = deferred.promise;
      } else if (collection[caller][reportId]) {
        // This report ID has been requested before (probably in a loop).
        return collection[caller][reportId];
      }

      rawReport = new ImatReportClass(reportId, caller, options.canArtifactPoll);

      initParams(rawReport, options)
        .then(function (report) {
          mergeParams(report, options);
          deferred.resolve(report);
        })
        .catch(function (err) {
          deferred.reject(err);
          collection[caller][reportId] = null;// Don't cache a report that failed to init.
        });

      return deferred.promise;
    }

    function initParams (report, options) {
      if (options.initParams) {
        setReportParams(report, options.initParams);
        return $q.resolve(report);
      }
      return getReportDetails(report)
        .then(function (details) {
          report.firstRunResultId = details.id;
          report.mode = details.data.mode;
          setReportParams(report, details.data.parameters);
          return report;
        });
    }

    function mergeParams (report, options) {
      if (!options.mergeParams) {
        return;
      }
      angular.forEach(options.mergeParams, function (obj, param) {
        if (Object.prototype.hasOwnProperty.call(report.parameters, param)) {
          report.parameters[param] = angular.merge({}, report.parameters[param], obj);
        }
      });
    }

    function setReportParams (report, params) {
      angular.forEach(params, function (def, param) {
        def.inputType = PS_REPORT.INPUT_TYPES.TEXT;
        switch (def.type) {
          case PS_REPORT.PARAM_TYPES.INT:
          case PS_REPORT.PARAM_TYPES.TEXT:
            if (def.choices !== null) {
              def.inputType = PS_REPORT.INPUT_TYPES.SELECT;
            }
            break;
          case PS_REPORT.PARAM_TYPES.LIST:
            def.inputType = PS_REPORT.INPUT_TYPES.LIST;
            def.value = setValueToArray(def.value);
            def.default = setValueToArray(def.default);
            break;
          case PS_REPORT.PARAM_TYPES.DATE:
            def.inputType = PS_REPORT.INPUT_TYPES.DATE;
            break;
          case PS_REPORT.PARAM_TYPES.BOOL:
            def.inputType = PS_REPORT.INPUT_TYPES.TOGGLE;
            break;
          case PS_REPORT.PARAM_TYPES.OBJ:
            def.inputType = PS_REPORT.INPUT_TYPES.TEXTAREA;
            break;
          default:
            def.inputType = PS_REPORT.INPUT_TYPES.TEXT;
        }
        report.parameters[param] = def;
      });
    }

    // TODO Switch to POST /api/queries/:id/run, after that API takes/uses the usesession parameter.
    function getReportDetails (report) {
      return $http.post('/search/queries/' + report.id + '/run', null, {
        params: { usesession: 'true' },
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } // XXX Seems pointless.
      })
        .then(function (res) {
          var data = res.data;

          if (Object.prototype.hasOwnProperty.call(data, 'response')) {
            return data.response;// XXX Is it even possible to get in here?
          }

          return pollResultStatus(data.result_id)
            .then(function () {
              return searchApiSrv.getResultOutput(data.result_id, 'summary.json');
            })
            .then(function (res) {
              var ret = { id: data.result_id, data: res.data };

              // If there are parameters and one or more are not valid (can we just check ret.data.mode=params_only?)
              if (ret.data.parameters && ret.data.valid === false && Object.keys(ret.data.parameters).length) {
                ret.data.validationError = true;
              }
              return ret;
            });
        })
        .then(function (response) {
          return (response && response.data) ? response : $q.reject();
        });
    }

    function pollResultStatus (resultId) {
      var POLL_DELAY = 750;
      var deferred = $q.defer();

      $timeout(_pollResultStatus, POLL_DELAY, false, resultId, deferred);
      return deferred.promise;
    }

    function _pollResultStatus (resultId, deferred) {
      var POLL_INTERVAL = 750;

      searchApiSrv.getSearchStatus(resultId)
        .then(function (res) {
          var result = res.data.result;

          if (result.exit_code === 'None') {
            $timeout(_pollResultStatus, POLL_INTERVAL, false, resultId, deferred);
          } else if (result.status === 'COMPLETE') {
            // Even with errors (which we expect because we sent no parameters).
            deferred.resolve({ id: resultId, msg: 'Report completed.' });
          } else {
            // Any terminal status other than COMPLETE.
            deferred.reject({ id: resultId, msg: 'Report failed with status ' + result.status + '.' });
          }
        }, function () {
          deferred.reject({ id: resultId, msg: 'Unable to read report status.' });
        });
    }

    function setValueToArray (value) {
      return angular.isArray(value) ? value : (value == null) ? [] : [value];
    }

    return service;
  }
})();
