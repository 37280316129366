(function () {
  'use strict';

  angular.module('dashboardApp')
    .controller('ItemLinkConfigCtrl', ItemLinkConfigCtrl);

  ItemLinkConfigCtrl.$inject = ['DASHBOARD', '$mdDialog', '$scope', 'DashboardItemClass', 'modalDashboard', 'modalItem'];

  function ItemLinkConfigCtrl (DASHBOARD, $mdDialog, $scope, DashboardItemClass, modalDashboard, modalItem) {
    var modal = this;
    // Properties
    $scope.IMAGE_TYPES = DashboardItemClass.prototype.IMAGE;
    $scope.colors = DASHBOARD.COLORS;
    $scope.icons = [].concat(
      DASHBOARD.ICONS.GLYPHICONS.map(function (i) { return { name: i, type: $scope.IMAGE_TYPES.GLYPHICON }; }),
      DASHBOARD.ICONS.MATERIALICONS.map(function (i) { return { name: i, type: $scope.IMAGE_TYPES.MATERIAL }; }),
      DASHBOARD.ICONS.PSICONS.map(function (i) { return { name: i, type: $scope.IMAGE_TYPES.PS }; })
    );
    $scope.image = { color: '', icon: '', src: '' };
    $scope.imgType = '';
    $scope.tile = modalItem;
    // Methods
    $scope.cancel = $mdDialog.cancel;
    $scope.submit = $mdDialog.hide;
    modal.changeImgType = changeImgType;
    modal.updateColor = updateColor;
    modal.updateIcon = updateIcon;
    modal.updateSrc = updateSrc;

    activate();

    function activate () {
      switch (modalItem.image.type) {
        case $scope.IMAGE_TYPES.PS:
        case $scope.IMAGE_TYPES.GLYPHICON:// No break.
        case $scope.IMAGE_TYPES.MATERIAL:
          $scope.image.color = modalItem.image.color || $scope.colors[0];
          $scope.image.icon = { name: modalItem.image.value, type: modalItem.image.type };
          $scope.image.src = '';
          $scope.imgType = '0';
          break;
        case $scope.IMAGE_TYPES.IMGSRC:
          $scope.image.color = $scope.colors[0];
          $scope.image.icon = $scope.icons[0];
          $scope.image.src = modalItem.image.value || '';
          $scope.imgType = '1';
          break;
        default:
          break;
      }
    }

    function changeImgType () {
      switch ($scope.imgType) {
        case '0':
          updateIcon($scope.image.icon);
          break;
        case '1':
          updateSrc();
          break;
        default:
          break;
      }
    }

    function updateColor (color) {
      modalItem.image.color = color;
      $scope.image.color = color;
    }

    function updateIcon (icon) {
      modalItem.image.type = icon.type;
      modalItem.image.value = icon.name;
      $scope.image.icon = icon;
    }

    function updateSrc () {
      modalItem.image.type = $scope.IMAGE_TYPES.IMGSRC;
      modalItem.image.value = $scope.image.src;
      // $scope.src updated via ngModal.
    }
  }
})();
