(function () {
  'use strict';

  angular.module('vhr.services')
    .service('vhrCxmlMedDispenseRecordClass', vhrCxmlMedDispenseRecordClass);

  vhrCxmlMedDispenseRecordClass.$inject = [
    'VhrCxmlRecordClass'
  ];

  function vhrCxmlMedDispenseRecordClass (
    VhrCxmlRecordClass
  ) {
    function CxmlMedDispenseRecord (cfg) {
      VhrCxmlRecordClass.call(this, cfg);
      this.printTemplateUrl = '/ui/vhr/templates/medications/print/med-dispense.html';
      this.templateUrl = '/ui/vhr/templates/medications/cxml-med-dispense-modal.html';
    }

    CxmlMedDispenseRecord.prototype = Object.create(VhrCxmlRecordClass.prototype);
    CxmlMedDispenseRecord.prototype.constructor = CxmlMedDispenseRecord;

    CxmlMedDispenseRecord.prototype.set = function (raw, setFields) {
      if (setFields === true) {
        setFields = [];
      }
      VhrCxmlRecordClass.prototype.set.call(this, raw, setFields);
    };

    return CxmlMedDispenseRecord;
  }
})();
