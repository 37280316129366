(function () {
  'use strict';

  angular
    .module('admin')
    .controller('UsersAddCtrl', UsersAddCtrl);

  UsersAddCtrl.$inject = ['$filter', '$scope', '$state', 'ArrayFnSrv', 'GroupsSrv', 'ProvidersSrv', 'psNotification', 'UsersSrv'];

  function UsersAddCtrl ($filter, $scope, $state, ArrayFnSrv, GroupsSrv, ProvidersSrv, psNotification, UsersSrv) {
    var vm = this;
    var GROUPS_GET_ERROR = 'Unable to retrieve the groups list.';
    var USER_ADD_ERROR = 'Unable to add ';
    var USER_GROUPS_ADD_ERROR = 'Unable to add groups to ';
    var USER_ADD_SUCCESS = ' added.';
    var allGroups = [];

    // Properties
    vm.availableGroups = [];
    vm.availableGroupsSearch = '';
    vm.includedGroupsSearch = '';
    vm.includedGroups = [];
    vm.loaded = { list: false };
    vm.providers = [];
    vm.user = {
      provider: '',
      username: null,
      name: null,
      password: ''
    };
    vm.confirmPassword = '';

    // Methods
    vm.addAll = addAll;
    vm.addGroup = addGroup;
    vm.addUser = addUser;
    vm.checkPassword = checkPassword;
    vm.clearAvailableGroupsFilter = clearAvailableGroupsFilter;
    vm.clearExistingGroupsFilter = clearExistingGroupsFilter;
    vm.filterAvailableGroups = filterAvailableGroups;
    vm.filterGroupsByProvider = filterGroupsByProvider;
    vm.filterIncludedGroups = filterIncludedGroups;
    vm.getPasswordPattern = getPasswordPattern;
    vm.removeGroup = removeGroup;
    vm.removeAll = removeAll;
    vm.filterAvailableList = filterAvailableList;
    vm.filterIncludedList = filterIncludedList;

    activate();

    function activate () {
      ProvidersSrv.getProviderList().then(function (providers) {
        vm.providers = providers;
        vm.user.provider = providers[0];
        filterGroupsByProvider(true)
          .finally(function () {
            vm.loaded.list = true;
          });
      });
    }

    //= ================================
    // Public interface
    //= ================================

    function addAll () {
      var visibleGroups = $filter('filter')(vm.availableGroups, { $: vm.availableGroupsSearch });
      vm.availableGroups = ArrayFnSrv.diff(vm.availableGroups, visibleGroups).sort(ArrayFnSrv.sortNames);
      vm.includedGroups = vm.includedGroups.concat(visibleGroups).sort(ArrayFnSrv.sortNames);
      clearAvailableGroupsFilter();
    }

    function addGroup (group) {
      vm.availableGroups.splice(vm.availableGroups.indexOf(group), 1);
      vm.includedGroups.push(group);
    }

    function addUser () {
      UsersSrv.createUser(vm.user)
        .then(function (user) {
          return UsersSrv.addGroups(user, vm.includedGroups)
            .catch(function () {
              psNotification.error(USER_GROUPS_ADD_ERROR + vm.user.username + '.');
            });
        })
        .then(function () {
          psNotification.success(vm.user.username + USER_ADD_SUCCESS);
          $state.go('app.admin.users');
        })
        .catch(function (response) {
          if (response.error.message) {
            psNotification.error(response.error.message);
          } else {
            psNotification.error(USER_ADD_ERROR + vm.user.username + '.');
          }
        });
    }

    function checkPassword () {
      if (vm.user.password && vm.confirmPassword) {
        if (vm.user.password !== vm.confirmPassword) {
          $scope.userAddForm.password_confirmation.$setValidity('passwordMismatch', false);
        } else {
          $scope.userAddForm.password_confirmation.$setValidity('passwordMismatch', true);
        }
      }
    }

    function clearAvailableGroupsFilter () {
      vm.availableGroupsSearch = '';
    }

    function clearExistingGroupsFilter () {
      vm.includedGroupsSearch = '';
    }

    function filterAvailableList (groups) {
      return filterList(groups.toLowerCase(), vm.availableGroupsSearch.toLowerCase());
    }

    function filterIncludedList (groups) {
      return filterList(groups.toLowerCase(), vm.includedGroupsSearch.toLowerCase());
    }

    function filterAvailableGroups () {
      return $filter('filter')(vm.availableGroups, function (group) {
        return group.indexOf(vm.availableGroupsSearch);
      });
    }

    function filterGroupsByProvider (force) {
      return GroupsSrv.getList(force).then(function (groups) {
        allGroups = [];
        groups.forEach(function (group) {
          if (group.provider === vm.user.provider) {
            allGroups.push(group.name);
          }
        });
        vm.includedGroups = [];
        vm.availableGroups = angular.copy(allGroups);
        filterIncludedGroups();
        filterAvailableGroups();
      })
        .catch(function () {
          psNotification.error(GROUPS_GET_ERROR);
        });
    }

    function filterIncludedGroups () {
      return $filter('filter')(vm.includedGroups, function (group) {
        return group.indexOf(vm.includedGroupsSearch);
      });
    }

    function getPasswordPattern (password) {
      // Escape for regular expression syntax.
      return password ? password.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&') : ''; //eslint-disable-line
    }

    function removeAll () {
      var visibleGroups = $filter('filter')(vm.includedGroups, { $: vm.includedGroupsSearch });

      vm.includedGroups = ArrayFnSrv.diff(vm.includedGroups, visibleGroups).sort(ArrayFnSrv.sortNames);
      vm.availableGroups = vm.availableGroups.concat(visibleGroups).sort(ArrayFnSrv.sortNames);
      clearExistingGroupsFilter();
    }

    function removeGroup (group) {
      vm.availableGroups.push(group);
      vm.includedGroups.splice(vm.includedGroups.indexOf(group), 1);
    }

    //= ================================
    // Private interface
    //= ================================

    function filterList (list, input) {
      if (!input) {
        return true;
      }

      return (list.indexOf(input) !== -1) ? true : (list.indexOf(input) !== -1);
    }
  }
})();
