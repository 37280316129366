(function () {
  'use strict';

  angular.module('vhr.services')
    .service('vhrCxmlAdtRecordClass', vhrCxmlAdtRecordClass);

  vhrCxmlAdtRecordClass.$inject = [
    'VhrCxmlRecordClass'
  ];

  function vhrCxmlAdtRecordClass (
    VhrCxmlRecordClass
  ) {
    function CxmlAdtRecord (cfg) {
      VhrCxmlRecordClass.call(this, cfg);
      this.controller = 'CxmlAdtModalCtrl';
      this.printTemplateUrl = '/ui/vhr/templates/cxml/print/adt.html';
      this.templateUrl = '/ui/vhr/templates/cxml/cxml-adt-modal.html';
    }

    CxmlAdtRecord.prototype = Object.create(VhrCxmlRecordClass.prototype);
    CxmlAdtRecord.prototype.constructor = CxmlAdtRecord;

    CxmlAdtRecord.prototype.set = function (raw, setFields) {
      if (setFields === true) {
        setFields = ['admit_date', 'discharge_date', 'attending_physician', 'pcp'];
      }
      VhrCxmlRecordClass.prototype.set.call(this, raw, setFields);
    };

    return CxmlAdtRecord;
  }
})();
