(function () {
  'use strict';

  angular.module('imat.services')
    .factory('DownloaderSrv', DownloaderSrv);

  DownloaderSrv.$inject = ['$filter', '$window'];

  function DownloaderSrv ($filter, $window) {
    var service = {
      MIME: {
        CSV: 'text/csv;charset=utf-8;',
        TEXT: 'text/plain',
        XML: 'text/xml;charset=utf-8;'
      },
      PROCESS: {
        nullToEmptyString: _processNullToEmptyStr,
        revertXmlEntities: _processXmlEntities
      },
      download: download,
      downloadCsv: downloadCsv,
      downloadXml: downloadXml
    };

    return service;

    // {
    //   filename: string,
    //   processors: [funcA, funcB],
    //   type: service.MIME.<type>
    // }
    function download (doc, options) {
      options = options || { type: service.MIME.TEXT };

      if (Array.isArray(options.processors)) {
        options.processors.forEach(function (processor) {
          if (typeof processor === 'function') {
            doc = processor(doc);
          }
        });
      }

      var blob = new Blob([doc], { type: options.type, endings: 'native' });
      return _download(blob, options.filename || 'export');
    }

    // {
    //   columns: [fields],
    //   filename: string,
    //   headers: {fieldA: labelA, fieldB: labelB},
    //   processors: {fieldA: funcA, fieldB: funcB},
    // }
    function downloadCsv (collection, options) {
      // We do not (necessarily) want to modify the collection, so we need to
      // make a copy that we can transform into a CSV (correct columns, values).
      var blob, csv, work;

      if (!angular.isArray(options.columns) || !options.columns.length) {
        throw new Error('downloadCsv requires options.columns');
      }

      work = collection.map(function (row) { return _copyCsvRow(row, options.columns, options.processors); });

      if (options.headers) {
        work.unshift(_copyCsvRow(options.headers, options.columns));
      }

      csv = work.map(function (row) { return row.join(','); }).join('\r\n');
      blob = new Blob([csv], { type: service.MIME.CSV, endings: 'native' });
      return _download(blob, options.filename || 'export.csv');
    }

    // {
    //   filename: string,
    //   processors: [funcA, funcB],
    // }
    function downloadXml (doc, options) {
      options = options || { type: service.MIME.XML };

      if (options.type !== service.MIME.XML) {
        options.type = service.MIME.XML;
      }

      if (!options.filename) {
        options.filename = 'export.xml';
      }

      return download(doc, options);
    }

    //= ================================
    // Private interface
    //= ================================

    function _copyCsvRow (row, columns, processors) {
      var copy = [];

      columns = columns || [];
      processors = processors || {};

      columns.forEach(function (column) {
        var value = (angular.isFunction(processors[column]) ? processors[column](row[column]) : row[column]);
        // Double double-quotes because standard.
        copy.push('"' + value.replace(/"/g, '""') + '"');
      });

      return copy;
    }

    function _download (blob, filename) {
      var href;
      var link = $window.document.createElement('a');

      if ($window.navigator.msSaveBlob) {
        // IE <= 11, Edge <= 18
        return $window.navigator.msSaveBlob(blob, filename);
      }

      if (link.download !== undefined) {
        // Build a link, inject it into the DOM, click it.
        href = $window.URL.createObjectURL(blob);
        link.setAttribute('href', href);
        link.setAttribute('download', encodeURI(filename));
        link.style.visibility = 'hidden';

        $window.document.body.appendChild(link);
        link.click();
        $window.document.body.removeChild(link);
        $window.URL.revokeObjectURL(href);
        return true;
      }

      return false;// Does not work.
    }

    //= ================================
    // Processors
    //= ================================

    function _processNullToEmptyStr (value) {
      return value == null ? '' : value;
    }

    function _processXmlEntities (doc) {
      // Revert XML "predefined entities"
      return doc == null ? ''
        : doc.replace(/&amp;/gi, '&')
          .replace(/&apos;/gi, "'")
          .replace(/&quot;/gi, '"')
          .replace(/&lt;/gi, '<')
          .replace(/&gt;/gi, '>');
    }
  }
})();
