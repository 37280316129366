(function () {
  'use strict';

  angular.module('account')
    .controller('AccountPasswordCtrl', AccountPasswordCtrl);

  AccountPasswordCtrl.$inject = ['$scope', '$transition$', 'errorService', 'imatConfig', 'psNotification', 'subscriptionsSrv', 'userSession'];

  function AccountPasswordCtrl ($scope, $transition$, errorService, imatConfig, psNotification, subscriptionsSrv, userSession) {
    var vm = this;
    var provider = 'default';

    vm.password = {
      current: null,
      new: null,
      confirm: null
    };

    vm.reset = reset;
    vm.submit = submit;

    activate();

    function activate () {
      var user = userSession.getUserData();
      provider = user.provider;
      $scope.$watch(function () { return vm.password; }, function (password) {
        if (password.current && password.new) {
          if (password.current === password.new) {
            $scope.passwordForm.new_password.$setValidity('notChanged', false);
          } else {
            $scope.passwordForm.new_password.$setValidity('notChanged', true);
          }
        }
        if (password.new && password.confirm) {
          if (password.new !== password.confirm) {
            $scope.passwordForm.confirm_password.$setValidity('notMatching', false);
          } else {
            $scope.passwordForm.confirm_password.$setValidity('notMatching', true);
          }
        }
      }, true);
    }

    function reset () {
      vm.password = {};
      $scope.passwordForm.$setUntouched();
      $scope.passwordForm.$setPristine();
    }

    function submit () {
      var missingRe = /^missing$/i;

      vm.changes = false;
      userSession.changePassword(vm.password.current, vm.password.new, provider)
        .then(function (ret) {
          if (missingRe.test(errorService.message(ret) || '')) {
            psNotification.success('Password updated.');
          }
        })
        .catch(function (err) {
          if (missingRe.test(errorService.message(err) || '')) {
            psNotification.error('Failed to update password.');
          } else if (err.data && err.data.error && err.data.error.message) {
            psNotification.error(err.data.error.message);
          }
        })
        .finally(function () {
          reset();
        });
    }
  }
})();
