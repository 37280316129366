(function () {
  'use strict';

  angular.module('imat')
    .provider('imatTheme', imatTheme);

  imatTheme.$inject = ['$mdThemingProvider'];

  function imatTheme ($mdThemingProvider) {
    var imatBlueMap = $mdThemingProvider.extendPalette('blue', {
      contrastDefaultColor: 'light',
      contrastLightColors: ['400', '500', '600', '700', '800', '900', 'A700'],
      contrastDarkColors: undefined
    });

    $mdThemingProvider.definePalette('imatBlue', imatBlueMap);

    // System Default Fallback
    var palettes = {};
    var theme = {
      primary: {
        name: 'imatBlue',
        definition: {
          default: '400',
          'hue-3': 'A700'
        }
      },
      accent: {
        name: 'grey',
        definition: {
          default: '200',
          'hue-1': '100',
          'hue-2': '500',
          'hue-3': '700'
        }
      },
      warn: {
        name: 'red',
        definition: {}
      },
      background: {
        name: 'grey',
        definition: {}
      }
    };

    this.getTheme = function () { // eslint-disable-line no-invalid-this
      return (Object.prototype.hasOwnProperty.call(window, 'appThemeConfig') && Object.prototype.hasOwnProperty.call(window.appThemeConfig, 'theme')) ? window.appThemeConfig.theme : theme;
    };

    this.getPalettes = function () { // eslint-disable-line no-invalid-this
      return (Object.prototype.hasOwnProperty.call(window, 'appThemeConfig') && Object.prototype.hasOwnProperty.call(window.appThemeConfig, 'palettes')) ? window.appThemeConfig.palettes : palettes;
    };

    this.$get = function () {}; // eslint-disable-line no-invalid-this
  }
})();
