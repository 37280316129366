(function () {
  'use strict';

  angular.module('admin')
    .controller('DashboardGroupsCtrl', DashboardGroupsCtrl);

  DashboardGroupsCtrl.$inject = [
    '$filter', '$mdDialog', '$q', '$scope', '$timeout',
    'DashboardsSrv', 'GroupsSrv', 'ProvidersSrv', 'psNotification'
  ];

  function DashboardGroupsCtrl (
    $filter, $mdDialog, $q, $scope, $timeout,
    DashboardsSrv, GroupsSrv, ProvidersSrv, psNotification
  ) {
    var vm = this;
    var ctx = this.constructor.name;
    var DASHBOARD_GET_ERROR = 'Unable to retrieve the dashboards.';
    var PRIORITY_PUT_ERROR = 'Unable to update the dashboard priority.';
    var PRIORITY_PUT_SUCCESS = 'Updated dashboard priority.';

    $scope.ACCESS = DashboardsSrv.ACCESS;
    $scope.LEVELS = DashboardsSrv.LEVELS;
    $scope.sortableOptions = {
      containment: 'parent',
      disabled: false,
      handle: '.item-handle',
      tolerance: 'pointer',
      'ui-floating': false,
      update: sortUpdatePriorities
    };

    // Properties
    vm.groupDashboards = [];
    vm.groupListFilters = [{
      name: 'Can Edit',
      key: 'owner_permissions'
    }, {
      name: 'Cannot Edit',
      key: 'disabled'
    }];
    vm.loaded = { detail: true, list: true };
    vm.priorities = [];
    vm.providerCount = 1;
    vm.searchGroupText; // eslint-disable-line no-unused-expressions
    vm.selected = {};
    vm.selectedDetail = { empty: true };
    vm.selectedFilter = '';

    // Methods
    vm.addDashboard = addDashboard;
    vm.checkSortable = checkSortable;
    vm.clearSelection = clearSelection;
    vm.cloneDashboard = cloneDashboard;
    vm.filterGroupList = filterGroupList;
    vm.groupTextFilterList = groupTextFilterList;
    vm.moveDown = moveDown;
    vm.moveUp = moveUp;
    vm.refreshGroupDashboardList = refreshGroupDashboardList;
    vm.resetGroupFilters = resetGroupFilters;
    vm.selectDashboard = selectDashboard;
    vm.updatePriorities = updatePriorities;

    activate();

    function activate () {
      vm.loaded.list = false;
      ProvidersSrv.getProviderList()
        .then(function (providers) {
          vm.providerCount = providers.length;
          vm.providers = providers;

          if (vm.providerCount > 1) {
            providers.forEach(function (provider) {
              vm.groupListFilters.push({
                name: 'Provider: ' + provider,
                key: 'provider',
                value: provider
              });
            });
          }
        });

      resetGroupFilters();
      filterGroupList(true)
        .catch(function () {
          psNotification.error(DASHBOARD_GET_ERROR);
        });
    }

    //= ==============================
    // Public interface
    //= ==============================

    function addDashboard () {
      var dialog = {
        controller: 'addDashboardModalCtrl as vm',
        templateUrl: '/ui/admin/dashboards/addDashboardModal.html',
        locals: {
          modalDashboards: vm.groupDashboards,
          modalLevel: DashboardsSrv.LEVELS.GROUP,
          modalOwner: vm.selected.group
        },
        parent: angular.element(document.body),
        targetEvent: event,
        clickOutsideToClose: true
      };

      $mdDialog.show(dialog)
        .then(function (modalDashboard) {
          createDashboardForGroup(modalDashboard);
        })
        .catch(function () {
        // Dismissed.
        });
    }

    function checkSortable () {
      if ($scope.sortableOptions.disabled) {
        psNotification.warn('You cannot reorder dashboards when the list is filtered.');
      }
    }

    function clearSelection () {
      DashboardsSrv.clearSelected(ctx);
      clearSelectionDetail();
      vm.selected = {};
    }

    function clearSelectionDetail () {
      vm.selectedDetail = { empty: true };
    }

    function cloneDashboard () {
      var dialog = {
        controller: 'cloneDashboardModalCtrl as vm',
        templateUrl: '/ui/admin/dashboards/cloneDashboardModal.html',
        locals: { modalOwner: vm.selected.group, modalLevel: DashboardsSrv.LEVELS.GROUP, modalDashboards: vm.groupDashboards },
        parent: angular.element(document.body),
        targetEvent: event,
        clickOutsideToClose: true
      };

      $mdDialog.show(dialog)
        .then(function (modalDashboard) {
          modalDashboard.tiles = vm.selectedDetail.tiles;
          createDashboardForGroup(modalDashboard);
        })
        .catch(function () {
        // Dismissed.
        });
    }

    function filterGroupList (force) {
      return GroupsSrv.getList(force)
        .then(function (groups) { // eslint-disable-line no-unused-vars
          if (vm.selectedFilter && vm.selectedFilter.key !== 'provider') {
            groups = $filter('filter')(groups, function (value) {
              return value[vm.selectedFilter.key] === 'true';
            });
          } else {
            groups = $filter('filter')(groups, function (value) {
              return (vm.selectedFilter) ? value[vm.selectedFilter.key] === vm.selectedFilter.value : true;
            });
          }
          return refreshGroupDashboardList(false);
        })
        .catch(function () {
          psNotification.error(DASHBOARD_GET_ERROR);
        });
    }

    function groupTextFilterList () {
      if (vm.searchGroupText) {
        vm.groupDashboards = $filter('filter')(vm.groupDashboards, function (dashboard) {
          return (dashboard.group.toLowerCase().indexOf(vm.searchGroupText.toLowerCase()) !== -1) ? true
            : (dashboard.display_name.toLowerCase().indexOf(vm.searchGroupText.toLowerCase()) !== -1);
        });
        $scope.sortableOptions.disabled = true;
      } else {
        $scope.sortableOptions.disabled = false;
      }
    }

    function moveDown (dashboard, $event) {
      dashboard.priority = parseInt(dashboard.priority || 0, 10) + 1;

      vm.priorities = vm.groupDashboards.map(function (dashboard) { // eslint-disable-line no-shadow
        return dashboard.id;
      });

      updatePriorities(dashboard, $event);
    }

    function moveUp (dashboard, $event) {
      dashboard.priority = parseInt(dashboard.priority || 0, 10) - 1;

      vm.priorities = vm.groupDashboards.map(function (dashboard) { // eslint-disable-line no-shadow
        return dashboard.id;
      });

      updatePriorities(dashboard, $event);
    }

    function refreshGroupDashboardList (force) {
      var q = [];

      q.push(
        DashboardsSrv.getPriorities(force)
          .then(function (response) {
            return response;
          })
          .catch(function () {
            return [];
          })
      );

      q.push(
        DashboardsSrv.getCompleteList(force)
          .then(function (results) {
            return results;
          })
      );

      q.push(
        GroupsSrv.getList(force)
          .then(function (results) {
            return results;
          })
          .catch(function () {
            return [];
          })
      );

      vm.loaded.list = false;
      return $q.all(q)
        .then(function (all) {
          var dashboards;
          var groupDashboardPriorities = all[0];
          var providerDashboards = all[1];
          var providerGroups = all[2];
          var reGroupLevel = new RegExp('^' + DashboardsSrv.LEVELS.GROUP + '$', 'i');

          // Find group level dashboards in groups that we've filtered for.
          dashboards = providerDashboards.filter(function (dashboard) {
            if (!providerGroups) {
              return reGroupLevel.test(dashboard.level);
            }
            if (reGroupLevel.test(dashboard.level)) {
              for (var i = providerGroups.length - 1; i >= 0; --i) {
                if (providerGroups[i].name === dashboard.group) {
                  return true;
                }
              }
            }
            return false;
          });

          vm.groupDashboards.splice(0);
          vm.priorities.splice(0);

          // Arrange the dashboards in priority order.
          groupDashboardPriorities.forEach(function (dashboardId) {
            for (var i = dashboards.length - 1; i >= 0; --i) {
              if (dashboards[i].id === dashboardId) {
                vm.groupDashboards.push(dashboards.splice(i, 1).pop());
                break;
              }
            }
          });
          // Append any unprioritized dashboards.
          vm.groupDashboards = vm.groupDashboards.concat(dashboards);
          // Rebuild the priority list in its entirety.
          vm.priorities = vm.groupDashboards.map(function (dashboard, idx) { vm.groupDashboards[idx].priority = idx + 1; return dashboard.id; });
        })
        .finally(function () {
          vm.loaded.list = true;
          if (DashboardsSrv.getSelectedId(ctx)) {
            selectDashboard(DashboardsSrv.getSelected(ctx));
          }
        });
    }

    function resetGroupFilters () {
      $scope.sortableOptions.disabled = false;
      vm.searchGroupText = '';
      vm.selectedFilter = '';
      filterGroupList(false);
    }

    function selectDashboard (dashboard, $event) {
      if ($event && $event.target.tagName === 'MD-ICON') {
        $event.preventDefault();
        return false;
      }

      vm.selected = dashboard;
      DashboardsSrv.setSelected(ctx, dashboard.id);

      vm.loaded.detail = false;
      if (!DashboardsSrv.isCachedItem(dashboard.id)) {
        clearSelectionDetail();
      }
      DashboardsSrv.getSelectedItem(ctx)
        .then(function (mwDashboard) {
          vm.selectedDetail.empty = false;
          vm.selectedDetail = mwDashboard;
          vm.selectedDetail.priority = parseInt(mwDashboard.priority || 0, 10);
        })
        .finally(function () {
          $timeout(function () {
            vm.loaded.detail = true;
          });
        });
    }

    function sortUpdatePriorities (e, ui) {
      ui.item.sortable.model.priority = ui.item.sortable.dropindex + 1;
      updatePriorities(ui.item.sortable.model)
        .catch(function () {
          ui.item.sortable.cancel();
        });
    }

    function updatePriorities (dashboard, $event) {
      var backupPriorities = angular.copy(vm.priorities);
      var currentPriority = vm.priorities.indexOf(dashboard.id);
      var desiredPriority = parseInt(dashboard.priority || 0, 10) - 1;// Convert to zero-based.

      if ($event) {
        $event.preventDefault();
      }

      if (currentPriority >= 0) {
        vm.priorities.splice(currentPriority, 1);// Remove.
      }
      vm.priorities.splice(desiredPriority, 0, dashboard.id);// Replace.

      return DashboardsSrv.setPriorities(vm.priorities)
        .then(function () {
          refreshGroupDashboardList(true)
            .then(function () {
            // Sidebar expects this to be a number.
              if (DashboardsSrv.getSelectedId(ctx)) {
                DashboardsSrv.getSelectedItem(ctx)
                  .then(function (selectedItem) {
                    selectedItem.priority = parseInt(DashboardsSrv.getSelected(ctx).priority, 10);
                  });
              }
            });
          psNotification.success(PRIORITY_PUT_SUCCESS);
        })
        .catch(function () {
          psNotification.error(PRIORITY_PUT_ERROR);
          dashboard.priority = currentPriority + 1;// Convert to one-based.
          vm.priorities = backupPriorities;
        });
    }

    //= ==============================
    // Private interface
    //= ==============================

    function createDashboardForGroup (dashboard) {
      DashboardsSrv.getDashboard(dashboard)
        .then(function () {
          psNotification.error('A dashboard already exists for this group.');
        }, function () {
          DashboardsSrv.save(dashboard)
            .then(function (mwDashboard) {
              DashboardsSrv.setSelected(ctx, mwDashboard.id);
              // The dashboard has been reloaded with default permsissions...
              dashboard = mwDashboard;
              dashboard.owner_permissions = DashboardsSrv.ACCESS.READ;
              return DashboardsSrv.savePermissions(dashboard);
            }, function (err) {
              psNotification.error('Unable to create the dashboard.');
              return $q.reject(err);
            })
            .then(function () {
              return DashboardsSrv.getPriorities()
                .then(function (response) {
                  var priorities = response;
                  priorities.push(dashboard.id);
                  DashboardsSrv.setPriorities(priorities);// Best effort. Pff.
                });
            })
            .then(function () {
              refreshGroupDashboardList(true);
            });
        });
    }
  }
})();
