(function () {
  'use strict';

  angular.module('admin')
    .controller('ManageUserGroupsModalCtrl', ManageUserGroupsModalCtrl);

  ManageUserGroupsModalCtrl.$inject = ['$filter', '$mdDialog', '$scope', 'ArrayFnSrv', 'GroupsSrv', 'modalGroups', 'provider'];
  function ManageUserGroupsModalCtrl ($filter, $mdDialog, $scope, ArrayFnSrv, GroupsSrv, modalGroups, provider) {
    var allGroups = [];
    var origGroups = angular.copy(modalGroups || []).sort(ArrayFnSrv.sortNames);

    $scope.excludedGroups = [];
    $scope.filterAvailableGroups = '';
    $scope.filterExistingGroups = '';
    $scope.includedGroups = angular.copy(origGroups);
    $scope.loading = true;

    // Methods
    $scope.addAllGroups = addAllGroups;
    $scope.addGroup = addGroup;
    $scope.cancel = cancel;
    $scope.clearAvailableGroupsFilter = clearAvailableGroupsFilter;
    $scope.clearExistingGroupsFilter = clearExistingGroupsFilter;
    $scope.removeAllGroups = removeAllGroups;
    $scope.removeGroup = removeGroup;
    $scope.resetGroups = resetGroups;
    $scope.updateUserGroups = updateUserGroups;

    GroupsSrv.getList(true)
      .then(function (groupList) {
        allGroups = [];
        groupList.forEach(function (group) {
          if (group.provider === provider) {
            allGroups.push(group.name);
          }
        });
        $scope.excludedGroups = ArrayFnSrv.diff(allGroups, origGroups).sort(ArrayFnSrv.sortNames);
      })
      .finally(function () {
        $scope.loading = false;
      });

    function addAllGroups () {
      var visibleGroups = $filter('filter')($scope.excludedGroups, { $: $scope.filterAvailableGroups });

      $scope.excludedGroups = ArrayFnSrv.diff($scope.excludedGroups, visibleGroups).sort(ArrayFnSrv.sortNames);
      $scope.includedGroups = $scope.includedGroups.concat(visibleGroups).sort(ArrayFnSrv.sortNames);
      clearAvailableGroupsFilter();
    }

    function addGroup (group) {
      $scope.excludedGroups.splice($scope.excludedGroups.indexOf(group), 1);
      $scope.includedGroups.push(group);
      $scope.includedGroups.sort(ArrayFnSrv.sortNames);
    }

    function cancel () {
      $mdDialog.cancel();
    }

    function clearAvailableGroupsFilter () {
      $scope.filterAvailableGroups = '';
    }

    function clearExistingGroupsFilter () {
      $scope.filterExistingGroups = '';
    }

    function removeAllGroups () {
      var visibleGroups = $filter('filter')($scope.includedGroups, { $: $scope.filterExistingGroups });

      $scope.includedGroups = ArrayFnSrv.diff($scope.includedGroups, visibleGroups).sort(ArrayFnSrv.sortNames);
      $scope.excludedGroups = $scope.excludedGroups.concat(visibleGroups).sort(ArrayFnSrv.sortNames);
      clearExistingGroupsFilter();
    }

    function removeGroup (group) {
      $scope.excludedGroups.push(group);
      $scope.excludedGroups.sort(ArrayFnSrv.sortNames);
      $scope.includedGroups.splice($scope.includedGroups.indexOf(group), 1);
    }

    function resetGroups () {
      $scope.includedGroups = angular.copy(origGroups).sort(ArrayFnSrv.sortNames);
      $scope.excludedGroups = ArrayFnSrv.diff(allGroups, origGroups).sort(ArrayFnSrv.sortNames);
    }

    function updateUserGroups () {
      var added = ArrayFnSrv.diff($scope.includedGroups, origGroups);
      var removed = ArrayFnSrv.diff(origGroups, $scope.includedGroups);

      if (added.length || removed.length) {
        $mdDialog.hide({ add: added, delete: removed });
      } else {
        $mdDialog.cancel();
      }
    }

    //= ================================
    // Private interface
    //= ================================
  }
})();
