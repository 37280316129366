(function () {
  'use strict';

  angular.module('mpiApp.services')
    .factory('simpleQueryBuilder', simpleQueryBuilder);

  simpleQueryBuilder.$inject = [];
  function simpleQueryBuilder () {
    var service = {
      getRolemapping: getRolemapping,
      getSimpleQuery: getSimpleQuery
    };
    function getRolemapping (config) {
      for (var key in config.form_fields.rolemappings) {
        // Find the rolemapp-ing that fits the current page
        if (config.form_fields.rolemappings[key].filelocation) {
          // TODO do logic to find other file location then sent parameters from there.
          // get role > If the role uses a different file go get it and use it
          // > Find Layout for that role> find form_fields for that role
        } else {
          return key;
        }
      }
    }

    function getSimpleQuery (data) { // eslint-disable-line complexity
      var query = '';
      var linkedFields = [];
      var linkedFieldsObject = {};
      var newParam = '';

      // Compose the form values object into an ANDed query
      for (var key in data) {
        if (data[key].value !== null && data[key].value !== '' && !angular.isUndefined(data[key].value)) {
          newParam = '';
          var field = data[key];

          // if there is a sibling field need to check to see if there is also a value in it
          if (Object.prototype.hasOwnProperty.call(field, 'linked_field') && Object.prototype.hasOwnProperty.call(field.linked_field, 'name') && Object.prototype.hasOwnProperty.call(field, field.linked_field.name) && field.name) {
            // put the linked fields in an array with the index being the order
            // if the linkedFieldsObject already has the linked_field then use that array
            // else create a new array
            if (Object.prototype.hasOwnProperty.call(linkedFieldsObject, field.linked_field.linked_field_name)) {
              linkedFields = linkedFieldsObject[field.linked_field.linked_field_name];
            } else {
              linkedFields = [];
            }
            var linkedFieldOrder = field.linked_field.linked_field_order;
            var linkedObject = { name: field.linked_field.name, value: field.value };
            linkedFields[linkedFieldOrder - 1] = linkedObject;
            linkedFieldsObject[field.linked_field.linked_field_name] = linkedFields;
          }

          if (linkedFields.length === 0) {
            var transformedValue;

            switch (field.type) {
              case 'select':
              case 'text':
                if (Object.prototype.hasOwnProperty.call(field, 'use_wildcards') && field.use_wildcards === true) {
                  newParam = field.name + ':(' + field.value + ' OR ' + field.value + '*)';
                } else {
                  newParam = field.name + ':"' + field.value + '"';
                }
                break;

              case 'number':
                newParam = field.name + ':' + field.value;
                break;

              case 'date':
                newParam = field.name + ':[' + field.value + ']';
                break;

              case 'phone':
                // Strip out punctuation and replace with spaces
                transformedValue = field.value.replace(/[()\[\].,?-]/g, ' ');  // eslint-disable-line

                // Strip out spaces to try as a solid number (e.g. 1234567890)
                var transformedValue2 = transformedValue.replace(/\s/g, '');

                newParam = field.name + ':"' + transformedValue + '" OR ' + transformedValue2;
                break;

              case 'ssn':
                transformedValue = field.value.replace(/-/g, '');
                newParam = field.name + ':"' + transformedValue + '"';
                break;

              case 'ssn_rendered_value':
                transformedValue = field.value;

                if (field.value.length === 9 && /^\d+$/.test(field.value)) {
                  transformedValue = (field.value.slice(0, 3) + '-' + field.value.slice(3, 5) + '-' + field.value.slice(5));
                }
                newParam = field.name + ':"' + transformedValue + '"';
                break;

              default: // Just treat like text?
                newParam = field.name + ':"' + field.value + '"';
            }
          }

          if (query === '') {
            query = newParam;
          } else {
            query += ' ' + newParam;
          }
        }
      }

      // go through each of the linked fields objects and generate the query string
      for (var obj in linkedFieldsObject) {
        if (Object.prototype.hasOwnProperty.call(linkedFieldsObject, obj)) {
          newParam = '';
          for (var i = 0; i < linkedFieldsObject[obj].length; i++) {
            if (i !== 0) {
              newParam += ' ';
            }
            newParam += linkedFieldsObject[obj][i].value;
            if (i === linkedFieldsObject[obj].length - 1) {
              query += obj + ':(' + newParam + ')';
            }
          }
        }
      }
      return query;
    }

    return service;
  }
})();
