(function () {
  'use strict';

  angular
    .module('vhr.services')
    .factory('vhrDicomSrv', vhrDicomSrv);

  vhrDicomSrv.$inject = ['$http'];

  function vhrDicomSrv ($http) {
    var service;

    service = {
      getStudyViewerURL: getStudyViewerURL,
      getWorklistViewerURL: getWorklistViewerURL
    };

    return service;

    //= ================================
    // API
    //= ================================
    function getStudyViewerURL (mpid, store, uri) {
      var url = "/api/patients/" + mpid + "/dicom/study";
      var params = {
        "uri": uri,
        "store": store,
      };
      return $http.get(url, { params })
        .then(function (response) {
          return response.data;
        });
    }

    function getWorklistViewerURL (mpid) {
      var url = "/api/patients/" + mpid + "/dicom/worklist";
      return $http.get(url, { params: {} })
        .then(function (response) {
          return response.data;
        });
    }
  }
})();
