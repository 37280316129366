(function () {
  'use strict';

  angular.module('imat.viewer')
    .config(config);

  config.$inject = ['$stateRegistryProvider'];

  function config ($stateRegistryProvider) {
    $stateRegistryProvider.register({
      name: 'app.viewer',
      data: {
        appKey: 'viewer',
        beta: true,
        pageTitle: 'Report Viewer',
        roles: ['search_by_id', 'search', 'system']
      },
      params: {
        reportId: null
      },
      views: {
        content: {
          templateUrl: '/ui/viewer/viewer.html',
          controller: 'ViewerCtrl',
          controllerAs: 'ctrl'
        }
      },
      url: '/run/:reportId'
    });
  }
})();
