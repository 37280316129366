(function () {
  'use strict';

  angular.module('admin')
    .controller('ReportSourceModalCtrl', ReportSourceModalCtrl);

  ReportSourceModalCtrl.$inject = ['$mdDialog', '$scope', 'ReportsSrv', 'selectedReport'];

  function ReportSourceModalCtrl ($mdDialog, $scope, ReportsSrv, selectedReport) {
    var vm = this;

    // Properties.
    vm.loading = true;
    vm.editorConfig = {
      mode: 'xml',
      lineWrapping: false,
      lineNumbers: true,
      indentUnit: 4,
      theme: 'eclipse',
      readOnly: true,
      extraKeys: {
        'Ctrl-F': 'findPersistent',
        'Cmd-F': 'findPersistent'
      },
      highlightSelectionMatches: {
        showToken: true,
        annotateScrollbar: true
      }
    };
    vm.selectedReport = selectedReport;
    vm.editorContent = { value: null };

    // Methods.
    vm._codemirrorLoaded = _codemirrorLoaded;
    vm.closeModal = closeModal;

    function closeModal () {
      $mdDialog.cancel();
    }

    // Makes codemirror object events available to tie further logic to.
    function _codemirrorLoaded (editor) {
      vm.codeEditor = editor;
      // Editor part.
      var _doc = vm.codeEditor.getDoc();
      // Options.
      _doc.markClean();
      // Events.
      vm.codeEditor.on('focus', function () {
        vm.editorFocused = true;
        $scope.$apply();
      });
      vm.codeEditor.on('blur', function () {
        vm.editorFocused = false;
        $scope.$apply();
      });
      vm.codeEditor.on('refresh', function () {
        var doc = vm.codeEditor.getDoc();
        doc.markClean();
      });
    }

    if (selectedReport.queryString) {
      vm.editorContent.value = selectedReport.queryString;
    } else if (selectedReport.queryId) {
      ReportsSrv.getReport(selectedReport.queryId, { admin: true })
        .then(function (query) {
          vm.editorContent.value = (query && query.query_string) || '';
        });
    }
  }
})();
