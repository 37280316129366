(function () {
  'use strict';

  angular.module('admin')
    .controller('AdminDashboardDetailsCtrl', AdminDashboardDetailsCtrl);

  AdminDashboardDetailsCtrl.$inject = ['$scope', '$mdSidenav', '$mdMedia', 'DashboardsSrv', '$mdDialog', 'psNotification'];

  function AdminDashboardDetailsCtrl ($scope, $mdSidenav, $mdMedia, DashboardsSrv, $mdDialog, psNotification) {
    var vm = this;
    var DASHBOARD_DELETE_ERROR = 'Unable to delete the dashboard.';
    var DASHBOARD_DELETE_SUCCESS = 'Dashboard deleted.';
    var DASHBOARD_PUT_ERROR = 'Unable to update the dashboard display name.';
    var DASHBOARD_PUT_SUCCESS = 'Updated the dashboard display name.';

    // Properties
    $scope.ACCESS = DashboardsSrv.ACCESS;
    $scope.LEVELS = DashboardsSrv.LEVELS;

    // Methods
    vm.cloneDashboard = cloneDashboard;
    vm.editDashboardDisplayName = editDashboardDisplayName;
    vm.onClose = onClose;
    vm.onUpdatePriority = onUpdatePriority;
    vm.promptDeleteDashboard = promptDeleteDashboard;
    vm.savePermissions = savePermissions;

    activate();

    function activate () {
      $scope.$watchGroup(['loaded', 'selectedDetail.empty'], function (curr) {
        if (curr[0] && curr[1]) {
          $mdSidenav('dashboardDetails').close();
        } else {
          $mdSidenav('dashboardDetails').open();
        }
      });
    }

    //= ================================
    // Public interface
    //= ================================

    function cloneDashboard () {
      if (angular.isFunction($scope.onClone)) {
        $scope.onClone();
      }
    }

    function editDashboardDisplayName (ev, dashboard) {
      $mdDialog.show({
        controller: 'EditDashboardDisplayNameModalCtrl',
        controllerAs: 'modal',
        locals: { modalDashboard: angular.copy(dashboard) },
        templateUrl: '/ui/admin/dashboards/editDashboardDisplayNameModal.html',
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: true
      })
        .then(function (modalDashboard) {
          DashboardsSrv.saveDisplayName(modalDashboard)
            .then(function (mwDashboard) {
              psNotification.success(DASHBOARD_PUT_SUCCESS);
              $scope.selected.display_name = mwDashboard.display_name;
              $scope.selectedDetail.display_name = mwDashboard.display_name;
            })
            .catch(function () {
              psNotification.error(DASHBOARD_PUT_ERROR);
            });
        })
        .catch(function () {
          // Dismissed.
        });
    }

    function onClose () {
      $mdSidenav('dashboardDetails').close();
      if (angular.isFunction($scope.onClose)) {
        $scope.onClose();
      }
    }

    function onDelete () {
      if (angular.isFunction($scope.onDelete)) {
        $scope.onDelete();
      }
    }

    function onUpdatePriority (dashboard) {
      if (angular.isFunction($scope.onUpdatePriority) && dashboard.priority) {
        $scope.onUpdatePriority({ selected: dashboard });
      }
    }

    function promptDeleteDashboard (ev, dashboard) {
      var confirm = $mdDialog.confirm()
        .targetEvent(ev)
        .title('Delete dashboard?')
        .textContent('Are you sure you want to delete this dashboard?')
        .ok('Delete')
        .cancel('Cancel')
        .clickOutsideToClose(true)
        .ariaLabel('Confirm delete dashboard');

      switch (dashboard.level) { // eslint-disable-line default-case
        case $scope.LEVELS.USER:
          confirm.title('Delete dashboard for ' + dashboard.user.name + ' (' + dashboard.user.username + ')?');
          break;
        case $scope.LEVELS.GROUP:
          confirm.title('Delete dashboard for ' + dashboard.group + '?');
          break;
        case $scope.LEVELS.SYSTEM:
          confirm.title('Delete the SYSTEM dashboard?');
          break;
      }

      $mdDialog.show(confirm)
        .then(function () {
          DashboardsSrv.delete(dashboard)
            .then(function () {
              psNotification.success(DASHBOARD_DELETE_SUCCESS);
              onDelete();
              onClose();
            })
            .catch(function () {
              psNotification.error(DASHBOARD_DELETE_ERROR);
            });
        })
        .catch(function () {
          // Modal dismissed.
        });
    }

    function savePermissions (dashboard) {
      DashboardsSrv.savePermissions(dashboard)
        .then(function (mwDashboard) {
          psNotification.success('Dashboard permissions changed.');
          $scope.selected.owner_permissions = mwDashboard.owner_permissions;
          $scope.selectedDetail.owner_permissions = mwDashboard.owner_permissions;
        });
    }
  }
})();
