/* eslint-disable no-multi-str */
(function () {
  'use strict';

  angular.module('imat.shared.test')
    .factory('imatFieldFixtures', imatFieldFixtures);

  imatFieldFixtures.$inject = ['x2js'];

  function imatFieldFixtures (x2js) {
    var service;

    service = {
      getFields: getFields
    };

    return service;

    function getFields () {
      return x2js.xml_str2json(
        '\
          <variables>\
            <variable>\
              <id>testProperty</id>\
              <field>testField</field>\
              <extract>testExtract</extract>\
              <type>regular</type>\
            </variable>\
          </variables>\
        '
      );
    }
  }
})();
