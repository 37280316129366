(function () {
  'use strict';

  angular.module('queries.services')
    .factory('FilterSrv', FilterSrv);

  FilterSrv.$inject = ['$http', '$log', '$q', 'dateTimeSrv', 'imatConfig'];

  function FilterSrv ($http, $log, $q, dateTimeSrv, imatConfig) {
    var loaded = {
      fields: false,
      filters: false
    };
    var filters = [];

    var service = {
      getFilterProperties: getFilterProperties,
      getFilterSyntax: getFilterSyntax,
      loadFilters: loadFilters
    };

    return service;

    function getFilterProperties (filterType) {
      var ft = (angular.isString(filterType) ? filterType.toUpperCase() : filterType);
      var properties = {
        operator: 'OR',
        options: {},
        value: ''
      };

      switch (ft) {
        // RANGE
        // DATE
        case 'RANGE':
        case 'DATE':
          properties.value = {
            start: null,
            end: null
          };
          break;
        // CODE SET
        case 'CODE_SET':
          properties.code = {
            selected: '',
            searchText: ''
          };
          properties.value = [];
          break;
        // CLINICAL CODES
        case 'NLP_CLINICAL':
          properties.code = {
            selected: '',
            searchText: ''
          };
          properties.nlp = true;
          properties.nlpFields = {
            type: 'snomed',
            section: '',
            subject: 'patient',
            concept: '',
            polarity: 'p',
            certainty: ''
          };
          properties.options = {};
          properties.value = [];
          break;
        // MEDICATIONS
        case 'NLP_MED':
          properties.code = {
            selected: '',
            searchText: ''
          };
          properties.nlp = true;
          properties.nlpFields = {
            type: 'rxnorm',
            section: '',
            subject: 'patient',
            concept: '',
            polarity: 'p',
            certainty: ''
          };
          properties.options = {};
          properties.value = [];
          break;
        // TEXT NLP
        case 'NLP_TEXT':
          properties.code = {
            selected: '',
            searchText: ''
          };
          properties.nlp = true;
          properties.nlpFields = {
            type: 'text',
            section: '',
            subject: 'patient',
            concept: '',
            polarity: 'p',
            certainty: ''
          };
          properties.options = {};
          properties.value = '';
          break;
        // SETS
        case 'SET':
        // SET has all necessary properties in selected object
          break;
        default:
          // Normal case
      }

      return $q.resolve(properties);
    }

    function getFilterSyntax (rule) { // eslint-disable-line complexity
      var syntax = '';
      var filterType = rule.type.toUpperCase();
      var termValue = '';

      switch (filterType) { // eslint-disable-line default-case
        case 'REGULAR':
        case 'NLP_TEXT':
          // fieldname:(value)
          // psfieldnames:"fieldname"
          syntax = (rule.value) ? rule.field + ':(' + rule.value + ')' : 'psfieldnames:"' + rule.field + '"';
          break;
        case 'SET':
          // nref/record list based
          // SET:setname
          // Quicktable fieldname based (usual quicktable field is PatientIDN)
          // FILTER(fieldname:SET:setname)
          if (rule.name !== '') {
            if (angular.isUndefined(rule.fieldname) ||
                rule.fieldname.toUpperCase() === 'NREF' ||
                rule.fieldname === '') {
              syntax = 'SET:' + rule.name;
            } else {
              syntax = 'FILTER(' + rule.fieldname + ':SET:' + rule.name + ')';
            }
          }
          break;
        case 'RANGE':
        case 'DATE':
          syntax = _formatRange(rule);
          break;
        case 'MANUAL':
        case 'KEYWORD':
          // String- whatever was entered into input field
          syntax = rule.value;
          break;
        case 'CODE_SET':
          // Supports Symbols
          // Need the field, operator, and code value (title)
          // If no values, will search psfieldnames
          syntax = 'psfieldnames:IN(' + rule.field + ')';
          // If AND, NOT or an OR with only 1 value
          if (rule.operator !== 'OR' || rule.value.length === 1) {
            termValue = rule.value.filter(function (entry) { return entry.title.length > 0; }).map(function (code) {
              return '"' + code.title + '"';
            }).join(' ');
            if (termValue.length > 0) {
              syntax = 'FOR(s.' + rule.field + '):(' + termValue + ')';
              if (rule.operator === 'NOT') {
                syntax = 'NOT (' + syntax + ')';
              }
            }
          } else {
            termValue = rule.value.filter(function (entry) { return entry.title.length > 0; }).map(function (code) {
              return '"' + code.title + '"';
            }).join(',');
            if (termValue.length > 0) {
              syntax = 'FOR(s.' + rule.field + '):IN(' + termValue + ')';
            }
          }
          break;
        case 'NLP_CLINICAL':
        case 'NLP_MED':
          // Need the field, operator, and code value (title)
          // If no values, will search psfieldnames
          syntax = 'psfieldnames:"' + rule.field + '"';
          // If AND, NOT or an OR with only 1 value
          if (rule.operator !== 'OR' || rule.value.length === 1) {
            termValue = rule.value.filter(function (entry) { return entry.title.length > 0; }).map(function (code) {
              return '"' + code.title + '"';
            }).join(' ');
            if (termValue.length > 0) {
              syntax = rule.field + ':(' + termValue + ')';
              if (rule.operator === 'NOT') {
                syntax = 'NOT (' + syntax + ')';
              }
            }
          } else {
            termValue = rule.value.filter(function (entry) { return entry.title.length > 0; }).map(function (code) {
              return '"' + code.title + '"';
            }).join(',');
            if (termValue.length > 0) {
              syntax = rule.field + ':IN(' + termValue + ')';
            }
          }
          break;
        case 'UNIVERSE':
          // UNIVERSE
          syntax = 'UNIVERSE';
          break;
      }
      return $q.resolve(syntax);
    }

    function loadFilters () {
      if (loaded.filters) {
        return $q.resolve(filters);
      }

      return imatConfig.loadConfigFile('filters.json')
        .then(function (filterResponse) {
          filters = filters.concat(filterResponse);
          // Loop through filters and replace with user friendly label name.
          angular.forEach(filters, function (filter) {
            switch (filter.type) {
              case 'CODE_SET':
                filter.typeLabel = 'Code search';
                break;
              case 'KEYWORD':
                filter.typeLabel = 'Keyword search';
                break;
              case 'MANUAL':
                filter.typeLabel = 'Advanced query syntax';
                break;
              case 'NLP_CLINICAL':
                filter.typeLabel = 'Clinical codes with NLP options';
                break;
              case 'NLP_MED':
                filter.typeLabel = 'Medication codes with NLP options';
                break;
              case 'NLP_TEXT':
                filter.typeLabel = 'Clinical text with NLP options';
                break;
              case 'SET':
                filter.typeLabel = 'Prebuilt result list of patient or clinical records';
                break;
              case 'UNIVERSE':
                filter.typeLabel = 'Return all records';
                break;
              default:
                filter.typeLabel = '';
            }
          });
          loaded.filters = true;
          return filters;
        })
        .catch(function (errorResponse) {
          $log.debug('Unable to load some or all filters');
          loaded.filters = false;
          return $q.reject(errorResponse);
        });
    }

    function _formatRange (rule) {
      var start = rule.value.start;
      var end = rule.value.end;
      var syntax = '';

      if (rule.type.toUpperCase() === 'DATE') {
        start = dateTimeSrv.getIsoDate(start).replace(/-/g, '');
        end = dateTimeSrv.getIsoDate(end).replace(/-/g, '');
      }

      // if no values then set to * (0 is valid)
      if (start === null || start === '') {
        start = '*';
      }
      if (end === null || end === '') {
        end = '*';
      }

      // if the values are the same, send one
      if (start === end) {
        syntax = rule.field + ':[' + start + ']';
      } else if ((start !== '*' && end !== '*') && start > end) { // if the start value is greater than the end, swap 'em
        syntax = rule.field + ':[' + end + ' TO ' + start + ']';
      } else { // [start TO end]
        syntax = rule.field + ':[' + start + ' TO ' + end + ']';
      }

      return syntax;
    }
  }
})();
