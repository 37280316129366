(function () {
  'use strict';

  angular.module('imat.directives')
    .directive('imatVirtualRemove', imatVirtualRemove);

  imatVirtualRemove.$inject = ['$animateCss'];

  function imatVirtualRemove ($animateCss) {
    return {
      link: postLink,
      require: '^^mdVirtualRepeat',
      restrict: 'A',
      scope: {
        callback: '&?imatVirtualRemove'
      }
    };

    function postLink (scope, element) {
      var bounce = false;
      var target = element.parent();

      scope.callback = scope.callback || angular.noop;

      while (!target.attr('md-virtual-repeat')) {
        target = target.parent();
      }

      element.on('click', function () {
        if (!bounce) {
          bounce = true;
          $animateCss(target, {
            from: { opacity: 1 },
            to: { opacity: 0 },
            duration: 0.3,
            cleanupStyles: false
          })
            .start()
            .then(function () {
              scope.callback();
              target.css('opacity', '');
            })
            .finally(function () {
              bounce = false;
            });
        }
      });
    }
  }
})();
