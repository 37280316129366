(function () {
  'use strict';

  angular.module('queries')
    .controller('RecordDetailsCtrl', RecordDetailsCtrl);

  RecordDetailsCtrl.$inject = [
    'QUERY',
    '$mdDialog', '$mdSidenav', '$q', '$state', '$scope', 'imatConfig', 'psNotification', 'QueriesSrv'
  ];

  function RecordDetailsCtrl (
    QUERY,
    $mdDialog, $mdSidenav, $q, $state, $scope, imatConfig, psNotification, QueriesSrv
  ) {
    var vm = this;

    // Properties
    vm.angular = angular;
    vm.helpURL = imatConfig.get('apps.queries.customerHelpLink');
    vm.loading = {
      record: false
    };
    vm.order = {
      extendedData: 'data._type',
      allergies: 'text',
      devices: 'text',
      labs: 'lab._descr',
      measurements: 'measurement._name',
      medications: 'text',
      problems: 'text',
      procedures: 'text',
      symptoms: 'text',
      codes: '_descr'
    };
    vm.queries = QueriesSrv.queries;
    vm.record = QueriesSrv.record;
    vm.selectConceptAgain = true;// Start true to prevent double initial select.
    vm.selectedConcept = QueriesSrv.selectedConcept;
    vm.searchText = '';
    vm.noResultsMsg = 'No results were found using the current filter.';
    vm.sidenavLoaded = false;

    // Methods
    vm.backToResults = backToResults;
    vm.clearSelection = clearSelection;
    vm.editQuery = editQuery;
    vm.loadRecord = loadRecord;
    vm.newQuery = newQuery;
    vm.searchByPatientId = searchByPatientId;
    vm.selectConcept = selectConcept;
    vm.showRecordInfo = showRecordInfo;
    vm.textFilterList = textFilterList;
    vm.resetSearch = resetSearch;

    activate();

    function activate () {
      loadRecord();

      $scope.$watch(function () { return vm.record; }, function (newValue, oldValue) {
        if (newValue !== oldValue) {
          vm.searchText = '';
        }
      }, true);

      $scope.$watch(function () { return $scope.$parent.ctrl.loading.record; }, function (loading) {
        if (loading) {
          clearSelection();
        }
      }, true);

      $mdSidenav('queryRecordDetails', true)
        .then(function () {
          vm.sidenavLoaded = true;
        });
    }

    function backToResults () {
      $state.go('app.queries.results');
    }

    function clearSelection () {
      if (vm.selectedConcept) {
        delete vm.selectedConcept.selected;
        vm.selectedConcept = {};
      }
      if (vm.sidenavLoaded) {
        $mdSidenav('queryRecordDetails').close();
      }
    }

    function editQuery () {
      $state.go('app.queries.builder');
    }

    function loadRecord () {
      clearSelection();
      vm.loading.record = true;
      return QueriesSrv.loadRecordData()
        .catch(function (errorResponse) {
          psNotification.error('Unable to load the record data.');
          return $q.reject(errorResponse);
        })
        .finally(function () {
          vm.loading.record = false;
        });
    }

    function newQuery () {
      QueriesSrv.resetQuery();
      $state.go('app.queries.builder');
    }

    function searchByPatientId () {
      var patientQuery = {
        name: 'Unnamed query',
        description: '',
        resultDisplay: QUERY.RESULT_DISPLAY_DEFAULT,
        saved: false,
        stores: [QUERY.ALL_STORES],
        parameters: {},
        built: '()s.patient_id:(' + vm.record.patient_id + ')',
        expression: {
          operator: 'AND',
          groups: [
            {
              operator: 'AND',
              filter: {
                selected: '',
                searchText: ''
              },
              rules: [
                { id: 'patient_id', label: 'Patient ID', field: 's.patient_id', extract: 'patient_id', type: 'regular', operator: 'AND', options: {}, value: vm.record.patient_id }
              ]
            }
          ]
        }
      };
      QueriesSrv.setActiveId();
      QueriesSrv.setQuery(patientQuery);
      $state.go('app.queries.builder');
    }

    function showRecordInfo () {
      $mdDialog.show({
        controller: 'RecordInfoModalCtrl',
        controllerAs: 'ctrl',
        locals: { record: vm.record },
        templateUrl: '/ui/queries/record-details/record-info-modal.html',
        parent: angular.element(document.body),
        clickOutsideToClose: true
      });
    }

    function selectConcept (concept) {
      var code = concept.codes && concept.codes.code ? concept.codes.code : false;
      if (code) {
        concept.codes.code = angular.isArray(code) ? code : [code];
      }
      angular.element('csmkdiv').removeClass('highlight');

      if (vm.selectedConcept) {
        clearSelection();
      }
      vm.selectedConcept = concept;
      vm.selectedConcept.selected = true;

      $mdSidenav('queryRecordDetails').open();
    }

    function textFilterList (concept) {
      if (!vm.searchText) {
        return true;
      }

      return (concept.data._type.indexOf(vm.searchText.toLowerCase()) !== -1) ? true : (concept.data._value.toLowerCase().indexOf(vm.searchText.toLowerCase()) !== -1);
    }

    function resetSearch () {
      vm.searchText = '';
    }
  }
})();
