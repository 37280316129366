(function () {
  'use strict';

  angular
    .module('mpiApp.search')
    .controller('mpiSearchCtrl', mpiSearchCtrl);

  mpiSearchCtrl.$inject = [
    'STORES',
    '$interval', '$mdSidenav', '$q', '$state', '$timeout',
    'mpiConfigSrv', 'mpiPersona', 'mpiSearchSrv', 'psNotification', 'simpleQueryBuilder'
  ];

  function mpiSearchCtrl (
    STORES,
    $interval, $mdSidenav, $q, $state, $timeout,
    mpiConfigSrv, mpiPersona, mpiSearchSrv, psNotification, simpleQueryBuilder
  ) {
    var config = {};
    var vm = this; // eslint-disable-line no-invalid-this

    vm.cached = {};
    vm.results = [];
    vm.selected = {};

    vm.currentPage = 1;
    vm.limit = 1;
    vm.total = 1;

    vm.columns = {
      MPID: true,
      Facility: true,
      MRN: true,
      'Full Name': true,
      Gender: true,
      'Birth Date': true,
      SSN: false,
      Phone: false,
      Address: false,
      City: false,
      State: false,
      Zip: false,
      Race: false,
      Ethnicity: false,
      'Multiple PI': false
    };
    vm.fields = {};
    vm.loading = { fields: true, results: false };
    vm.searched = false;
    vm.sections = { patientDemographics: false, patientIds: false, recordDetails: false };

    // Methods
    vm.clear = clear;
    vm.clearResults = clearResults;
    vm.clearSelection = clearSelection;
    vm.firstPage = firstPage;
    vm.goToDetails = goToDetails;
    vm.limitChange = limitChange;
    vm.nextPage = nextPage;
    vm.openMenu = openMenu;
    vm.renderPages = renderPages;
    vm.search = search;
    vm.selectPersona = selectPersona;

    activate();

    // Public Methods

    function activate () {
      _getFields();
    }

    function clear () {
      vm.fields = angular.copy(config.form_fields) || {};
    }

    function clearResults () {
      clearSelection();
      mpiSearchSrv.clearCachedResults(config.store);
      vm.results = [];
      vm.searched = false;
      _scrollToElement('.search-view', '.mpi-search-form');
    }

    function clearSelection () {
      $mdSidenav('personaDetails').close();
      mpiSearchSrv.clearSelection(config.store);
      vm.selected = {};
    }

    function goToDetails (id) {
      $state.go('app.mpi.personas', { id: id });
    }

    function openMenu ($mdMenu, ev) {
      $mdMenu.open(ev);
    }

    function firstPage () {
      vm.loading.results = true;
      return _run(vm.fields, 1);
    }

    function nextPage () {
      vm.loading.results = true;
      return _run(vm.fields, vm.currentPage + 1);
    }

    function limitChange (limit) {
      vm.limit = limit || vm.limit;
      return firstPage();
    }

    function renderPages (page) {
      vm.loading.results = true;
      return _run(vm.fields, page);
    }

    function search () {
      if (!_checkFields()) {
        psNotification.show('Please enter a value to search.');
        return;
      }
      clearSelection();
      renderPages(1)
        .then(function () {
          _scrollToElement('.search-view', '.search-results');
        });
    }

    function selectPersona (persona) {
      vm.selected = persona;
      mpiSearchSrv.selectRecord(config.store, persona);
      $mdSidenav('personaDetails').open();
    }

    // Private Methods

    function _getFields () { // eslint-disable-line complexity
      config = mpiConfigSrv.getPersonaConfig();
      vm.limit = config.limit;
      vm.cached = mpiSearchSrv.getCachedResults(config.store);

      if (angular.isDefined(vm.cached.formFields)) {
        vm.fields = angular.copy(vm.cached.formFields) || {};
      } else {
        vm.fields = angular.copy(config.form_fields) || {};
      }

      if (!vm.fields.default_fields || !vm.fields.default_fields.length) {
        for (var section in vm.sections) {
          if (Object.prototype.hasOwnProperty.call(vm.sections, section)) {
            vm.sections[section] = true;
          }
        }
      }

      if (vm.cached.results && vm.cached.results.length) {
        _processResults(vm.cached, vm.currentPage);
      }

      if (vm.cached.selected) {
        $timeout(function () {
          selectPersona(vm.cached.selected.data);
        }, 0);
      }

      vm.loading.fields = false;
    }

    function _getPersonas (hits) {
      if (Array.isArray(hits)) {
        hits.forEach(function (hit) {
          mpiPersona.load(hit.rawrecord)
            .then(function (persona) {
              persona.setURI(hit.uri);
              vm.results.push(persona);
            });
        });
      }
    }

    function _processResults (res, page) {
      vm.results = [];
      vm.currentPage = page;
      vm.total = res.meta._total;
      _getPersonas(res.results);
      vm.searched = true;
      return vm.results;
    }

    function _run (parameters, page) {
      clearSelection();
      vm.loading.results = true;

      var newQuery = {};
      var q = '()' + simpleQueryBuilder.getSimpleQuery(parameters);

      newQuery.formFields = parameters;
      newQuery.caller = config.caller; // What UI is the search coming from?
      newQuery.store = config.store; // Which store should we query?
      newQuery.report = config.report; // Do we need an exacttotal or not?
      newQuery.onspace = page === 1 ? 0 : parseInt(config.onspace, 10);
      newQuery.onref = page === 1 ? 0 : parseInt(config.onref, 10);
      newQuery.limit = vm.limit; // Configurable limit
      newQuery.fields = 'uri,rawrecord';
      newQuery.q = q;

      if (newQuery.q === '()') {
        vm.loading.results = false;
        psNotification.show('Please enter a value to search.');
        return $q.reject();
      }

      return mpiSearchSrv.run(newQuery)
        .then(function (res) {
          config.onspace = res.meta.onspace;
          config.onref = res.meta.onref;
          return _processResults(res, page);
        })
        .catch(function () {
          psNotification.error('There was a problem with the search.');
        })
        .finally(function () {
          vm.loading.results = false;
          mpiPersona.reset();
          if (!vm.results.length) {
            psNotification.show('No results found.');
          }
        });
    }

    function _scrollToElement (scroller, scrollTo, speed) {
      var iterations = speed || 70;
      var scrollStart = document.querySelector(scroller).scrollTop;
      var amount = scrollStart;
      var offsetTop = document.querySelector(scrollTo).offsetTop;

      $interval(function () {
        var scrollTop = document.querySelector(scroller).scrollTop;

        switch (true) {
          case offsetTop < scrollStart:
            amount = scrollTop - Math.floor(scrollStart / iterations);
            break;

          case scrollTop > 0 && scrollTop > offsetTop:
            amount = scrollTop;
            break;

          default:
            amount = amount + Math.floor(offsetTop / iterations);
            break;
        }

        document.querySelector(scroller).scrollTop = amount;
      }, 0, iterations);
    }

    function _checkFields () {
      var values = false;
      for (var field in vm.fields) {
        if (vm.fields[field].value) {
          values = true;
        }
      }
      return values;
    }
  }
})();
