(function () {
  'use strict';

  angular
    .module('imat.services')
    .factory('userSession', userSession);

  userSession.$inject = [
    'APP_TIMEOUT',
    '$cacheFactory', '$cookies', '$interval', '$mdDialog', '$q', '$rootScope', '$window',
    'accountApiSrv', 'dateTimeSrv', 'idleTimerSrv', 'imatConfig', 'profileApiSrv', 'Preferences'
  ];

  function userSession (
    APP_TIMEOUT,
    $cacheFactory, $cookies, $interval, $mdDialog, $q, $rootScope, $window,
    accountApiSrv, dateTimeSrv, idleTimerSrv, imatConfig, profileApiSrv, Preferences
  ) {
    var SESSION_COOKIE = 'sesssync';
    var SESSION_WARNING = 'warning';
    var SESSION_EXPIRED = 'expired';
    var $httpCache = $cacheFactory.get('$http');
    var loader;
    var poller;
    var session = { multiplier: 1, multiplied: 0, expiration: null };

    var userData = {
      groups: [],
      name: '',
      provider: '',
      roles: [],
      systemName: '',
      username: null,
      isAdmin: false
    };

    var service = {
      changeName: changeName,
      changePassword: changePassword,
      load: load,
      logout: logout,
      getGroups: getGroups,
      getLoginSettings: getLoginSettings,
      getSecurityQuestions: getSecurityQuestions,
      getUserData: getUserData,
      hasRoles: hasRoles,
      postSecurityQuestions: postSecurityQuestions,
      userHasGroup: userHasGroup,
      userHasRole: userHasRole,
      userIsAdmin: userIsAdmin
    };

    var dialog = {
      controller: [function () {
      }],
      parent: angular.element($window.document.body),
      template:
        '<md-toolbar class="md-default-theme md-warn flex="auto" layout-align-start-center layout-row layout-padding">' +
        '  <span class="layout-padding">System Inactivity Warning</span>' +
        '</md-toolbar>' +
        '<div class="md-dialog">' +
        '  <div class="md-dialog-content">You appear to be idle. You will be logged out soon.</div>' +
        '</div>'
    };

    return service;

    function changeName (newName) {
      return saveProfile({
        name: newName
      });
    }

    function changePassword (oldPassword, newPassword, provider) {
      return saveProfile({
        old_password: oldPassword,
        password: newPassword,
        provider: provider,
        permanent: 'true'
      });
    }

    function saveProfile (data) {
      var currentUser = getUserData();
      data.provider = currentUser.provider;
      return profileApiSrv.saveProfile(data)
        .then(function (res) {
          angular.forEach(data, function (val, key) {
            if (Object.prototype.hasOwnProperty.call(userData, key)) {
              userData[key] = angular.copy(val);
            }
          });
          $httpCache.remove('/api/appliance/profile');
          $rootScope.$broadcast('user.profile.modified');
          return res;
        });
    }

    function logout (redirect, save) {
      var promise = save ? saveLocation() : $q.resolve();

      return promise
        .catch(function () {
          // Don't care.
        })
        .then(function () {
          return accountApiSrv.logout()
            .then(function () {
              idleTimerSrv.cancel();
              $window.location.assign('/account/login');
            })
            .catch(function (err) {
              return showBadSessionTermination(err)
                .finally(function () {
                  if (redirect) {
                    $window.location.assign('/account/login');
                  }
                });
            });
        });
    }

    function load () {
      if (!loader) {
        loader = $q.defer();

        $q.all([
          Preferences.load(),
          prepareSession(true),
          initUserData().then(getRoleProperties)
        ])
          .then(function () {
            loader.resolve(userData);
          })
          .catch(function (err) {
            loader.reject(err);
          });
      }

      return loader.promise;
    }

    function initUserData () {
      var tasks = [];
      tasks.push(getRoles());
      tasks.push(getUsername());
      tasks.push(getGroups());
      tasks.push(getProfile());
      return $q.all(tasks);
    }

    function hasRoles (roles) {
      // Undefined roles arg - No Access
      if (!roles || typeof roles === 'undefined') {
        return false;
      }

      // Empty roles arg - Global Access
      if (Array.isArray(roles) && roles.length === 0) {
        return true;
      }

      // Roles specified - Access if any role found
      for (var i = 0, ii = roles.length; i < ii; ++i) {
        if (userData.roles.indexOf(roles[i]) >= 0) {
          return true;
        }
      }
      return false;
    }

    function getUserData () {
      return userData;
    }

    function getUsername () {
      return accountApiSrv.getSession().then(setUsername);
    }

    function setUsername (res) {
      var data = res.data;
      if (Object.prototype.hasOwnProperty.call(data, 'username')) {
        userData.username = data.username;
      }
      return res;
    }

    function getGroups () {
      return profileApiSrv.getProfileGroups().then(setGroups);
    }

    function setGroups (res) {
      var data = res.data;
      if (Object.prototype.hasOwnProperty.call(data, 'group')) {
        userData.groups = data.group;
      }
      return res;
    }

    function getLoginSettings () {
      return accountApiSrv.getLoginSettings()
        .then(function (response) {
          return response.data;
        });
    }

    function getSecurityQuestions () {
      return accountApiSrv.getSecurityQuestions()
        .then(function (response) {
          return response.data;
        });
    }

    function postSecurityQuestions (questions) {
      return accountApiSrv.postSecurityQuestions({ questions: questions });
    }

    function getProfile () {
      return profileApiSrv.get().then(setProfile);
    }

    function setProfile (res) {
      var data = res.data;
      if (Object.prototype.hasOwnProperty.call(data, 'name')) {
        userData.name = data.name;
      }
      if (Object.prototype.hasOwnProperty.call(data, 'provider')) {
        userData.provider = data.provider;
        userData.systemName = data.username + '@' + data.provider;
      }
    }

    function getRoleProperties () {
      // Find out if the user has an extended-session role.
      // Among those, choose the largest extension.
      return imatConfig.loadConfigFile('role-properties.json')
        .then(function (roles) {
          roles.toArray().forEach(function (role) {
          // TODO Stop using the group AS a group; use the associated role.
            if (role.sessionLengthMultiplier && role.sessionLengthMultiplier > session.multiplier && userHasGroup(role.role)) {
              session.multiplier = role.sessionLengthMultiplier;
            }
          });
        })
        .catch(function () {
        // Ignored; probably no roles.json file.
        });
    }

    function getRoles () {
      return profileApiSrv.getRoles().then(setRoles);
    }

    function setRoles (res) {
      var data = res.data;
      if (Object.prototype.hasOwnProperty.call(data, 'role')) {
        if (Array.isArray(data.role)) {
          userData.roles = data.role;
        } else {
          userData.roles = [data.role];
        }
        userData.isAdmin = userIsAdmin();
      }
      return res;
    }

    function userHasGroup (group) {
      return userData.groups.indexOf(group) !== -1;
    }

    function userHasRole (role) {
      return userData.roles.indexOf(role) !== -1;
    }

    function userIsAdmin () {
      return userHasRole('system');
    }

    //= ================================
    // Session timing and "whatnot"
    //= ================================

    function prepareSession (init) {
      return getSessionExpiration()
        .then(function (expiration) {
          session.expiration = expiration;
          return setSessionIdleTimer(expiration, init)
            .catch(showBadSessionConfiguration);
        });
    }

    function getSessionExpiration () {
      return accountApiSrv.getSession()
        .then(function (response) {
          var expiration;

          if (Object.prototype.hasOwnProperty.call(response, 'data') && Object.prototype.hasOwnProperty.call(response.data, 'username') && Object.prototype.hasOwnProperty.call(response.data, 'session_expiration')) {
            expiration = dateTimeSrv.getIsoDatetime(response.data.session_expiration);
          }
          return expiration || $q.reject('The session expiration was not set or could not be parsed.');
        });
    }

    function setSessionIdleTimer (sessionExpiration, init) {
      if (!idleTimerSrv.set(sessionExpiration)) {
        return $q.reject('Server session duration is too short.');
      }

      // XXX For IE 11, try remove/rewrite to defeat aggressive caching.
      $cookies.remove(SESSION_COOKIE);
      $cookies.put(SESSION_COOKIE, sessionExpiration);

      if (init) {
        // Fired periodically from activityInterceptor.
        $rootScope.$on('HttpRequestActivity', handleSessionActivity);
        // Fires when the countdown should begin.
        $rootScope.$on('ImatSessionIdle', handleSessionIdle);
        // Fires when the countdown is aborted due to activity.
        $rootScope.$on('ImatSessionResumed', handleSessionResumed);
        // Fires when the countdown is completed without activity.
        $rootScope.$on('ImatSessionExpired', handleSessionExpired);
      }

      return $q.resolve();
    }

    //= ================================
    // Rabbit-hole of session helpers
    //= ================================

    function saveLocation () {
      return Preferences.setPreference('prevlocation', {
        url: $window.location.pathname + $window.location.hash,
        timestamp: Date.now()
      });
    }

    function handleSessionActivity (e) { // eslint-disable-line no-unused-vars
      var expts = new Date(session.expiration).getTime();
      var adjts = expts + ((idleTimerSrv.duration - idleTimerSrv.remaining) * 1000) + 1000;
      var adjdt = dateTimeSrv.getIsoDatetime(new Date(adjts));

      if (session.expiration !== adjdt && idleTimerSrv.set(adjdt)) {
        session.expiration = adjdt;
        session.multiplied = 0;
        // XXX For IE 11, try remove/rewrite to defeat aggressive caching.
        $cookies.remove(SESSION_COOKIE);
        $cookies.put(SESSION_COOKIE, adjdt);
      }
    }

    function handleSessionIdle (e) { // eslint-disable-line no-unused-vars
      if (isLateToTheParty()) { return; }

      accountApiSrv.getSessionExpiration()
        .then(function (response) {
          var expiration = response && response.data && response.data.expirationTimestamp;
          if (expiration && isOldExpiration(expiration)) { // If our *current* expiration is old
            if (idleTimerSrv.set(expiration)) {
              session.expiration = expiration;
              session.multiplied = 0;
            }
            return;
          }

          if (++session.multiplied < session.multiplier) {
            // Auto-extend the session.
            prepareSession()
              .catch(function () {
                logout(true);
              });
          } else {
            // XXX For IE 11, try remove/rewrite to defeat aggressive caching.
            $cookies.remove(SESSION_COOKIE);
            $cookies.put(SESSION_COOKIE, SESSION_WARNING);
            $mdDialog.show(dialog);
            idleTimerSrv.listen();
          }
        });
    }

    function handleSessionResumed (e) { // eslint-disable-line no-unused-vars
      session.multiplied = 0;
      $mdDialog.hide();
      prepareSession()
        .catch(function () {
          logout(true);
        });
    }

    function handleSessionExpired () {
      accountApiSrv.getSessionExpiration()
        .then(function (response) {
          var expiration = response && response.data && response.data.expirationTimestamp;
          if (isOldExpiration(expiration)) { // If our *current* expiration is old
            if (idleTimerSrv.set(expiration)) {
              session.expiration = expiration;
              session.multiplied = 0;
              $mdDialog.hide();
            }
            return true;
          }
          return false;
        })
        .catch(function (err) {
          console.error(err);
          return false;
        })
        .then(function (breakChain) {
          if (breakChain) return;

          // XXX For IE 11, try remove/rewrite to defeat aggressive caching.
          $cookies.remove(SESSION_COOKIE);
          $cookies.put(SESSION_COOKIE, SESSION_EXPIRED);
          $mdDialog.cancel();
          logout(true, true);
        });
    }

    // ---------------------------------

    function isLateToTheParty () {
      var sessionExpiration = $cookies.get(SESSION_COOKIE);

      if (sessionExpiration === SESSION_EXPIRED) {
        // Another tab has expired the session.
        logout(true, true);
        return true;
      }

      if (sessionExpiration === SESSION_WARNING) {
        // Another tab is listening for activity.
        followTheLeader();
        return true;
      }

      if (isOldExpiration(sessionExpiration)) {
        // Another tab has documented proof of life.
        if (idleTimerSrv.set(sessionExpiration)) {
          session.expiration = sessionExpiration;
          session.multiplied = 0;
        } else {
          followTheLeader();
        }
        return true;
      }

      return false;
    }

    function isOldExpiration (sessionExpiration) {
      var browserExpiration = new Date(sessionExpiration);
      var serviceExpiration = new Date(session.expiration);
      return serviceExpiration < browserExpiration;
    }

    function followTheLeader () {
      if (poller) { return; }

      poller = $interval(function () {
        var sessionExpiration = $cookies.get(SESSION_COOKIE);

        switch (sessionExpiration) {
          case SESSION_EXPIRED:
            $interval.cancel(poller); poller = null;
            logout(true, true);
            break;
          case SESSION_WARNING:
            // Continue polling...
            break;
          default:// timestamp
            $interval.cancel(poller); poller = null;
            if (idleTimerSrv.set(sessionExpiration)) {
              session.multiplied = 0;
              session.expiration = sessionExpiration;
            } else {
              logout(true, true);
            }
            break;
        }
      }, 1000, APP_TIMEOUT.COUNTDOWN, false);
    }

    //= ================================
    // Session related dialogs
    //= ================================

    function showBadSessionConfiguration (err) {
      return $mdDialog.show(
        $mdDialog.alert()
          .title('Bad Server Session Configuration')
          .textContent('The server might have a bad timezone configuration, incorrect clock, or the session timeout is too short. You will be logged out now.')
          .ok('Ok')
      ).then(function () { return $q.reject(err); });
    }

    function showBadSessionTermination (err) {
      return $mdDialog.show(
        $mdDialog.alert()
          .title('Server Error')
          .textContent('There was an error logging you out. Please try again later.')
          .ok('Ok')
      ).then(function () { return $q.reject(err); });
    }
  }
})();
