(function () {
  'use strict';

  angular.module('queries')
    .controller('RecordInfoModalCtrl', RecordInfoModalCtrl);

  RecordInfoModalCtrl.$inject = ['$mdDialog', 'record'];

  function RecordInfoModalCtrl ($mdDialog, record) {
    var vm = this;

    vm.record = angular.copy(record);
    vm.closeDialog = closeDialog;

    function closeDialog () {
      $mdDialog.cancel();
    }
  }
})();
