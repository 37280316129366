(function () {
  'use strict';

  angular
    .module('vhr.directives')
    .directive('vhrMedInsDates', vhrDir);

  vhrDir.$inject = [];

  function vhrDir () {
    var directive = {
      link: { pre: postLink },
      restrict: 'E',
      scope: {
        json: '='
      },
      templateUrl: '/ui/vhr/directives/uiGridTemplates/medicalInsurance/medical-insurance-dates.html'
    };
    var json = {
      end: '',
      start: ''
    };

    return directive;

    function postLink (scope, element, attrs, ctrl) { // eslint-disable-line no-unused-vars
      var period = angular.extend({}, json, scope.json || {});

      scope.planStartDate = period.start;
      scope.planEndDate = period.end;
    }
  }
})();
