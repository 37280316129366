(function () {
  'use strict';
  angular.module('admin')
    .filter('notInPermissions', notInPermissions);

  notInPermissions.$inject = [];

  function notInPermissions () {
    return function (list, permissions) {
      if (!permissions) {
        return list;
      }
      return list.filter(function (item) {
        for (var index in permissions) {
          if (
            permissions[index].authentication_type == item.permission.authentication_type && // eslint-disable-line eqeqeq
            permissions[index].authentication_id == item.permission.authentication_id) { // eslint-disable-line eqeqeq
            return false;
          }
        }
        return true;
      });
    };
  }
})();
