(function () {
  'use strict';

  angular
    .module('vhr.test')
    .factory('vhrPatientFix', vhrPatientFix);

  vhrPatientFix.$inject = ['$q'];

  function vhrPatientFix ($q) {
    var service;

    service = {
      getPatient: getPatient,
      getDeadPatient: getDeadPatient
    };

    function getPatient (promise) {
      var fix = {
        date_of_birth: '1974-07-11',
        first_name: 'Testy',
        gender: 'M',
        last_name: 'McTester',
        middle_initial: 'T',
        middle_name: 'Test',
        mpid: 1234,
        phone: '987-654-3210',
        suffix: 'Jr.'
      };
      return (promise) ? $q.resolve(fix) : fix;
    }

    function getDeadPatient (promise) {
      var fix = {
        date_of_birth: '1974-07-11',
        date_of_death: '2000-07-11',
        first_name: 'Testy',
        gender: 'M',
        last_name: 'McTester',
        middle_initial: 'T',
        middle_name: 'Test',
        mpid: 1234,
        phone: '987-654-3210',
        suffix: 'Jr.'
      };
      return (promise) ? $q.resolve(fix) : fix;
    }

    return service;
  }
})();
