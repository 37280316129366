(function () {
  'use strict';

  angular
    .module('vhr')
    .controller('GenericGridCtrl', GenericGridCtrl);

  GenericGridCtrl.$inject = [
    'MODE', 'VHR_REPORT',
    '$scope', '$state', '$log', '$q', '$timeout',
    'psNotification', 'psReports', 'vhrApp', 'vhrConfigSrv', 'vhrGridSrv', 'vhrPatientSrv'
  ];

  function GenericGridCtrl (
    MODE, VHR_REPORT,
    $scope, $state, $log, $q, $timeout,
    psNotification, psReports, vhrApp, vhrConfigSrv, vhrGridSrv, vhrPatientSrv
  ) {
    var vm = this;
    var RECORD_TYPE_ALL = 'ALL';

    $scope.INPUT_TYPES = psReports.INPUT_TYPES;

    // Properties
    vm.config = vhrConfigSrv.getPageByState($state.current.name);
    vm.enableDicom = false;
    vm.filters = [];
    vm.filtersSet = false;
    vm.formFilters = {};
    vm.gridApi = {};
    vm.gridConfig = {};
    vm.gridRouting = {};
    vm.grids = [];
    vm.hint = {};
    vm.loaded = { report: false };
    vm.mpid = vhrPatientSrv.raw.mpid;
    vm.options = vm.config.options || {};//hack to get in View Images button for radiology
    vm.report = {};
    vm.reportParams = {};
    vm.recordTypes = [];
    vm.selectedRecordTypes = {};
    vm.showFilter = vhrApp.showFilter();
    vm.showTypes = ($state.current.name === 'app.vhr.patient.abstract-grid.grid-all');
    vm.workspace = vm.config.stateName;
    // Methods
    vm.resetFilter = resetFilter;
    vm.submitFilter = submitFilter;

    activate();

    // Implementation

    function activate () {
      $scope.$on('vhrFilterShown', function (evt, showFilter) { vm.showFilter = showFilter; });
      $scope.$on('vhrRefreshed', function () { refresh(); });
      vhrApp.enableFilter(true);
      vhrApp.enableModes(true);
      vhrApp.enableRefresh(false);

      setGridConfig();

      loadReport()
        .then(function () {
          return loadReportData(angular.copy(vm.reportParams));
        })
        .catch(function (reason) {
          reason = reason || 'Failed to load the report.';
          psNotification.error(reason);
        })
        .finally(function () {
          ready();
          handleGridOnRegisterApi();
        });
    }

    function handleGridOnRegisterApi () {
      angular.forEach(vm.gridApi, function (grid) {
        grid.core.handleWindowResize();
      });
    }

    function getDefaultParameters (report, state) {
      var params;

      if (!state || state === vm.workspace) {
        params = report.getParameterDefaults(true);
      } else {
        params = report.getLastParameters(state, true);
        delete $state.current.data.useWorkspaceParams;
      }
      angular.forEach(vm.config.parameters, function (param, paramName) {
        if (typeof params[paramName] === 'undefined') {
          $log.debug('The page configuration is trying to set an unexpected parameter: ' + paramName);
          return;// continue
        }
        params[paramName].value = param;
      });
      return params;
    }

    function loadReport () {
      return psReports.load(vm.config.reportId, VHR_REPORT.CALLER, { initParams: vhrConfigSrv.getReportParameters(vm.config.reportId) })
        .then(function (report) {
          var params;

          if ($state.current.data && $state.current.data.useWorkspaceParams && report.results[$state.current.data.useWorkspaceParams]) {
            params = getDefaultParameters(report, $state.current.data.useWorkspaceParams);
          } else {
            params = report.getLastParameters(vm.workspace, true) || getDefaultParameters(report);
          }

          vm.report = report;
          vm.reportParams = params;
          setFilter(params);
        })
        .catch(function (reason) {
          $log.debug(reason);
          return $q.reject('Failed to prepare the report.');
        })
        .finally(function () {
          vhrApp.enableRefresh((vm.report && !!vm.report.id));
        });
    }

    function loadReportData (params, force) {
      if (vm.reportParams.patient_id) {
        params.patient_id.value = vhrPatientSrv.raw.mpid;
      }
      if (vm.reportParams.summary) {
        params.summary = false;
      }

      return vm.report.run(vm.workspace, params, force)
        .then(function () {
          return vm.report.results[vm.workspace].getPollPromise();
        })
        .then(checkDicom)
        .then(function () {
          return vm.report.getArtifact(vm.workspace, 'results.json');
        })
        .then(normalizeVhrReportData)
        .then(function (reportData) {
          vm.gridConfig[RECORD_TYPE_ALL].data = reportData[0][RECORD_TYPE_ALL];
        })
        .catch(function (reason) {
          $log.debug(reason);
          return $q.reject('Failed to load the patient data.');
        });
    }

    function checkDicom () {
      //if dicom workflow is enabled, we need to find out if button should be enabled
      if (vm.options.dicom) {
        return vm.report.getArtifact(vm.workspace, 'dicom.json')
        .then(function (response) {
          vm.enableDicom = response.enableDicom || false;
          return;
        });
      }
      return;
    }

    function normalizeVhrReportData (data) {
      var packed = {};
      var source;
      var rexSource;

      // Assume legacy format.
      // [{ ... }, { ... }, ...]
      packed[RECORD_TYPE_ALL] = Array.isArray(data) ? data : [];

      if (data.length === 1 && angular.isObject(data[0])) {
        rexSource = new RegExp('^' + RECORD_TYPE_ALL + '$', 'i');
        source = Object.keys(data[0]);

        if (source.length === 1 && rexSource.test(source[0])) {
          packed[RECORD_TYPE_ALL] = data[0][source[0]];// Assume desired format.
        }
      }

      return [packed];// [{ 'ALL': [records] }]
    }

    function ready () {
      vm.loaded.report = true;
      $timeout(function () {
        setGridRowSelections(vhrApp.getMode());
      });
    }

    function refresh () {
      vm.gridConfig[RECORD_TYPE_ALL].data.splice(0);
      vm.loaded.report = false;

      loadReportData(angular.copy(vm.reportParams), true)
        .catch(function (reason) {
          psNotification.error(reason);
        })
        .finally(function () {
          ready();
        });
    }

    function resetFilter () {
      vm.gridConfig[RECORD_TYPE_ALL].data.splice(0);
      vm.loaded.report = false;
      vm.reportParams = getDefaultParameters(vm.report);

      setFilter(vm.reportParams);
      loadReportData(angular.copy(vm.reportParams))
        .catch(function (reason) {
          psNotification.error(reason);
        })
        .finally(function () {
          ready();
        });
    }

    function setFilter (params) {
      var allowedRecordTypes = ((vm.config.parameters && vm.config.parameters.record_types) ? vm.config.parameters.record_types : []);
      var blacklist = ['limit', 'patient_id', 'summary', 'dicom_check_enabled'];
      var filter = {};

      vm.filters = [];
      vm.filtersSet = true;
      vm.recordTypes = [];

      angular.forEach(params, function (param, paramName) {
        if (param.visible === false || blacklist.indexOf(paramName) !== -1) {
          return;// continue
        }
        if (paramName === 'record_types') {
          angular.forEach(param.choices, function (choice) {
            if (allowedRecordTypes.indexOf(choice) >= 0) {
              vm.recordTypes.push({ label: choice, value: choice });
              vm.selectedRecordTypes[choice] = true;
            }
          });
          return;// continue
        }
        filter = {
          id: param.name,
          label: param.display_name,
          value: param.value,
          choices: param.choices,
          inputType: param.inputType,
          attributes: param.attributes,
          description: param.description
        };
        vm.formFilters[param.name] = { value: param.value };
        if (param.inputType === psReports.INPUT_TYPES.DATE) {
          filter.dateOptions = {
            maxDate: new Date(),
            minDate: new Date(1900, 1, 1)
          };
          vm.formFilters[param.name] = { value: moment(param.value, 'YYYY-MM-DD').toDate() };
        }
        vm.filters.push(filter);
      });
    }

    function setGridConfig () {
      vm.config.grids2.forEach(function (grid, idx) {
        var gridDef = vhrConfigSrv.raw.grids[grid.grid] || { fieldOrder: [] };
        var gridConfig = gridDef.uiGridConfig || {};
        var gridRoute;
        var gridType = RECORD_TYPE_ALL;

        if (Array.isArray(vm.config.gridPages) && vm.config.gridPages.length) {
          gridRoute = vhrConfigSrv.getRouteByPage(vm.config.gridPages[idx]);
        }

        vm.gridConfig[gridType] = {};
        vm.gridRouting[gridType] = (gridRoute || { pageTitle: '', stateName: '' });
        vm.grids.push(gridType);

        gridConfig = vhrGridSrv.getGridConfig($scope, gridType, vhrGridSrv.CONFIG.RECORD_LIST, gridConfig, gridDef.fieldOrder);
        angular.extend(vm.gridConfig[gridType], gridConfig);
        if (typeof gridDef.isSelectable === 'undefined' || gridDef.isSelectable) {
          vhrGridSrv.setOnRegisterApi($scope, vm.gridConfig, vm.gridApi, gridType);
        } else {
          vm.gridConfig[gridType].enableRowHeaderSelection = false;
          vm.gridConfig[gridType].enableFullRowSeleciton = false;
          vhrApp.enableModes(false);
        }
      });
      $scope.$on('vhrModeChanged', function (evt, mode) { setGridRowSelections(mode); });
    }

    function setGridRowSelections (mode) {
      switch (mode) {
        case MODE.PRINT:
          angular.forEach(vm.gridConfig, function (ignored, gridType) {
            vhrGridSrv.selectRowsInPrintQueue(vm.gridApi[gridType], vm.gridConfig[gridType]);
          });
          break;
        case MODE.VIEW:
          angular.forEach(vm.gridConfig, function (ignored, gridType) {
            vhrGridSrv.selectRowsInRecentViews(vm.gridApi[gridType]);
          });
          break;
        default:
          // Nothing to do.
          break;
      }
    }

    function submitFilter () {
      // Wait for the datepicker to update its input.
      $timeout(function () {
        var params = angular.merge({}, vm.report.parameters, vm.formFilters);

        if (vm.reportParams.record_types) {
          params.record_types.value = [];
          angular.forEach(vm.selectedRecordTypes, function (isTrue, type) {
            if (isTrue) {
              params.record_types.value.push(type);
            }
          });
        }
        // Check vhr-app.json for limit value from configured parameters,
        // if none, check the report parameters for limit value,
        // if none, return -1.
        params.limit = (vm.config.parameters && vm.config.parameters.limit != null ? vm.config.parameters.limit : vm.report.parameters.limit.default || -1);

        angular.forEach(params, function (param) {
          if (param.type === psReports.PARAM_TYPES.DATE) {
            param.value = moment(param.value).format('YYYY-MM-DD');
          }
        });
        vm.gridConfig[RECORD_TYPE_ALL].data.splice(0);
        vm.loaded.report = false;
        loadReportData(params, true)
          .catch(function (reason) {
            psNotification.error(reason);
          })
          .finally(function () {
            ready();
          });
      }, 550);
    }
  }
})();
