(function () {
  'use strict';

  angular.module('admin')
    .controller('BulkAddUsersModalCtrl', BulkAddUsersModalCtrl);

  BulkAddUsersModalCtrl.$inject = [
    '$filter', '$http', '$mdDialog', '$q', '$scope', '$window',
    'ArrayFnSrv', 'DownloaderSrv', 'GroupsSrv', 'modalProvider', 'searchApiSrv', 'UsersSrv'
  ];
  function BulkAddUsersModalCtrl (
    $filter, $http, $mdDialog, $q, $scope, $window,
    ArrayFnSrv, DownloaderSrv, GroupsSrv, modalProvider, searchApiSrv, UsersSrv
  ) {
    modalProvider = modalProvider || 'default';

    var vm = this;
    var FLD_USERNAME = 'username';
    var FLD_PASSWORD = 'password';
    var FLD_FULLNAME = 'fullname';
    var allGroups = [];
    var origGroups = [];

    // ---------------------------------
    // Steps
    // ---------------------------------
    vm.step = 0;
    vm.steps = [
      {
        title: 'Step 1: Enter Data',
        templateUrl: './admin/users/modals/_bulkAddUserDataModal.html',
        prevDisabled: true,
        nextDisabled: true,
        nextLabel: 'Next'
      }, {
        title: 'Step 2: Parse Data',
        templateUrl: './admin/users/modals/_bulkAddUserParsingModal.html',
        prevDisabled: false,
        nextDisabled: false,
        nextLabel: 'Next'
      }, {
        title: 'Step 3: Match Fields',
        templateUrl: './admin/users/modals/_bulkAddUserFieldsModal.html',
        prevDisabled: false,
        nextDisabled: true,
        nextLabel: 'Next'
      }, {
        title: 'Step 4: Add Groups',
        templateUrl: './admin/users/modals/_bulkAddUserGroupsModal.html',
        prevDisabled: false,
        nextDisabled: false,
        nextLabel: 'Next'
      }, {
        title: 'Step 5: Review',
        templateUrl: './admin/users/modals/_bulkAddUserPreviewModal.html',
        prevDisabled: false,
        nextDisabled: false,
        nextLabel: 'Add Users'
      }, {
        title: 'Step 6: Results',
        templateUrl: './admin/users/modals/_bulkAddUserResultsModal.html',
        prevDisabled: true,
        nextDisabled: false,
        nextLabel: 'Close'
      }
    ];

    vm.cancel = $mdDialog.cancel;
    vm.nextStep = nextStep;
    vm.prevStep = prevStep;

    // ---------------------------------
    // Step: Enter CSV data
    // ---------------------------------
    vm.csvColumns = 3;
    vm.csvHeaders = false;
    vm.handleDrag = handleDrag;
    vm.handleDrop = handleDrop;
    vm.csvRaw = '';
    vm.csvParsed = null;

    vm.changeCols = changeCols;

    // ---------------------------------
    // Step: Parse CSV data
    // ---------------------------------

    // ---------------------------------
    // Step: Match fields
    // ---------------------------------
    $scope.FLD_USERNAME = FLD_USERNAME;
    $scope.FLD_PASSWORD = FLD_PASSWORD;
    $scope.FLD_FULLNAME = FLD_FULLNAME;
    vm.userField = FLD_USERNAME;

    vm.csvFields = [];
    vm.usernameFields = [];
    vm.fullnameFields = [];
    vm.passwordFields = [];
    vm.csvPreview = '"","",""';

    vm.previewCsv = previewCsv;
    vm.previewCsvField = previewCsvField;

    // ---------------------------------
    // Step: Add groups
    // ---------------------------------
    vm.loaded = { list: false };
    vm.excludedGroups = [];
    vm.filterAvailableGroups = '';
    vm.filterExistingGroups = '';
    vm.includedGroups = [];

    vm.addAllGroups = addAllGroups;
    vm.addGroup = addGroup;
    vm.clearAvailableGroupsFilter = clearAvailableGroupsFilter;
    vm.clearExistingGroupsFilter = clearExistingGroupsFilter;
    vm.removeAllGroups = removeAllGroups;
    vm.removeGroup = removeGroup;
    vm.updateUserGroups = updateUserGroups;

    // ---------------------------------
    // Step: Review
    // ---------------------------------
    vm.requestGroups = [];
    vm.requestUsers = [];

    // ---------------------------------
    // Step: Results
    // ---------------------------------
    vm.requestComplete = false;
    vm.requestDetails = [];
    vm.requestNotices = [];

    vm.downloadCSV = downloadCSV;

    // ---------------------------------
    // Activate
    // ---------------------------------

    GroupsSrv.getList(true)
      .then(function (groupList) {
        allGroups = [];
        groupList.forEach(function (group) {
          if (group.provider === modalProvider) {
            allGroups.push(group.name);
          }
        });
        vm.excludedGroups = ArrayFnSrv.diff(allGroups, origGroups).sort(ArrayFnSrv.sortNames);
      })
      .finally(function () {
        vm.loaded.list = true;
      });

    $scope.$watch(function () { return vm.csvRaw; }, function (curr) {
      vm.steps[0].nextDisabled = !curr;
    });

    $scope.$watchGroup([
      function () { return vm.usernameFields.length; },
      function () { return vm.fullnameFields.length; },
      function () { return vm.passwordFields.length; }
    ], function (curr) {
      if (curr && curr[0] && curr[1] && curr[2]) {
        vm.steps[2].nextDisabled = false;
      }
    });

    // ---------------------------------
    // Steps
    // ---------------------------------

    function nextStep () {
      switch (++vm.step) {
        case 0:// data
          // Unreachable
          break;
        case 1:// parsing
          parse(vm.csvRaw);
          break;
        case 2:// fields
          vm.usernameFields = [];
          vm.passwordFields = [];
          vm.fullnameFields = [];
          vm.csvPreview = '"","",""';
          break;
        case 3:// groups
          break;
        case 4:// preview
          vm.requestGroups = vm.includedGroups;
          vm.requestUsers = [];

          vm.csvParsed.forEach(function (csv) {
            vm.requestUsers.push({
              username: vm.usernameFields.map(function (f) { return csv[f.index]; }).join(' '),
              password: vm.passwordFields.map(function (f) { return csv[f.index]; }).join(' '),
              name: vm.fullnameFields.map(function (f) { return csv[f.index]; }).join(' ')
            });
          });
          break;
        case 5:// results
          vm.requestComplete = false;
          vm.requestNotices = [];
          bulkAddUsers();
          break;
        case 6:// close
          $mdDialog.hide();
          break;
        default:// squelch lint
      }
    }

    function prevStep () {
      switch (--vm.step) {
        case 0:
          vm.csvErrors = [];
          break;
        case 1:
          break;
        case 2:
          break;
        case 3:
          break;
        case 4:
          break;
        case 5:
          break;
        case 6:
          // Unreachable
          break;
        default:// squelch lint
      }
    }

    // ---------------------------------
    // Step: Enter CSV data
    // ---------------------------------

    function changeCols (amt) {
      vm.csvColumns += amt;
      if (vm.csvColumns < 3) {
        vm.csvColumns = 3;
      }
    }

    function handleDrag ($e) {
      $e.stopPropagation();
      $e.preventDefault();
      switch ($e.type) {
        case 'drag': break;
        case 'dragend': break;
        case 'dragenter': angular.element($e.target).css('border-color', 'rgb(66,165,245)'); break;
        case 'dragexit': break;
        case 'dragleave': angular.element($e.target).css('border-color', 'initial'); break;
        case 'dragover': break;// DRAGOVER MUST BE NERFED FOR DROP TO WORK!
        case 'dragstart': break;
        default:// squelch lint
      }
    }

    function handleDrop ($e) { // eslint-disable-line complexity
      var CSV_ERROR_LOAD = 'There was a problem loading the CSV file.';
      var ev = $e.originalEvent;
      var fileReader = new FileReader();

      fileReader.onloadstart = function (readerEvent) { // eslint-disable-line no-unused-vars
        $scope.$applyAsync(function () { vm.csvRaw = 'Loading...'; });
      };
      // fileReader.onprogress = function(readerEvent) {// eslint-disable-line no-unused-vars
      //   $scope.$applyAsync(function() { vm.csvRaw += '.'; });
      // }
      fileReader.onerror = function (readerEvent) { // eslint-disable-line no-unused-vars
        $scope.$applyAsync(function () { vm.csvRaw = CSV_ERROR_LOAD; });
      };
      fileReader.onload = function (readerEvent) {
        $scope.$applyAsync(function () { vm.csvRaw = readerEvent.target.result; });
      };
      // fileReader.onloadend = function(readerEvent) {// eslint-disable-line no-unused-vars
      //   // Load ended, successful or not.
      // }

      $e.stopPropagation();
      $e.preventDefault();
      angular.element($e.target).css('border-color', 'initial');

      var i;
      var ii;

      if (ev.dataTransfer.items) { // Newer DnD interface
        for (i = 0, ii = ev.dataTransfer.items.length; i < ii; ++i) {
          if (ev.dataTransfer.items[i].kind === 'file' && ev.dataTransfer.items[i].type === 'text/csv') {
            try {
              fileReader.readAsText(ev.dataTransfer.items[i].getAsFile());
            } catch (e) { vm.csvRaw = CSV_ERROR_LOAD; }
            break;
          } else if (ev.dataTransfer.items[i].kind === 'string') {
            ev.dataTransfer.items[i].getAsString(function (str) {
              $scope.$apply(function () { vm.csvRaw = str; });
            });
            break;
          }
        }
      } else if (ev.dataTransfer.files.length) { // Older DnD file interface
        for (i = 0, ii = ev.dataTransfer.files.length; i < ii; ++i) {
          if (ev.dataTransfer.files[i].type === 'text/csv') {
            try {
              fileReader.readAsText(ev.dataTransfer.files[i]);
            } catch (e) { vm.csvRaw = CSV_ERROR_LOAD; }
            break;
          }
        }
      } else { // Older DnD text interface
        vm.csvRaw = ev.dataTransfer.getData('text/plain');
      }
    }

    // ---------------------------------
    // Step: Parse CSV data
    // ---------------------------------

    function parse (raw) {
      var csv = raw.replace(/\r\n|\r/g, '\n');
      var hdrs = [];

      if (!vm.csvHeaders) {
        // Attach a header row for better parsing.
        for (var i = 0, ii = vm.csvColumns; i < ii; ++i) {
          hdrs[i] = 'Column ' + (i + 1);
        }
        csv = hdrs.join(',') + '\n' + csv;
      }

      $window.Papa.parse(csv, {
        complete: parseComplete,
        error: parseError, // Unused since we aren't parsing a file.
        fastMode: false,
        header: true,
        skipEmptyLines: 'greedy',
        worker: true
      });
    }

    function parseComplete (result) {
      // https://www.papaparse.com/docs#results

      // {
      //   data:   // array of parsed data
      //   errors: // array of errors
      //   meta:   // object with extra info
      // }

      // .data (header: true)
      // [
      //   {
      //     "Column 1": "foo",
      //     "Column 2": "bar"
      //   },
      //   {
      //     "Column 1": "abc",
      //     "Column 2": "def"
      //   }
      // ]

      // .data (header: false)
      // [
      //   ["foo", "bar"],
      //   ["abc", "def"]
      // ]

      // .errors
      // [{
      //   type: "",     // A generalization of the error
      //   code: "",     // Standardized error code
      //   message: "",  // Human-readable details
      //   row: 0,       // Row index of parsed data where error is
      // }]

      vm.csvAborted = !!result.meta.aborted;
      vm.csvErrors = result.errors.sort(function (a, b) { return a.row - b.row; });
      vm.csvParsed = result.data;

      if (vm.csvParsed.length && !vm.csvAborted) {
        vm.csvFields = Object.keys(result.data[0]).reduce(function (acc, key) {
          if (key !== '__parsed_extra') {
            acc.push({ index: '' + key, label: '' + key, value: result.data[0][key] });
          }
          return acc;
        }, []);
      }

      vm.steps[1].nextDisabled = vm.csvAborted;
      $scope.$applyAsync();
    }

    function parseError () {
      // https://www.papaparse.com/docs#results
      // Called if Papaparse internal FileReader encounters an error.
      vm.csvParsed = false;
    }

    // ---------------------------------
    // Step: Match fields
    // ---------------------------------

    function previewCsv (userField) {
      if (userField) {
        vm.userField = userField;
      }

      // BEWARE mdChips + ngRequired causing the model to be undefined, and other misbehaviors.
      // ngRequired causes mdChips to disappear its model when 2+ chips are removed via the UI.
      // These lines are here just in case someone turns on ngRequired again.
      vm.usernameFields = vm.usernameFields || [];
      vm.fullnameFields = vm.fullnameFields || [];
      vm.passwordFields = vm.passwordFields || [];

      vm.csvPreview = [];
      vm.csvPreview.push('"<b>' + vm.usernameFields.map(function (f) { return f.value.replace(/"/g, '""'); }).join(' ') + '</b>"');
      vm.csvPreview.push('"<b>' + vm.fullnameFields.map(function (f) { return f.value.replace(/"/g, '""'); }).join(' ') + '</b>"');
      vm.csvPreview.push('"<b>' + vm.passwordFields.map(function (f) { return f.value.replace(/"/g, '""'); }).join(' ') + '</b>"');
      vm.csvPreview = vm.csvPreview.length ? vm.csvPreview.join(',') : '"","",""';
    }

    function previewCsvField (csvField) {
      var userFields;

      switch (vm.userField) {
        case FLD_USERNAME:
          userFields = vm.usernameFields;
          break;
        case FLD_FULLNAME:
          userFields = vm.fullnameFields;
          break;
        case FLD_PASSWORD:
          userFields = vm.passwordFields;
          break;
        default:// squelch lint
      }

      if (userFields && userFields.indexOf(csvField) === -1) {
        userFields.push(csvField);
        $scope.$applyAsync();
      }

      previewCsv();
    }

    // ---------------------------------
    // Step: Add groups
    // ---------------------------------

    function addAllGroups () {
      var visibleGroups = $filter('filter')(vm.excludedGroups, { $: vm.filterAvailableGroups });

      vm.excludedGroups = ArrayFnSrv.diff(vm.excludedGroups, visibleGroups).sort(ArrayFnSrv.sortNames);
      vm.includedGroups = vm.includedGroups.concat(visibleGroups).sort(ArrayFnSrv.sortNames);
      clearAvailableGroupsFilter();
    }

    function addGroup (group) {
      vm.excludedGroups.splice(vm.excludedGroups.indexOf(group), 1);
      vm.includedGroups.push(group);
      vm.includedGroups.sort(ArrayFnSrv.sortNames);
    }

    function clearAvailableGroupsFilter () {
      vm.filterAvailableGroups = '';
    }

    function clearExistingGroupsFilter () {
      vm.filterExistingGroups = '';
    }

    function removeAllGroups () {
      var visibleGroups = $filter('filter')(vm.includedGroups, { $: vm.filterExistingGroups });

      vm.includedGroups = ArrayFnSrv.diff(vm.includedGroups, visibleGroups).sort(ArrayFnSrv.sortNames);
      vm.excludedGroups = vm.excludedGroups.concat(visibleGroups).sort(ArrayFnSrv.sortNames);
      clearExistingGroupsFilter();
    }

    function removeGroup (group) {
      vm.excludedGroups.push(group);
      vm.excludedGroups.sort(ArrayFnSrv.sortNames);
      vm.includedGroups.splice(vm.includedGroups.indexOf(group), 1);
    }

    function updateUserGroups () {
      var added = ArrayFnSrv.diff(vm.includedGroups, origGroups);
      var removed = ArrayFnSrv.diff(origGroups, vm.includedGroups);
      return (added.length || removed.length) ? { add: added, delete: removed } : [];
    }

    // ---------------------------------
    // Step: Review
    // ---------------------------------

    // ---------------------------------
    // Step: Results
    // ---------------------------------

    function bulkAddUsers () {
      var MSG_CREATE = 'Creating...';
      var MSG_GROUP = 'Adding to groups...';
      var MSG_SUCCESS = 'Success.';
      var ERR_CREATE = 'Failed to create.';
      var ERR_GROUP = 'Failed to add to groups.';

      var createUser = [];
      var groupUser = [];
      var promises = [];
      var sessions = [];

      var apiGroups = updateUserGroups();

      vm.requestUsers.forEach(function (usr, idx) {
        var deferred = $q.defer();

        vm.requestDetails.push('');
        vm.requestNotices.push('');

        createUser.push({
          method: 'POST',
          url: '/appliance/users/default',
          data: angular.copy(usr),
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        });

        groupUser.push([
          apiGroups,
          { provider: modalProvider, username: usr.username }
        ]);

        $q.resolve()
          .then(function () {
            deferred.notify(MSG_CREATE);
            return $http(createUser[idx])
              .then(function () { sessions.push('user_' + usr.username + '@' + modalProvider); })
              .catch(function (e) { vm.requestDetails[idx] = e.data; return $q.reject(ERR_CREATE); });
          })
          .then(function () {
            deferred.notify(MSG_GROUP);
            return UsersSrv.updateUserGroups.apply(null, groupUser[idx])
              .catch(function (e) { vm.requestDetails[idx] = e.data; return $q.reject(ERR_GROUP); });
          })
          .then(
            function () { deferred.resolve(MSG_SUCCESS); },
            function (ERR) { deferred.reject(ERR); }
          );

        promises.push(deferred.promise.then(
          function (MSG) { vm.requestNotices[idx] = MSG; },
          function (ERR) { vm.requestNotices[idx] = ERR; },
          function (MSG) { vm.requestNotices[idx] = MSG; }
        ));
      });

      $q.all(promises)
        .finally(function () {
          vm.requestComplete = true;
          searchApiSrv.ensureSession(sessions);
        });
    }

    function downloadCSV () {
      vm.requestDetails.forEach(function (response, idx, arr) {
        if (/<html>/i.test(response)) {
          arr[idx] = /<title>(.+?)<\/title>/i.exec(response)[1];
        }
      });

      var collection = vm.requestUsers.map(function (usr, idx) {
        return angular.extend({}, usr, { status: vm.requestDetails[idx] });
      });

      return DownloaderSrv.downloadCsv(collection, {
        columns: ['username', 'password', 'name', 'status'],
        filename: 'bulkUsers.csv',
        headers: {
          username: 'Username',
          password: 'Password',
          name: 'Full Name',
          status: 'Status'
        }
      });
    }

    //= ================================
    // Private interface
    //= ================================
  }
})();
