(function () {
  'use strict';

  angular
    .module('imat')
    .config(config);

  config.$inject = ['$mdThemingProvider', '$mdIconProvider', '$sceDelegateProvider', 'imatThemeProvider'];

  function config ($mdThemingProvider, $mdIconProvider, $sceDelegateProvider, imatThemeProvider) {
    var theme = imatThemeProvider.getTheme();
    var palettes = imatThemeProvider.getPalettes();

    angular.forEach(palettes, function (definition, palette) {
      $mdThemingProvider.definePalette(palette, definition);
    });

    $mdThemingProvider.theme('default')
      .primaryPalette(theme.primary.name, theme.primary.definition)
      .accentPalette(theme.accent.name, theme.accent.definition)
      .warnPalette(theme.warn.name, theme.warn.definition)
      .backgroundPalette(theme.background.name, theme.background.definition);

    /* etHIN teal/blue-grey */
    $mdIconProvider
      .fontSet('md', 'material-icons')
      .iconSet('ps', '/ui/_shared/icons/ps.svg');

    $sceDelegateProvider.resourceUrlWhitelist(['self']);
  }
})();
