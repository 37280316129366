(function () {
  'use strict';

  angular
    .module('admin')
    .controller('ReportContainersCtrl', ReportContainersCtrl);

  ReportContainersCtrl.$inject = ['$mdDialog', '$mdSidenav', '$state', 'ContainersSrv', 'psNotification'];

  function ReportContainersCtrl ($mdDialog, $mdSidenav, $state, ContainersSrv, psNotification) {
    var vm = this;

    vm.containers = [];
    vm.loading = false;
    vm.selected = {};
    vm.searchText = '';

    vm.clearSelection = clearSelection;
    vm.getContainer = getContainer;
    vm.getContainers = getContainers;
    vm.getRunningCount = getRunningCount;
    vm.loadContainers = loadContainers;
    vm.parseSeconds = parseSeconds;
    vm.parseTime = parseTime;
    vm.resetFilters = resetFilters;
    vm.stop = stop;
    vm.textFilterList = textFilterList;
    vm.viewReports = viewReports;

    activate();

    function activate () {
      loadContainers();
    }

    function clearSelection () {
      ContainersSrv.clearSelected();
      vm.selected = ContainersSrv.getSelected();
      $mdSidenav('containerDetails').close();
    }

    function getContainer (container) {
      ContainersSrv.setSelected(container);
      vm.selected = ContainersSrv.getSelected();

      $mdSidenav('containerDetails').open();
    }

    function getContainers (force) {
      return ContainersSrv.getContainersList(force)
        .catch(function () {
          psNotification.error('Unable to retrieve the containers.');
        });
    }

    function getRunningCount (running) {
      const parsed = parseInt(running, 10);
      return (!isNaN(parsed) && parsed >= 0) ? parsed : 'Unknown';
    }

    function loadContainers () {
      vm.loading = true;
      getContainers(true)
        .then(function (containers) {
          vm.containers = containers;

          // Reloads selected container if there is one
          vm.selected = ContainersSrv.getSelected();
          if (vm.selected.id) {
            $mdSidenav('containerDetails').open();
          }
        })
        .finally(function () {
          vm.loading = false;
        });
    }

    function parseSeconds (seconds) {
      if (!seconds) {
        return 'unknown';
      }
      return moment.duration(seconds, 'seconds').humanize();
    }

    function parseTime (value) {
      if (angular.isString(value)) {
        return value;
      }

      var arr = [];

      // Break down time into hours, minutes, & seconds
      if (value / 3600 > 1) {
        arr.push(Math.floor(value / 3600) + ' h');
        value = value % 3600;
      }

      if (value / 60 > 1) {
        arr.push(Math.floor(value / 60) + 'm');
        value = value % 60;
      }

      arr.push(Math.floor(value) + 's');

      return arr.join(' ');
    }

    function resetFilters () {
      vm.searchText = '';
    }

    function splitAtLastIndex(strToSplit, charToSplitAt) {
      let parts = [];
      let index = strToSplit.lastIndexOf(charToSplitAt);
      parts.push(strToSplit.slice(0, index));
      parts.push(strToSplit.slice(index + 1))
      return parts;
    }

    function stop (locals) {
      var parts = splitAtLastIndex(locals.id, '@');
      var username = parts[0];
      var provider = parts[1];
      var dialog = $mdDialog.confirm({
        title: 'Stop Container?',
        textContent: 'The container ' + locals.id + ' will be shutdown.',
        ok: 'Stop',
        cancel: 'Cancel'
      });

      $mdDialog.show(dialog)
        .then(function () {
          $mdDialog.show({
            template:
                '<md-dialog class="layout-padding">' +
                  '<md-dialog-content class="processing-dialog text-center">' +
                    '<h1 class="md-headline">Processing...</h1>' +
                    '<material-loader></material-loader>' +
                  '</md-dialog-content>' +
                '</md-dialog>'
          });

          ContainersSrv.setContainerState(provider, username, 'off')
            .then(function (response) {
              if (response.data.status === 'success') {
                psNotification.success('Container has shut down.');
              } else {
                psNotification.error('An unknown response was received. The container may not exist.');
              }
              loadContainers();
            })
            .catch(function () {
              psNotification.error('Unable to process the shutdown request.');
            })
            .finally(function () {
              $mdDialog.hide();
              clearSelection();
            });
        })
        .catch(function () {
          // dialog canceled
        });
    }

    function textFilterList (container) {
      if (!vm.searchText) {
        return true;
      }
      return (container.id.indexOf(vm.searchText.toLowerCase()) !== -1) ? true : (container.id.indexOf(vm.searchText.toLowerCase()) !== -1);
    }

    function viewReports (user) {
      $state.go('app.admin.report-containers.container', { id: user.id });
    }
  }
})();
