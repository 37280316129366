(function () {
  'use strict';

  angular.module('imat')
    .controller('ImatCtrl', ImatCtrl);

  ImatCtrl.$inject = ['$location', '$scope', '$state', '$transitions', '$window', 'browserSrv', 'imatConfig', 'releaseSrv', 'userSession', '$mdDialog'];

  function ImatCtrl ($location, $scope, $state, $transitions, $window, browserSrv, imatConfig, releaseSrv, userSession, $mdDialog) {
    var vm = this;
    var DOCS_LINK = imatConfig.get('customerHelpLink') || '/ui/documentation/';
    var STATE_ACCOUNT = 'app.account.default';
    var deregisterStateChangeBefore = $transitions.onBefore({ to: 'app.**' }, stateChangeBefore);
    var deregisterStateChangeSuccess = $transitions.onSuccess({ to: 'app.**' }, stateChangeSuccess);
    var hideBetaBanner = imatConfig.get('hideBetaBanner');

    // Properties
    vm.accountLinkEnabled = true;
    vm.copyright = (new Date()).getFullYear();
    vm.feedbackLink = imatConfig.get('feedbackLink') || false;
    vm.isBeta = ($state.current.data && $state.current.data.beta);
    vm.pageLoading = false;
    vm.showBetaMessage = false;
    vm.user = {};
    vm.userLinks = imatConfig.get('footerLinks') || {};
    vm.version = '';
    // Methods
    vm.closeMessage = closeMessage;
    vm.goToAccount = goToAccount;
    vm.goToHelpDocs = goToHelpDocs;
    vm.goToLogout = goToLogout;
    vm.showLegalDialog = showLegalDialog;
    vm.supportedBrowsers = browserSrv.getSupportedBrowsers($state.$current, true).join(', ');
    vm.browserSupported = vm.supportedBrowsers.length ? browserSrv.checkBrowserSupport(browserSrv.getSupportedBrowsers($state.$current)) : true;

    activate();

    // Implementation
    function activate () {
      vm.user = userSession.getUserData();
      vm.showBetaMessage = (!hideBetaBanner && vm.isBeta);
      releaseSrv.getVersionNum()
        .then(function (versionNum) {
          vm.version = versionNum;
        })
        .catch(function () {
          vm.version = 'Unavailable';
        // console.debug("releaseSrv.getVersionNum", error);
        });

      $scope.$on('$destroy', function () {
        deregisterStateChangeBefore();
        deregisterStateChangeSuccess();
      });
    }

    function goToAccount () {
      $state.go(STATE_ACCOUNT, {
        return: '/ui/#' + $location.url()
      });
    }

    function goToHelpDocs () {
      $window.open(DOCS_LINK);
    }

    function goToLogout () {
      userSession.logout();
    }

    function showLegalDialog() {
      $mdDialog.show(
        $mdDialog.alert()
          .clickOutsideToClose(true)
          .title('Legal')
          .htmlContent('Contains technology protected under the following U.S. patents (and others pending) <br><br> 7644082, 7774347, 7774353, 7912840, 8032495, 8037075, 8176052, 8190597, 8266152, 8392426, 8745035, 11200217')
          .ok('Ok')
      );
    }

    function stateChangeBefore (trans) {
      vm.pageLoading = true;
      trans.promise.finally(function () { vm.pageLoading = false; });
    }

    function stateChangeSuccess (trans) {
      vm.isBeta = (trans.to().data && trans.to().data.beta);
      vm.showBetaMessage = (!hideBetaBanner && vm.isBeta);
      vm.accountLinkEnabled = trans.to().name !== STATE_ACCOUNT;

      vm.supportedBrowsers = browserSrv.getSupportedBrowsers(trans.to(), true).join(', ');
      vm.browserSupported = vm.supportedBrowsers.length ? browserSrv.checkBrowserSupport(browserSrv.getSupportedBrowsers(trans.to())) : true;
    }

    function closeMessage () {
      vm.browserSupported = true;
      browserSrv.dismissCheck();
    }
  }
})();
