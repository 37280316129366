(function () {
  'use strict';

  angular.module('admin')
    .directive('adminDashboardDetails', adminDashboardDetails);

  adminDashboardDetails.$inject = [];

  function adminDashboardDetails () {
    var directive = {
      controller: 'AdminDashboardDetailsCtrl',
      controllerAs: 'vm',
      restrict: 'AE',
      scope: {
        activeTab: '@',
        loaded: '=',
        onClone: '&?',
        onClose: '&',
        onDelete: '&',
        onUpdatePriority: '&?',
        priorities: '@?',
        providerCount: '@',
        selected: '=',
        selectedDetail: '='
      },
      templateUrl: '/ui/admin/directives/admin-dashboard-details/admin-dashboard-details.html'
    };

    return directive;
  }
})();
