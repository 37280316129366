(function () {
  /* jshint loopfunc: true */
  'use strict';

  angular
    .module('vhr.directives')
    .directive('vhrPdfPrinter', vhrPdfPrinter);

  vhrPdfPrinter.$inject = ['$q', '$timeout', '$window', 'vhrPrintSrv', 'vhrPdfSrv'];

  function vhrPdfPrinter ($q, $timeout, $window, vhrPrintSrv, vhrPdfSrv) {
    var service = {
      controller: VhrPdfPrinterCtrl,
      controllerAs: 'ctrl',
      link: postLink,
      restrict: 'E',
      scope: false,
      templateUrl: '/ui/vhr/directives/vhrPdf/vhr-pdf-printer.html',
      transclude: true
    };

    VhrPdfPrinterCtrl.$inject = ['$window'];

    return service;

    function VhrPdfPrinterCtrl ($window) { // eslint-disable-line no-shadow
      var vm = this;

      // Properties
      vm.aborted = false;
      vm.canvases = [];
      vm.fitPage = false;
      vm.fitWidth = false;
      vm.pageCount = 0;
      vm.scale = 1;

      // Methods
      vm.clearCanvas = clearCanvas;
      vm.renderPage = renderPage;

      function clearCanvas () {
        vm.canvases.forEach(function (canvas) {
          angular.element(canvas).remove();
        });
      }

      function renderPage (canvas, pdf, pageNum, consumedVerticalSpace) {
        return pdf.getPage(pageNum)
          .then(function (page) {
            var clientRect,
              height, // eslint-disable-line no-unused-vars
              parent,
              viewport;

            if (vm.fitPage) {
              height = $window.innerHeight || $window.document.documentElement.offsetHeight;
              height = (height - (consumedVerticalSpace || 0));
              viewport = page.getViewport(1);
              vm.scale = 1;
            }

            if (vm.fitWidth) {
              parent = angular.element(canvas).parent();
              parent.css({ display: 'block' });
              clientRect = parent[0].getBoundingClientRect();
              viewport = page.getViewport(1);
              vm.scale = Math.max(vhrPdfSrv.PDF_SCALE_MIN, (clientRect.width / viewport.width));
            }

            viewport = page.getViewport(vm.scale);
            vhrPdfSrv.setCanvasDimensions($window, canvas, viewport.width, viewport.height);
            return page.render({ canvasContext: canvas.getContext('2d'), viewport: viewport, intent: 'print' });
          });
      }
    }

    function postLink (scope, element, attrs, ctrl, transcludeFn) {
      var offVhrPrinted = angular.noop; // eslint-disable-line no-unused-vars
      var transcopes = [];

      reset();
      function reset () {
        ctrl.aborted = false;
        ctrl.canvases = [];
        ctrl.fitPage = scope.pdfFitPage;
        ctrl.fitWidth = scope.pdfFitWidth;
        ctrl.pageCount = 0;
        ctrl.scale = scope.pdfScale;
      }

      scope.$watch('pdfAborted', function (curr) {
        if (curr) {
          scope.$applyAsync(function () {
            ctrl.aborted = true;
            element.append('<div>There was a problem loading the PDF.</div>');
          });
        }
      });

      scope.$watch('pdfLoaded', function (curr) {
        var i;

        if (!curr) {
          // offVhrPrinted();
          ctrl.clearCanvas();
          reset();
          return;
        }

        ctrl.pdf = scope.pdfDocument;
        ctrl.pageCount = scope.pdfDocument.numPages;
        element.empty();

        for (i = 0; i < ctrl.pageCount; ++i) {
          transcludeFn(function (tnodes, tscope) {
            var page = angular.element('<div class="vhr-pdf-page" style="page-break-inside: avoid;">');
            var wrap = angular.element('<div class="vhr-pdf-page-canvas" style="text-align: center;">');
            var canv = angular.element('<canvas>');

            element.append(page.append(tnodes).append(wrap.append(canv)));
            transcopes.push(tscope);
          });
        }

        ctrl.canvases = nodeListToArray(element[0].querySelectorAll('canvas'));
      });

      scope.$watch('pdfReady', function (curr) {
        var POINTS_PER_INCH = 72;
        var POINTS_PER_PIXEL = 0.75;
        var consumedVerticalSpace;
        var q = [];
        var tmplHeight;
        var verticalMargins = 0.63;// Chrome `Default` margins WITHOUT `Headers and footers`?
        verticalMargins = 1.16;// Chrome `Default` margins WITH `Headers and footers`?

        if (curr) {
          tmplHeight = ctrl.canvases[0].getBoundingClientRect().top - scope.pdfElement.getBoundingClientRect().top;
          // Convert margins to points, then to pixels.
          consumedVerticalSpace = tmplHeight + (verticalMargins * POINTS_PER_INCH * POINTS_PER_PIXEL);

          ctrl.canvases.forEach(function (canvas, idx) {
            q.push(ctrl.renderPage(canvas, ctrl.pdf, idx + 1, consumedVerticalSpace));
          });
          $q.all(q).finally(function () {
            vhrPrintSrv.exitFakePrintMedia();
            vhrPrintSrv.printed();
          });
        }
      });

      offVhrPrinted = scope.$on('vhrPrinted', function () {
        if (scope.pdfReady) {
          vhrPrintSrv.printed();
        } else {
          vhrPrintSrv.enterFakePrintMedia();// Should result in pdfReady. Ensure the vhr-pdf element becomes visible (display:block).
        }
      });

      function nodeListToArray (nodeList) {
        var i; var arr = [];

        for (i = 0; i < nodeList.length; ++i) {
          arr.push(nodeList[i]);
        }
        return arr;
      }

      // -------------------------------

      scope.$on('$destroy', function () {
        transcopes.forEach(function (tscope) {
          tscope.$destroy();
        });
        transcopes.splice(0);
        vhrPrintSrv.cancel();
      });
    }
  }
})();
