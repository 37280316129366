(function () {
  'use strict';

  angular
    .module('imat.services')
    .factory('releaseApiSrv', releaseApiSrv);

  releaseApiSrv.$inject = ['$http'];

  function releaseApiSrv ($http) {
    var baseUrl = '/api/release';

    var service = {
      getComponents: getComponents,
      getVersion: getVersion
    };

    return service;

    function getComponents () {
      return $http.get([baseUrl, 'components'].join('/'));
    }

    function getVersion () {
      return $http.get([baseUrl, 'version'].join('/'));
    }
  }
})();
