(function () {
  'use strict';

  angular.module('imat.viewer')
    .controller('ViewerParametersModalCtrl', ViewerParametersModalCtrl);

  ViewerParametersModalCtrl.$inject = ['_moment', '$scope', '$mdDialog', 'dateTimeSrv', 'psReports', 'report', 'reportName'];

  function ViewerParametersModalCtrl (_moment, $scope, $mdDialog, dateTimeSrv, psReports, report, reportName) {
    var vm = this;
    var INPUT_TYPES = psReports.INPUT_TYPES;

    $scope.INPUT_TYPES = INPUT_TYPES;
    $scope.formParams = [];
    $scope.params = {};
    $scope.reportId = report.id;
    $scope.reportName = reportName;
    vm.workspace = 'report-viewer';

    $scope.cancel = $mdDialog.cancel;
    $scope.getLimits = getLimits;
    $scope.getSelectedText = getSelectedText;
    $scope.submit = submit;

    activate();

    function activate () {
      if (!report || typeof report.getParameters !== 'function') {
        return false;
      }
      var lastParams = report.getLastParameters(vm.workspace, true);
      var defaultParams = report.getParameterDefaults(true);
      var parameters = angular.extend({}, defaultParams, lastParams);
      $scope.params = parameters;

      angular.forEach(parameters, function (param) {
        if (param.inputType === INPUT_TYPES.DATE) {
          param.min = dateTimeSrv.getIsoDate(param.min);
          param.max = dateTimeSrv.getIsoDate(param.max);
          param.value = _moment(param.value).toDate();
        }
        $scope.formParams.push(param);
      });
    }

    function getLimits (formParams) {
      return [Math.ceil(formParams.length / 2), -Math.floor(formParams.length / 2)];
    }

    function getSelectedText (selection) {
      var text = selection.display_name;
      if (!Array.isArray(selection.value)) {
        return text;
      }
      if (selection.value.length === 1) {
        text = selection.value[0];
      } else if (selection.value.length > 1) {
        text = '"' + selection.value.join('", "') + '"';
      }
      return text;
    }

    function submit () {
      $mdDialog.hide(angular.copy($scope.params));
    }
  }
})();
