(function () {
  'use strict';

  angular.module('imat.http')
    .factory('httpReqFormUrlEncoded', httpReqFormUrlEncoded);

  httpReqFormUrlEncoded.$inject = ['$httpParamSerializer'];

  function httpReqFormUrlEncoded ($httpParamSerializer) {
    return {
      serialize: function (params, headersGetter) {
        if (!/^application\/x-www-form-urlencoded/.test(headersGetter('content-type'))) {
          return params;
        }
        return (typeof params === 'object') ? $httpParamSerializer(params) : params;
      }
    };
  }
})();
