(function () {
  'use strict';

  angular.module('imat', [
    /* Angular modules */
    'ngAnimate',
    'ngMaterial',
    'ngMessages',
    'ngSanitize',

    'imat.components',
    'imat.constants',
    'imat.directives',
    'imat.http',
    'imat.services',

    /* 3rd party modules */
    'ui.router',
    'xml', // Provides the 'xmlHttpInterceptor' service (angular-xml package).

    /* UI Components */
    'admin',
    'account',
    'dashboardApp',
    'imat.viewer',
    'mpiApp',
    'queries',
    'reports',
    'tools',
    'vhr'
  ]);
})();
