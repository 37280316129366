(function () {
  'use strict';

  angular
    .module('vhr.directives')
    .directive('vhrImgViewerPicker', vhrDir);

  vhrDir.$inject = [];

  function vhrDir () {
    var component = {
      restrict: 'E',
      link: postLink,
      scope: {
        href: '='
      },
      templateUrl: '/ui/vhr/directives/vhrImgViewerPicker/vhr-img-viewer-picker.html'
    };

    return component;

    function postLink (scope, element) {
      var href = scope.href;

      if (angular.isArray(href) && href.length === 1) {
        href = href.pop();
      }
      if (!href) {
        element.empty();
      } else if (angular.isString(href)) {
        element.html('<a class="md-button md-headline" href="' + href + '" target="_blank"><i class="material-icons">visibility</i> <span>View Image</span></a>');
      }
      element.removeAttr('href');
    }
  }
})();
