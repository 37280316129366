(function () {
  'use strict';

  angular
    .module('tools')
    .controller('ToolsMainCtrl', ToolsMainCtrl);

  ToolsMainCtrl.$inject = [];

  function ToolsMainCtrl () {
    var vm = this; // eslint-disable-line no-invalid-this

    vm.navLinks = [
      { label: 'Platform Status', state: 'app.tools.platform-status' }
    ];

    activate();

    // Implementation
    function activate () {

    }
  }
})();
