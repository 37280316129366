(function () {
  'use strict';

  angular.module('mpiApp.services')
    .factory('mpiUtilitySrv', mpiUtilitySrv);

  mpiUtilitySrv.$inject = [];

  function mpiUtilitySrv () {
    var service;

    service = {
      processFmrnsByPeriod: processFmrnsByPeriod,
      removeDuplicates: removeDuplicates,
      removeDuplicateFacilities: removeDuplicateFacilities,
      removeDuplicateOlderFacilities: removeDuplicateOlderFacilities,
      removeEmpty: removeEmpty
    };

    return service;

    // -------------------------------
    // Collection Helpers
    // -------------------------------

    function processFmrnsByPeriod (idObjArr) {
      var fmrns = {};
      var now = Date.now();
      var ret = [];

      idObjArr.forEach(function (facObj) { // eslint-disable-line complexity
        var fmrn, start, end;

        facObj.isActive = true;

        // For a given FMRN (unique identifier system + value), we are trying
        // to isolate the identifier that has the most recent period data, and
        // to activate/deactivate the FMRN accordingly. We assume the FMRN is
        // active in all cases (including the absence of period data) except
        // when the most recent date represents an end/deactivation. We ignore
        // future dates and assume start dates always precede end dates.
        // XXX The extract data has a list of active/inactive MRNs, but we
        // do not have access to it via the API (AFAIK).
        fmrn = facObj.value + '@' + facObj.system;

        if (!Object.prototype.hasOwnProperty.call(fmrns, fmrn)) {
          fmrns[fmrn] = facObj;
        }

        if (facObj.period) {
          if (facObj.period.start) {
            start = new Date(facObj.period.start);
          }
          if (facObj.period.end) {
            end = new Date(facObj.period.end);
          }

          // Isolate the identifier that represents the most recent state.
          if (start && start <= now) {
            facObj._recent = start;
          }
          if (end && end < now) {
            facObj._recent = end;
            facObj.isActive = false;
          }
          if (facObj._recent && (!fmrns[fmrn]._recent || facObj._recent > fmrns[fmrn]._recent)) {
            fmrns[fmrn] = facObj;
          }
        }
      });

      Object.keys(fmrns).forEach(function (fmrn) {
        delete fmrns[fmrn]._recent;
        ret.push(fmrns[fmrn]);
      });

      return ret;
    }

    function removeDuplicates (currentValue, index, array) {
      for (var i = 0; i < index; ++i) {
        if (angular.equals(currentValue, array[i])) {
          return false;// Duplicate, exclude it.
        }
      }
      return true;// Unique, include it.
    }

    function removeDuplicateFacilities (currentValue, index, array) { // eslint-disable-line complexity
      // IMAT currently "extracts" facilities from identifiers.
      for (var i = 0; i < index; ++i) {
        if (array[i].system != null && array[i].system === currentValue.system &&
            array[i].value != null && array[i].value === currentValue.value
        ) {
          // Assume duplicate unless KNOWN DIFFERENT type/use values.
          if ((array[i].type == null || currentValue.type == null || angular.equals(array[i].type, currentValue.type)) &&
              (array[i].use == null || currentValue.use == null || angular.equals(array[i].use, currentValue.use))
          ) {
            return false;// Duplicate, exclude it.
          }
        }
      }
      return true;// Unique, include it.
    }

    // eslint-disable-next-line complexity
    function removeDuplicateOlderFacilities (currentValue, index, array) {
      for (var i = 0; i < index; ++i) {
        if (array[i].value != null && array[i].value === currentValue.value) {
          // Check if period is different, and only show newest facility
          if (currentValue.period && array[i].period) {
            return moment(currentValue.period.start || currentValue.period.end).isAfter(array[i].period.start || array[i].period.end);
          }
        }
      }
      return true;// Unique, include it.
    }

    // MpiPersona only
    function removeEmpty (currentValue) {
      return !(currentValue === '' || currentValue == null || Number.isNaN(currentValue));
    }
  }
})();
