(function () {
  'use strict';

  angular.module('vhr.services')
    .service('vhrCxmlMedRequestRecordClass', vhrCxmlMedRequestRecordClass);

  vhrCxmlMedRequestRecordClass.$inject = [
    'VhrCxmlRecordClass'
  ];

  function vhrCxmlMedRequestRecordClass (
    VhrCxmlRecordClass
  ) {
    function CxmlMedRequestRecord (cfg) {
      VhrCxmlRecordClass.call(this, cfg);
      this.controller = 'CxmlMedRequestModalCtrl';
      this.printTemplateUrl = '/ui/vhr/templates/medications/print/med-request.html';
      this.templateUrl = '/ui/vhr/templates/medications/cxml-med-request-modal.html';
    }

    CxmlMedRequestRecord.prototype = Object.create(VhrCxmlRecordClass.prototype);
    CxmlMedRequestRecord.prototype.constructor = CxmlMedRequestRecord;

    CxmlMedRequestRecord.prototype.set = function (raw, setFields) {
      if (setFields === true) {
        setFields = [];
      }
      VhrCxmlRecordClass.prototype.set.call(this, raw, setFields);
    };

    return CxmlMedRequestRecord;
  }
})();
