(function () {
  'use strict';

  angular.module('mpiApp')
    .controller('piEditCtrl', piEditCtrl);

  piEditCtrl.$inject = [
    'FHIR_EXTENSION_URLS', 'FHIR_VALUE_SETS', 'IMAT_FHIR_EXTENSION', 'MPI_FHIR',
    '$filter', '$log', '$mdDialog', '$q', '$scope', '$timeout', '$transition$', '$window',
    'dateTimeSrv', 'fhirExtensionFactory', 'fhirExtensionSrv', 'fhirResourceSrv', 'mpiApiSrv', 'mpiConfigSrv', 'piRecord', 'psNotification'
  ];

  function piEditCtrl (
    FHIR_EXTENSION_URLS, FHIR_VALUE_SETS, IMAT_FHIR_EXTENSION, MPI_FHIR,
    $filter, $log, $mdDialog, $q, $scope, $timeout, $transition$, $window,
    dateTimeSrv, fhirExtensionFactory, fhirExtensionSrv, fhirResourceSrv, mpiApiSrv, mpiConfigSrv, piRecord, psNotification
  ) {
    var vm = this; // eslint-disable-line no-invalid-this
    var PI_CREATE_MISSING_REQUIRED_PARAMS = 'Missing required parameters. A valid PI and MPID is required.';
    var PI_CREATE_SUBMIT_RECORD_UPDATE_SUCCESS = 'PI record updated.';
    var PI_CREATE_SUBMIT_RECORD_UPDATE_ERROR = 'Unable to update the PI record.';
    var PI_CREATE_SUBMIT_INVALID_DIALOG_TITLE = 'Missing required fields';
    var PI_CREATE_SUBMIT_INVALID_DIALOG_CONTENT = 'Verify each identifier has a type selected.';
    var PI_CREATE_SUBMIT_INVALID_DIALOG_ARIA = 'Missing required fields alert dialog';
    var PI_CREATE_SUBMIT_INVALID_DIALOG_OK = 'Ok';
    var PI_CREATE_ERROR_LOADING_PI = 'Unable to load the PI record.';

    // Properties
    vm.commit = false;
    vm.extDefs = [];
    vm.EXTENSION_URLS = FHIR_EXTENSION_URLS;
    vm.MPI_FHIR = MPI_FHIR;
    vm.fhir = {
      system: {
        contactPoint: FHIR_VALUE_SETS.CONTACT_POINT_SYSTEM
      },
      type: {
        address: FHIR_VALUE_SETS.ADDRESS_TYPE,
        extension: fhirExtensionSrv.getDefinitions('Patient'),
        identifier: FHIR_VALUE_SETS.IMAT_IDENTIFIER_TYPE
      },
      use: {
        address: FHIR_VALUE_SETS.ADDRESS_USE,
        contactPoint: FHIR_VALUE_SETS.CONTACT_POINT_USE,
        identifier: FHIR_VALUE_SETS.IDENTIFIER_USE,
        name: FHIR_VALUE_SETS.NAME_USE
      },
      set: {
        languages: FHIR_VALUE_SETS.LANGUAGES,
        maritalStatus: FHIR_VALUE_SETS.MARITAL_STATUS
      }
    };
    vm.loading = true;
    vm.mpidAssignmentType = 'new';
    vm.params = $transition$.params();
    vm.record = null; // PI record to be submitted
    vm.result = null;
    vm.showMoreFields = false; // Not supported yet

    // Methods
    vm.addProperty = addProperty;
    vm.getExtensionTemplateName = getExtensionTemplateName;
    vm.getExtensionValues = getExtensionValues;
    vm.handleExtensionChange = handleExtensionChange;
    vm.removeProperty = removeProperty;
    vm.resetForm = resetForm;
    vm.submitForm = submitForm;
    vm.triggerHistoryBack = triggerHistoryBack;

    $scope.$watch('ctrl.mpidAssignmentType', function (newVal) {
      if (newVal == 'new') { // eslint-disable-line eqeqeq
        vm.mpidAssignExisting = undefined;
      }
    });

    activate();

    function activate () {
    // TODO: fix init values for missing data, e.g., identifier types may be missing but we need the code value (check if type coding[] exists)
      if (vm.params.id && vm.params.mpid) {
        // get PI record values to populate form
        _getPiRecord(vm.params.id)
          .then(function (result) {
            // Copy blank template objects over for missing PI properties
            vm.record = angular.extend(piRecord.getPiTemplate(), result.data);

            // If PI record has missing MRN data, (e.g. there is no vm.record.identifier[].type),
            // then add "type.coding[].system" and "type.coding[].code" to the record to populate the UI's "Type" fields in the Identifiers for MRNs.
            var systemId = mpiConfigSrv.systemId;
            vm.prefix = systemId.replace(/(https?:\/\/.+?)\/.*/, '$1/facility');
            var mrnIdentifier = new RegExp(vm.prefix);
            vm.record.identifier.forEach(function (identifier) {
              if (!identifier.type && identifier.system) {
                if (mrnIdentifier.test(identifier.system)) {
                  identifier.type = { coding: [{ code: MPI_FHIR.CODE.MRN, system: MPI_FHIR.SYS.HL7_IDENTIFIER }] };
                }
              }
              if (!identifier.type) {
                identifier.type = { coding: [{ code: '' }] };
              }
            });

            // Parse to human readable date & time string for view
            vm.record.deceasedDateTime ? vm.record.deceasedDateTime = dateTimeSrv.getLocaleDatetime(vm.record.deceasedDateTime) : false; // eslint-disable-line no-unused-expressions

            var rexFindStrDef = /StructureDefinition/;
            var rexReplaceStrDef = /^.+?\/StructureDefinition\//;

            if (vm.record.extension) {
              if (!angular.isArray(vm.record.extension)) {
                vm.record.extension = [vm.record.extension];
              }
              // Find the definition (or fake one) for each extension.
              vm.record.extension.forEach(function (ext, idx) {
                var extDef = fhirExtensionSrv.getDefinition(ext.url);
                var extName;

                if (!extDef) {
                  extName = ext.url;
                  if (ext.url.match(rexFindStrDef)) {
                    extName = ext.url.replace(rexReplaceStrDef, '');
                  }
                  extDef = fhirExtensionSrv.getInstanceValueProperty(ext);
                  // ... something happens here when the value[x] has a rendered-value extension...
                  // ... something happens here when the value[x] is replaced with an extension...
                  extDef = angular.extend(fhirExtensionSrv.getEmptyDefinition(true), {
                    url: ext.url,
                    type: extDef, // Property name, not data type.
                    name: 'Unsupported: ' + extName
                  });
                }
                vm.extDefs[idx] = extDef;
                vm.record.extension[idx] = fhirExtensionFactory.construct(ext);// XXX try/catch
              });
            }

            vm.initialRecord = angular.copy(vm.record);
            $log.debug(result.data);
          })
          .catch(function () {
            psNotification.error(PI_CREATE_ERROR_LOADING_PI + ' ' + vm.params.id);
          })
          .finally(function () {
            vm.loading = false;
          });
      } else {
        vm.loading = false;
        psNotification.error(PI_CREATE_MISSING_REQUIRED_PARAMS);
      }
    }

    function addProperty (property) {
      var template;

      if (angular.isUndefined(vm.record[property])) {
        vm.record[property] = [];
      }

      if (property === IMAT_FHIR_EXTENSION.ELEMENT) {
        vm.extDefs.push(fhirExtensionSrv.getEmptyDefinition());
        vm.record[property].push(fhirExtensionFactory.construct());
      } else {
        template = piRecord.getPiTemplate(property);
        if (template) {
          vm.record[property].push(template);
        }
      }
    }

    function getExtensionTemplateName (index) {
      var extDef = vm.extDefs[index];
      var type = fhirExtensionSrv.getValueType(extDef.type);

      return (type || (extDef.id ? extDef.id : 'Unsupported'));
    }

    function getExtensionValues (index, url) {
      return fhirExtensionSrv.getBindingValues(vm.extDefs[index], url);
    }

    function handleExtensionChange (index) {
      var extDef = vm.extDefs[index];
      var template = piRecord.getPiTemplate("extensions['" + extDef.id + "']");

      if (template) {
        template.url = extDef.url;// Just in case template doesn't match for any reason.
        vm.record.extension[index] = fhirExtensionFactory.construct(template);
      }
    }

    function removeProperty (property, index) {
      vm.record[property].splice(index, 1);

      if (property === IMAT_FHIR_EXTENSION.ELEMENT) {
        vm.extDefs.splice(index, 1);
      }
    }

    function resetForm () {
      vm.mpidAssignmentType = 'new';
      vm.mpidAssignExisting = undefined;
      vm.comment = undefined;
      vm.commit = false;
      vm.record = angular.copy(vm.initialRecord);
    }

    function submitForm (isValid) {
      if (isValid) {
        // Turn loading back on while process request
        vm.loading = true;

        // Update meta lastUpdated
        if (vm.record.meta) {
          vm.record.meta.lastUpdated = $filter('date')(Date.now(), 'yyyy-MM-ddTHH:mm:ss.sssZ', '+0000');
        } else {
          vm.record.meta = { lastUpdated: $filter('date')(Date.now(), 'yyyy-MM-ddTHH:mm:ss.sssZ', '+0000') };
        }

        // Update dates to iso format for submission
        if (vm.record.deceasedDateTime) {
          vm.record.deceasedDateTime = dateTimeSrv.getIsoDatetime(vm.record.deceasedDateTime);
        }

        // Strip out PI identifier
        vm.record.identifier = vm.record.identifier.filter(function (identifier) {
          return !identifier.value.match(/^\d+\.PI$/);
        });

        // Get FHIR JSON (removing empty properties/extensions).
        fhirResourceSrv.getValidPatientJson(vm.record);

        mpiApiSrv.replacePi(vm.record, vm.params.mpid)
          .then(function () {
          // Display assigned mpid to end user
            psNotification.success(PI_CREATE_SUBMIT_RECORD_UPDATE_SUCCESS);
          })
          .catch(function () {
            $log.debug('Create PI submit error');
            psNotification.error(PI_CREATE_SUBMIT_RECORD_UPDATE_ERROR);
          })
          .finally(function () {
            activate();
            vm.loading = false;
          });
      } else { // Form is invalid.
        $mdDialog.show(
          $mdDialog.alert()
            .parent(angular.element($window.document.querySelector('#content')))
            .clickOutsideToClose(true)
            .title(PI_CREATE_SUBMIT_INVALID_DIALOG_TITLE)
            .htmlContent(PI_CREATE_SUBMIT_INVALID_DIALOG_CONTENT)
            .ariaLabel(PI_CREATE_SUBMIT_INVALID_DIALOG_ARIA)
            .ok(PI_CREATE_SUBMIT_INVALID_DIALOG_OK)
        );
      }
    }

    function triggerHistoryBack () {
      $window.history.back();
    }

    function _getPiRecord (id) {
      return mpiApiSrv.getPiRecord(id);
    }
  }// end piEditCtrl function
})();
