/**
 * This file can go away when IE 11 support goes away
 * UI-4062 IE 11 dashboard blank - app does not auto bootstrap
 * See https://github.com/angular/angular.js/issues/15772
 * pdf.js stomps over property angularjs uses to determine bootrap mechanism
 */

(function () {
  'use strict';

  angular.element(document).ready(function () {
    // injector should return undefined if angularjs app not bootstrapped, object otherwise
    if (!angular.element(document.querySelector('[ng-app]')).injector()) {
      angular.bootstrap(document, ['imat']);
    }
  });
})();
