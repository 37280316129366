(function () {
  'use strict';

  angular.module('vhr.services')
    .service('VhrCxmlRecordClass', VhrCxmlRecordClass);

  VhrCxmlRecordClass.$inject = [
    '$filter',
    'vhrXmlRecordClass'
  ];

  function VhrCxmlRecordClass (
    $filter,
    vhrXmlRecordClass
  ) {
    function humanizeDate (date, isString) {
      var humanized = isString ? date : date.toISOString();

      return (humanized.slice(0, 19).replace('T', ' ') + ' (UTC)').replace(/00:00:00 \(UTC\)$/, '');
    }

    function CxmlRecord (cfg) {
      vhrXmlRecordClass.call(this, cfg);
      this.controller = 'CxmlModalCtrl';
      this.printTemplateUrl = '/ui/vhr/templates/cxml/print/cxml.html';
      this.templateUrl = '/ui/vhr/templates/cxml/cxml-modal.html';
    }

    CxmlRecord.prototype = Object.create(vhrXmlRecordClass.prototype);
    CxmlRecord.prototype.constructor = CxmlRecord;

    CxmlRecord.prototype.formatDocument = function (doc) {
      doc = vhrXmlRecordClass.prototype.formatDocument.call(this, doc);
      doc = doc.replace(/csmk:div/g, 'csmkdiv');
      doc = doc.replace(/csmk:class=('|")section-name\1/g, 'csmk:class="section-name" class="section-name"');
      return doc;
    };

    // Pass undefined to show most non-empty fields.
    // Pass true to set up with default fields.
    // Pass an array of key names to set up with only those fields.
    // Pass false or an empty array to set up without fields.
    CxmlRecord.prototype.set = function (raw, setFields) {
      var excluded = ['rawrecord', 'store', 'uri'];
      var included;
      var reAngularAttached = /^\$+/;
      var reImpliedBoolean = /^(?:can|has|is)_/;
      var reImpliedDatetime = /^(?:date|datetime|time)$|^(?:date|datetime|time)_|_(?:date|datetime|time)_|_(?:date|datetime|time)$/;
      var reImpliedGender = /^(?:gender|sex)$|^(?:gender|sex)_|_(?:gender|sex)_|_(?:gender|sex)$/;
      var reIsoDatetime = /^(?:[+-]\d\d)?\d\d\d\d-\d\d-\d\dT\d\d:\d\d:\d\d\.\d\d\dZ$/;
      var reTruthy = /^(?:true|yes|t|y|1)$/i;
      var reFalsey = /^(?:false|no|f|n|0)$/i;
      var reGender = /^f(?:emale)?|(?:m(?:ale)?|o(?:ther)?|u(?:nknown)?)$/i;

      vhrXmlRecordClass.prototype.set.call(this, raw, false);

      if (angular.isUndefined(setFields)) {
        included = Object.keys(raw).filter(function (key) { return !reAngularAttached.test(key); }).sort();
      } else if (angular.isArray(setFields) && setFields.length) {
        included = setFields;
      } else {
        return;
      }

      excluded.forEach(function (key) {
        var idx = included.indexOf(key);

        if (idx >= 0) { included.splice(idx, 1); }
      });

      this.fields = included.map(function (key) { // eslint-disable-line complexity
        var label = $filter('imatLabelize')(key);
        var value = raw[key];

        // Remove the cruft...
        if (!setFields) { // Showing most fields except...
          // Skip alternative pretty-print values.
          if (/^print_/.test(key)) { return void (0); } // eslint-disable-line no-void
          // Skip values that are falsey, empty arrays, or white-space only.
          if (!value || !value.length || (value.trim && !value.trim())) { return void (0); } // eslint-disable-line no-void
        } else { // Showing specified fields except...
          // Skip values that are no longer defined (i.e., safeguard against getting out of sync with the report).
          if (typeof value === 'undefined') { return void (0); } // eslint-disable-line no-void
        }

        if (typeof value === 'boolean') {
          value = !!value ? 'Yes' : 'No'; // eslint-disable-line no-extra-boolean-cast
        } else if (reImpliedBoolean.test(key)) { // Assume the value is boolean if the key implies it.
          if (reTruthy.test(value)) {
            value = 'Yes';
          } else if (reFalsey.test(value)) {
            value = 'No';
          } else if (!!value) { // eslint-disable-line no-extra-boolean-cast
            value = 'Yes';
          } else {
            value = 'No';
          }
        } else if (angular.isDate(value)) {
          value = humanizeDate(value);
        } else if (reIsoDatetime.test(value)) {
          value = humanizeDate(value, true);
        } else if (reImpliedDatetime.test(key)) { // Assume the value is a date if the key implies it.
          if (!isNaN(Date.parse(value))) {
            value = humanizeDate(new Date(value));
          } else if (angular.isString(value) && /\|/.test(value)) {
            value = value.split('|')
              .map(function (v) {
                var d = Date.parse(v);
                return !isNaN(d) ? humanizeDate(new Date(d)) : v;
              })
              .filter(function (v, idx, arr) {
                if (v) {
                  for (var i = 0; i < idx; ++i) {
                    if (v === arr[i]) {
                      return false;// Remove duplicates.
                    }
                  }
                  return true;
                }
              })
              .join(', ');
          }
        } else if (reImpliedGender.test(key) && reGender.test(value)) { // Assume the value is a gender if key and value both quack like gender.
          value = value[0].toUpperCase() + value.slice(1);
        }

        return { isArray: angular.isArray(value), key: key, label: label, value: value };
      });

      this.fields = this.fields.filter(function (f) { return typeof f !== 'undefined'; });
    };

    return CxmlRecord;
  }
})();
