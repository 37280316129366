(function () {
  'use strict';

  angular.module('imat.services')
    .factory('DashboardItemsSrv', DashboardItemsSrv);

  DashboardItemsSrv.$inject = ['DASHBOARD', '$q', '$rootScope', '$state', '_lodash', 'DashboardItemClass', 'imatConfig'];

  function DashboardItemsSrv (DASHBOARD, $q, $rootScope, $state, _lodash, DashboardItemClass, imatConfig) {
    var defaultConf = {
      beta: false,
      category: '',
      image: {},
      link: {},
      roles: [],
      type: '',
      version: 1
    };
    var loaded = false;
    var loadedConfigs = [];
    var loadedItems = [];
    var service = {
      getItems: getItems,
      loadItem: loadItem
    };
    var states = $state.get().map(function (s) {
      return s.$$state();// XXX The returned object/interface is internal and may change without notice.
    });

    activate();
    return service;

    function activate () {
      return imatConfig.loadConfigFile('dashboard-tiles.json')
        .then(function (config) { // ImatConfigClass
          config.toArray().forEach(function (cfg) {
            var c = normalizeConfig(cfg);
            var i = _loadItem(construct(c.type), angular.copy(c));

            loadedConfigs.push(angular.copy(c));
            loadedItems.push(i);
          });
        })
        .finally(function () {
          loaded = true;
        });
    }

    function getItems (roles) {
      return _loadAndCall(function () {
        if (roles == null) {
          return angular.copy(loadedItems);
        }

        return angular.copy(loadedItems).filter(function (item) {
          if (!item.cfg.roles || !item.cfg.roles.length) {
            return true;
          }
          for (var i = 0, ii = roles.length; i < ii; ++i) {
            if (item.cfg.roles.indexOf(roles[i]) >= 0) {
              return true;
            }
          }
          return false;
        });
      });
    }

    // Create an "official" item representation comprising
    // both its default and user-saved configuration data.
    function loadItem (mwItem) {
      return getConfig(mwItem.type)
        .then(function (cfg) {
          return _loadItem(mwItem, cfg);
        });
    }

    //= ================================
    // Helpers
    //= ================================

    function construct (data) {
      return new DashboardItemClass(data);
    }

    function getConfig (type) {
      return _loadAndCall(function () {
        var cfg = loadedConfigs.filter(function (c) { return c.type === type; }).pop() || defaultConf;
        return angular.copy(cfg);
      });
    }

    function _loadItem (mwItem, cfg) { // eslint-disable-line complexity
      var item;

      if (mwItem.type === DASHBOARD.ITEMS.TYPES.FILTER || mwItem.type === DASHBOARD.ITEMS.TYPES.SEQUENCE) {
        return mwItem;
      }
      mwItem.data = mwItem.data || {};

      var defaultData = {
        image: angular.extend({
          color: '',
          type: DashboardItemClass.prototype.IMAGE.GLYPHICON,
          value: ''
        }, cfg.image || {}),
        link: angular.extend({
          href: '',
          params: {},
          sref: '',
          text: ''
        }, cfg.link || {})
      };
      var uiItem = {
        cfg: cfg,
        data: _lodash.merge({}, defaultData, cfg),
        type: mwItem.type
      };
      // Put legacy stuff in the correct places...
      if (mwItem.data.color) {
        uiItem.data.image.color = mwItem.data.color;
      }
      if (mwItem.data.imgUrl) {
        uiItem.data.image.value = mwItem.data.imgUrl;
        uiItem.data.image.type = DashboardItemClass.prototype.IMAGE.IMGSRC;
      } else if (mwItem.data.icon) {
        uiItem.data.image.value = mwItem.data.icon;
        uiItem.data.image.type = DashboardItemClass.prototype.IMAGE.GLYPHICON;
      }
      if (mwItem.data.url && uiItem.cfg.category !== DashboardItemClass.prototype.CATEGORY.INTERNAL) {
        uiItem.data.link.href = mwItem.data.url;
      }
      if (mwItem.data.title) {
        uiItem.data.link.text = mwItem.data.title;
      }

      // Disallow cruft in the data object (legacy properties).
      // I.e., don't just extend .data with DB garbage.
      Object.keys(mwItem.data).forEach(function (key) {
        var val = mwItem.data[key];

        switch (key) {
          case 'image':
            Object.keys(val).forEach(function (k) {
              if (Object.prototype.hasOwnProperty.call(uiItem.data.image, k)) {
                uiItem.data.image[k] = angular.copy(val[k]);
              }
            });
            break;
          case 'link':
            Object.keys(val).forEach(function (k) {
              if (Object.prototype.hasOwnProperty.call(uiItem.data.link, k)) {
                uiItem.data.link[k] = angular.copy(val[k]);
              }
            });
            break;
          default:
            if (Object.prototype.hasOwnProperty.call(uiItem.data, key)) {
              uiItem.data[key] = angular.copy(val);
            }
            break;
        }
      });
      // The item has a stored type that cannot be found in the current configs.
      uiItem.unsupported = uiItem.type && !uiItem.cfg.type;

      // Primarily to get a sort order similar to the old gridster layout.
      uiItem.data.position = (mwItem.data.position ? angular.copy(mwItem.data.position) : ['', '']);
      if (!Array.isArray(uiItem.data.position)) {
        uiItem.data.position = [uiItem.data.position[0] == null ? '' : uiItem.data.position[0], uiItem.data.position[1] == null ? '' : uiItem.data.position[1]];
      }

      item = construct(uiItem);
      item.error = item.checkHasError();

      return item;
    }

    // -------------------------------------
    // LEGACY CONFIGURATION
    // -------------------------------------
    // {
    //   "type": "link-queries",
    //   "title": "Queries",
    //   "roles": ["system", "search"],
    //   "beta": true,
    //   "icon": "search",
    //   "url": "/ui/#/queries/",
    //   "colors": ["#f2c553", "#11a4d3", "#a4c558", "#e51b24", "#00235d", "#000000"],
    //   "sizes": [
    //     [1, 1],
    //     [2, 1],
    //     [2, 2]
    //   ],
    //   "views": {
    //     "partial": "/ui/dashboard/items/config/internal/view.html",
    //     "config": "/ui/dashboard/items/config/internal/config.html"
    //   },
    //   "defaults": {
    //     "color": "#e51b24",
    //     "size": [2, 2]
    //   }
    // }

    // -------------------------------------
    // TARGET CONFIGURATION
    // -------------------------------------
    // {
    //   beta: true,
    //   category: "internal",
    //   image: { color: "#e51b24", type: "glyphicon", value: "search" },
    //   link: { href: "#/queries/", params: {}, sref: "app.queries.builder", text: "Queries" },
    //   roles: ["system", "search"],
    //   type: "link-queries",
    //   version: 1
    // }

    function normalizeConfig (cfg) {
      var conv;

      if (!Object.prototype.hasOwnProperty.call(cfg, 'version')) {
        cfg = normalizeConfig_0(cfg);
      }

      do {
        try {
          conv = eval('normalizeConfig_' + (cfg.version + 1));// eslint-disable-line no-eval
          cfg = conv(cfg);
        } catch (e) { conv = null; }
      } while (typeof conv === 'function');

      matchHrefToSref(cfg);

      return cfg;
    }

    function normalizeConfig_0 (cfg) { // eslint-disable-line
      if (!cfg.defaults) {
        cfg.defaults = {};
      }

      // Ensure booleans.
      cfg.beta = (angular.isString(cfg.beta) ? /^true$/i.test(cfg.beta) : !!cfg.beta);
      cfg.disabled = (angular.isString(cfg.disabled) ? /^true$/i.test(cfg.disabled) : !!cfg.disabled);

      // Move name to type. This is trying to correct a long-ago mismatch
      // between the configuration file and what we were saving in the DB.
      if (Object.prototype.hasOwnProperty.call(cfg, 'name')) {
        if (!cfg.type) {
          cfg.type = cfg.name || '';
        }
        delete cfg.name;
      }

      // Move title from defaults to top-level.
      if (Object.prototype.hasOwnProperty.call(cfg.defaults, 'title')) {
        if (!cfg.title) {
          cfg.title = cfg.defaults.title || '';
        }
        delete cfg.defaults.title;
      }

      // Move icon from defaults to top-level.
      if (Object.prototype.hasOwnProperty.call(cfg.defaults, 'icon')) {
        if (!cfg.icon) {
          cfg.icon = cfg.defaults.icon || '';
        }
        delete cfg.defaults.icon;
      }

      // Move color from defaults to top-level.
      if (Object.prototype.hasOwnProperty.call(cfg.defaults, 'color')) {
        if (!cfg.color) {
          cfg.color = cfg.defaults.color || '';
        }
        delete cfg.defaults.color;
      }

      cfg.version = 0;

      return angular.extend({}, defaultConf, cfg);
    }

    // No direct calls; function name is derived.
    function normalizeConfig_1 (cfg) { // eslint-disable-line complexity, no-unused-vars, camelcase
      if (!cfg.defaults) {
        cfg.defaults = {};
      }
      if (!cfg.views) {
        cfg.views = {};
      }

      cfg.image.color = '';
      cfg.image.type = DashboardItemClass.prototype.IMAGE.GLYPHICON;
      cfg.image.value = '';
      cfg.link.href = '';
      cfg.link.params = {};
      cfg.link.sref = '';
      cfg.link.text = '';

      // Determine the category.
      switch (cfg.views.config) {
        case '/ui/dashboard/tiles/link/config.html':// No break.
        case '/ui/dashboard/items/config/external/config.html':
          cfg.category = DashboardItemClass.prototype.CATEGORY.EXTERNAL;
          break;
        case '/ui/dashboard/tiles/link-product/config.html':// No break.
        case '/ui/dashboard/items/config/internal/config.html':
          cfg.category = DashboardItemClass.prototype.CATEGORY.INTERNAL;
          break;
        case '/ui/dashboard/tiles/link-reports/config.html':// No break.
        case '/ui/dashboard/items/config/crReport/config.html':
          cfg.category = DashboardItemClass.prototype.CATEGORY.CR_REPORT;
          break;
        case '/ui/dashboard/tiles/link-results/config.html':// No break.
        case '/ui/dashboard/items/config/crResult/config.html':
          cfg.category = DashboardItemClass.prototype.CATEGORY.CR_RESULT;
          break;
        default:
          cfg.category = '';
      }

      // Put stuff in the correct place...
      cfg.image.color = cfg.color || cfg.defaults.color || '';
      cfg.image.value = cfg.icon || cfg.defaults.icon || '';
      if (cfg.imgUrl || cfg.defaults.imgUrl) {
        cfg.image.type = DashboardItemClass.prototype.IMAGE.IMGSRC;
        cfg.image.value = cfg.imgUrl || cfg.defaults.imgUrl;
      }
      cfg.link.href = cfg.url || cfg.defaults.url || '';
      cfg.link.text = cfg.title || '';

      // Remove unsupported properties.
      delete cfg.color; delete cfg.defaults.color;
      delete cfg.colors;
      delete cfg.disabled;
      delete cfg.disabledMessage;
      delete cfg.icon; delete cfg.defaults.icon;
      delete cfg.imgUrl; delete cfg.defaults.imgUrl;
      delete cfg.required;
      delete cfg.size; delete cfg.defaults.size;
      delete cfg.sizes;
      delete cfg.title; delete cfg.defaults.title;
      delete cfg.url; delete cfg.defaults.url;
      delete cfg.views;

      cfg.version = 1;

      return angular.extend({}, defaultConf, cfg);
    }

    function matchHrefToSref (cfg) {
      var stateMatch = false;

      if (cfg.link.sref) {
        const stateDecl = $state.get(cfg.link.sref);

        if (stateDecl) {
          cfg.beta = stateDecl.data.beta;
          cfg.link.href = $state.href(stateDecl, cfg.link.params || {});
          cfg.roles = stateDecl.data.roles || [];
          stateMatch = true;
        }
      }

      if (cfg.link.href && !stateMatch) {
        const ngRe = /^\/?(ui\/)?#/;
        let stateIdx;

        if (ngRe.test(cfg.link.href)) {
          states.forEach(function (state, idx) {
            if (!state.abstract && state.url) {
              if (state.url.exec(cfg.link.href.replace(ngRe, '')) != null) {
                stateIdx = idx;
                stateMatch = true;
              }
            }
          });

          if (stateMatch) {
            cfg.beta = states[stateIdx].data.beta;
            cfg.link.href = $state.href(states[stateIdx]);
            cfg.link.sref = states[stateIdx].name;
            cfg.roles = states[stateIdx].data.roles || [];
          }
        }
      }
    }

    function _loadAndCall (callback) {
      var deferred = $q.defer();
      var unwatch = $rootScope.$watch(function () { return loaded; }, function (curr) {
        if (curr) {
          if (typeof callback === 'function') {
            deferred.resolve(callback());
          } else {
            deferred.resolve();
          }
          unwatch();
        }
      });

      return deferred.promise;
    }
  }
})();
