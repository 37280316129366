(function () {
  'use strict';

  angular.module('mpiApp.personas', [
    'imat.services', // psNotification
    'mpiApp.constants', // MPI_CLIENT_NAME
    'mpiApp.services', // mpiApiSrv, mpiConfigSrv, mpiPersona, mpiUtilitySrv
    'ngMaterial' // $mdDialog $mdMenu
  ]);
})();
