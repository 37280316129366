(function () {
  'use strict';

  angular
    .module('vhr.test')
    .factory('vhrHieFix', vhrHieFix);

  vhrHieFix.$inject = ['$q'];

  function vhrHieFix ($q) {
    var service;

    service = {
      getFoo: getFoo
    };

    function getFoo (promise) {
      var fix = {};

      return (promise) ? $q.resolve(fix) : fix;
    }

    return service;
  }
})();
