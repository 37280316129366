(function () {
  'use strict';

  angular
    .module('imat.services')
    .factory('authTokensSrv', authTokensSrv);

  authTokensSrv.$inject = ['$http', '$q'];

  function authTokensSrv ($http, $q) {
    let tokens = [];

    var service = {
      deleteToken: deleteToken,
      getList: getList,
      createToken: createToken
    };

    return service;

    function deleteToken (id) {
      return $http.delete('/api/authtokens/' + id);
    }

    function getList (force) {
      if (!force && tokens.length) {
        return $q.resolve(tokens);
      }
      return $http.get('/api/authtokens').then(function (resp) {
        tokens = resp.data;
        return tokens;
      });
    }

    function createToken (data) {
      return $http.post('/api/authtokens', JSON.stringify(data))
        .then(function (resp) { return resp.data; });
    }
  }
})();
