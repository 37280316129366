(function () {
  'use strict';

  angular
    .module('mpiApp')
    .config(config);

  config.$inject = ['$stateRegistryProvider'];
  function config ($stateRegistryProvider) {
    $stateRegistryProvider.register({
      name: 'app.mpi',
      abstract: true,
      data: {
        appKey: 'mpi',
        pageTitle: 'the MPI',
        roles: ['system']
      },
      resolve: [{
        token: 'mpiBootstrap',
        policy: { when: 'EAGER' },
        deps: ['$q', 'imatFieldsSrv', 'imatStoresSrv', 'mpiConfigSrv'],
        resolveFn: function ($q, imatFieldsSrv, imatStoresSrv, mpiConfigSrv) {
          return $q.all([
            imatFieldsSrv.load()
              .then(function () {
                imatFieldsSrv.loaded = true;
              }),

            imatStoresSrv.load()
              .then(function () {
                imatStoresSrv.loaded = true;
              }),

            mpiConfigSrv.load()
              .then(function () {
                mpiConfigSrv.loaded = true;
              })
          ]);
        }
      }],
      views: {
        'content@app': {
          templateUrl: '/ui/_shared/layouts/left-sidebar-layout.html',
          controller: 'LeftSidebarCtrl',
          controllerAs: 'ctrl'
        },
        'sidenav@app.mpi': {
          templateUrl: '/ui/_shared/layouts/sidebar.html',
          controller: 'SidebarCtrl',
          controllerAs: 'ctrl'
        }
      },
      url: '/mpi'
    });
    $stateRegistryProvider.register({
      name: 'app.mpi.query',
      abstract: true
    });
    $stateRegistryProvider.register({
      name: 'app.mpi.limboQuery',
      abstract: true
    });
    $stateRegistryProvider.register({
      name: 'app.mpi.query.search',
      url: '/',
      data: {
        pageTitle: 'Persona Search',
        help: '/ui/documentation/#/guides/mpi#searching'
      },
      views: {
        'main-content@app.mpi': {
          templateUrl: '/ui/mpi/search/search.html',
          controller: 'mpiSearchCtrl',
          controllerAs: 'ctrl'
        }
      }
    });
    $stateRegistryProvider.register({
      name: 'app.mpi.pi-link-editor',
      url: '/pi-link-editor/?mpids',
      data: {
        back: true,
        pageTitle: 'PI Link Editor',
        help: '/ui/documentation/#/guides/pi-link-editor'
      },
      views: {
        'main-content@app.mpi': {
          templateUrl: '/ui/mpi/pi-link-editor/pi-link-editor.html',
          controller: 'piLinkEditorCtrl',
          controllerAs: 'ctrl'
        }
      }
    });
    $stateRegistryProvider.register({
      name: 'app.mpi.piCreate',
      url: '/pi/create/?id&store',
      data: {
        back: true,
        pageTitle: 'Create PI Record',
        help: '/ui/documentation/#/guides/pi-create'
      },
      params: {
        id: null,
        store: null
      },
      views: {
        'main-content@app.mpi': {
          templateUrl: '/ui/mpi/pi-create/pi-create.html',
          controller: 'piCreateCtrl',
          controllerAs: 'ctrl'
        }
      }
    });
    $stateRegistryProvider.register({
      name: 'app.mpi.personas',
      url: '/personas/:id/',
      data: {
        back: true,
        pageTitle: 'Persona Details',
        help: '/ui/documentation/#/guides/mpi#persona'
      },
      views: {
        'main-content@app.mpi': {
          templateUrl: '/ui/mpi/personas/personas.html',
          controller: 'personasCtrl',
          controllerAs: 'personasVM'
        }
      }
    });
    $stateRegistryProvider.register({
      name: 'app.mpi.piEdit',
      url: '/pi/{id:.*}/mpid/{mpid:.*}/',
      data: {
        back: true,
        pageTitle: 'Edit PI Record'
      },
      params: {
        id: null,
        mpid: null
      },
      views: {
        'main-content@app.mpi': {
          templateUrl: '/ui/mpi/pi-edit/pi-edit.html',
          controller: 'piEditCtrl',
          controllerAs: 'ctrl'
        }
      }
    });
    $stateRegistryProvider.register({
      name: 'app.mpi.limboQuery.limboSearch',
      url: '/limbo/',
      data: {
        pageTitle: 'Limbo Search',
        help: '/ui/documentation/#/guides/mpi#searching'
      },
      views: {
        'main-content@app.mpi': {
          templateUrl: '/ui/mpi/search/limbo-search.html',
          controller: 'limboSearchCtrl',
          controllerAs: 'ctrl'
        }
      }
    });
    $stateRegistryProvider.register({
      name: 'app.mpi.limboQuery.limboRecord',
      url: '/limbo/record/?id/',
      data: {
        back: true,
        pageTitle: 'Limbo Record Details'
      },
      views: {
        'main-content@app.mpi': {
          templateUrl: '/ui/mpi/limbo-record/limbo-record.html',
          controller: 'limboRecordCtrl',
          controllerAs: 'vm'
        }
      }
    });
  }
})();
