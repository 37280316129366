(function () {
  'use strict';

  angular.module('admin')
    .controller('ReportsCtrl', ReportsCtrl);

  ReportsCtrl.$inject = ['$filter', '$mdDialog', '$mdSidenav', '$q', '$scope', 'Permissions', 'psNotification', 'ReportsSrv'];

  function ReportsCtrl ($filter, $mdDialog, $mdSidenav, $q, $scope, Permissions, psNotification, ReportsSrv) {
    var vm = this;
    var ctx = this.constructor.name;

    var GET_CURRENT_ACCESS_LIST_ERROR = 'Unable to retrieve current access list.';

    // Properties.
    vm.filter = ['python', 'pslucene'];
    vm.loaded = { list: true, detail: true };
    vm.reports = [];
    vm.searchText = ReportsSrv.lastSearch;
    vm.selected = {};
    vm.selectedDetail = {};
    vm.selectedFilter; // eslint-disable-line no-unused-expressions

    // Methods.
    vm.clearSelection = clearSelection;
    vm.filterByType = filterByType;
    vm.previewReport = previewReport;
    vm.resetFilters = resetFilters;
    vm.refreshReports = refreshReports;
    vm.sansProvider = sansProvider;
    vm.selectReport = selectReport;

    activate();

    function activate () {
      $scope.$watch('vm.searchText', function (curr) {
        if (curr != null) { ReportsSrv.lastSearch = curr; }
      });

      vm.loaded.detail = false;
      loadReports(false)
        .then(function () {
          vm.selected = ReportsSrv.getSelected(ctx);
          return ReportsSrv.getSelectedItem(ctx);
        })
        .then(function (report) {
          return getCurrentAccess(report);
        })
        .then(function (report) {
          vm.selectedDetail = report;
          $mdSidenav('sharingDetails').open();
        })
        .finally(function () {
          vm.loaded.detail = true;
        });
    }

    function clearSelection () {
      ReportsSrv.clearSelected(ctx);
      vm.selected = {};
      clearSelectionDetail();
    }

    function clearSelectionDetail () {
      vm.selectedDetail = angular.copy(vm.selected) || {};
      vm.selectedDetail.empty = true;
      $mdSidenav('sharingDetails').close();
    }

    function filterByType (report) {
      var displayed = vm.filter[report.type] || noFilter(vm.filter);
      return displayed;
    }

    function loadReports (reload) {
      vm.loaded.list = false;

      return ReportsSrv.getList({ admin: true }, reload)
        .then(function (reports) {
          // TODO: Remove .map function below when clinical-reports is phased out.
          reports = reports.map(function (report) {
            report.name = report.name.trim();
            return report;
          });
          vm.reports = reports;
        })
        .catch(function () {
          psNotification.error('Unable to retrieve the reports.');
        })
        .finally(function () {
          vm.loaded.list = true;
        });
    }

    function noFilter (filterObject) {
      return Object.keys(filterObject).every(function (key) {
        return !filterObject[key];
      });
    }

    function previewReport (reportName, reportId) {
      $mdDialog.show({
        controller: 'ReportViewModalCtrl',
        controllerAs: 'vm',
        locals: { reportName: reportName, reportId: reportId },
        templateUrl: '/ui/admin/sharing/report-view-modal.html'
      });
    }

    function refreshReports () {
      loadReports(true);
    }

    function resetFilters () {
      vm.searchText = '';
      vm.selectedFilter = '';
    }

    function sansProvider (str) {
      return str.split(/@[\w]+$/)[0];
    }

    function selectReport (report) {
      vm.loaded.detail = false;
      vm.selected = report;
      ReportsSrv.setSelected(ctx, report.id);
      ReportsSrv.getSelectedItem(ctx, { admin: true })
        .then(function (reportDetail) {
          if (!reportDetail.permissions) {
            return getCurrentAccess(reportDetail)
              .then(function (res) {
                vm.selectedDetail = res;
              });
          }
          vm.selectedDetail = reportDetail;
        })
        .then(function () {
          $mdSidenav('sharingDetails').open();
        })
        .catch(function () {
          clearSelectionDetail();
        })
        .finally(function () {
          vm.loaded.detail = true;
        });
    }

    function getCurrentAccess (report) {
      return Permissions.getCurrentAccess(report.id)
        .then(function (permissions) {
          report.permissions = $filter('orderBy')(permissions, 'authentication_id');
          ReportsSrv.cacheItem(report);
          return report;
        })
        .catch(function () {
          return $q.reject(GET_CURRENT_ACCESS_LIST_ERROR);
        });
    }
  }
})();
