(function () {
  'use strict';

  angular.module('imat.services')
    .service('CachingClass', CachingClass);

  CachingClass.$inject = ['$q'];

  function CachingClass ($q) {
    function Caching () {
      this._cache = {
        ids: {},
        itemIdentifier: function (item) { return '' + item.id; },
        items: {},
        list: [],
        listIdentifier: function (item) { return '' + item.id; }
      };
    }

    Caching.prototype = {

      cache: function (list) {
        this._cache.list = Array.isArray(list) ? list : (list ? [list] : []);
        this._cache.items = {};
        return this._cache.list;
      },

      cacheItem: function (item) {
        var id = this._cache.itemIdentifier(item);
        if (!id) { return item; }
        this._cache.items[id] = item;
        return this._cache.items[id];
      },

      _cachedFinder: function (id) {
        var self = this;
        return function (item) { return self._cache.listIdentifier(item) === id; };
      },

      _cachedItemFinder: function (id) {
        var self = this;
        return function (item) { return self._cache.itemIdentifier(item) === id; };
      },

      clearSelected: function (ctx) {
        delete this._cache.ids[ctx];
      },

      ejectCachedItem: function (id, delist) {
        var idx;
        delete this._cache.items[id];

        if (delist) {
          idx = this._cache.list.findIndex(this._cachedFinder(id));
          if (idx >= 0) { this._cache.list.splice(idx, 1); }
        }
      },

      getCached: function () {
        return this._cache.list;
      },

      getCachedListEntry: function (id) {
        return this._cache.list.find(this._cachedFinder(id));
      },

      getCachedItem: function (id) {
        return this._cache.items[id];
      },

      getSelected: function (ctx) {
        var id = this._cache.ids[ctx];
        return this._cache.list.find(this._cachedFinder(id));
      },

      getSelectedId: function (ctx) {
        return this._cache.ids[ctx];
      },

      getSelectedItem: function (ctx, force) {
        var id = this._cache.ids[ctx];
        if (!force && this.isCachedItem(id)) {
          return $q.resolve(this.getCachedItem(id));
        }
        return $q.reject();
      },

      isCached: function () {
        return !!this._cache.list.length;
      },

      isCachedItem: function (id) {
        return Object.prototype.hasOwnProperty.call(this._cache.items, id);
      },

      setCachedItemIdentifier: function (cb) {
        if (typeof cb === 'function') {
          this._cache.itemIdentifier = cb;
        }
      },

      setCachedListIdentifier: function (cb) {
        if (typeof cb === 'function') {
          this._cache.listIdentifier = cb;
        }
      },

      setSelected: function (ctx, id) {
        // TODO Ensure a valid ID?
        this._cache.ids[ctx] = id;
      }

    };

    return Caching;
  }
})();
