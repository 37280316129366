(function () {
  'use strict';

  angular.module('reports')
    .config(config);

  config.$inject = ['$stateRegistryProvider'];

  function config ($stateRegistryProvider) {
    $stateRegistryProvider.register({
      name: 'app.reports',
      abstract: true,
      data: {
        appKey: 'reports',
        beta: true,
        pageTitle: 'Reports',
        roles: ['search', 'system']
      },
      views: {
        'content@app': {
          templateUrl: '/ui/_shared/layouts/left-sidebar-layout.html',
          controller: 'LeftSidebarCtrl',
          controllerAs: 'ctrl'
        },
        'sidenav@app.reports': {
          templateUrl: '/ui/_shared/layouts/sidebar.html',
          controller: 'SidebarCtrl',
          controllerAs: 'ctrl'
        }
      },
      url: '/reports'
    });
    $stateRegistryProvider.register({
      name: 'app.reports.list',
      data: {
        pageTitle: 'Reports'
      },
      views: {
        'main-content@app.reports': {
          templateUrl: '/ui/reports/list/list.html',
          controller: 'ReportsListCtrl',
          controllerAs: 'ctrl'
        }
      },
      url: '/'
    });
    $stateRegistryProvider.register({
      name: 'app.reports.editor',
      data: {
        pageTitle: 'Report Editor'
      },
      params: {
        report_id: null
      },
      views: {
        'main-content@app.reports': {
          templateUrl: '/ui/reports/editor/editor.html',
          controller: 'ReportsEditorCtrl',
          controllerAs: 'ctrl'
        }
      },
      url: '/editor/:report_id/'
    });
    $stateRegistryProvider.register({
      name: 'app.reports.results',
      data: {
        pageTitle: 'Results'
      },
      views: {
        'main-content@app.reports': {
          templateUrl: '/ui/reports/results/results.html',
          controller: 'ResultsCtrl',
          controllerAs: 'ctrl'
        }
      },
      url: '/results/'
    });
    $stateRegistryProvider.register({
      name: 'app.reports.run',
      // Individual report view
      data: {
        pageTitle: 'Report Viewer',
        roles: ['search_by_id', 'search', 'system']
      },
      params: {
        report_id: null,
        report_name: null
      },
      views: {
        'main-content@app.reports': {
          templateUrl: '/ui/reports/run/run.html',
          controller: 'RunCtrl',
          controllerAs: 'ctrl'
        },
        'sidebar@app.reports': {
          templateUrl: '/ui/reports/run/sidebar.html'
        },
        'toolbar@app.reports.run': {
          templateUrl: '/ui/reports/run/toolbar.html',
          controller: 'RunToolbarCtrl',
          controllerAs: 'ctrl'
        }
      },
      url: '/runner/:report_id/?report_name&raw&admin' // The '/reports' part of the url needs to be changed when building the rest of the report UI
    });
  }
})();
