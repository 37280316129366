(function () {
  'use strict';

  angular
    .module('imat.filters')
    .filter('rawRecordFilter', function () {
      return function (input) {
        var rawRegEx = /<rawrecord>(.|\n|\r)*<\/rawrecord>/;
        var rawRecord = input.match(rawRegEx).join('').replace(/<rawrecord>|<\/rawrecord>/g, '');
        return rawRecord;
      };
    });
})();
