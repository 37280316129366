(function () {
  'use strict';

  angular
    .module('vhr')
    .controller('HieSearchCtrl', HieSearchCtrl);

  HieSearchCtrl.$inject = [
    '$scope', '$state', '$q',
    'psNotification', 'psReports', 'vhrHieApi', 'vhrPersistence'
  ];

  function HieSearchCtrl (
    $scope, $state, $q,
    psNotification, psReports, vhrHieApi, vhrPersistence
  ) {
    var vm = this;

    $scope.FILTER_TYPE = psReports.INPUT_TYPES;

    // Properties
    vm.targets = [];
    vm.selectedTargets = [];
    vm.loaded = false;

    // Methods
    vm.addTarget = addTarget;
    vm.toggleSelectAllTargets = toggleSelectAllTargets;
    vm.submit = submit;

    activate();

    // Implementation

    function activate () {
      $scope.$on('vhrHieRefreshed', refresh);

      getTargets()
        .finally(function () {
          vm.loaded = true;
        });
    }

    function getTargets () {
      var failure = false;

      return vhrHieApi.getTargets()
        .then(function (targetOids) {
          var promises = [];

          targetOids.forEach(function (targetOid) {
            promises.push(
              vhrHieApi.getOrganization(targetOid)
                .then(function (targetOrg) {
                  vm.targets.push(targetOrg);
                })
                .catch(function () {
                  // Should never happen, but ignore failed lookups.
                  // In other words, return as many as we can find.
                  failure = true;
                })
            );
          });

          return $q.all(promises)
            .then(function () {
              vm.selectedTargets = vhrPersistence.getData('hieSelectedTargets');

              if (vm.selectedTargets && vm.selectedTargets.length) {
                syncSelectedTargetRefs();
              } else {
                vm.selectedTargets = vm.targets.slice(0);// Select all by default.
              }

              if (failure) {
                psNotification.warn('Unable to load some communities.');
              }
            });
        })
        .catch(function () {
          psNotification.error('Unable to load the community list.');
        });
    }

    function addTarget (target) {
      var idx = vm.selectedTargets.indexOf(target);

      if (idx >= 0) {
        vm.selectedTargets.splice(idx, 1);
      } else {
        vm.selectedTargets.push(target);
      }
    }

    function refresh () {
      vhrPersistence.reset('hieTargets');
      vhrPersistence.reset('hieSelectedTargets');
      vm.targets.splice(0);

      vm.loaded = false;
      getTargets()
        .finally(function () {
          vm.loaded = true;
        });
    }

    function toggleSelectAllTargets () {
      vm.selectedTargets.length === vm.targets.length ? vm.selectedTargets.splice(0) : vm.selectedTargets = vm.targets.slice(0); // eslint-disable-line no-unused-expressions
    }

    function submit () {
      var targetCommunities = vm.selectedTargets.map(function (target) {
        return target.organizationOid;
      });

      vhrPersistence.setData('hieTargetCommunities', targetCommunities);
      vhrPersistence.setData('hieSelectedTargets', vm.selectedTargets);
      $state.go('app.vhr.patient.hie.hie-communities', { targetCommunities: targetCommunities });
    }

    function syncSelectedTargetRefs () {
      vm.targets.forEach(function (target) {
        for (var i = 0, ii = vm.selectedTargets.length; i < ii; i++) {
          if (vm.selectedTargets[i].organizationOid === target.organizationOid) {
            vm.selectedTargets[i] = target;// Copy reference.
            break;
          }
        }
      });
    }
  }
})();
