(function () {
  'use strict';

  angular.module('imat.services')
    .factory('ImatReportClass', ImatReportClass);

  ImatReportClass.$inject = ['PS_REPORT', '$http', '$q', '$rootScope', '$timeout', '$window', 'searchSrv', 'socketUtil'];

  function ImatReportClass (PS_REPORT, $http, $q, $rootScope, $timeout, $window, searchSrv, socketUtil) {
    var POLL_DELAY = 750;
    var POLL_INTERVAL = 750;

    function ImatReport (reportId, caller, canArtifactPoll) {
      this.id = reportId;
      this.caller = caller;
      this.canArtifactPoll = canArtifactPoll;
      this.mode = null;
      this.parameters = {};
      this.preferredTemplate = null;
      this.supportedTemplates = [];
      this.results = {};
    }

    ImatReport.prototype.STATUS = {
      ERROR: -1,
      RUNNING: 0,
      COMPLETE: 1,
      CANCELED: 2
    };

    ImatReport.prototype.getArtifact = function (workspace, filename, force) {
      if (force) {
        return searchSrv.getResultOutput(this.results[workspace].resultId, filename);
      }
      if (workspace && this.results && Object.prototype.hasOwnProperty.call(this.results, workspace) && this.results[workspace].artifacts.indexOf(filename) >= 0) {
        return searchSrv.getResultOutput(this.results[workspace].resultId, filename);
      }
      return $q.reject('The report has no artifact list or the given artifact is not in it: ' + filename);
    };

    ImatReport.prototype.getLastParameters = function (workspace, verbose) {
      var params = {};

      if (!Object.prototype.hasOwnProperty.call(this.results, workspace)) {
        return null;
      }
      if (verbose) {
        params = angular.copy(this.results[workspace].parameters);// Last-run
      } else {
        angular.forEach(this.results[workspace].parameters, function (def, param) {
          params[param] = def.value;
        });
      }
      return params;
    };

    ImatReport.prototype.getParameters = function (verbose) {
      var params = {};

      if (verbose) {
        params = angular.copy(this.parameters);
      } else {
        angular.forEach(this.parameters, function (def, param) {
          params[param] = def.value;
        });
      }
      return params;
    };

    ImatReport.prototype.getParameterDefaults = function (verbose) {
      var params = {};

      if (verbose) {
        angular.forEach(this.parameters, function (def, param) {
          params[param] = angular.copy(def);
          params[param].value = def.default;
        });
      } else {
        angular.forEach(this.parameters, function (def, param) {
          params[param] = def.default;
        });
      }
      return params;
    };

    ImatReport.prototype.getMeta = function () {
      return $http.get('/api/queries/' + this.id)
        .then(function (res) {
          // Don't overwrite any values that already exist
          Object.keys(res.data).forEach(function (key) {
            if (!Object.prototype.hasOwnProperty.call(this, key)) {
              this[key] = res.data[key];
            }
          }, this);
        }.bind(this));
    };

    ImatReport.prototype.getTimestamp = function (workspace) {
      if (workspace && this.results && Object.prototype.hasOwnProperty.call(this.results, workspace) && this.results[workspace].timestamp) {
        return this.results[workspace].timestamp;
      }
      return null;
    };

    ImatReport.prototype.cancel = function (workspace) {
      this.deleteResult(workspace);
    };

    ImatReport.prototype.deleteResult = function (workspace) {
      var self = this;

      if (!Object.prototype.hasOwnProperty.call(this.results, workspace)) {
        return $q.reject();
      }

      if (!this.results[workspace].resultId) {
        return $q.reject();
      }

      return searchSrv.cancel(self.results[workspace].resultId)
        .then(function (response) {
          self.results[workspace].status = ImatReport.prototype.STATUS.CANCELED;
          return response;
        });
    };

    ImatReport.prototype.setArtifactResult = function (workspace, artifact, results) {
      this.results[workspace].artifactResults[artifact] = results;
    };

    ImatReport.prototype.run = function (workspace, parameters, force) {
      var self = this;
      var deferredResult = $q.defer();
      var deferredStatus = $q.defer();
      var invalidParams;
      var params;
      var preparedParams = {};
      var xhr;

      if (workspace == null) {
        return $q.reject({ error: 'A workspace is required to run the report.' });
      }

      if (parameters == null) {
        return $q.reject({ error: 'Parameters are required to run the report.' });
      }

      params = angular.copy(self.getParameterDefaults(true));
      angular.forEach(parameters, function (param, paramName) {
        if (params[paramName] === undefined) {
          return;// continue
        }
        if (!params[paramName].protected) {
          params[paramName].value = param;
          if (angular.isObject(param) && Object.prototype.hasOwnProperty.call(param, 'value')) {
            params[paramName].value = param.value;
          }
          if (angular.isDate(params[paramName].value)) {
            params[paramName].value = params[paramName].value.toISOString().replace(/\.\d\d\dZ$/, '+00:00');
          }
          if (params[paramName].type === PS_REPORT.PARAM_TYPES.BOOL && typeof params[paramName].value !== 'boolean') {
            if (/^(false|no)$/i.test(params[paramName].value)) {
              params[paramName].value = false;
            } else if (/^(true|yes)$/i.test(params[paramName].value)) {
              params[paramName].value = true;
            } else {
              params[paramName].value = Boolean(params[paramName].value).valueOf();
            }
          }
        }
      });

      invalidParams = checkInvalidParams(params);
      if (invalidParams.length) {
        return $q.reject({ error: 'Invalid parameters. All parameters require: type, value.', params: invalidParams });
      }

      // If we're not forced to re-run and the parameters match, return the result ID (like a first .run).
      // Only works with 'verbose' parameters.
      // TODO Check .equals() for types and values only.
      if (!force && Object.prototype.hasOwnProperty.call(self.results, workspace)) {
        if (angular.equals(params, self.results[workspace].parameters) || !Object.keys(params).length) {
          return $q.resolve(self.results[workspace].resultId);
        }
      }

      self.results[workspace] = {
        status: ImatReport.prototype.STATUS.RUNNING,
        getPollPromise: function () { return deferredStatus.promise; },
        resultId: null,
        artifacts: [],
        artifactStatus: {},
        artifactResults: {},
        parameters: params,
        timestamp: new Date()
      };

      // Strip out "verbose" properties.
      angular.forEach(params, function (param, paramName) {
        preparedParams[paramName] = { value: param.value, type: param.type };
      });

//START DEBUG USING POLLING
      if ($window.debugReport) {
        var deferred = $q.defer();
        self.results[workspace].getPollPromise = function () { return deferred.promise; };
        xhr = {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
          url: '/search/queries/' + self.id + '/run',
          data: { caller: self.caller, parameters: { parameters: preparedParams } },
          params: { usesession: 'true' }
        };

        return $http(xhr)
          .then(function (res) {
            if (self.results[workspace].status === ImatReport.prototype.STATUS.CANCELED) {
              // deleteResult was (probably) called while we were waiting for a resultId...
              searchSrv.cancel(res.data.result_id);
              return $q.reject({ error: '' });
            }
            self.results[workspace].resultId = res.data.result_id;
            $timeout(function () { statusPoll(self.results[workspace], deferred, self.canArtifactPoll); }, POLL_DELAY);
            return res.data.result_id;
          });
      }
//END DEBUG USING POLLING

      var requestId = getRequestId();

      $window.io.socket.request({
        method: 'post',
        url: socketUtil.getUrlString('/api/queries/' + self.id + '/run', { usesession: true }),
        data: { caller: self.caller, parameters: { parameters: preparedParams } },
        headers: { 'x-ps-requestid': requestId }
      });

      $window.io.socket.on(requestId, function (res) {
        var RESULT_PREPARED = 'READY';
        // var RESULT_FAILURE = 'FAILED';
        var RESULT_SUCCESS = 'COMPLETE';

        if (res.status === RESULT_PREPARED) {
          self.results[workspace].resultId = res.resultId;
          return deferredResult.resolve(res.resultId);
        }

        if (!self.results[workspace].resultId) {
          self.results[workspace].resultId = res.resultId;
          deferredResult.resolve(res.resultId);
        }

        $window.io.socket.off(requestId);

        if (res.status === RESULT_SUCCESS) {
          return searchSrv.getResultOutput(self.results[workspace].resultId, 'summary.json')
            .then(function (artifactFile) {
              self.results[workspace].rawResult = artifactFile;

              if (artifactFile.mode === 'run' && artifactFile.parameters && artifactFile.valid === false) {
                deferredStatus.reject({ error: 'The report was called with invalid parameters.', params: artifactFile.parameters });
                return;
              }

              if (artifactFile.extended_data && artifactFile.extended_data.data_files) {
                artifactFile.extended_data.data_files.forEach(function (filename) {
                  self.results[workspace].artifacts.push(filename);
                });
              }

              self.results[workspace].status = ImatReport.prototype.STATUS.COMPLETE;
              deferredStatus.resolve({ data: artifactFile, id: res.resultId });
            }, function () { // This handler is (was?) required to catch rejections from getArtifactsFile().
              self.results[workspace].status = ImatReport.prototype.STATUS.ERROR;
              deferredStatus.reject({ error: 'The report\'s summary could not be retrieved.' });
            });
        }

        self.results[workspace].status = ImatReport.prototype.STATUS.ERROR;

        // TODO getArtifactsFile('stdout.log') for debugging?
        if (res.message) {
          deferredStatus.reject({ error: 'The report failed with the message "' + res.message + '"' });
        } else {
          deferredStatus.reject({ error: 'The report failed.' });
        }
      });

      return deferredResult.promise;
    };

    // ---------------------------------
    // Helpers
    // ---------------------------------

    function checkArtifactMasterStatus (workspace, deferred, canArtifactPoll) {
      if (canArtifactPoll) {
        searchSrv.getResultOutput(workspace.resultId, 'master.status')
          .then(function (status) {
            if (status !== '') {
              workspace.artifactStatus = status;
              deferred.notify({ artifactStatus: status });
            }
          });
      }
    }

    function checkInvalidParams (paramsToCheck) {
      var invalid = [];

      angular.forEach(paramsToCheck, function (param, paramName) {
        if (!Object.prototype.hasOwnProperty.call(param, 'type') || !Object.prototype.hasOwnProperty.call(param, 'value')) {
          invalid.push(paramName);
        }
      });
      return invalid;
    }

    function getResultStatus (resultId) {
      return $http({ method: 'GET', url: '/search/results/' + resultId + '/status' })
        .then(function (response) {
          return response.data.result;
        });
    }

    function getRequestId () {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      for (var i = 0; i < 16; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
      }
      return result;
    }

    function statusPoll (workspace, deferred, canArtifactPoll) {
      var pollResponse = { data: null, id: workspace.resultId, msg: '' };

      if (workspace.status === ImatReport.prototype.STATUS.CANCELED) {
        if (workspace.resultId) {
          $rootScope.$broadcast('report-result-' + workspace.resultId, 'aborted');
        }
        pollResponse.msg = 'The report was canceled.';
        deferred.reject(pollResponse);
        return;
      }

      getResultStatus(workspace.resultId)
        .then(function (result) {
          switch (result.exit_code) {
            case 'None':
              checkArtifactMasterStatus(workspace, deferred, canArtifactPoll);
              $timeout(function () { statusPoll(workspace, deferred, canArtifactPoll); }, POLL_INTERVAL);
              break;

            case '0':
              checkArtifactMasterStatus(workspace, deferred, canArtifactPoll);
              // Return so getResultStatus().catch() can handle rejections.
              return searchSrv.getResultOutput(workspace.resultId, 'summary.json')
                .then(function (artifactFile) {
                  workspace.rawResult = artifactFile;
                  if (artifactFile.mode === 'run' && artifactFile.parameters && artifactFile.valid === false) {
                    pollResponse.msg = 'The report was called with invalid parameters.';
                    pollResponse.params = artifactFile.parameters;
                    return $q.reject(pollResponse);
                  }
                  if (artifactFile.extended_data && artifactFile.extended_data.data_files) {
                    artifactFile.extended_data.data_files.forEach(function (filename) {
                      workspace.artifacts.push(filename);
                    });
                  } else {
                    //Complete hack to get uncontained query for immunization to work
                    workspace.artifacts.push('results.json');
                  }
                  workspace.status = ImatReport.prototype.STATUS.COMPLETE;
                  $rootScope.$broadcast('report-result-' + workspace.resultId, 'complete');

                  pollResponse.data = artifactFile;
                  deferred.resolve(pollResponse);
                }, function () { // This handler is (was?) required to catch rejections from getArtifactsFile().
                  return $q.reject();
                })
                .catch(function (reason) {
                  reason = reason || 'The report\'s summary could not be retrieved.';
                  return $q.reject(reason);
                });

            default:
            // Return so getResultStatus().catch() can handle rejections.
              return searchSrv.getResultOutput(workspace.resultId, 'stdout.log')
                .then(function (artifactFile) {
                  return $q.reject(artifactFile);
                }, function () { // This handler is (was?) required to catch rejections from getArtifactsFile().
                  return $q.reject('The report failed with the message "' + result.exit_message + '"');
                });
          }
        })
        .catch(function (reason) {
          reason = reason || 'The report failed to run.';
          if (angular.isObject(reason)) {
            angular.extend(pollResponse, reason);
          } else {
            pollResponse.msg = reason;
          }
          workspace.status = ImatReport.prototype.STATUS.ERROR;
          $rootScope.$broadcast('report-result-' + workspace.resultId, 'failed');
          deferred.reject(pollResponse);
        });
    }

    return ImatReport;
  }
})();
