(function () {
  'use strict';

  angular
    .module('admin')
    .controller('CreateCollectionCtrl', CreateCollectionCtrl);

  CreateCollectionCtrl.$inject = ['$filter', '$state', 'ArrayFnSrv', 'collectionsSrv', 'GroupsSrv', 'ProvidersSrv', 'psNotification'];

  function CreateCollectionCtrl ($filter, $state, ArrayFnSrv, collectionsSrv, GroupsSrv, ProvidersSrv, psNotification) {
    var vm = this;

    var allGroups = [];
    var STATE_RETURN = 'app.admin.collections';
    var GROUPS_GET_ERROR = 'Unable to retrieve the groups list.';
    var COLLECTION_SUCCESS_MESSAGE = 'Collection created.';
    var COLLECTION_ADD_ERROR = 'Unable to create the collection.';
    var PROVIDERS_GET_ERROR = 'Unable to retrieve the providers.';

    // Properties
    vm.collection = {
      name: '',
      description: '',
      criteriaQuery: '',
      groups: [],
      provider: $state.params.provider || 'default'
    };
    vm.availableGroups = [];
    vm.availableGroupsSearch = '';
    vm.includedGroupsSearch = '';
    vm.providers; // eslint-disable-line no-unused-expressions

    // Methods
    vm.save = save;
    vm.cancel = cancel;
    vm.clearAvailableGroupsSearch = clearAvailableGroupsSearch;
    vm.clearIncludedGroupsSearch = clearIncludedGroupsSearch;
    vm.addAll = addAll;
    vm.addGroup = addGroup;
    vm.filterAvailableGroups = filterAvailableGroups;
    vm.filterGroupsByProvider = filterGroupsByProvider;
    vm.filterIncludedGroups = filterIncludedGroups;
    vm.removeGroup = removeGroup;
    vm.removeAll = removeAll;
    vm.filterAvailableList = filterAvailableList;
    vm.filterIncludedList = filterIncludedList;

    activate();

    function activate () {
      _getProviderList();
      filterGroupsByProvider(true);
    }

    //= ================================
    // Public interface
    //= ================================

    function cancel () {
      $state.go(STATE_RETURN);
    }

    function clearAvailableGroupsSearch () {
      vm.availableGroupsSearch = '';
    }

    function clearIncludedGroupsSearch () {
      vm.includedGroupsSearch = '';
    }

    function save () {
      vm.collection.includeAll = vm.accessType === 'full';

      collectionsSrv.createCollection(vm.collection)
        .then(function () {
          psNotification.success(COLLECTION_SUCCESS_MESSAGE);
          $state.go(STATE_RETURN);
        })
        .catch(function () {
          psNotification.error(COLLECTION_ADD_ERROR);
        });
    }

    function filterAvailableList (groups) {
      return filterList(groups.toLowerCase(), vm.availableGroupsSearch.toLowerCase());
    }

    function filterIncludedList (groups) {
      return filterList(groups.toLowerCase(), vm.includedGroupsSearch.toLowerCase());
    }

    function filterAvailableGroups () {
      return $filter('filter')(vm.availableGroups, function (group) {
        return group.indexOf(vm.availableGroupsSearch);
      });
    }

    function filterGroupsByProvider (force) {
      GroupsSrv.getList(force).then(function (groups) {
        allGroups = [];
        groups.forEach(function (group) {
          if (group.provider === vm.collection.provider) {
            allGroups.push(group.name);
          }
        });
        vm.collection.groups = [];
        vm.availableGroups = angular.copy(allGroups);
        filterIncludedGroups();
        filterAvailableGroups();
      })
        .catch(function () {
          psNotification.error(GROUPS_GET_ERROR);
        });
    }

    function filterIncludedGroups () {
      return $filter('filter')(vm.collection.groups, function (group) {
        return group.indexOf(vm.includedGroupsSearch);
      });
    }

    function addAll () {
      var visibleGroups = $filter('filter')(vm.availableGroups, { $: vm.availableGroupsSearch });
      vm.availableGroups = ArrayFnSrv.diff(vm.availableGroups, visibleGroups).sort(ArrayFnSrv.sortNames);
      vm.collection.groups = vm.collection.groups.concat(visibleGroups).sort(ArrayFnSrv.sortNames);
      clearAvailableGroupsSearch();
    }

    function addGroup (group) {
      vm.availableGroups.splice(vm.availableGroups.indexOf(group), 1);
      vm.collection.groups.push(group);
    }

    function removeAll () {
      var visibleGroups = $filter('filter')(vm.collection.groups, { $: vm.includedGroupsSearch });

      vm.collection.groups = ArrayFnSrv.diff(vm.collection.groups, visibleGroups).sort(ArrayFnSrv.sortNames);
      vm.availableGroups = vm.availableGroups.concat(visibleGroups).sort(ArrayFnSrv.sortNames);
      clearIncludedGroupsSearch();
    }

    function removeGroup (group) {
      vm.availableGroups.push(group);
      vm.collection.groups.splice(vm.collection.groups.indexOf(group), 1);
    }

    //= ================================
    // Private interface
    //= ================================

    function _getProviderList () {
      return ProvidersSrv.getProviderList()
        .then(function (data) {
          vm.providers = data;
        })
        .catch(function () {
          psNotification.error(PROVIDERS_GET_ERROR);
        });
    }

    function filterList (list, input) {
      if (!input) {
        return true;
      }

      return (list.indexOf(input) !== -1) ? true : (list.indexOf(input) !== -1);
    }
  }
})();
