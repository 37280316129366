(function () {
  'use strict';

  angular.module('admin')
    .controller('CollectionsCtrl', CollectionsCtrl);

  CollectionsCtrl.$inject = ['$filter', '$mdDialog', '$mdSidenav', '$scope', '$state', 'collectionsSrv', 'ProvidersSrv', 'psNotification'];

  function CollectionsCtrl ($filter, $mdDialog, $mdSidenav, $scope, $state, collectionsSrv, ProvidersSrv, psNotification) {
    var vm = this;
    var COLLECTION_DELETE_ERROR = 'Unable to delete the collection.';
    var COLLECTION_DELETE_SUCCESS = 'Collection deleted.';
    var COLLECTION_GET_ERROR = 'Unable to retrieve the collection list.';
    var COLLECTION_PUT_ERROR = 'Unable to update the collection.';
    var COLLECTION_PUT_SUCCESS = 'Collection updated.';
    var FILTER_KEY_PROVIDER = 'provider';
    var STATE_CREATE = 'app.admin.collections.create';

    // Properties
    $scope.PUBLIC_COLLECTION = 'c_PUB';
    $scope.SYSTEM_COLLECTION = 'c_SYS';
    vm.collections = [];
    vm.filters = [];
    vm.providerCount = 1;
    vm.reloadCollections = false;
    vm.searchText = '';
    vm.selectedCollection = {};
    vm.selectedFilter = '';

    // Methods
    vm.clearSelection = clearSelection;
    vm.create = createcollection;
    vm.delete = deleteCollection;
    vm.editCollectionCriteria = editCollectionCriteria;
    vm.editCollectionDescription = editCollectionDescription;
    vm.filterCollections = filterCollections;
    vm.manageGroups = manageGroups;
    vm.refreshList = refreshList;
    vm.resetFilters = resetFilters;
    vm.selectCollection = selectCollection;
    vm.textFilterList = textFilterList;

    activate();

    function activate () {
      filterCollections(true)
        .then(function () {
          ProvidersSrv.getProviderList()
            .then(function (providers) {
              vm.providerCount = providers.length;
              if (vm.providerCount > 1) {
                providers.forEach(function (provider) {
                  vm.filters.push({
                    name: 'Provider: ' + provider,
                    key: FILTER_KEY_PROVIDER,
                    value: provider
                  });
                });
              }
            });
        });
    }

    function textFilterList (collection) {
      if (!vm.searchText) {
        return true;
      }
      return (collection.name.toLowerCase().indexOf(vm.searchText.toLowerCase()) !== -1) ? true : (collection.description.toLowerCase().indexOf(vm.searchText.toLowerCase()) !== -1);
    }

    function editCollectionCriteria (ev) {
      $mdDialog.show({
        controller: 'EditCollectionCriteriaModalCtrl',
        controllerAs: 'modal',
        locals: { modalCollection: angular.copy(vm.selectedCollection) },
        templateUrl: '/ui/admin/collections/editCollectionCriteriaModal.html',
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: true
      })
        .then(function (collection) {
          collectionsSrv.editCollection(vm.selectedCollection.provider, collection)
            .then(function (mwCollection) {
              angular.extend(vm.selectedCollection, { includeAll: false, criteriaQuery: '' }, mwCollection);
              psNotification.success(COLLECTION_PUT_SUCCESS);
            })
            .catch(function () {
              psNotification.error(COLLECTION_PUT_ERROR);
            });
        })
        .catch(function () {
          // Dismissed.
        });
    }

    function editCollectionDescription (ev) {
      $mdDialog.show({
        controller: 'EditCollectionDescriptionModalCtrl',
        controllerAs: 'modal',
        locals: { modalCollection: angular.copy(vm.selectedCollection) },
        templateUrl: '/ui/admin/collections/editCollectionDescriptionModal.html',
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: true
      })
        .then(function (collection) {
          collectionsSrv.editCollection(vm.selectedCollection.provider, collection)
            .then(function (mwCollection) {
              angular.extend(vm.selectedCollection, mwCollection);
              psNotification.success(COLLECTION_PUT_SUCCESS);
            })
            .catch(function () {
              psNotification.error(COLLECTION_PUT_ERROR);
            });
        })
        .catch(function () {
          // Dismissed.
        });
    }

    function filterCollections (force, selectedId) {
      vm.reloadCollections = true;
      return collectionsSrv.getCollections(force)
        .then(function (collections) {
          var selectedCollection;
          if (vm.selectedFilter && vm.selectedFilter.key !== FILTER_KEY_PROVIDER) {
            vm.collections = $filter('filter')(collections, function (value) {
              return value[vm.selectedFilter.key] === 'true';
            });
          } else {
            vm.collections = $filter('filter')(collections, function (value) {
              return (vm.selectedFilter) ? value[vm.selectedFilter.key] === vm.selectedFilter.value : true;
            });
          }
          if (selectedId) {
            selectedCollection = vm.collections.filter(function (c) { return c.id === selectedId; }).pop();
            if (selectedCollection) {
              selectCollection(selectedCollection);
            }
          }
        })
        .catch(function () {
          psNotification.error(COLLECTION_GET_ERROR);
        })
        .finally(function () {
          vm.reloadCollections = false;
        });
    }

    function clearSelection () {
      if (vm.selectedCollection.id) {
        delete vm.selectedCollection.selected;
        vm.selectedCollection = {};
      }
      $mdSidenav('collectionDetails').close();
    }

    function createcollection () {
      $state.go(STATE_CREATE, { provider: vm.selectedFilter && (vm.selectedFilter.key === FILTER_KEY_PROVIDER ? vm.selectedFilter.value : 'default') });
    }

    function manageGroups (ev) {
      $mdDialog.show({
        controller: 'ManageCollectionGroupsModalCtrl',
        controllerAs: 'modal',
        locals: { modalGroups: vm.selectedCollection.groups, provider: vm.selectedCollection.provider },
        templateUrl: '/ui/admin/collections/manageCollectionGroupsModal.html',
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: true
      })
        .then(function (groupActions) {
          collectionsSrv.updateGroups(vm.selectedCollection, groupActions.added, groupActions.removed)
            .then(function (mwCollection) {
              angular.extend(vm.selectedCollection, mwCollection);
              psNotification.success(COLLECTION_PUT_SUCCESS);
            })
            .catch(function () {
              psNotification.error("Unable to update the collection's groups.");
            });
        });
    }

    function refreshList (selectedId) {
      vm.reloadCollections = true;
      filterCollections(true, selectedId)
        .finally(function () {
          vm.reloadCollections = false;
        });
    }

    function resetFilters () {
      vm.searchText = '';
      vm.selectedFilter = '';
      filterCollections(false);
    }

    function selectCollection (collection) {
      if (vm.selectedCollection.id) {
        clearSelection();
      }
      vm.selectedCollection = collection;
      vm.selectedCollection.selected = true;
      $mdSidenav('collectionDetails').open();
    }

    function deleteCollection (provider, collectionId) {
      var confirm = $mdDialog.confirm()
        .title('Confirm Delete')
        .textContent('Are you sure? This action cannot be undone.')
        .ariaLabel('Confirm Delete')
        .ok('Delete')
        .cancel('Cancel');

      $mdDialog.show(confirm)
        .then(function () {
          collectionsSrv.deleteCollections(provider, collectionId)
            .then(function () {
              clearSelection();
              filterCollections(true);
              psNotification.success(COLLECTION_DELETE_SUCCESS);
            })
            .catch(function () {
              psNotification.error(COLLECTION_DELETE_ERROR);
            });
        });
    }
  }
})();
