(function () {
  'use strict';

  angular.module('imat.directives', [
    'dashboardApp.constants', // DASHBOARD
    'imat.services', // DashboardItemClass
    'ngAnimate', // $animateCss
    'ngMaterial'// $mdSelect
  ]);
})();
