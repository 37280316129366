(function () {
  'use strict';

  angular.module('tools')
    .config(config);

  config.$inject = ['$stateRegistryProvider'];

  function config ($stateRegistryProvider) {
    $stateRegistryProvider.register({
      name: 'app.tools',
      abstract: true,
      url: '/tools',
      data: {
        roles: []
      },
      views: {
        'content@app': {
          templateUrl: '/ui/_tools/tools-layout.html'
        }
      }
    });
    $stateRegistryProvider.register({
      name: 'app.tools.main',
      url: '/',
      views: {
        'inner-content@app.tools': {
          templateUrl: '/ui/_tools/tools-main.html',
          controller: 'ToolsMainCtrl',
          controllerAs: 'ctrl'
        }
      }
    });
    $stateRegistryProvider.register({
      name: 'app.tools.platform-status',
      url: '/platform-status',
      views: {
        'inner-content@app.tools': {
          templateUrl: '/ui/_tools/platform-status/platform-status.html',
          controller: 'PlatformStatusCtrl',
          controllerAs: 'ctrl'
        }
      }
    });
  }
})();
