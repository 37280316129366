(function () {
  'use strict';

  angular.module('account')
    .controller('AccountHomeCtrl', AccountHomeCtrl);

  AccountHomeCtrl.$inject = ['ProvidersSrv', 'subscriptionsSrv', 'userSession'];

  function AccountHomeCtrl (ProvidersSrv, subscriptionsSrv, userSession) {
    var vm = this;
    vm.providerCount = 0;
    vm.user = {};
    vm.showSubscriptions = false;

    activate();

    function activate () {
      vm.user = userSession.getUserData();
      ProvidersSrv.getProviderList().then(function (res) {
        vm.providerCount = res.length;
      });

      subscriptionsSrv.loadConfig()
        .then(function () {
          vm.showSubscriptions = subscriptionsSrv.isEnabled() && subscriptionsSrv.hasAccess();
        });
    }
  }
})();
