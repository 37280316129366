(function () {
  'use strict';

  angular.module('admin')
    .controller('AdminSymbolsCtrl', AdminSymbolsCtrl);

  AdminSymbolsCtrl.$inject = [
    '$mdDialog', '$mdSidenav', '$q', '$timeout',
    'SymbolsSrv', 'psNotification'
  ];

  function AdminSymbolsCtrl (
    $mdDialog, $mdSidenav, $q, $timeout,
    SymbolsSrv, psNotification
  ) {
    var vm = this;
    var categorizedList = {};

    // Properties
    vm.activeSymbol = {};
    vm.count = 0;
    vm.loading = { symbols: true };
    vm.searchText = '';
    vm.symbols = [];

    // Methods
    vm.applyChanges = applyChanges;
    vm.clearSelection = clearSelection;
    vm.deleteSymbol = deleteSymbol;
    vm.editDescription = editDescription;
    vm.editItems = editItems;
    vm.filterList = filterList;
    vm.refreshList = refreshList;
    vm.resetFilters = resetFilters;
    vm.selectSymbol = selectSymbol;

    activate();

    function activate () {
      _getSymbols(true);
    }

    //= ================================
    // Public interface
    //= ================================

    function applyChanges () {
      var promises = [];

      promises.push(SymbolsSrv.postSymbolFields());
      promises.push(SymbolsSrv.postSymbolValues());

      $q.all(promises)
        .then(function () {
          vm.loading.symbols = true;
          $timeout(function () { _getSymbols(true); }, 3500);
          psNotification.success('Apply changes request received.');
        })
        .catch(function () {
          psNotification.error('Unable to apply changes.');
        });
    }

    function clearSelection () {
      _setActiveSymbol();
      $mdSidenav('symbolDetails').close();
    }

    function deleteSymbol (symbol) {
      var promises = [];
      var fieldsSymbol = angular.copy(symbol);
      var valuesSymbol = angular.copy(symbol);

      var confirm = $mdDialog.confirm()
        .title('Confirm Delete')
        .textContent('Delete the symbol ' + symbol.name + ' from the category ' + symbol.category + '?')
        .targetEvent()
        .ok('Delete')
        .cancel('Cancel');

      $mdDialog.show(confirm)
        .then(function () {
          fieldsSymbol.type = 'fields';
          valuesSymbol.type = 'values';
          promises.push(SymbolsSrv.deleteSymbol(fieldsSymbol));
          promises.push(SymbolsSrv.deleteSymbol(valuesSymbol));
          $q.all(promises)
            .then(function () {
              vm.loading.symbols = true;
              $timeout(function () { _getSymbols(true); }, 3500);
              clearSelection();
              psNotification.success('Delete request received.');
            })
            .catch(function () {
              psNotification.error('Unable to delete symbol.');
            });
        });
    }

    function editDescription (ev) {
      var promises = [];
      var fieldsSymbol = angular.copy(vm.activeSymbol);
      var valuesSymbol = angular.copy(vm.activeSymbol);

      $mdDialog.show({
        controller: 'EditSymbolDescriptionModalCtrl',
        controllerAs: 'modal',
        locals: { symbolDesc: fieldsSymbol.desc },
        templateUrl: '/ui/admin/symbols/editSymbolDescriptionModal.html',
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: true
      })
        .then(function (description) {
          fieldsSymbol.type = 'fields';
          valuesSymbol.type = 'values';
          promises.push(SymbolsSrv.updateSymbol(fieldsSymbol, { desc: description }));
          promises.push(SymbolsSrv.updateSymbol(valuesSymbol, { desc: description }));
          $q.all(promises)
            .then(function () {
              psNotification.success('Symbol description updated.');
              $timeout(function () { _getSymbols(true); }, 1000);
            })
            .catch(function () {
              psNotification.error('Unable to edit description.');
            });
        });
    }

    function editItems (ev) {
      var promises = [];
      var fieldsSymbol = angular.copy(vm.activeSymbol);
      var valuesSymbol = angular.copy(vm.activeSymbol);

      $mdDialog.show({
        controller: 'ManageSymbolItemsModalCtrl',
        controllerAs: 'modal',
        locals: {
          items: angular.copy(vm.activeSymbol.items)
        },
        templateUrl: '/ui/admin/symbols/manageSymbolItemsModal.html',
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: true
      })
        .then(function (items) {
          fieldsSymbol.type = 'fields';
          valuesSymbol.type = 'values';
          promises.push(SymbolsSrv.updateSymbol(fieldsSymbol, { items: items }));
          promises.push(SymbolsSrv.updateSymbol(valuesSymbol, { items: items }));
          $q.all(promises)
            .then(function () {
              psNotification.success('Symbol values updated.');
              $timeout(function () { selectSymbol(vm.activeSymbol); }, 500);
            })
            .catch(function () {
              psNotification.error('Unable to update symbol values.');
            });
        });
    }

    function filterList (symbol) {
      var regex = new RegExp(vm.searchText, 'i');

      if (!vm.searchText) {
        return true;
      }
      if (!symbol.sectionHeader) {
        return regex.test(symbol.name) || regex.test(symbol.desc);
      }
      // check if any symbols to display in section
      return (categorizedList[symbol.category].join('~~~').search(regex) >= 0);
    }

    function refreshList () {
      _getSymbols(true);
    }

    function resetFilters () {
      vm.searchText = '';
    }

    function selectSymbol (symbol) {
      _setActiveSymbol(symbol);
      $mdSidenav('symbolDetails').open();
      SymbolsSrv.getSymbol(symbol)
        .then(function (symbolData) {
        // populate items
          vm.activeSymbol.items = symbolData.items;
        });
    }

    //= ================================
    // Private interface
    //= ================================

    function _getSymbols (force) {
      vm.loading.symbols = true;
      SymbolsSrv.getCombinedList(force)
        .then(function (symbols) {
          vm.symbols = symbols;
          if (vm.activeSymbol.selected) {
            for (var i = 0, ii = vm.symbols.length; i < ii; ++i) {
              if (vm.symbols[i].category === vm.activeSymbol.category && vm.symbols[i].name === vm.activeSymbol.name) {
                selectSymbol(vm.symbols[i]);
                break;
              }
            }
          }
          return SymbolsSrv.getCombinedListCounts();
        })
        .then(function (counts) {
          vm.count = counts.symbols;
          return SymbolsSrv.getCategorizedList();
        })
        .then(function (catList) {
          return categorizedList = catList; // eslint-disable-line no-return-assign
        })
        .finally(function () {
          vm.loading.symbols = false;
        });
    }

    function _setActiveSymbol (symbol) {
      delete vm.activeSymbol.selected;
      delete vm.activeSymbol.items;
      vm.activeSymbol = {};
      if (symbol) {
        vm.activeSymbol = symbol;
        vm.activeSymbol.selected = true;
      }
    }
  }
})();
