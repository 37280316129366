(function () {
  'use strict';

  angular.module('imat.services')
    .factory('browserSrv', browserSrv);

  browserSrv.$inject = [];

  function browserSrv () {
    var BROWSERS = {
      chrome: 'Google Chrome',
      firefox: 'Mozilla Firefox',
      edge: 'Microsoft Edge',
      ie: 'Internet Explorer 11'
    };
    var dismissed = false;

    var service = {
      checkBrowserSupport: checkBrowserSupport,
      dismissCheck: dismissCheck,
      getSupportedBrowsers: getSupportedBrowsers
    };

    return service;

    // eslint-disable-next-line complexity
    function checkBrowserSupport (browsers) {
      browsers = browsers || [];
      var ua = navigator.userAgent;

      if (dismissed) {
        return true;
      }

      if (browsers.length) {
        // Firefox
        if (ua.indexOf('Firefox') > -1 && ua.indexOf('Seamonkey') === -1 && browsers.indexOf('firefox') > -1) {
          return true;
        }
        // Chrome
        if (ua.indexOf('Chrome') > -1 && ua.indexOf('Chromium') === -1 && browsers.indexOf('chrome') > -1) {
          return true;
        }
        // Edge
        if (ua.indexOf('Edge') > -1 && browsers.indexOf('edge') > -1) {
          return true;
        }
        // IE 11
        if (ua.indexOf('rv:11.0) like Gecko') > -1 && browsers.indexOf('ie') > -1) {
          return true;
        }
        return false;
      }
      // Assume browser support if not IE < 11 & no supportedBrowsers property
      return true;
    }

    function dismissCheck () {
      dismissed = true;
    }

    function getSupportedBrowsers (state, pretty) {
      var browsers = findSupportedBrowsers(state);
      if (pretty) {
        return browsers.map(function (browser) {
          return BROWSERS[browser];
        });
      }
      return browsers;
    }

    function findSupportedBrowsers (state) {
      return (state.data && state.data.supportedBrowsers) ? state.data.supportedBrowsers : [];
    }
  }
})();
