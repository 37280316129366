(function () {
  'use strict';

  angular
    .module('vhr')
    .controller('HieDocumentsCtrl', HieDocumentsCtrl);

  HieDocumentsCtrl.$inject = [
    '$log', '$q', '$scope', '$state', '$transition$', 'psNotification', 'vhrConfigSrv', 'vhrGridSrv', 'vhrHieApi', 'vhrPersistence'
  ];

  function HieDocumentsCtrl (
    $log, $q, $scope, $state, $transition$, psNotification, vhrConfigSrv, vhrGridSrv, vhrHieApi, vhrPersistence
  ) {
    var vm = this;
    var gridSource = 'documents';

    // Messages
    var PURPOSE_OF_USE_ERROR = 'Error retrieving purpose of use.';

    // Properties
    vm.communityId = $transition$.params().community || vhrPersistence.getData('hieDocumentsCommunityId') || null;
    vm.targetOid = $transition$.params().targetOid || vhrPersistence.getData('hieDocumentsTargetOid') || null;
    vm.config = {};
    vm.gridApi = {};
    vm.gridConfig = {};
    vm.loaded = { api: false };
    vm.patientId = $transition$.params().patient || vhrPersistence.getData('hieDocumentsPatientId') || null;

    // Methods
    vm.back = back;

    activate();

    // Implementation

    function activate () {
      vm.config = vhrConfigSrv.getPageByState($state.current.name);

      // Catch refresh event thrown by hie.controller.js
      $scope.$on('vhrHieRefreshed', refresh);

      setGridConfig();

      vhrHieApi.getPurpose()
        .then(function () {
          loadGrid();
        })
        .catch(function () {
          psNotification.error(PURPOSE_OF_USE_ERROR);
        });

      vhrPersistence.setData('hieDocumentsCommunityId', vm.communityId);
      vhrPersistence.setData('hieDocumentsPatientId', vm.patientId);
      vhrPersistence.setData('hieDocumentsTargetOid', vm.targetOid);
      vhrPersistence.setData('hieState', $state.current.name);
    }

    // ---------------------------------
    // Public interface
    // ---------------------------------

    function back () {
      $state.go('app.vhr.patient.hie.hie-communities', null, { location: 'replace' });
      return false;
    }

    // ---------------------------------
    // Private interface
    // ---------------------------------

    function calculateDateRange (months) {
      months = months || 12;

      var visitStartDate = new Date();
      visitStartDate.setMonth(visitStartDate.getMonth() - months);
      visitStartDate = visitStartDate.toISOString().substr(0, 10).replace(/-/g, '');
      var visitStartDateTo = new Date().toISOString().substr(0, 10).replace(/-/g, '');

      var visitEndDate = new Date();
      visitEndDate.setMonth(visitEndDate.getMonth() - months);
      visitEndDate = visitEndDate.toISOString().substr(0, 10).replace(/-/g, '');
      var visitEndDateTo = new Date().toISOString().substr(0, 10).replace(/-/g, '');

      var dateRange = {
        visitStartDate: visitStartDate,
        visitStartDateTo: visitStartDateTo,
        visitEndDate: visitEndDate,
        visitEndDateTo: visitEndDateTo
      };

      return dateRange;
    }

    function handleGridRowSelectionChanged (row) { // jshint ignore:line
      $state.go('app.vhr.patient.hie.hie-document', {
        /* MedFX is not sending back correct sourceOid - using vm.communityId */
        /* Zen using vm.communityId (represents sourceOid value) */
        /* Rosetta version does odd stuff to targetOid/sourceOid, so hacking to use assigningAuthority */
        community: row.entity.assigningAuthority || vm.communityId,
        document: row.entity.documentId,
        repositoryId: row.entity.repositoryId,
        /* Bronx state lookup value hack */
        documentsLookupOid: vm.communityId,
        targetOid: vm.targetOid
      });
    }

    function loadDocuments (targetOid, communityId, patientId, force) {
      var fields = calculateDateRange(vm.config.dateRangeMonths);

      return vhrHieApi.getDocuments(targetOid, communityId, [patientId], fields, force)
        .then(function (response) {
          // Munge response...
          return response;
        })
        .catch(function (reason) {
          $log.debug(reason);
          return $q.reject();
        });
    }

    function loadGrid (force) {
      vm.loaded.api = false;

      loadDocuments(vm.targetOid, vm.communityId, vm.patientId, force)
        .then(function (response) {
          vm.gridConfig.data = response;
        })
        .catch(function (reason) {
          reason = reason || 'Failed to load the document list.';
          psNotification.error(reason);
        })
        .finally(function () {
          vm.loaded.api = true;
        });
    }

    function refresh () {
      vm.gridConfig.data.splice(0);
      loadGrid(true);
    }

    // For HIE grids, `gridType` TIES RECORDS TO SPECIFIC GRIDS.
    // If data is returned from the report inside a property whose name matches
    // a `source` value in a page's `grids` collection, then that data will be
    // tied to a grid prepared for that data. If the property name does not
    // match any configured `source` value, then its data will be ignored (it's
    // for a different HIE view).
    function setGridConfig () {
      var grids = vm.config.grids2.filter(function (g) { return g.source === gridSource; });// Should be only one.

      grids.forEach(function (grid) {
        var gridDef = vhrConfigSrv.raw.grids[grid.grid];
        var gridConfig = gridDef.uiGridConfig || {};
        var gridType = grid.source.toUpperCase();

        vm.gridConfig = {};

        gridConfig = vhrGridSrv.getGridConfig($scope, gridType, vhrGridSrv.CONFIG.HIE, gridConfig, gridDef.fieldOrder);
        angular.extend(vm.gridConfig, gridConfig);

        // vhrGridSrv.setOnRegisterApi($scope, vm.gridConfig, vm.gridApi, gridType);
        vm.gridConfig.onRegisterApi = function (gridApi) {
          if (gridApi.selection) {
            gridApi.selection.on.rowSelectionChanged($scope, handleGridRowSelectionChanged);
          }
          vm.gridApi = gridApi;
        };
      });
    }
  }
})();
