(function () {
  'use strict';

  angular.module('mpiApp.personas')
    .controller('PiRecordDeleteModalCtrl', PiRecordDeleteModalCtrl);

  PiRecordDeleteModalCtrl.$inject = ['$mdDialog', 'selected'];

  function PiRecordDeleteModalCtrl ($mdDialog, selected) {
    var vm = this;

    vm.scrub = 0;
    vm.comment = '';

    vm.selected = selected;
    vm.cancel = cancel;
    vm.submit = submit;
    vm.selectedText = selected.length > 1 ? 'records' : 'record';

    function cancel () {
      $mdDialog.cancel();
    }

    function submit () {
      $mdDialog.hide({ scrub: vm.scrub, comment: vm.comment });
    }
  }
})();
