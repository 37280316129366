(function () {
  'use strict';

  angular.module('reports.services')
    .factory('ReportViewer', ReportViewer);

  ReportViewer.$inject = [];

  function ReportViewer () {
    var service;

    service = {
      content: false,
      loading: true,
      raw: false,
      reportName: false,
      resultId: '',
      artifacts: []
    };

    return service;
  }
})();
