(function () {
  'use strict';

  angular.module('vhr.directives')
    .directive('vhrAgeBadge', vhrAgeBadge);

  vhrAgeBadge.$inject = ['$filter', 'vhrConfigSrv', 'vhrPatientSrv'];

  function vhrAgeBadge ($filter, vhrConfigSrv, vhrPatientSrv) {
    var directive;

    directive = {
      link: postLink,
      restrict: 'E',
      scope: true,
      templateUrl: '/ui/vhr/directives/vhrAgeBadge/vhr-age-badge.html'
    };

    return directive;

    function postLink (scope, element) {
      var ageInYears;
      var ageBadges;
      var ageLabels;

      scope.isDeceased = !!vhrPatientSrv.isDeceased();
      element.toggleClass('deceased', scope.isDeceased);
      element.toggleClass('underage', vhrPatientSrv.isUnderage());

      if (angular.isDefined(vhrConfigSrv.raw.ages)) {
        ageInYears = vhrPatientSrv.getAges(['years'])['years']; // eslint-disable-line dot-notation
        ageBadges = angular.copy(vhrConfigSrv.raw.ages);
        ageLabels = angular.copy(vhrConfigSrv.raw.ages);

        Object.keys(ageBadges).forEach(function (key) { ageBadges[key] = ageBadges[key].badge; });
        Object.keys(ageLabels).forEach(function (key) { ageLabels[key] = ageLabels[key].label; });

        scope.badge = $filter('vhrJsonRange')(ageInYears, ageBadges);
        scope.label = $filter('vhrJsonRange')(ageInYears, ageLabels);
        scope.class = '';

        if (scope.label) {
          scope.class = String(scope.label).toLowerCase().replace(/\s+/g, '-');
          element.addClass(scope.class);
        }
      }
    }
  }
})();
