(function () {
  'use strict';

  angular
    .module('vhr.services')
    .factory('vhrPdfSrv', vhrPdfSrv);

  vhrPdfSrv.$inject = ['$log', '$q', '$rootScope'];

  function vhrPdfSrv ($log, $q, $rootScope) {
    var service;

    service = {
      PDF_SCALE_MIN: 0.2,
      PDF_SCALE_STEP: 0.2,
      get: get,
      setCanvasDimensions: setCanvasDimensions
    };

    if (typeof window.PDFJS === 'undefined') {
      throw new Error('vhrPdfSrv requires the pdf.js library.');
    }

    PDFJS.verbosity = 0;
    PDFJS.workerSrc = '/bower_components/pdf.worker.js';

    // ----------------------------------------
    // Public interface
    // ----------------------------------------

    function get (params, progressCb, passwordCb) {
      var pdfLoaderTask = PDFJS.getDocument(params);

      pdfLoaderTask.onProgress = progressCb || pdfLoaderTask.onProgress;
      pdfLoaderTask.onPassword = passwordCb || pdfLoaderTask.onPassword;

      pdfLoaderTask.then(function (pdf) {
        $rootScope.$broadcast('vhrPdfLoaded', pdf.fingerprint);
      }, function (reason) {
        $log.debug(reason);
        return $q.reject(reason);
      });

      return pdfLoaderTask;
    }

    function setCanvasDimensions (window, canvas, w, h) {
      var ratio = getCanvasBackingScale(window, canvas);

      canvas.width = Math.floor(w * ratio);
      canvas.height = Math.floor(h * ratio);
      canvas.style.width = Math.floor(w) + 'px';
      canvas.style.height = Math.floor(h) + 'px';
      canvas.getContext('2d').setTransform(ratio, 0, 0, ratio, 0, 0);
      return canvas;
    }

    // ----------------------------------------
    // Private interface
    // ----------------------------------------

    function getCanvasBackingScale (window, canvas) {
      var ctx = canvas.getContext('2d');
      var dpr = window.devicePixelRatio || 1;
      var bsr = ctx.webkitBackingStorePixelRatio ||
            ctx.mozBackingStorePixelRatio ||
            ctx.msBackingStorePixelRatio ||
            ctx.oBackingStorePixelRatio ||
            ctx.backingStorePixelRatio || 1;

      return dpr / bsr;
    }

    // ---------------------------------
    return service;
  }
})();
