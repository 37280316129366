(function () {
  'use strict';

  angular.module('dashboardApp')
    .controller('DashboardLayoutCtrl', DashboardLayoutCtrl);

  DashboardLayoutCtrl.$inject = [];

  function DashboardLayoutCtrl () {
    // ...
  }
})();
