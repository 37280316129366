(function () {
  'use strict';

  angular.module('admin')
    .controller('DataManagementCtrl', DataManagementCtrl);

  DataManagementCtrl.$inject = ['$scope', '$state'];

  function DataManagementCtrl ($scope, $state) {
    var vm = this;
    var deregisterWatch = $scope.$watch(function () { return $state.current.data.pageTitle; }, function (newVal) {
      vm.selectedTab = newVal.toLowerCase();
    });

    $scope.$on('$destroy', function () {
      deregisterWatch();
    });
  }
})();
