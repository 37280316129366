(function () {
  'use strict';

  angular.module('imat.directives')
    .directive('materialLoader', function () {
      return {
        templateUrl: '/ui/_shared/directives/material-loader/material-loader.html',
        restrict: 'AE',
        scope: {
          diameter: '@?',
          type: '@?',
          active: '=?'
        },
        link: function (scope, element, attrs) {
          if (!attrs.active) {
            scope.active = true;
          }
          if (/^'[^']+'$/.test(attrs.type)) {
            attrs.type = attrs.type.replace(/'/g, '');
          }
          if (!attrs.type || attrs.type !== 'linear') {
            attrs.type = 'circular';
          }
          if (attrs.diameter && /^\d+/.test(attrs.diameter)) {
            attrs.diameter = attrs.diameter.replace(/[^\d]+$/, '');
          }
        }
      };
    });
})();
