(function () {
  'use strict';

  angular.module('reports')
    .controller('RunCtrl', RunCtrl);

  RunCtrl.$inject = ['$q', '$sce', '$scope', 'psNotification', 'ReportViewer', 'searchSrv'];

  function RunCtrl ($q, $sce, $scope, psNotification, ReportViewer, searchSrv) {
    var vm = this;
    var blacklistExtensions = [
      'csv',
      'jpg',
      'png',
      'jpeg',
      'gif',
      'json'
    ];
    var artifacts = [];
    var order = 4;
    vm.loading = true;

    vm.results = [{
      data: '<div>Run the report.</div>',
      file: '',
      name: 'Output',
      order: 0,
      type: 'html'
    }];

    // vm.loading = ReportViewer.loading;

    activate();

    function activate () {
      $scope.$watch(function () { return ReportViewer.content; }, function (content) {
        if (content) {
          vm.loading = false;
          vm.content = content;
        }
      });
      $scope.$watch(function () { return ReportViewer.loading; }, function (loading) {
        vm.loading = true;
        if (!loading) {
          if (ReportViewer.artifacts.length) {
            loadArtifacts();
          } else {
            vm.loading = false;
          }
        }
      });
    }

    function loadArtifacts () {
      vm.results = [];
      artifacts = [];
      var artifactPromises = [];
      ReportViewer.artifacts.forEach(function (fileName) {
        var parts = fileName.toLowerCase().split('.');
        var extension = parts[parts.length - 1];
        if (!ReportViewer.raw && fileName !== 'results.json' && blacklistExtensions.indexOf(extension) !== -1) {
          return $q.resolve();
        }
        artifactPromises.push(searchSrv.getResultOutput(ReportViewer.resultId, fileName)
          .then(function (artifactFile) {
            var file = {
              fileName: fileName,
              name: parts[0],
              extension: extension,
              data: artifactFile
            };
            return $q.resolve(processFile(file));
          }));
      });

      $q.all(artifactPromises).finally(function () {
        psNotification.success('Report run complete.');
        if (artifacts.length) {
          vm.results = artifacts;
        } else {
          vm.results = [{
            data: '<div>No files found.</div>',
            file: '',
            name: 'Output',
            order: 0,
            type: 'html'
          }];
        }
        vm.loading = false;
      });
    }

    function processFile (fileObj) { // eslint-disable-line complexity
      var artifact = {
        data: null,
        file: fileObj.fileName,
        name: fileObj.name.replace(new RegExp('_', 'g'), ' ').replace(new RegExp('-', 'g'), ' '),
        order: 0,
        type: 'string'
      };
      switch (fileObj.fileName) {
        case 'documentation.html':
          artifact.name = 'Documentation';
          artifact.order = 0;
          break;
        case 'stdout.log':
          artifact.name = 'Output';
          artifact.order = 1;
          break;
        case 'results.json': // No break.
        case 'result.log':
          artifact.name = 'Results';
          artifact.order = 2;
          break;
          // Currently disabled from view (Found at the bottom of run.toolbar.controller.js)
          // case 'stderr.log':
          //   artifact.name = 'Errors';
          //   artifact.order = 3;
          //   break;
        default:
          // Shorten the name because tab realestate
          if (artifact.name.length > 20) {
            artifact.name = artifact.name.substring(0, 20) + '...';
          }
          artifact.order = order++;
      }

      // Data Cleaning
      if (fileObj.data === '') {
        fileObj.data = 'File is empty.';
      }
      if (typeof data === 'string') {
        if (fileObj.data.indexOf('Exception: must be') !== -1) {
          fileObj.data = 'One or more parameters was given an invalid value.';
        }
      }
      var htmlRegex = new RegExp('<\/?[^>]*>');  // eslint-disable-line
      switch (true) {
        // HTML FILES
        case (fileObj.extension === 'html'):
        case (fileObj.fileName === 'stdout.log' && htmlRegex.test(fileObj.data)):
          artifact.data = $sce.trustAsHtml(fileObj.data);
          artifact.type = 'html';
          break;
          // JSON FILES
        case (fileObj.fileName === 'stdout.log' && typeof data === 'object'):
          artifact.data = '<pre>' + JSON.stringify(fileObj.data, undefined, 2) + '</pre>';
          break;
        default:
          artifact.data = fileObj.data;
      }
      // If it's an array, check if the first elements keys are found in the rest of the elements
      //   If so, it's a hitlist
      //   If not, check to see if it's an array of tables

      if (Array.isArray(fileObj.data)) {
        if (fileObj.fileName === 'results.json' || isTable(fileObj.data)) {
          artifact.type = 'hitlist';
          artifact.headers = Object.keys(fileObj.data[0]);
        } else {
          // if (isTableCollection(fileObj.data)) {
          //   artifact.type = 'tableCollection';
          //   artifact.data = getTableCollections(fileObj.data);
          // } else {
          //   if (fileObj.data.length) {
          //     artifact.type = 'hitlist';
          //     artifact.headers = [];
          //   } else {
          artifact.type = 'html';
          artifact.data = '<pre>' + JSON.stringify(fileObj.data, undefined, 2) + '</pre>';
          //   }
          // }
        }
      }

      artifacts.push(artifact);
    }

    function isTable (data) {
      if (data.length <= 1) {
        return false;
      }
      var keys = Object.keys(data[0]);
      var missingKeys = data.filter(function (item, idx) {
        if (idx === 0) {
          return false;
        }
        var missingKey = false;
        keys.forEach(function (key) {
          if (!Object.prototype.hasOwnProperty.call(item, key)) {
            missingKey = true;
          }
        });
        return missingKey;
      });
      return (missingKeys.length === 0);
    }

    // function isTableCollection(data) {
    //   var collections = data.filter(function(item) {
    //     var keys = Object.keys(item);
    //     if (keys.length > 1) {
    //       return false;
    //     }
    //     return (Array.isArray(item[keys[0]]));
    //   });
    //   return collections.length === data.length;
    // }

    // function getTableCollections(data) {
    //   var collection = [];
    //   data.forEach(function(item) {
    //     var keys = Object.keys(item);
    //     var headers = null;
    //     var data = [];
    //     if (item[keys[0]].length) {
    //       headers = Object.keys(item[keys[0]][0]);
    //       data = item[keys[0]];
    //     }
    //     collection.push({
    //       name: keys[0],
    //       headers: headers,
    //       data: item[keys[0]]
    //     });
    //   });
    //   return collection;
    // }
  }
})();
