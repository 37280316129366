(function () {
  'use strict';

  angular.module('account')
    .controller('AccountSecurityCtrl', AccountSecurityCtrl);

  AccountSecurityCtrl.$inject = ['$scope', '$state', '$transition$', 'imatConfig', 'psNotification', 'subscriptionsSrv', 'userSession'];

  function AccountSecurityCtrl ($scope, $state, $transition$, imatConfig, psNotification, subscriptionsSrv, userSession) {
    var vm = this;
    $scope.securityQuestionsEnabled = false;

    activate();

    function activate () {
      $scope.$watch(function () { return $state.current.data.navItem; }, function (newVal) { vm.currentNavItem = newVal; });

      userSession.getLoginSettings()
        .then(function (data) {
          $scope.securityQuestionsEnabled = data.forgotPasswordWorkflow;
        });
    }
  }
})();
