(function () {
  'use strict';

  angular
    .module('vhr')
    .controller('PatientCtrl', PatientCtrl);

  PatientCtrl.$inject = [
    'MODE',
    '$mdDialog', '$mdSidenav', '$q', '$scope', '$state', '$window', 'imatConfig', 'psNotification', 'subscriptionsSrv',
    'vhrApp', 'vhrConfigSrv', 'vhrPatientSrv', 'vhrPrintSrv', 'vhrRecordCollectionSrv'
  ];

  function PatientCtrl (
    MODE,
    $mdDialog, $mdSidenav, $q, $scope, $state, $window, imatConfig, psNotification, subscriptionsSrv,
    vhrApp, vhrConfigSrv, vhrPatientSrv, vhrPrintSrv, vhrRecordCollectionSrv
  ) {
    var vm = this;
    var forceToggleSubscriptionIcon = false;
    var rvColl;
    var pqColl;

    var SUBSCRIPTION_ICON_SETUP = 'notifications_off';
    var SUBSCRIPTION_ICON_SUBSCRIBED = 'notifications';
    var SUBSCRIPTION_ICON_UNSUBSCRIBED = 'notifications_none';
    var SUBSCRIPTION_ICON_TOOLTIP_SETUP = 'You have not set up subscriptions';
    var SUBSCRIPTION_ICON_TOOLTIP_SUBSCRIBED = 'You are subscribed to this patient';
    var SUBSCRIPTION_ICON_TOOLTIP_UNSUBSCRIBED = 'You are not subscribed to this patient';

    $scope.enableRefresh = vhrApp.enableRefresh;
    $scope.enableFilter = vhrApp.enableFilter;

    // Properties
    vm.helpURL = imatConfig.get('apps.vhr.customerHelpLink');
    vm.isFilterEnabled = vhrApp.enableFilter();
    vm.isRefreshEnabled = vhrApp.enableRefresh();
    vm.widgets = [];

    vm.isSubscriptionAllowed = false;
    vm.isSubscriptionEnabled = false;
    vm.isSubscribed = false;
    vm.subscriptionIcon = SUBSCRIPTION_ICON_SETUP;
    vm.subscriptionIconTooltip = SUBSCRIPTION_ICON_TOOLTIP_SETUP;
    vm.subscriptionToggleVerb = '';

    vm.modeStyles = "#vhr-app .ui-grid-icon-ok:before { content: '" + vhrApp.getModeIconCode(vhrApp.getMode()) + "'; }";
    vm.patient = vhrPatientSrv;
    vm.queueSize = 0;
    vm.record = {};
    vm.recordId = null;
    vm.recordList = [];

    // Methods
    vm.getAppDoc = getAppDoc;
    vm.refresh = refresh;
    vm.openWidget = openWidget;
    vm.openSubscriptionMenu = openSubscriptionMenu;
    vm.openSubscriptionSettings = openSubscriptionSettings;
    vm.toggleFilter = toggleFilter;
    vm.toggleNav = toggleNav;
    vm.toggleSubscription = toggleSubscription;
    vm.updateRecordMomento = updateRecordMomento;
    vm.viewRecord = viewRecord;

    activate();

    function activate () {
      $scope.$on('vhrFilterEnabled', function (evt, isFilterEnabled) {
        vm.isFilterEnabled = isFilterEnabled;
      });

      $scope.$on('vhrRefreshEnabled', function (evt, isRefreshEnabled) {
        vm.isRefreshEnabled = isRefreshEnabled;
      });

      $scope.$on('vhrModeChanged', function (evt, mode, meta) {
        vm.modeStyles = "#vhr-app .ui-grid-icon-ok:before { content: '" + meta.ICONCODE + "'; }";
      });

      $scope.$on('vhrRecordCollectionChanged', function (evt, coll) {
        switch (coll.getType()) { // eslint-disable-line default-case
          case 'recentViews':
            vm.recordList = coll.findAll();
            break;
          case 'printQueue':
            vm.queueSize = coll.findAll().length;
            break;
        }
      });

      subscriptionsSrv.loadConfig()
        .then(function () {
          if (!(subscriptionsSrv.isEnabled() && subscriptionsSrv.hasAccess())) {
            return;
          }
          vm.isSubscriptionAllowed = true;
          setSubscriptionUi();
        });

      loadWidgets();

      rvColl = vhrRecordCollectionSrv.construct('recentViews');
      pqColl = vhrRecordCollectionSrv.construct('printQueue');
      vm.queueSize = pqColl.findAll().length;
      vm.recordList = rvColl.findAll();
    }

    // ---------------------------------
    // Public interface
    // ---------------------------------

    function getAppDoc () {
      $window.location.assign(vhrConfigSrv.getHelpLink());
    }

    function openWidget (widgetId, $event) {
      $mdDialog.show({
        bindToController: true,
        controller: 'WidgetModalCtrl',
        controllerAs: '$ctrl',
        clickOutsideToClose: true,
        locals: { widgetId: widgetId },
        targetEvent: $event,
        templateUrl: '/ui/vhr/patient/widget-modal.html'
      });
    }

    function openSubscriptionMenu ($mdMenu, $event) {
      if (vm.isSubscriptionEnabled) {
        $mdMenu.open();
      } else {
        openSubscriptionSettings($event);
      }
    }

    function openSubscriptionSettings ($event) {
      var dialog = $mdDialog.alert()
        .textContent('Your subscription settings and status will be updated.')
        .targetEvent($event)
        .ok('OK');

      var url = $state.href('app.account.subscriptions');
      $window.open(url, '_blank');

      $mdDialog.show(dialog)
        .finally(function () {
        // Settings are updated on the Account page from which we cannot
        // receive messages until we are a single-page app, so we check
        // the server again regardless of how the dialog was dismissed.
          setSubscriptionUi();
        });
    }

    function toggleSubscription () {
      subscriptionsSrv.toggleSubscription(vhrPatientSrv.raw.mpid)
        .then(function () {
          psNotification.success((vm.isSubscribed ? 'Unsubscribed' : 'Subscribed') + ' to patient alerts.');
          // There is lag between a toggle and the last check,
          // so if we know we toggled, force the UI to toggle.
          // TODO Make the subscription service track state.
          forceToggleSubscriptionIcon = true;
          setSubscriptionUi();
        })
        .catch(function () {
          psNotification.error('Unable to ' + (vm.isSubscribed ? 'unsubscribe' : 'subscribe') + ' to patient alerts.');
        });
    }

    function refresh () {
      vhrApp.refresh();
    }

    function toggleFilter () {
      vhrApp.showFilter(!vhrApp.showFilter());
    }

    function toggleNav () {
      $mdSidenav('sidenav').toggle();
    }

    function updateRecordMomento () {
      vm.recordList.forEach(function (record) {
        record.moment = moment(record.viewedAt).fromNow();
      });
    }

    function viewRecord () {
      var cfg;

      vm.record = rvColl.findById(vm.recordId);

      if (!vm.record) {
        return;
      }

      vhrRecordCollectionSrv.stash(vm.record);
      cfg = vm.record.getModalConfig();
      cfg.onComplete = vhrPrintSrv.hideContent;
      cfg.onRemoving = vhrPrintSrv.showContent;

      $mdDialog.show(cfg)
        .then(function () {
        // Interaction
        }, function () {
        // Cancel
        })
        .finally(function () {
          rvColl.resequence(vm.record);
          vm.recordId = null;
          vm.record = {};
        });
    }

    // ---------------------------------
    // Private interface
    // ---------------------------------

    function loadWidgets () {
      vhrConfigSrv.load().then(function () {
        var widgets = vhrConfigSrv.getWidgets();
        angular.forEach(widgets, loadWidget);
      });
    }

    function loadWidget (widget, widgetId) {
      vhrPatientSrv.existsInSet(widget.set)
        .then(function (show) {
          if (!show) {
            return;
          }

          vm.widgets.push({
            id: widgetId,
            label: widget.label,
            buttonClass: widget.badge === 'warning' ? 'md-warn' : '',
            icon: widget.badge === 'warning' ? 'error_outline' : 'info_outline'
          });
        });
    }

    function setSubscriptionIcon (isSubscribed) {
      if (isSubscribed === true) {
        vm.subscriptionIcon = SUBSCRIPTION_ICON_SUBSCRIBED;
        vm.subscriptionIconTooltip = SUBSCRIPTION_ICON_TOOLTIP_SUBSCRIBED;
        vm.subscriptionToggleVerb = 'Unsubscribe';
      } else if (isSubscribed === false) {
        vm.subscriptionIcon = SUBSCRIPTION_ICON_UNSUBSCRIBED;
        vm.subscriptionIconTooltip = SUBSCRIPTION_ICON_TOOLTIP_UNSUBSCRIBED;
        vm.subscriptionToggleVerb = 'Subscribe';
      } else {
        vm.subscriptionIcon = SUBSCRIPTION_ICON_SETUP;
        vm.subscriptionIconTooltip = SUBSCRIPTION_ICON_TOOLTIP_SETUP;
        vm.subscriptionToggleVerb = 'Subscribe';
      }
    }

    function setSubscriptionUi () {
      return subscriptionsSrv.checkNotificationSettings()
        .then(function (hasSubscriptions) {
          vm.isSubscriptionEnabled = hasSubscriptions;
          return (hasSubscriptions ? subscriptionsSrv.checkPatientSubscriptions(vhrPatientSrv.raw.mpid) : null);
        })
        .then(function (isSubscribed) {
          // There is lag between a toggle and the last check,
          // so if we know we toggled, force the UI to toggle.
          // TODO Make the subscription service track state.
          if (forceToggleSubscriptionIcon && isSubscribed !== null) {
            isSubscribed = vm.isSubscribed = !vm.isSubscribed;
          } else {
            vm.isSubscribed = !!isSubscribed;
          }
          forceToggleSubscriptionIcon = false;
          setSubscriptionIcon(isSubscribed);
        })
        .catch(function (reason) {
          // Ignore 404, meaning subscriptions are not set up.
          if (reason && reason.status !== 404) {
            psNotification.error('Failed to retrieve your subscription settings and status.');
            return $q.reject(reason);
          }
        });
    }
  }
})();
