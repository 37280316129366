(function () {
  'use strict';

  angular
    .module('vhr.services')
    .factory('vhrPatientSrv', vhrPatientSrv);

  vhrPatientSrv.$inject = ['$http', 'vhrConfigSrv'];

  function vhrPatientSrv ($http, vhrConfigSrv) {
    var service;

    service = {
      raw: {},
      existsInSet: existsInSet,
      getAge: getAge,
      getAges: getAges,
      getDateOfBirth: getDateOfBirth,
      getDateOfDeath: getDateOfDeath,
      getFullName: getFullName,
      getGender: getGender,
      getName: getName,
      getMpid: getMpid,
      getPhone: getPhone,
      isAttested: isAttested,
      isDeceased: isDeceased,
      isUnderage: isUnderage,
      reset: reset,
      set: set
    };

    return service;

    // ----------------------------------------
    // Public interface
    // ----------------------------------------

    function existsInSet (set, mpid) {
      mpid = mpid || getMpid();
      var getArgs = { existsInSet: set };
      return $http.get('/api/patients/' + mpid, { params: getArgs })
        .then(function (res) {
          return res.data.exists;
        });
    }

    function getAge () {
      var age = '?';
      var ages;

      if (service.raw.date_of_birth) {
        ages = getAges(['months', 'years']);
        age = (ages['months'] < 24 ? ages['months'] + ' months' : ages['years'] + ' years'); // eslint-disable-line dot-notation
      }
      return age;
    }

    function getAges (units) {
      var ages = {};
      var birthDate;
      var deathDate;

      if (service.raw.date_of_birth) {
        birthDate = moment(service.raw.date_of_birth, 'YYYY-MM-DD');

        if (service.raw.date_of_death) {
          deathDate = moment(service.raw.date_of_death, 'YYYY-MM-DD');
        }

        units.forEach(function (unit) {
          var momentUnit = moment.normalizeUnits(unit);

          if (momentUnit) {
            ages[unit] = moment(deathDate).diff(birthDate, momentUnit);
          }
        });
      }
      return ages;
    }

    function getDateOfBirth () {
      return service.raw.date_of_birth;
    }

    function getDateOfDeath () {
      return service.raw.date_of_death || service.raw.deceased_date;
    }

    function isDeceased () {
      if (!service.raw.deceased && service.raw.date_of_death) { service.raw.deceased = true; return service.raw.deceased; }
      return service.raw.deceased;
    }

    function getFullName (reversed) {
      var name, mn;

      mn = service.raw.middle_name;
      name = (reversed)
        ? service.raw.last_name + ' ' + service.raw.suffix + ', ' + service.raw.first_name + ' ' + mn
        : service.raw.first_name + ' ' + mn + ' ' + service.raw.last_name + ' ' + service.raw.suffix;

      return getDisplayName(name, reversed);
    }

    function getGender () {
      if (!Object.keys(service.raw).length) { return ''; }
      if (/^m(ale)?$/i.test(service.raw.gender)) { return 'Male'; }
      if (/^f(emale)?$/i.test(service.raw.gender)) { return 'Female'; }
      if (/^u(nknown)?$/i.test(service.raw.gender)) { return 'Unknown'; }
      return '';
    }

    function getMpid () {
      return service.raw.mpid;
    }

    function getName (reversed) {
      var name;

      name = (reversed)
        ? service.raw.last_name + ' ' + service.raw.suffix + ', ' + service.raw.first_name
        : service.raw.first_name + ' ' + service.raw.last_name + ' ' + service.raw.suffix;

      return getDisplayName(name, reversed);
    }

    function getPhone () {
      return service.raw.phone;
    }

    function isAttested () {
      return Boolean(service.raw.mpid);
    }

    function isUnderage () {
      return getAges(['years'])['years'] < 18; // eslint-disable-line dot-notation
    }

    function reset () {
      service.raw = {};
    }

    function set (patient) {
      reset();
      service.raw = patient || {};
      setRawDateOfBirth();
      setRawDateOfDeath();
      setRawDeceased();
      setRawNames();
    }

    // ----------------------------------------
    // Private interface
    // ----------------------------------------

    function getDisplayName (name, reversed) {
      name = name.replace(/^\s+/, '??? ').replace(/,\s*$/, ', ???').replace(/\s+/g, ' ').replace(' ,', ',').trim();
      return (name || (reversed ? '???, ???' : '??? ???'));
    }

    function setRawDateOfBirth () {
      service.raw.date_of_birth = vhrConfigSrv.getIsoDate(service.raw.date_of_birth);
      if (!moment(service.raw.date_of_birth).isValid()) {
        service.raw.date_of_birth = '';
      }
    }

    function setRawDateOfDeath () {
      service.raw.deceased_date = service.raw.date_of_death = vhrConfigSrv.getIsoDate(service.raw.deceased_date || service.raw.date_of_death);
      if (!moment(service.raw.date_of_death).isValid()) {
        service.raw.date_of_death = service.raw.deceased_date = '';
      }
    }

    function setRawDeceased () {
      service.raw.deceased = ([undefined, null, false, '', 'N', 'n', 'F', 'f', 'NO', 'No', 'no', 'FALSE', 'False', 'false', 'null', 'undefined'].indexOf(service.raw.deceased) === -1);
    }

    function setRawNames () {
      ['first_name', 'middle_name', 'last_name', 'middle_initial', 'suffix'].forEach(function (field) {
        if (!service.raw[field]) {
          service.raw[field] = '';
        }
      });
    }
  }
})();
