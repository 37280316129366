(function () {
  'use strict';

  angular.module('imat.fhir')
    .factory('FhirJsonWrapperClass', FhirJsonWrapperClass);

  FhirJsonWrapperClass.$inject = [];

  function FhirJsonWrapperClass () {
    function FhirJsonWrapper (obj, key, idx) {
      if (idx === true) {
        resourcifyJson(obj, key);// Side-effects on the obj.
      }

      this.obj = obj;// DO NOT COPY obj. The validation process requires references to remain intact.
      this.key = key;
      this.idx = idx;
      this.isArray = angular.isArray(this.obj[this.key]);
      this.isDefined = this.obj[this.key] != null;
      this.isPrimitive = false;
      this.isResource = idx === true;

      this.len = function () {
        return (!this.isArray ? 0 : this.obj[this.key].length);
      };

      Object.defineProperty(this, 'data', {
        get: function () {
          return (this.isArray ? this.obj[this.key][this.idx] : (this.isResource ? this.obj : this.obj[this.key]));
        },
        set: function () {
          if (this.isArray) {
            this.obj[this.key][this.idx] = arguments[0];
          } else if (this.isResource) {
            // Hopefully we never reset a resource...
            if (!angular.isObject(arguments[0]) || angular.isArray(arguments[0])) {
              return;// Refuse.
            }
            var self = this;
            Object.keys(this.obj).forEach(function (objKey) {
              delete self.obj[objKey];
            });
            resourcifyJson(arguments[0], key);// Side-effects on the obj.
            angular.extend(this.obj, arguments[0]);
          } else {
            this.obj[this.key] = arguments[0];
            this.isDefined = arguments[0] != null;
          }
        }
      });

      // A primitve (at obj.key for example) usually has a value. Whether it
      // does or not, if it has other attributes and/or children, then those
      // attributes/children must be moved into a new obj._key property created
      // for them. If the primitive is an element within an array then its
      // obj._key placeholder is also within an array (and is set to null if
      // there is nothing to store there). See the FHIR JSON representation at
      // http://hlt.org/fhir/json.html#primitive

      this.setPrimitive = function () {
        this._key = '_' + this.key;
        this.isPrimitive = true;

        this._len = function () {
          return (!this.isArray ? 0 : this.obj[this._key].filter(function (e) { return e !== undefined; }).length);
        };

        Object.defineProperty(this, '_data', {
          get: function () {
            if (this.isArray && Array.isArray(this.obj[this._key])) {
              return this.obj[this._key][this.idx];
            }
            return this.obj[this._key];
          },
          set: function () {
            if (this.isArray) {
              this.obj[this._key][this.idx] = arguments[0];
              if (arguments[0] === null && this._len() === this.len()) {
                delete this.obj[this._key];// Remove property since all elements are null.
              }
            } else {
              this.obj[this._key] = arguments[0];
              if (arguments[0] == null) {
                delete this.obj[this._key];
              }
            }
          }
        });

        if (!Object.prototype.hasOwnProperty.call(this.obj, this._key)) {
          this.obj[this._key] = (this.isArray ? new Array(this.len()) : {});
        }

        if (!angular.isObject(this.data) || angular.isArray(this.data)) {
          // Data is primitive with no attributes or children.
          // If this is valid FHIR JSON then _data contains the extra,
          // non-value information and we don't want to mess with that.
          if (this._data === undefined || angular.equals(this._data, {})) {
            this._data = null;
          }
          return;
        }

        // Move id attribute and/or extensions, per the spec.
        this._data = angular.extend({}, this.data);
        this.data = null;

        // Restore primitive value, if given.
        if (Object.prototype.hasOwnProperty.call(this._data, 'value')) {
          this.data = this._data['value']; // eslint-disable-line dot-notation
          delete this._data['value']; // eslint-disable-line dot-notation
        }
      };
    }

    function resourcifyJson (obj, key) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        angular.extend(obj, obj[key]);
        delete obj[key];
      }
      obj.resourceType = key;
    }

    return FhirJsonWrapper;
  }
})();
