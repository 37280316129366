(function () {
  'use strict';

  angular
    .module('account')
    .config(config);

  config.$inject = ['$stateRegistryProvider'];

  function config ($stateRegistryProvider) {
    $stateRegistryProvider.register({
      name: 'app.account',
      url: '/account/?pageTitle&return&section',
      data: {
        appKey: 'account',
        pageTitle: 'Account Settings',
        roles: []
      },
      params: {
        pageTitle: null, // Is this needed? I dont't see this used anywhere
        return: null,
        section: null // Is this needed? not if the link in vhr patient controller is changed
      },
      views: {
        'content@app': {
          templateUrl: '/ui/_shared/layouts/left-sidebar-layout.html',
          controller: 'LeftSidebarCtrl',
          controllerAs: 'ctrl'
        },
        'sidenav@app.account': {
          templateUrl: '/ui/_shared/layouts/sidebar.html',
          controller: 'SidebarCtrl',
          controllerAs: 'ctrl'
        }
      },
      redirectTo: 'app.account.default'
    });
    $stateRegistryProvider.register({
      name: 'app.account.default',
      data: {
        pageTitle: 'Account Home',
        roles: [],
        help: '/ui/documentation/#/guides/user-menu'
      },
      views: {
        'main-content@app.account': {
          templateUrl: '/ui/account/home/account-home.html',
          controller: 'AccountHomeCtrl',
          controllerAs: 'ctrl'
        }
      },
      url: 'home/'
    });
    $stateRegistryProvider.register({
      name: 'app.account.info',
      data: {
        pageTitle: 'Personal Info',
        roles: [],
        help: '/ui/documentation/#/guides/user-menu'
      },
      views: {
        'main-content@app.account': {
          templateUrl: '/ui/account/personal-info/account-info.html',
          controller: 'AccountInfoCtrl',
          controllerAs: 'ctrl'
        }
      },
      url: 'info/'
    });
    $stateRegistryProvider.register({
      name: 'app.account.security',
      data: {
        pageTitle: 'Password & Security',
        roles: [],
        help: '/ui/documentation/#/guides/user-menu'
      },
      views: {
        'main-content@app.account': {
          templateUrl: '/ui/account/security/account-security.html',
          controller: 'AccountSecurityCtrl',
          controllerAs: 'ctrl'
        }
      },
      url: 'security/',
      redirectTo: 'app.account.security.password'
    });
    $stateRegistryProvider.register({
      name: 'app.account.security.password',
      data: {
        navItem: 'password'
      },
      views: {
        'nav-content@app.account.security': {
          templateUrl: '/ui/account/security/password/account-password.html',
          controller: 'AccountPasswordCtrl',
          controllerAs: 'ctrl'
        }
      },
      url: 'password/'
    });
    $stateRegistryProvider.register({
      name: 'app.account.security.questions',
      data: {
        navItem: 'questions'
      },
      views: {
        'nav-content@app.account.security': {
          templateUrl: '/ui/account/security/questions/account-questions.html',
          controller: 'AccountQuestionsCtrl',
          controllerAs: 'ctrl'
        }
      },
      url: 'questions/'
    });
    $stateRegistryProvider.register({
      name: 'app.account.subscriptions',
      data: {
        pageTitle: 'Subscription Alerts',
        roles: [],
        help: '/ui/documentation/#/guides/user-menu'
      },
      views: {
        'main-content@app.account': {
          templateUrl: '/ui/account/subscriptions/account-subscriptions.html',
          controller: 'AccountSubscriptionsCtrl',
          controllerAs: 'ctrl'
        }
      },
      url: 'subscriptions/'
    });
    $stateRegistryProvider.register({
      name: 'app.account.preferences',
      data: {
        pageTitle: 'App Preferences',
        roles: [],
        help: '/ui/documentation/#/guides/user-menu'
      },
      views: {
        'main-content@app.account': {
          templateUrl: '/ui/account/preferences/preferences.html',
          controller: 'AccountPreferencesCtrl',
          controllerAs: 'ctrl'
        }
      },
      url: 'preferences/'
    });
  }
})();
