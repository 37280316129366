(function () {
  'use strict';

  angular.module('admin')
    .controller('CreateSetCtrl', CreateSetCtrl);

  CreateSetCtrl.$inject = ['$scope', '$state', 'GroupsSrv', 'ProvidersSrv', 'psNotification', 'imatStoresSrv', 'SetsSrv', 'UsersSrv'];

  function CreateSetCtrl ($scope, $state, GroupsSrv, ProvidersSrv, psNotification, imatStoresSrv, SetsSrv, UsersSrv) {
    var vm = this;
    var STORE_ALL = '-1';

    // Field options
    vm.fieldNamesOp = {
      PatientIDN: 'PATIENTIDN',
      NREF: '',
      other: 'other'
    };
    vm.ownerTypes = {
      User: 'user',
      Group: 'group'
    };
    vm.queryType = {
      Compound: 'c',
      Single: 'q'
    };

    // Variables.
    vm.description = '';
    vm.editorConfig = {
      lineWrapping: true,
      lineNumbers: true,
      indentUnit: 4,
      theme: 'eclipse',
      mode: 'python'
    };
    vm.editorFocused = false;
    vm.fieldname = vm.fieldNamesOp.PatientIDN;
    vm.fieldnameEdit = '';
    vm.filteredOwners = [];
    vm.listOfGroups = [];
    vm.listOfUsers = [];
    vm.ownerType = vm.ownerTypes.Group;
    vm.providers = [];
    vm.query_string = '';
    vm.query_type = vm.queryType.Single;
    $scope.selectedOwner = null;
    vm.selectedStore = [];
    vm.stores = [];

    // Methods.
    vm.cancel = cancel;
    vm.codemirrorLoaded = codemirrorLoaded;
    vm.filterOwners = filterOwners;
    vm.save = save;
    vm.selectOwner = selectOwner;

    activate();

    // Method list.
    function activate () {
      loadProviders();
      loadStores();

      $scope.$watch(function () { return vm.selectedStore; }, function (curr, prev) {
        if (angular.isArray(curr)) {
          if (curr.length === 0) {
            vm.selectedStore = [STORE_ALL];
          } else if (curr.length > 1) {
            const currAllIdx = curr.indexOf(STORE_ALL);
            if (currAllIdx >= 0) {
              if (prev && prev.indexOf(STORE_ALL) >= 0) {
                vm.selectedStore.splice(currAllIdx, 1);
              } else if (prev) {
                vm.selectedStore = [STORE_ALL];
              }
            }
          }
        } else if (!curr) {
          vm.selectedStore = [STORE_ALL];
        }
      });
    }

    // Cancel creation of new set.
    function cancel () {
      vm.selectedStore = [];
      vm.editCurrentItem = 0;
      $state.go('app.admin.sets');
    }

    // Makes codemirror object events available to tie further logic to.
    function codemirrorLoaded (editor) {
      var cmDoc = editor.getDoc();
      var jqTxt = angular.element(editor.getTextArea()).get(0);
      var ngTxt = $scope[jqTxt.form.name][jqTxt.name];

      cmDoc.markClean();

      // Events
      editor.on('focus', function () {
        $scope.$applyAsync(function () { vm.editorFocused = true; });
      });

      editor.on('blur', function () {
        $scope.$applyAsync(function () { vm.editorFocused = false; });
        ngTxt.$setTouched();// Triggers required.
      });
    }

    function filterOwners (filter) {
      var field = 'name';
      var owners = vm.listOfGroups;
      var rexFilter = new RegExp(filter, 'i');

      if (vm.ownerType === vm.ownerTypes.User) {
        field = 'username';
        owners = vm.listOfUsers;
      }
      vm.filteredOwners = owners.filter(function (owner) {
        return rexFilter.test(owner[field]);
      });
    }

    // Save a new set.
    function save (activateSet) {
      if (vm.fieldname === vm.fieldNamesOp.other) {
        vm.fieldname = vm.fieldnameEdit;
      }
      var sessionId = vm.ownerType + '_' + vm.groupOrUserName + '@' + vm.provider;
      var payload = {
        name: vm.set_name,
        sessionid: sessionId,
        query_type: vm.query_type,
        query_string: vm.query_string,
        fieldname: vm.fieldname,
        description: vm.description,
        store: vm.selectedStore.join()
      };
      if (payload.query_type === vm.queryType.Compound) {
        payload.store = null;
      }
      SetsSrv.createSetDefinition(payload, activateSet)
        .then(function () {
          const message = activateSet ? 'Set definition saved and set created.' : 'Set definition saved.';
          psNotification.success(message);
          $state.go('app.admin.sets', { refresh: true });
        }).catch(function (err) {
          var msg = {
            type: 'error',
            message: 'Error creating set definition'
          };

          if (err.status === 409) {
            msg.message = 'A set owned by ' + vm.ownerType + ' ' + vm.groupOrUserName + ' already exists with the name ' + vm.set_name;
          }

          if (err.status === 500 && /Must run only Boolean/.test(err.data.title)) {
            msg.type = 'warn';
            msg.message = 'Set created, but unable to run. Please check your query, fieldname, and stores, and try again.';
          }

          psNotification.customShow(msg);
        });
    }

    function selectOwner (owner) {
      if (owner) {
        vm.groupOrUserName = owner.username || owner.name || '';
      }
    }

    // ======================
    //  Private Methods
    // ======================

    function loadProviders () {
      ProvidersSrv.getProviderList()
        .then(function (providers) {
          vm.providers = providers;
          vm.provider = providers[providers.length - 1];

          GroupsSrv.getList()
            .then(function (groups) {
              vm.listOfGroups = groups;
            });
          UsersSrv.getList()
            .then(function (users) {
              vm.listOfUsers = users;
            });
        });
    }

    function loadStores () {
      // Gets list of stores available.
      imatStoresSrv.getIncluded().then(function (stores) {
        vm.stores = [{
          exclude: '0',
          name: 'All non-excluded stores',
          number: STORE_ALL
        }];
        stores.forEach(function (store) {
          vm.stores.push({
            exclude: store.exclude.toString(),
            name: store.name.toString(),
            number: store.number.toString()
          });
        });
      });
    }
  }
})();
