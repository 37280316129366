(function () {
  'use strict';

  angular
    .module('vhr.directives')
    .directive('vhrPdfToolbar', vhrPdfToolbar);

  vhrPdfToolbar.$inject = [];

  function vhrPdfToolbar () {
    var service = {
      restrict: 'E',
      scope: false,
      templateUrl: '/ui/vhr/directives/vhrPdf/vhr-pdf-toolbar.html',
      controller: VhrPdfToolbarCtrl,
      controllerAs: 'ctrl',
      link: postLink
    };

    VhrPdfToolbarCtrl.$inject = ['$scope', '$window', 'vhrPdfSrv'];

    return service;

    function VhrPdfToolbarCtrl ($scope, $window, vhrPdfSrv) {
      var vm = this;
      var renderTask;

      // Properties
      vm.aborted = false;
      vm.canvas = null;
      vm.fitPage = false;
      vm.fitWidth = false;
      vm.loaded = { pdf: false, pdfPage: false };
      vm.pageCount = 0;
      vm.pageNum = 1;
      vm.pageToDisplay = 1;
      vm.scale = 1;

      // Methods
      $scope.changePage = changePage;
      $scope.fit = fit;
      $scope.goNext = goNext;
      $scope.goPrevious = goPrevious;
      $scope.rotate = rotate;
      $scope.zoomIn = zoomIn;
      $scope.zoomOut = zoomOut;
      vm.clearCanvas = clearCanvas;
      vm.renderPage = renderPage;

      function clearCanvas () {
        if (vm.canvas && vm.loaded.pdf) {
          vm.canvas.getContext('2d').clearRect(0, 0, vm.canvas.width, vm.canvas.height);
        }
      }

      function goPrevious () {
        var page = parseInt(vm.pageToDisplay, 10) - 1;

        if (isNaN(page) || page < 1) {
          return;
        }
        vm.pageNum = page;// Watched.
      }

      function goNext () {
        var page = parseInt(vm.pageToDisplay, 10) + 1;

        if (isNaN(page) || page > vm.pageCount) {
          return;
        }
        vm.pageNum = page;// Watched.
      }

      function zoomIn () {
        var scale = parseFloat(vm.scale) + vhrPdfSrv.PDF_SCALE_STEP;

        if (isNaN(scale)) {
          return;
        }
        vm.fitPage = false;
        vm.fitWidth = false;
        vm.scale = scale;
        changePage();
      }

      function zoomOut () {
        var scale = parseFloat(vm.scale) - vhrPdfSrv.PDF_SCALE_STEP;

        if (isNaN(scale) || scale < vhrPdfSrv.PDF_SCALE_MIN) {
          return;
        }
        vm.fitPage = false;
        vm.fitWidth = false;
        vm.scale = scale;
        changePage();
      }

      function fit () {
        vm.fitPage = false;
        vm.fitWidth = true;
        changePage();
      }

      function changePage () {
        renderPage(vm.canvas, vm.pdf, vm.pageToDisplay);
      }

      function rotate () {
        var classes = ['rotate0', 'rotate90', 'rotate180', 'rotate270'];
        var classStr = vm.canvas.getAttribute('class');
        var currClass = 1;
        var prevClass;

        prevClass = classes.filter(function (c) {
          return (classStr.indexOf(c) >= 0);
        }).pop();

        if (prevClass) {
          currClass = classes.indexOf(prevClass) + 1;
          if (currClass === classes.length) {
            currClass = 0;
          }
        }
        angular.element(vm.canvas).removeClass(prevClass).addClass(classes[currClass]);
      }

      function renderPage (canvas, pdf, pageNum, consumedVerticalSpace) {
        if (renderTask && renderTask._internalRenderTask.running) {
          renderTask._internalRenderTask.cancel();
          renderTask = null;
        }

        vm.loaded.pdfPage = false;

        return pdf.getPage(pageNum)
          .then(function (page) {
            var clientRect,
              height,
              parent,
              viewport;

            if (vm.fitPage) {
              height = $window.innerHeight || $window.document.documentElement.offsetHeight;
              height = (height - (consumedVerticalSpace || 0));
              viewport = page.getViewport(1);
              vm.scale = Math.max(vhrPdfSrv.PDF_SCALE_MIN, (height / viewport.height));
            }

            if (vm.fitWidth) {
              parent = angular.element(canvas).parent();
              parent.css({ display: 'block' });
              clientRect = parent[0].getBoundingClientRect();
              viewport = page.getViewport(1);
              vm.scale = Math.max(vhrPdfSrv.PDF_SCALE_MIN, (clientRect.width / viewport.width));
            }

            viewport = page.getViewport(vm.scale);
            vhrPdfSrv.setCanvasDimensions($window, canvas, viewport.width, viewport.height);
            renderTask = page.render({ canvasContext: canvas.getContext('2d'), viewport: viewport });
            return renderTask.then(function () { vm.loaded.pdfPage = true; });
          });
      }
    }

    function postLink (scope, element, attrs, ctrl) {
      reset();
      function reset () {
        ctrl.aborted = false;
        ctrl.canvas = scope.pdfCanvas;
        ctrl.fitPage = scope.pdfFitPage;
        ctrl.fitWidth = scope.pdfFitWidth;
        ctrl.pageCount = 0;
        ctrl.pageNum = scope.pdfPage;
        ctrl.pageToDisplay = scope.pdfPage;
        ctrl.scale = scope.pdfScale;
      }

      scope.$watch('pdfAborted', function (curr) {
        if (curr) {
          scope.$applyAsync(function () { ctrl.aborted = true; });
        }
      });

      scope.$watch('pdfLoaded', function (curr) {
        if (curr) {
          ctrl.loaded.pdf = true;
          ctrl.pdf = scope.pdfDocument;
          ctrl.pageCount = scope.pdfDocument.numPages;
        } else {
          ctrl.clearCanvas();
          ctrl.loaded.pdf = false;
          ctrl.loaded.pdfPage = false;
          reset();
        }
      });

      scope.$watch('pdfReady', function (curr) {
        var tmplHeight;

        if (curr) {
          tmplHeight = scope.pdfCanvas.getBoundingClientRect().top - scope.pdfElement.getBoundingClientRect().top;
          ctrl.renderPage(ctrl.canvas, ctrl.pdf, ctrl.pageToDisplay, tmplHeight)
            .then(function () {
              scope.$evalAsync('ctrl.loaded.pdf = true');
            });
        }
      });

      scope.$watch('ctrl.pageNum', function (curr) {
        curr = parseInt(curr, 10);
        if (isNaN(curr) || curr < 1 || curr === ctrl.pageToDisplay || curr > ctrl.pageCount || !ctrl.pdf) {
          return;
        }
        ctrl.pageToDisplay = curr;
        ctrl.renderPage(ctrl.canvas, ctrl.pdf, ctrl.pageToDisplay);
      });
    }
  }
})();
