(function () {
  'use strict';

  angular.module('imat.components')
    .component('psPager', {
      transclude: true,
      controller: psPagerCtrl,
      templateUrl: '/ui/_shared/components/ps-pager/ps-pager.html',
      bindings: {
        results: '<',
        limit: '<',
        pagerCurrent: '<',
        total: '<',
        onFirst: '&',
        onNext: '&',
        onLimitChange: '&'
      }
    });

  psPagerCtrl.$inject = ['$timeout'];

  function psPagerCtrl ($timeout) { // $scope, $element, $attrs
    // eslint-disable-next-line no-invalid-this
    var ctrl = this;
    ctrl.loading = false;

    ctrl.pagerCurrent = ctrl.pagerCurrent || 1;
    ctrl.start = 1;
    ctrl.end = 1;
    ctrl.rounded = _rounded(ctrl.total);

    ctrl.$onChanges = function (changes) {
      if (changes.pagerCurrent || changes.total || changes.limit) {
        _renderPageNumbers();

        if (changes.total) {
          ctrl.rounded = _rounded(ctrl.total);
        }
      }
    };

    ctrl.goToFirstPage = function () {
      ctrl.onFirst({ page: 1 });
    };

    ctrl.nextPage = function () {
      if ((ctrl.pagerCurrent + 1) > ctrl.lastPage) { return; }
      ctrl.onNext({ page: ctrl.pagerCurrent + 1 });
    };

    ctrl.limitChange = function () {
      ctrl.onLimitChange({ limit: ctrl.limit });
    };

    // eslint-disable-next-line complexity
    function _renderPageNumbers () {
      ctrl.loading = true;
      ctrl.pagerCurrent = ctrl.pagerCurrent || 1;
      ctrl.start = (ctrl.pagerCurrent * ctrl.limit) - ctrl.limit + 1;
      ctrl.lastPage = ctrl.total ? Math.ceil(ctrl.total / ctrl.limit) : 1;
      if (ctrl.results) {
        var remainder = (parseInt(ctrl.total, 10) % parseInt(ctrl.limit, 10)) || parseInt(ctrl.limit, 10);
        ctrl.end = ctrl.pagerCurrent === ctrl.lastPage ? ctrl.start + remainder - 1 : ctrl.pagerCurrent * ctrl.limit;
      }

      $timeout(function () {
        ctrl.loading = false;
      }, 200);
    }

    function _rounded (num) {
      if (num < 100) {
        return Math.ceil(num * 0.1) * 10;
      }
      return Math.ceil(num * 0.01) * 100;
    }
  }
})();
