(function () {
  'use strict';

  angular.module('imat.services')
    .factory('IndexerSrv', IndexerSrv);

  IndexerSrv.$inject = ['$http'];

  function IndexerSrv ($http) {
    function getBuilds () {
      return $http({
        method: 'GET',
        url: '/indexer?build'
      });
    }

    return {
      getBuilds: getBuilds
    };
  }
})();
