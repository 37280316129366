(function () {
  'use strict';

  angular.module('dashboardApp')
    .controller('ItemLinkResultsConfigCtrl', ItemLinkResultsConfigCtrl);

  ItemLinkResultsConfigCtrl.$inject = ['DASHBOARD', '$mdDialog', '$scope', '$timeout', 'DashboardItemClass', 'modalDashboard', 'modalItem', 'searchSrv', 'userSession'];

  function ItemLinkResultsConfigCtrl (DASHBOARD, $mdDialog, $scope, $timeout, DashboardItemClass, modalDashboard, modalItem, searchSrv, userSession) {
    var modal = this;
    // Query params to turn off Clinical Reports UI widgets
    var linkOptionsMap = {
      x_patientpanel: 'hidePatientPanel',
      x_recordlist: 'hideRecordList',
      x_resultlist: true,
      x_reportpanel: true
    };
    // Properties
    $scope.IMAGE_TYPES = DashboardItemClass.prototype.IMAGE;
    $scope.canSaveInvalid = false;
    $scope.colors = DASHBOARD.COLORS;
    $scope.icons = [].concat(
      DASHBOARD.ICONS.GLYPHICONS.map(function (i) { return { name: i, type: $scope.IMAGE_TYPES.GLYPHICON }; }),
      DASHBOARD.ICONS.MATERIALICONS.map(function (i) { return { name: i, type: $scope.IMAGE_TYPES.MATERIAL }; }),
      DASHBOARD.ICONS.PSICONS.map(function (i) { return { name: i, type: $scope.IMAGE_TYPES.PS }; })
    );
    $scope.image = { color: '', icon: '', src: '' };
    $scope.imgType = '';
    $scope.loading = true;
    $scope.linkOptions = {
      hideRecordList: true,
      hidePatientPanel: true
    };
    $scope.savedList = [];
    $scope.selected = null;
    $scope.tile = modalItem;
    $scope.user = userSession.getUserData();
    // Methods
    $scope.cancel = $mdDialog.cancel;
    $scope.submit = $mdDialog.hide;
    modal.changeImgType = changeImgType;
    modal.updateColor = updateColor;
    modal.updateIcon = updateIcon;
    modal.updateSrc = updateSrc;

    activate();

    refresh()
      .then(function () {
        initWithUrl($scope.tile.link.href);
      });

    function activate () {
      switch (modalItem.image.type) {
        case $scope.IMAGE_TYPES.PS:// No break.
        case $scope.IMAGE_TYPES.GLYPHICON:// No break.
        case $scope.IMAGE_TYPES.MATERIAL:
          $scope.image.color = modalItem.image.color || $scope.colors[0];
          $scope.image.icon = { name: modalItem.image.value, type: modalItem.image.type };
          $scope.image.src = '';
          $scope.imgType = '0';
          break;
        case $scope.IMAGE_TYPES.IMGSRC:
          $scope.image.color = $scope.colors[0];
          $scope.image.icon = $scope.icons[0];
          $scope.image.src = modalItem.image.value || '';
          $scope.imgType = '1';
          break;
        default:
          break;
      }
    }

    function changeImgType () {
      switch ($scope.imgType) {
        case '0':
          updateIcon($scope.image.icon);
          break;
        case '1':
          updateSrc();
          break;
        default:
          break;
      }
    }

    function updateColor (color) {
      modalItem.image.color = color;
      $scope.image.color = color;
    }

    function updateIcon (icon) {
      modalItem.image.type = icon.type;
      modalItem.image.value = icon.name;
      $scope.image.icon = icon;
    }

    function updateSrc () {
      modalItem.image.type = $scope.IMAGE_TYPES.IMGSRC;
      modalItem.image.value = $scope.image.src;
    }

    function refresh () {
      var user = $scope.user;
      var dashboard = modalItem.ui.ref;
      var params = getParams(user, dashboard);

      params.named_only = true;

      return searchSrv.getSearches(params)
        .then(function (list) {
          if (list.total === '0') {
            return [];
          }

          list.searches = list.searches.sort(function (a, b) {
            return a.name > b.name;
          });

          // XXX Isn't named_only forced considering this filter?
          var searchList = list.searches.filter(function (search) {
            return !!search.name; // continue
          });

          return searchList.map(function (search) {
            return {
              name: search.name,
              link: '/extensions/clinical-reports/#navresultspanel?runsavedresult(id=' + search.id + ')',
              id: search.id
            };
          });
        })
        .then(function (list) {
          $scope.savedList = list.sort(compareName);

          if (!list.length) {
            $scope.canSaveInvalid = !!modalItem.data.link.href;
            $timeout(function () {
              $scope.dashboardItemConfig.savedList.$setValidity('savedListEmpty', false);
              $scope.dashboardItemConfig.savedList.$setValidity('savedListError', true);
              $scope.dashboardItemConfig.savedList.$setTouched();
            });
          }
        })
        .catch(function () {
          $scope.savedList = [];
          $scope.canSaveInvalid = !!modalItem.data.link.href;
          $timeout(function () {
            $scope.dashboardItemConfig.savedList.$setValidity('savedListEmpty', true);
            $scope.dashboardItemConfig.savedList.$setValidity('savedListError', false);
            $scope.dashboardItemConfig.savedList.$setTouched();
          });
        })
        .finally(function () {
          $scope.loading = false;
        });
    }

    function compareName (a, b) {
      var aName = a.name.toLowerCase();
      var bName = b.name.toLowerCase();
      if (aName < bName) { return -1; }
      if (aName > bName) { return 1; }
      return 0;
    }

    function getParams (user, dashboard) {
      var params = {
        admin: true,
        provider: dashboard.provider
      };

      if (user.systemName.toLowerCase() === dashboard.owner.toLowerCase()) {
        return {};
      }

      if (dashboard.level === dashboard.LEVELS.USER) {
        params.user = user.username;
      } else if (dashboard.level === dashboard.LEVELS.GROUP) {
        params.group = dashboard.group;
      } else if (dashboard.level === dashboard.LEVELS.SYSTEM) {
        params.group = 'searchappliance_search';
      }

      return params;
    }

    function initWithUrl (url) {
      $scope.selected = findUrlInList(url, $scope.savedList);

      if ($scope.selected) {
        Object.keys(linkOptionsMap).forEach(function (linkOption) {
          var opt = linkOptionsMap[linkOption];

          if (angular.isString(opt)) {
            $scope.linkOptions[opt] = (url.indexOf(linkOption) >= 0);
          }
        });
      }
      startWatchingForChanges();
    }

    function findUrlInList (url, list) {
      if (!url || !list) { return; }

      for (var i = 0, ii = list.length; i < ii; ++i) {
        if (url.indexOf(list[i].link) === 0) {
          return list[i];
        }
      }
    }

    function startWatchingForChanges () {
      $scope.$watch('selected', function (curr) {
        if (curr) updateTileUrl();
      }, true);

      $scope.$watch('linkOptions.hideRecordList', function (curr) {
        if (curr === false) {
          $scope.linkOptions.showPatientPanel = false;
        }
        updateTileUrl();
      });

      $scope.$watch('linkOptions.hidePatientPanel', function () {
        updateTileUrl();
      });
    }

    function updateTileUrl () {
      $scope.tile.link.href = $scope.selected ? rebuildUrlWithOptions($scope.selected.link, $scope.linkOptions) : '';
    }

    function rebuildUrlWithOptions (url, options) {
      if (!url) { return ''; }

      var queryStringOptions = url.match(/\?(.+)/)[1].split('&') || [];

      // Exclude parameters we will evaluate
      queryStringOptions = queryStringOptions.filter(function (val) {
        return !Object.prototype.hasOwnProperty.call(linkOptionsMap, val);
      });

      Object.keys(linkOptionsMap).forEach(function (linkOption) {
        var opt = linkOptionsMap[linkOption];

        if (angular.isFunction(opt)) {
          if (opt()) { queryStringOptions.push(linkOption); }
        } else if (options[opt]) {
          queryStringOptions.push(linkOption);
        }
      });

      return url.replace(/\?.+/, '?' + queryStringOptions.join('&'));
    }
  }
})();
