(function () {
  'use strict';

  angular.module('admin')
    .controller('deleteSystemGroupCtrl', deleteSystemGroupCtrl);

  deleteSystemGroupCtrl.$inject = ['$mdDialog', '$scope', 'modalGroup'];

  function deleteSystemGroupCtrl ($mdDialog, $scope, modalGroup) {
    $scope.deleteConfirm = '';
    $scope.group = modalGroup;

    $scope.confirm = function () {
      $mdDialog.hide(true);
    };

    $scope.cancel = function () {
      $mdDialog.cancel();
    };
  }
})();
