(function () {
  'use strict';

  angular.module('imat.filters')
    .filter('truncate', [function () {
      return function (text, length, end) {
        if (isNaN(length)) {
          length = 10;
        }

        if (end === undefined) {
          end = '...';
        }

        if (text.length + end.length > length) {
          return String(text).substring(0, length - end.length) + end;
        }

        return text;
      };
    }]);
})();
