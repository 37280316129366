(function () {
  'use strict';

  angular.module('imat')
    .config(config);

  config.$inject = ['$stateRegistryProvider', '$urlServiceProvider'];

  function config ($stateRegistryProvider, $urlServiceProvider) {
    $urlServiceProvider.deferIntercept();// Defer routing until the app is loaded.
    // Allow a URL with a trailing slash to match a pattern without one.
    $urlServiceProvider.config.strictMode(false);
    // See app.run.js for:
    // $urlServiceProvider.rules.otherwise
    // $urlServiceProvider.rules.when

    $stateRegistryProvider.register({
      name: 'app',
      abstract: true,
      views: {
        '@': {
          templateUrl: '/ui/_shared/layouts/imat-layout.html',
          controller: 'ImatCtrl',
          controllerAs: 'ImatCtrl'
        }
      }
    });
  }
})();
