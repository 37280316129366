/* eslint-disable no-invalid-this */
(function () {
  'use strict';

  angular.module('mpiApp.services')
    .factory('MpiPersonaClass', MpiPersonaClass);

  MpiPersonaClass.$inject = ['$q', 'imatFieldsSrv', 'imatStoresSrv', 'mpiApiSrv', 'MpiPatientClass', 'mpiUtilitySrv'];

  function MpiPersonaClass ($q, imatFieldsSrv, imatStoresSrv, mpiApiSrv, MpiPatientClass, mpiUtilitySrv) {
    var util = mpiUtilitySrv;

    function MpiPersona (rawRecord) {
      MpiPatientClass.call(this, rawRecord);

      // XXX Legacy --------------------------
      this.has_multiple_pis = this.hasMultipleContained();// Prefer calling this.hasMultipleContained() directly.
      // -------------------------------------
      this.mpid = this.getMPID();

      this.getContainedSummary();// Sets up this.contained.
    }

    var prototype = {

      getClinicalRecordCount: function () {
        var pidField = imatFieldsSrv.get('pi.field');
        var promises = [];
        var stores = imatStoresSrv.included.map(function (store) { return store.number; });

        this.getContainedPatientIDs().forEach(function (pid) {
          promises.push(
            mpiApiSrv.getClinicalRecordCount(pidField, pid, stores)
              .then(function (summary) {
                var counts = {};
                var total = 0;
                // Convert store numbers to names.
                angular.forEach(summary, function (count, store) {
                  counts[imatStoresSrv.index[store].name] = count;
                  total += count;
                });
                return { pid: pid, counts: counts, total: total };
              })
              .catch(function () {
                // Ignored.
              })
          );
        });

        return $q.all(promises)
          .then(function (all) {
            return all.reduce(function (acc, cur) {
              acc[cur.pid] = cur;
              return acc;
            }, {});
          });
      },

      getContained: function () {
        var patients = [];

        if (this._cache.contained) {
          return this._cache.contained;
        }
        if (this._rawRecord.contained != null) {
          if (!Array.isArray(this._rawRecord.contained)) {
            this._rawRecord.contained = [this._rawRecord.contained];
          }
          this._rawRecord.contained.forEach(function (record) {
            patients.push(new MpiPatientClass(record));
          });
        }
        this._cache.contained = patients;
        return patients;
      },

      getContainedPatientIDs: function () {
        if (!this._cache.containedPatientIDs) {
          this._cache.containedPatientIDs = this._cache.contained.map(function (patient) { return patient.pid; }).filter(util.removeDuplicates);
        }
        return this._cache.containedPatientIDs;
      },

      getContainedSummary: function () {
        if (!this.contained) {
          this.contained = _getContainedSummary.call(this);
        }
        return this.contained;
      },

      getMPID: function () {
        if (this._cache.mpid != null && this._cache.mpid) {
          return this._cache.mpid;
        }

        if (this._rawRecord.id && angular.isString(this._rawRecord.id) && !/\.PI$/.test(this._rawRecord.id)) {
          return this._cache.mpid = this._rawRecord.id; // eslint-disable-line no-return-assign
        }

        return MpiPatientClass.prototype.getMPID.call(this);
      },

      // Override MpiPatient to reduce the array to a string.
      // TODO Stop doing this?
      getPatientEthnicity: function () {
        if (this._cache.preferredEthnicity == null) {
          MpiPatientClass.prototype.getPatientEthnicity.call(this);
          this._cache.preferredEthnicity = this._cache.preferredEthnicity.length ? this._cache.preferredEthnicity[0] : '';
        }
        return this._cache.preferredEthnicity;
      },

      getPatientID: function () {
        return '';
      },

      // Override MpiPatient to reduce the array to a string.
      // TODO Start doing this?
      // getPatientRace: function() {
      //   if (this._cache.preferredRace == null) {
      //     MpiPatientClass.prototype.getPatientRace.call(this);
      //     this._cache.preferredRace = this._cache.preferredRace.length ? this._cache.preferredRace[0] : '';
      //   }
      //   return this._cache.preferredRace;
      // },

      hasMultipleContained: function () {
        return (this._rawRecord && Array.isArray(this._rawRecord.contained) && this._rawRecord.contained.length > 1);
      }

    };

    MpiPersona.prototype = Object.create(MpiPatientClass.prototype);
    MpiPersona.prototype.constructor = MpiPersona;
    angular.extend(MpiPersona.prototype, prototype);

    return MpiPersona;

    //= ================================
    // MpiPersona Class Helpers
    //= ================================

    function _getContainedDeceased () {
      var deceased = [];
      var deceasedBooleans;
      var deceasedDateTimes;

      if (this._cache.containedDeceased == null) {
        deceasedDateTimes = _getRawContainedValues.call(this, 'deceasedDateTime').filter(util.removeDuplicates);
        deceasedDateTimes = deceasedDateTimes.filter(function (d) {
          return !isNaN(new Date(d).getTime());
        });
        if (deceasedDateTimes.length > 0) {
          deceased.push(true);
          deceased = deceased.concat(deceasedDateTimes);
        } else {
          deceasedBooleans = _getRawContainedValues.call(this, 'deceasedBoolean').filter(util.removeDuplicates);
          deceasedBooleans = deceasedBooleans.filter(function (b) {
            return b === true || /^(?:true|t|yes|y|1)$/i.test(b || '');
          });
          if (deceasedBooleans.length > 0) {
            deceased.push(true);
          }
        }
        this._cache.containedDeceased = deceased.filter(util.removeDuplicates);
      }
      return this._cache.containedDeceased;
    }

    function _getContainedMultipleBirth () {
      var multipleBirth = [];
      var birthBooleans;
      var birthIntegers;

      if (this._cache.containedMultipleBirth == null) {
        birthIntegers = _getRawContainedValues.call(this, 'multipleBirthInteger').filter(util.removeDuplicates);
        birthIntegers = birthIntegers.filter(function (i) {
          return i != '0' && i == parseInt(i, 10); // eslint-disable-line eqeqeq
        });
        if (birthIntegers.length > 0) {
          multipleBirth.push(true);
          multipleBirth = multipleBirth.concat(birthIntegers);
        } else {
          birthBooleans = _getRawContainedValues.call(this, 'multipleBirthBoolean').filter(util.removeDuplicates);
          birthBooleans = birthBooleans.filter(function (b) {
            return b === true || /^(?:true|t|yes|y|1)$/i.test(b || '');
          });
          if (birthBooleans.length > 0) {
            multipleBirth.push(true);
          }
        }
        this._cache.containedMultipleBirth = multipleBirth.filter(util.removeDuplicates);
      }
      return this._cache.containedMultipleBirth;
    }

    // TODO Remove me?
    function _getRawContainedValues (property) {
      var ret = [];

      if (!this.isRawRecordEmpty() && Array.isArray(this._rawRecord.contained)) {
        this._rawRecord.contained.forEach(function (record) {
          var recObj = (Object.prototype.hasOwnProperty.call(record, 'Patient') ? record.Patient : record);

          if (Object.prototype.hasOwnProperty.call(recObj, property)) {
            ret.push(angular.copy(recObj[property]));
          }
        });
      }
      return ret;
    }

    function _getContainedSummary () {
      var contained = this.getContained();
      var summary = {
        address_1s: [],
        address_2s: [],
        addresses: [],
        birth_dates: [],
        cities: [],
        deceased: [],
        ethnicities: [],
        facilities: [],
        family_names: [],
        first_names: [],
        full_addresses: [],
        genders: [],
        last_updateds: [],
        metas: [],
        middle_initials: [],
        middle_names: [],
        multiple_birth: [],
        names: [],
        phone_numbers: [],
        postal_codes: [],
        prefixes: [],
        races: [],
        ssns: [],
        states: [],
        suffixes: [],
        telecoms: []
      };

      contained.forEach(function (patient) {
        summary.address_1s = summary.address_1s.concat(patient.address_1);
        summary.address_2s = summary.address_2s.concat(patient.address_2);
        summary.addresses = summary.addresses.concat(patient.address);
        summary.birth_dates = summary.birth_dates.concat(patient.birth_date);
        summary.cities = summary.cities.concat(patient.city);
        // summary.deceased = summary.deceased.concat(patient.deceased);
        summary.ethnicities = summary.ethnicities.concat(patient.ethnicity);
        summary.facilities = summary.facilities.concat(patient.facility);
        summary.family_names = summary.family_names.concat(patient.family_name);
        summary.first_names = summary.first_names.concat(patient.first_name);
        summary.full_addresses = summary.full_addresses.concat(patient.full_address);
        summary.genders = summary.genders.concat(patient.gender);
        summary.last_updateds = summary.last_updateds.concat(patient.last_updated);
        summary.metas = summary.metas.concat(patient.meta);
        summary.middle_initials = summary.middle_initials.concat(patient.middle_initial);
        summary.middle_names = summary.middle_names.concat(patient.middle_name);
        // summary.multiple_birth = summary.multiple_birth.concat(patient.multiple_birth);
        summary.names = summary.names.concat(patient.name);
        summary.phone_numbers = summary.phone_numbers.concat(patient.phone_number);
        summary.postal_codes = summary.postal_codes.concat(patient.postal_code);
        summary.prefixes = summary.prefixes.concat(patient.prefix);
        summary.races = summary.races.concat(patient.race);
        summary.ssns = summary.ssns.concat(patient.ssn);
        summary.states = summary.states.concat(patient.state);
        summary.suffixes = summary.suffixes.concat(patient.suffix);
        summary.telecoms = summary.telecoms.concat(patient.telecom);
      });
      Object.keys(summary).forEach(function (key) {
        if (key === 'facilities') {
          summary[key] = util.processFmrnsByPeriod(summary[key]).filter(util.removeEmpty);
        } else {
          summary[key] = summary[key].filter(util.removeDuplicates).filter(util.removeEmpty);
        }
      });

      // Special handling
      // TODO Stop it.
      summary.deceased = _getContainedDeceased.call(this);
      summary.multiple_birth = _getContainedMultipleBirth.call(this);

      return summary;
    }
  }
})();
