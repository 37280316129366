(function () {
  'use strict';

  angular.module('queries')
    .controller('RecordsLayoutCtrl', RecordsLayoutCtrl);

  RecordsLayoutCtrl.$inject = ['$log', '$mdSidenav', '$q', 'psNotification', 'QueriesSrv'];

  function RecordsLayoutCtrl ($log, $mdSidenav, $q, psNotification, QueriesSrv) {
    var vm = this;

    // Properties
    vm.allRecords = false;
    vm.loading = {
      record: false,
      records: false
    };
    vm.nav = {
      records: QueriesSrv.recordList,
      recordsMenuOpen: true
    };
    vm.record = QueriesSrv.record;
    vm.sidenavOpen = true;

    // Methods
    vm.loadRecord = loadRecord;
    vm.toggleSidenav = toggleSidenav;
    vm.updateAll = updateAll;

    activate();

    // Implementation

    function activate () {
      setupRecordsList(vm.allRecords);
    }

    function loadRecord (record) {
      vm.loading.record = true;
      return QueriesSrv.setRecord(record)
        .then(QueriesSrv.loadRecordData)
        .catch(function (errorResponse) {
          psNotification.error('Unable to load the record data.');
          return $q.reject(errorResponse);
        })
        .finally(function () {
          vm.loading.record = false;
        });
    }

    function setupRecordsList (showAllRecords) {
      vm.loading.records = true;
      QueriesSrv.getRecordList(showAllRecords)
        .catch(function (errorResponse) {
          $log.debug(errorResponse);
          psNotification.error('Unable to load the patient records.');
          return $q.reject(errorResponse);
        })
        .finally(function () {
          vm.loading.records = false;
        });
      return true;
    }

    function toggleSidenav () {
      $mdSidenav('record-details-sidenav').toggle();
    }

    function updateAll () {
      setupRecordsList(vm.allRecords);
    }
  }
})();
