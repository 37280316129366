(function () {
  'use strict';

  angular
    .module('admin')
    .controller('FeedJobsCtrl', FeedJobsCtrl);

  FeedJobsCtrl.$inject = [];

  function FeedJobsCtrl () {}
})();
