(function () {
  'use strict';

  angular
    .module('vhr.directives')
    .directive('vhrPrintView', vhrPrintView);

  function vhrPrintView () {
    var directive = {
      controller: vhrPrintViewController,
      controllerAs: 'ctrl',
      templateUrl: '/ui/vhr/directives/vhrPrintView/vhr-print-view.html',
      transclude: true,
      restrict: 'A',
      scope: true
    };

    return directive;
  }

  vhrPrintViewController.$inject = ['$rootScope'];

  function vhrPrintViewController ($rootScope) {
    var vm = this; // eslint-disable-line no-invalid-this
    vm.printingAvailable = $rootScope.printingAvailable;
  }
})();
