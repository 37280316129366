(function () {
  'use strict';

  angular.module('imat.services')
    .factory('fieldsApiSrv', fieldsApiSrv);

  fieldsApiSrv.$inject = ['$http', '$parse'];

  function fieldsApiSrv ($http, $parse) {
    var service = {
      getFieldMap: getFieldMap
    };

    return service;

    function getFieldMap () {
      return $http({
        method: 'GET',
        url: '/search-ui/fields.xml',
        headers: { Accept: 'text/xml, application/xml' }
      })
        .then(function (response) {
          var payload = $parse('variables.variable')(response.data);

          if (payload != null && !angular.isArray(payload)) {
            payload = [payload];
          }
          response.data = payload;
          return response;
        });
    }
  }
})();
