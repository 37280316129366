(function () {
  'use strict';

  angular.module('admin')
    .controller('DashboardUsersCtrl', DashboardUsersCtrl);

  DashboardUsersCtrl.$inject = [
    '$filter', '$mdDialog', '$q', '$scope', '$timeout', 'DashboardsSrv', 'ProvidersSrv', 'psNotification', 'UsersSrv'
  ];

  function DashboardUsersCtrl (
    $filter, $mdDialog, $q, $scope, $timeout, DashboardsSrv, ProvidersSrv, psNotification, UsersSrv
  ) {
    var vm = this;
    var ctx = this.constructor.name;
    var DASHBOARD_GET_ERROR = 'Unable to retrieve the dashboards.';

    $scope.ACCESS = DashboardsSrv.ACCESS;
    $scope.LEVELS = DashboardsSrv.LEVELS;

    // Properties
    vm.loaded = { detail: true, list: true };
    vm.providerCount = 1;
    vm.searchUserText; // eslint-disable-line no-unused-expressions
    vm.selected = {};
    vm.selectedDetail = { empty: true };
    vm.selectedFilter = '';
    vm.userDashboards = [];
    vm.userListFilters = [{
      name: 'Can Edit',
      key: 'owner_permissions'
    }, {
      name: 'Cannot Edit',
      key: 'disabled'
    }];

    // Methods
    vm.addDashboard = addDashboard;
    vm.clearSelection = clearSelection;
    vm.cloneDashboard = cloneDashboard;
    vm.filterUserList = filterUserList;
    vm.refreshUserDashboardList = refreshUserDashboardList;
    vm.resetUserFilters = resetUserFilters;
    vm.selectDashboard = selectDashboard;
    vm.textFilterList = textFilterList;

    activate();

    function activate () {
      vm.loaded.list = false;
      ProvidersSrv.getProviderList()
        .then(function (providers) {
          vm.providerCount = providers.length;
          vm.providers = providers;

          if (vm.providerCount > 1) {
            providers.forEach(function (provider) {
              vm.userListFilters.push({
                name: 'Provider: ' + provider,
                key: 'provider',
                value: provider
              });
            });
          }
        });

      resetUserFilters();
      filterUserList(true)
        .catch(function () {
          psNotification.error(DASHBOARD_GET_ERROR);
        });
    }

    //= ==============================
    // Public interface
    //= ==============================

    function addDashboard () {
      var dialog = {
        controller: 'addDashboardModalCtrl as vm',
        templateUrl: '/ui/admin/dashboards/addDashboardModal.html',
        locals: { modalLevel: DashboardsSrv.LEVELS.USER, modalDashboards: vm.userDashboards },
        parent: angular.element(document.body),
        targetEvent: event,
        clickOutsideToClose: true
      };

      $mdDialog.show(dialog)
        .then(function (modalDashboard) {
          createDashboardForUser(modalDashboard);
        })
        .catch(function () {
        // Dismissed.
        });
    }

    function clearSelection () {
      DashboardsSrv.clearSelected(ctx);
      clearSelectionDetail();
      vm.selected = {};
    }

    function clearSelectionDetail () {
      vm.selectedDetail = { empty: true };
    }

    function cloneDashboard () {
      var dialog = {
        controller: 'cloneDashboardModalCtrl as vm',
        templateUrl: '/ui/admin/dashboards/cloneDashboardModal.html',
        locals: {
          modalDashboards: vm.userDashboards,
          modalLevel: DashboardsSrv.LEVELS.USER,
          modalOwner: '{name} ({username})'
            .replace('{name}', vm.selected.user.name)
            .replace('{username}', vm.providerCount > 1 ? vm.selected.owner : vm.selected.user.username)
        },
        parent: angular.element(document.body),
        targetEvent: event,
        clickOutsideToClose: true
      };

      $mdDialog.show(dialog)
        .then(function (modalDashboard) {
          modalDashboard.tiles = vm.selectedDetail.tiles;
          createDashboardForUser(modalDashboard);
        })
        .catch(function () {
        // Dismissed.
        });
    }

    function filterUserList (force) {
      return UsersSrv.getList(force)
        .then(function (users) { // eslint-disable-line no-unused-vars
          if (vm.selectedFilter && vm.selectedFilter.key !== 'provider') {
            users = $filter('filter')(users, function (value) {
              return value[vm.selectedFilter.key] === 'true';
            });
          } else {
            users = $filter('filter')(users, function (value) {
              return (vm.selectedFilter) ? value[vm.selectedFilter.key] === vm.selectedFilter.value : true;
            });
          }
          return refreshUserDashboardList();
        })
        .catch(function () {
          psNotification.error(DASHBOARD_GET_ERROR);
        });
    }

    function refreshUserDashboardList (force) {
      var q = [];
      var searching = Boolean($scope.userFilter);

      q.push(
        DashboardsSrv.getCompleteList(force)
      );

      q.push(
        UsersSrv.getList(force)
      );

      vm.loaded.list = false;
      return $q.all(q)
        .then(function (all) {
          var dashboards;
          var providerDashboards = all[0];
          var providerUsers = all[1];
          var reUserLevel = new RegExp('^' + DashboardsSrv.LEVELS.USER + '$', 'i');

          // Find user level dashboards for users that we've filtered for.
          dashboards = providerDashboards.filter(function (dashboard) {
            if (!searching) {
              return reUserLevel.test(dashboard.level);
            }
            if (reUserLevel.test(dashboard.level)) {
              for (var i = providerUsers.length - 1; i >= 0; --i) {
                if (providerUsers[i].username + '@' + providerUsers[i].provider === dashboard.owner) {
                  return true;
                }
              }
            }
            return false;
          });

          vm.userDashboards.splice(0);

          // Arrange the dashboards in user-alphabetical order.
          providerUsers.forEach(function (user) {
            for (var i = dashboards.length - 1; i >= 0; --i) {
              if (user.username + '@' + user.provider === dashboards[i].owner) {
                dashboards[i].user = user;
                vm.userDashboards.push(dashboards.splice(i, 1).pop());
                break;
              }
            }
          });
        })
        .finally(function () {
          vm.loaded.list = true;
          if (DashboardsSrv.getSelectedId(ctx)) {
            selectDashboard(DashboardsSrv.getSelected(ctx));
          }
        });
    }

    function resetUserFilters () {
      vm.searchUserText = '';
      vm.selectedFilter = '';
      filterUserList(false);
    }

    function selectDashboard (dashboard) {
      vm.selected = dashboard;
      DashboardsSrv.setSelected(ctx, dashboard.id);

      vm.loaded.detail = false;
      if (!DashboardsSrv.isCachedItem(dashboard.id)) {
        clearSelectionDetail();
      }

      DashboardsSrv.getSelectedItem(ctx)
        .then(function (mwDashboard) {
          vm.selectedDetail = mwDashboard;
        })
        .finally(function () {
          $timeout(function () {
            vm.loaded.detail = true;
          });
        });
    }

    function textFilterList (dashboard) {
      if (!vm.searchUserText) {
        return true;
      }
      return (dashboard.user.username.toLowerCase().indexOf(vm.searchUserText.toLowerCase()) !== -1) ? true
        : (dashboard.user.name.toLowerCase().indexOf(vm.searchUserText.toLowerCase()) !== -1) ? true
          : (dashboard.display_name.toLowerCase().indexOf(vm.searchUserText.toLowerCase()) !== -1);
    }

    //= ================================
    // Private interface
    //= ================================

    function createDashboardForUser (dashboard) {
      // DashboardsSrv.getDashboard(dashboard)
      // .then(function() {
      //   psNotification.error('A dashboard already exists for this user.');
      // }, function() {
      DashboardsSrv.save(dashboard)
        .then(function (mwDashboard) {
          DashboardsSrv.setSelected(ctx, mwDashboard.id);
          // The dashboard has been reloaded with default permsissions...
          mwDashboard.owner_permissions = DashboardsSrv.ACCESS.READ;
          return DashboardsSrv.savePermissions(mwDashboard);
        }, function (err) {
          psNotification.error('Unable to create the dashboard.');
          return $q.reject(err);
        })
        .then(function () {
          refreshUserDashboardList(true);
        });
      // });
    }
  }
})();
