(function () {
  'use strict';

  angular
    .module('vhr.directives')
    .directive('vhrModePicker', vhrDir);

  vhrDir.$inject = ['$timeout', 'vhrApp'];

  function vhrDir ($timeout, vhrApp) {
    var directive = {
      link: postLink,
      restrict: 'E',
      templateUrl: '/ui/vhr/directives/vhrModePicker/vhr-mode-picker.html'
    };

    return directive;

    function postLink (scope, element) {
      var srvMode = vhrApp.getMode();

      scope.btns = [];
      scope.enabled = vhrApp.enableModes();
      scope.setMode = vhrApp.setMode;

      vhrApp.getModes().forEach(function (mode) {
        scope.btns.push({
          mode: mode,
          meta: vhrApp.getModeMeta(mode),
          selected: (mode === srvMode)
        });

        // POC for select menu style
        // if (mode === srvMode) {
        // scope.currentIndex = mode;
        // }
      });

      scope.$on('vhrModeChanged', function (evt, srvMode) { // eslint-disable-line no-shadow
        var isMode = false;
        // var btns = element.children();

        // vhrApp.getModes().forEach(function(mode, idx) {
        //  angular.element(btns[idx]).toggleClass('md-raised', (mode === srvMode));
        // });

        // POC for select menu style
        scope.btns.forEach(function (btn, idx) { // eslint-disable-line no-unused-vars
          isMode = (btn.mode === srvMode);
          btn.selected = isMode;
        //  if(isMode) {
        //    scope.currentIndex = idx;
        //  }
        });
      });

      scope.$on('vhrModesEnabled', function (evt, enabled) {
        var btns = element.children();
        var fn = (enabled) ? 'removeAttr' : 'attr';

        vhrApp.getModes().forEach(function (mode, idx) {
          angular.element(btns[idx])[fn]('disabled', true);
        });
      });
    }
  }
})();
