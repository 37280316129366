(function () {
  'use strict';

  angular.module('imat.services')
    .factory('fhirApiSrv', fhirApiSrv);

  fhirApiSrv.$inject = ['$http'];

  function fhirApiSrv ($http) {
    var service = {
      getPotentialMpid: getPotentialMpid
    };

    return service;

    function getPotentialMpid (fhirJson) {
      return $http({
        method: 'POST',
        url: '/extensions/mpi/mpid/lookup',
        headers: { 'Content-Type': 'application/fhir+json' },
        data: fhirJson
      });
    }
  }
})();
