(function () {
  'use strict';

  angular.module('admin')
    .controller('EditSetCtrl', EditSetCtrl);

  EditSetCtrl.$inject = ['$q', '$scope', '$state', '$transition$', 'GroupsSrv', 'ProvidersSrv', 'psNotification', 'imatStoresSrv', 'SetsSrv', 'UsersSrv'];

  function EditSetCtrl ($q, $scope, $state, $transition$, GroupsSrv, ProvidersSrv, psNotification, imatStoresSrv, SetsSrv, UsersSrv) {
    var vm = this;
    var setId = $transition$.params().id;
    var STORE_ALL = '-1';

    // Field options
    vm.fieldNamesOp = {
      PatientIDN: 'PATIENTIDN',
      NREF: '',
      other: 'other'
    };
    vm.ownerTypes = {
      User: 'user',
      Group: 'group'
    };
    vm.queryType = {
      Compound: 'c',
      Single: 'q'
    };

    // Variables.
    vm.editorConfig = {
      lineWrapping: true,
      lineNumbers: true,
      indentUnit: 4,
      theme: 'eclipse',
      mode: 'python'
    };
    vm.editorFocused = false;
    vm.filteredOwners = [];
    vm.form = {};
    vm.listOfGroups = [];
    vm.listOfUsers = [];
    vm.providers = [];
    $scope.selectedOwner = null;
    vm.stores = [];
    vm.submittable = false;

    // Methods.
    vm.cancel = cancel;
    vm.codemirrorLoaded = codemirrorLoaded;
    vm.filterOwners = filterOwners;
    vm.save = save;
    vm.selectOwner = selectOwner;

    activate();

    function activate () {
      loadProviders().then(function () {
        loadSet();
      });

      $scope.$watch(function () { return vm.form.selectedStore; }, function (curr, prev) {
        if (angular.isArray(curr)) {
          if (curr.length === 0) {
            vm.form.selectedStore = [STORE_ALL];
          } else if (curr.length > 1) {
            const currAllIdx = curr.indexOf(STORE_ALL);
            if (currAllIdx >= 0) {
              if (prev && prev.indexOf(STORE_ALL) >= 0) {
                vm.form.selectedStore.splice(currAllIdx, 1);
              } else if (prev) {
                vm.form.selectedStore = [STORE_ALL];
              }
            }
          }
        } else if (!curr) {
          vm.form.selectedStore = [STORE_ALL];
        }
      });
    }

    // ========================
    //    Public Methods
    // ========================

    // Cancel creation of new set.
    function cancel () {
      vm.form.selectedStore = [];
      vm.editCurrentItem = 0;
      $state.go('app.admin.sets');
    }

    // Makes codemirror object events available to tie further logic to.
    function codemirrorLoaded (editor) {
      var cmDoc = editor.getDoc();
      var jqTxt = angular.element(editor.getTextArea()).get(0);
      var ngTxt = $scope[jqTxt.form.name][jqTxt.name];

      cmDoc.markClean();

      // Events
      editor.on('focus', function () {
        $scope.$applyAsync(function () { vm.editorFocused = true; });
      });

      editor.on('blur', function () {
        $scope.$applyAsync(function () { vm.editorFocused = false; });
        ngTxt.$setTouched();// Triggers required.
      });
    }

    function filterOwners (filter) {
      var field = 'name';
      var owners = vm.listOfGroups;
      var rexFilter = new RegExp(filter, 'i');

      if (vm.form.groupOrUser === vm.ownerTypes.User) {
        field = 'username';
        owners = vm.listOfUsers;
      }
      vm.filteredOwners = owners.filter(function (owner) {
        return rexFilter.test(owner[field]);
      });
    }

    // Saves edit of current item.
    function save (activateSet) {
      // vm.form.fieldnameEdit = '';
      if (vm.form.fieldname === vm.fieldNamesOp.other) {
        vm.form.fieldname = vm.form.fieldnameEdit;
      }
      var session_id = vm.form.groupOrUser + '_' + vm.form.groupOrUserName + '@' + vm.form.provider; // eslint-disable-line
      var payload = {
        id: setId,
        name: vm.form.set_name,
        sessionid: session_id,
        query_type: vm.form.query_type,
        query_string: vm.form.query_string,
        fieldname: vm.form.fieldname,
        description: vm.form.description,
        store: vm.form.selectedStore.join()
      };
      if (payload.query_type === vm.queryType.Compound) {
        payload.store = null;
      }
      SetsSrv.saveSetDefinition(setId, payload)
        .then(function () {
          if (activateSet) {
            SetsSrv.createSet(setId)
              .then(function () {
                psNotification.success('Set definition saved and set created.');
                $state.go('app.admin.sets');
              })
              .catch(function () {
                psNotification.warn('Saved set definition, but did not create the set.');
                $state.go('app.admin.sets');
              });
          } else {
            psNotification.success('Set definition saved.');
            $state.go('app.admin.sets');
          }
        }).catch(function () {
          psNotification.warn('Failed to save set definition.');
        });
    }

    function selectOwner (owner) {
      if (owner) {
        vm.form.groupOrUserName = owner.username || owner.name || '';
      }
    }

    // ====================
    //   Private Methods
    // ====================

    function loadProviders () {
      const providerPromise = ProvidersSrv.getProviderList()
        .then(function (providers) {
          vm.providers = providers;
          vm.form.provider = providers[providers.length - 1];
        });
      const groupPromise = GroupsSrv.getList()
        .then(function (groups) {
          vm.listOfGroups = groups;
        });
      const userPromise = UsersSrv.getList()
        .then(function (users) {
          vm.listOfUsers = users;
        });
      const storesPromise = loadStores();

      return $q.all([providerPromise, groupPromise, userPromise, storesPromise]);
    }

    function splitAtLastIndex(strToSplit, charToSplitAt) {
      let parts = [];
      let index = strToSplit.lastIndexOf(charToSplitAt);
      parts.push(strToSplit.slice(0, index));
      parts.push(strToSplit.slice(index + 1))
      return parts;
    }

    function loadSet () {
      // Get specific set on page load after clicking on specific set from the list of sets view.
      SetsSrv.getSet(setId)
        .then(function (set) {
          var groupOrUser = set.sessionid.split(/_(.+)/);
          groupOrUser.splice(2, 1);
          var groupNameAndAuthProv = splitAtLastIndex(groupOrUser[1], '@');
          vm.form.groupOrUser = groupOrUser[0];
          vm.form.groupOrUserName = groupNameAndAuthProv[0];
          vm.form.provider = groupNameAndAuthProv[1];
          vm.form.set_name = set.name;
          if (Object.values(vm.fieldNamesOp).indexOf(set.fieldname) >= 0) {
            vm.form.fieldname = set.fieldname;
          } else {
            vm.form.fieldname = vm.fieldNamesOp.other;
            vm.form.fieldnameEdit = set.fieldname;
          }
          vm.form.description = set.description;
          vm.form.query_type = set.query_type;
          vm.form.query_string = set.query_string;
          vm.form.selectedStore = [STORE_ALL];
          if (set.query_type === vm.queryType.Compound) {
            set.store = null;
          }
          if (Object.prototype.hasOwnProperty.call(set, 'store') && set.store !== null && set.query_type !== vm.queryType.Compound) {
          // Not Legacy.
            if (!Array.isArray(set.store)) {
              vm.form.selectedStore = set.store.split(',');
            } else {
              vm.form.selectedStore = set.store;
            }
          }

          // Pre-fill the Owner autocomplete.
          filterOwners(vm.form.groupOrUserName);
          for (let i = 0; i < vm.filteredOwners.length; i++) {
            if (vm.filteredOwners[i].username === vm.form.groupOrUserName || vm.filteredOwners[i].name === vm.form.groupOrUserName) {
              $scope.selectedOwner = vm.filteredOwners[i];
              break;
            }
          }

          $scope.setEditForm.$setPristine();
        })
        .catch(function () {
          psNotification.error('Not able to load set definition.');
        });
    }

    function loadStores () {
      // Gets list of stores available.
      imatStoresSrv.getIncluded()
        .then(function (stores) {
          vm.stores = [{
            exclude: '0',
            name: 'All non-excluded stores',
            number: STORE_ALL
          }];
          stores.forEach(function (store) {
            vm.stores.push({
              exclude: store.exclude.toString(),
              name: store.name.toString(),
              number: store.number.toString()
            });
          });
        });
    }
  }
})();
