(function () {
  'use strict';

  angular.module('mpiApp.search')
    .controller('reviewSelectionCtrl', reviewSelectionCtrl);

  reviewSelectionCtrl.$inject = ['$scope', '$mdDialog', 'records'];

  function reviewSelectionCtrl ($scope, $mdDialog, records) {
    var vm = this; // eslint-disable-line no-invalid-this

    // Variables
    vm.records = records;
    vm.comment = '';
    vm.enabled = false;
    vm.scrub = false;
    vm.recordText = vm.records.length > 1 ? 'records' : 'record';

    // Methods
    vm.cancel = cancel;
    vm.confirm = confirm;
    vm.remove = remove;

    activate();

    function activate () {
      $scope.$watch(function () { return vm.records.length; }, function () {
        vm.recordText = vm.records.length > 1 ? 'records' : 'record';
      });
    }

    function cancel () {
      $mdDialog.cancel();
    }

    function confirm () {
      vm.records = vm.records.map(function (element) {
        return element.uri;
      });
      $mdDialog.hide({ records: vm.records, comment: vm.comment, scrub: vm.scrub });
    }

    function remove (record) {
      vm.records.splice(record, 1);
      if (!vm.records.length) {
        cancel();
      }
    }
  }
})();
