(function () {
  'use strict';

  angular.module('imat.services')
    .factory('ctakesSrv', ctakesSrv);

  ctakesSrv.$inject = ['ctakesApiSrv', 'searchApiSrv'];

  function ctakesSrv (ctakesApiSrv, searchApiSrv) {
    var selectedCodes = {};

    var service = {
      deleteAlphanorm: deleteAlphanorm,
      getAlphanorm: getAlphanorm,
      getSelected: getSelected,
      postAlphanorm: postAlphanorm,
      postTranslate: postTranslate,
      putAlphanorm: putAlphanorm,
      removeSelectedCode: removeSelectedCode,
      removeAllSelected: removeAllSelected,
      wildcardSearch: wildcardSearch
    };

    function deleteAlphanorm (payload) {
      return ctakesApiSrv.deleteAlphanorm(payload)
        .then(function (res) {
          return res.data;
        });
    }

    function getAlphanorm (codeType, search) {
      return ctakesApiSrv.getAlphanorm(codeType, search.join(','))
        .then(function (res) {
          if (angular.isObject(res.data)) {
            Object.keys(res.data).forEach(function (key) {
              selectedCodes[key] = res.data[key];
              var temp = {};
              Object.keys(selectedCodes[key].body).sort()
                .forEach(function (entry) {
                  temp[entry] = selectedCodes[key].body[entry];
                });
              selectedCodes[key].body = temp;
            });
          }
        });
    }

    function getSelected () {
      return selectedCodes;
    }

    function postAlphanorm (payload) {
      return ctakesApiSrv.postAlphanorm(payload, 'deactivate')
        .then(function (res) {
          return res.data;
        });
    }

    function postTranslate (payload) {
      return ctakesApiSrv.postAlphanorm(payload, 'translate')
        .then(function (res) {
          return res.data;
        });
    }

    function putAlphanorm (payload) {
      return ctakesApiSrv.putAlphanorm(payload)
        .then(function (res) {
          return res.data;
        });
    }

    function removeAllSelected () {
      Object.keys(selectedCodes).forEach(function (key) {
        delete selectedCodes[key];
      });
    }

    function removeSelectedCode (code) {
      delete selectedCodes[code];
    }

    function wildcardSearch (codeType, search, tuis) {
      var wildcards = search.split(' ').map(function (c) { return c + '*'; }).join(' ');
      var config = {
        params: {
          store: 63,
          report: 'approxtotal',
          q: '()(pstitle:(' + wildcards + ') OR syn:(' + wildcards + ") OR pstitle:('" + search + "') OR syn:('" + search + "') AND (" + tuis + ')) AND sab:' + codeType,
          fields: 'title,description',
          limit: 25
        }
      };

      return searchApiSrv.post(null, config)
        .then(function (res) {
          return res.data.response.hits;
        });
    }

    return service;
  }
})();
