(function () {
  'use strict';

  angular.module('imat.services')
    .factory('collectionsSrv', collectionsSrv);

  collectionsSrv.$inject = ['$http', '$q', 'ProvidersSrv'];

  function collectionsSrv ($http, $q, ProvidersSrv) {
    var collections = [];
    var providers = [];
    var service = {
      createCollection: createCollection,
      deleteCollections: deleteCollections,
      editCollection: editCollection,
      getCollection: getCollection,
      getCollections: getCollections,
      updateGroups: updateGroups
    };

    function createCollection (collection) {
      return $http.post('/api/collections/', collection)
        .then(function (res) {
          return res.data;
        });
    }

    function deleteCollections (provider, id) {
      return $http.delete('/api/collections/' + id + '?provider=' + provider);
    }

    function editCollection (provider, collection) {
      return $http.put('/api/collections/' + collection.id, collection)
        .then(function () {
          return getCollection(provider, collection.id);
        });
    }

    function getCollection (provider, id) {
      return $http.get('/api/collections/' + id + '?provider=' + provider)
        .then(function (res) {
          return res.data;
        });
    }

    function getCollections (force) {
      if (!force && collections.length) {
        return $q.resolve(collections);
      }

      return ProvidersSrv.getProviderList()
        .then(function (res) {
          var promises = [];

          providers = res;
          providers.forEach(function (provider) {
            promises.push(
              $http.get('/api/collections?provider=' + provider).then(function (resp) { return resp.data; })
            );
          });

          collections = [];

          return $q.all(promises)
            .then(function (res) { // eslint-disable-line no-shadow
              res.forEach(function (collectionList) {
                collections = collections.concat(collectionList);
              });
              return collections;
            });
        });
    }

    function updateGroups (c, addedGroups, removedGroups) {
      if (!c.groups && (addedGroups || removedGroups)) {
        c.groups = [];
      }

      if (Array.isArray(removedGroups)) {
        removedGroups.forEach(function (group) {
          var idx = c.groups.indexOf(group);
          if (idx >= 0) {
            c.groups.splice(idx, 1);
          }
        });
      }

      if (Array.isArray(addedGroups)) {
        addedGroups.forEach(function (group) {
          c.groups.push(group);
        });
      }

      return editCollection(c.provider, c);
    }

    return service;
  }
})();
