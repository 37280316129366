(function () {
  'use strict';

  angular
    .module('vhr.services')
    .factory('vhrHieApi', vhrHieApi);

  vhrHieApi.$inject = ['HIE', '$http', '$log', '$q', 'HashSrv', 'psPhiAccess', 'vhrConfigSrv', 'vhrPatientSrv', 'vhrPersistence', 'vhrUserSrv'];

  function vhrHieApi (HIE, $http, $log, $q, HashSrv, psPhiAccess, vhrConfigSrv, vhrPatientSrv, vhrPersistence, vhrUserSrv) {
    var service;
    var targetUrlFormat = '/extensions/healthexchange/targets/{{targetOid}}';
    var useMockData = vhrConfigSrv.getHieMockData;
    var useSingleTargetRequest = vhrConfigSrv.getHieSingleTargetRequest;
    var hieDateBegin; // eslint-disable-line no-unused-vars
    var hieDateEnd; // eslint-disable-line no-unused-vars

    service = {
      hieResults: [],
      hieResultsCount: 0,
      organizations: {},

      getDocument: getDocument,
      getDocuments: getDocuments,
      getPatient: getPatient,
      getPurpose: getPurpose,
      getTarget: getTarget,
      getTargets: getTargets,
      getOrganization: getOrganization,
      lookup: lookup,
      resetHieResultsCount: resetHieResultsCount,
      setDateBegin: setDateBegin,
      setDateEnd: setDateEnd,
      setPurpose: setPurpose
    };

    return service;

    //= ================================
    // Configuration interface
    //= ================================

    function setDateBegin (date) {
      hieDateBegin = date;
    }

    function setDateEnd (date) {
      hieDateEnd = date;
    }

    function setPurpose (purpose) {
      purpose = purpose.toUpperCase();
      if (Object.prototype.hasOwnProperty.call(HIE.PURPOSE_OF_USE, purpose)) {
        vhrPersistence.setData('purposeOfUse', purpose);
      }
    }

    //= ================================
    // API
    //= ================================

    function getDocument (targetOid, sourceOid, repositoryId, documentId, force) {
      var rurl = getTargetUrl(targetOid) + '/documents/' + repositoryId + '/' + documentId;
      var doc = vhrPersistence.getData('hie_' + targetOid + '_repoId_' + repositoryId + '_documents_' + documentId);

      if (doc && !force) {
        return $q.resolve(doc);
      }

      // test using mock data
      if (useMockData()) {
        rurl = 'assets/hie-mock/document.xml';
      }

      return getPurpose()
        .then(function (purpose) {
          return $http.get(rurl, { params: { imatTransactionId: vhrPersistence.getData('imatTransactionId'), PurposeOfUse: purpose, sourceOid: sourceOid, mpid: vhrPatientSrv.getMpid() } })
            .then(function (response) {
              vhrPersistence.setData('hie_' + targetOid + '_repoId_' + repositoryId + '_documents_' + documentId, response.data.original_response);
              return response.data.original_response;
            });
        });
    }

    function getDocuments (targetOid, sourceOid, patientIdList, fields, force) {
      var url = getTargetUrl(targetOid) + '/documents';
      var promises = [];
      var rfields = angular.copy(fields || {});
      var documents = vhrPersistence.getData('hie_' + targetOid + '_' + patientIdList.toString().replace(/,/g, ''));

      if (documents && !force) {
        return $q.resolve(documents);
      }

      return getPurpose()
        .then(function (purpose) {
          rfields.sourceOid = sourceOid;
          rfields.mpid = vhrPatientSrv.getMpid();
          rfields.imatTransactionId = vhrPersistence.getData('imatTransactionId');
          rfields.PurposeOfUse = purpose;
          if (angular.isArray(patientIdList)) {
            patientIdList.forEach(function (patientId) {
              // test using mock data
              if (useMockData()) {
                url = 'assets/hie-mock/documents.json';
              }

              rfields.PatientId = ('' + patientId + '^^^&' + sourceOid + '&ISO');// Typical(?) for HL7 PID.3
              promises.push($http.get(url, { responseType: 'json', params: rfields }));
            });
          }
          return mungeDocuments();
        });

      function mungeDocuments () {
        return $q.all(promises)
          .then(function (all) {
            var coalesced = [];

            all.forEach(function (response) {
              response.data.documents.forEach(function (doc) {
                if (Object.prototype.hasOwnProperty.call(doc, 'homeCommunityId')) {
                  doc.sourceOid = doc.homeCommunityId;
                  delete doc.homeCommunityId;
                }
              });

              coalesced = coalesced.concat(response.data.documents);
            });
            vhrPersistence.setData('hie_' + targetOid + '_' + patientIdList.toString().replace(/,/g, ''), coalesced);
            return coalesced;
          })
          .catch(function () {
            $log.debug('Unable to lookup documents');
            return $q.reject('Unable to lookup the patient documents.');
          });
      }
    }

    function getPatient (fields, force) { // eslint-disable-line complexity
      fields = fields || {};

      // TODO: refactor vhrPersistence so doesn't have to trigger so much
      // temp code to set imatTransactionId for autolookup calls
      var imatTransactionId = vhrPersistence.getData('imatTransactionId');
      if (!imatTransactionId) {
        imatTransactionId = HashSrv.hash(vhrUserSrv.getUsername() + vhrUserSrv.getProvider() + vhrPatientSrv.getMpid() + Date.now());
        vhrPersistence.setData('imatTransactionId', imatTransactionId);
      }

      var patient;
      var targets = fields.targetOid || fields.targetoid || fields.targetOids || fields.targetoids || '';
      var url = '/extensions/healthexchange/patients/' + vhrPatientSrv.getMpid();

      // test using mock data
      if (useMockData()) {
        url = 'assets/hie-mock/patients.json';
      }

      targets = targets.split(',').map(function (t) { return t.trim(); });
      targets.sort();
      patient = vhrPersistence.getData('hiePatient_' + (targets.length ? targets.join(',') : 'all'));

      if (patient && !force) {
        return $q.resolve(patient);
      }

      return getPurpose()
        .then(function (purpose) {
          var promises = [];

          if (!targets.length) {
            promises.push($http.get(url, {
              responseType: 'json',
              params: {
                PurposeOfUse: purpose,
                targetoid: 'all',
                imatTransactionId: vhrPersistence.getData('imatTransactionId')
              }
            }));
          } else {
            if (useSingleTargetRequest()) {
              promises.push($http.get(url, {
                responseType: 'json',
                params: {
                  PurposeOfUse: purpose,
                  targetoid: targets.join(','),
                  imatTransactionId: vhrPersistence.getData('imatTransactionId')
                }
              }));
            } else {
              targets.forEach(function (targetOid) {
                promises.push($http.get(url, {
                  responseType: 'json',
                  params: {
                    PurposeOfUse: purpose,
                    targetoid: targetOid,
                    imatTransactionId: vhrPersistence.getData('imatTransactionId')
                  }
                }));
              });
            }
          }
          return $q.all(promises)
            .then(function (all) {
              var temp = [];
              patient = all.filter(function (response) {
                return (response && response.data && response.data.patient);
              }).map(function (response) { return response.data.patient; });
              patient.forEach(function (patientsFromSomewhere) {
                patientsFromSomewhere.forEach(function (patientObj) {
                  temp.push(patientObj);
                });
              });
              patient = temp;
              vhrPersistence.setData('hiePatient_' + (targets.length ? targets.join(',') : 'all'), patient);
              return patient;
            })
            .catch(function () {
              $log.debug('Unable to lookup patient');
              return $q.reject('Unable to lookup the patient');
            });
        });
    }

    function getTarget (targetOid) {
      var rurl = getTargetUrl(targetOid);
      var target = vhrPersistence.getData('hieTarget');

      if (target) {
        return $q.resolve(target);
      }

      // test using mock data
      if (useMockData()) {
        rurl = 'assets/hie-mock/targets.json';
      }

      return $http.get(rurl, { params: { imatTransactionId: vhrPersistence.getData('imatTransactionId') }, responseType: 'json' })
        .then(function (response) {
          vhrPersistence.setData('hieTarget', response.data);
          return response.data;
        });
    }

    function getTargets () {
      var url = '/extensions/healthexchange/targets';
      var targets = vhrPersistence.getData('hieTargets');

      if (targets) {
        return $q.resolve(targets);
      }

      // test using mock data
      if (useMockData()) {
        url = 'assets/hie-mock/targets.json';
      }

      return $http.get(url, { params: { imatTransactionId: vhrPersistence.getData('imatTransactionId') }, responseType: 'json' })
        .then(function (response) {
          vhrPersistence.setData('hieTargets', response.data);
          return response.data;
        });
    }

    function getOrganization (oid) {
      var label = vhrConfigSrv.getOIDLabels(oid);
      var url = '/extensions/healthexchange/organizations/' + oid;

      if (Object.prototype.hasOwnProperty.call(service.organizations, oid)) {
        return $q.resolve(service.organizations[oid]);
      }

      // Rosetta Health -> organizations api removed
      // mock data not turned on
      if (!useMockData()) {
        service.organizations[oid] = {};
        service.organizations[oid].name = label || oid;
        service.organizations[oid].organizationOid = oid;// used in hie-search.controller.js

        return $q.resolve(service.organizations[oid]);
      }

      // test using mock data
      url = 'assets/hie-mock/organization.' + oid + '.json';

      return $http.get(url, { params: { imatTransactionId: vhrPersistence.getData('imatTransactionId') }, responseType: 'json' })
        .then(function (response) {
          if (response.data && response.data.org && response.data.org.length && response.data.org[0] !== null) {
            service.organizations[oid] = response.data.org[0];
            if (label) {
              service.organizations[oid].name = label;
            }
            return service.organizations[oid];
          }
          // Essentially a 404?
          response.status = 404;
          response.statusText = 'Not Found';
          return $q.reject(response);
        });
    }

    function getPurpose () {
      var purposeOfUse = vhrPersistence.getData('purposeOfUse');
      if (purposeOfUse) {
        return $q.resolve(purposeOfUse);
      }

      var mpid = vhrPatientSrv.getMpid();
      return psPhiAccess.getConsentRecord(mpid)
        .then(function (result) {
          setPurpose(result.purpose_of_use);
          return result.purpose_of_use;
        })
        .catch(function () {
          $log.debug('Unable to get purpose of use');
          return $q.reject('Unable to get the purpose of use.');
        });
    }

    function lookup () {
      getPatient({})
        .then(function (results) {
          var resultCount, exclude, matches, keys;
          exclude = vhrConfigSrv.getHieAutoLookupExclude();
          service.hieResults = results;
          resultCount = results.length;

          // if hieAutoLookupExclude has String values ex: [{"someProperty":"someValue", "primaryAssignerOid": "1.2.300...500.1"}]
          // loop through the results and check against excludes
          // use case: bronx doesn't want state "search all" included in count
          if (exclude.length > 0) {
            resultCount = results.filter(function (result) {
              return !exclude.some(function (excludeObj) {
                matches = 0;
                keys = Object.keys(excludeObj);
                keys.forEach(function (key) {
                  if (result[key] && result[key].toLowerCase() === excludeObj[key].toLowerCase()) {
                    matches++;
                  }
                });
                if (matches === keys.length) {
                // current result matched exclusion criteria, don't include in final filtered results
                  return true;
                }
                return false;
              });
            }).length;
          }
          service.hieResultsCount = resultCount;
          $log.debug('HIE automatic lookup completed. Found ' + resultCount + ' results.');
        })
        .catch(function () {
          $log.debug('Unable to lookup patient');
        // intentionally returning nothing
        });
    }

    function resetHieResultsCount () {
      service.hieResultsCount = 0;
    }

    //= ================================
    // Private interface
    //= ================================

    function getTargetUrl (targetOid) {
      return targetUrlFormat.replace('{{targetOid}}', targetOid);
    }
  }
})();
