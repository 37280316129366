(function () {
  'use strict';

  angular.module('vhr.services', [
    'imat.filters', // imatLabelize
    'imat.services', // dateTimeSrv, HashSrv, Preferences, psPhiAccess, userSession
    'ngMaterial', // $mdDialog
    'ui.grid', // uiGridConstants
    'vhr.constants',
    'vhr.filters'
  ]);
})();
