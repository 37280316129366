(function () {
  'use strict';

  angular.module('reports')
    .controller('ReportsEditorCtrl', ReportsEditorCtrl);

  ReportsEditorCtrl.$inject = [
    'STORES',
    '$mdDialog', '$scope', '$state', '$timeout', '$transition$', '$transitions',
    'psNotification', 'ReportsSrv', 'searchSrv'
  ];

  function ReportsEditorCtrl (
    STORES,
    $mdDialog, $scope, $state, $timeout, $transition$, $transitions,
    psNotification, ReportsSrv, searchSrv
  ) {
    var vm = this;
    var ctx = 'app.reports';// this.constructor.name;
    var deregisterOnExit = $transitions.onExit({ exiting: 'app.reports.editor' }, confirmExit);

    vm.editor; // eslint-disable-line no-unused-expressions
    vm.viewer; // eslint-disable-line no-unused-expressions
    vm.editorConfig = {
      mode: 'python',
      lineWrapping: true,
      lineNumbers: true,
      indentUnit: 4,
      inputStyle: 'contenteditable',
      theme: 'eclipse',
      extraKeys: {
        'Ctrl-S': function () {
          saveReport();
        },
        'Cmd-S': function () {
          saveReport();
        },
        'Shift-Ctrl-S': function () {
          saveReport(null, true);
        },
        'Shift-Cmd-S': function () {
          saveReport(null, true);
        }
      },
      highlightSelectionMatches: {
        showToken: true,
        annotateScrollbar: true
      }
    };
    vm.viewerConfig = {
      mode: { name: 'python', version: 3 },
      readOnly: true,
      lineWrapping: true,
      lineNumbers: true,
      indentUnit: 4,
      theme: 'eclipse',
      highlightSelectionMatches: {
        showToken: true,
        annotateScrollbar: true
      }
    };

    vm.loading = {
      output: false,
      paramters: false,
      report: false
    };
    vm.modified = false;
    vm.openArtifact = {};
    vm.output = '';
    vm.outputKeys = [];
    vm.original = {};
    vm.parseAsList = false;
    vm.report = {}; // {id, name, desc, content}
    vm.resultId = null;
    vm.selectedSecondaryTab = 0;
    vm.viewerOn = true;

    // Methods
    vm.viewerLoaded = viewerLoaded;
    vm.codemirrorLoaded = codemirrorLoaded;
    vm.loadArtifact = loadArtifact;
    vm.loadReport = loadReport;
    vm.newReport = newReport;
    vm.openReport = openReport;
    vm.renameReport = renameReport;
    vm.runReport = runReport;
    vm.saveReport = saveReport;
    vm.searchReport = searchReport;
    vm.shareReport = shareReport;

    // Implementation
    activate();

    function activate () {
      var id = $transition$.params().report_id;

      if (!id && ReportsSrv.getSelectedId(ctx)) {
        return $state.transitionTo($state.$current, { report_id: ReportsSrv.getSelectedId(ctx) }, { reload: true, inherit: false });
      }

      loadReport(id);

      $scope.$watch(function () { return vm.report.content; }, function (curr) {
        vm.modified = ((curr && vm.report.content !== vm.original.content) || (!curr && vm.original.content));
      });
    }

    function confirmExit () {
      var confirm = $mdDialog.confirm()
        .title('Unsaved Changes')
        .textContent('You have unsaved changes. Are you sure you want to leave?')
        .ariaLabel('Unsaved Changes')
        .ok('Leave')
        .cancel('Cancel');

      if (vm.modified) {
        return $mdDialog.show(confirm)
          .then(function () {
            deregisterOnExit();
            return true;
          })
          .catch(function () {
            return false;
          });
      }
    }

    // Makes codemirror object events available to tie further logic to.
    function codemirrorLoaded (editor) {
      editor.getDoc().markClean();

      // Events
      editor.on('focus', function () {
        $scope.$applyAsync(function () { vm.editorFocused = true; });
      });

      editor.on('blur', function () {
        $scope.$applyAsync(function () { vm.editorFocused = false; });
      });

      vm.editor = editor;
    }

    function viewerLoaded (editor) {
      editor.getDoc().markClean();

      // Events
      editor.on('focus', function () {
        $scope.$applyAsync(function () { vm.viewerFocused = true; });
      });

      editor.on('blur', function () {
        $scope.$applyAsync(function () { vm.viewerFocused = false; });
      });

      vm.viewer = editor;
    }

    function loadReport (reportId) {
      vm.modified = false;
      vm.original = {};

      if (!reportId) {
        vm.report = _getUnsavedReportTemplate();// placeholder for service
        return;
      }
      return ReportsSrv.getReport(reportId)
        .then(function (res) {
          vm.report = {
            id: res.id,
            name: res.name,
            desc: res.description,
            content: res.query_string
          };
          vm.original = angular.copy(vm.report);
        })
        .catch(function () {
          psNotification.error('Unable to load report.');
        });
    }

    function newReport () {
      ReportsSrv.clearSelected(ctx);
      $state.transitionTo($state.$current, { report_id: null }, { reload: true, inherit: false });
    }

    function loadArtifact (path) {
      vm.openArtifact = {};
      vm.selectedSecondaryTab = 2;
      vm.viewer.getDoc().setValue('');

      searchSrv.getResultOutput(vm.resultId, path)
        .then(function (artifactFile) {
          var doc = {
            name: path,
            type: (/\.[a-z]+$/i.test(path) ? path.substring(path.lastIndexOf('.') + 1) : ''),
            content: (angular.isObject(artifactFile) ? angular.toJson(artifactFile, 4) : artifactFile)
          };
          var viewerMode = adjustViewerMode(doc.type);
          var viewerOn = !/other/i.test(viewerMode.name);

          if (['csv', 'log'].indexOf(doc.type) >= 0) {
            doc.content = '<pre>' + doc.content.replace(/</g, '&lt;').replace(/>/g, '&gt;') + '</pre>';
          }

          if (viewerOn) {
            $timeout(function () {
              if (vm.viewer.getOption('mode').name !== viewerMode.name) {
                vm.viewer.setOption('mode', viewerMode);
              }
              vm.viewer.getDoc().setValue(doc.content);
            });
          }

          vm.openArtifact = doc;
          vm.viewerConfig.mode = viewerMode;
          vm.viewerOn = viewerOn;
        })
        .catch(function () {
          vm.selectedSecondaryTab = 1;
        });
    }

    function adjustViewerMode (fileType) {
      var mode;

      switch (fileType) {
        case 'py3': // No break.
        case 'py':
          mode = { name: 'python', version: 3 };
          break;
        case 'json':
          mode = { name: 'javascript', json: true };
          break;
        case 'html':
          mode = { name: 'xml', htmlMode: true, matchClosing: false, alignCDATA: false };
          break;
        case 'log': // No break.
        case 'pdf': // No break.
        default:
          mode = { name: 'other' };
          break;
      }

      return mode;
    }

    function openReport (ev) {
      $mdDialog.show({
        controller: 'OpenReportCtrl',
        templateUrl: '/ui/reports/editor/open-report-modal.html',
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: true
      })
        .then(function (report) {
          ReportsSrv.setSelected(ctx, report.id);
          $state.transitionTo($state.$current, { report_id: report.id }, { reload: true, inherit: false });
        });
    }

    function renameReport (ev) {
      $mdDialog.show({
        controller: 'SaveReportCtrl',
        templateUrl: '/ui/reports/editor/add-report-modal.html',
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: true,
        locals: { report: angular.copy(vm.original), config: { ok: 'Rename Report', title: 'Rename Report ' + vm.original.id } }
      })
        .then(function (res) {
          var params = {
            id: res.id,
            name: res.name,
            description: res.desc,
            query_string: res.content,
            store: STORES.ALL
          };

          ReportsSrv.saveReport(params)
            .then(function () {
            // Nothing useful in the response.
              psNotification.success('Report renamed.');
              vm.modified = false;
              // Expensive reload and simple changes means
              // manually sync'ing all the things...
              vm.original.name = vm.report.name = params.name;
              vm.original.desc = vm.report.desc = params.description;
              _updateListManuallyForSanity(params);
            })
            .catch(function () {
              psNotification.error('Error renaming report.');
            });
        });
    }

    function runReport (ev) {
      $mdDialog.show({
        controller: 'RunReportCtrl',
        templateUrl: '/ui/reports/editor/run-report-modal.html',
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: true,
        locals: { report: vm.report, config: {} }
      })
        .then(function (res) {
          vm.output = res.content;
          vm.artifacts = res.artifacts;
          vm.summary = res.summary;
          vm.resultId = res.resultId;
        });

      // Run search -> /search -> result id
      // Poll result id until complete status -> /search/results/{{result_id}}/status
      // Get output -> /search/results/{{result_id}}/output/stdout.log
    }

    function saveReport (ev, saveAs) {
      var params;

      if (!vm.report.id || saveAs) {
        $mdDialog.show({
          controller: 'SaveReportCtrl',
          templateUrl: '/ui/reports/editor/add-report-modal.html',
          parent: angular.element(document.body),
          targetEvent: ev,
          clickOutsideToClose: true,
          locals: { report: angular.copy(vm.report), config: { ok: 'Save', title: 'Save Report As' } }
        })
          .then(function (report) {
            ReportsSrv.addReport(report)
              .then(function (reportId) {
                psNotification.success('Report saved.');
                vm.modified = false;
                ReportsSrv.getList(null, true);
                $state.transitionTo($state.$current, { report_id: reportId }, { reload: true, inherit: false });
              })
              .catch(function () {
                psNotification.error('Error creating report.');
              });
          });
      } else {
        params = {
          id: vm.report.id,
          query_string: vm.report.content,
          store: STORES.ALL
        };

        ReportsSrv.saveReport(params)
          .then(function () {
            // Nothing useful in the response.
            psNotification.success('Report saved.');
            vm.modified = false;
            // Expensive reload and simple changes means
            // manually sync'ing all the things...
            vm.original.content = vm.report.content;
            _updateListManuallyForSanity(params);
          })
          .catch(function () {
            psNotification.error('Error saving report.');
          });
      }
    }

    function searchReport () {
      // open codemirror search feature
      vm.editor.execCommand('findPersistent');
    }

    // placeholder for service
    function _getUnsavedReportTemplate () {
      return {
        id: null,
        name: 'Unsaved Report',
        desc: '',
        content: ''
      };
    }

    function shareReport () {
      // placeholder until sharing is built out
    }

    function _updateListManuallyForSanity (rec) {
      var cached;

      if (ReportsSrv.isCached()) {
        cached = ReportsSrv.getSelected(ctx);
        if (rec.name) { cached.name = rec.name; }
        if (rec.description) { cached.description = rec.description; }
        if (rec.query_string) { cached.query_string = rec.query_string; }
        ReportsSrv.ejectCachedItem(rec.id, false);
      } else {
        ReportsSrv.setSelected(ctx, rec.id);
      }
    }
  }
})();
