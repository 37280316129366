(function () {
  'use strict';

  angular.module('queries')
    .controller('QueryResultsInfoModalCtrl', QueryResultsInfoModalCtrl);

  QueryResultsInfoModalCtrl.$inject = ['$mdDialog', 'info'];

  function QueryResultsInfoModalCtrl ($mdDialog, info) {
    var vm = this;

    vm.info = angular.copy(info);
    vm.closeDialog = closeDialog;

    function closeDialog () {
      $mdDialog.cancel();
    }
  }
})();
