(function () {
  'use strict';

  angular
    .module('imat.filters')
    .filter('statusLabel', function () {
      const labels = {
        CREATED: 'Created',
        OUTDATED: 'Outdated',
        PENDING: 'Not created',
        REQUESTED: 'Requested',
        FAILED: 'Failed'
      };

      return function (key) {
        return labels[key];
      };
    });
})();
