(function () {
  'use strict';

  angular.module('reports')
    .controller('SaveReportCtrl', SaveReportCtrl);

  SaveReportCtrl.$inject = ['$mdDialog', '$scope', 'report', 'config'];

  function SaveReportCtrl ($mdDialog, $scope, report, config) {
    $scope.report = report;
    $scope.invalid = true;

    activate();

    function activate () {
      Object.keys(config).forEach(function (key) {
        $scope[key] = config[key];
      });
    }

    $scope.$watch('report', function (curr) {
      if (curr.name !== 'Unsaved Report' && curr.name !== '') {
        $scope.invalid = false;
      }
    }, true);

    $scope.cancel = function () {
      $mdDialog.cancel();
    };

    $scope.createReport = function () {
      $mdDialog.hide($scope.report);
    };
  }
})();
