(function () {
  'use strict';

  angular.module('imat')
    .config(config);

  config.$inject = [
    '$httpProvider', '$locationProvider', '$mdDateLocaleProvider', '$qProvider', 'httpReqFormUrlEncodedProvider'
  ];

  function config (
    $httpProvider, $locationProvider, $mdDateLocaleProvider, $qProvider, httpReqFormUrlEncodedProvider
  ) {
    if (!$httpProvider.defaults.headers.get) {
      $httpProvider.defaults.headers.common = {};
    }
    $httpProvider.defaults.headers.common.Pragma = 'no-cache';
    $httpProvider.defaults.headers.common['Cache-Control'] = 'no-cache';
    $httpProvider.defaults.transformRequest = [httpReqFormUrlEncodedProvider.$get().serialize].concat($httpProvider.defaults.transformRequest);

    $locationProvider.hashPrefix('');
    $qProvider.errorOnUnhandledRejections(false);

    $mdDateLocaleProvider.parseDate = function (dateString) {
      // replace -, ., or space with / and convert to date
      var m = moment(dateString.replace(/[-\.\s]/g, '/'), ['M/D/YYYY', 'MM/DD/YYYY'], true); // eslint-disable-line no-useless-escape
      return m.isValid() ? m.toDate() : new Date(NaN);
    };
  }
})();
