(function () {
  'use strict';

  angular
    .module('mpiApp.constants', [])
    .constant('MPI_CLIENT_NAME', 'IMAT-MPI-UI')// Middleware audit logs.
    .constant('MPI_FHIR', {
      SYS: {
        HL7_IDENTIFIER: 'http://hl7.org/fhir/v2/0203',
        HL7_US_SSN: 'http://hl7.org/fhir/sid/us-ssn',
        IMAT_MPI: 'http://imat.us/fhir/codes/mpi'
        // IMAT_MPI_INSTALL: 'https://test.imat.io/mpi/0/',// See mpiConfigSrv.systemId.
      },
      EXT: {
        // HL7_MOTHERS_MAIDEN_NAME: 'http://hl7.org/fhir/StructureDefinition/patient-mothersMaidenName',
        HL7_RENDERED_VALUE: 'http://hl7.org/fhir/StructureDefinition/rendered-value',
        IMAT_MPI_MATCH: 'http://imat.us/fhir/extensions/mpi',
        IMAT_FICTITIOUS: 'http://imat.us/fhir/extensions/fictitious'
      },
      CODE: {
        MPID: 'MPID',
        MRN: 'MR',
        PI: 'PI',
        SS: 'SS'
      }
    })
  ;
})();
