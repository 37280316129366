(function () {
  'use strict';

  angular.module('imat.shared.test')
    .factory('imatConfigFix', imatConfigFix);

  imatConfigFix.$inject = ['$q'];

  function imatConfigFix ($q) {
    var service;

    service = {
      getArbitraryConfig: getArbitraryConfig,
      getDefaultConfig: getDefaultConfig
    };

    function getArbitraryConfig (promise) {
      var fix = {
        customerHelpLink: '/ui/documentation/#/guides/vhr'
      };
      return (promise ? $q.resolve(fix) : fix);
    }

    function getDefaultConfig (promise) {
      var fix = {
        customerHelpLink: '/ui/documentation/',
        subscriptions: {
          alertReportIds: [],
          enabled: true,
          groups: [],
          includeFmrns: false,
          contactMeans: {
            phone: false,
            email: true
          },
          testable: true
        },
        apps: {
          dashboard: {
            title: 'Dashboard',
            customerHelpLink: '/ui/documentation/#/guides/dashboard'
          }
        }
      };
      return (promise ? $q.resolve(fix) : fix);
    }

    return service;
  }
})();
