(function () {
  'use strict';

  angular
    .module('imat.services')
    .factory('accountApiSrv', accountApiSrv);

  accountApiSrv.$inject = ['APP_TIMEOUT', '$http'];

  function accountApiSrv (APP_TIMEOUT, $http) {
    var baseUrl = '/account';

    var service = {
      getSessionExpiration: getSessionExpiration,
      getLoginSettings: getLoginSettings,
      getSecurityQuestions: getSecurityQuestions,
      getSession: getSession,
      logout: logout,
      postSecurityQuestions: postSecurityQuestions
    };

    return service;

    function getSessionExpiration () {
      return $http.get('/appliance/session-expiration');
    }

    function getLoginSettings () {
      return $http.get([baseUrl, 'login-settings'].join('/'));
    }

    function getSecurityQuestions () {
      return $http.get([baseUrl, 'metadata', 'auth', 'security_questions'].join('/'));
    }

    function getSession () {
      return $http.get(APP_TIMEOUT.SESSION_URL, { cache: false });
    }

    function logout () {
      return $http.get('/api/account/logout');
    }

    function postSecurityQuestions (questions) {
      return $http.post([baseUrl, 'metadata', 'auth', 'security_questions'].join('/'), questions);
    }
  }
})();
