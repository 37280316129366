(function () {
  'use strict';

  angular.module('imat.fhir')
    .factory('fhirExtensionSrv', fhirExtensionSrv);

  fhirExtensionSrv.$inject = ['FHIR_DATATYPES', 'FHIR_EXTENSION_VALUES', 'FHIR_EXTENSION_SCHEMAS', 'FHIR_VALUE_SETS'];

  function fhirExtensionSrv (FHIR_DATATYPES, FHIR_EXTENSION_VALUES, FHIR_EXTENSION_SCHEMAS, FHIR_VALUE_SETS) {
    var service;
    var extKeys = [];
    var extUrls = [];
    var rexIsUrl = /^https?:\/\//i;
    var rexIsValue = /^value/;

    service = {
      getBinding: getBinding,
      getBindingType: getBindingType,
      getBindingValues: getBindingValues,
      getDefinition: getDefinition,
      getDefinitions: getDefinitions,
      getEmptyDefinition: getEmptyDefinition,
      getInstanceValueProperty: getInstanceValueProperty,
      getValueType: getValueType
    };

    activate();
    return service;

    function activate () {
      angular.forEach(FHIR_EXTENSION_SCHEMAS, function (def, key) {
        extKeys.push(key);
        extUrls.push(def.url);
      });
    }

    function getBinding (def, url) {
      if (!angular.isArray(def.binding) || !url) {
        return def.binding || null;
      }

      for (var i = 0, ii = def.binding.length; i < ii; ++i) {
        if (url === def.binding[i].url) {
          return angular.copy(def.binding[i]);
        }
      }
      return null;
    }

    function getBindingType (def, url) {
      var binding = getBinding(def, url);
      if (binding == null) { return ''; }
      return (Object.prototype.hasOwnProperty.call(FHIR_EXTENSION_VALUES, binding.type) ? FHIR_EXTENSION_VALUES[binding.type] : '');
    }

    function getBindingValues (def, url) {
      var binding = getBinding(def, url);
      if (binding == null) { return []; }
      if (angular.isString(binding) &&
          [
            FHIR_DATATYPES.code,
            FHIR_DATATYPES.CodeableConcept,
            FHIR_DATATYPES.Coding
          ].indexOf(def.type) >= 0
      ) {
        binding = { valueSet: binding };
      }
      return (Object.prototype.hasOwnProperty.call(FHIR_VALUE_SETS, binding.valueSet) ? FHIR_VALUE_SETS[binding.valueSet] : []);
    }

    function getDefinition (key) {
      if (rexIsUrl.test(key)) { key = extKeys[extUrls.indexOf(key)]; }
      return (Object.prototype.hasOwnProperty.call(FHIR_EXTENSION_SCHEMAS, key) ? angular.copy(FHIR_EXTENSION_SCHEMAS[key]) : null);
    }

    function getDefinitions (context) {
      var defs = extKeys.map(function (key) { return FHIR_EXTENSION_SCHEMAS[key]; });

      if (context) {
        defs = defs.filter(function (def) {
          return (def.context && def.context.indexOf(context) >= 0);
        });
      }
      return defs;
    }

    function getEmptyDefinition (unsupported) {
      var def = {
        url: '',
        type: 'string',
        name: 'Unknown'
      };

      if (unsupported) {
        def.type = '';
        def.name = 'Unsupported: unknown';
        def.unsupported = true;
      }
      return def;
    }

    function getInstanceValueProperty (ext) {
      var keys = Object.keys(ext || {}).filter(function (key) { return rexIsValue.test(key); });

      return (keys.length === 1 ? keys[0] : '');
    }

    function getValueType (dataType) {
      return (Object.prototype.hasOwnProperty.call(FHIR_EXTENSION_VALUES, dataType) ? FHIR_EXTENSION_VALUES[dataType] : '');
      // return 'value' + dataType.replace(/^\w/, function(c) { return c.toUpperCase(); });
    }
  }
})();
