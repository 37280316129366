(function () {
  'use strict';

  angular.module('imat.directives')
    .directive('imatSizer', imatSizer);

  imatSizer.$inject = ['$window'];

  function imatSizer ($window) {
    return {
      controller: DomCtrl,
      priority: 99,
      restrict: 'A',
      scope: false,
      template: null
    };

    function DomCtrl ($scope, $element, $attrs) {
      var $target = null;
      var oldTop;

      var cb = function () { setHeight($target); };

      // Called on each controller after all the controllers on an element have been constructed and had their bindings
      // initialized (and before the pre & post linking functions for the directives on this element). This is a good
      // place to put initialization code for your controller.
      this.$onInit = function () {
        $element.addClass('imat-sized');

        if ($attrs.imatSizer) {
          $target = angular.element($element.find($attrs.imatSizer).eq(0));

          if (!$target.length) {
            $target = null;
          }
        }
        $target = $target || $element;
      };
      // Called whenever one-way (<) or interpolation (@) bindings are updated. The changesObj is a hash whose keys are
      // the names of the bound properties that have changed, and the values are an object of the form { currentValue,
      // previousValue, isFirstChange() }. Use this hook to trigger updates within a component such as cloning the bound
      // value to prevent accidental mutation of the outer value. Note that this will also be called when your bindings
      // are initialized.
      // this.$onChanges = function() {};
      // Called on each turn of the digest cycle. Provides an opportunity to detect and act on changes. Any actions that
      // you wish to take in response to the changes that you detect must be invoked from this hook; implementing this
      // has no effect on when $onChanges is called. For example, this hook could be useful if you wish to perform a
      // deep equality check, or to check a Date object, changes to which would not be detected by AngularJS's change
      // detector and thus not trigger $onChanges. This hook is invoked with no arguments; if detecting changes, you
      // must store the previous value(s) for comparison to the current values.
      this.$doCheck = function () {
        var newTop = Math.round($target.get(0).getBoundingClientRect().top);

        if (oldTop == null || oldTop !== newTop) {
          oldTop = newTop;
          $scope.$applyAsync(function () { setHeight($target); });
        }
      };
      // Called on a controller when its containing scope is destroyed. Use this hook for releasing external resources,
      // watches and event handlers. Note that components have their $onDestroy() hooks called in the same order as the
      // $scope.$broadcast events are triggered, which is top down. This means that parent components will have their
      // $onDestroy() hook called before child components.
      this.$onDestroy = function () {
        angular.element($window).off('resize', cb);
      };
      // Called after this controller's element and its children have been linked. Similar to the post-link function
      // this hook can be used to set up DOM event handlers and do direct DOM manipulation. Note that child elements
      // that contain templateUrl directives will not have been compiled and linked since they are waiting for their
      // template to load asynchronously and their own compilation and linking has been suspended until that occurs.
      this.$postLink = function () {
        angular.element($window).on('resize', cb);
      };
    }

    function setHeight ($element) {
      var height;
      var offset = 1;// 1px slop.
      var rectBottom;
      var rectTop;
      var $target = $element;

      try {
        // TODO Account for siblings?

        do {
          // Start at the list and traverse up to
          // the offsetParent, summing dimensions...
          offset += getBorders($target);
          offset += getMargins($target);
          offset += getPadding($target);
          $target = $target.parent();
        } while ($target.length && $target.get(0) !== $element.get(0).offsetParent);

        if ($target.length) {
          // The offsetParent is a little different since we care
          // only about what's between us and the bottom of the box.
          offset += parseInt($target.css('border-bottom-width'), 10) || 0;
          // offset += parseInt(target.css('margin-bottom'), 10) || 0;
          offset += parseInt($target.css('padding-bottom'), 10) || 0;

          rectBottom = $target.get(0).getBoundingClientRect().bottom;
          rectTop = $element.get(0).getBoundingClientRect().top;
          height = Math.round(rectBottom - rectTop - offset);

          if (height > 0) {
            $element.css('height', height + 'px');
          }
        }
      } catch (e) {
        // Ignored.
      }
    }

    function getBorders ($element) {
      return (parseInt($element.css('border-top-width'), 10) || 0) + (parseInt($element.css('border-bottom-width'), 10) || 0);
    }

    function getMargins ($element) {
      return (parseInt($element.css('margin-top'), 10) || 0) + (parseInt($element.css('margin-bottom'), 10) || 0);
    }

    function getPadding ($element) {
      return (parseInt($element.css('padding-top'), 10) || 0) + (parseInt($element.css('padding-bottom'), 10) || 0);
    }
  }
})();
