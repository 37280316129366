(function () {
  'use strict';

  angular.module('imat.services')
    .factory('imatApp', imatApp);

  imatApp.$inject = ['$http', '$q', 'imatConfig', 'userSession'];

  function imatApp ($http, $q, imatConfig, userSession) {
    var loader;
    var service = {
      load: load
    };

    return service;

    function load () {
      var tasks;

      if (!loader) {
        loader = $q.defer();

        imatConfig.loadConfigFile('app.config.json')
          .then(function (config) {
            if (config.proxies && config.proxies.use) {
              const proxy = config.proxies[config.proxies.use];
              if (proxy.token && proxy.username) {
                io.socket.headers = {
                  authtoken: proxy.token,
                  authuser: proxy.username
                };
                $http.defaults.headers.common.authtoken = proxy.token;
                $http.defaults.headers.common.authuser = proxy.username;
              }
            }
            tasks = [
              userSession.load(),
              imatConfig.load(true)
            ];
            $q.all(tasks)
              .then(function () {
                loader.resolve(true);
              })
              .catch(function (err) {
                loader.reject(err);
              });
          });
      }

      return loader.promise;
    }
  }
})();
