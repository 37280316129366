(function () {
  'use strict';

  angular.module('queries')
    .controller('QueryInfoModalCtrl', QueryInfoModalCtrl);

  QueryInfoModalCtrl.$inject = ['$mdDialog', 'psNotification', 'QueriesSrv', 'queryId'];

  function QueryInfoModalCtrl ($mdDialog, psNotification, QueriesSrv, queryId) {
    var vm = this;

    vm.query = {};
    vm.closeDialog = closeDialog;
    vm.loading = {
      details: false
    };

    activate();

    function activate () {
      vm.loading.details = true;
      QueriesSrv.getSavedQuery(queryId)
        .then(function (queryData) {
          vm.query = queryData;
        })
        .catch(function () {
          psNotification.error('Unable to load the query details.');
        })
        .finally(function () {
          vm.loading.details = false;
        });
    }

    function closeDialog () {
      $mdDialog.cancel();
    }
  }
})();
