(function () {
  'use strict';

  angular.module('imat.services')
    .factory('GroupsSrv', GroupsSrv);

  GroupsSrv.$inject = ['$q', 'ProvidersSrv', 'groupsApiSrv', 'searchApiSrv'];

  function GroupsSrv ($q, ProvidersSrv, groupsApiSrv, searchApiSrv) {
    // Variables
    var groups = [];

    // Interface
    var service = {
      addMembers: addMembers,
      createGroup: createGroup,
      deleteGroup: deleteGroup,
      getGroup: getGroup,
      getList: getGroupList,
      getUsers: getUsers,
      updateGroup: updateGroup,
      updateUsers: updateUsers,
      removeMembers: removeMembers
    };

    // Methods

    function addMembers (group, members) {
      return updateMembers(group, members);
    }

    function createGroup (group) {
      return groupsApiSrv.createGroup(group)
        .then(function (resp) {
          // TODO Wait for this to return?
          searchApiSrv.ensureSession('group_' + group.name + '@' + group.provider);
          return resp;
        });
    }

    function deleteGroup (group) {
      if (!group.provider || !group.name) {
        return $q.reject();
      }
      return groupsApiSrv.deleteGroup(group.provider, group.name);
    }

    function getGroup (group) {
      if (!group.provider || !group.name) {
        return $q.reject();
      }
      return groupsApiSrv.getGroup(group.provider, group.name);
    }

    function getGroupList (force, config) {
      if (!force && groups.length) {
        return $q.resolve(groups);
      }
      return ProvidersSrv.getProviderList()
        .then(function (providers) {
          var promises = [];

          providers.forEach(function (provider) {
            promises.push(
              groupsApiSrv.getList(provider, config).then(parseGroupListResponse)
            );
          });

          return $q.all(promises)
            .then(function (all) {
              groups = [];
              all.forEach(function (groupList) {
                groups = groups.concat(groupList);
              });
              return groups;
            });
        });
    }

    function getUsers (group) {
      if (!group.provider || !group.name) {
        return $q.reject();
      }
      return groupsApiSrv.getUsers(group.provider, group.name);
    }

    function updateGroup (group) {
      if (!group.provider || !group.name || !group.modifiable) {
        return $q.reject();
      }
      return groupsApiSrv.updateGroup(group.description, group.provider, group.name);
    }

    function updateMembers (group, addedMembers, removedMembers) {
      var cmds = [];

      if (angular.isArray(addedMembers)) {
        addedMembers.forEach(function (member) {
          if (Object.prototype.hasOwnProperty.call(member, 'type')) {
            cmds.push('add' + member.type + '=' + encodeURIComponent(member.name));
          } else if (Object.prototype.hasOwnProperty.call(member, 'username')) {
            cmds.push('adduser=' + encodeURIComponent(member.username));
          } else {
            cmds.push('addgroup=' + encodeURIComponent(member.name));
          }
        });
      }
      if (angular.isArray(removedMembers)) {
        removedMembers.forEach(function (member) {
          if (Object.prototype.hasOwnProperty.call(member, 'type')) {
            cmds.push('delete' + member.type + '=' + encodeURIComponent(member.name));
          } else if (Object.prototype.hasOwnProperty.call(member, 'username')) {
            cmds.push('deleteuser=' + encodeURIComponent(member.username));
          } else {
            cmds.push('deletegroup=' + encodeURIComponent(member.name));
          }
        });
      }
      groupsApiSrv.updateMembers(group, cmds)
        .then(function (response) {
          return response.data;
        });
    }

    function updateUsers (addedMembers, removedMembers, group) {
      var cmds = [];
      if (!group.provider || !group.name) {
        return $q.reject();
      }
      if (angular.isArray(addedMembers)) {
        addedMembers.forEach(function (member) {
          cmds.push('adduser=' + encodeURIComponent(member));
        });
      }
      if (angular.isArray(removedMembers)) {
        removedMembers.forEach(function (member) {
          cmds.push('deleteuser=' + encodeURIComponent(member));
        });
      }
      return groupsApiSrv.updateUsers(group, cmds);
    }

    function parseGroupListResponse (groupRes) {
      var groupList = [];
      if (groupRes.data && groupRes.data.groups && groupRes.data.groups.group) {
        groupList = angular.isArray(groupRes.data.groups.group) ? groupRes.data.groups.group : [groupRes.data.groups.group];
      }
      return groupList;
    }

    function removeMembers (group, members) {
      return updateMembers(group, null, members);
    }

    return service;
  }
})();
