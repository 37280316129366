(function () {
  'use strict';

  angular.module('imat.services')
    .factory('ReportsSrv', ReportsSrv);

  ReportsSrv.$inject = ['$q', 'CachingClass', 'queriesApiSrv', 'searchApiSrv'];

  function ReportsSrv ($q, CachingClass, queriesApiSrv, searchApiSrv) {
    var TYPE_PSLUCENE = 'pslucene';
    var TYPE_PYTHON = 'python';
    var service = {
      refreshing: false,

      lastSearch: '', // Last filter applied to the list.
      addReport: addReport,
      deleteReport: deleteReport,
      getList: getList,
      getReport: getReport,
      getSelectedItem: getSelectedItem,
      saveReport: saveReport
    };

    service = angular.extend((new CachingClass()), service);

    return service;

    //= ================================
    // Public interface
    //= ================================

    function addReport (data) {
      // Required items in data: name, desc, content
      // Optional items in data: type, stores, additionalCgi

      // example params obj: {savequery: 'TestReport', desc: 'Test Report', c: '()UNIVERSE;'}
      var params = angular.extend({
        savequery: data.name,
        desc: data.desc,
        space: data.stores || undefined
      }, data.additionalCgi || {});

      if (data.type == null || data.type === TYPE_PYTHON) {
        params.c = data.content;
      } else if (data.type === TYPE_PSLUCENE) {
        params.q = data.content;
      } else throw new Error('Invalid type');

      return searchApiSrv.post(params, {}, true)
        .then(function (res) {
          // TODO Reload caches?
          return res.data.query_id;
        });
    }

    function deleteReport (id, admin) {
      return queriesApiSrv.deleteReport(id, admin || admin == null)
        .then(function () {
          service.ejectCachedItem(id, true);
        });
    }

    function getList (params, force) {
      if (!force && service.isCached()) {
        return $q.resolve(service.getCached());
      }

      service.refreshing = true;
      return queriesApiSrv.getList(params)
        .then(function (res) {
          var queries = res.data;
          queries.forEach(function (query) {
            query.link = (
              '/extensions/clinical-reports/#navreportspanel' +
              (query.type === TYPE_PSLUCENE ? '?runsinglesavedreport' : '?runcompoundsavedreport') +
              '(id=' + query.id + ')'
            );
          });

          return service.cache(queries);
        })
        .finally(function () {
          service.refreshing = false;
        });
    }

    function getReport (id, params, force) {
      if (!force && service.isCachedItem(id)) {
        return $q.resolve(service.getCachedItem(id));
      }
      return queriesApiSrv.getReport(id, params)
        .then(function (res) {
          return service.cacheItem(res.data.query);
        });
    }

    function getSelectedItem (ctx, params, force) {
      return CachingClass.prototype.getSelectedItem.call(service, ctx, force)
        .catch(function () {
          var id = service.getSelectedId(ctx);
          if (id) { return getReport(id, params, force); }
          return $q.reject();
        });
    }

    function saveReport (data, admin) {
      return queriesApiSrv.saveReport(data, data.id, admin || admin == null)
        .then(function (res) {
          service.ejectCachedItem(res.data.response.id, false);
          return res.data;
        });
    }

    //= ================================
    // Private interface
    //= ================================
  }
})();
