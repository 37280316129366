(function () {
  'use strict';

  angular
    .module('admin')
    .controller('UploaderCtrl', UploaderCtrl);

  UploaderCtrl.$inject = ['$mdDialog', '$scope', '$window', 'FileUploader', 'imatConfig', 'psNotification', 'uploaderApi', 'userSession'];

  function UploaderCtrl ($mdDialog, $scope, $window, FileUploader, imatConfig, psNotification, uploaderApi, userSession) {
    var vm = this;
    var uploader;
    var username;
    var userGroups;

    // Properties.
    vm.dataTable = {
      limit: 20,
      currentPage: 1
    };
    vm.defaultUri = 'inbox-upload://%(user)s/%(file_name)s';
    vm.filetypes = 'csv';
    vm.inboxName = 'uploader';
    vm.uploader = new FileUploader({
      url: '/inboxes/' + vm.inboxName,
      uri: vm.defaultUri,
      fileTypes: vm.filetypes
    });

    // Methods.
    vm.cancelAll = cancelAll;
    vm.changeSettings = changeSettings;
    vm.clearQueue = clearQueue;
    vm.uploadAll = uploadAll;
    vm.uploadSubmit = uploadSubmit;

    // Filters.
    uploader = vm.uploader;
    uploader.filters.push({
      name: 'customFilter',
      fn: function () {
        return this.queue.length < 20;
      }
    });

    //= =================
    // Public Interface
    //= =================

    activate();

    function activate () {
      var user = userSession.getUserData();
      username = user.username + '_' + user.provider;
      userGroups = user.groups;

      uploader.onAfterAddingFile = function (item) {
        item.uri = vm.defaultUri;

        // Creates custom uri for file and assigns item._file.type if empty string.
        var replaceUserName = item.uri.replace('%(user)s', username);
        var replaceFileName = replaceUserName.replace('%(file_name)s', item._file.name);
        item.uri = replaceFileName;
      };

      uploader.onBeforeUploadItem = function (item) {
        var mime = 'MIME-Version: 1.0';
        var contentMixed = 'Content-Type: multipart/mixed; boundary=",,,,NextPart_eC7e1bb5_1105_4db3_bae3_2a6208eb099e,,,,"';
        var contentTypeHeader = 'Content-Type: text/plain; charset=utf-8';
        var contentType = 'Content-Type: ' + item.file.type;
        var dashes = '--';
        var boundary = ',,,,NextPart_eC7e1bb5_1105_4db3_bae3_2a6208eb099e,,,,';
        var groups = 'groups: ' + userGroups;
        var uri = 'uri=' + item.uri;
        var userName = 'user: ' + username;
        var newLine = '\r\n';
        var header = mime + newLine + contentMixed + newLine + dashes + boundary + newLine + contentTypeHeader + newLine + newLine;
        var data = header + uri + newLine + userName + newLine + groups + newLine + dashes + boundary + newLine + contentType;
        item.customBody = data;
      };

      uploader.onCompleteItem = function (item, response) {
        if (item.isError || !item.isSuccess || response.indexOf('Added 0') !== -1) {
          psNotification.error('There was a problem uploading the file ' + item.file.name);
        }
      };
    }

    function cancelAll (uploader) { // eslint-disable-line no-shadow
      uploader.cancelAll();
    }

    function changeSettings (item) {
      $mdDialog.show({
        controller: 'UploaderSettingsDialogCtrl',
        controllerAs: 'ctrl',
        locals: { uri: item.uri },
        templateUrl: '/ui/admin/uploader/change-settings-dialog.html',
        parent: angular.element(document.body)
      }).then(function (uri) {
        item.uri = uri;
        item.customUri = true;
      });
    }

    function clearQueue (uploader) { // eslint-disable-line no-shadow
      uploader.clearQueue();
    }

    function uploadAll (uploader) { // eslint-disable-line no-shadow
      uploader.uploadAll();
    }

    function uploadSubmit (item) {
      item.upload();
    }
  }
})();
