(function () {
  'use strict';

  angular.module('admin')
    .controller('storesCtrl', storesCtrl);

  storesCtrl.$inject = ['$interval', '$filter', '$mdDialog', '$mdSidenav', '$scope', '$timeout', 'psNotification', 'imatStoresSrv'];

  function storesCtrl ($interval, $filter, $mdDialog, $mdSidenav, $scope, $timeout, psNotification, imatStoresSrv) {
    var vm = this; // eslint-disable-line no-invalid-this
    var COMMIT_RECORDS_ERROR = 'Unable to commit records.';
    var DELETE_RECORDS_ERROR = 'Unable to delete records.';
    var DELETE_RECORDS_SUCCESS = 'Records deleted from the selected store.';
    var GET_STORES_ERROR = 'Unable to retrieve the stores.';
    var UPDATE_DELAY = 2000;

    var storePolling;

    vm.loadingStores = false;
    vm.stores = [];
    vm.storePollingEnabled = false;
    vm.searchStoreText = '';
    vm.selectedStore = {};
    vm.selectedStoreFilter = '';
    vm.storeFilters = [{
      name: 'Uncommitted',
      key: 'uncommitted_count',
      value: 'Uncommitted'
    }];

    // Methods
    vm.clearSelectedStore = clearSelectedStore;
    vm.commitRecord = commitRecord;
    vm.deleteStoreData = deleteStoreData;
    vm.filterStores = filterStores;
    vm.refreshStoreList = refreshStoreList;
    vm.resetStoreFilters = resetStoreFilters;
    vm.selectStore = selectStore;
    vm.storeTextFilterList = storeTextFilterList;
    vm.toggleStorePolling = toggleStorePolling;

    $scope.$on('$destroy', function () {
      toggleStorePolling(false);
    });

    activate();

    function activate () {
      if (!vm.stores.length) {
        filterStores(true);
      }
    }

    function clearSelectedStore () {
      if (vm.selectedStore.name) {
        delete vm.selectedStore.selected;
        vm.selectedStore = {};
      }
      $mdSidenav('storeDetails').close();
    }

    function commitRecord () {
      imatStoresSrv.commitRecords(vm.selectedStore.number)
        .then(function (response) {
          switch (response.status) {
            case 200:
              vm.loadingStores = true;
              $timeout(function () { filterStores(true); }, UPDATE_DELAY);
              psNotification.success('Pending records committed in ' + vm.selectedStore.name + '.');
              break;

            case 204:
              psNotification.show('No records pending commit in ' + vm.selectedStore.name + '.');
              break;

            default:
              psNotification.error('Unknown response.');
          }
        })
        .catch(function () {
          psNotification.error(COMMIT_RECORDS_ERROR);
        });
    }

    function deleteStoreData () {
      var confirm = $mdDialog.confirm()
        .title('Delete all records from the selected store?')
        .ok('Delete')
        .cancel('Cancel');

      $mdDialog.show(confirm)
        .then(function () {
          imatStoresSrv.deleteStoreData(vm.selectedStore.number)
            .then(function () {
              psNotification.success(DELETE_RECORDS_SUCCESS);
            })
            .catch(function () {
              psNotification.error(DELETE_RECORDS_ERROR);
            })
            .finally(function () {
              $timeout(function () { filterStores(true); }, UPDATE_DELAY);
            });
        });
    }

    function filterStores (force) {
      vm.loadingStores = true;
      var selected = angular.copy(vm.selectedStore);
      _getStoresList(force)
        .then(function (stores) {
          if (vm.selectedStoreFilter && vm.selectedStoreFilter.key === 'uncommitted_count') {
            vm.stores = $filter('filter')(stores, function (value) {
              return parseInt(value[vm.selectedStoreFilter.key], 10) > 0;
            });
          } else {
            vm.stores = $filter('filter')(stores, function (value) {
              return (vm.selectedStoreFilter) ? value[vm.selectedStoreFilter.key] === vm.selectedStoreFilter.value : true;
            });
          }
          // Preserve selected store on refresh
          if (selected.name) {
            for (var i = 0; i < vm.stores.length; i++) {
              if (selected.name === vm.stores[i].name) {
                selectStore(vm.stores[i]);
                break;
              }
            }
          }
        })
        .finally(function () {
          vm.loadingStores = false;
        });
    }

    function refreshStoreList () {
      clearSelectedStore();
      filterStores(true);
    }

    function resetStoreFilters () {
      vm.selectedStoreFilter = '';
      vm.searchStoreText = '';
      filterStores();
    }

    function selectStore (store) {
      if (vm.selectedStore.name) {
        clearSelectedStore();
      }
      vm.selectedStore = store;
      vm.selectedStore.selected = true;
      vm.selectedStore.indexed = formatBytes(vm.selectedStore.bytes_indexed);
      $mdSidenav('storeDetails').open();
    }

    function storeTextFilterList (store) {
      if (!vm.searchStoreText) {
        return true;
      }
      return (store.name.toLowerCase().indexOf(vm.searchStoreText.toLowerCase()) !== -1) ? true : (store.number.toLowerCase().indexOf(vm.searchStoreText) !== -1);
    }

    function toggleStorePolling (enabled) {
      vm.storePollingEnabled = !!enabled;
      if (enabled) {
        filterStores(true);
        storePolling = $interval(function () { filterStores(true); }, 10000);
      } else {
        $interval.cancel(storePolling);
      }
    }

    //= =================
    // Private Interface
    //= =================

    function _getStoresList (force) {
      return imatStoresSrv.getAll(force)
        .then(function (stores) {
          return stores;
        })
        .catch(function () {
          psNotification.error(GET_STORES_ERROR);
        });
    }

    function formatBytes (bytes) {
      switch (true) {
        case bytes / 1099511627776 > 1:
          return (bytes / 1099511627776).toFixed(2) + ' TiB';

        case bytes / 1073741824 > 1:
          return (bytes / 1073741824).toFixed(2) + ' GiB';

        case bytes / 1048576 > 1:
          return (bytes / 1048576).toFixed(2) + ' MiB';

        case bytes / 1024 > 1:
          return (bytes / 1024).toFixed(2) + ' KiB';

        default:
          return bytes + ' bytes';
      }
    }
  }
})();
