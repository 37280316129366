(function () {
  'use strict';

  angular.module('dashboardApp.constants', [])
    .constant('DASHBOARD', {
      COLORS: ['#f2c553', '#11a4d3', '#a4c558', '#ea3131', '#00235d', '#000000'],
      DEFAULT: {
        NAME: 'my-dashboard'
      },
      ICONS: {
        GLYPHICONS: ['cogwheels', 'globe_af', 'heart', 'group', 'file', 'notes', 'picture', 'ok_2', 'paperclip', 'tag', 'link', 'stats', 'charts', 'pie_chart', 'warning_sign', 'cardio', 'pencil', 'bookmark', 'book', 'pushpin', 'show_thumbnails'],
        MATERIALICONS: ['account_balance', 'announcement', 'apps', 'assignment_ind', 'assignment_late', 'assignment_return', 'assignment_returned', 'assignment_turned_in', 'assignment', 'attach_file', 'autorenew', 'block', 'book', 'bookmark', 'border_all', 'bubble_chart', 'build', 'call_split', 'cancel', 'check', 'chrome_reader_mode', 'cloud_upload', 'code', 'compare_arrows', 'content_paste', 'dashboard', 'data_usage', 'date_range', 'delete', 'description', 'domain', 'donut_large', 'donut_small', 'edit_mode', 'equalizer', 'error', 'favorite', 'file_upload', 'forward', 'get_app', 'group', 'healing', 'help', 'highlight', 'import_export', 'info', 'insert_chart_outlined', 'label', 'linear_scale', 'link', 'local_hospital', 'local_hotel', 'local_pharmacy', 'location_city', 'lock', 'merge_type', 'message', 'monetization_on', 'multiline_chart', 'new_releases', 'notes', 'notifications', 'open_in_browser', 'pageview', 'payment', 'perm_contact_calendar', 'person', 'photo', 'pie_chart_outlined', 'pie_chart', 'playlist_add_check', 'playlist_add', 'poll', 'portrait', 'priority_high', 'public', 'publish', 'queue', 'report', 'schedule', 'search', 'security', 'send', 'settings', 'share', 'show_chart', 'shuffle', 'star', 'storage', 'swap_horiz', 'sync', 'timeline', 'traffic', 'transform', 'verified_user', 'view_compact', 'view_list', 'vpn_key', 'warning', 'wc', 'web', 'work', 'youtube_searched_for'],
        PSICONS: ['adt', 'advance_directive', 'ambulance', 'beaker', 'doctor', 'limbo_search', 'patient_details', 'patient_summary', 'persona_search', 'pill', 'pill_bottle', 'prescription', 'rx', 'sets', 'transcription', 'vitals', 'xray']
      },
      ITEMS: {
        TYPES: {
          FILTER: '__filter__',
          SEQUENCE: '__sequence__'
        }
      },
      LEVELS: {
        BASE: 'base',
        GROUP: 'group',
        SYSTEM: 'system',
        USER: 'user'
      }
    });
})();
