(function () {
  'use strict';

  angular.module('queries.services')
    .factory('QueriesSrv', QueriesSrv);

  QueriesSrv.$inject = [
    'QUERY',
    '$filter', '$http', '$log', '$parse', '$q', 'FilterSrv', 'imatStoresSrv',
    'NlpSrv', 'ReportsSrv', 'ResultDisplaySrv', 'searchSrv', 'userSession'
  ];

  function QueriesSrv (
    QUERY,
    $filter, $http, $log, $parse, $q, FilterSrv, imatStoresSrv,
    NlpSrv, ReportsSrv, ResultDisplaySrv, searchSrv, userSession
  ) {
    var fields = [];
    var filters = [];
    var loaded = {
      fields: false,
      filters: false,
      savedQueries: false,
      exports: false,
      searchStores: false
    };
    var reportExactTotal = true; // eslint-disable-line no-unused-vars
    var result = {
      saved: false,
      hits: [],
      meta: {},
      info: {},
      parameters: {}
    };
    var searchStores = [];

    var STORE_ALL = '-1';
    var STORE_LAB_CODES = 'LabCodes';
    var STORE_MED_CODES = 'MedCodes';

    var service = {
      active: { id: '' },
      admin: false,
      export: {
        id: '',
        info: []
      },
      exports: [],
      operators: {},
      queries: {},
      queriesMenu: {
        savedQueries: false,
        exports: false
      },
      query: getQueryTemplate(),
      record: {},
      recordList: [],
      result: result,
      resultsSortOrder: '',
      savedQueries: [],
      selectedConcept: [],
      buildQuery: buildQuery,
      codeSearch: codeSearch,
      deleteQuery: deleteQuery,
      deleteExport: deleteExport,
      getFields: getFields,
      getFilterProperties: getFilterProperties,
      getFilters: getFilters,
      getHits: getHits,
      getRecord: getRecord,
      getRecordList: getRecordList,
      getSavedQuery: getSavedQuery,
      getSavedQueries: getSavedQueries,
      getExport: getExport,
      getExports: getExports,
      getStores: getStores,
      getQueryTemplate: getQueryTemplate,
      loadRecordData: loadRecordData,
      resetExport: resetExport,
      resetQuery: resetQuery,
      resetResults: resetResults,
      runQuery: runQuery,
      saveQuery: saveQuery,
      saveResults: saveResults,
      searchFieldExtracts: searchFieldExtracts,
      searchFilterTypes: searchFilterTypes,
      searchFilterTypesForLabs: searchFilterTypesForLabs,
      setActiveId: setActiveId,
      setAdmin: setAdmin,
      setExport: setExport,
      setQueriesMenu: setQueriesMenu,
      setQuery: setQuery,
      setRecord: setRecord,
      setResultsSortOrder: setResultsSortOrder,
      updateFieldName: updateFieldName,
      updateQuery: updateQuery
    };

    return service;

    // FUTURE: determine if want a recursive method for nested groups/rules
    function buildQuery (query) {
      var booleanString = '';
      var keywordString = '';
      var termPromises = [];
      var groups = query.expression.groups; // maybe copy groups so can modify seperately

      // Loop through all of the rules in expression and
      // add a promise to return formatted query syntax
      // if the filter is a keyword, then add to keywordString instead of boolean
      angular.forEach(groups, function (group) {
        angular.forEach(group.rules, function (rule) {
          delete rule.term; // reset
          termPromises.push(
            FilterSrv.getFilterSyntax(rule)
              .then(function (termSyntax) {
                rule.term = termSyntax;
                if (rule.type === 'KEYWORD') {
                  keywordString += rule.term + ' ';
                  rule.term = '';
                }
                return termSyntax;
              })
              .catch(function (errorResponse) {
                return $q.reject(errorResponse);
              })
          );
        });// end for each rule in group x
      });// end for each group

      function _getValidTerms (element) {
        return element.term.length > 0;
      }

      return $q.all(termPromises).then(function () {
        // Build group term
        // for each rule with a term, add boolean operators
        angular.forEach(groups, function (g) {
          g.term = g.rules.filter(_getValidTerms).map(function (r) {
            return r.term;
          }).join(' ' + g.operator + ' ');
          if (g.term.length > 0 && g.operator === 'NOT') {
            g.term = 'NOT ' + g.term;
          }
        });
        // Build final expression
        // for each group with a term, add boolean operators and ()
        // combine ranked keyword values with boolean
        booleanString = groups.filter(_getValidTerms).map(function (grp) {
          return grp.term;
        }).join(') ' + query.expression.operator + ' (');
        if (booleanString.length > 0) {
          booleanString = booleanString.replace(/\(\)/g, '');// clean up a bit of the ()(()UNIVERSE) syntax
          booleanString = (query.expression.operator !== 'NOT') ? '()(' + booleanString + ')' : '() NOT (' + booleanString + ')';
        }
        return keywordString + booleanString;
      });
    }

    function codeSearch (searchText, type, lookupValue) {
      var parameters = {
        q: '()',
        limit: 50,
        store: STORE_MED_CODES,
        onref: 0
      };
      var terms = searchText.split(' '); // split on spaces, empty array default
      if (type) {
        // setup query syntax for code type
        switch (type.toUpperCase()) { // eslint-disable-line default-case
          case 'NLP_CLINICAL':
            if (terms[0].length === 0) {
              // default load case
              parameters.q += '()psfieldnames:snmterm ';
            } else {
              // searchText "a b" ()snmterm:a* snmterm:b*
              angular.forEach(terms, function (term) {
                parameters.q += 'snmterm:' + term + '* ';
              });
            }
            break;
          case 'NLP_MED':
          case 'CODE_SET':
            // searchText "a b" becomes ()sab:{code set here} (psdescription:(a*) OR pstitle:a*) (psdescription:b* OR pstitle:(b*))
            // wrapping in () so can handle cases like 2-ace...
            parameters.q += 'sab:IN(' + lookupValue + ') ';
            angular.forEach(terms, function (term) {
              if (term) {
                parameters.q += '(psdescription:(' + term + '*) OR pstitle:(' + term + '*)) ';
              }
            });
            break;
        }
        return runQuery(parameters)
          .catch(function (errorResponse) {
            $log.debug('Code search failed', errorResponse);
            return $q.reject(errorResponse);
          });
      }

      return $q.resolve([]);
    }

    function deleteExport (exportId) {
      // TODO: determine if want to update the list behind the scenes from api call instead of using service.savedExports
      return searchSrv.cancel(exportId, service.admin)
        .then(function () {
          if ('export_' + exportId === service.active.id) { // reset active export if was one deleted
            resetExport();
          }
          for (var i = 0, l = service.exports.length; i < l; i++) {
            if (service.exports[i].id === exportId) {
              service.exports.splice(i, 1);// remove deleted query from saved list
              break;
            }
          }
          return exportId;
        })
        .catch(function (errorResponse) {
          return $q.reject(errorResponse);
        });
    }

    function deleteQuery (queryId) {
      // TODO: determine if want to update the list behind the scenes from api call instead of using service.savedQueries
      return ReportsSrv.deleteReport(queryId, service.admin)
        .then(function () {
          if ('query_' + queryId === service.active.id) { // reset active query if was one deleted
            resetQuery();
          }
          for (var i = 0, l = service.savedQueries.length; i < l; i++) {
            if (service.savedQueries[i].id === queryId) {
              service.savedQueries.splice(i, 1);// remove deleted query from saved list
              break;
            }
          }
          return queryId;
        })
        .catch(function (errorResponse) {
          return $q.reject(errorResponse);
        });
    }

    // TODO: Use imatFieldsSrv instead.
    function getFields () {
      if (loaded.fields) {
        return $q.resolve(fields);
      }
      return $http({
        method: 'GET',
        url: '/search-ui/fields.xml',
        headers: { Accept: 'text/xml, application/xml' }
      })
        .then(function (response) {
          var payload = $parse('variables.variable')(response.data);
          if (payload != null && !angular.isArray(payload)) {
            payload = [payload];
          }
          // Loop through payload's objects and replace type values with user friendly label names.
          angular.forEach(payload, function (filter) {
            switch (filter.type) {
              case 'date':
                filter.typeLabel = 'Date range';
                break;
              case 'range':
                filter.typeLabel = 'Numerical range';
                break;
              case 'regular':
                filter.typeLabel = 'Text';
                break;
              default:
                filter.typeLabel = 'Text';
            }
          });
          // START mpi-api.service.js code
          // response.data = payload;
          // return response;
          // END

          // START QUERY SPECIFIC LOGIC
          fields = payload;
          fields = $filter('orderBy')(fields, 'label');
          loaded.fields = true;
          return fields;
          // END
        })
        .catch(function (errorResponse) {
          $log.error('Unable to load the field list.');
          loaded.fields = false;
          return $q.reject(errorResponse);
        });
    }

    function getFilterProperties (filterType) {
      return FilterSrv.getFilterProperties(filterType)
        .then(function (properties) {
          properties.valueDefault = angular.copy(properties.value);
          properties.operatorDefault = angular.copy(properties.operator);

          if (!properties.nlp) {
            return properties;
          }

          properties.nlpFieldsDefaults = angular.copy(properties.nlpFields);
          return NlpSrv.getRefinementOptions(filterType)
            .then(function (options) {
              angular.extend(properties.options, options);
              return properties;
            })
            .catch(function (errorResponse) {
              $log.error('Unable to load the filter nlp refinement properties.');
              return $q.reject(errorResponse);
            });
        })
        .catch(function (errorResponse) {
          $log.error('Unable to load the filter properties');
          return $q.reject(errorResponse);
        });
    }

    function getFilters () {
      if (loaded.filters) {
        return $q.resolve(filters);
      }
      return FilterSrv.loadFilters()
        .then(function (filtersResponse) {
          filters = filtersResponse;
          loaded.filters = true;
          return filters;
        })
        .catch(function (errorResponse) {
          loaded.filters = false;
          return $q.reject(errorResponse);
        });
    }

    function getHits () {
      return $q.resolve(result.hits);
    }

    function getRecord () {
      return $q.resolve(service.record);
    }

    function getRecordList (showAllRecords) {
      var parameters = {
        q: '()s.patient_id:(' + service.record.patient_id + ')',
        store: STORE_ALL,
        report: 'exacttotal',
        limit: -1,
        onspace: 0,
        onref: 0,
        fields: 'uri, indextime, patient_id, mpid, patient_full_name, patient_date_of_birth, date_of_service, record_type, sending_facility, facility'
      };

      // if missing patient id value, search for just specific record viewing
      if (!service.record.patient_id || service.record.patient_id.length === 0) {
        parameters.q = '()psuri:("' + service.record.uri + '")';
        parameters.store = service.record.store;
      }

      // Return the list filtered down from built query
      if (!showAllRecords) {
        parameters.q += ' ' + service.query.built;
        parameters.store = service.query.stores;
      }
      return runRecordQuery(parameters)
        .then(function (recordList) {
          angular.copy(recordList.hits, service.recordList);
          return recordList.hits;
        })
        .catch(function (errorResponse) {
          service.recordList.length = 0;
          return $q.reject(errorResponse);
        });
    }

    function getSavedQuery (id) {
      var all = service.admin ? '?admin=true' : '';
      return $http.get('/search/queries/' + id + all)
        .then(function (res) {
          var queryResult = res.data.query;
          var resultDisplay = queryResult.additional_cgi.split(/&amp;|&/);
          var rdParamString = QUERY.RESULT_DISPLAY_DEFAULT;

          for (var i = 0, l = resultDisplay.length; i < l; i++) {
            if (resultDisplay[i].indexOf('result_display') > -1) {
              rdParamString = resultDisplay[i].substring(resultDisplay[i].indexOf('=') + 1);
              break;
            }
          }

          queryResult.resultDisplay = rdParamString;

          return queryResult;
        });
    }

    function splitAtLastIndex(strToSplit, charToSplitAt) {
      let parts = [];
      let index = strToSplit.lastIndexOf(charToSplitAt);
      parts.push(strToSplit.slice(0, index));
      parts.push(strToSplit.slice(index + 1))
      return parts;
    }

    function getSavedQueries (force) {
      var config = service.admin ? { params: { admin: true } } : {};

      if (force) {
        loaded.savedQueries = false;
      }

      if (loaded.savedQueries) {
        return $q.resolve(service.savedQueries);
      }

      return $http.get('/api/queries', config)
        .then(function (res) {
          var filtered = res.data.filter(function (query) {
            return query.type === QUERY.TYPE && query.permission > 1;
          });
          angular.copy(filtered, service.savedQueries);
          service.savedQueries.forEach(function (r) {
            var ownerParts = splitAtLastIndex(r.owner, '@');
            r.username = ownerParts[0];
            r.provider = ownerParts.length > 1 ? ownerParts[1] : '';
          });
          loaded.savedQueries = true;
          return service.savedQueries;
        })
        .catch(function (error) {
          service.savedQueries.length = 0;
          return $q.reject(error);
        });
    }

    function getExport (exportId) {
      var all = service.admin ? '?admin=true' : '';
      return $http.get('/search/results/' + exportId + '/info' + all)
        .then(function (res) {
          var infoResult = res.data;
          var formattedInfo;
          // Yes this is gross
          // If users click on several different export results, we only want to show the active one
          // But the api takes 10+ seconds to return
          if (infoResult.result.set_name === service.export.id) {
            service.export.id = exportId;
            service.export.name = infoResult.result.name;
            formattedInfo = _formatInfo(infoResult.result);
            service.export.info.length = 0;
            for (var i = 0, l = formattedInfo.length; i < l; i++) {
              service.export.info.push(formattedInfo[i]);
            }
          }
          return service.export;
        })
        .catch(function (errorResponse) {
          return $q.reject(errorResponse);
        });
    }

    function getExports (force) {
      var all = service.admin ? '&admin=true' : '';

      if (force) {
        loaded.exports = false;
      }

      if (loaded.exports) {
        return $q.resolve(service.exports);
      }

      return $http.get('/search/results?named_only&caller=' + QUERY.CALLER + all)
        .then(function (res) {
          // No results
          if (!res.data.results.result) {
            service.exports.length = 0;
            return service.exports;
          }

          // One or many results

          // Normalize response to array
          var resultData = res.data.results.result.length ? res.data.results.result : [res.data.results.result];

          // Generate key/value pairs from additional_cgi
          resultData.forEach(function (r) {
            var arr = r.additional_cgi.split('&');
            arr.forEach(function (item) {
              var key = item.split('=')[0];
              var value = item.split('=')[1];
              switch (true) {
                case !isNaN(parseInt(value, 10)):
                  r[key] = parseInt(value, 10);
                  break;

                case (value === 'true' || value === 'false' || value === 'undefined'):
                  r[key] = eval(value); // eslint-disable-line no-eval
                  break;

                default:
                  r[key] = value;
                  break;
              }
            });

            var ownerParts = splitAtLastIndex(r.owner, '@');
            r.username = ownerParts[0];
            r.provider = ownerParts.length > 1 ? ownerParts[1] : '';
          });
          var filtered = resultData.filter(function (query) {
            return query.type === QUERY.TYPE && query.permission > 1 && query.sourcetype === 'export';
          });
          angular.copy(filtered, service.exports);
          loaded.exports = true;
          return service.exports;
        })
        .catch(function (error) {
          service.exports.length = 0;
          return $q.reject(error);
        });
    }

    function getStores () {
      if (loaded.searchStores) {
        return $q.resolve(searchStores);
      }
      return imatStoresSrv.getAll()
        .then(function (stores) {
          searchStores = angular.copy(stores);
          searchStores.unshift({
            name: 'All stores',
            number: '-1',
            exclude: '0'
          });
          loaded.searchStores = true;
          return searchStores;
        })
        .catch(function () {
          loaded.searchStores = false;
          return $q.reject('Unable to retrieve the data stores.');
        });
    }

    function getQueryTemplate (queryData) {
      var stores = [STORE_ALL];

      if (angular.isUndefined(queryData)) {
        return {
          id: '',
          name: 'Unsaved query',
          description: '',
          fields: ResultDisplaySrv.getFields(QUERY.RESULT_DISPLAY_DEFAULT, false),
          resultDisplay: QUERY.RESULT_DISPLAY_DEFAULT,
          saved: false,
          stores: [STORE_ALL],
          parameters: {},
          built: '',
          expression: {
            operator: 'AND',
            groups: [
              {
                operator: 'AND',
                filter: {
                  selected: '',
                  searchText: ''
                },
                rules: [
                ]
              }
            ]
          }
        };
      }

      if (queryData.space !== '') {
        stores = queryData.space.split(',');
      }

      return {
        id: queryData.id,
        name: queryData.name,
        description: queryData.description,
        fields: ResultDisplaySrv.getFields(QUERY.RESULT_DISPLAY_DEFAULT, false),
        resultDisplay: queryData.resultDisplay,
        saved: true,
        stores: stores,
        parameters: {},
        built: queryData.query_string,
        expression: {
          operator: 'AND',
          groups: [
            {
              operator: 'AND',
              filter: {
                selected: '',
                searchText: ''
              },
              rules: [{
                id: 'manual',
                label: 'Manual',
                type: 'MANUAL',
                field: '',
                format: 'Boolean search',
                options: {},
                operator: 'AND',
                value: queryData.query_string,
                valueDefault: '',
                operatorDefault: 'AND'
              }]
            }
          ]
        }
      };
    }

    function loadRecordData () {
      var splitIndex;
      var uri;
      var store;
      var parameters = {};
      var recordId = service.record.recordId;

      if (angular.isUndefined(recordId)) {
        return $q.reject();
      }

      splitIndex = recordId.lastIndexOf('__');
      uri = recordId.substring(0, splitIndex);
      store = recordId.substring(splitIndex + 2);

      // Not sure cache call is working currently, get 404
      // return $http({
      //  method: 'GET',
      //  url: 'search/stores/'+ store + '/items/' + encodeURIComponent(uri) + '/cache',
      //  headers: {'Accept': 'text/xml, application/xml'},
      // })

      parameters = {
        store: store,
        q: '()psuri:("' + uri + '")',
        fields: 'uri, patient_id, mpid, indextime, patient_full_name, patient_date_of_birth, date_of_service, record_type, sending_facility, facility, rawrecord'
      };

      function formatConcept (conceptType) {
        var concepts = [];
        if (angular.isDefined(conceptType) && angular.isDefined(conceptType.concept)) {
          if (angular.isArray(conceptType.concept)) {
            concepts = conceptType.concept;
          } else {
            concepts.push(conceptType.concept);
          }
        }

        return concepts;
      }

      return runRecordQuery(parameters)
        .then(function (recordResponse) { // eslint-disable-line complexity
          var match, doc, rawrecord, hit;
          var rawRegEx = /<rawrecord>(.|\n|\r)*<\/rawrecord>/;
          // $log.debug("record load data", recordResponse);
          if (recordResponse.hits.length > 0) {
            hit = recordResponse.hits[0];
            service.record.indexTime = hit.indextime;
            rawrecord = hit.rawrecord;
            // NOTE: Assuming all clinical records will have extended data section
            if (rawrecord.cxml && rawrecord.cxml.extended_data) {
              service.record.dataType = 'cxml';
              service.record.cxml = hit.rawrecord.cxml;
              match = recordResponse.origResponse.match(/(<document[^>]*>(.|\n|\r)*<\/document>)/);
              if (match && match.length > 0) {
                doc = match[0];
                doc = doc.replace(/csmk:div/g, 'csmkdiv');
                doc = doc.replace(/csmk:class=('|")section-name('|")/g, 'csmk:class="section-name" class="section-name"');
                service.record.document = doc;
              }
              service.selectedConcept.splice(0);
              service.record.extended_data = formatConcept(service.record.cxml.extended_data);
              service.record.allergies = formatConcept(service.record.cxml.allergies);
              service.record.devices = formatConcept(service.record.cxml.devices);
              service.record.labs = formatConcept(service.record.cxml.labs);
              service.record.measurements = formatConcept(service.record.cxml.measurements);
              service.record.medications = formatConcept(service.record.cxml.medications);
              service.record.problems = formatConcept(service.record.cxml.problems);
              service.record.procedures = formatConcept(service.record.cxml.procedures);
              service.record.symptoms = formatConcept(service.record.cxml.symptoms);
              service.record.patient_id = hit.patient_id || hit.mpid;
              service.record.patientId = hit.patient_id;
              service.record.recordId = hit.uri + '__' + hit.store;
              service.record.sending_facility = (hit.sending_facility) ? hit.sending_facility.split('|')[0] : (hit.facility && angular.isUndefined(hit.facility.system)) ? hit.facility.split('|')[0] : '';
              service.record.date_of_service = (hit.date_of_service) ? hit.date_of_service.split('|')[0] : '';
              service.record.patient_full_name = (hit.patient_full_name) ? hit.patient_full_name.split('|').join(' ') : '';
              service.record.patient_date_of_birth = (hit.patient_date_of_birth) ? hit.patient_date_of_birth.split('|')[0] : '';
              service.record.record_type = (hit.record_type) ? hit.record_type.split('|')[0] : '';
            } else if (rawrecord.Patient) {
              // mpi-persona fhir patient record returned
              service.record.dataType = 'patient';
            } else {
              // display rawrecord content
              // run regex to grab content inside rawrecord tags
              service.record.dataType = 'raw';
              service.record.raw = (recordResponse.origResponse.match(rawRegEx) || [''])[0].replace(/<rawrecord>|<\/rawrecord>/g, '').replace(/></g, '>\n<');
            }
          } else {
            return $q.reject('Unable to load the selected record.');
          }
          return $q.resolve(service.record);
        })
        .catch(function (errorResponse) {
          return $q.reject(errorResponse);
        });
    }

    function resetExport () {
      // Export most likely deleted, clear service.export and active id
      setActiveId();
      setExport({ id: '', info: [], name: '' });
    }

    function resetQuery () {
      // NEW QUERY selected, clear service.query and active id
      // reset fields/columns selection
      setActiveId();
      setQuery(getQueryTemplate());
      ResultDisplaySrv.resetFields();
    }

    function resetResults () {
      // Running a new query from menu action, so reset any current results
      service.result.info = {};
      service.result.saved = false;
      service.result.parameters = {};
      service.result.hits.length = 0;
      service.result.meta = {};
    }

    function runQuery (parameters) {
      parameters.onref = parameters.onref || 0;
      parameters.onspace = parameters.onspace || 0;
      if (parameters.q !== service.result.parameters.q) {
        parameters.report = 'exacttotal';
      } else {
        delete parameters.report;
      }

      service.result.parameters = parameters;
      service.result.info = _formatInfo(parameters);
      service.result.saved = false;

      parameters.caller = QUERY.CALLER;
      return searchSrv.search(parameters, {}, true)
        .then(function (data) {
          return _formatSearchResults(data)
            .catch(function () {
              return $q.reject('An error occurred while formatting search results');
            });
        })
        .catch(function (errorResponse) {
          var message = errorResponse.error.message || 'Request failed';
          return $q.reject(message);
        })
        .finally(function () {
          reportExactTotal = false;
        });
    }

    function runRecordQuery (parameters) {
      var params = angular.extend(parameters, { caller: QUERY.CALLER });
      return searchSrv.search(params, {}, true)
        .then(function (data) {
          return _formatRecordResult(data)
            .then(function (formattedResults) {
              return { hits: formattedResults, origResponse: data.original_response };
            })
            .catch(function () {
              return $q.reject('An error occurred while formatting the record');
            });
        })
        .catch(function (errorResponse) {
          var message = errorResponse.error.message || 'Request failed';
          return $q.reject(message);
        });
    }

    function saveQuery (type, parameters) {
      var data = {
        type: type,
        content: parameters.built,
        name: parameters.name,
        description: parameters.description,
        stores: _formatSourceData(parameters.stores),
        additionalCgi: {
          result_display: parameters.resultDisplay,
          fields: (Array.isArray(parameters.fields) ? parameters.fields.join(',') : '')
        }
      };

      // TODO: determine if want to update the list behind the scenes using api call
      return ReportsSrv.addReport(data)
        .then(function (response) {
          // update savedQueries with new object, stores and query syntax not needed
          var newQuery = {
            description: parameters.description,
            id: response,
            name: parameters.name,
            owner: userSession.getUserData().username + '@' + userSession.getUserData().provider,
            username: userSession.getUserData().username,
            provider: userSession.getUserData().provider,
            permission: '7',
            type: QUERY.TYPE
          };
          service.savedQueries.push(newQuery);
          return response;
        })
        .catch(function (errorResponse) {
          return $q.reject(errorResponse);
        });
    }

    function saveResults (formFields) {
      var query = service.query;
      if (!query) {
        return $q.reject('No Active Query');
      }
      var data = {
        caller: QUERY.CALLER,
        desc: formFields.desc,
        limit: '-1',
        usesession: true,
        q: service.result.parameters.q,
        saveresults: formFields.saveresults,
        store: _formatSourceData(service.result.parameters.store),
        result_display: service.result.parameters.result_display,
        initial_query: service.result.parameters.initial_query,
        initial_store: service.result.parameters.initial_store
      };
      if (service.admin) {
        data.admin = true;
      }
      if (formFields.exportResults) { // check if request came from export workflow
        angular.extend(data, {
          sourcetype: 'export',
          format: formFields.format || 'csv',
          fields: formFields.fields.join(','),
          start: formFields.start,
          limit: formFields.limit
        });
      }

      var xhr = {
        url: '/search',
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
        data: data
      };

      return $http(xhr)
        .then(function (response) {
          // update exports with new object, need new id, name, owner, description, etc
          var newResult = {
            description: data.desc,
            format: data.format || 'xml',
            id: response.data.result_id,
            name: data.saveresults,
            owner: userSession.getUserData().systemName,
            username: userSession.getUserData().username,
            provider: userSession.getUserData().provider,
            permission: '7',
            type: QUERY.TYPE
          };

          if (formFields.exportResults) {
            newResult.sourcetype = 'export';
          }

          service.exports.push(newResult);
          return response;
        });
    }

    function searchFieldExtracts (searchText) {
      var filtered;

      searchText = (angular.isString(searchText) ? searchText.toLowerCase() : searchText);
      filtered = !searchText
        ? fields.filter(function (item) {
          if (ResultDisplaySrv.isIncluded(service.query.resultDisplay, item.extract)) { return false; }
          return true;
        })
        : fields.filter(function (item) {
          var filterLabel = item.label;
          if (!filterLabel || !angular.isString(filterLabel) || ResultDisplaySrv.isIncluded(service.query.resultDisplay, item.extract)) { return false; }
          filterLabel = filterLabel.toLowerCase();
          return filterLabel.indexOf(searchText) >= 0;
        });
      return $q.resolve(filtered);
    }

    function searchFilterTypes (filterCategory, searchText, filterTypes) { // eslint-disable-line complexity
      var filtered;
      var categoryType = filterCategory.toLowerCase();

      // filter down list by category type first
      if (filterCategory !== QUERY.FILTER_CATEGORY_DEFAULT) {
        switch (filterCategory) {
          case QUERY.FILTER_CATEGORY_CODE_SETS:
            categoryType = 'code_set';
            break;
          case QUERY.FILTER_CATEGORY_DATE:
            categoryType = 'date';
            break;
          case QUERY.FILTER_CATEGORY_NLP:
            categoryType = 'nlp';
            break;
          case QUERY.FILTER_CATEGORY_NUMERIC:
            categoryType = 'range';
            break;
          case QUERY.FILTER_CATEGORY_SET:
            categoryType = 'set';
            break;
          case QUERY.FILTER_CATEGORY_TEXT:
            categoryType = 'regular';
            break;
          default:
            // no special value so just use filter category type as is
        }

        if (categoryType !== 'nlp') {
          filterTypes = filterTypes.filter(function (item) {
            if (item.type.toLowerCase() === categoryType) {
              return true;
            }
          });
        } else {
          filterTypes = filterTypes.filter(function (item) {
            if (item.type.toLowerCase().indexOf(categoryType) === 0) {
              return true;
            }
          });
        }
      }
      searchText = (angular.isString(searchText) ? searchText.toLowerCase() : searchText);
      filtered = !searchText
        ? filterTypes
        : filterTypes.filter(function (item) {
          var filterLabel = item.label;
          if (!filterLabel || !angular.isString(filterLabel)) { return false; }
          // append description to required label if it exists
          if (item.description && item.description.length > 0) {
            filterLabel += ' ' + item.description;
          }
          filterLabel = filterLabel.toLowerCase();
          return filterLabel.indexOf(searchText) >= 0;
        });
      return $q.resolve(filtered);
    }

    function searchFilterTypesForLabs (searchText) {
      var params = { q: QUERY.DEFAULT_QUERY, store: STORE_LAB_CODES, limit: 100 };
      var terms = searchText.split(''); // split on every char

      if (terms.length > 0) {
        params.q = '()labsplit:("' + terms.join(' ') + '")';
      }

      return $http({
        method: 'POST',
        url: '/search',
        headers: {
          'Content-Type': undefined
        },
        params: params
      })
        .then(function (response) {
          var hitfinder = $parse('response.hits.hit');
          var hits = hitfinder(response.data) || [];
          var hit;

          if (!angular.isArray(hits)) {
            hits = [hits];
          }

          // loop through backwards so can push new items in
          for (var i = hits.length - 1; i >= 0; i--) {
            hit = hits[i];

            // if type defined in store, set as recommended
            hit.recommended = true;

            // setup filter properties
            if (hit.extract.labtype === 'string') {
              hit.id = 'lab_string_' + hit.extract.labfield;
              hit.label = hit.extract.labfield.replace(/_/g, ' ');
              hit.type = 'REGULAR';
              hit.field = 'lab_t.' + hit.extract.labfield;
              hit.format = '';
              hit.extract.labtype = 'text';
              // add number version
              hits.push({
                id: 'lab_number_' + hit.extract.labfield,
                label: hit.extract.labfield.replace(/_/g, ' '),
                type: 'RANGE',
                field: 'lab.' + hit.extract.labfield,
                format: '',
                extract: {
                  labtype: 'number',
                  labfield: hit.extract.labfield
                }
              });
            } else { // number or undefined/blank ie default
              hit.id = 'lab_number_' + hit.extract.labfield;
              hit.label = hit.extract.labfield.replace(/_/g, ' ');
              hit.type = 'RANGE';
              hit.field = 'lab.' + hit.extract.labfield;
              hit.format = '';
              hit.extract.labtype = 'number';// set incase blank

              // add text version
              hits.push({
                id: 'lab_string_' + hit.extract.labfield,
                label: hit.extract.labfield.replace(/_/g, ' '),
                type: 'REGULAR',
                field: 'lab_t.' + hit.extract.labfield,
                format: '',
                extract: {
                  labtype: 'text',
                  labfield: hit.extract.labfield
                }
              });
            }
            // Add an option for normality search
            hits.push({
              id: 'lab_normality_' + hit.extract.labfield,
              label: hit.extract.labfield.replace(/_/g, ' ') + ' normality',
              type: 'REGULAR',
              field: 'labNorm.' + hit.extract.labfield,
              format: '',
              extract: {
                labtype: 'normality',
                labfield: hit.extract.labfield
              }
            });
          }// end for

          hits = $filter('orderBy')(hits, ['extract.labfield', 'extract.labtype']);
          angular.forEach(hits, function (h) {
            switch (h.type) {
              case 'DATE':
                h.typeLabel = 'Date range';
                break;
              case 'RANGE':
                h.typeLabel = 'Numerical range';
                break;
              case 'REGULAR':
                h.typeLabel = 'Text';
                break;
              default:
                h.typeLabel = 'Text';
            }
          });
          return hits;
        });
    }

    function setActiveId (id) {
      service.active.id = id || '';
    }

    function setAdmin (value) {
      service.admin = Boolean(value);
    }

    function setExport (exportObj) {
      angular.copy(exportObj, service.export);
    }

    function setQueriesMenu (savedQuery, exportResult) {
      service.queriesMenu.savedQueries = Boolean(savedQuery);
      service.queriesMenu.exports = Boolean(exportResult);
    }

    function setQuery (queryObj) {
      angular.copy(queryObj, service.query);
    }

    function setRecord (recordObj) {
      // TODO: add error checking to verify record has data required
      service.selectedConcept.splice(0);// keep selectedConcept so not undefined
      angular.copy(recordObj, service.record);
      return $q.resolve(service.record);
    }

    function setResultsSortOrder (order) {
      service.resultsSortOrder = order;
    }

    function updateFieldName (options) {
      return NlpSrv.buildFieldName(options)
        .catch(function (errorResponse) {
          return $q.reject(errorResponse);
        });
    }

    function updateQuery (parameters) {
      var data = {
        id: parameters.id,
        query_string: parameters.built,
        name: parameters.name,
        description: parameters.description,
        space: _formatSourceData(parameters.stores),
        additionalCgi: {
          result_display: parameters.resultDisplay,
          fields: (Array.isArray(parameters.fields) ? parameters.fields.join(',') : '')
        }
      };
      return ReportsSrv.saveReport(data, service.admin)
        .then(function (response) {
          // TODO: determine if want to update the list too behind the scenes using api call
          // find and update query object in savedQueries with potentially new name or description (query syntax and stores not in nav menu obj)
          for (var i = 0, l = service.savedQueries.length; i < l; i++) {
            if (service.savedQueries[i].id === parameters.id) {
              service.savedQueries[i].description = parameters.description;
              service.savedQueries[i].name = parameters.name;
              break;
            }
          }
          return response;
        })
        .catch(function (errorResponse) {
          return $q.reject(errorResponse);
        });
    }

    function _formatInfo (data) {
      var copied = angular.copy(data);
      var sorted = [];

      copied.query = copied.query ? copied.query : copied.query_string ? copied.query_string : copied.q;
      copied.data_source = copied.data_source ? copied.data_source : copied.space ? copied.space : copied.store;

      Object.keys(copied).sort().forEach(function (key) {
        if (copied[key] && ['q', 'query_string', 'space', 'store'].indexOf(key) < 0) {
          sorted.push({ key: key });
          sorted[sorted.length - 1][key] = copied[key];
        }
      });

      return sorted;
    }

    function _formatRecordResult (responseData) {
      var hits = [];
      if (responseData && responseData.response && responseData.response.hits) {
        if (responseData.response.hits._count === '0') {
          // no results found so just return empty array
        } else if (responseData.response.hits._count !== '1') {
          hits = responseData.response.hits.hit;
        } else {
          hits.push(responseData.response.hits.hit);
        }
        angular.forEach(hits, function (hit) {
          hit.store = hit._store;
          delete hit._store;
          hit.indexTime = hit.indextime || '';
          hit.patient_id = hit.patient_id || hit.mpid;
          hit.patientId = hit.patient_id;
          hit.recordId = hit.uri + '__' + hit.store;
          hit.sending_facility = (hit.sending_facility) ? hit.sending_facility.split('|')[0] : (hit.facility && angular.isUndefined(hit.facility.system)) ? hit.facility.split('|')[0] : '';
          hit.date_of_service = (hit.date_of_service) ? hit.date_of_service.split('|')[0] : '';
          hit.patient_full_name = (hit.patient_full_name) ? hit.patient_full_name.split('|').join(' ') : '';
          hit.patient_date_of_birth = (hit.patient_date_of_birth) ? hit.patient_date_of_birth.split('|')[0] : '';
          hit.record_type = (hit.record_type) ? hit.record_type.split('|')[0] : '';
        });
      }

      return $q.resolve(hits);
    }

    function _formatSearchResults (responseData) {
      var hits = [];
      var meta = {};
      if (responseData && responseData.response && responseData.response.hits) {
        if (responseData.response.hits._count === '0') {
          // no results found so just return empty array
        } else if (responseData.response.hits._count !== '1') {
          hits = responseData.response.hits.hit;
        } else {
          hits.push(responseData.response.hits.hit);
        }
        angular.forEach(hits, function (hit) {
          hit.store = hit._store;
          delete hit._store;
          hit.patient_id = hit.patient_id || hit.mpid;
          hit.patientId = hit.patient_id;
          hit.recordId = hit.uri + '__' + hit.store;
          hit.sending_facility = (hit.sending_facility) ? hit.sending_facility.split('|')[0] : (hit.facility && angular.isUndefined(hit.facility.system)) ? hit.facility.split('|')[0] : '';
          hit.date_of_service = (hit.date_of_service) ? hit.date_of_service.split('|')[0] : '';
          hit.patient_full_name = (hit.patient_full_name) ? hit.patient_full_name.split('|').join(' ') : '';
          hit.patient_date_of_birth = (hit.patient_date_of_birth) ? hit.patient_date_of_birth.split('|')[0] : '';
          hit.record_type = (hit.record_type) ? hit.record_type.split('|')[0] : '';
          if (hit.extract) {
            angular.forEach(hit.extract, function (value, key) {
              if (hit[key]) {
                hit[key + '_extract'] = value;
              } else {
                hit[key] = value;
              }
            });
          }
        });

        meta = responseData.response.hits;
        meta._total = parseInt(meta._total, 10) > -1 ? meta._total : service.result.meta._total;
        delete meta.hit;
      }
      service.result.hits = hits;
      service.result.meta = meta;

      // Update paging
      service.result.parameters.onspace = (responseData.response.paging && responseData.response.paging._onspace) || 0;
      service.result.parameters.onref = (responseData.response.paging && responseData.response.paging._onref) || 0;

      return $q.resolve(hits);
    }

    function _formatSourceData (data) {
      return data.join(',');
    }
  }
})();
