(function () {
  'use strict';

  angular.module('admin')
    .controller('AdminGroupAddCtrl', AdminGroupAddCtrl);

  AdminGroupAddCtrl.$inject = ['$filter', '$q', '$scope', '$state', 'ArrayFnSrv', 'ProvidersSrv', 'psNotification', 'UsersSrv', 'GroupsSrv'];

  function AdminGroupAddCtrl ($filter, $q, $scope, $state, ArrayFnSrv, ProvidersSrv, psNotification, UsersSrv, GroupsSrv) {
    var vm = this;
    var GROUP_ADD_ERROR = 'Unable to add ';
    var GROUP_ADD_SUCCESS = ' added.';
    var USER_LIST_ERROR = 'Unable to retrieve the user list.';
    var allUsers = [];

    // Properties
    vm.availableUsers = [];
    vm.availableUsersSearch = '';
    vm.includedUsersSearch = '';
    vm.filterUsersByProvider = filterUsersByProvider;
    vm.includedUsers = [];
    vm.providers = [];
    vm.group = {
      provider: '',
      name: '',
      description: ''
    };
    vm.loadingUserList = true;

    // Methods
    vm.addAllUsers = addAllUsers;
    vm.addUser = addUser;
    vm.clearAvailableUsersFilter = clearAvailableUsersFilter;
    vm.clearExistingUsersFilter = clearExistingUsersFilter;
    vm.createGroup = createGroup;
    vm.filterAvailableList = filterAvailableList;
    vm.filterIncludedList = filterIncludedList;
    vm.removeUser = removeUser;
    vm.removeAllUsers = removeAllUsers;

    activate();

    function activate () {
      ProvidersSrv.getProviderList()
        .then(function (providers) {
          vm.providers = providers;
          vm.group.provider = providers[0];
          filterUsersByProvider(true);
        });
    }

    //= ================================
    // Public interface
    //= ================================

    function clearAvailableUsersFilter () {
      vm.availableUsersSearch = '';
    }

    function clearExistingUsersFilter () {
      vm.includedUsersSearch = '';
    }

    function createGroup () {
      GroupsSrv.createGroup(vm.group)
        .then(function (group) {
          var members = [];
          vm.includedUsers.forEach(function (member) {
            members.push({ username: member });
          });
          return GroupsSrv.addMembers(group, members);
        })
        .then(function () {
          psNotification.success(vm.group.name + GROUP_ADD_SUCCESS);
          $state.go('app.admin.groups');
        })
        .catch(function (response) {
          response = (angular.isString(response) ? response : GROUP_ADD_ERROR + vm.group.name + '.');
          psNotification.error(response);
        });
    }

    function filterUsersByProvider (force) {
      UsersSrv.getList(force)
        .then(function (users) {
          allUsers = [];
          users.forEach(function (user) {
            if (user.provider === vm.group.provider) {
              allUsers.push(user.username);
            }
          });
          vm.includedUsers = [];
          vm.availableUsers = angular.copy(allUsers);
          filterIncludedUsers();
          filterAvailableUsers();
        })
        .catch(function () {
          psNotification.error(USER_LIST_ERROR);
        })
        .finally(function () {
          vm.loadingUserList = false;
        });
    }

    function filterAvailableList (groups) {
      return filterList(groups.toLowerCase(), vm.availableUsersSearch.toLowerCase());
    }

    function filterIncludedList (groups) {
      return filterList(groups.toLowerCase(), vm.includedUsersSearch.toLowerCase());
    }

    //= ================================
    // Private interface
    //= ================================

    function addAllUsers () {
      var visibleUsers = $filter('filter')(vm.availableUsers, { $: vm.availableUsersSearch });
      vm.availableUsers = ArrayFnSrv.diff(vm.availableUsers, visibleUsers).sort(ArrayFnSrv.sortNames);
      vm.includedUsers = vm.includedUsers.concat(visibleUsers).sort(ArrayFnSrv.sortNames);
      clearAvailableUsersFilter();
    }

    function addUser (user) {
      vm.availableUsers.splice(vm.availableUsers.indexOf(user), 1);
      vm.includedUsers.push(user);
    }

    function filterIncludedUsers () {
      return $filter('filter')(vm.includedUsers, function (user) {
        return user.indexOf(vm.includedUsersSearch);
      });
    }

    function filterAvailableUsers () {
      return $filter('filter')(vm.availableUsers, function (user) {
        return user.indexOf(vm.availableUsersSearch);
      });
    }

    function filterList (list, input) {
      if (!input) {
        return true;
      }
      return (list.indexOf(input) !== -1) ? true : (list.indexOf(input) !== -1);
    }

    function removeAllUsers () {
      var visibleUsers = $filter('filter')(vm.includedUsers, { $: vm.includedUsersSearch });

      vm.includedUsers = ArrayFnSrv.diff(vm.includedUsers, visibleUsers).sort(ArrayFnSrv.sortNames);
      vm.availableUsers = vm.availableUsers.concat(visibleUsers).sort(ArrayFnSrv.sortNames);
      clearExistingUsersFilter();
    }

    function removeUser (user) {
      vm.availableUsers.push(user);
      vm.includedUsers.splice(vm.includedUsers.indexOf(user), 1);
    }
  }
})();
