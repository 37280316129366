(function () {
  'use strict';

  angular
    .module('admin')
    .factory('uploaderApi', uploaderApi);

  uploaderApi.$inject = ['$http'];

  function uploaderApi ($http) {
    var factory = [];
    var url = '/inboxes';

    factory.getInboxes = function () {
      return $http.get(url);
    };

    return factory;
  } // End factory.
})();
