(function () {
  'use strict';

  angular.module('mpiApp.services')
    .service('mpiPersona', mpiPersona);

  mpiPersona.$inject = ['STORES', '$http', '$q', 'MpiPatientClass', 'MpiPersonaClass'];

  function mpiPersona (STORES, $http, $q, MpiPatientClass, MpiPersonaClass) {
    var collection = {};
    var service = {
      activate: activate,
      load: load,
      loadRecords: loadRecords,
      reset: reset
    };

    return service;

    //= ================================
    // Public interface
    //= ================================

    // This method activates.
    function activate (activate, mpid, system, facilityObject) { // eslint-disable-line no-shadow
      if (activate === undefined || mpid === undefined || system === undefined || facilityObject === undefined) {
        return $q.reject();
      }

      var params = {
        purpose: (activate ? 'activate' : 'deactivate'),
        system: system,
        patient_id_system: facilityObject.system,
        patient_id_value: facilityObject.value
      };

      if (Object.prototype.hasOwnProperty.call(facilityObject, 'assigner') && Object.prototype.hasOwnProperty.call(facilityObject.assigner, 'display')) {
        params.patient_id_assigner_display = facilityObject.assigner.display;
      }

      if (Object.prototype.hasOwnProperty.call(facilityObject, 'assigner') && Object.prototype.hasOwnProperty.call(facilityObject.assigner, 'reference')) {
        params.patient_id_assigner_ref = facilityObject.assigner.reference;
      }

      return $http({
        method: 'POST',
        url: '/extensions/mpi/personas/' + mpid,
        params: params
      }).then(function (res) {
        return res.data;
      });
    }

    // This method loads (or retrieves from cache) a *master* PI record (as
    // opposed to the normal PI records that it comprises).
    function load (mpid) {
      var idx = convertIdToIndex(mpid);

      if (idx && Object.prototype.hasOwnProperty.call(collection, idx)) {
        return $q.resolve(collection[idx]);
      }
      return construct(mpid, idx);
    }

    // This method loads *normal* PI records (as opposed to master PI records),
    // and they are fetched from the "mpi-patientinfo" store which can
    // potentially return more detailed records (e.g., full SSNs) than what is
    // returned as "contained" records attached to a master PI record.
    // NOTE: This method does not cache the MpiPersonas or the fetched records.
    function loadRecords (mpid) {
      var idx = parseInt(mpid, 10);

      if (!idx) {
        return $q.resolve([]);
      }

      return $http({
        method: 'POST',
        url: '/search',
        params: {
          q: '()FILTER(curmpid:[' + idx + '])',
          store: STORES.MPI_PI_RECORDS,
          fields: 'uri,rawrecord,curlastupdated,qtable.f1',
          limit: '-1'
        }
      })
        .then(function (response) {
          var hits;

          if (response && response.data) {
            hits = response.data.response.hits.hit || [];
            return (angular.isArray(hits) ? hits : [hits]);
          }
          return [];
        })
        .then(function (hits) {
          var patient;
          var patients = [];
          hits.forEach(function (hit) {
            patient = new MpiPatientClass(hit.rawrecord || {});
            var curLastUpdated = (hit.curlastupdated) ? parseInt(moment(hit.curlastupdated).format('x'), 10) : 0;
            var lastUpdated = (patient.meta && patient.meta.lastUpdated) ? parseInt(moment(patient.meta.lastUpdated).format('x'), 10) : 0;
            patient.last_updated = curLastUpdated > lastUpdated ? curLastUpdated : lastUpdated;
            patient.isActive = true;
            patient.behavior = hit.qtable.f1;
            patient.setURI(hit.uri);// XXX Is this needed?
            patients.push(patient);
          });
          return patients;
        });
    }

    // This method clears the service MpiPersona cache
    // (either completely or for the passed MPID).
    function reset (mpid) {
      var idx = mpid && convertIdToIndex(mpid);

      if (idx) {
        delete collection[idx];
      } else {
        collection = {};
      }
    }

    //= ================================
    // Private interface
    //= ================================

    function construct (mpid, idx) {
      var promise;

      if (idx && angular.isObject(mpid)) {
        promise = $q.resolve(mpid);
      } else if (idx) {
        promise = $http({
          method: 'GET',
          url: '/extensions/mpi/personas/' + idx
        })
          .then(function (response) {
            return (response.data && response.data.mpi ? response.data.mpi : $q.reject());
          });
      } else {
        promise = $q.reject('MpiPersona constructor requires an MPID or a JSON FHIR patient record.');
      }

      return promise
        .then(function (fhirish) {
          collection[idx] = new MpiPersonaClass(fhirish || {});
          return collection[idx];
        });
    }

    // eslint-disable-next-line complexity
    function convertIdToIndex (mpid) {
      var idx;

      if (mpid && angular.isObject(mpid.Patient) && Object.prototype.hasOwnProperty.call(mpid.Patient, 'id')) {
        // A Patient object of the form { Patient: { id: <MPID> } }
        // May not be cleaned up after x2js(), hence checking for _value.
        idx = (angular.isDefined(mpid.Patient.id._value) ? parseInt(mpid.Patient.id._value, 10) : parseInt(mpid.Patient.id, 10));
      } else if (mpid && angular.isObject(mpid) && Object.prototype.hasOwnProperty.call(mpid, 'id')) {
        // A Patient object of the form { ResourceType: 'Patient', id: <MPID> }
        // This form is virtually guaranteed to be cleaned up.
        idx = parseInt(mpid.id, 10);
      } else if (angular.isString(mpid)) {
        idx = parseInt(mpid, 10);
      } else if (angular.isNumber(mpid)) {
        idx = mpid;
      }
      return (angular.isNumber(idx) ? idx : false);
    }
  }
}());
