/* eslint-disable no-multi-str */
(function () {
  'use strict';

  angular.module('imat.shared.test')
    .factory('imatFhirFixtures', imatFhirFixtures);

  imatFhirFixtures.$inject = [];

  function imatFhirFixtures () {
    var service;

    service = {
      getFhirJsonPatient: getFhirJsonPatient,
      getFhirXmlPatient: getFhirXmlPatient
    };

    return service;

    function getFhirJsonPatient () {
      // https://www.hl7.org/fhir/patient-examples.html
      return {
        resourceType: 'Patient',
        id: 'example',
        // "text": {
        //   "status": "generated",
        //   "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">\n\t\t\t<table>\n\t\t\t\t<tbody>\n\t\t\t\t\t<tr>\n\t\t\t\t\t\t<td>Name</td>\n\t\t\t\t\t\t<td>Peter James \n              <b>Chalmers</b> (&quot;Jim&quot;)\n            </td>\n\t\t\t\t\t</tr>\n\t\t\t\t\t<tr>\n\t\t\t\t\t\t<td>Address</td>\n\t\t\t\t\t\t<td>534 Erewhon, Pleasantville, Vic, 3999</td>\n\t\t\t\t\t</tr>\n\t\t\t\t\t<tr>\n\t\t\t\t\t\t<td>Contacts</td>\n\t\t\t\t\t\t<td>Home: unknown. Work: (03) 5555 6473</td>\n\t\t\t\t\t</tr>\n\t\t\t\t\t<tr>\n\t\t\t\t\t\t<td>Id</td>\n\t\t\t\t\t\t<td>MRN: 12345 (Acme Healthcare)</td>\n\t\t\t\t\t</tr>\n\t\t\t\t</tbody>\n\t\t\t</table>\n\t\t</div>"
        // },
        identifier: [
          {
            use: 'usual',
            type: {
              coding: [
                {
                  system: 'http://hl7.org/fhir/v2/0203',
                  code: 'MR'
                }
              ]
            },
            system: 'urn:oid:1.2.36.146.595.217.0.1',
            value: '12345',
            period: {
              start: '2001-05-06'
            },
            assigner: {
              display: 'Acme Healthcare'
            }
          }
        ],
        active: true,
        name: [
          {
            use: 'official',
            family: 'Chalmers',
            given: [
              'Peter',
              'James'
            ]
          },
          {
            use: 'usual',
            given: [
              'Jim'
            ]
          },
          {
            use: 'maiden',
            family: 'Windsor',
            given: [
              'Peter',
              'James'
            ],
            _given: [
              null,
              {
                extension: [{
                  url: 'http://hl7.org/fhir/StructureDefinition/rendered-value',
                  valueString: 'Foopsy'
                }]
              }
            ],
            period: {
              end: '2002'
            }
          }
        ],
        telecom: [
          {
            use: 'home'
          },
          {
            system: 'phone',
            value: '(03) 5555 6473',
            use: 'work',
            rank: 1
          },
          {
            system: 'phone',
            value: '(03) 3410 5613',
            use: 'mobile',
            rank: 2
          },
          {
            system: 'phone',
            value: '(03) 5555 8834',
            use: 'old',
            period: {
              end: '2014'
            }
          }
        ],
        gender: 'male',
        birthDate: '1974-12-25',
        _birthDate: {
          extension: [
            {
              url: 'http://hl7.org/fhir/StructureDefinition/patient-birthTime',
              valueDateTime: '1974-12-25T14:35:45-05:00'
            }
          ]
        },
        deceasedBoolean: false,
        address: [
          {
            use: 'home',
            type: 'both',
            text: '534 Erewhon St PeasantVille, Rainbow, Vic  3999',
            line: [
              '534 Erewhon St'
            ],
            city: 'PleasantVille',
            district: 'Rainbow',
            state: 'Vic',
            postalCode: '3999',
            period: {
              start: '1974-12-25'
            }
          }
        ],
        contact: [
          {
            relationship: [
              {
                coding: [
                  {
                    system: 'http://hl7.org/fhir/v2/0131',
                    code: 'N'
                  }
                ]
              }
            ],
            name: {
              family: 'du Marché',
              _family: {
                extension: [
                  {
                    url: 'http://hl7.org/fhir/StructureDefinition/humanname-own-prefix',
                    valueString: 'VV'
                  }
                ]
              },
              given: [
                'Bénédicte'
              ]
            },
            telecom: [
              {
                system: 'phone',
                value: '+33 (237) 998327'
              }
            ],
            address: {
              use: 'home',
              type: 'both',
              line: [
                '534 Erewhon St'
              ],
              city: 'PleasantVille',
              district: 'Rainbow',
              state: 'Vic',
              postalCode: '3999',
              period: {
                start: '1974-12-25'
              }
            },
            gender: 'female',
            period: {
              start: '2012'
            }
          }
        ],
        managingOrganization: {
          reference: 'Organization/1'
        }
      };
    }

    function getFhirXmlPatient () {
      // https://www.hl7.org/fhir/patient-examples.html
      return '<?xml version="1.0" encoding="UTF-8"?>\
        <Patient xmlns="http://hl7.org/fhir">\
          <id value="example"/>\
          <!-- <text>\
            <status value="generated"/>\
            <div xmlns="http://www.w3.org/1999/xhtml">\
              <table>\
                <tbody>\
                  <tr>\
                    <td>Name</td>\
                    <td>Peter James \
                      <b>Chalmers</b> (&quot;Jim&quot;)\
                    </td>\
                  </tr>\
                  <tr>\
                    <td>Address</td>\
                    <td>534 Erewhon, Pleasantville, Vic, 3999</td>\
                  </tr>\
                  <tr>\
                    <td>Contacts</td>\
                    <td>Home: unknown. Work: (03) 5555 6473</td>\
                  </tr>\
                  <tr>\
                    <td>Id</td>\
                    <td>MRN: 12345 (Acme Healthcare)</td>\
                  </tr>\
                </tbody>\
              </table>\
            </div>\
          </text> -->\
          <!--   MRN assigned by ACME healthcare on 6-May 2001   -->\
          <identifier>\
            <use value="usual"/>\
            <type>\
              <coding>\
                <system value="http://hl7.org/fhir/v2/0203"/>\
                <code value="MR"/>\
              </coding>\
            </type>\
            <system value="urn:oid:1.2.36.146.595.217.0.1"/>\
            <value value="12345"/>\
            <period>\
              <start value="2001-05-06"/>\
            </period>\
            <assigner>\
              <display value="Acme Healthcare"/>\
            </assigner>\
          </identifier>\
          <active value="true"/>\
          <!--   Peter James Chalmers, but called "Jim"   -->\
          <name>\
            <use value="official"/>\
            <family value="Chalmers"/>\
            <given value="Peter"/>\
            <given value="James"/>\
          </name>\
          <name>\
            <use value="usual"/>\
            <given value="Jim"/>\
          </name>\
          <name>\
            <!--  Maiden names apply for anyone whose name changes as a result of marriage - irrespective of gender  -->\
            <use value="maiden"/>\
            <family value="Windsor"/>\
            <given value="Peter"/>\
            <given value="James">\
              <extension url="http://hl7.org/fhir/StructureDefinition/rendered-value">\
                <valueString value="Foopsy"/>\
              </extension>\
            </given>\
            <period>\
              <end value="2002"/>\
            </period>\
          </name>\
          <telecom>\
            <use value="home"/>\
            <!--   home communication details aren\'t known   -->\
          </telecom>\
          <telecom>\
            <system value="phone"/>\
            <value value="(03) 5555 6473"/>\
            <use value="work"/>\
            <rank value="1"/>\
          </telecom>\
          <telecom>\
            <system value="phone"/>\
            <value value="(03) 3410 5613"/>\
            <use value="mobile"/>\
            <rank value="2"/>\
          </telecom>\
          <telecom>\
            <system value="phone"/>\
            <value value="(03) 5555 8834"/>\
            <use value="old"/>\
            <period>\
              <end value="2014"/>\
            </period>\
          </telecom>\
          <!--   use FHIR code system for male / female   -->\
          <gender value="male"/>\
          <birthDate value="1974-12-25">\
            <extension url="http://hl7.org/fhir/StructureDefinition/patient-birthTime">\
              <valueDateTime value="1974-12-25T14:35:45-05:00"/>\
            </extension>\
          </birthDate>\
          <deceasedBoolean value="false"/>\
          <address>\
            <use value="home"/>\
            <type value="both"/>\
            <text value="534 Erewhon St PeasantVille, Rainbow, Vic  3999"/>\
            <line value="534 Erewhon St"/>\
            <city value="PleasantVille"/>\
            <district value="Rainbow"/>\
            <state value="Vic"/>\
            <postalCode value="3999"/>\
            <period>\
              <start value="1974-12-25"/>\
            </period>\
          </address>\
          <contact>\
            <relationship>\
              <coding>\
                <system value="http://hl7.org/fhir/v2/0131"/>\
                <code value="N"/>\
              </coding>\
            </relationship>\
            <name>\
              <family value="du March&#xE9;">\
                <!--   the "du" part is a family name prefix (VV in iso 21090)   -->\
                <extension url="http://hl7.org/fhir/StructureDefinition/humanname-own-prefix">\
                  <valueString value="VV"/>\
                </extension>\
              </family>\
              <given value="B&#xE9;n&#xE9;dicte"/>\
            </name>\
            <telecom>\
              <system value="phone"/>\
              <value value="+33 (237) 998327"/>\
            </telecom>\
            <address>\
              <use value="home"/>\
              <type value="both"/>\
              <line value="534 Erewhon St"/>\
              <city value="PleasantVille"/>\
              <district value="Rainbow"/>\
              <state value="Vic"/>\
              <postalCode value="3999"/>\
              <period>\
                <start value="1974-12-25"/>\
              </period>\
            </address>\
            <gender value="female"/>\
            <period>\
              <!--   The contact relationship started in 2012   -->\
              <start value="2012"/>\
            </period>\
          </contact>\
          <managingOrganization>\
            <reference value="Organization/1"/>\
          </managingOrganization>\
        </Patient>';
    }
  }
})();
