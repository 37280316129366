(function () {
  'use strict';

  angular.module('dashboardApp')
    .controller('DashboardSidebarCtrl', DashboardSidebarCtrl);

  DashboardSidebarCtrl.$inject = [];

  function DashboardSidebarCtrl () {
    // ...
  }
})();
