(function () {
  'use strict';

  angular
    .module('imat.services')
    .factory('containersApiSrv', containersApiSrv);

  containersApiSrv.$inject = ['$http', '$q'];

  function containersApiSrv ($http, $q) {
    var baseUrl = '/containers';

    var service = {
      getList: getList,
      setState: setState
    };

    function getList () {
      return $http.get(baseUrl);
    }

    function setState (provider, username, state) {
      var url = [baseUrl, provider, username].join('/') + '/status';

      if (!state) {
        return $q.reject();
      }

      var data = { activate: state };
      var params = {
        headers: {
          'Content-Type': 'application/json'
        }
      };

      return $http.put(url, data, params);
    }

    return service;
  }
})();
