/*
Dynamic Height Directive

This directive was created specifically for use with md-virtual-repeat-container
which requires a height to be set, but there could be other use cases as well.

sizer [String]: A css query selector.
        This is the element that the height of the dynamically sized element is calculated off of.

        Example:
            <div dynamic-height sizer="'#mySizerDiv'">

offset (Optional) [String | Number | Array]: CSS selector, Number, or an array of css query selectors and/or numbers. Offset value(s) are subtracted from the height calculation of the dynamically sized element.

        Example:
            <div dynamic-height sizer="'#mySizerDiv'" offset="['.offset-element', 12]">

defer (Optional) [Any]: This is a watched value to trigger recalculation of the height based on a changed value.
*/

(function () {
  'use strict';
  angular
    .module('imat.directives')
    .directive('dynamicHeight', ['$timeout', '$window', function ($timeout, $window) {
      return {
        restrict: 'A',
        scope: {
          sizer: '=',
          offset: '=?',
          defer: '=?',
          sizerOverflow: '=?'
        },
        link: function (scope, element) {
          $timeout(function () { setHeight(true); }, 0);

          scope.$watch('defer', function () {
            $timeout(function () { setHeight(true); }, 0);
          });

          // Recalculate height when browser window is resized
          angular.element($window).on('resize', function () {
            setHeight();
          });

          // Disable scrolling on sizer element by default, unless overridden by scope value
          if (!scope.sizerOverflow) {
            angular.element(scope.sizer).css('overflow', 'hidden');
          }

          function setHeight () {
            if (scope.sizer) {
              var size = angular.element(scope.sizer).height();
              angular.element(element).height(size - calculateOffset());
            } else {
              handleError('No sizer set.');
            }
          }

          function calculateOffset () {
            var ret;

            if (!scope.offset) { return 0; }

            switch (typeof scope.offset) {
              // Offset is an array
              case 'object':
                if (!scope.offset.length) {
                  handleError('Incorrect format for offset value.');
                  break;
                }
                var total = 0;
                scope.offset.forEach(function (value) {
                  // If offset is a css selector string, parse it to a number, otherwise just return the number
                  var number = typeof value === 'string' ? calculateTotalHeight(angular.element(value)) : value;
                  total = total + number;
                });
                ret = total;
                break;

              // Offset is a single css selector
              case 'string':
                ret = calculateTotalHeight(angular.element(scope.offset));
                break;

              // Offset is a single integer
              case 'number':
                ret = scope.offset;
                break;

              default:
                handleError('Incorrect format for offset value.');
            }

            return ret;
          }

          function calculateTotalHeight (el) {
            // If multiple elements returned from css selector string, return total of all element heights
            if (el.length > 1) {
              var total = 0;
              for (var i = 0; i < el.length; i++) {
                total = total + totalHeight(el[i]);
              }
              return total;
            }
            // If only one element, just calculate height of element
            return totalHeight(el);
          }

          function totalHeight (el) {
            // If multiple elements, convert to angular.element
            var e = angular.element(el);
            var height = e.height() +
              parseInt(e.css('margin-top'), 10) +
              parseInt(e.css('margin-bottom'), 10) +
              parseInt(e.css('padding-top'), 10) +
              parseInt(e.css('padding-bottom'), 10) +
              parseInt(e.css('border-top-width'), 10) +
              parseInt(e.css('border-bottom-width'), 10);

            return height;
          }

          function handleError (text) {
            console.error('dynamicHeight: ' + text);
          }
        }
      };
    }]);
})();
