(function () {
  'use strict';

  angular.module('account')
    .controller('AccountPreferencesCtrl', AccountPreferencesCtrl);

  AccountPreferencesCtrl.$inject = ['$scope', '$state', 'imatConfig', 'Preferences', 'psNotification', 'userSession'];

  function AccountPreferencesCtrl ($scope, $state, imatConfig, Preferences, psNotification, userSession) {
    var vm = this;
    var appStates = [
      { page: 'Dashboard', state: 'app.dashboard.main', url: '/' },
      { page: 'Admin', state: 'app.admin.default', url: '/admin' },
      { page: 'MPI', state: 'app.mpi', url: '/mpi' },
      { page: 'Queries', state: 'app.queries', url: '/queries' },
      { page: 'Reports', state: 'app.reports.list', url: '/reports' },
      { page: 'VHR', state: 'app.vhr', url: '/vhr' }
    ];

    vm.hasPreferences = false;
    vm.configurable = {
      setLandingPage: imatConfig.get('setLandingPage')
    };

    vm.appPages = [];
    vm.submit = submit;

    activate();

    function activate () {
      vm.preferences = Preferences.getPreferences() || {};
      vm.preferences.landingpage = vm.preferences.landingpage || '';

      // Go through configurable prefs and make sure some are enabled
      for (var pref in vm.configurable) {
        if (Object.prototype.hasOwnProperty.call(vm.configurable, pref) && vm.configurable[pref] === true) {
          vm.hasPreferences = true;
        }
      }

      vm.appPages = appStates.filter(function (page) {
        var stateDecl = $state.get(page.state);
        return !!stateDecl && userSession.hasRoles(stateDecl.data.roles);
      });
    }

    // If we get multiple preferences here, may want to be able to post all prefs at once
    function submit () {
      Preferences.setPreference('landingpage', vm.preferences.landingpage).then(function () {
        psNotification.success('Preferences saved.');
        $scope.preferencesForm.$setPristine();
      }).catch(function () {
        psNotification.error('Failed to save preferences. Please try again later.');
      });
    }
  }
})();
