(function () {
  'use strict';

  angular
    .module('vhr.directives')
    .directive('vhrDate', vhrDir);

  vhrDir.$inject = ['$filter', '$locale', 'vhrConfigSrv'];

  function vhrDir ($filter, $locale, vhrConfigSrv) {
    var directive = {
      link: postLink,
      restrict: 'E',
      scope: {
        date: '='
      },
      templateUrl: '/ui/vhr/directives/vhrDate/vhr-date.html'
    };

    return directive;

    function postLink (scope, element, attrs) {
      scope.$watch('date', function (curr) {
        var params;
        var subjectDate;
        var valid = curr && (angular.isString(curr) || angular.isDate(curr));

        // Invalid or it looks like a milliseconds value (which we don't support).
        if (!valid || curr == parseInt(curr, 10)) { // eslint-disable-line eqeqeq
          scope.displayDate = '-';
          if (scope.formatClass) {
            element.removeClass(scope.formatClass);
            scope.formatClass = '';
          }
          return;
        }

        subjectDate = (angular.isDate(scope.date)) ? scope.date.toISOString() : scope.date.trim();
        params = vhrConfigSrv.getDateFilterParams((attrs.format || ''), (typeof attrs.local === 'undefined'));
        scope.displayDate = $filter('date')(subjectDate, params.format, params.tzOffset);

        if (params.property) {
          element.addClass(params.property);
          scope.formatClass = params.property;
        }
      });
    }
  }
})();
