(function () {
  'use strict';

  angular.module('imat.services')
    .factory('socketUtil', socketUtil);

  socketUtil.$inject = [];
  function socketUtil () {
    var service = {
      getUrlString: getUrlString
    };
    function getUrlString (url, params) {
      const queryParams = [];
      Object.keys(params).forEach(function (key) {
        queryParams.push(key + '=' + encodeURIComponent(params[key]));
      });
      if (queryParams.length) {
        return url + '?' + queryParams.join('&');
      }
      return url;
    }

    return service;
  }
})();
