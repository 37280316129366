(function () {
  'use strict';

  angular.module('queries.utils')
    .filter('unsafe', ['$sce', function ($sce) {
      return function (input) {
        return $sce.trustAsHtml(input);
      };
    }]);
})();
