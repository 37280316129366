(function () {
  'use strict';

  angular.module('imat.services.config')
    .factory('imatConfig', imatConfig);

  imatConfig.$inject = ['$http', '$log', '$q', '$rootScope', 'ImatConfigClass'];

  function imatConfig ($http, $log, $q, $rootScope, ImatConfigClass) {
    var FILE_IMAT_DEFAULT = 'imat.json';
    var PATH_IMAT_DEFAULT = '/api/conf/';
    var config = {};
    var configs = {};
    var loaded = false;
    var loading = false;
    var service;

    service = {
      construct: construct,
      get: get,
      init: init,
      isLoaded: isLoaded,
      load: load,
      loadConfigFile: loadConfigFile,
      set: set
    };

    return service;

    //= ================================
    // Core file
    //= ================================

    function construct (conf) {
      return new ImatConfigClass(conf || {});
    }

    function get (property) {
      if (loaded) {
        return config.get(property);
      }
    }

    // Deprecated. Use load().
    function init (reload) {
      return load(reload);
    }

    function isLoaded () {
      return !!loaded;
    }

    function load (reload) {
      if (loaded && !reload) {
        return $q.resolve(config);
      }
      if (loading) {
        return _loadAndCall(function () { return config; });
      }

      loaded = false;
      loading = true;

      return $q.all([
        _loadConfig()
      ])
      // .then(_loadSomeData)
      // .then(_loadSomeMoreData)
        .then(function () {
          loaded = true;
          loading = false;
          return config;
        });
    }

    // Load arbitrary/auxilliary files
    function loadConfigFile (file, path) {
      if (Object.prototype.hasOwnProperty.call(configs, file)) {
        return $q.resolve(configs[file]);
      }

      return _loadConfigFile(file, path)
        .then(function (topConf) {
          var q = [];

          if (angular.isObject(topConf.includes)) {
            angular.forEach(topConf.includes, function (incl, prop) {
              q.push(
                _loadConfigFile(incl, path)
                  .then(function (subConf) {
                    topConf[prop] = subConf;
                  })
              );
            });
            delete topConf.includes;
          }

          return $q.all(q)
            .then(function () {
              configs[file] = new ImatConfigClass(topConf);
              return configs[file];
            });
        });
    }

    function set (property, setting) {
      if (loaded && arguments.length === 2) {
        return config.set(property, setting);
      }
    }

    //= ================================
    // Private interface
    //= ================================

    function _loadAndCall (callback) {
      var deferred = $q.defer();
      var unwatch = $rootScope.$watch(function () { return loaded; }, function (curr) {
        if (curr) {
          if (typeof callback === 'function') {
            deferred.resolve(callback());
          } else {
            deferred.resolve();
          }
          unwatch();
        }
      });

      return deferred.promise;
    }

    function _loadConfig () {
      return loadConfigFile(FILE_IMAT_DEFAULT, PATH_IMAT_DEFAULT)
        .catch(function () {
          return new ImatConfigClass({});
        })
        .then(function (imatConf) {
          config = imatConf;
          delete configs[FILE_IMAT_DEFAULT];
          return config;
        })
        .then(function (raw) {
          if (!angular.isString(raw.customerHelpLink) || !raw.customerHelpLink) {
            raw.set('customerHelpLink', '/ui/documentation/');
          }
          // if (typeof raw.dates !== 'object') {
          //   raw.set('dates', {});
          // }
          // if (typeof raw.dates.date !== 'string') {
          //   raw.set('dates.date', 'mediumDate');
          // }
          // if (typeof raw.dates.datetime !== 'string') {
          //   raw.set('dates.datetime', 'medium');
          // }
        });
    }

    function _loadConfigFile (file, path) {
      if (angular.isUndefined(path)) { path = PATH_IMAT_DEFAULT; }

      var url = path + file;

      return $http({
        method: 'GET',
        url: url
      })
        .then(function (response) {
          if (!(new RegExp(PATH_IMAT_DEFAULT)).test(url)) {
            return response.data;
          }

          if (response.data.type === 'application/json') {
            return JSON.parse(response.data.content);
          } else if (response.data.type === 'application/xml' || response.data.type === 'text/html') {
            return { value: response.data.content };
          } else {
            // This error is thrown if haven't explicitly programmed how to parse a particular type
            $log.error('Config file file-type ' + response.data.type + ' is not yet supported.');
            throw new Error();
          }
        })
        .catch(function () {
          $log.debug('Unable to load config file ' + file);
          return $q.reject();
        });
    }
  }
})();
