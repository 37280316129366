(function () {
  'use strict';

  angular.module('dashboardApp', [
    'ngAnimate',
    'ngMaterial',
    'ngMessages',
    'ngSanitize',

    'dashboardApp.constants',
    'imat.directives', // materialBanner
    'imat.filters', // truncate
    'imat.services',

    'ui.router',
    'ui.sortable'
  ])
    .config(['$stateRegistryProvider', function ($stateRegistryProvider) {
      $stateRegistryProvider.register({
        abstract: true,
        name: 'app.dashboard',
        url: '/',
        data: {
          appKey: 'dashboard',
          pageTitle: '',
          roles: []
        }
      });
      $stateRegistryProvider.register({
        name: 'app.dashboard.main',
        views: {
          'content@app': {
            controller: 'DashboardLayoutCtrl',
            controllerAs: 'ctrl',
            templateUrl: '/ui/dashboard/layouts/view-layout.html'
          },
          'sidenav@app.dashboard.main': {
            controller: 'DashboardSidebarCtrl',
            controllerAs: 'ctrl',
            templateUrl: '/ui/dashboard/layouts/view-sidebar.html'
          },
          'item-content@app.dashboard.main': {
            controller: 'DashboardListCtrl',
            controllerAs: 'ctrl',
            templateUrl: '/ui/dashboard/dashboard.html'
          }
        }
      });
      $stateRegistryProvider.register({
        name: 'app.dashboard.base',
        url: 'dashboard/',
        views: {
          'content@app': {
            controller: 'DashboardListCtrl',
            controllerAs: 'ctrl',
            templateUrl: '/ui/dashboard/dashboard.html'
          }
        }
      });
      $stateRegistryProvider.register({
        name: 'app.dashboard.view',
        url: 'dashboard/:dashboardId/',
        views: {
          'content@app': {
            controller: 'DashboardLayoutCtrl',
            controllerAs: 'ctrl',
            templateUrl: '/ui/dashboard/layouts/view-layout.html'
          },
          'sidenav@app.dashboard.view': {
            controller: 'DashboardSidebarCtrl',
            controllerAs: 'ctrl',
            templateUrl: '/ui/dashboard/layouts/view-sidebar.html'
          },
          'item-content@app.dashboard.view': {
            controller: 'DashboardListCtrl',
            controllerAs: 'ctrl',
            templateUrl: '/ui/dashboard/dashboard.html'
          }
        }
      });
      $stateRegistryProvider.register({
        name: 'app.dashboard.edit',
        url: 'dashboard/:dashboardId/edit/',
        views: {
          'content@app': {
            controller: 'DashboardLayoutCtrl',
            controllerAs: 'ctrl',
            templateUrl: '/ui/dashboard/layouts/edit-layout.html'
          },
          'sidenav@app.dashboard.edit': {
            controller: 'DashboardSidebarCtrl',
            controllerAs: 'ctrl',
            templateUrl: '/ui/dashboard/layouts/edit-sidebar.html'
          },
          'item-content@app.dashboard.edit': {
            controller: 'DashboardEditCtrl',
            controllerAs: 'ctrl',
            templateUrl: '/ui/dashboard/dashboardEdit.html'
          }
        }
      });
      $stateRegistryProvider.register({
        name: 'app.dashboard.admin',
        url: 'dashboard/:dashboardId/admin/',
        data: {
          roles: ['system']
        },
        views: {
          'content@app': {
            controller: 'DashboardLayoutCtrl',
            controllerAs: 'ctrl',
            templateUrl: '/ui/dashboard/layouts/edit-layout.html'
          },
          'sidenav@app.dashboard.admin': {
            controller: 'DashboardSidebarCtrl',
            controllerAs: 'ctrl',
            templateUrl: '/ui/dashboard/layouts/edit-sidebar.html'
          },
          'item-content@app.dashboard.admin': {
            controller: 'DashboardEditCtrl',
            controllerAs: 'ctrl',
            templateUrl: '/ui/dashboard/dashboardEdit.html'
          }
        }
      });
    }]);
})();
