/* eslint-disable no-multi-spaces */
(function () {
  'use strict';

  angular.module('imat.fhir.constants')
    .constant('FHIR_VALUE_SET_URLS', {
      ADDRESS_TYPE: 'http://hl7.org/fhir/ValueSet/address-type',
      ADDRESS_USE: 'http://hl7.org/fhir/ValueSet/address-use',
      ADMINISTRATIVE_GENDER: 'http://hl7.org/fhir/ValueSet/administrative-gender',
      CONTACT_POINT_SYSTEM: 'http://hl7.org/fhir/ValueSet/contact-point-system',
      CONTACT_POINT_USE: 'http://hl7.org/fhir/ValueSet/contact-point-use',
      DATA_ABSENT_REASON: 'http://hl7.org/fhir/ValueSet/data-absent-reason',
      IDENTIFIER_TYPE: 'http://hl7.org/fhir/ValueSet/identifier-type',
      IDENTIFIER_USE: 'http://hl7.org/fhir/ValueSet/identifier-use',
      IMAT_IDENTIFIER_TYPE: 'http://imat.us/fhir/???', // TODO Stop guessing.
      LANGUAGES: 'http://hl7.org/fhir/ValueSet/languages',
      MARITAL_STATUS: 'http://hl7.org/fhir/ValueSet/marital-status',
      NAME_USE: 'http://hl7.org/fhir/ValueSet/name-use',

      US_DETAILED_ETHNICITY: 'http://hl7.org/fhir/us/core/ValueSet/detailed-ethnicity',
      US_DETAILED_RACE: 'http://hl7.org/fhir/us/core/ValueSet/detailed-race',
      US_OMB_ETHNICITY: 'http://hl7.org/fhir/us/core/ValueSet/omb-ethnicity-category',
      US_OMB_RACE: 'http://hl7.org/fhir/us/core/ValueSet/omb-race-category',

      V2_CONTACT_ROLE: 'http://hl7.org/fhir/ValueSet/v2-0131'
    })
    .constant('FHIR_VALUE_SETS', {
    // http://hl7.org/fhir/STU3/valueset-address-type.html
      ADDRESS_TYPE: [
        { code: 'postal',   display: 'Postal',            system: 'http://hl7.org/fhir/address-type' },
        { code: 'physical', display: 'Physical',          system: 'http://hl7.org/fhir/address-type' },
        { code: 'both',     display: 'Postal & Physical', system: 'http://hl7.org/fhir/address-type' }
      ],
      // http://hl7.org/fhir/STU3/valueset-address-use.html
      ADDRESS_USE: [
        { code: 'home', display: 'Home',            system: 'http://hl7.org/fhir/address-use' },
        { code: 'work', display: 'Work',            system: 'http://hl7.org/fhir/address-use' },
        { code: 'temp', display: 'Temporary',       system: 'http://hl7.org/fhir/address-use' },
        { code: 'old',  display: 'Old / Incorrect', system: 'http://hl7.org/fhir/address-use' }
      ],
      // http://hl7.org/fhir/STU3/valueset-administrative-gender.html
      ADMINISTRATIVE_GENDER: [
        { code: 'male',    display: 'Male',    system: 'http://hl7.org/fhir/administrative-gender' },
        { code: 'female',  display: 'Female',  system: 'http://hl7.org/fhir/administrative-gender' },
        { code: 'other',   display: 'Other',   system: 'http://hl7.org/fhir/administrative-gender' },
        { code: 'unknown', display: 'Unknown', system: 'http://hl7.org/fhir/administrative-gender' }
      ],
      // http://hl7.org/fhir/STU3/valueset-contact-point-system.html
      CONTACT_POINT_SYSTEM: [
        { code: 'phone', display: 'Phone', system: 'http://hl7.org/fhir/contact-point-system' },
        { code: 'fax',   display: 'Fax',   system: 'http://hl7.org/fhir/contact-point-system' },
        { code: 'email', display: 'Email', system: 'http://hl7.org/fhir/contact-point-system' },
        { code: 'pager', display: 'Pager', system: 'http://hl7.org/fhir/contact-point-system' },
        { code: 'url',   display: 'URL',   system: 'http://hl7.org/fhir/contact-point-system' },
        { code: 'sms',   display: 'SMS',   system: 'http://hl7.org/fhir/contact-point-system' },
        { code: 'other', display: 'Other', system: 'http://hl7.org/fhir/contact-point-system' }
      ],
      // http://hl7.org/fhir/STU3/valueset-contact-point-use.html
      CONTACT_POINT_USE: [
        { code: 'home',   display: 'Home',   system: 'http://hl7.org/fhir/contact-point-use' },
        { code: 'work',   display: 'Work',   system: 'http://hl7.org/fhir/contact-point-use' },
        { code: 'temp',   display: 'Temp',   system: 'http://hl7.org/fhir/contact-point-use' },
        { code: 'old',    display: 'Old',    system: 'http://hl7.org/fhir/contact-point-use' },
        { code: 'mobile', display: 'Mobile', system: 'http://hl7.org/fhir/contact-point-use' }
      ],
      // http://hl7.org/fhir/STU3/valueset-data-absent-reason.html
      DATA_ABSENT_REASON: [
        { code: 'unknown',       display: 'Unknown',       system: 'http://hl7.org/fhir/data-absent-reason' },
        { code: 'asked',         display: 'Asked',         system: 'http://hl7.org/fhir/data-absent-reason' },
        { code: 'temp',          display: 'Temp',          system: 'http://hl7.org/fhir/data-absent-reason' },
        { code: 'not-asked',     display: 'Not Asked',     system: 'http://hl7.org/fhir/data-absent-reason' },
        { code: 'masked',        display: 'Masked',        system: 'http://hl7.org/fhir/data-absent-reason' },
        { code: 'unsupported',   display: 'Unsupported',   system: 'http://hl7.org/fhir/data-absent-reason' },
        { code: 'astext',        display: 'As Text',       system: 'http://hl7.org/fhir/data-absent-reason' },
        { code: 'error',         display: 'Error',         system: 'http://hl7.org/fhir/data-absent-reason' },
        { code: 'NaN',           display: 'Not a Number',  system: 'http://hl7.org/fhir/data-absent-reason' },
        { code: 'not-performed', display: 'Not Performed', system: 'http://hl7.org/fhir/data-absent-reason' }
      ],
      // http://hl7.org/fhir/STU3/valueset-identifier-type.html
      IDENTIFIER_TYPE: [
        { code: 'DL',   display: "Driver's License Number",        system: 'http://hl7.org/fhir/v2/0203' },
        { code: 'PPN',  display: 'Passport Number',                system: 'http://hl7.org/fhir/v2/0203' },
        { code: 'BRN',  display: 'Breed Registry Number',          system: 'http://hl7.org/fhir/v2/0203' },
        { code: 'MR',   display: 'Medical Record Number',          system: 'http://hl7.org/fhir/v2/0203' },
        { code: 'MCN',  display: 'Microchip Number',               system: 'http://hl7.org/fhir/v2/0203' },
        { code: 'EN',   display: 'Employer Number',                system: 'http://hl7.org/fhir/v2/0203' },
        { code: 'TAX',  display: 'Tax ID Number',                  system: 'http://hl7.org/fhir/v2/0203' },
        { code: 'NIIP', display: 'National Insurance Payor Identifier (Payor)', system: 'http://hl7.org/fhir/v2/0203' },
        { code: 'PRN',  display: 'Provider Number',                system: 'http://hl7.org/fhir/v2/0203' },
        { code: 'MD',   display: 'Medical License Number',         system: 'http://hl7.org/fhir/v2/0203' },
        { code: 'DR',   display: 'Donor Registration Number',      system: 'http://hl7.org/fhir/v2/0203' },
        { code: 'ACSN', display: 'Accession ID',                   system: 'http://hl7.org/fhir/v2/0203' },
        { code: 'UDI',  display: 'Universal Device Identifier',    system: 'http://hl7.org/fhir/identifier-type' },
        { code: 'SNO',  display: 'Serial Number',                  system: 'http://hl7.org/fhir/identifier-type' },
        { code: 'SB',   display: 'Social Beneficiary Identifier',  system: 'http://hl7.org/fhir/identifier-type' },
        { code: 'PLAC', display: 'Placer Identifier',              system: 'http://hl7.org/fhir/identifier-type' },
        { code: 'FILL', display: 'Filler Identifier',              system: 'http://hl7.org/fhir/identifier-type' }
      ],
      // http://hl7.org/fhir/STU3/valueset-identifier-use.html
      IDENTIFIER_USE: [
        { code: 'usual',     display: 'Usual',     system: 'http://hl7.org/fhir/identifier-use' },
        { code: 'official',  display: 'Official',  system: 'http://hl7.org/fhir/identifier-use' },
        { code: 'temp',      display: 'Temp',      system: 'http://hl7.org/fhir/identifier-use' },
        { code: 'secondary', display: 'Secondary', system: 'http://hl7.org/fhir/identifier-use' }
      ],
      // http://imat.us/fhir/???
      IMAT_IDENTIFIER_TYPE: [
        { code: 'MR',   display: 'Medical record number',          system: 'http://hl7.org/fhir/v2/0203' },
        { code: 'SS',   display: 'Social Security Number',         system: 'http://hl7.org/fhir/v2/0203' },
        { code: 'PI',   display: 'Patient information identifier', system: 'http://imat.us/fhir/codes/mpi' },
        { code: 'MPID', display: 'Master patient identifier',      system: 'http://imat.us/fhir/codes/mpi' }
      ],
      // http://hl7.org/fhir/STU3/valueset-languages.html
      LANGUAGES: [
        { code: 'ar',    display: 'Arabic',                  system: 'urn:ietf:bcp:47' },
        { code: 'bn',    display: 'Bengali',                 system: 'urn:ietf:bcp:47' },
        { code: 'cs',    display: 'Czech',                   system: 'urn:ietf:bcp:47' },
        { code: 'da',    display: 'Danish',                  system: 'urn:ietf:bcp:47' },
        { code: 'de',    display: 'German',                  system: 'urn:ietf:bcp:47' },
        { code: 'de-AT', display: 'German (Austria)',        system: 'urn:ietf:bcp:47' },
        { code: 'de-CH', display: 'German (Switzerland)',    system: 'urn:ietf:bcp:47' },
        { code: 'de-DE', display: 'German (Germany)',        system: 'urn:ietf:bcp:47' },
        { code: 'el',    display: 'Greek',                   system: 'urn:ietf:bcp:47' },
        { code: 'en',    display: 'English',                 system: 'urn:ietf:bcp:47' },
        { code: 'en-AU', display: 'English (Australia)',     system: 'urn:ietf:bcp:47' },
        { code: 'en-CA', display: 'English (Canada)',        system: 'urn:ietf:bcp:47' },
        { code: 'en-GB', display: 'English (Great Britain)', system: 'urn:ietf:bcp:47' },
        { code: 'en-IN', display: 'English (India)',         system: 'urn:ietf:bcp:47' },
        { code: 'en-NZ', display: 'English (New Zeland)',    system: 'urn:ietf:bcp:47' },
        { code: 'en-SG', display: 'English (Singapore)',     system: 'urn:ietf:bcp:47' },
        { code: 'en-US', display: 'English (United States)', system: 'urn:ietf:bcp:47' },
        { code: 'es',    display: 'Spanish',                 system: 'urn:ietf:bcp:47' },
        { code: 'es-AR', display: 'Spanish (Argentina)',     system: 'urn:ietf:bcp:47' },
        { code: 'es-ES', display: 'Spanish (Spain)',         system: 'urn:ietf:bcp:47' },
        { code: 'es-UY', display: 'Spanish (Uruguay)',       system: 'urn:ietf:bcp:47' },
        { code: 'fi',    display: 'Finnish',                 system: 'urn:ietf:bcp:47' },
        { code: 'fr',    display: 'French',                  system: 'urn:ietf:bcp:47' },
        { code: 'fr-BE', display: 'French (Belgium)',        system: 'urn:ietf:bcp:47' },
        { code: 'fr-CH', display: 'French (Switzerland)',    system: 'urn:ietf:bcp:47' },
        { code: 'fr-FR', display: 'French (France)',         system: 'urn:ietf:bcp:47' },
        { code: 'fy',    display: 'Frysian',                 system: 'urn:ietf:bcp:47' },
        { code: 'fy-NL', display: 'Frysian (Netherlands)',   system: 'urn:ietf:bcp:47' },
        { code: 'hi',    display: 'Hindi',                   system: 'urn:ietf:bcp:47' },
        { code: 'hr',    display: 'Croatian',                system: 'urn:ietf:bcp:47' },
        { code: 'it',    display: 'Italian',                 system: 'urn:ietf:bcp:47' },
        { code: 'it-CH', display: 'Italian (Switzerland)',   system: 'urn:ietf:bcp:47' },
        { code: 'it-IT', display: 'Italian (Italy)',         system: 'urn:ietf:bcp:47' },
        { code: 'ja',    display: 'Japanese',                system: 'urn:ietf:bcp:47' },
        { code: 'ko',    display: 'Korean',                  system: 'urn:ietf:bcp:47' },
        { code: 'nl',    display: 'Dutch',                   system: 'urn:ietf:bcp:47' },
        { code: 'nl-BE', display: 'Dutch (Belgium)',         system: 'urn:ietf:bcp:47' },
        { code: 'nl-NL', display: 'Dutch (Netherlands)',     system: 'urn:ietf:bcp:47' },
        { code: 'no',    display: 'Norwegian',               system: 'urn:ietf:bcp:47' },
        { code: 'no-NO', display: 'Norwegian (Norway)',      system: 'urn:ietf:bcp:47' },
        { code: 'pa',    display: 'Punjabi',                 system: 'urn:ietf:bcp:47' },
        { code: 'pt',    display: 'Portuguese',              system: 'urn:ietf:bcp:47' },
        { code: 'pt-BR', display: 'Portuguese (Brazil)',     system: 'urn:ietf:bcp:47' },
        { code: 'ru',    display: 'Russian',                 system: 'urn:ietf:bcp:47' },
        { code: 'ru-RU', display: 'Russian (Russia)',        system: 'urn:ietf:bcp:47' },
        { code: 'sr',    display: 'Serbian',                 system: 'urn:ietf:bcp:47' },
        { code: 'sr-SP', display: 'Serbian (Serbia)',        system: 'urn:ietf:bcp:47' },
        { code: 'sv',    display: 'Swedish',                 system: 'urn:ietf:bcp:47' },
        { code: 'sv-SE', display: 'Swedish (Sweden)',        system: 'urn:ietf:bcp:47' },
        { code: 'te',    display: 'Telegu',                  system: 'urn:ietf:bcp:47' },
        { code: 'zh',    display: 'Chinese',                 system: 'urn:ietf:bcp:47' },
        { code: 'zh-CN', display: 'Chinese (China)',         system: 'urn:ietf:bcp:47' },
        { code: 'zh-HK', display: 'Chinese (Hong Kong)',     system: 'urn:ietf:bcp:47' },
        { code: 'zh-SG', display: 'Chinese (Singapore)',     system: 'urn:ietf:bcp:47' },
        { code: 'zh-TW', display: 'Chinese (Taiwan)',        system: 'urn:ietf:bcp:47' }
      ],
      // http://hl7.org/fhir/STU3/valueset-marital-status.html
      MARITAL_STATUS: [
        { code: 'A',   display: 'Anulled',           system: 'http://hl7.org/fhir/v3/MaritalStatus' },
        { code: 'D',   display: 'Divorced',          system: 'http://hl7.org/fhir/v3/MaritalStatus' },
        { code: 'I',   display: 'Interlocutory',     system: 'http://hl7.org/fhir/v3/MaritalStatus' },
        { code: 'L',   display: 'Legally Separated', system: 'http://hl7.org/fhir/v3/MaritalStatus' },
        { code: 'M',   display: 'Married',           system: 'http://hl7.org/fhir/v3/MaritalStatus' },
        { code: 'P',   display: 'Polygamous',        system: 'http://hl7.org/fhir/v3/MaritalStatus' },
        { code: 'S',   display: 'Never Married',     system: 'http://hl7.org/fhir/v3/MaritalStatus' },
        { code: 'T',   display: 'Domestic Partner',  system: 'http://hl7.org/fhir/v3/MaritalStatus' },
        { code: 'U',   display: 'Unmarried',         system: 'http://hl7.org/fhir/v3/MaritalStatus' },
        { code: 'W',   display: 'Widowed',           system: 'http://hl7.org/fhir/v3/MaritalStatus' },
        { code: 'UNK', display: 'Unknown',           system: 'http://hl7.org/fhir/v3/NullFlavor' }
      ],
      // http://hl7.org/fhir/STU3/valueset-name-use.html
      NAME_USE: [
        { code: 'usual',     display: 'Usual',     system: 'http://hl7.org/fhir/name-use' },
        { code: 'official',  display: 'Official',  system: 'http://hl7.org/fhir/name-use' },
        { code: 'temp',      display: 'Temp',      system: 'http://hl7.org/fhir/name-use' },
        { code: 'nickname',  display: 'Nickname',  system: 'http://hl7.org/fhir/name-use' },
        { code: 'anonymous', display: 'Anonymous', system: 'http://hl7.org/fhir/name-use' },
        { code: 'old',       display: 'Old',       system: 'http://hl7.org/fhir/name-use' },
        { code: 'maiden',    display: 'Name changed for Marriage', system: 'http://hl7.org/fhir/name-use' }
      ],
      // https://hl7.org/fhir/STU3/v3/ReligiousAffiliation/vs.html
      RELIGIOUS_AFFILIATION: [
        { code: '1001', display: 'Adventist',                               system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1002', display: 'African Religions',                       system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1003', display: 'Afro-Caribbean Religions',                system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1004', display: 'Agnosticism',                             system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1005', display: 'Anglican',                                system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1006', display: 'Animism',                                 system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1007', display: 'Atheism',                                 system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1008', display: "Babi & Baha'I faiths",                    system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1009', display: 'Baptist',                                 system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1010', display: 'Bon',                                     system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1011', display: 'Cao Dai',                                 system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1012', display: 'Celticism',                               system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1013', display: 'Christian (non-Catholic,  non-specific)', system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1014', display: 'Confucianism',                            system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1015', display: 'Cyberculture Religions',                  system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1016', display: 'Divination',                              system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1017', display: 'Fourth Way',                              system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1018', display: 'Free Daism',                              system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1019', display: 'Gnosis',                                  system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1020', display: 'Hinduism',                                system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1021', display: 'Humanism',                                system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1022', display: 'Independent',                             system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1023', display: 'Islam',                                   system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1024', display: 'Jainism',                                 system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1025', display: "Jehovah's Witnesses",                     system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1026', display: 'Judaism',                                 system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1027', display: 'Latter Day Saints',                       system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1028', display: 'Lutheran',                                system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1029', display: 'Mahayana',                                system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1030', display: 'Meditation',                              system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1031', display: 'Messianic Judaism',                       system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1032', display: 'Mitraism',                                system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1033', display: 'New Age',                                 system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1034', display: 'non-Roman Catholic',                      system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1035', display: 'Occult',                                  system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1036', display: 'Orthodox',                                system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1037', display: 'Paganism',                                system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1038', display: 'Pentecostal',                             system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1039', display: 'Process,  The',                           system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1040', display: 'Reformed/Presbyterian',                   system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1041', display: 'Roman Catholic Church',                   system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1042', display: 'Satanism',                                system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1043', display: 'Scientology',                             system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1044', display: 'Shamanism',                               system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1045', display: 'Shiite (Islam)',                          system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1046', display: 'Shinto',                                  system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1047', display: 'Sikism',                                  system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1048', display: 'Spiritualism',                            system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1049', display: 'Sunni (Islam)',                           system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1050', display: 'Taoism',                                  system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1051', display: 'Theravada',                               system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1052', display: 'Unitarian-Universalism',                  system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1053', display: 'Universal Life Church',                   system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1054', display: 'Vajrayana (Tibetan)',                     system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1055', display: 'Veda',                                    system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1056', display: 'Voodoo',                                  system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1057', display: 'Wicca',                                   system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1058', display: 'Yaohushua',                               system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1059', display: 'Zen Buddhism',                            system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1060', display: 'Zoroastrianism',                          system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1061', display: 'Assembly of God',                         system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1062', display: 'Brethren',                                system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1063', display: 'Christian Scientist',                     system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1064', display: 'Church of Christ',                        system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1065', display: 'Church of God',                           system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1066', display: 'Congregational',                          system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1067', display: 'Disciples of Christ',                     system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1068', display: 'Eastern Orthodox',                        system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1069', display: 'Episcopalian',                            system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1070', display: 'Evangelical Covenant',                    system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1071', display: 'Friends',                                 system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1072', display: 'Full Gospel',                             system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1073', display: 'Methodist',                               system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1074', display: 'Native American',                         system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1075', display: 'Nazarene',                                system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1076', display: 'Presbyterian',                            system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1077', display: 'Protestant',                              system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1078', display: 'Protestant,  No Denomination',            system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1079', display: 'Reformed',                                system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1080', display: 'Salvation Army',                          system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1081', display: 'Unitarian Universalist',                  system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' },
        { code: '1082', display: 'United Church of Christ',                 system: 'http://hl7.org/fhir/v3/ReligiousAffiliation' }
      ],
      // http://hl7.org/fhir/us/core/ValueSet-detailed-ethnicity.html
      US_DETAILED_ETHNICITY: [],
      // http://hl7.org/fhir/us/core/ValueSet-detailed-race.html
      US_DETAILED_RACE: [],
      // http://hl7.org/fhir/us/core/ValueSet-omb-ethnicity-category.html
      US_OMB_ETHNICITY: [
        { code: '2135-2', display: 'Hispanic or Latino',     system: 'urn:oid:2.16.840.1.113883.6.238' },
        { code: '2186-5', display: 'Not Hispanic or Latino', system: 'urn:oid:2.16.840.1.113883.6.238' }
      ],
      // http://hl7.org/fhir/us/core/ValueSet-omb-race-category.html
      US_OMB_RACE: [
        { code: '1002-5', display: 'American Indian or Alaska Native',          system: 'urn:oid:2.16.840.1.113883.6.238' },
        { code: '2028-9', display: 'Asian',                                     system: 'urn:oid:2.16.840.1.113883.6.238' },
        { code: '2054-5', display: 'Black or African American',                 system: 'urn:oid:2.16.840.1.113883.6.238' },
        { code: '2076-8', display: 'Native Hawaiian or Other Pacific Islander', system: 'urn:oid:2.16.840.1.113883.6.238' },
        { code: '2106-3', display: 'White',                                     system: 'urn:oid:2.16.840.1.113883.6.238' },
        { code: 'UNK',    display: 'Unknown',                                   system: 'http://hl7.org/fhir/v3/NullFlavor' },
        { code: 'ASKU',   display: 'Asked but no answer',                       system: 'http://hl7.org/fhir/v3/NullFlavor' }
      ],
      // http://hl7.org/fhir/STU3/v2/0131/index.html
      V2_CONTACT_ROLE: [
        { code: 'BP', display: 'Billing contact person',    system: 'http://hl7.org/fhir/v2/0131' },
        { code: 'C',  display: 'Emergency contact',         system: 'http://hl7.org/fhir/v2/0131' },
        { code: 'CP', display: 'Contact person',            system: 'http://hl7.org/fhir/v2/0131' },
        { code: 'E',  display: 'Employer',                  system: 'http://hl7.org/fhir/v2/0131' },
        { code: 'EP', display: 'Emergency contact person',  system: 'http://hl7.org/fhir/v2/0131' },
        { code: 'F',  display: 'Federal agency',            system: 'http://hl7.org/fhir/v2/0131' },
        { code: 'I',  display: 'Insurance company',         system: 'http://hl7.org/fhir/v2/0131' },
        { code: 'N',  display: 'Next-of-Kin',               system: 'http://hl7.org/fhir/v2/0131' },
        { code: 'O',  display: 'Other',                     system: 'http://hl7.org/fhir/v2/0131' },
        { code: 'PR', display: 'Person preparing referral', system: 'http://hl7.org/fhir/v2/0131' },
        { code: 'S',  display: 'State agency',              system: 'http://hl7.org/fhir/v2/0131' },
        { code: 'U',  display: 'Unknown',                   system: 'http://hl7.org/fhir/v2/0131' }
      ]
    });
})();
