(function () {
  'use strict';

  angular
    .module('imat.filters')
    .filter('ssQueryFieldname', function () {
      var rePatientIdn = /^patientidn$/i;

      return function (name) {
        if (!name) {
          return 'NRef';
        }
        if (rePatientIdn.test(name)) {
          return 'PatientIDN';
        }
        return name;
      };
    });
})();
