(function () {
  'use strict';

  angular
    .module('vhr.services')
    .factory('vhrConfigSrv', vhrConfigSrv);

  vhrConfigSrv.$inject = ['VHR_ADMIN_GROUP', '$q', '$rootScope', 'dateTimeSrv', 'imatConfig'];

  function vhrConfigSrv (VHR_ADMIN_GROUP, $q, $rootScope, dateTimeSrv, imatConfig) {
    var service;
    var loader;
    var loading;

    service = {
      raw: { pages: null }, // XXX Make private and remove references.
      getAbnormalLabFlags: getAbnormalLabFlags,
      getADTLabels: getADTLabels,
      getAdminGroup: getAdminGroup,
      // getAppName: getAppName,
      getDateFilterParams: getDateFilterParams,
      getHelpLink: getHelpLink,
      getHieAutoLookup: getHieAutoLookup,
      getHieAutoLookupExclude: getHieAutoLookupExclude,
      getHieMockData: getHieMockData,
      getHieSingleTargetRequest: getHieSingleTargetRequest,
      getIsoDate: dateTimeSrv.getIsoDate,
      getIsoDatetime: dateTimeSrv.getIsoDatetime,
      getLandingPage: getLandingPage,
      getOIDLabels: getOIDLabels,
      getOriginalDocumentAccess: getOriginalDocumentAccess,
      getPage: getPage,
      getPageByState: getPageByState,
      getPages: getPages,
      getPrintGroups: getPrintGroups,
      getPrintSource: getPrintSource,
      getRecords: getRecords,
      getReportParameters: getReportParameters,
      getRouteByPage: getRouteByPage,
      getRoutes: getRoutes,
      getTimezoneOffsetStr: dateTimeSrv.getTimezoneOffsetStr,
      getWidgets: getWidgets,
      load: load,
      transformCellForUiGrid: transformCellForUiGrid
    };

    return service;

    function getAdminGroup () {
      return VHR_ADMIN_GROUP;
    }

    function getAbnormalLabFlags () {
      return service.raw.abnormalLabFlags;
    }

    function getADTLabels () {
      return service.raw.hl7Labels;
    }

    function getAppName () {
      return service.raw.customerAppName || 'IMAT-VHR';
    }

    function getDateFilterParams (format, utc) {
      return dateTimeSrv.getDateFilterParams(format, utc, service.raw.dates);
    }

    function getHelpLink () {
      return service.raw.customerHelpLink || '/ui/documentation/#/guides/vhr';
    }

    function getHieAutoLookup () {
      return service.raw.hieAutoLookup;
    }

    function getHieAutoLookupExclude () {
      return service.raw.hieAutoLookupExclude || [];
    }

    function getHieMockData () {
      return service.raw.hieMockData || false;
    }

    function getHieSingleTargetRequest () {
      return service.raw.hieSingleTargetRequest || false;
    }

    function getLandingPage (group) {
      if (Object.prototype.hasOwnProperty.call(service.raw.landingPages, group)) {
        var pageName = service.raw.landingPages[group];
        var route = getRouteByPage(pageName);
        if (route) {
          return route.stateName;
        }
      }
      return false;
    }

    function getOIDLabels (oid) {
      return service.raw.oidLabels[oid];
    }

    function getOriginalDocumentAccess () {
      return service.raw.originalDocumentAccess;
    }

    function getPage (page) {
      return service.raw.pages[page];
    }

    function getPageByState (state) {
      var cfg;
      var i;
      var pages = Object.keys(service.raw.pages);

      for (i = pages.length - 1; i >= 0; --i) {
        cfg = service.raw.pages[pages[i]];
        if (cfg.stateName === state) {
          return cfg;
        }
        if (angular.isArray(cfg.stateNames) && cfg.stateNames.indexOf(state) >= 0) {
          return cfg;
        }
      }
    }

    function getPages () {
      return service.raw.pages;
    }

    function getPrintGroups () {
      return service.raw.printGroups || [];
    }

    function getPrintSource () {
      return service.raw.customerPrintSource || getAppName();
    }

    function getRecords () {
      return service.raw.records;
    }

    function getReportParameters (reportId) {
      if (service.raw && service.raw.reportParameters && service.raw.reportParameters[reportId] && typeof service.raw.reportParameters[reportId] === 'object') {
        return service.raw.reportParameters[reportId];
      }
    }

    function getRouteByPage (page) {
      var cfg = service.raw.pages[page] || {};

      if (cfg.isAccessible && cfg.stateName) {
        return { attest: cfg.attest, pageTitle: cfg.label, stateName: cfg.stateName };
      }
    }

    function getRoutes () {
      var pages = Object.keys(service.raw.pages);
      var pageOrder = service.raw.pageOrder || [];
      var routes = Array(pageOrder.length);

      pages.forEach(function (page) {
        var cfg = service.raw.pages[page];
        var idx = pageOrder.indexOf(page);
        var route = { attest: cfg.attest, pageTitle: cfg.label, stateName: cfg.stateName, icon: cfg.icon };
        if (!cfg.isAccessible || !cfg.stateName) {
          if (idx >= 0) {
            pageOrder.splice(idx, 1);
            routes.splice(idx, 1);
          }
          return;// continue
        }

        if (idx >= 0) {
          routes[idx] = route;
        } else {
          // routes.push(route);// Append whatever is remaining.
        }
      });
      return routes;
    }

    function getWidgets () {
      return service.raw.widgets || {};
    }

    function load (reload) {
      if (!loading && (!loader || reload)) {
        loader = $q.defer();
        loading = true;

        _loadConfig()
          .then(_downgradePagesGrids)
          .then(_upgradePagesGrids)
          .then(function () {
            loader.resolve();
          })
          .catch(function () {
            loader.reject();
          })
          .finally(function () {
            loading = false;
          });
      }

      return loader.promise;
    }

    function transformCellForUiGrid (colName, colDef, ctrlScope, scopeKey) {
      var col = angular.copy(colDef);
      var scope;
      var scopeStr;

      if (!col || !col.transform) {
        return col;
      }

      // .transform {
      //   template: <name>,
      //   translation: <object|property>,
      //   type: 'icon|text',
      //   unknown: <value>,
      //   format: <filter>,
      // }

      if (!ctrlScope[scopeKey]) {
        ctrlScope[scopeKey] = {};
      }

      ctrlScope[scopeKey][colName] = {
        translation: void (0), // eslint-disable-line no-void
        unknown: void (0) // eslint-disable-line no-void
      };

      scope = ctrlScope[scopeKey][colName];
      scopeStr = "grid.appScope['" + scopeKey + "']['" + colName + "']";

      setCellFormatForUiGrid(col, col.transform, scope, scopeStr);
      setCellTemplateForUiGrid(col, col.transform, scope, scopeStr);
      setCellTranslationForUiGrid(col, col.transform, scope, scopeStr);

      delete col.transform;
      return col;
    }

    function setCellFormatForUiGrid (col, cfg) { // eslint-disable-line complexity
      var filter;
      var params;

      switch (cfg.type) {
        case 'date':
          col.configType = 'date';
          params = getDateFilterParams((cfg.format || col.configType), true);
          filter = "date : '" + params.format + "' : '" + params.tzOffset + "'";
          col.cellFilter = (col.cellFilter) ? col.cellFilter + ' | ' + filter : filter;
          break;
        case 'datetime':
          col.configType = 'datetime';
          params = getDateFilterParams((cfg.format || col.configType), true);
          filter = "date : '" + params.format + "' : '" + params.tzOffset + "'";
          col.cellFilter = (col.cellFilter) ? col.cellFilter + ' | ' + filter : filter;
          break;
        case 'icon':// No break;
        case 'material-icon':
          col.maxWidth = (col.maxWidth) ? col.maxWidth : 48;
          col.minWidth = (col.minWidth) ? col.minWidth : 24;
          col.configType = 'material-icon';
          if (!cfg.cellTemplate && cfg.translation) {
            col.sortCellFiltered = false;
            col.cellTemplate = '<div class="ui-grid-cell-contents" title="{{COL_FIELD}}" style="text-align: center;"><i class="material-icons">{{COL_FIELD CUSTOM_FILTERS}}</i></div>';
          }
          break;
        case 'image':
          col.maxWidth = (col.maxWidth) ? col.maxWidth : 60;
          col.minWidth = (col.minWidth) ? col.minWidth : 60;
          col.configType = 'image';
          if (!cfg.cellTemplate && cfg.translation) {
            col.sortCellFiltered = false;
            col.cellTemplate = '<div class="ui-grid-cell-contents" title="{{COL_FIELD}}" style="text-align: center;"><img ng-src="{{COL_FIELD CUSTOM_FILTERS}}" style="height:16px;" /></div>';
          }
          break;
        case 'json':
          switch (cfg.format) { // eslint-disable-line default-case
            case 'insurance-plan':
              col.configType = cfg.format;
              col.cellTemplate = '<div class="ui-grid-cell-contents"><vhr-med-ins-plan json="COL_FIELD"></vhr-med-ins-plan></div>';
              break;
            case 'insurance-plan-dates':
              col.configType = cfg.format;
              col.cellTemplate = '<div class="ui-grid-cell-contents"><vhr-med-ins-dates json="COL_FIELD"></vhr-med-ins-dates></div>';
              break;
            case 'insurance-plan-insured':
              col.configType = cfg.format;
              col.cellTemplate = '<div class="ui-grid-cell-contents"><vhr-med-ins-insured json="COL_FIELD"></vhr-med-ins-insured></div>';
              break;
            case 'insurance-plan-payer':
              col.configType = cfg.format;
              col.cellTemplate = '<div class="ui-grid-cell-contents"><vhr-med-ins-payer json="COL_FIELD"></vhr-med-ins-payer></div>';
              break;
          }
          break;
        case 'footnote':
          col.configType = 'footnote';
          if (!cfg.cellTemplate) {
            col.cellTemplate = '<div class="ui-grid-cell-contents" title="{{COL_FIELD}}"><a class="footnote-link" ng-click="grid.appScope.scrollToLocation(row, COL_FIELD)">{{COL_FIELD}}</a></div>';
          }
          break;
        case 'original-tooltip':
          col.configType = 'original-tooltip';
          if (!cfg.cellTemplate && cfg.translation) {
            col.cellTemplate = '<div class="ui-grid-cell-contents" title="{{COL_FIELD}}">{{COL_FIELD CUSTOM_FILTERS}}</div>';
          }
          break;
        default:// text
          col.configType = 'text';
          if (cfg.format) { col.cellFilter = cfg.format; }
          break;
      }
    }

    function setCellTemplateForUiGrid (col, cfg) {
      if (cfg.template) {
        col.cellTemplate = cfg.template;
      }
    }

    function setCellTranslationForUiGrid (col, cfg, scope, scopeStr) {
      var filter = 'vhrJsonDictionary';
      var filterParams = scopeStr + '.translation : ' + scopeStr + '.unknown : ' + scopeStr + '.path';
      var translation;

      if (cfg.translation) {
        if (typeof cfg.translation === 'string') {
          translation = service.raw[cfg.translation];
        }
        if (typeof cfg.translation === 'object') {
          translation = cfg.translation;
        }
        if (typeof translation === 'object') {
          if (cfg.translationKeys === 'regexp') {
            filter = 'vhrJsonDictionary';// TODO Implement regex keys.
          } else if (cfg.translationKeys === 'range') {
            filter = 'vhrJsonRange:' + filterParams;
          } else {
            filter = filter + ':' + filterParams;
          }

          scope.translation = translation;
          scope.unknown = cfg.unknown;
          scope.path = cfg.path;
          col.cellFilter = (col.cellFilter) ? filter + ' | ' + col.cellFilter : filter;
          col.sortCellFiltered = true;
        }
      }
    }

    function _loadConfig () {
      return imatConfig.loadConfigFile('vhr-app.json')
        .then(function (response) {
          service.raw = response || {};

          if (typeof service.raw.dates !== 'object') {
            service.raw.dates = {};
          }
          if (typeof service.raw.dates.date !== 'string') {
            service.raw.dates.date = 'mediumDate';
          }
          if (typeof service.raw.dates.datetime !== 'string') {
            service.raw.dates.datetime = 'medium';
          }
          // Example ages definition:
          // if (typeof service.raw.ages !== 'object') {
          //   service.raw.ages = {
          //     '18:54': {'label': 'Adult', 'badge': null},
          //     '55:': {'label': 'Senior', 'badge': 'star'},
          //     '2:17': {'label': 'Child', 'badge': 'star_half'},
          //     ':1': {'label': 'Infant', 'badge': 'star_border'},
          //   };
          // }
          if (typeof service.raw.originalDocumentAccess !== 'object') {
            service.raw.originalDocumentAccess = {};
          }
          if (!Array.isArray(service.raw.originalDocumentAccess.download)) {
            service.raw.originalDocumentAccess.download = [];
          }
          if (!Array.isArray(service.raw.originalDocumentAccess.view)) {
            service.raw.originalDocumentAccess.view = [];
          }
        });
    }

    // Converts the "new style" pages grids config properties into the
    // "old style" and moves the new style value to a .grids2 property.
    // This keeps the configuration file cleaner and allows the controllers
    // that understand the new format to have access to the information.
    // See PS-26 "Re-engineer the vhr-grids.json 'record_type' config property."
    function _downgradePagesGrids () {
      var pages = Object.keys(service.raw.pages);

      pages.forEach(function (page) {
        var grids;

        if (Object.prototype.hasOwnProperty.call(service.raw.pages[page], 'grids')) {
          grids = service.raw.pages[page].grids;

          if (angular.isArray(grids)) {
            service.raw.pages[page].grids2 = [];
            grids.forEach(function (grid, idx) {
              if (grid && Object.prototype.hasOwnProperty.call(grid, 'grid') && Object.prototype.hasOwnProperty.call(grid, 'source')) {
                service.raw.pages[page].grids2[idx] = angular.copy(grid);
                grids[idx] = grid.grid;
              }
            });
            if (!service.raw.pages[page].grids2.length) {
              delete service.raw.pages[page].grids2;
            }
          }
        }
      });
    }

    // Converts the "old style" pages grids config properties into the
    // "new style" and puts the new style value into a .grids2 property.
    // This keeps the configuration file cleaner and allows the controllers
    // that understand the new format to have access to the information.
    // See PS-26 "Re-engineer the vhr-grids.json 'record_type' config property."
    function _upgradePagesGrids () {
      var pages = Object.keys(service.raw.pages);

      pages.forEach(function (page) {
        var grids;

        if (Object.prototype.hasOwnProperty.call(service.raw.pages[page], 'grids') && !Object.prototype.hasOwnProperty.call(service.raw.pages[page], 'grids2')) {
          grids = service.raw.pages[page].grids;

          if (angular.isArray(grids)) {
            service.raw.pages[page].grids2 = [];
            grids.forEach(function (grid, idx) {
              var gridDef = service.raw.grids[grid];
              if (gridDef) {
                service.raw.pages[page].grids2[idx] = { grid: grid, source: (gridDef.recordType || gridDef.record_type || 'ALL').toUpperCase() };
              }
            });
          }
        }
      });
    }
  }
})();
