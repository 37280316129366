(function () {
  'use strict';

  angular.module('imat.fhir')
    .factory('fhirExtensionFactory', fhirExtensionFactory);

  fhirExtensionFactory.$inject = ['FHIR_EXTENSION_URLS', 'FHIR_EXTENSION_URLS_BC', 'FhirExtensionClass'];

  function fhirExtensionFactory (FHIR_EXTENSION_URLS, FHIR_EXTENSION_URLS_BC, FhirExtensionClass) {
    var service;

    service = {
      construct: construct
    };

    return service;

    function construct (data) {
      if (data == null) {
        return new FhirExtensionClass();
      }

      switch (data.url) {
        case FHIR_EXTENSION_URLS.US_CORE_ETHNICITY: // No break.
        case FHIR_EXTENSION_URLS.US_CORE_RACE: // No break.
        case FHIR_EXTENSION_URLS_BC.US_CORE_ETHNICITY_7_3: // No break.
        case FHIR_EXTENSION_URLS_BC.US_CORE_RACE_7_3:
          return getUsCoreCoding(data);
        default:
          return new FhirExtensionClass(data);
      }
    }

    function getUsCoreCoding (data) {
      function FhirExtensionUsCoreClass (xdata) {
        FhirExtensionClass.call(this, xdata);
      }
      FhirExtensionUsCoreClass.prototype = Object.create(FhirExtensionClass.prototype);
      FhirExtensionUsCoreClass.prototype.constructor = FhirExtensionUsCoreClass;
      FhirExtensionUsCoreClass.prototype.toJSON = function () {
        var json = FhirExtensionClass.prototype.toJSON.call(this);
        var subExt = this.extExtension('ombCategory');

        json.extension = [{
          url: subExt.url,
          valueCoding: subExt.valueCoding
        }];

        return json;
      };

      var ext = new FhirExtensionUsCoreClass(data);// FhirExtensionClass with new toJSON()

      if (ext.url === FHIR_EXTENSION_URLS_BC.US_CORE_ETHNICITY_7_3) {
        ext.url = FHIR_EXTENSION_URLS.US_CORE_ETHNICITY;
      }
      if (ext.url === FHIR_EXTENSION_URLS_BC.US_CORE_RACE_7_3) {
        ext.url = FHIR_EXTENSION_URLS.US_CORE_RACE;
      }

      return ext;
    }
  }
})();
