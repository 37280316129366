(function () {
  'use strict';
  angular
    .module('imat.directives')
    .directive('disallowSpaces', function () {
      return {
        restrict: 'A',

        link: function ($scope, $element) {
          $element.bind('input', function () {
            $(this).val($(this).val().replace(/ /g, '')); // eslint-disable-line no-undef, no-invalid-this
          });
        }
      };
    });
})();
