(function () {
  'use strict';

  angular
    .module('vhr')
    .controller('HieCommunitiesCtrl', HieCommunitiesCtrl);

  HieCommunitiesCtrl.$inject = [
    '$log', '$scope', '$state', '$q', 'vhrConfigSrv', 'vhrGridSrv', 'vhrHieApi', 'vhrPersistence', 'psNotification'
  ];

  function HieCommunitiesCtrl (
    $log, $scope, $state, $q, vhrConfigSrv, vhrGridSrv, vhrHieApi, vhrPersistence, psNotification
  ) {
    var vm = this;
    var gridSource = 'communities';

    // Messages
    var PURPOSE_OF_USE_ERROR = 'Error retrieving purpose of use.';
    var COMMUNITY_LIST_LOAD_FAILURE = 'Failed to load the community list.';

    // Properties
    vm.config = {};
    vm.disableBack = vhrConfigSrv.getHieAutoLookup();
    vm.gridApi = {};
    vm.gridConfig = {};
    vm.loaded = { api: false };
    vm.targetCommunities = $state.params.targetCommunities || vhrPersistence.getData('hieTargetCommunities') || [];

    // Methods

    activate();

    // Implementation

    function activate () {
      vm.config = vhrConfigSrv.getPageByState($state.current.name);

      // Catch refresh event thrown by hie.controller.js
      $scope.$on('vhrHieRefreshed', refresh);

      setGridConfig();

      vhrHieApi.getPurpose()
        .then(function () {
          loadGrid();
          vhrPersistence.setData('hieState', $state.current.name);
        })
        .catch(function () {
          psNotification.error(PURPOSE_OF_USE_ERROR);
        });
    }

    // ---------------------------------
    // Public interface
    // ---------------------------------

    vm.back = function () {
      $state.go('app.vhr.patient.hie.hie-search', null, { location: 'replace' });
      return false;
    };

    // ---------------------------------
    // Private interface
    // ---------------------------------

    function handleGridRowSelectionChanged (row) {
      var patientIds = row.entity.primaryId;

      if (Object.prototype.hasOwnProperty.call(row.entity, 'primaryAssignerOid')) {
        patientIds = row.entity.primaryAssignerOid + ':' + row.entity.primaryId;// MedFX
      }

      $state.go('app.vhr.patient.hie.hie-documents', {
        targetOid: row.entity.targetOid,
        community: row.entity.sourceOid,
        patient: patientIds
      });
    }

    function loadCommunities (force) {
      var fields = vm.targetCommunities.length ? { targetOid: vm.targetCommunities.join(',') } : {};

      return vhrHieApi.getPatient(fields, force)
        .then(function (response) {
          // Munge response...
          return response;
        });
    }

    function loadGrid (force) {
      vm.loaded.api = false;

      loadCommunities(force)
        .then(function (response) {
          vm.gridConfig.data = response;
        })
        .catch(function (reason) {
          reason = reason || COMMUNITY_LIST_LOAD_FAILURE;
          psNotification.error(reason);
        })
        .finally(function () {
          vm.loaded.api = true;
        });
    }

    function refresh () {
      vm.gridConfig.data.splice(0);
      loadGrid(true);
    }

    // For HIE grids, `gridType` TIES RECORDS TO SPECIFIC GRIDS.
    // If data is returned from the report inside a property whose name matches
    // a `source` value in a page's `grids` collection, then that data will be
    // tied to a grid prepared for that data. If the property name does not
    // match any configured `source` value, then its data will be ignored (it's
    // for a different HIE view).
    function setGridConfig () {
      var grids = vm.config.grids2.filter(function (g) { return g.source === gridSource; });// Should be only one.

      grids.forEach(function (grid) {
        var gridDef = vhrConfigSrv.raw.grids[grid.grid];
        var gridConfig = gridDef.uiGridConfig || {};
        var gridType = grid.source.toUpperCase();

        gridConfig = vhrGridSrv.getGridConfig($scope, gridType, vhrGridSrv.CONFIG.HIE, gridConfig, gridDef.fieldOrder);

        vm.gridConfig = angular.extend({}, gridConfig, {
          onRegisterApi: function (gridApi) {
            if (gridApi.selection) {
              gridApi.selection.on.rowSelectionChanged($scope, handleGridRowSelectionChanged);
            }
            vm.gridApi = gridApi;
          }
        });
      });
    }
  }
})();
