(function () {
  'use strict';

  angular.module('admin')
    .controller('DashboardAdminCtrl', DashboardAdminCtrl);

  DashboardAdminCtrl.$inject = [
    '$scope', '$state', 'DashboardsSrv'
  ];

  function DashboardAdminCtrl (
    $scope, $state, DashboardsSrv
  ) {
    var vm = this;
    var deregisterWatch;

    // Properties
    $scope.LEVELS = DashboardsSrv.LEVELS;

    activate();

    function activate () {
      deregisterWatch = $scope.$watch(function () { return $state.current.data.dashboardLevel; }, function (newVal) {
        vm.currentNavItem = newVal;
      });
    }

    $scope.$on('$destroy', function () {
      deregisterWatch();
    });
  }
})();
