(function () {
  'use strict';

  angular
    .module('vhr')
    .controller('PatientLookupCtrl', PatientLookupCtrl);

  PatientLookupCtrl.$inject = [
    'VHR_REPORT',
    '$filter', '$interval', '$location', '$log', '$mdDialog', '$mdSidenav', '$q', '$scope', '$state', '$timeout', '$window',
    'imatConfig', 'psNotification', 'psPhiAccess', 'psReports', 'vhrConfigSrv', 'vhrGridSrv',
    'vhrPatientSrv', 'vhrRecordCollectionSrv', 'vhrHieApi', 'vhrUserSrv', 'vhrPersistence'
  ];

  function PatientLookupCtrl (
    VHR_REPORT,
    $filter, $interval, $location, $log, $mdDialog, $mdSidenav, $q, $scope, $state, $timeout, $window,
    imatConfig, psNotification, psPhiAccess, psReports, vhrConfigSrv, vhrGridSrv,
    vhrPatientSrv, vhrRecordCollectionSrv, vhrHieApi, vhrUserSrv, vhrPersistence
  ) {
    var vm = this;
    var requiredFields = [];
    var simpleTemplateUrl = '/ui/vhr/lookup/lookup_simple.html';
    var complexTemplateUrl = '/ui/vhr/lookup/lookup_complex.html';
    var adminTemplateUrl = '/ui/vhr/lookup/lookup_admin.html';

    $scope.INPUT_TYPES = psReports.INPUT_TYPES;

    // Properties
    vm.autoLookup = false;
    vm.config = {};
    vm.fields = {};
    vm.generating = false;
    vm.gridApi = {};
    vm.gridConfig = {};
    vm.helpURL = imatConfig.get('apps.vhr.customerHelpLink');
    vm.hint = {};
    vm.inputsTemplateUrl = '/ui/vhr/lookup/lookup_inputs.html';
    vm.loaded = { config: false, report: false, reportData: false };
    vm.report = {};
    vm.reportData = [];
    vm.reportParams = {};
    vm.templateUrl = '';
    vm.workspace = $state.current.name;
    vm.error = false;

    // Methods
    vm.isSimpleDisplay = isSimpleDisplay;
    vm.isRequiredField = isRequiredField;
    vm.reset = reset;
    vm.submitAdminSearch = submitAdminSearch;
    vm.submitComplexSearch = submitComplexSearch;
    vm.submitSimpleSearch = submitSimpleSearch;
    vm.toggleNav = toggleNav;
    vm.newSearch = newSearch;

    activate();

    // Implementation

    function activate () {
      vm.config = vhrConfigSrv.getPageByState($state.current.name);

      if (vm.config.grids && vm.config.grids.length && Object.prototype.hasOwnProperty.call(vhrConfigSrv.raw.grids, vm.config.grids[0])) {
        setGridConfig();
      } else {
        setDefaultGridConfig();
      }

      vm.loaded.config = true;

      loadReport()
        .then(function () {
          resetForm();
          checkAutoLookup();
        })
        .catch(function (reason) {
          reason = reason || 'There was a problem preparing or performing the search.';
          psNotification.error(reason);
        })
        .finally(function () {
          if (!vm.autoLookup) {
            vm.loaded.report = true;
          }
        });

      if (vhrRecordCollectionSrv.construct('printQueue').selected().length > 0) {
        $mdDialog.show({
          controller: 'UnprintedRecordModalCtrl',
          controllerAs: 'modal',
          templateUrl: '/ui/vhr/lookup/unprinted-record-modal.html',
          parent: angular.element(document.body),
          clickOutsideToClose: true,
          fullscreen: true
        })
          .then(function () {
            $state.go('app.vhr.patient.print');
          }, function () {
          // Cancel
          })
          .finally(function () {
          // ...
          });
      }

      reset();
    }

    // ---------------------------------
    // Public interface
    // ---------------------------------

    function isSimpleDisplay () {
      return vm.templateUrl.indexOf(simpleTemplateUrl) >= 0;
    }

    function isRequiredField (fieldName) {
      return requiredFields.indexOf(fieldName) >= 0;
    }

    function submitAdminSearch () {
      var searchParams = getSearchParams();

      if (!hasAnyNonEmptyField(searchParams)) {
        psNotification.error('Invalid search: at least one field must contain a value.');
        return;
      }

      search(searchParams);
      scrollToElement('.page-content', '#lookup-grid');
    }

    function submitComplexSearch () {
      var searchParams = getSearchParams();

      if (!hasAllRequiredFields(searchParams, vm.config.formRequirements)) {
        psNotification.error('Invalid search: required fields are missing values.');
        return;
      }

      search(searchParams);
      scrollToElement('.page-content', '#lookup-grid');
    }

    function submitSimpleSearch () {
      var searchParams = getSearchParams();

      if (!requiredFields.length && !hasAnyNonEmptyField(searchParams)) {
        psNotification.error('Invalid search: at least one field must contain a value.');
        return;
      }

      search(searchParams);
      scrollToElement('.page-content', '#lookup-grid');
    }

    function toggleNav () {
      $mdSidenav('sidenav').toggle();
    }

    // ---------------------------------
    // Private interface
    // ---------------------------------

    function checkAutoLookup () {
      var autoLookup = !!Object.keys($location.search()).length;
      var fieldReqs;
      var searchParams;

      if (autoLookup) {
        searchParams = getSearchParamsFromUrl();
        fieldReqs = mergeFieldRequirements(vm.config.paramRequirements);
        vm.config.paramRequirements = fieldReqs.config;
        if (hasAllRequiredFields(searchParams, vm.config.paramRequirements)) {
          vm.autoLookup = true;
          search(searchParams);
        } else {
          psNotification.error('Redirect failed: one or more required values are missing.');
        }
      }
    }

    function viewPatient (mpid) {
      var page = getUserLandingPage();
      var patient = $filter('filter')(vm.reportData, { mpid: mpid }, true).pop();

      if (mpid !== vhrPatientSrv.getMpid()) {
        psPhiAccess.clearConsent();
        vhrHieApi.resetHieResultsCount();
        vhrPatientSrv.set(patient || {});
        vhrPersistence.reset();
        vhrRecordCollectionSrv.reset();

        psReports.reset(VHR_REPORT.CALLER, true);
        // Hack to accommodate the caller hack in RecordCollectionCtrl#loadReport
        // that allows us to re-use a report for different record types. An
        // alternative solution is to make psReports reset() its entire cache of
        // reports. Having psReports do cache-by-caller was probably good ol'
        // over-engineering.
        angular.forEach(VHR_REPORT.RECORD_TYPE, function (recordType) {
          psReports.reset(VHR_REPORT.CALLER + '-' + recordType, true);
        });
      }

      $state.go(page, null, { location: 'replace' });
    }

    function getUserLandingPage () {
      var page = null;
      angular.forEach(vhrUserSrv.getGroups(), function (group) {
        if (!page) {
          page = vhrConfigSrv.getLandingPage(group);
        }
      });
      if (!page) {
        throw new Error('User has no groups with an associated landing page.');
      }
      return page;
    }

    function formatSearchCriteria (uiParams) {
      var params = {};

      angular.forEach(uiParams, function (val, key) {
        params[key] = val;

        if (angular.isDate(val) || /^(date|datetime|time)_|_(date|datetime|time)_|_(date|datetime|time)$/.test(key)) {
          params[key] = vhrConfigSrv.getIsoDate(val) || null;
        }
      });
      return params;
    }

    function formatSearchResults (hitlist) {
      var uiHitlist = hitlist;
      var raw = vhrPatientSrv.raw;

      if (vm.gridConfig.isDefaultConfig) {
        uiHitlist = [];
        hitlist.forEach(function (row) {
          vhrPatientSrv.set(row);
          uiHitlist.push({
            mpid: row.mpid,
            name: vhrPatientSrv.getFullName(),
            date_of_birth: vhrPatientSrv.getDateOfBirth(),
            gender: vhrPatientSrv.getGender(),
            phone_number: vhrPatientSrv.getPhone(),
            address: row.address
          });
        });
        vhrPatientSrv.set(raw);
      }
      return uiHitlist;
    }

    function getSearchParamsFromUrl () {
      var fields = {};
      var params = $location.search();

      angular.forEach(vm.reportParams, function (param, paramName) {
        fields[paramName] = (Object.prototype.hasOwnProperty.call(params, paramName) ? params[paramName] : param);
      });

      return formatSearchCriteria(fields);
    }

    function getSearchParams () {
      var fields = {};

      angular.forEach(vm.reportParams, function (param, paramName) {
        fields[paramName] = vm.fields[paramName].value;
      });

      return formatSearchCriteria(fields);
    }

    function hasAnyNonEmptyField (params) {
      var fields = vm.report.getParameterDefaults();// Set up fields that look like a new/blank form.

      // Do the same transformations as resetForm().
      angular.forEach(fields, function (field, fieldName) {
        if (vm.fields[fieldName].default === '' && vm.fields[fieldName].inputType === psReports.INPUT_TYPES.SELECT) {
          fields[fieldName] = null;
        }
        if (field === null && params[fieldName] === '') {
          fields[fieldName] = '';
        }
      });

      return !angular.equals(params, fields);
    }

    function hasAllRequiredFields (params, requirements) {
      // Check if values exist for all of the fields specified in any one requirement group.
      for (var i = requirements.length - 1; i >= 0; --i) {
        if (requirements[i].reduce(countMissingFields, 0) === 0) {
          return true;
        }
      }

      return requirements.length === 0;

      function countMissingFields (numMissing, fieldName) {
        var field = vm.fields[fieldName];
        var fieldVal = params[fieldName];

        return (
          (fieldVal) ||// truthy values are always legit
          (field.choices && field.choices.indexOf(fieldVal) >= 0) ||// choices values are always legit
          (field.type === psReports.PARAM_TYPES.BOOL) ||// booleans are always legit
          (field.type === psReports.PARAM_TYPES.INT && fieldVal === 0) ||// integer zero is sometimes legit
          (field.inputType !== psReports.INPUT_TYPES.TEXT && fieldVal === field.default) // default values are usually legit
        ) ? numMissing : numMissing + 1;
      }
    }

    function loadReportData (params) {
      if (vm.config.parameters) {
        angular.forEach(vm.config.parameters, function (param, paramName) {
          if (typeof params[paramName] === 'undefined') {
            $log.debug('The page configuration is trying to set an unexpected parameter: ' + paramName);
            return;// continue
          }
          params[paramName] = param;
        });
      }

      return vm.report.run(vm.workspace, params, true)
        .then(function () {
          return vm.report.results[vm.workspace].getPollPromise();
        })
        .catch(function (reason) {
          vm.loaded.report = true;
          $state.reload();
          $log.debug(reason);
          return $q.reject('Failed to load the report.');
        });
    }

    function reset (form) {
      vm.fields = (vm.report.getParameters ? vm.report.getParameters(true) : {});
      vm.generating = false;
      vm.gridConfig.data = [];
      vm.loaded.reportData = false;
      vm.reportData = [];
      vm.reportParams = (vm.report.getParameters ? vm.report.getParameters() : {});
      // *Clear* fields.
      angular.forEach(vm.fields, function (f) {
        f.value = null;
      });
      if (form) {
        form.$setUntouched();
        form.$setPristine();
      }
    }

    function scrollToElement (scroller, scrollTo, speed, interval) {
      var iterations = speed || 70;
      var amount = 0;

      // Fallbacks
      iterations = iterations < 100 ? 100 - iterations : 1;
      interval = interval || 30;

      $interval(function () {
        amount = amount + Math.floor(document.querySelector(scrollTo).offsetTop / iterations);
        document.querySelector(scroller).scrollTop = amount;
      }, interval, iterations);
    }

    function search (params) {
      vm.generating = true;
      vm.gridConfig.data.splice(0);
      vm.loaded.reportData = false;
      loadReportData(params)
        .then(function () {
          return loadDataArtifact();
        })
        .then(function () {
          vm.loaded.reportData = true;

          if (vm.autoLookup && vm.reportData.length !== 1) {
            reset();
            resetForm();
            psNotification.error('Redirect failed: could not find exactly one patient.');
          }
          vm.loaded.report = true;
          vm.autoLookup = false;
        })
        .catch(function (reason) {
          vm.loaded.reportData = true;
          psNotification.error(reason);
        })
        .finally(function () {
          vm.generating = false;
        });
    }

    function getDefaultGridConfig () {
      return {
        columnDefs: [
          {
            field: 'name',
            width: 350
          }, {
            field: 'gender',
            width: 150
          }, {
            field: 'date_of_birth',
            cellTemplate: '<div class="ui-grid-cell-contents"><vhr-date date="COL_FIELD" format="date"></vhr-date></div>',
            width: 150
          }, {
            field: 'phone_number',
            width: 150
          }, {
            field: 'address'
          }
        ]
      };
    }

    function setGridConfig () {
      // There can be only one grid on this page and there should be only one
      // in the list. Regardless, we loop here because the grids property is
      // always an array and because it keeps controllers similar.
      vm.config.grids.forEach(function (grid, idx) {
        var gridDef = vhrConfigSrv.raw.grids[grid] || { fieldOrder: [] };
        var gridConfig = gridDef.uiGridConfig || {};
        var gridType = '' + idx;// Grid configs create a controller property to store data; this is part of that.

        vm.gridConfig = { isDefaultConfig: false };

        gridConfig = vhrGridSrv.getGridConfig($scope, gridType, vhrGridSrv.CONFIG.PATIENT_LIST, gridConfig, gridDef.fieldOrder);
        angular.extend(vm.gridConfig, gridConfig);

        vm.gridConfig.onRegisterApi = function (gridApi) {
          gridApi.selection.on.rowSelectionChanged($scope, function (row) {
            viewPatient(row.entity.mpid);
          });
          vm.gridApi = gridApi;
        };
      });
    }

    function setDefaultGridConfig () {
      var gridDef = { fieldOrder: [] };
      var gridConfig = getDefaultGridConfig();
      var gridType = 'PATIENT_SEARCH';// Grid configs create a controller property to store data; this is part of that.

      vm.gridConfig = { isDefaultConfig: true };

      gridConfig = vhrGridSrv.getGridConfig($scope, gridType, vhrGridSrv.CONFIG.PATIENT_LIST, gridConfig, gridDef.fieldOrder);
      angular.extend(vm.gridConfig, gridConfig);

      vm.gridConfig.onRegisterApi = function (gridApi) {
        gridApi.selection.on.rowSelectionChanged($scope, function (row) {
          viewPatient(row.entity.mpid);
        });
        vm.gridApi = gridApi;
      };
    }

    function loadDataArtifact () {
      var artifactName = 'results.json';

      return vm.report.getArtifact(vm.workspace, artifactName)
        .then(function (response) {
          vm.reportData = response || [];
          vm.gridConfig.data = formatSearchResults(vm.reportData);
          if (vm.gridApi.core) {
            vm.gridApi.core.handleWindowResize();
          }
          if (response.length === 1) {
            viewPatient(response[0].mpid);
          }
        })
        .catch(function (reason) {
          $state.reload();
          $log.debug(reason);
          return $q.reject('Failed to load the result list.');
        });
    }

    function loadReport () {
      return psReports.load(vm.config.reportId, VHR_REPORT.CALLER, { initParams: vhrConfigSrv.getReportParameters(vm.config.reportId) })
        .then(function (report) {
          vm.report = report;
          vm.reportParams = report.getParameters();
        })
        .catch(function (reason) {
          $log.debug(reason);
          return $q.reject('Failed to prepare the report.');
        });
    }

    function newSearch () {
      $state.reload();
    }

    function mergeFieldRequirements (requirements) {
      var fields = vm.report.getParameterDefaults(true);
      var allRequiredFields = [];
      var reportRequiredFields = [];

      angular.forEach(fields, function (fieldDef, fieldName) {
        if (!fieldDef.optional) {
          reportRequiredFields.push(fieldName);
        }
      });

      allRequiredFields = angular.copy(reportRequiredFields);

      if (!angular.isArray(requirements)) {
        requirements = [reportRequiredFields];
      } else {
        requirements.forEach(function (fieldGroup, gidx) {
          fieldGroup.forEach(function (fieldName, fidx) {
            if (!Object.prototype.hasOwnProperty.call(fields, fieldName)) {
              fieldGroup[fidx] = null;// ...to be removed.
            } else if (allRequiredFields.indexOf(fieldName) < 0) {
              allRequiredFields.push(fieldName);
            }
          });

          // Remove all fields that are not report parameters.
          fieldGroup = fieldGroup.filter(function (fieldName) { return !!fieldName; });

          // The report trumps the config, so add report-required fields to all groups.
          if (fieldGroup.length && reportRequiredFields.length) {
            reportRequiredFields.forEach(function (fieldName) {
              if (fieldGroup.indexOf(fieldName) < 0) {
                fieldGroup.push(fieldName);
              }
            });
          }

          requirements[gidx] = fieldGroup;
        });

        // Remove empty groups.
        requirements = requirements.filter(function (fieldGroup) { return !!fieldGroup.length; });
      }

      // Remove all fields that are defined in the page config: they will be added later.
      angular.forEach(vm.config.parameters, function (param, paramName) {
        var allIdx = allRequiredFields.indexOf(paramName);
        var repIdx = reportRequiredFields.indexOf(paramName);

        if (allIdx >= 0) { allRequiredFields.splice(allIdx, 1); }
        if (repIdx >= 0) { reportRequiredFields.splice(repIdx, 1); }
      });

      return { config: requirements, merged: allRequiredFields, report: reportRequiredFields };
    }

    function resetForm () {
      var fieldReqs = mergeFieldRequirements(vm.config.formRequirements);

      requiredFields = fieldReqs.merged;
      vm.config.formRequirements = fieldReqs.config;
      vm.fields = vm.report.getParameterDefaults(true);

      if (!fieldReqs.report.length && vhrUserSrv.isAdmin()) {
        vm.templateUrl = adminTemplateUrl;
      } else {
        vm.templateUrl = (vm.config.formRequirements.length > 1)
          ? complexTemplateUrl
          : simpleTemplateUrl;
      }

      // -------------------------------
      // Arrangement
      // -------------------------------

      if (!angular.isArray(vm.config.formArrangement) || !vm.config.formArrangement.length) {
        // If we don't have an arrangement, lay out the form linearly down the page :-(
        vm.config.formArrangement = Object.keys(vm.reportParams);
        vm.config.formArrangement = vm.config.formArrangement.map(function (paramName) { return [paramName]; });
      } else {
        vm.config.formArrangement.forEach(function (fieldGroup, gidx) {
          fieldGroup.forEach(function (fieldName, fidx) {
            if (!Object.prototype.hasOwnProperty.call(vm.fields, fieldName)) {
              fieldGroup[fidx] = null;// ...to be removed.
            } else if (vm.fields[fieldName].hidden) {
              fieldGroup[fidx] = null;// ...to be removed.
            }
          });

          // Remove all fields that are not report parameters or that are designated hidden.
          vm.config.formArrangement[gidx] = fieldGroup.filter(function (fieldName) { return !!fieldName; });
        });

        // Remove empty rows.
        vm.config.formArrangement = vm.config.formArrangement.filter(function (fieldGroup) { return !!fieldGroup.length; });
      }

      // Remove all fields that are defined in the page config.
      angular.forEach(vm.config.parameters, function (param, paramName) {
        vm.config.formArrangement.forEach(function (fieldGroup, gidx) {
          fieldGroup.forEach(function (fieldName, fidx) {
            if (paramName === fieldName) {
              fieldGroup[fidx] = null;
            }
          });
          vm.config.formArrangement[gidx] = fieldGroup.filter(function (fieldName) { return !!fieldName; });
        });
      });

      // A select-style parameter with a default value of empty string will
      // display weirdly (seemingly simultaneously "selected and empty") so we
      // reset the value to null to force the select to be clearly unselected.
      vm.config.formArrangement.forEach(function (fieldGroup) {
        fieldGroup.forEach(function (fieldName) {
          if (vm.fields[fieldName].default === '' && vm.fields[fieldName].inputType === psReports.INPUT_TYPES.SELECT) {
            vm.fields[fieldName].value = null;
          }
        });
      });
    }
  }
})();
