(function () {
  'use strict';

  angular
    .module('vhr.services')
    .factory('vhrUserSrv', vhrUserSrv);

  vhrUserSrv.$inject = ['VHR_ADMIN_GROUP', '$log', '$q', 'userSession', 'vhrConfigSrv'];

  function vhrUserSrv (VHR_ADMIN_GROUP, $log, $q, userSession, vhrConfigSrv) {
    var service;
    var groups = [];

    service = {
      raw: {}, // XXX Make private and remove references.

      canDownloadOriginalDocuments: canDownloadOriginalDocuments,
      canViewOriginalDocuments: canViewOriginalDocuments,
      getDisplayName: getDisplayName,
      getGroups: getGroups,
      getProvider: getProvider,
      getUsername: getUsername,
      isAdmin: isAdmin,
      load: load
    };

    return service;

    function canDownloadOriginalDocuments () {
      return _canAccessOriginalDocuments(vhrConfigSrv.getOriginalDocumentAccess().download);
    }

    function canViewOriginalDocuments () {
      return _canAccessOriginalDocuments(vhrConfigSrv.getOriginalDocumentAccess().view);
    }

    function getGroups () {
      return groups;
    }

    function getDisplayName () {
      return service.raw.name || '';
    }

    function getProvider () {
      return service.raw.provider || 'default';
    }

    function getUsername () {
      return service.raw.username || '';
    }

    function isAdmin () {
      return (groups.indexOf(VHR_ADMIN_GROUP) >= 0);
    }

    function load () {
      return _getUserProfile()
        .then(function (response) {
          service.raw = response;
          groups = response.groups;
        });
    }

    function _getUserProfile () {
      if (userSession.getUserData().username) {
        return $q.resolve(userSession.getUserData());
      }
      return userSession.load()
        .then(function () {
          return userSession.getUserData();
        })
        .catch(function () {
          $log.debug('Loading the user account failed.');
          return {};
        });
    }

    function _canAccessOriginalDocuments (access) {
      if (!access.length) {
        return true;
      }

      for (var i = 0; i < groups.length; ++i) {
        if (access.indexOf(groups[i]) >= 0) {
          return true;
        }
      }
      return false;
    }
  }
})();
