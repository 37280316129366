(function () {
  'use strict';

  angular.module('imat.fhir')
    .factory('imatFhirSrv', imatFhirSrv);

  imatFhirSrv.$inject = ['$q', 'fhirApiSrv', 'fhirResourceSrv'];

  function imatFhirSrv ($q, fhirApiSrv, fhirResourceSrv) {
    var service;

    service = {
      getPotentialMpid: getPotentialMpid
    };

    return service;

    function getPotentialMpid (fhirPatient, mpiSystemId) {
      if (!fhirPatient) {
        return $q.reject();
      }

      return fhirApiSrv.getPotentialMpid(fhirPatient)
        .then(function (response) {
          var identifiers;
          var mpid;
          var system;
          var validJson;

          if (Object.prototype.hasOwnProperty.call(response.data.mpi, 'lookup')) {
            // The PI entry will be attached to a new persona that will be created for it.
            return 0;
          }

          validJson = fhirResourceSrv.getValidPatientJson(response.data.mpi.Patient);
          identifiers = (validJson ? validJson.identifier : []);

          for (var i = 0, ii = identifiers.length; i < ii; ++i) {
            mpid = identifiers[i].value;
            system = identifiers[i].system;
            if (mpid && system && system === mpiSystemId) {
              // The PI entry will be attached to the identified persona.
              return parseInt(mpid, 10);
            }
          }

          // Unparseable response...
          return $q.reject();
        })
        .catch(function (error) {
          // The PI entry is too thin to attempt matching (or some other error).
          return (error.status && error.status === 422 ? -1 : $q.reject(error));
        });
    }
  }
})();
