(function () {
  'use strict';

  angular
    .module('tools')
    .controller('PlatformStatusCtrl', PlatformStatusCtrl);

  PlatformStatusCtrl.$inject = ['releaseSrv'];

  function PlatformStatusCtrl (releaseSrv) {
    var vm = this;

    vm.loading = false;
    vm.filters = [
      { name: 'Status: Problem', key: 'matching', value: false }
    ];
    vm.searchText = '';
    vm.selectedFilter = '';

    vm.clearFilters = clearFilters;
    vm.filterList = filterList;
    vm.loadComponents = loadComponents;

    activate();

    function activate () {
      loadComponents(false);
    }

    function clearFilters () {
      vm.searchText = '';
      vm.selectedFilter = '';
    }

    function filterList (value) {
      return (vm.selectedFilter) ? value[vm.selectedFilter.key] === vm.selectedFilter.value : true;
    }

    function loadComponents (force) {
      vm.loading = true;
      releaseSrv.getComponents(force).then(function (resp) {
        vm.components = resp;
      }).finally(function () {
        vm.loading = false;
      });
    }
  }
})();
