(function () {
  'use strict';

  angular
    .module('imat.services')
    .factory('groupsApiSrv', groupsApiSrv);

  groupsApiSrv.$inject = ['$http'];

  function groupsApiSrv ($http) {
    var baseUrl = '/appliance/groups';
    var Group = function (params) {
      params = params || {};
      [
        'modifiable',
        'removable',
        'membership_modifiable'
      ].forEach(function (key) {
        if (params[key]) {
          params[key] = params[key] === 'true';
        }
      });
      angular.extend(this, {
        name: '',
        provider: '',
        description: ''
      }, params);
    };

    var service = {
      createGroup: createGroup,
      deleteGroup: deleteGroup,
      getGroup: getGroup,
      getList: getList,
      getUsers: getUsers,
      Group: Group,
      updateGroup: updateGroup,
      updateUsers: updateUsers,
      updateMembers: updateMembers
    };

    function createGroup (group) {
      var url = [baseUrl, group.provider].join('/');
      return $http({
        method: 'POST',
        url: url,
        data: {
          name: group.name,
          description: group.description
        },
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
        .then(function () {
          return get(group.provider, group.name);
        });
    }

    function deleteGroup (provider, name, config) {
      var url = [baseUrl, provider, name].join('/');
      return $http.delete(url, angular.extend({}, config || {}));
    }

    function get (provider, name) {
      var url = [baseUrl, provider, name].join('/');

      return $http({
        method: 'GET',
        url: url,
        cache: false
      })
        .then(function (res) {
          var instance = new Group(res.data.group);
          instance.name = name;
          instance.provider = provider;
          return instance;
        });
    }

    function getGroup (provider, name, config) {
      var url = [baseUrl, provider, name].join('/');

      return $http.get(url, angular.extend({}, config || {}));
    }

    // Update the member list atomically.

    function updateMembers (group, cmds) {
      var url = [baseUrl, group.provider, group.name, 'members'].join('/');

      return $http({
        method: 'PUT',
        url: url,
        data: cmds.join('&'),
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      })
        .then(function (response) {
          return response.data;
        });
    }

    function getList (provider, config) {
      var url = [baseUrl, provider].join('/');
      var defaultConfig = {
        params: {
          group: '',
          limit: 12000,
          order: 'asc',
          start: 0
        }
      };
      return $http.get(url, angular.extend(defaultConfig, config || {}));
    }

    function getUsers (provider, name, config) {
      // Returns entity:
      // {
      //   count: Int,
      //   total: Int,
      //   members: []
      // }
      return (new Group({ provider: provider, name: name })).getMembers(config);
    }

    Group.prototype.getMembers = function (params) {
      var url = [baseUrl, this.provider, this.name, 'members'].join('/');

      return $http({
        method: 'GET',
        url: url,
        params: params,
        cache: false
      })
        .then(function (res) {
          var collection = res.data.members.member || [];
          collection = angular.isArray(collection) ? collection : [collection];

          collection = collection.map(function (values) {
            return {
              name: values.__text,
              type: values._type
            };
          });

          return {
            count: parseInt(res.data.members._count, 10),
            total: parseInt(res.data.members._total, 10),
            members: collection
          };
        });
    };

    function updateGroup (description, provider, name, config) {
      var url = [baseUrl, provider, name].join('/');
      var data = { description: description };
      var defaultConfig = {
        headers: {
          Accept: undefined,
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      };
      return $http.put(url, data, angular.extend(defaultConfig, config || {}));
    }

    function updateUsers (group, cmds) {
      return updateMembers(group, cmds)
        .then(function () {
          return getGroup(group.provider, group.name);
        });
    }

    return service;
  }
})();
