(function () {
  'use strict';

  angular.module('dashboardApp')
    .controller('DashboardEditCtrl', DashboardEditCtrl);

  DashboardEditCtrl.$inject = [
    'DASHBOARD',
    '$q', '$mdDialog', '$scope', '$state', '$transition$', '$window',
    'DashboardItemClass', 'DashboardItemsSrv', 'DashboardsSrv', 'imatConfig', 'Preferences', 'psNotification', 'userSession', 'UsersSrv'
  ];

  function DashboardEditCtrl (
    DASHBOARD,
    $q, $mdDialog, $scope, $state, $transition$, $window,
    DashboardItemClass, DashboardItemsSrv, DashboardsSrv, imatConfig, Preferences, psNotification, userSession, UsersSrv
  ) {
    var vm = this;
    var MSG_LOAD_ERROR = 'There was a problem loading the dashboard, please try again later. If the problem persists, please contact your system administrator.';
    var MSG_LOAD_ERROR_ADMIN = 'There was a problem loading the dashboard, please try again later.';
    var STATE_ADMIN = $state.$current.name === 'app.dashboard.admin';

    $scope.sortableLinkOptions = {
      containment: 'parent',
      handle: '.item-handle',
      start: sortableStart,
      stop: sortableStop,
      tolerance: 'pointer',
      'ui-floating': false
    };
    $scope.sortableTileOptions = {
      containment: 'parent',
      tolerance: 'pointer',
      'ui-floating': 'auto'
    };

    // Properties
    vm.ACCESS = DashboardsSrv.ACCESS;
    vm.CATEGORY = DashboardItemClass.prototype.CATEGORY;
    vm.IMAGE_TYPES = DashboardItemClass.prototype.IMAGE;
    vm.LEVELS = DashboardsSrv.LEVELS;
    $scope.$parent.banner = {};
    vm.dashboard = DashboardsSrv.construct();
    $scope.$parent.dashboardId = null;
    vm.dashboardItems = { links: [], tiles: [] };
    $scope.$parent.dashboardList = [];
    $scope.$parent.exclusiveId = null;
    $scope.$parent.helpURL = imatConfig.get('apps.dashboard.customerHelpLink');
    $scope.$parent.isAdmin = STATE_ADMIN;
    vm.isLoading = true;
    vm.itemFilter = { items: [] };
    vm.itemSequence = { items: [] };
    vm.owner = null;
    vm.ownerDisplayName = '';
    vm.user = userSession.getUserData();

    // Methods
    $scope.$parent.attachItem = attachItem;
    $scope.$parent.cancelEdit = cancelEdit;
    vm.configureItem = configureItem;
    vm.detachItem = detachItem;
    vm.excludeItem = excludeItem;
    $scope.$parent.excludeItems = excludeItems;
    $scope.$parent.saveDashboard = saveDashboard;
    vm.setItemInvisible = setItemInvisible;
    vm.setItemVisible = setItemVisible;
    vm.showItemProvenance = showItemProvenance;

    activate();

    function activate () {
      var dashboardId = $transition$.params().dashboardId || '0';

      $scope.$parent.dashboardId = dashboardId;
      vm.isLoading = true;

      DashboardsSrv.getDashboard(dashboardId)
        .then(function (dashboard) {
          vm.dashboard = angular.copy(dashboard);// Do not alter the service cache version until saved.

          /* jslint bitwise: true */
          vm.canEdit = STATE_ADMIN || (
            (vm.dashboard.owner.toLowerCase() === vm.user.systemName.toLowerCase() ) &&
          (vm.dashboard.effective_permissions & vm.ACCESS.WRITE) == vm.ACCESS.WRITE // eslint-disable-line eqeqeq
          );
          /* jslint bitwise: false */

          if (!vm.canEdit) {
            cancelEdit();
            return $q.reject();
          }

          if (STATE_ADMIN) {
            showBannerForEmulation(vm.dashboard);
          }

          if (vm.dashboard.level !== DashboardsSrv.LEVELS.USER) {
            return [vm.dashboard];
          }

          if (vm.dashboard.owner.toLowerCase() === vm.user.systemName.toLowerCase()) {
            vm.owner = angular.copy(vm.user);
            return getDashboardList(vm.dashboard, vm.owner.groups, STATE_ADMIN);
          }

          return getDashboardOwner(vm.dashboard.owner)
            .then(function (owner) {
              vm.owner = owner;
              return getDashboardList(vm.dashboard, vm.owner.groups, STATE_ADMIN);
            });
        })
        .then(function (dashboardList) {
          if (!dashboardList.length) {
            return $q.reject();
          }
          $scope.$parent.dashboardList = dashboardList;

          return getDashboardItems(dashboardList);
        })
        .then(function (items) {
          items.forEach(function (item) {
            if ([vm.CATEGORY.PARAMETERIZED, vm.CATEGORY.CR_REPORT, vm.CATEGORY.CR_RESULT].indexOf(item.cfg.category) >= 0) {
              vm.dashboardItems.tiles.push(item);
            } else {
              vm.dashboardItems.tiles.push(item);
            }
          });
          $scope.$parent.dashboardList[0].tiles.length += 1;// Ensure that the top dashboard is always accessible.
          getDashboardPreference();
        })
        .catch(function () {
          $scope.$parent.banner.type = 'md-warn';
          $scope.$parent.banner.icon = 'warning';
          $scope.$parent.banner.message = MSG_LOAD_ERROR;

          if (STATE_ADMIN) {
            $scope.$parent.banner.message = MSG_LOAD_ERROR_ADMIN;
            $scope.$parent.banner.link = {
              location: $state.href('app.admin.dashboards'),
              text: 'Return to Dashboard Admin'
            };
          }
          return $q.reject();
        })
        .finally(function () {
          vm.isLoading = false;
        });
    }

    //= ================================
    // Public interface
    //= ================================

    function attachItem (ev) {
      var modalInternalItems = [].concat(vm.dashboardItems.links, vm.dashboardItems.tiles);

      modalInternalItems = modalInternalItems.filter(function (i) { return i.cfg.category === 'internal' && i.ui.ref.id === $scope.$parent.exclusiveId; });
      modalInternalItems = modalInternalItems.map(function (i) { return i.link.href; });

      $mdDialog.show({
        controller: 'AddDashboardItemModalCtrl as modal',
        templateUrl: '/ui/dashboard/modals/addDashboardItemModal.html',
        locals: { modalInternalItems: modalInternalItems, modalUserRoles: vm.user.roles },
        clickOutsideToClose: true,
        targetEvent: ev
      })
        .then(function (modalItem) {
          DashboardItemsSrv.loadItem(modalItem)
            .then(function (mwItem) {
              mwItem.error = mwItem.checkHasError();

              mwItem.ui.ref = vm.dashboard;
              mwItem.ui.refs = [vm.dashboard];
              mwItem.ui.errs = [mwItem.error];
              mwItem.ui.id = getItemId(mwItem);

              mwItem.ui.filtered = false;
              mwItem.ui.inherited = false;
              mwItem.ui.owned = true;

              if ([vm.CATEGORY.PARAMETERIZED, vm.CATEGORY.CR_REPORT, vm.CATEGORY.CR_RESULT].indexOf(mwItem.cfg.category) >= 0) {
                vm.dashboardItems.tiles.push(mwItem);
              } else {
                vm.dashboardItems.tiles.unshift(mwItem);
                vm.dashboardItems.tiles = vm.dashboardItems.tiles.filter(removeDuplicateItems);
              }
            });
        })
        .catch(function () {
        // Dismissed.
        });
    }

    function cancelEdit () {
      $window.history.back();
    }

    function configureItem (ev, item) {
      var ctrl;
      var tmpl;

      switch (item.cfg.category) {
        case vm.CATEGORY.EXTERNAL:
          ctrl = 'ItemLinkConfigCtrl';
          tmpl = '/ui/dashboard/items/config/external/config.html';
          break;
        case vm.CATEGORY.INTERNAL:
          ctrl = 'ItemLinkProductConfigCtrl';
          tmpl = '/ui/dashboard/items/config/internal/config.html';
          break;
        case vm.CATEGORY.PARAMETERIZED:
          ctrl = 'ItemParameterizedConfigCtrl';
          tmpl = '/ui/dashboard/items/config/parameterized/config.html';
          break;
        case vm.CATEGORY.CR_REPORT:
          ctrl = 'ItemLinkReportsConfigCtrl';
          tmpl = '/ui/dashboard/items/config/crReport/config.html';
          break;
        case vm.CATEGORY.CR_RESULT:
          ctrl = 'ItemLinkResultsConfigCtrl';
          tmpl = '/ui/dashboard/items/config/crResult/config.html';
          break;
        default:
          tmpl = '/ui/dashboard/items/config/error/config.html';
          break;
      }
      $mdDialog.show({
        controller: ctrl,
        controllerAs: 'modal',
        templateUrl: tmpl,
        locals: { modalDashboard: null, modalItem: angular.copy(item) },
        clickOutsideToClose: false,
        targetEvent: ev
      })
        .then(function (modalItem) {
          angular.extend(item, modalItem);
          item.error = item.checkHasError();
          item.ui.errs[0] = item.error;
          item.ui.id = getItemId(item);
        })
        .catch(function () {
        // Dismissed.
        });
    }

    function detachItem (item, key) {
      // var item = vm.dashboardItems[key][index];
      var index = vm.dashboardItems[key].indexOf(item);

      // if (!item.ui.inherited) {
      vm.dashboardItems[key].splice(index, 1);
      // } else {
      //   item.ui.refs.shift();
      //   item.ui.errs.shift();
      //   item.ui.ref = item.refs[0];
      //   item.ui.owned = false;
      //   item.ui.id = getItemId(item);
      //   // Create a filter; user trashed their copy, assume inherited items are also unwanted.
      //   setItemInvisible(ev, key, index);
      // }
    }

    function excludeItem (item) {
      return item.ui.ref.id !== $scope.$parent.exclusiveId;
    }

    function excludeItems (exclusiveId) {
      $scope.$parent.exclusiveId = exclusiveId;
      Preferences.setPreference('dashboard', exclusiveId);
    }

    function saveDashboard () {
      // Record the sequence.
      var itemSequence = {
        type: DASHBOARD.ITEMS.TYPES.SEQUENCE,
        items: [].concat(
          vm.dashboardItems.links.map(function (i) { return i.ui.id; }),
          vm.dashboardItems.tiles.map(function (i) { return i.ui.id; })
        )
      };
      // Update the filters.
      vm.itemFilter.type = DASHBOARD.ITEMS.TYPES.FILTER;
      vm.itemFilter.items.forEach(function (f) { delete f.matched; });
      // Update the dashboard.
      vm.dashboard.tiles = [].concat(
        vm.dashboardItems.links.filter(function (i) { return i.ui.owned; }),
        vm.dashboardItems.tiles.filter(function (i) { return i.ui.owned; }),
        vm.itemFilter,
        itemSequence
      );

      DashboardsSrv.save(vm.dashboard)
        .then(function () {
          $window.history.back();
        })
        .catch(function () {
          psNotification.error('Failed to update the dashboard.');
          return $q.reject();
        });
    }

    function setItemInvisible (item, key) {
      if (vm.itemFilter.items.filter(function (f) { return f.id === item.ui.id; }).length === 0) {
        vm.itemFilter.items.push({ id: item.ui.id, matched: true });
      }

      vm.dashboardItems[key] = vm.dashboardItems[key].filter(markFilteredItems);
    }

    function setItemVisible (item, key) {
      if (item.ui.filtered) {
        var idx = vm.itemFilter.items.map(function (f) { return f.id === item.ui.id; }).indexOf(true);
        if (idx >= 0) {
          vm.itemFilter.items.splice(idx, 1);
        }
      }

      item.ui.filtered = false;
      vm.dashboardItems[key] = vm.dashboardItems[key].filter(markFilteredItems).filter(removeDuplicateItems);
    }

    function showItemProvenance (ev, item) {
      $mdDialog.show({
        controller: 'ShowItemProvenanceModalCtrl as modal',
        templateUrl: '/ui/dashboard/modals/showItemProvenanceModal.html',
        locals: { modalItem: item },
        clickOutsideToClose: true,
        targetEvent: ev
      });
    }

    //= ================================
    // Private interface
    //= ================================

    function getDashboardItems (dashboardList) {
      var dashboardItems = [];
      var promises = dashboardList.map(function (dashboard) { return DashboardsSrv.getDashboard(dashboard); });

      return $q.all(promises)
        .then(function (all) {
          vm.itemFilter.items.splice(0);

          all.forEach(function (dashboard, idx) {
            var owned = vm.dashboard.id === dashboard.id;
            // Cellect all the edit-relevant items.
            dashboardList[idx].tiles.length = 0;
            dashboard.tiles.forEach(function (mwItem) { // eslint-disable-line complexity
              var item = angular.copy(mwItem);

              if (owned && item.type === DASHBOARD.ITEMS.TYPES.FILTER) {
                vm.itemFilter = item;
                return;// continue
              }
              if (owned && item.type === DASHBOARD.ITEMS.TYPES.SEQUENCE) {
                vm.itemSequence = item;
                return;// continue
              }
              // Exclude inherited non-items.
              if (!item.type || (item.type === DASHBOARD.ITEMS.TYPES.FILTER || item.type === DASHBOARD.ITEMS.TYPES.SEQUENCE)) {
                return;// continue
              }
              if (!owned && (item.error || item.unsupported) && !STATE_ADMIN) {
                return;// continue
              }

              item.ui.idx = idx;
              item.ui.ref = dashboard;
              item.ui.refs = [dashboard];
              item.ui.errs = [item.error];

              item.ui.filtered = false;
              item.ui.inherited = !owned;
              item.ui.owned = owned;

              dashboardItems.push(item);
              dashboardList[idx].tiles.length += 1;
            });
          });

          // Sort "broken" items downward so that broken items
          // are matched against working items when de-duping.
          dashboardItems.sort(sortItemsBrokenDown);

          dashboardItems = dashboardItems.filter(markFilteredItems);// Doesn't remove, just tags.
          dashboardItems = dashboardItems.filter(removeDuplicateItems);

          // Remove unused filters...
          vm.itemFilter.items = vm.itemFilter.items.filter(function (f) { return f.matched; });

          // Sort according to user preference...
          if (vm.itemSequence && vm.itemSequence.items.length) {
            dashboardItems.sort(sortItemsInSequence);
          }

          return dashboardItems;
        });
    }

    function getDashboardList (urlDashboard, groups) {
      return DashboardsSrv.getList({ admin: true, provider: urlDashboard.provider })
        .then(function (dashboards) {
          var dashboardList = [];
          var groupDashboards;
          var systemDashboards;
          var userDashboards;
          // --------------------------------------
          // Segregate by level
          // --------------------------------------
          userDashboards = dashboards.filter(function (dashboard) {
            return dashboard.level === DashboardsSrv.LEVELS.USER &&
            dashboard.owner === urlDashboard.owner;
          });
          groupDashboards = dashboards.filter(function (dashboard) {
          /* jslint bitwise: true */
            return dashboard.level === DashboardsSrv.LEVELS.GROUP &&
            dashboard.provider === urlDashboard.provider &&
            groups.indexOf(dashboard.group) >= 0 &&
            (dashboard.effective_permissions & vm.ACCESS.READ) === vm.ACCESS.READ;
          /* jslint bitwise: false */
          });
          systemDashboards = dashboards.filter(function (dashboard) {
            return dashboard.level === DashboardsSrv.LEVELS.SYSTEM &&
            dashboard.provider === urlDashboard.provider;
          });
          // --------------------------------------
          // Sort within levels
          // --------------------------------------
          if (userDashboards.length > 1) {
            userDashboards.sort(sortDashboardsAlpha);
          }
          if (groupDashboards.length > 1) {
            groupDashboards.sort(sortDashboardsPriority);
          }
          if (systemDashboards.length > 1) {
            systemDashboards.sort(sortDashboardsNewest);
          }
          // --------------------------------------
          dashboardList = dashboardList.concat(userDashboards, groupDashboards, systemDashboards);

          return dashboardList;
        });
    }

    function splitAtLastIndex(strToSplit, charToSplitAt) {
      let parts = [];
      let index = strToSplit.lastIndexOf(charToSplitAt);
      parts.push(strToSplit.slice(0, index));
      parts.push(strToSplit.slice(index + 1))
      return parts;
    }

    function getDashboardOwner (dashboardOwner) {
      var owner = splitAtLastIndex(dashboardOwner, '@');

      owner = { provider: owner[1], roles: null, username: owner[0] };

      return UsersSrv.getUserGroups(owner)// Attaches to owner.
        .then(function () {
          return owner;
        });
    }

    function getDashboardPreference () {
      var dashboardId = Preferences.getPreferences().dashboard;
      var didList = $scope.$parent.dashboardList.map(function (d) { return d.id; });
      var didIdx = didList.indexOf(dashboardId);

      if (didIdx >= 0 && $scope.$parent.dashboardList[didIdx].tiles.length > 0) {
        excludeItems(dashboardId);
      } else {
        excludeItems($scope.$parent.dashboardId);
      }
    }

    function getItemId (item) {
      return item.ui.ref.id + ':' + item.type + ':' + (item.link.sref || item.link.href || item.link.text);
    }

    function markFilteredItems (currentValue) {
      var item = currentValue;

      for (var i = 0, ii = vm.itemFilter.items.length; i < ii; ++i) {
        if (item.ui.id === vm.itemFilter.items[i].id) {
          vm.itemFilter.items[i].matched = true;
          currentValue.ui.filtered = true;
          break;
        }
      }
      return true;// Exclude nothing.
    }

    function removeDuplicateItems (currentValue, index, arr) {
      var currData = angular.copy(currentValue.link.href);
      var testData;

      for (var i = 0; i < index; ++i) {
        testData = angular.copy(arr[i].link.href);

        if (angular.equals(currData, testData)) {
          if (arr[i].ui.ref.id !== currentValue.ui.ref.id) {
            arr[i].ui.refs = arr[i].ui.refs.concat(currentValue.ui.refs);
            arr[i].ui.errs = arr[i].ui.errs.concat(currentValue.ui.errs);
            arr[i].ui.inherited = true;
          }
          return true;// false;// Duplicate, exclude it.
        }
      }
      return true;// Unique, include it.
    }

    function showBannerForEmulation (urlDashboard) {
      $scope.$parent.banner.type = 'info';
      $scope.$parent.banner.icon = 'info';

      switch (urlDashboard.level) {
        case DashboardsSrv.LEVELS.SYSTEM:
          $scope.$parent.banner.message = 'THIS IS THE <b>SYSTEM</b> DASHBOARD.';
          break;
        case DashboardsSrv.LEVELS.GROUP:
          $scope.$parent.banner.message = 'This is a group dashboard for <b>' + urlDashboard.group.replace(/</g, '&lt;').replace(/>/g, '&gt;') + '</b>';
          break;
        case DashboardsSrv.LEVELS.USER:
          UsersSrv.getList()
            .then(function (userList) {
              for (var i = 0, ii = userList.length; i < ii; ++i) {
                if (urlDashboard.owner === (userList[i].username + '@' + userList[i].provider)) {
                  vm.ownerDisplayName = userList[i].name + ' (' + userList[i].username + ')';
                  break;
                }
              }
              $scope.$parent.banner.message = 'This is a user dashboard for <b>' + (vm.ownerDisplayName || urlDashboard.owner).replace(/</g, '&lt;').replace(/>/g, '&gt;') + '</b>';
            });
          break;
        default:
          // Nothing to do. The "base" dashboard cannot be edited.
      }
    }

    function sortableStart (ev, ui) {
      ui.item.css('border-bottom', '1px solid rgba(0,0,0,0.12)');
    }

    function sortableStop (ev, ui) {
      ui.item.css('border-bottom-color', 'transparent');
    }

    function sortDashboardsAlpha (a, b) {
      var aCmp;
      var bCmp;

      if (a.display_name && !b.display_name) { return -1; }
      if (!a.display_name && b.display_name) { return 1; }
      if (a.display_name && b.display_name) {
        aCmp = a.display_name.toLowerCase();
        bCmp = b.display_name.toLowerCase();
        if (aCmp < bCmp) { return -1; }
        if (aCmp > bCmp) { return 1; }
      }
      return sortDashboardsNewest(a, b);
    }

    function sortDashboardsNewest (a, b) {
      // var aCmp;
      // var bCmp;

      if (a.created_on && !b.created_on) { return -1; }
      if (!a.created_on && b.created_on) { return 1; }
      if (a.created_on && b.created_on) {
        if (a.created_on < b.created_on) { return 1; }
        if (a.created_on > b.created_on) { return -1; }
      }
      return 0;
    }

    function sortDashboardsPriority (a, b) {
      var aCmp = a.priority;
      var bCmp = b.priority;
      var missing = [null, ''];
      var aIsPresent = missing.indexOf(aCmp) === -1;
      var bIsPresent = missing.indexOf(bCmp) === -1;

      if (aIsPresent && !bIsPresent) { return -1; }
      if (!aIsPresent && bIsPresent) { return 1; }
      if (aIsPresent && bIsPresent) {
        if (parseInt(aCmp, 10) < parseInt(bCmp, 10)) { return -1; }
        if (parseInt(aCmp, 10) > parseInt(bCmp, 10)) { return 1; }
      }
      return sortDashboardsAlpha(a, b);
    }

    function sortItemsBrokenDown (a, b) { // eslint-disable-line complexity
      if (!a.ui.owned && b.ui.owned) { return 1; }
      if (a.ui.owned && !b.ui.owned) { return -1; }

      if (!a.ui.owned && !b.ui.owned) {
        if (a.unsupported && !b.unsupported) { return 1; }
        if (!a.unsupported && b.unsupported) { return -1; }
        if (a.error && !b.error) { return 1; }
        if (!a.error && b.error) { return -1; }
      }

      return 0;
    }

    function sortItemsInSequence (a, b) {
      var aIdx = vm.itemSequence.items.indexOf(a.ui.id);
      var bIdx = vm.itemSequence.items.indexOf(b.ui.id);

      if (aIdx >= 0 && bIdx >= 0) { return aIdx - bIdx; }
      if (aIdx >= 0 && bIdx < 0) { return -1; }
      if (aIdx < 0 && bIdx >= 0) { return 1; }

      return 0;
    }
  }
})();
