(function () {
  'use strict';

  angular
    .module('vhr')
    .controller('HieCtrl', HieCtrl);

  HieCtrl.$inject = [
    '$rootScope', '$scope', '$state',
    'vhrApp', 'vhrConfigSrv', 'vhrPersistence'
  ];

  function HieCtrl (
    $rootScope, $scope, $state,
    vhrApp, vhrConfigSrv, vhrPersistence
  ) {
    var vm = this;

    // Properties
    vm.config = {};

    activate();

    // Implementation

    function activate () {
      vm.config = vhrConfigSrv.getPageByState($state.current.name);
      $scope.pageLabel = vm.config.label;

      $scope.$on('vhrRefreshed', function () {
        $rootScope.$broadcast('vhrHieRefreshed');
      });
      vhrApp.enableFilter(false);
      vhrApp.enableModes(false);
      vhrApp.enableRefresh(true);

      launch();
    }

    function launch () {
      var go = vhrPersistence.getData('hieState') || (vhrConfigSrv.getHieAutoLookup() ? 'app.vhr.patient.hie.hie-communities' : 'app.vhr.patient.hie.hie-search');
      $state.go(go);
    }
  }
})();
