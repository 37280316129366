(function () {
  'use strict';

  angular
    .module('mpiApp.services')
    .factory('mpiApiSrv', mpiApiSrv);

  mpiApiSrv.$inject = [
    'MPI_FHIR', 'MPI_CLIENT_NAME',
    '$http', '$log', '$parse', '$q', 'imatStoresSrv'
  ];

  function mpiApiSrv (
    MPI_FHIR, MPI_CLIENT_NAME,
    $http, $log, $parse, $q, imatStoresSrv
  ) {
    var service;
    var baseUrl = '/extensions/mpi/';
    var deleteTransactions = [];

    service = {
      commitMpids: commitMpids,
      createPi: createPi,
      deletePi: deletePi,
      deleteLimboRecords: deleteLimboRecords,
      getClinicalRecordCount: getClinicalRecordCount,
      getLimboRecord: getLimboRecord,
      getPiRecord: getPiRecord,
      getRecordsPendingDelete: getRecordsPendingDelete,
      replacePi: replacePi,
      updateLevel: updateLevel
    };

    return service;

    //= ================================
    // Public interface
    //= ================================

    function commitMpids (changeComment, personas) {
      var re = /^(New-)?(\d+)\.?/;
      var regroup = [];
      var postData = '';

      angular.forEach(personas, function (persona, mpid) {
        if (persona.altered) {
          var matches = re.exec(mpid);
          var subject = matches && ('mpid.' + (matches[1] ? 'new' : matches[2]));
          var predicate = [];

          if (!matches || (matches[1] && !persona.pi_entries.length)) {
            // Nothing to do for a "New-X" persona with no assigned PI entries.
            return;// continue
          }

          persona.pi_entries.forEach(function (pi) {
            matches = re.exec(pi.pid);
            if (matches && matches[2]) {
              predicate.push(matches[2]);
            }
          });

          regroup.push(subject + ':' + predicate.join(','));
        }
      });

      if (regroup.length < 1) {
        return $q.resolve({ data: '' });// XXX This should probably reject.
      }

      postData = {
        regroup: regroup.join(','),
        comment: changeComment,
        system: MPI_CLIENT_NAME
      };

      return $http.post('/extensions/mpi/transactions', postData, {
        headers: { Accept: 'text/html', 'Content-Type': 'application/x-www-form-urlencoded' }
      });
    }

    function deleteLimboRecords (uris, comment, scrub) {
      var url = '/extensions/mpi/transactions/delete';
      var params = { system: 'IMAT-MPI-UI' };

      if (!uris) {
        return $q.reject('Empty list.');
      }

      params.externaluri = (angular.isArray(uris) ? uris : [uris]).join(',');

      if (comment) {
        params.comment = comment;
      }
      if (scrub) {
        params.scrub = 1;
      }

      return $http({
        method: 'DELETE',
        url: url,
        params: params
      });
    }

    function getRecordsPendingDelete (force) {
      if (!force && deleteTransactions.length) {
        return $q.resolve(deleteTransactions);
      }

      deleteTransactions = [];

      return $http.get(baseUrl + 'transactions/changes?sinceminutes=360')
        .then(function (response) {
          if (response.data && response.data.transaction_changes && response.data.transaction_changes.change) {
            response.data.transaction_changes.change.forEach(function (transaction) {
              if (transaction.pi_entry._value === '-1' && transaction.external_uri) {
                deleteTransactions.push(transaction.external_uri._value);
              }
            });
          }
          return deleteTransactions;
        });
    }

    function getClinicalRecordCount (piField, pid, stores) {
      var config = {
        params: {
          q: '()' + piField + ':' + parseInt(pid, 10),
          limit: 0,
          report: 'exacttotal',
          store: null
        }
      };
      var counts = {};
      var promises = stores.map(function (store) {
        counts[store] = 0;
        config.params.store = store;
        return $http.post('/search', null, angular.copy(config))
          .then(function (response) {
            if (response.data && response.data.response && response.data.response.hits) {
              counts[store] = parseInt(response.data.response.hits._total, 10);
            }
          })
          .catch(function () {
            // Ignored.
          });
      });

      return $q.all(promises)
        .then(function () {
          return counts;
        });
    }

    function getLimboRecord (uri, store) {
      return imatStoresSrv.getRecord(store, uri)
        .then(function (res) {
          return res.data;
        });
    }

    function getPiRecord (id) {
      return getPersonInfoRecord('pi', id)
        .catch(function () {
          $log.debug('Unable to load the PI record', id);
          return $q.reject();
        });
    }

    function updateLevel (pientry, level, comments) {
      var data = {
        system: MPI_CLIENT_NAME,
        pientry: pientry,
        level: level,
        comments: comments
      };

      return $http({
        method: 'POST',
        url: baseUrl + 'transactions/restrict',
        headers: { Accept: 'text/html', 'Content-Type': 'application/x-www-form-urlencoded' },
        data: data
      });
    }

    function createPi (contentBody, params) {
      var createParams = angular.extend({
        system: MPI_CLIENT_NAME,
        externaluri: '',
        mpid: '',
        comment: '',
        commit: '',
        purpose: ''
      }, params);

      return $http({
        method: 'POST',
        url: '/extensions/mpi/transactions/add',
        data: cleanFictitiousExtensions(contentBody),
        headers: { Accept: 'text/xml, application/xml', 'Content-Type': 'application/fhir+json' },
        params: createParams
      })
        .catch(function () {
          $log.debug('Unable to create a PI record');
          return $q.reject();
        });
    }

    function deletePi (pientry, scrub, comment) {
      return $http({
        method: 'DELETE',
        url: '/extensions/mpi/transactions/delete',
        params: {
          system: MPI_CLIENT_NAME,
          pientry: pientry,
          scrub: scrub,
          comment: comment
        }
      })
        .catch(function () {
          $log.debug('Unable to delete PI record');
          return $q.reject();
        });
    }

    function replacePi (pientry, mpid) {
      return $http({
        method: 'POST',
        url: '/extensions/mpi/transactions/replace',
        headers: { Accept: 'text/xml, application/xml', 'Content-Type': 'application/fhir+json' },
        params: {
          system: MPI_CLIENT_NAME,
          pientry: parseInt(pientry.id, 10),
          mpid: parseInt(mpid, 10)
        },
        data: pientry
      })
        .catch(function () {
          $log.debug('Unable to update PI record');
          return $q.reject();
        });
    }

    //= ================================
    // Private interface
    //= ================================

    function getPersonInfoRecord (type, id) {
      return $http({
        method: 'GET',
        url: baseUrl + type + '/' + parseInt(id, 10),
        headers: { Accept: 'text/xml, application/xml' }
      });
    }

    function cleanFictitiousExtensions (record) {
      angular.forEach(record, function (prop, propName) {
        if (typeof prop !== 'object' || prop == null) {
          return;
        }
        if (Array.isArray(prop)) {
          prop.forEach(function (obj, idx) {
            removeFictitiousExtension(record[propName][idx]);
          });
        } else {
          removeFictitiousExtension(record[propName]);
          if (Object.keys(record[propName]).length === 0) {
            delete record[propName];
          }
        }
      });

      function removeFictitiousExtension (objRef) {
        if (objRef != null && Array.isArray(objRef.extension)) {
          objRef.extension = objRef.extension.filter(function (ext) {
            return ext.url !== MPI_FHIR.EXT.IMAT_FICTITIOUS;
          });
          if (objRef.extension.length === 0) {
            delete objRef.extension;
          }
        }
      }
      return record;
    }
  }
})();
