(function () {
  'use strict';

  angular.module('admin')
    .controller('EditDashboardDisplayNameModalCtrl', EditDashboardDisplayNameModalCtrl);

  EditDashboardDisplayNameModalCtrl.$inject = ['$mdDialog', '$scope', 'modalDashboard'];
  function EditDashboardDisplayNameModalCtrl ($mdDialog, $scope, modalDashboard) {
    $scope.details = modalDashboard;

    // Methods
    $scope.cancel = cancel;
    $scope.updateDashboardDescription = updateDashboardDescription;

    function cancel () {
      $mdDialog.cancel();
    }

    function updateDashboardDescription () {
      $mdDialog.hide($scope.details);
    }

    //= ================================
    // Private interface
    //= ================================
  }
})();
