(function () {
  'use strict';

  angular.module('admin')
    .controller('CreateTokenCtrl', CreateTokenCtrl);

  CreateTokenCtrl.$inject = ['$mdDialog', '$scope', 'authTokensSrv', 'logTypes', 'providers', 'psNotification', 'UsersSrv'];
  function CreateTokenCtrl ($mdDialog, $scope, authTokensSrv, logTypes, providers, psNotification, UsersSrv) { // eslint-disable-line no-shadow
    $scope.token = {
      provider: 'default',
      username: '',
      ips: '0.0.0.0/0',
      apis: '.*',
      logType: ''
    };

    $scope.hash = '';
    $scope.logTypes = logTypes;
    $scope.providers = providers;
    $scope.status = 'default';
    $scope.users = [];
    $scope.message = '';

    $scope.cancel = cancel;
    $scope.copyToken = copyToken;
    $scope.create = create;
    $scope.hide = hide;
    $scope.parseApis = parseApis;

    activate();

    function activate () { // eslint-disable-line no-shadow
      getUsers();
    }

    function cancel () {
      $mdDialog.cancel();
    }

    function parseApis () {
      if (/^\/api$/.test($scope.token.apis)) {
        $scope.token.apis = '/(api|appliance/profile)/*';
        $scope.message = 'APIs updated with /appliance/profile to ensure authentication succeeds.';
      } else {
        $scope.message = '';
      }
    }

    function create () {
      $scope.status = 'processing';
      var newToken = $scope.token;
      if (newToken.custom) {
        newToken.logType = newToken.custom;
        delete newToken.custom;
      }
      authTokensSrv.createToken(newToken)
        .then(function (res) {
          $scope.hash = res.token;
          $scope.status = 'created';
        })
        .catch(function (err) {
          cancel();
          var message = '';
          if (err.data && err.data.code === 'E_MISSING_OR_INVALID_PARAMS') {
            message = 'Invalid parameters.';
          }
          psNotification.error('Unable to create the token. ' + message);
        });
    }

    function copyToken () {
      var textArea = document.createElement('textarea');
      textArea.value = $scope.hash;
      textArea.setAttribute('readonly', '');
      textArea.style = { position: 'absolute', left: '-99999px' };
      document.body.appendChild(textArea);

      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);

      psNotification.success('Token copied to clipboard');
    }

    function hide () {
      $mdDialog.hide();
    }

    //= ================
    // Private Methods
    //= ================
    function getUsers () {
      return UsersSrv.getList(false)
        .then(function (users) {
          users.forEach(function (user) {
            $scope.users.push(user.username);
          });
        });
    }
  }
})();
