(function () {
  'use strict';

  angular
    .module('vhr.directives')
    .directive('vhrMedInsInsured', vhrDir);

  vhrDir.$inject = [];

  function vhrDir () {
    var directive = {
      link: { pre: postLink },
      restrict: 'E',
      scope: {
        json: '='
      },
      templateUrl: '/ui/vhr/directives/uiGridTemplates/medicalInsurance/medical-insurance-insured.html'
    };
    var json = {
      birthDate: '',
      name: {
        family: [],
        given: [],
        resourceType: 'HumanName'
      },
      resourceType: 'Patient'
    };

    return directive;

    function postLink (scope, element, attrs, ctrl) { // eslint-disable-line no-unused-vars
      var insured = angular.extend({}, json, scope.json || {});
      var given = (insured.name.given) ? insured.name.given.join(' ') : '';
      var family = (insured.name.family) ? insured.name.family : '';
      scope.planInsuredName = given + ' ' + family;
      scope.planInsuredDob = insured.birthDate;
    }
  }
})();
