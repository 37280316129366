/* eslint-disable no-multi-spaces */
(function () {
  'use strict';

  angular.module('imat.fhir')
    .factory('fhirResourceSrv', fhirResourceSrv);

  fhirResourceSrv.$inject = ['IMAT_FHIR_EXTENSION', 'fhirDatatypeSrv', 'fhirUtilitySrv', 'fhirValueSetSrv'];

  function fhirResourceSrv (IMAT_FHIR_EXTENSION, fhirDatatypeSrv, fhirUtilitySrv, fhirValueSetSrv) {
    var service;
    var fhirdata = fhirDatatypeSrv;
    var fhirutil = fhirUtilitySrv;

    service = {
      getValidPatientJson: getValidPatientJson,

      verifyAsPatient: verifyAsPatient,
      verifyAsPatientContact: verifyAsPatientContact,

      isEmptyExtension: fhirutil.isEmptyExtension,
      isEmptyProperty: fhirutil.isEmptyProperty,
      removeProperties: fhirutil.removeProperties
    };

    return service;

    //= ================================
    // Public interface
    //= ================================

    function getValidPatientJson (json, returnInvalidJson) {
      var jsonWrapper = fhirUtilitySrv.getFhirJsonWrapper(json, 'Patient', true);
      fhirutil.removeProperties(jsonWrapper.data);
      var isValidJson = verifyAsPatient(jsonWrapper);
      return (isValidJson || returnInvalidJson ? jsonWrapper.data : null);
    }

    // ---------------------------------
    // Foundational types
    // ---------------------------------

    function verifyAsPatient (wrap) {
      // https://www.hl7.org/fhir/patient.html#resource
      // {
      //   "identifier" : [{ Identifier }], // An identifier for this patient
      //   "active" : <boolean>, // Whether this patient's record is in active use
      //   "name" : [{ HumanName }], // A name associated with the patient
      //   "telecom" : [{ ContactPoint }], // A contact detail for the individual
      //   "gender" : "<code>", // male | female | other | unknown
      //   "birthDate" : "<date>", // The date of birth for the individual
      //   // deceased[x]: Indicates if the individual is deceased or not. One of these 2:
      //   "deceasedBoolean" : <boolean>,
      //   "deceasedDateTime" : "<dateTime>",
      //   "address" : [{ Address }], // Addresses for the individual
      //   "maritalStatus" : { CodeableConcept }, // Marital (civil) status of a patient
      //   // multipleBirth[x]: Whether patient is part of a multiple birth. One of these 2:
      //   "multipleBirthBoolean" : <boolean>,
      //   "multipleBirthInteger" : <integer>,
      //   "photo" : [{ Attachment }], // Image of the patient
      //   "contact" : [{ // A contact party (e.g. guardian, partner, friend) for the patient
      //     "relationship" : [{ CodeableConcept }], // The kind of relationship
      //     "name" : { HumanName }, // A name associated with the contact person
      //     "telecom" : [{ ContactPoint }], // A contact detail for the person
      //     "address" : { Address }, // Address for the contact person
      //     "gender" : "<code>", // male | female | other | unknown
      //     "organization" : { Reference(Organization) }, // C? Organization that is associated with the contact
      //     "period" : { Period } // The period during which this contact person or organization is valid to be contacted relating to this patient
      //   }],
      //   "animal" : { // This patient is known to be an animal (non-human)
      //     "species" : { CodeableConcept }, // R!  E.g. Dog, Cow
      //     "breed" : { CodeableConcept }, // E.g. Poodle, Angus
      //     "genderStatus" : { CodeableConcept } // E.g. Neutered, Intact
      //   },
      //   "communication" : [{ // A list of Languages which may be used to communicate with the patient about his or her health
      //     "language" : { CodeableConcept }, // R!  The language which can be used to communicate with the patient about his or her health
      //     "preferred" : <boolean> // Language preference indicator
      //   }],
      //   "generalPractitioner" : [{ Reference(Organization|Practitioner) }], // Patient's nominated primary care provider
      //   "managingOrganization" : { Reference(Organization) }, // Organization that is the custodian of the patient record
      //   "link" : [{ // Link to another patient resource that concerns the same actual person
      //     "other" : { Reference(Patient|RelatedPerson) }, // R!  The other patient or related person resource that the link refers to
      //     "type" : "<code>" // R!  replaced-by | replaces | refer | seealso - type of link
      //   }]
      // }
      var results;

      if (!verifyAsDomainResource(wrap, 'Patient')) { return false; }

      results = fhirutil.verifyProperties(wrap.data, [
        { property: 'identifier',           isRequired: false, isArray: true,  validate: fhirdata.verifyAsIdentifier },
        { property: 'active',               isRequired: false, isArray: false, validate: fhirdata.verifyAsBoolean },
        { property: 'name',                 isRequired: false, isArray: true,  validate: fhirdata.verifyAsHumanName },
        { property: 'telecom',              isRequired: false, isArray: true,  validate: fhirdata.verifyAsContactPoint },
        { property: 'gender',               isRequired: false, isArray: false, validate: fhirdata.verifyAsCode, args: [fhirValueSetSrv.getValueSetRegex('ADMINISTRATIVE_GENDER')] },
        { property: 'birthDate',            isRequired: false, isArray: false, validate: fhirdata.verifyAsDate },
        { property: 'deceasedBoolean',      isRequired: false, isArray: false, validate: fhirdata.verifyAsBoolean },
        { property: 'deceasedDateTime',     isRequired: false, isArray: false, validate: fhirdata.verifyAsDateTime },
        { property: 'address',              isRequired: false, isArray: true,  validate: fhirdata.verifyAsAddress },
        { property: 'maritalStatus',        isRequired: false, isArray: false, validate: fhirdata.verifyAsCodeableConcept, args: [fhirValueSetSrv.getValueSetRegex('MARITAL_STATUS')] },
        { property: 'multipleBirthBoolean', isRequired: false, isArray: false, validate: fhirdata.verifyAsBoolean },
        { property: 'multipleBirthInteger', isRequired: false, isArray: false, validate: fhirdata.verifyAsInteger },
        { property: 'contact',              isRequired: false, isArray: true,  validate: verifyAsPatientContact }
      ]);
      fhirutil.xorProperties(wrap.data, results, 'deceased', ['DateTime', 'Boolean']);
      fhirutil.xorProperties(wrap.data, results, 'multipleBirth', ['Integer', 'Boolean']);
      return fhirutil.analyzeResults(wrap.data, results);
    }

    function verifyAsPatientContact (wrap) {
      // {
      //   "relationship" : [{ CodeableConcept }], // The kind of relationship
      //   "name" : { HumanName }, // A name associated with the contact person
      //   "telecom" : [{ ContactPoint }], // A contact detail for the person
      //   "address" : { Address }, // Address for the contact person
      //   "gender" : "<code>", // male | female | other | unknown
      //   "organization" : { Reference(Organization) }, // C? Organization that is associated with the contact
      //   "period" : { Period } // The period during which this contact person or organization is valid to be contacted relating to this patient
      // }
      var results;

      if (!verifyAsBackboneElement(wrap)) { return false; }

      results = fhirutil.verifyProperties(wrap.data, [
        { property: 'relationship', isRequired: false, isArray: true,  validate: fhirdata.verifyAsCodeableConcept, args: [fhirValueSetSrv.getValueSetRegex('V2_CONTACT_ROLE')] },
        { property: 'name',         isRequired: false, isArray: false, validate: fhirdata.verifyAsHumanName },
        { property: 'telecom',      isRequired: false, isArray: true,  validate: fhirdata.verifyAsContactPoint },
        { property: 'address',      isRequired: false, isArray: false, validate: fhirdata.verifyAsAddress },
        { property: 'gender',       isRequired: false, isArray: false, validate: fhirdata.verifyAsCode, args: [fhirValueSetSrv.getValueSetRegex('ADMINISTRATIVE_GENDER')] },
        { property: 'organization', isRequired: false, isArray: false, validate: fhirdata.verifyAsReference },
        { property: 'period',       isRequired: false, isArray: false, validate: fhirdata.verifyAsPeriod }
      ]);
      return fhirutil.analyzeResults(wrap.data, results);
    }

    //= ================================
    // Private interface
    //= ================================

    function verifyAsBackboneElement (wrap) { // jshint ignore:line
      // {
      //   "id" : "<id>", // Logical id of this artifact
      //   "extension" : [{ Extension }], // Additional content defined by implementations
      // }
      var results;

      // if (!fhirdata.verifyAsElement(wrap)) { return false; }

      results = fhirutil.verifyProperties(wrap.data, [
        { property: 'modifierExtension', isRequired: false, isArray: true,  validate: fhirdata.verifyAsExtension }
      ]);
      return fhirutil.analyzeResults(wrap.data, results);
    }

    function verifyAsContainedDomainResource (wrap, resourceType) { // jshint ignore:line
      // A contained resource
      // + It SHALL NOT contain nested Resources
      // + It SHALL NOT contain any narrative
      // + It SHALL NOT have a meta.versionId or a meta.lastUpdated
      // + It SHALL be referred to from elsewhere in the resource
      // {
      //   (Extensions - see JSON page)
      //   (Modifier Extensions - see JSON page)
      // }
      var results = [{ property: resourceType }];
      var validator = 'verifyAs' + resourceType;

      if (angular.isFunction(service[validator])) {
        results = fhirutil.verifyProperties(wrap.data, [
          { property: resourceType, isRequired: false, isArray: false, validate: service[validator] }
        ]);
      }
      // fhirutil.notProperties(wrap.data, results, [
      //   {property: 'contained'},
      //   {property: 'meta', properties: ['lastUpdated', 'versionId']},
      //   {property: 'text'},
      // ]);
      return fhirutil.analyzeResults(wrap.data, results);
    }

    function verifyAsDomainResource (wrap, resourceType) { // jshint ignore:line
      // A resource with narrative, extensions, and contained resources
      // {
      //   "text" : { Narrative }, // C? Text summary of the resource, for human interpretation
      //   "contained" : [{ Resource }], // Contained, inline Resources
      //   (Extensions - see JSON page)
      //   (Modifier Extensions - see JSON page)
      // }
      var results;

      if (!verifyAsResource(wrap, resourceType)) { return false; }

      results = fhirutil.verifyProperties(wrap.data, [
        // { property: 'text',              isRequired: false, isArray: false, validate: verifyAsNarrative },
        { property: 'contained',         isRequired: false, isArray: true,  validate: verifyAsContainedDomainResource, args: ['Patient'] },
        { property: 'extension',         isRequired: false, isArray: true,  validate: fhirdata.verifyAsExtension }
        // { property: 'modifierExtension', isRequired: false, isArray: true,  validate: verifyAsModifierExtension }
      ]);
      return fhirutil.analyzeResults(wrap.data, results);
    }

    function verifyAsResource (wrap, resourceType) { // jshint ignore:line
      // {
      //   "resourceType" : "[name]",
      //   "id" : "<id>", // Logical id of this artifact
      //   "meta" : { Meta }, // Metadata about the resource
      //   "implicitRules" : "<uri>", // A set of rules under which this content was created
      //   "language" : "<code>" // Language of the resource content
      // }
      var results;

      results = fhirutil.verifyProperties(wrap.data, [
        { property: 'resourceType',  isRequired: false, isArray: false, validate: fhirdata.verifyAsString, args: [resourceType] },
        { property: 'id',            isRequired: false, isArray: false, validate: fhirdata.verifyAsId },
        { property: 'meta',          isRequired: false, isArray: false, validate: fhirdata.verifyAsMeta },
        // { property: 'implicitRules', isRequired: false, isArray: false, validate: fhirdata.verifyAsUri },
        { property: 'language',      isRequired: false, isArray: false, validate: fhirdata.verifyAsCode }
      ]);
      return fhirutil.analyzeResults(wrap.data, results);
    }
  }
})();
