(function () {
  'use strict';

  angular.module('admin')
    .controller('buildsCtrl', buildsCtrl);

  buildsCtrl.$inject = ['$interval', '$scope', 'IndexerSrv', 'psNotification'];

  function buildsCtrl ($interval, $scope, Indexer, psNotification) {
    var vm = this; // eslint-disable-line no-invalid-this
    var GET_BUILDS_ERROR = 'Unable to retrieve the builds.';

    var interval;

    // Properties
    vm.builds = null;
    vm.loaded = { list: true };

    // Methods
    vm.getBuilds = getBuilds;

    activate();

    function activate () {
      getBuilds();
      interval = $interval(getBuilds, 15000);
      $scope.$on('$destroy', function () {
        if (interval) {
          $interval.cancel(interval);
        }
      });
    }

    function getBuilds () {
      vm.loaded.list = false;
      Indexer.getBuilds()
        .then(function (response) {
          switch (response.data.build.IndexJobs._count) {
            case '0':
              vm.builds = [];
              break;
            case '1':
              vm.builds = [response.data.build.IndexJobs.IndexJob];
              break;
            default:
              vm.builds = response.data.build.IndexJobs.IndexJob;
          }
        })
        .catch(function () {
          psNotification.error(GET_BUILDS_ERROR);
        })
        .finally(function () {
          vm.loaded.list = true;
        });
    }
  }
})();
