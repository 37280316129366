(function () {
  'use strict';

  angular.module('queries')
    .controller('QueriesExportsCtrl', QueriesExportsCtrl);

  QueriesExportsCtrl.$inject = ['$log', '$mdDialog', '$scope', '$state', '$transition$', 'imatConfig', 'psNotification', 'QueriesSrv', 'userSession'];

  function QueriesExportsCtrl ($log, $mdDialog, $scope, $state, $transition$, imatConfig, psNotification, QueriesSrv, userSession) {
    var vm = this;

    // Properties
    vm.banner = {
      icon: 'info',
      message: ''
    };
    vm.export = QueriesSrv.export;
    vm.helpURL = imatConfig.get('apps.queries.customerHelpLink');
    vm.isAdmin = userSession.userHasRole('system');
    vm.loading = {
      exports: false
    };

    // Methods
    vm.deleteExport = deleteExport;
    vm.emptyState = emptyState;
    vm.downloadExport = downloadExport;
    vm.newQuery = newQuery;
    vm.showExportInfo = showExportInfo;

    activate();

    function activate () {
      // Check if export id passed in with loading action
      if ($transition$.params().id && $transition$.params().action && $transition$.params().action === 'loading') {
        vm.loading.exports = true;
        QueriesSrv.getExport($transition$.params().id)
          .catch(function () {
            QueriesSrv.resetExport();
            psNotification.error('Unable to load the export.');
          })
          .finally(function () {
            vm.loading.exports = false;
          });
      }

      $scope.$watch(function () {
        (vm.export.id && vm.export.id.length > 0) ? vm.banner.message = 'View details about the export request below. To download the file click on the download icon in the toolbar above.' : vm.banner.message = 'Select an export from the side menu or <a class="md-primary" href="/ui/#/queries">build a new query</a>.'; // eslint-disable-line no-unused-expressions
      });
    }

    function deleteExport () {
      var confirm = $mdDialog.confirm()
        .title('Confirm Delete')
        .textContent('Delete export ' + vm.export.name + '?')
        .ariaLabel('Confirm Delete')
        .ok('Delete')
        .cancel('Cancel');

      $mdDialog.show(confirm)
        .then(function () {
          QueriesSrv.deleteExport(vm.export.id)
            .then(function () {
              psNotification.success('Export deleted.');
            }, function (errorResponse) {
              psNotification.error('Unable to delete the export.');
              $log.debug('Deleting the export failed', errorResponse);
            });
        });
    }

    function downloadExport () {
      var uri = '/search/results/' + vm.export.id + '?admin=' + vm.isAdmin;
      var iframe = document.createElement('iframe');

      iframe.css = 'visibility:hidden;';
      iframe.src = uri;
      document.body.appendChild(iframe);
      iframe.click();
      psNotification.customShow({ message: 'The query may not be finished processing results. Verify that all data is present before using the exported file.', hideDelay: 12000 });
    }

    function emptyState () {
      return (angular.isUndefined(vm.export.id) || vm.export.id.length === 0);
    }

    function newQuery () {
      QueriesSrv.resetQuery();
      $state.go('app.queries.builder');
    }

    function showExportInfo () {
      $mdDialog.show({
        controller: 'ExportsInfoModalCtrl',
        controllerAs: 'ctrl',
        locals: { exportObj: vm.export },
        templateUrl: '/ui/queries/query-exports/exports-info-modal.html',
        parent: angular.element(document.body),
        clickOutsideToClose: true
      });
    }
  }
})();
