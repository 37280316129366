(function () {
  'use strict';

  angular.module('vhr')
    .run(vhrInit);

  vhrInit.$inject = [
    '$state', '$stateRegistry', '$transitions', 'psNotification',
    'vhrApp', 'vhrConfigSrv', 'vhrRecordSrv', 'vhrUserSrv'
  ];

  function vhrInit (
    $state, $stateRegistry, $transitions, psNotification,
    vhrApp, vhrConfigSrv, vhrRecordSrv, vhrUserSrv
  ) {
    var MSG_ABORT_NO_ROLE = 'You are not allowed to load the requested page.';

    $transitions.onStart({ to: 'app.vhr.**' }, meetsGroupConstraints);

    function meetsGroupConstraints (trans) {
      var vhrConfigSrv = trans.injector().get('vhrConfigSrv');
      var page = vhrConfigSrv.getPageByState(trans.to().name);

      if (page && !page.isAccessible) {
        psNotification.error(MSG_ABORT_NO_ROLE);
        return false;
      }
      return true;// Either accessible or not a VHR "page" (i.e., no group constraints defined).
    }

    vhrConfigSrv.load()
      .then(function () {
        return vhrRecordSrv.load();
      })
      .then(function () {
        return vhrUserSrv.load();
      })
      .then(function () {
        setRoutes();
        vhrApp.isLoaded(true);
      });

    function setRoutes () {
      var pages = vhrConfigSrv.getPages();
      var userGroups = vhrUserSrv.getGroups();
      var userIsAdmin = vhrUserSrv.isAdmin();

      angular.forEach(pages, function (cfg, pageName) {
        var config, stateName;

        cfg.isAccessible = userIsAdmin || isAccessibleRoute(cfg, userGroups);
        cfg.pageName = pageName;

        if (cfg.type === 'lookup') {
          stateName = exposeReportState('app.vhr.lookup', cfg);
        } else if (cfg.type === 'summary') {
          stateName = exposeSummaryState(pageName, cfg);
          cfg.attest = true;
        } else if (cfg.type === 'patient-details') {
          stateName = exposeReportState('app.vhr.patient.patient-details', cfg);
          cfg.attest = true;
        } else if (cfg.type === 'grid') {
          stateName = exposeGridState(pageName, cfg);
          cfg.attest = true;
        } else if (cfg.type === 'hie') {
          // The stateName value is the state entry-point into the workflow.
          // The stateNames value is all of the workflow states that share the page configuration.
          stateName = exposeHieState(pageName, cfg);
          cfg.attest = true;
          cfg.stateNames = [
            'app.vhr.patient.hie',
            'app.vhr.patient.hie.hie-search',
            'app.vhr.patient.hie.hie-communities',
            'app.vhr.patient.hie.hie-documents',
            'app.vhr.patient.hie.hie-document'
          ];
        } else {
          config = $state.get('app.vhr.' + cfg.type);
          if (config) {
            stateName = exposeState(config, cfg);
          }
        }
        cfg.stateName = stateName;
      });
    }

    function isAccessibleRoute (cfg, userGroups) {
      var i;

      if (!cfg.groups || cfg.groups.length === 0) {
        return true;
      }
      if (userGroups.length === 0) {
        return false;
      }
      for (i = userGroups.length - 1; i >= 0; --i) {
        if (cfg.groups.indexOf(userGroups[i]) >= 0) {
          return true;
        }
      }
      return false;
    }

    function exposeGridState (pageName, cfg) {
      var stateName;

      if (!cfg.label || !cfg.reportId) {
        return null;
      }
      stateName = 'app.vhr.patient.abstract-grid.grid-' + pageName;
      $stateRegistry.register({
        name: stateName,
        url: pageName.replace(/[^\w\d-]/g, '').toLowerCase() + '/',
        data: { pageTitle: cfg.label }
      });
      return stateName;
    }

    function exposeHieState (pageName, cfg) {
      var config;

      if (!cfg.label) {
        return null;
      }

      // The HIE page represents the entry-point into a workflow. The workflow
      // has its parts tied together via the 'hie' parent state which redirects
      // user to the search.
      config = $state.get('app.vhr.patient.hie');
      config.data = config.data || {};
      config.data.pageTitle = cfg.label;

      return 'app.vhr.patient.hie';
    }

    function exposeReportState (stateName, cfg) {
      var config;

      if (!cfg.label || !cfg.reportId) {
        return null;
      }
      config = $state.get(stateName);
      if (!config) {
        return null;
      }
      config.data = config.data || {};
      config.data.pageTitle = cfg.label;
      return stateName;
    }

    function exposeState (config, cfg) {
      if (!cfg.label) {
        return null;
      }
      config.data = config.data || {};
      config.data.pageTitle = cfg.label;
      return config.name;
    }

    // Exposes abstract summary state, which allows for custom summary pages.
    function exposeSummaryState (pageName, cfg) {
      var stateName;

      if (!cfg.label || !cfg.reportId) {
        return null;
      }
      stateName = 'app.vhr.patient.abstract-summary.summary-' + pageName;
      $stateRegistry.register({
        name: stateName,
        url: pageName.replace(/[^\w\d-]/g, '').toLowerCase() + '/',
        data: { pageTitle: cfg.label }
      });
      return stateName;
    }
  }
})();
