(function () {
  'use strict';

  angular.module('admin')
    .config(config);

  config.$inject = [
    'DASHBOARD', '$stateRegistryProvider'
  ];

  function config (
    DASHBOARD, $stateRegistryProvider
  ) {
    $stateRegistryProvider.register({
      name: 'app.admin',
      abstract: true,
      data: {
        appKey: 'admin',
        pageTitle: 'IMAT Admin',
        roles: ['system'],
        supportedBrowsers: ['chrome', 'firefox', 'edge']
      },
      views: {
        'content@app': {
          templateUrl: '/ui/_shared/layouts/left-sidebar-layout.html',
          controller: 'LeftSidebarCtrl',
          controllerAs: 'ctrl'
        },
        'sidenav@app.admin': {
          templateUrl: '/ui/_shared/layouts/sidebar.html',
          controller: 'SidebarCtrl',
          controllerAs: 'ctrl'
        }
      },
      url: '/admin'
    });
    $stateRegistryProvider.register({
      name: 'app.admin.default',
      data: {
        pageTitle: 'Admin Home',
        roles: ['system', 'inboxes', 'indexer'],
        help: '/ui/documentation/#/advanced/config#home'
      },
      views: {
        'main-content@app.admin': {
          templateUrl: '/ui/admin/home/adminHome.html',
          controller: 'AdminHomeCtrl',
          controllerAs: 'ctrl'
        }
      },
      url: '/'
    });
    $stateRegistryProvider.register({
      name: 'app.admin.users',
      data: {
        pageTitle: 'Users',
        help: '/ui/documentation/#/advanced/admin/users'
      },
      views: {
        'main-content@app.admin': {
          templateUrl: '/ui/admin/users/users.html',
          controller: 'UsersCtrl',
          controllerAs: 'ctrl'
        }
      },
      url: '/users/'
    });
    $stateRegistryProvider.register({
      name: 'app.admin.users.add',
      data: {
        pageTitle: 'Add User',
        back: 'app.admin.users'
      },
      views: {
        'main-content@app.admin': {
          templateUrl: '/ui/admin/users/add.html',
          controller: 'UsersAddCtrl',
          controllerAs: 'ctrl'
        }
      },
      url: 'add/'
    });
    $stateRegistryProvider.register({
      name: 'app.admin.groups',
      data: {
        pageTitle: 'Groups',
        help: '/ui/documentation/#/advanced/admin/groups'
      },
      views: {
        'main-content@app.admin': {
          templateUrl: '/ui/admin/groups/groups.html',
          controller: 'AdminGroupsCtrl',
          controllerAs: 'ctrl'
        }
      },
      url: '/groups/'
    });
    $stateRegistryProvider.register({
      name: 'app.admin.groups.add',
      data: {
        pageTitle: 'Add Group',
        back: 'app.admin.groups'
      },
      views: {
        'main-content@app.admin': {
          templateUrl: '/ui/admin/groups/groupAdd.html',
          controller: 'AdminGroupAddCtrl',
          controllerAs: 'ctrl'
        }
      },
      url: 'add/'
    });
    $stateRegistryProvider.register({
      name: 'app.admin.groups.edit',
      data: {
        pageTitle: 'Edit Group',
        back: 'app.admin.groups'
      },
      params: {
        provider: null,
        groupname: null
      },
      views: {
        'main-content@app.admin': {
          templateUrl: '/ui/admin/groups/groupEdit.html',
          controller: 'AdminGroupEditCtrl',
          controllerAs: 'ctrl'
        }
      },
      url: 'edit/:provider/:groupname/'
    });
    $stateRegistryProvider.register({
      name: 'app.admin.dashboards',
      data: {
        pageTitle: 'Dashboards',
        help: '/ui/documentation/#/advanced/admin/dashboards'
      },
      views: {
        'main-content@app.admin': {
          templateUrl: '/ui/admin/dashboards/admin.html',
          controller: 'DashboardAdminCtrl',
          controllerAs: 'vm'
        }
      },
      url: '/dashboards/',
      redirectTo: 'app.admin.dashboards.users'
    });
    $stateRegistryProvider.register({
      name: 'app.admin.dashboards.users',
      data: {
        dashboardLevel: DASHBOARD.LEVELS.USER
      },
      views: {
        'nav-content@app.admin.dashboards': {
          templateUrl: '/ui/admin/dashboards/users/users.html',
          controller: 'DashboardUsersCtrl',
          controllerAs: 'vm'
        }
      },
      url: 'users/'
    });
    $stateRegistryProvider.register({
      name: 'app.admin.dashboards.groups',
      data: {
        dashboardLevel: DASHBOARD.LEVELS.GROUP
      },
      views: {
        'nav-content@app.admin.dashboards': {
          templateUrl: '/ui/admin/dashboards/groups/groups.html',
          controller: 'DashboardGroupsCtrl',
          controllerAs: 'vm'
        }
      },
      url: 'groups/'
    });
    $stateRegistryProvider.register({
      name: 'app.admin.dashboards.system',
      data: {
        dashboardLevel: DASHBOARD.LEVELS.SYSTEM
      },
      views: {
        'nav-content@app.admin.dashboards': {
          templateUrl: '/ui/admin/dashboards/system/system.html',
          controller: 'DashboardSystemCtrl',
          controllerAs: 'vm'
        }
      },
      url: 'system/'
    });
    $stateRegistryProvider.register({
      name: 'app.admin.access-tokens',
      data: {
        pageTitle: 'Access Tokens',
        help: '/ui/documentation/#/advanced/admin/access-tokens'
      },
      views: {
        'main-content@app.admin': {
          templateUrl: '/ui/admin/access_tokens/access_tokens.html',
          controller: 'accessTokens',
          controllerAs: 'ctrl'
        }
      },
      url: '/access-tokens/'
    });
    $stateRegistryProvider.register({
      name: 'app.admin.configuration',
      data: {
        pageTitle: 'Configuration',
        help: '/ui/documentation/#/advanced/admin/configuration'
      },
      views: {
        'main-content@app.admin': {
          templateUrl: '/ui/admin/configuration/configuration.html',
          controller: 'ConfigCtrl',
          controllerAs: 'ctrl'
        }
      },
      url: '/configuration/'
    });
    $stateRegistryProvider.register({
      name: 'app.admin.collections',
      data: {
        pageTitle: 'Security Collections',
        help: '/ui/documentation/#/advanced/admin/collections'
      },
      views: {
        'main-content@app.admin': {
          templateUrl: '/ui/admin/collections/collections.html',
          controller: 'CollectionsCtrl',
          controllerAs: 'ctrl'
        }
      },
      url: '/collections/'
    });
    $stateRegistryProvider.register({
      name: 'app.admin.collections.create',
      data: {
        pageTitle: 'Add Security Collection',
        back: 'app.admin.collections'
      },
      params: {
        provider: null
      },
      views: {
        'main-content@app.admin': {
          templateUrl: '/ui/admin/collections/create.html',
          controller: 'CreateCollectionCtrl',
          controllerAs: 'ctrl'
        }
      },
      url: 'create/'
    });
    $stateRegistryProvider.register({
      name: 'app.admin.feed-jobs',
      data: {
        pageTitle: 'Feed Jobs',
        roles: []
      },
      views: {
        'main-content@app.admin': {
          templateUrl: '/ui/admin/feed-jobs/feed-jobs.html',
          controller: 'FeedJobsCtrl',
          controllerAs: 'ctrl'
        }
      },
      url: '/feed-jobs/'
    });
    $stateRegistryProvider.register({
      name: 'app.admin.data-management',
      data: {
        pageTitle: 'Data Management',
        roles: ['system', 'indexer'],
        help: '/ui/documentation/#/advanced/admin/data-management'
      },
      views: {
        'main-content@app.admin': {
          templateUrl: '/ui/admin/data-management/data-management.html',
          controller: 'DataManagementCtrl',
          controllerAs: 'ctrl'
        }
      },
      url: '/data-management/',
      redirectTo: 'app.admin.data-management.stores'
    });
    $stateRegistryProvider.register({
      name: 'app.admin.data-management.stores',
      data: {
        pageTitle: 'Stores'
      },
      views: {
        'tab-content@app.admin.data-management': {
          templateUrl: '/ui/admin/data-management/stores/stores.html',
          controller: 'storesCtrl',
          controllerAs: 'ctrl'
        }
      },
      url: 'stores/'
    });
    $stateRegistryProvider.register({
      name: 'app.admin.data-management.records',
      data: {
        pageTitle: 'Records'
      },
      views: {
        'tab-content@app.admin.data-management': {
          templateUrl: '/ui/admin/data-management/records/records.html',
          controller: 'recordsCtrl',
          controllerAs: 'ctrl'
        }
      },
      url: 'records/'
    });
    $stateRegistryProvider.register({
      name: 'app.admin.data-management.builds',
      data: {
        pageTitle: 'Builds'
      },
      views: {
        'tab-content@app.admin.data-management': {
          templateUrl: '/ui/admin/data-management/builds/builds.html',
          controller: 'buildsCtrl',
          controllerAs: 'ctrl'
        }
      },
      url: 'builds/'
    });
    $stateRegistryProvider.register({
      name: 'app.admin.uploader',
      data: {
        pageTitle: 'Uploader',
        roles: ['inboxes'],
        help: '/ui/documentation/#/advanced/admin/uploader'
      },
      views: {
        'main-content@app.admin': {
          templateUrl: '/ui/admin/uploader/uploader.html',
          controller: 'UploaderCtrl',
          controllerAs: 'ctrl'
        }
      },
      url: '/uploader/'
    });
    $stateRegistryProvider.register({
      name: 'app.admin.sharing',
      data: {
        pageTitle: 'Sharing',
        help: '/ui/documentation/#/advanced/admin/sharing'
      },
      views: {
        'main-content@app.admin': {
          templateUrl: '/ui/admin/sharing/reports.html',
          controller: 'ReportsCtrl',
          controllerAs: 'vm'
        }
      },
      url: '/sharing/'
    });
    $stateRegistryProvider.register({
      name: 'app.admin.sharing.share',
      data: {
        pageTitle: 'Edit Access',
        back: 'app.admin.sharing'
      },
      params: {
        reportId: null,
        reportName: null,
        reportOwner: null,
        backAlt: null
      },
      views: {
        'main-content@app.admin': {
          templateUrl: '/ui/admin/sharing/share/share.html',
          controller: 'shareCtrl',
          controllerAs: 'vm'
        }
      },
      url: 'share/:reportId/:reportName/:reportOwner/?'
    });
    $stateRegistryProvider.register({
      name: 'app.admin.symbols',
      data: {
        help: '/ui/documentation/#/advanced/admin/symbols',
        pageTitle: 'Symbols'
      },
      views: {
        'main-content@app.admin': {
          templateUrl: '/ui/admin/symbols/symbols.html',
          controller: 'AdminSymbolsCtrl',
          controllerAs: 'ctrl'
        }
      },
      url: '/symbols/'
    });
    $stateRegistryProvider.register({
      name: 'app.admin.symbols.add',
      data: {
        back: 'app.admin.symbols',
        pageTitle: 'Add Symbol'
      },
      views: {
        'main-content@app.admin': {
          templateUrl: '/ui/admin/symbols/add.html',
          controller: 'AdminSymbolsAddCtrl',
          controllerAs: 'ctrl'
        }
      },
      url: 'add/'
    });
    $stateRegistryProvider.register({
      name: 'app.admin.symbols.categories',
      data: {
        back: 'app.admin.symbols',
        pageTitle: 'Edit Category XML'
      },
      views: {
        'main-content@app.admin': {
          templateUrl: '/ui/admin/symbols/categories.html',
          controller: 'AdminSymbolsCategoriesCtrl',
          controllerAs: 'ctrl'
        }
      },
      url: 'categories/'
    });
    $stateRegistryProvider.register({
      name: 'app.admin.report-containers',
      data: {
        pageTitle: 'Report Containers',
        help: '/ui/documentation/#/advanced/admin/report-containers'
      },
      views: {
        'main-content@app.admin': {
          templateUrl: '/ui/admin/report-containers/report-containers.html',
          controller: 'ReportContainersCtrl',
          controllerAs: 'ctrl'
        }
      },
      url: '/report-containers/'
    });
    $stateRegistryProvider.register({
      name: 'app.admin.report-containers.container',
      data: {
        pageTitle: 'Reports for User',
        back: 'app.admin.report-containers',
        help: '/ui/documentation/#/advanced/admin/report-containers'
      },
      views: {
        'main-content@app.admin': {
          templateUrl: '/ui/admin/report-containers/container/container.html',
          controller: 'ContainerCtrl',
          controllerAs: 'ctrl'
        }
      },
      url: ':id/'
    });
    $stateRegistryProvider.register({
      name: 'app.admin.ctakes',
      data: {
        help: '/ui/documentation/#/advanced/admin/nlp',
        pageTitle: 'NLP Dictionary Editor (cTAKES)',
        roles: ['feeder']
      },
      views: {
        'main-content@app.admin': {
          templateUrl: '/ui/admin/ctakes/ctakes.html',
          controller: 'CtakesCtrl',
          controllerAs: 'ctrl'
        }
      },
      url: '/ctakes/'
    });
    $stateRegistryProvider.register({
      name: 'app.admin.sets',
      data: {
        pageTitle: 'Set Definitions',
        help: '/ui/documentation/#/advanced/admin/sets'
      },
      params: {
        refresh: false
      },
      views: {
        'main-content@app.admin': {
          templateUrl: '/ui/admin/sets/sets-main.html',
          controller: 'SetsMainCtrl',
          controllerAs: 'ctrl'
        }
      },
      url: '/sets/'
    });
    $stateRegistryProvider.register({
      name: 'app.admin.sets.create',
      data: {
        pageTitle: 'Create Set Definition',
        back: 'app.admin.sets',
        help: '/ui/documentation/#/advanced/admin/sets#creating'
      },
      views: {
        'main-content@app.admin': {
          templateUrl: '/ui/admin/sets/create-set/create-set.html',
          controller: 'CreateSetCtrl',
          controllerAs: 'vm'
        }
      },
      url: 'create/'
    });
    $stateRegistryProvider.register({
      name: 'app.admin.sets.edit',
      data: {
        pageTitle: 'Edit Set Definition',
        back: 'app.admin.sets',
        help: '/ui/documentation/#/advanced/admin/sets#editing'
      },
      params: {
        group: null,
        set: null
      },
      views: {
        'main-content@app.admin': {
          templateUrl: '/ui/admin/sets/edit-set/edit-set.html',
          controller: 'EditSetCtrl',
          controllerAs: 'vm'
        }
      },
      url: 'edit/:id'
    });
  }
})();
