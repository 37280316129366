(function () {
  'use strict';

  angular.module('vhr.services')
    .service('vhrCxmlRadiologyRecordClass', vhrCxmlRadiologyRecordClass);

  vhrCxmlRadiologyRecordClass.$inject = [
    'VhrCxmlRecordClass'
  ];

  function vhrCxmlRadiologyRecordClass (
    VhrCxmlRecordClass
  ) {
    function CxmlRadiologyRecord (cfg) {
      VhrCxmlRecordClass.call(this, cfg);
      this.printTemplateUrl = '/ui/vhr/templates/cxml/print/radiology.html';
      this.templateUrl = '/ui/vhr/templates/cxml/cxml-radiology-modal.html';
    }

    CxmlRadiologyRecord.prototype = Object.create(VhrCxmlRecordClass.prototype);
    CxmlRadiologyRecord.prototype.constructor = CxmlRadiologyRecord;

    CxmlRadiologyRecord.prototype.set = function (raw, setFields) {
      if (setFields === true) {
        setFields = ['result', 'ordered_by', 'clinical_date', 'attending_physician'];
      }
      VhrCxmlRecordClass.prototype.set.call(this, raw, setFields);
    };

    return CxmlRadiologyRecord;
  }
})();
