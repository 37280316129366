/* eslint-disable no-multi-spaces */
(function () {
  'use strict';

  angular.module('imat.fhir')
    .factory('fhirDatatypeSrv', fhirDatatypeSrv);

  fhirDatatypeSrv.$inject = ['FHIR_DATATYPES', 'FHIR_EXTENSION_URLS', 'IMAT_FHIR_EXTENSION', 'fhirUtilitySrv', 'fhirValueSetSrv'];

  function fhirDatatypeSrv (FHIR_DATATYPES, FHIR_EXTENSION_URLS, IMAT_FHIR_EXTENSION, fhirUtilitySrv, fhirValueSetSrv) {
    var service;
    var fhirutil = fhirUtilitySrv;

    service = {
      // verifyAsBase64Binary: verifyAsBase64Binary,// Not implemented.
      verifyAsBoolean: verifyAsBoolean,
      // verifyAsCanonical: verifyAsCanonical,// FHIRv4
      verifyAsCode: verifyAsCode,
      verifyAsDate: verifyAsDate,
      verifyAsDateTime: verifyAsDateTime,
      verifyAsDecimal: verifyAsDecimal, // Not implemented.
      verifyAsId: verifyAsId,
      verifyAsInstant: verifyAsInstant, // Not implemented.
      verifyAsInteger: verifyAsInteger,
      // verifyAsMarkdown: verifyAsMarkdown,// Not implemented.
      // verifyAsOid: verifyAsOid,// Not implemented.
      verifyAsPositiveInt: verifyAsPositiveInt,
      verifyAsString: verifyAsString,
      // verifyAsTime: verifyAsTime,// Not implemented.
      verifyAsUnsignedInt: verifyAsUnsignedInt,
      // verifyAsUri: verifyAsUri,// Not implemented.
      // verifyAsUrl: verifyAsUrl,// FHIRv4
      // verifyAsUuid: verifyAsUuid,// FHIRv4

      verifyAsAddress: verifyAsAddress,
      verifyAsCodeableConcept: verifyAsCodeableConcept,
      verifyAsCoding: verifyAsCoding,
      verifyAsContactPoint: verifyAsContactPoint,
      verifyAsExtension: verifyAsExtension,
      verifyAsHumanName: verifyAsHumanName,
      verifyAsIdentifier: verifyAsIdentifier,
      verifyAsMeta: verifyAsMeta,
      verifyAsPeriod: verifyAsPeriod,
      verifyAsReference: verifyAsReference,

      isEmptyExtension: fhirutil.isEmptyExtension,
      isEmptyProperty: fhirutil.isEmptyProperty,
      removeProperties: fhirutil.removeProperties
    };

    return service;
    //= ================================
    // Public interface
    //= ================================

    // ---------------------------------
    // "Primitive" types
    // http://hl7.org/fhir/STU3/datatypes.html#primitive
    // ---------------------------------

    function verifyAsBoolean (wrap) {
      // true | false.
      return verifyAsValue(wrap, function (v) {
        if (typeof v === 'boolean') {
          return true;
        }
        if (!angular.isString(v) || !/^(?:true|false)$/.test(v)) {
          return false;
        }
        wrap.data = v === 'true';// Repair
        return true;
      });
    }

    function verifyAsCode (wrap, regex) {
      // Indicates that the value is taken from a set of controlled strings defined elsewhere (see Using codes for
      // further discussion). Technically, a code is restricted to a string which has at least one character and no
      // leading or trailing whitespace, and where there is no whitespace other than single spaces in the contents.
      return verifyAsString(wrap) &&
        (regex ? regex.test(wrap.data) : /^[^\s]+(?:[\s]?[^\s]+)*$/.test(wrap.data));
    }

    function verifyAsDate (wrap) {
      // A date, or partial date (e.g. just year or year + month) as used in human communication. There is no time zone.
      return verifyAsString(wrap) &&
        /^-?[0-9]{4}(?:-(0[1-9]|1[0-2])(?:-(?:0[0-9]|[1-2][0-9]|3[0-1]))?)?$/.test(wrap.data);
    }

    function verifyAsDateTime (wrap) {
      // A date, date-time or partial date (e.g. just year or year + month) as used in human communication. If hours
      // and minutes are specified, a time zone SHALL be populated. Seconds must be provided due to schema type
      // constraints but may be zero-filled and may be ignored. The time "24:00" is not allowed.
      return verifyAsString(wrap) &&
        /^-?[0-9]{4}(?:-(0[1-9]|1[0-2])(?:-(0[0-9]|[1-2][0-9]|3[0-1])(?:T(?:[01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9](?:\.[0-9]+)?(?:Z|(?:\+|-)(?:(?:0[0-9]|1[0-3]):[0-5][0-9]|14:00)))?)?)?$/.test(wrap.data);
    }

    function verifyAsDecimal (wrap) {
      // Not implemented.

      // See https://www.hl7.org/fhir/json.html#primitive
      // When using a JavaScript JSON.parse() implementation, note that
      // JavaScript natively supports only one numeric datatype, which is a
      // floating point number. This can cause loss of precision for FHIR
      // numbers. In particular, trailing 0s after a decimal point will be lost
      // e.g. 2.00 will be converted to 2. The FHIR decimal data type is
      // defined such that precision, including trailing zeros, is preserved
      // for presentation purposes, and this is widely regarded as critical for
      // correct presentation of clinical measurements. Implementations should
      // consider using a custom parser and big number library to meet these
      // requirements (e.g. https://github.com/jtobey/javascript-bignum).
      return verifyAsValue(wrap, function () {
        return true;// Hope for a rendered-value?
      });
    }

    function verifyAsId (wrap) {
      // Any combination of upper or lower case ASCII letters ('A'..'Z', and 'a'..'z', numerals ('0'..'9'), '-' and '.',
      // with a length limit of 64 characters. (This might be an integer, an un-prefixed OID, UUID or any other
      // identifier pattern that meets these constraints.)
      return verifyAsString(wrap) &&
        /^[A-Za-z0-9\-\.]{1,64}$/.test(wrap.data); //eslint-disable-line
    }

    function verifyAsInstant (wrap) {
      // An instant in time - known at least to the second and always includes a time zone.
      return verifyAsString(wrap);//* &&
      // /^([0-9]([0-9]([0-9][1-9]|[1-9]0)|[1-9]00)|[1-9]000)-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])T([01][0-9]|2[0-3]):[0-5][0-9]:([0-5][0-9]|60)(\.[0-9]+)?(Z|(\+|-)((0[0-9]|1[0-3]):[0-5][0-9]|14:00))$/.test(wrap.data);
    }

    function verifyAsInteger (wrap) {
      // A signed 32-bit integer (for larger values, use decimal)
      return verifyAsValue(wrap, function (v) {
        var isValid = isFinite(v) && v != null && v >= -2147483648 && v <= 2147483647;
        if (isValid && angular.isString(v)) {
          wrap.data = parseInt(v, 10);// Repair
        }
        return isValid;
      });
    }

    function verifyAsPositiveInt (wrap) {
      return verifyAsInteger(wrap) && wrap.data >= 1;
    }

    function verifyAsString (wrap, value) {
      return verifyAsValue(wrap, function (v) {
        return angular.isString(v) && v.length <= 1048576 && (value ? v === value : true);
      });
    }

    function verifyAsUnsignedInt (wrap) {
      return verifyAsInteger(wrap) && wrap.data >= 0;
    }

    // ---------------------------------
    // Complex types
    // http://hl7.org/fhir/STU3/datatypes.html#complex
    // ---------------------------------

    function verifyAsAddress (wrap) {
      // http://hl7.org/fhir/STU3/datatypes.html#Address
      // {
      //   "use" : "<code>", // home | work | temp | old - purpose of this address
      //   "type" : "<code>", // postal | physical | both
      //   "text" : "<string>", // Text representation of the address
      //   "line" : ["<string>"], // Street name, number, direction & P.O. Box etc.
      //   "city" : "<string>", // Name of city, town etc.
      //   "district" : "<string>", // District name (aka county)
      //   "state" : "<string>", // Sub-unit of country (abbreviations ok)
      //   "postalCode" : "<string>", // Postal code for area
      //   "country" : "<string>", // Country (e.g. can be ISO 3166 2 or 3 letter code)
      //   "period" : { Period } // Time period when address was/is in use
      // }
      var results;

      if (!verifyAsElement(wrap)) { return false; }

      results = fhirutil.verifyProperties(wrap.data, [
        { property: 'use',          isRequired: false, isArray: false, validate: verifyAsCode, args: [fhirValueSetSrv.getValueSetRegex('ADDRESS_USE')] },
        { property: 'type',         isRequired: false, isArray: false, validate: verifyAsCode, args: [fhirValueSetSrv.getValueSetRegex('ADDRESS_TYPE')] },
        { property: 'text',         isRequired: false, isArray: false, validate: verifyAsString },
        { property: 'line',         isRequired: false, isArray: true,  validate: verifyAsString },
        { property: 'city',         isRequired: false, isArray: false, validate: verifyAsString },
        { property: 'district',     isRequired: false, isArray: false, validate: verifyAsString },
        { property: 'state',        isRequired: false, isArray: false, validate: verifyAsString },
        { property: 'postalCode',   isRequired: false, isArray: false, validate: verifyAsString },
        { property: 'country',      isRequired: false, isArray: false, validate: verifyAsString },
        { property: 'period',       isRequired: false, isArray: false, validate: verifyAsPeriod }
      ]);
      return fhirutil.analyzeResults(wrap.data, results);
    }

    function verifyAsCodeableConcept (wrap, regex) {
      // http://hl7.org/fhir/STU3/datatypes.html#CodeableConcept
      // {
      //   "coding" : [{ Coding }], // Code defined by a terminology system
      //   "text" : "<string>" // Plain text representation of the concept
      // }
      var results;

      if (!verifyAsElement(wrap)) { return false; }

      results = fhirutil.verifyProperties(wrap.data, [
        { property: 'coding', isRequired: false, isArray: true,  validate: verifyAsCoding, args: [regex] },
        { property: 'text',   isRequired: false, isArray: false, validate: verifyAsString }
      ]);
      return fhirutil.analyzeResults(wrap.data, results);
    }

    function verifyAsCoding (wrap, regex) {
      // http://hl7.org/fhir/STU3/datatypes.html#Coding
      // {
      //   "system" : "<uri>", // Identity of the terminology system
      //   "version" : "<string>", // Version of the system - if relevant
      //   "code" : "<code>", // Symbol in syntax defined by the system
      //   "display" : "<string>", // Representation defined by the system
      //   "userSelected" : <boolean> // If this coding was chosen directly by the user
      // }
      var results;

      if (!verifyAsElement(wrap)) { return false; }

      results = fhirutil.verifyProperties(wrap.data, [
        { property: 'system',       isRequired: false, isArray: false, validate: verifyAsString },
        { property: 'version',      isRequired: false, isArray: false, validate: verifyAsString },
        { property: 'code',         isRequired: false, isArray: false, validate: verifyAsCode, args: [regex] },
        { property: 'display',      isRequired: false, isArray: false, validate: verifyAsString },
        { property: 'userSelected', isRequired: false, isArray: false, validate: verifyAsBoolean }
      ]);
      return fhirutil.analyzeResults(wrap.data, results);
    }

    function verifyAsContactPoint (wrap) {
      // http://hl7.org/fhir/STU3/datatypes.html#ContactPoint
      // {
      //   "system" : "<code>", // C? phone | fax | email | pager | url | sms | other
      //   "value" : "<string>", // The actual contact point details
      //   "use" : "<code>", // home | work | temp | old | mobile - purpose of this contact point
      //   "rank" : "<positiveInt>", // Specify preferred order of use (1 = highest)
      //   "period" : { Period } // Time period when the contact point was/is in use
      // }
      var results;

      if (!verifyAsElement(wrap)) { return false; }

      results = fhirutil.verifyProperties(wrap.data, [
        { property: 'system',       isRequired: false, isArray: false, validate: verifyAsCode, args: [fhirValueSetSrv.getValueSetRegex('CONTACT_POINT_SYSTEM')] },
        { property: 'value',        isRequired: false, isArray: false, validate: verifyAsString },
        { property: 'use',          isRequired: false, isArray: false, validate: verifyAsCode, args: [fhirValueSetSrv.getValueSetRegex('CONTACT_POINT_USE')] },
        { property: 'rank',         isRequired: false, isArray: false, validate: verifyAsPositiveInt },
        { property: 'period',       isRequired: false, isArray: false, validate: verifyAsPeriod }
      ]);
      fhirutil.andProperties(wrap.data, results, [
        { property: 'value', properties: ['system'] }// The `value` property is dependent upon the `system` property.
      ]);
      return fhirutil.analyzeResults(wrap.data, results);
    }

    function verifyAsExtension (wrap) { // jshint ignore:line
      // {
      //   "url" : "<uri>", // R!  identifies the meaning of the extension
      //   // value[x]: Value of extension. One of these 23:
      //   "valueInteger" : <integer>
      //   "valueDecimal" : <decimal>
      //   "valueDateTime" : "<dateTime>"
      //   "valueDate" : "<date>"
      //   "valueInstant" : "<instant>"
      //   "valueString" : "<string>"
      //   "valueUri" : "<uri>"
      //   "valueBoolean" : <boolean>
      //   "valueCode" : "<code>"
      //   "valueBase64Binary" : "<base64Binary>"
      //   "valueCoding" : { Coding }
      //   "valueCodeableConcept" : { CodeableConcept }
      //   "valueAttachment" : { Attachment }
      //   "valueIdentifier" : { Identifier }
      //   "valueQuantity" : { Quantity }
      //   "valueRange" : { Range }
      //   "valuePeriod" : { Period }
      //   "valueRatio" : { Ratio }
      //   "valueHumanName" : { HumanName }
      //   "valueAddress" : { Address }
      //   "valueContactPoint" : { ContactPoint }
      //   "valueSchedule" : { Schedule }
      //   "valueReference" : { Reference }
      // }
      var results;

      if (!verifyAsElement(wrap)) { return false; }

      results = fhirutil.verifyProperties(wrap.data, [
        { property: 'url',                  isRequired: true,  isArray: false, validate: verifyAsString },
        { property: 'valueInteger',         isRequired: false, isArray: false, validate: verifyAsInteger },
        // { property: 'valueDecimal',         isRequired: false, isArray: false, validate: verifyAsDecimal },
        { property: 'valueDateTime',        isRequired: false, isArray: false, validate: verifyAsDateTime },
        { property: 'valueDate',            isRequired: false, isArray: false, validate: verifyAsDate },
        { property: 'valueInstant',         isRequired: false, isArray: false, validate: verifyAsInstant },
        { property: 'valueString',          isRequired: false, isArray: false, validate: verifyAsString },
        // { property: 'valueUri',             isRequired: false, isArray: false, validate: verifyAsUri },
        { property: 'valueBoolean',         isRequired: false, isArray: false, validate: verifyAsBoolean },
        { property: 'valueCode',            isRequired: false, isArray: false, validate: verifyAsCode },
        // { property: 'valueBase64Binary',    isRequired: false, isArray: false, validate: verifyAsBase64Binary },
        { property: 'valueCoding',          isRequired: false, isArray: false, validate: verifyAsCoding },
        { property: 'valueCodeableConcept', isRequired: false, isArray: false, validate: verifyAsCodeableConcept },
        // { property: 'valueAttachment',      isRequired: false, isArray: false, validate: verifyAsAttachment },
        { property: 'valueIdentifier',      isRequired: false, isArray: false, validate: verifyAsIdentifier },
        // { property: 'valueQuantity',        isRequired: false, isArray: false, validate: verifyAsQuantity },
        // { property: 'valueRange',           isRequired: false, isArray: false, validate: verifyAsRange },
        { property: 'valuePeriod',          isRequired: false, isArray: false, validate: verifyAsPeriod },
        // { property: 'valueRatio',           isRequired: false, isArray: false, validate: verifyAsRatio },
        { property: 'valueHumanName',       isRequired: false, isArray: false, validate: verifyAsHumanName },
        { property: 'valueAddress',         isRequired: false, isArray: false, validate: verifyAsAddress },
        { property: 'valueContactPoint',    isRequired: false, isArray: false, validate: verifyAsContactPoint },
        // { property: 'valueSchedule',        isRequired: false, isArray: false, validate: verifyAsSchedule },
        { property: 'valueReference',       isRequired: false, isArray: false, validate: verifyAsReference }
      ]);
      fhirutil.xorProperties(wrap.data, results, 'value', []);// Empty preferences will invalidate if 2+ XOR properties are present.

      if (wrap.data.extension && Object.keys(wrap.data).filter(function (key) { return /^value/.test(key); }).length) {
        results.push({
          property: 'extension',
          isValid: false,
          reason: 'XOR with value[x]',
          value: wrap.data.extension
        });
      }

      return fhirutil.analyzeResults(wrap.data, results);
    }

    function verifyAsHumanName (wrap) {
      // http://hl7.org/fhir/STU3/datatypes.html#HumanName
      // {
      //   "use" : "<code>", // usual | official | temp | nickname | anonymous | old | maiden
      //   "text" : "<string>", // Text representation of the full name
      //   "family" : "<string>", // Family name (often called 'Surname')
      //   "given" : ["<string>"], // Given names (not always 'first'). Includes middle names
      //   "prefix" : ["<string>"], // Parts that come before the name
      //   "suffix" : ["<string>"], // Parts that come after the name
      //   "period" : { Period } // Time period when name was/is in use
      // }
      var results;

      if (!verifyAsElement(wrap)) { return false; }

      results = fhirutil.verifyProperties(wrap.data, [
        { property: 'use',          isRequired: false, isArray: false, validate: verifyAsCode, args: [fhirValueSetSrv.getValueSetRegex('NAME_USE')] },
        { property: 'text',         isRequired: false, isArray: false, validate: verifyAsString },
        { property: 'family',       isRequired: false, isArray: false, validate: verifyAsString },
        { property: 'given',        isRequired: false, isArray: true,  validate: verifyAsString },
        { property: 'prefix',       isRequired: false, isArray: true,  validate: verifyAsString },
        { property: 'suffix',       isRequired: false, isArray: true,  validate: verifyAsString },
        { property: 'period',       isRequired: false, isArray: false, validate: verifyAsPeriod }
      ]);
      return fhirutil.analyzeResults(wrap.data, results);
    }

    function verifyAsIdentifier (wrap) {
      // {
      //   "use" : "<code>", // usual | official | temp | secondary (If known)
      //   "type" : { CodeableConcept }, // Description of identifier
      //   "system" : "<uri>", // The namespace for the identifier value
      //   "value" : "<string>", // The value that is unique
      //   "period" : { Period }, // Time period when id is/was valid for use
      //   "assigner" : { Reference(Organization) } // Organization that issued id (may be just text)
      // }
      var results;

      if (!verifyAsElement(wrap)) { return false; }

      results = fhirutil.verifyProperties(wrap.data, [
        { property: 'use',      isRequired: false, isArray: false, validate: verifyAsCode, args: [fhirValueSetSrv.getValueSetRegex('IDENTIFIER_USE')] },
        { property: 'type',     isRequired: false, isArray: false, validate: verifyAsCodeableConcept },
        { property: 'system',   isRequired: false, isArray: false, validate: verifyAsString },
        { property: 'value',    isRequired: false, isArray: false, validate: verifyAsString },
        { property: 'period',   isRequired: false, isArray: false, validate: verifyAsPeriod },
        { property: 'assigner', isRequired: false, isArray: false, validate: verifyAsReference }
      ]);
      return fhirutil.analyzeResults(wrap.data, results);
    }

    function verifyAsMeta (wrap) { // jshint ignore:line
      // http://hl7.org/fhir/STU3/resource.html#Meta
      // {
      //   "versionId" : "<id>", // Version specific identifier
      //   "lastUpdated" : "<instant>", // When the resource version last changed
      //   "profile" : ["<uri>"], // Profiles this resource claims to conform to
      //   "security" : [{ Coding }], // Security Labels applied to this resource
      //   "tag" : [{ Coding }] // Tags applied to this resource
      // }
      var results;

      if (!verifyAsElement(wrap)) { return false; }

      results = fhirutil.verifyProperties(wrap.data, [
        { property: 'versionId',   isRequired: false, isArray: false, validate: verifyAsId },
        { property: 'lastUpdated', isRequired: false, isArray: false, validate: verifyAsInstant },
        { property: 'profile',     isRequired: false, isArray: true,  validate: verifyAsString },
        { property: 'security',    isRequired: false, isArray: true,  validate: verifyAsCoding },
        { property: 'tag',         isRequired: false, isArray: true,  validate: verifyAsCoding }
      ]);
      return fhirutil.analyzeResults(wrap.data, results);
    }

    function verifyAsPeriod (wrap) {
      // http://hl7.org/fhir/STU3/datatypes.html#Period
      // {
      //   "start" : "<dateTime>", // C? Starting time with inclusive boundary
      //   "end" : "<dateTime>" // C? End time with inclusive boundary, if not ongoing
      // }
      var results;

      if (!verifyAsElement(wrap)) { return false; }

      results = fhirutil.verifyProperties(wrap.data, [
        { property: 'start', isRequired: false, isArray: false, validate: verifyAsDateTime },
        { property: 'end',   isRequired: false, isArray: false, validate: verifyAsDateTime }
      ]);
      return fhirutil.analyzeResults(wrap.data, results);
    }

    function verifyAsReference (wrap) {
      // http://hl7.org/fhir/STU3/references.html#Reference
      // {
      //   "reference" : "<string>", // C? Literal reference, Relative, internal or absolute URL
      //   "identifier" : { Identifier }, // Logical reference, when literal reference is not known
      //   "display" : "<string>" // Text alternative for the resource
      // }
      var results;

      if (!verifyAsElement(wrap)) { return false; }

      results = fhirutil.verifyProperties(wrap.data, [
        { property: 'reference',  isRequired: false, isArray: false, validate: verifyAsString },
        { property: 'identifier', isRequired: false, isArray: false, validate: verifyAsIdentifier },
        { property: 'display',    isRequired: false, isArray: false, validate: verifyAsString }
      ]);
      return fhirutil.analyzeResults(wrap.data, results);
    }

    //= ================================
    // Private interface
    //= ================================

    function verifyAsElement (wrap) { // jshint ignore:line
      // {
      //   "id" : "<id>", // Logical id of this artifact
      //   "extension" : [{ Extension }], // Additional content defined by implementations
      // }
      var results;

      results = fhirutil.verifyProperties(wrap.data, [
        { property: 'id',        isRequired: false, isArray: false, validate: verifyAsString },
        { property: 'extension', isRequired: false, isArray: true,  validate: verifyAsExtension }
      ]);
      return fhirutil.analyzeResults(wrap.data, results);
    }

    function verifyAsValue (wrap, validator) {
      // var exts;

      if (angular.isArray(wrap.data)) { return false; }

      // A primitve (at obj.key) usually has a value. If it does not, but has
      // other attributes and/or children, then those attributes/children are
      // preserved in a new obj._key created for them. If the primitive is an
      // element in an array and has only a value, its index in obj._key is
      // null. See http://hlt.org/fhir/json.html#primitive
      if (angular.isObject(wrap.data)) {
        if (!verifyAsElement(wrap)) { return false; }
      }
      // Move the object to _key, and set the primitive value, if given.
      // NOTE that this may remove/delete the key if there was no value.
      wrap.setPrimitive();

      // XXX The following is probably not good form: exchanging the rendered
      // value for the *actual* value could cause a lot of confusion when
      // saving data or in other contexts (permissions?). We could *possibly*
      // ease the discovery of rendered values by finding and storing them in a
      // top-level property on the _key, like:
      // _key: { imatRenderedValue: "bar", extension: [{ ... }] }

      // TODO Check for proper context before using rendered-value: canonical,
      // code, date, dateTime, decimal, instant, integer, string, time, and
      // Identifier.value

      // If null/undefined, and a rendered-value is given, then substitute it.
      // if (!wrap.isDefined && wrap._data && Array.isArray(wrap._data[IMAT_FHIR_EXTENSION.ELEMENT])) {
      //   exts = wrap._data[IMAT_FHIR_EXTENSION.ELEMENT];

      //   if (exts && exts.length) {
      //     for (var i = 0, ii = exts.length; i < ii; ++i) {
      //       if (exts[i].url === FHIR_EXTENSION_URLS.RENDERED_VALUE && exts[i].valueString) {
      //         wrap.data = exts[i].valueString;
      //         break;
      //       }
      //     }
      //   }
      // }

      // If setPrimitive() removed the value, it can be valid only if it is not
      // required which, currently, we cannot determine here. That said, most
      // values in FHIR are optional, so assume that.
      return (wrap.isDefined ? validator(wrap.data) : true);
    }
  }
})();
