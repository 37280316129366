(function () {
  'use strict';

  angular.module('vhr', [
    /* Angular modules */
    'ngAnimate',
    'ngMaterial',
    'ngMessages',
    'ngSanitize',

    /* IMAT modules */
    'imat.services', // dateTimeSrv, DownloaderSrv, psNotification, psPhiAccess, psReports, subscriptionsSrv

    /* 3rd party modules */
    'ui.grid',
    'ui.grid.autoResize',
    'ui.grid.cellNav',
    'ui.grid.exporter',
    'ui.grid.grouping',
    'ui.grid.pagination',
    'ui.grid.pinning',
    'ui.grid.resizeColumns',
    'ui.grid.selection',
    'ui.router',
    'ngXslt',

    /* VHR submodules */
    'vhr.constants',
    'vhr.services',
    'vhr.directives'
  ]);
})();
