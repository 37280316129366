(function () {
  'use strict';

  angular.module('admin')
    .controller('accessTokens', accessTokens);

  accessTokens.$inject = ['$filter', '$mdDialog', '$mdSidenav', '$scope', 'authTokensSrv', 'ProvidersSrv', 'psNotification'];

  function accessTokens ($filter, $mdDialog, $mdSidenav, $scope, authTokensSrv, ProvidersSrv, psNotification) {
    var vm = this; // eslint-disable-line no-invalid-this

    // Properties
    vm.LOG_TYPES = [
      { value: '', display: 'None' },
      { value: 'BulkETL', display: 'BulkETL' },
      { value: 'Custom', display: 'Custom' }
    ];
    vm.authTokens = [];
    vm.data = '';
    vm.loadingTokens = false;
    vm.providers = [];
    vm.reloadTokens = false;
    vm.searchTokenText = '';
    vm.selectedFilter = '';
    vm.selectedToken = {};
    vm.tokenListFilters = [];

    // Methods
    vm.clearSelection = clearSelection;
    vm.confirm = confirmDeleteToken;
    vm.create = createToken;
    vm.delete = deleteToken;
    vm.filterTokenList = filterTokenList;
    vm.refreshList = refreshList;
    vm.resetFilters = resetFilters;
    vm.selectToken = selectToken;
    vm.textFilterList = textFilterList;

    activate();

    function activate () {
      refreshList(false);
    }

    // =======================================
    // Public Methods
    // =======================================

    function clearSelection () {
      if (vm.selectedToken) {
        delete vm.selectedToken.selected;
        vm.selectedToken = {};
      }
      $mdSidenav('tokenDetails').close();
    }

    function confirmDeleteToken (ev, id) {
      var confirm = $mdDialog.confirm()
        .title('Confirm Delete')
        .textContent('Are you sure? This action cannot be undone.')
        .ariaLabel('Confirm Delete')
        .targetEvent(ev)
        .ok('Delete')
        .cancel('Cancel');

      $mdDialog.show(confirm)
        .then(function () {
          deleteToken(id);
        })
        .catch(function () {
          return false;
        });
    }

    function createToken (ev) {
      $mdDialog.show({
        controller: 'CreateTokenCtrl',
        controllerAs: 'ctrl',
        templateUrl: '/ui/admin/access_tokens/create-token-dialog.html',
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: false,
        locals: {
          logTypes: vm.LOG_TYPES,
          providers: vm.providers
        }
      })
        .then(function () {
          refreshList(true);
        });
    }

    function deleteToken (id) {
      authTokensSrv.deleteToken(id)
        .then(function () {
          refreshList(true);
          psNotification.success('Token ' + id + ' deleted.');
        })
        .catch(function () {
          psNotification.error('Unable to delete the token ' + id + '.');
        })
        .finally(function () {
          clearSelection();
          $mdSidenav('tokenDetails').close();
        });
    }

    function filterTokenList (token) {
      return (vm.selectedFilter) ? token[vm.selectedFilter.key] === vm.selectedFilter.value : true;
    }

    function refreshList (force) {
      vm.loadingTokens = true;
      authTokensSrv.getList(force).then(function (tokens) {
        vm.authTokens = tokens;
        generateFilters();
      }).catch(function () {
        psNotification.error('Unable to retrieve the auth tokens list.');
      }).finally(function () {
        vm.loadingTokens = false;
      });
    }

    function resetFilters () {
      vm.searchTokenText = '';
      vm.selectedFilter = '';
    }

    function selectToken (token) {
      if (vm.selectedToken) {
        clearSelection();
      }
      vm.selectedToken = token;
      vm.selectedToken.selected = true;
      $mdSidenav('tokenDetails').open();
    }

    function textFilterList (token) {
      if (!vm.searchTokenText) {
        return true;
      }
      return token.username.toLowerCase().indexOf(vm.searchTokenText.toLowerCase()) !== -1 ? true : token.id.toLowerCase().indexOf(vm.searchTokenText.toLowerCase()) !== -1;
    }

    // =======================================
    // Private Methods
    // =======================================

    function generateFilters () {
      const tempListFilters = [];
      vm.selectedFilter = '';
      ProvidersSrv.getProviderList()
        .then(function (providers) {
          vm.providers = providers;
          if (providers.length > 1) {
            providers.forEach(function (provider) {
              tempListFilters.push({
                name: 'Provider: ' + provider,
                key: 'provider',
                value: provider
              });
            });
          }
        });

      const loggingTypes = [];
      vm.authTokens.forEach(function (token) {
        if (loggingTypes.indexOf(token.logType) < 0) {
          loggingTypes.push(token.logType);
        }
      });
      loggingTypes.forEach(function (logType) {
        tempListFilters.push({
          name: 'Log Type: ' + (logType || 'None'),
          key: 'logType',
          value: logType
        });
      });

      vm.tokenListFilters = tempListFilters;
    }
  }
})();
