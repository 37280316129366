(function () {
  'use strict';

  angular
    .module('imat.services')
    .factory('profileApiSrv', profileApiSrv);

  profileApiSrv.$inject = ['$http'];

  function profileApiSrv ($http) {
    var baseUrl = '/api/appliance/profile';

    var defaultHeaders = {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
    };

    var service = {
      get: get,
      getProfileGroups: getProfileGroups,
      getRoles: getRoles,
      saveProfile: saveProfile
    };

    return service;

    function get () {
      return $http.get(baseUrl);
    }

    function getProfileGroups () {
      return $http.get([baseUrl, 'groups'].join('/'));
    }

    function getRoles () {
      return $http.get([baseUrl, 'roles'].join('/'));
    }

    function saveProfile (data) {
      var url = '/appliance/profile'; // We use a different URL than baseUrl to bypass pylons.
      return $http.put(url, data, {
        headers: defaultHeaders
      });
    }
  }
})();
