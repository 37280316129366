(function () {
  'use strict';

  angular.module('vhr.services')
    .service('vhrCxmlMedAdministerRecordClass', vhrCxmlMedAdministerRecordClass);

  vhrCxmlMedAdministerRecordClass.$inject = [
    'VhrCxmlRecordClass'
  ];

  function vhrCxmlMedAdministerRecordClass (
    VhrCxmlRecordClass
  ) {
    function CxmlMedAdministerRecord (cfg) {
      VhrCxmlRecordClass.call(this, cfg);
      this.controller = 'CxmlMedAdministerModalCtrl';
      this.printTemplateUrl = '/ui/vhr/templates/medications/print/med-administer.html';
      this.templateUrl = '/ui/vhr/templates/medications/cxml-med-administer-modal.html';
    }

    CxmlMedAdministerRecord.prototype = Object.create(VhrCxmlRecordClass.prototype);
    CxmlMedAdministerRecord.prototype.constructor = CxmlMedAdministerRecord;

    CxmlMedAdministerRecord.prototype.set = function (raw, setFields) {
      if (setFields === true) {
        setFields = [];
      }
      VhrCxmlRecordClass.prototype.set.call(this, raw, setFields);
    };

    return CxmlMedAdministerRecord;
  }
})();
