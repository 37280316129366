(function () {
  'use strict';

  angular.module('admin')
    .controller('ChangeNameModalCtrl', ChangeNameModalCtrl);

  ChangeNameModalCtrl.$inject = ['$scope', '$mdDialog', 'name'];
  function ChangeNameModalCtrl ($scope, $mdDialog, name) {
    $scope.name = name;

    $scope.cancel = function () {
      $mdDialog.cancel();
    };

    $scope.save = function () {
      $mdDialog.hide($scope.name);
    };
  }
})();
