(function () {
  'use strict';

  angular.module('dashboardApp')
    .controller('DashboardListCtrl', DashboardListCtrl);

  DashboardListCtrl.$inject = [
    'DASHBOARD',
    '$q', '$scope', '$state', '$transition$',
    'DashboardItemClass', 'DashboardsSrv', 'imatConfig', 'Preferences', 'psNotification', 'userSession'
  ];

  function DashboardListCtrl (
    DASHBOARD,
    $q, $scope, $state, $transition$,
    DashboardItemClass, DashboardsSrv, imatConfig, Preferences, psNotification, userSession
  ) {
    var vm = this;
    var MSG_BASE_ADMIN = 'No viewable dashboard could be found.';
    var MSG_BASE_ERROR = 'No viewable dashboard could be found. If the problem persists, please contact your system administrator.';
    var MSG_LOAD_ERROR = 'There was a problem loading the dashboard, please try again later. If the problem persists, please contact your system administrator.';
    var STATE_BASE = $state.$current.name === 'app.dashboard.base';

    // Properties
    vm.ACCESS = DashboardsSrv.ACCESS;
    vm.CATEGORY = DashboardItemClass.prototype.CATEGORY;
    vm.IMAGE_TYPES = DashboardItemClass.prototype.IMAGE;
    vm.LEVELS = DashboardsSrv.LEVELS;

    vm.banner = {};
    $scope.$parent.canCreate = false;
    $scope.$parent.canEdit = false;
    vm.dashboard = DashboardsSrv.construct();
    $scope.$parent.dashboardId = null;
    vm.dashboardItems = { links: [], tiles: [] };
    $scope.$parent.dashboardList = [];
    $scope.$parent.exclusiveId = null;
    $scope.$parent.helpURL = imatConfig.get('apps.dashboard.customerHelpLink');
    vm.isLoading = false;
    vm.itemFilter = { items: [] };
    vm.itemSequence = { items: [] };
    vm.user = userSession.getUserData();

    // Methods
    $scope.$parent.createDashboardForUser = createDashboardForUser;
    vm.excludeItem = excludeItem;
    $scope.$parent.excludeItems = excludeItems;

    activate();

    function activate () {
      if (STATE_BASE) {
        vm.banner.icon = 'warning';
        vm.banner.message = MSG_BASE_ERROR;

        if (userSession.userIsAdmin()) {
          vm.banner.message = MSG_BASE_ADMIN;
          vm.banner.link = {
            location: $state.href('app.admin.dashboards'),
            text: 'Dashboard Administration'
          };
        }
        return;
      }

      vm.isLoading = true;

      getDashboardList()
        .then(function (dashboardList) {
          if (!dashboardList.length) {
            return $state.go('app.dashboard.base', null, { location: 'replace' });
          }

          vm.dashboard = dashboardList[0];

          $scope.$parent.dashboardId = $transition$.params().dashboardId || vm.dashboard.id;
          $scope.$parent.dashboardList = dashboardList;// Does not include items.

          if (vm.dashboard.level === DashboardsSrv.LEVELS.USER) {
          /* jslint bitwise: true */
            $scope.$parent.canEdit = (vm.dashboard.effective_permissions & vm.ACCESS.WRITE) === vm.ACCESS.WRITE;
          /* jslint bitwise: false */
          }

          // Build a composite view of all of the user's accessible dashboards.
          return getDashboardItems(dashboardList);
        })
        .then(function (items) {
          items.forEach(function (item) {
            if ([vm.CATEGORY.PARAMETERIZED, vm.CATEGORY.CR_REPORT, vm.CATEGORY.CR_RESULT].indexOf(item.cfg.category) >= 0) {
              vm.dashboardItems.tiles.push(item);
            } else {
              vm.dashboardItems.tiles.push(item);
            }
            if (!item.ui.filtered) {
              $scope.$parent.dashboardList[item.ui.idx].tiles.length += 1;
            }
          });
          $scope.$parent.dashboardList[0].tiles.length += 1;// Ensure that the top dashboard is always visible.
          getDashboardPreference();
        })
        .then(checkCanCreateDashboard)
        .then(function (canCreate) {
          $scope.$parent.canCreate = canCreate;
        })
        .catch(function () {
          vm.banner.icon = 'warning';
          vm.banner.message = MSG_LOAD_ERROR;
          return $q.reject();
        })
        .finally(function () {
          vm.isLoading = false;
        });
    }

    //= ================================
    // Public interface
    //= ================================

    function createDashboardForUser (clone) {
      var items = [].concat(vm.dashboardItems.links, vm.dashboardItems.tiles)
        .filter(function (i) { return i.ui.ref.id === $scope.$parent.exclusiveId; });

      var dashboard = DashboardsSrv.construct({
        id: null,
        group: null,
        level: DashboardsSrv.LEVELS.USER,
        owner: vm.user.username, // $save fixes this to be username@provider
        provider: vm.user.provider,
        tiles: (clone ? angular.copy(items) : [])
      });

      // DashboardsSrv.getDashboard(dashboard)
      // .then(function() {
      //   psNotification.error('A dashboard already exists for this user.');
      // }, function() {
      DashboardsSrv.save(dashboard)
        .then(function (mwDashboard) {
          // The dashboard has been reloaded with default permsissions...
          dashboard = mwDashboard;
          dashboard.owner_permissions = DashboardsSrv.ACCESS.FULL;
          return DashboardsSrv.savePermissions(dashboard);
        })
        .catch(function () {
          psNotification.error('Unable to create the dashboard.');
          if (dashboard.id) {
            DashboardsSrv.delete(dashboard);
          }
        })
        .then(function () {
          DashboardsSrv.reset();
          $state.go('app.dashboard.main', null, { reload: true });
        });
      // });
    }

    function excludeItem (item) {
      return item.ui.ref.id !== $scope.$parent.exclusiveId;
    }

    function excludeItems (exclusiveId) {
      $scope.$parent.exclusiveId = exclusiveId;
      Preferences.setPreference('dashboard', exclusiveId);
    }

    //= ================================
    // Private interface
    //= ================================

    function checkCanCreateDashboard () {
      // If THIS dashboard is not a user-level dashboard...
      if (vm.dashboard.level === DashboardsSrv.LEVELS.USER) {
        return $q.resolve(false);
      }
      // ...and we cannot find the user's personal dashboard (i.e., it does not exist)
      if ($scope.$parent.dashboardList.length &&
          $scope.$parent.dashboardList[0].owner === vm.userOwner &&
          $scope.$parent.dashboardList[0].level === DashboardsSrv.LEVELS.USER
      ) {
        return $q.resolve(false);
      }
      // ...and the user is allowed to create a personal dashboard (i.e., getDashboardSettings.can_create = true)
      // ...then allow the user to create a personal dashboard...
      return DashboardsSrv.getSettings()
        .then(function (dashboardSettings) {
          return (angular.isString(dashboardSettings.can_create) ? /t|true/i.test(dashboardSettings.can_create) : !!dashboardSettings.can_create);
        });
    }

    function getDashboardItems (dashboardList) {
      var dashboardItems = [];
      var promises = dashboardList.map(function (dashboard) { return DashboardsSrv.getDashboard(dashboard); });

      return $q.all(promises)
        .then(function (all) {
          vm.itemFilter.items.splice(0);

          all.forEach(function (dashboard, idx) {
            var owned = vm.dashboard.id === dashboard.id;
            // Cellect all the edit-relevant items.
            dashboardList[idx].tiles.length = 0;
            dashboard.tiles.forEach(function (mwItem) {
              var item = angular.copy(mwItem);

              if (owned && item.type === DASHBOARD.ITEMS.TYPES.FILTER) {
                vm.itemFilter = item;
                return;// continue
              }
              if (owned && item.type === DASHBOARD.ITEMS.TYPES.SEQUENCE) {
                vm.itemSequence = item;
                return;// continue
              }
              // Exclude inherited non-items.
              if (item.type === DASHBOARD.ITEMS.TYPES.FILTER || item.type === DASHBOARD.ITEMS.TYPES.SEQUENCE) {
                return;// continue
              }
              if (!owned && (item.error || item.unsupported)) {
                return;// continue
              }

              item.ui.idx = idx;
              item.ui.ref = dashboard;
              item.ui.refs = [dashboard];
              item.ui.errs = [item.error];

              item.ui.filtered = false;
              item.ui.inherited = !owned;
              item.ui.owned = owned;

              dashboardItems.push(item);
            });
          });

          // Sort "broken" items downward so that broken items
          // are matched against working items when de-duping.
          dashboardItems.sort(sortItemsBrokenDown);

          dashboardItems = dashboardItems.filter(markFilteredItems);// Doesn't remove, just tags.
          dashboardItems = dashboardItems.filter(removeDuplicateItems);

          // Remove unused filters...
          vm.itemFilter.items = vm.itemFilter.items.filter(function (f) { return f.matched; });

          // Sort according to user preference...
          if (vm.itemSequence && vm.itemSequence.items.length) {
            dashboardItems.sort(sortItemsInSequence);
          }

          return dashboardItems;
        });
    }

    function getDashboardList () {
      return DashboardsSrv.getList()
        .then(function (dashboards) {
          var dashboardList = [];
          var groupDashboards;
          var systemDashboards;
          var userDashboards;
          // --------------------------------------
          // Segregate by level
          // --------------------------------------
          userDashboards = dashboards.filter(function (dashboard) {
            return dashboard.level === DashboardsSrv.LEVELS.USER && dashboard.owner.toLowerCase() === vm.user.systemName.toLowerCase();
          });
          groupDashboards = dashboards.filter(function (dashboard) {
          /* jslint bitwise: true */
            return dashboard.level === DashboardsSrv.LEVELS.GROUP && (dashboard.effective_permissions & vm.ACCESS.READ) === vm.ACCESS.READ;
          /* jslint bitwise: false */
          });
          systemDashboards = dashboards.filter(function (dashboard) {
            return dashboard.level === DashboardsSrv.LEVELS.SYSTEM;
          });
          // --------------------------------------
          // Sort within levels
          // --------------------------------------
          if (userDashboards.length > 1) {
            userDashboards.sort(sortDashboardsAlpha);
          }
          if (groupDashboards.length > 1) {
            groupDashboards.sort(sortDashboardsPriority);
          }
          if (systemDashboards.length > 1) {
            systemDashboards.sort(sortDashboardsNewest);
          }
          // --------------------------------------
          dashboardList = dashboardList.concat(userDashboards, groupDashboards, systemDashboards);

          return dashboardList;
        });
    }

    function getDashboardPreference () {
      var dashboardId = Preferences.getPreferences().dashboard;
      var didList = $scope.$parent.dashboardList.map(function (d) { return d.id; });
      var didIdx = didList.indexOf(dashboardId);

      if (didIdx >= 0 && $scope.$parent.dashboardList[didIdx].tiles.length > 0) {
        excludeItems(dashboardId);
      } else {
        excludeItems($scope.$parent.dashboardId);
      }
    }

    function markFilteredItems (currentValue) {
      var item = currentValue;

      for (var i = 0, ii = vm.itemFilter.items.length; i < ii; ++i) {
        if (item.ui.id === vm.itemFilter.items[i].id) {
          vm.itemFilter.items[i].matched = true;
          currentValue.ui.filtered = true;
          break;
        }
      }
      return true;// Exclude nothing.
    }

    function removeDuplicateItems (currentValue, index, arr) {
      var currData = angular.copy(currentValue.link.href);
      var testData;

      for (var i = 0; i < index; ++i) {
        testData = angular.copy(arr[i].link.href);

        if (angular.equals(currData, testData)) {
          if (arr[i].ui.ref.id !== currentValue.ui.ref.id) {
            arr[i].ui.refs = arr[i].ui.refs.concat(currentValue.ui.refs);
            arr[i].ui.errs = arr[i].ui.errs.concat(currentValue.ui.errs);
            arr[i].ui.inherited = true;
          }
          return true;// false;// Duplicate, exclude it.
        }
      }
      return true;// Unique, include it.
    }

    function sortDashboardsAlpha (a, b) {
      var aCmp;
      var bCmp;

      if (a.display_name && !b.display_name) { return -1; }
      if (!a.display_name && b.display_name) { return 1; }
      if (a.display_name && b.display_name) {
        aCmp = a.display_name.toLowerCase();
        bCmp = b.display_name.toLowerCase();
        if (aCmp < bCmp) { return -1; }
        if (aCmp > bCmp) { return 1; }
      }
      return sortDashboardsNewest(a, b);
    }

    function sortDashboardsNewest (a, b) {
      // var aCmp;
      // var bCmp;

      if (a.created_on && !b.created_on) { return -1; }
      if (!a.created_on && b.created_on) { return 1; }
      if (a.created_on && b.created_on) {
        if (a.created_on < b.created_on) { return 1; }
        if (a.created_on > b.created_on) { return -1; }
      }
      return 0;
    }

    function sortDashboardsPriority (a, b) {
      var aCmp = a.priority;
      var bCmp = b.priority;
      var missing = [null, ''];
      var aIsPresent = missing.indexOf(aCmp) === -1;
      var bIsPresent = missing.indexOf(bCmp) === -1;

      if (aIsPresent && !bIsPresent) { return -1; }
      if (!aIsPresent && bIsPresent) { return 1; }
      if (aIsPresent && bIsPresent) {
        if (parseInt(aCmp, 10) < parseInt(bCmp, 10)) { return -1; }
        if (parseInt(aCmp, 10) > parseInt(bCmp, 10)) { return 1; }
      }
      return sortDashboardsAlpha(a, b);
    }

    function sortItemsBrokenDown (a, b) { // eslint-disable-line complexity
      if (!a.ui.owned && b.ui.owned) { return 1; }
      if (a.ui.owned && !b.ui.owned) { return -1; }

      if (!a.ui.owned && !b.ui.owned) {
        if (a.unsupported && !b.unsupported) { return 1; }
        if (!a.unsupported && b.unsupported) { return -1; }
        if (a.error && !b.error) { return 1; }
        if (!a.error && b.error) { return -1; }
      }

      return 0;
    }

    function sortItemsInSequence (a, b) {
      var aIdx = vm.itemSequence.items.indexOf(a.ui.id);
      var bIdx = vm.itemSequence.items.indexOf(b.ui.id);

      if (aIdx >= 0 && bIdx >= 0) { return aIdx - bIdx; }
      if (aIdx >= 0 && bIdx < 0) { return -1; }
      if (aIdx < 0 && bIdx >= 0) { return 1; }

      return 0;
    }
  }
})();
