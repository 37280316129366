(function () {
  'use strict';

  angular.module('imat.services')
    .factory('imatStoresSrv', imatStoresSrv);

  imatStoresSrv.$inject = ['STORES', '$http', '$log', '$q'];

  function imatStoresSrv (STORES, $http, $log, $q) {
    var service;
    var raw = []; // eslint-disable-line no-unused-vars

    service = {
      all: [],
      excluded: [],
      included: [],
      index: {},
      loaded: false,
      STORE: STORES,

      load: load,
      commitRecords: commitRecords,
      deleteRecord: deleteRecord,
      deleteStoreData: deleteStoreData,
      getAll: getAll,
      getExcluded: getExcluded,
      getIncluded: getIncluded,
      getRecord: getRecord
    };

    return service;

    //= ================================
    // Public interface
    //= ================================

    function load () {
      return $http.get('/api/stores')
        .then(function (response) {
          var stores = [];
          if (Array.isArray(response.data)) {
            raw = response.data;
            raw.forEach(function (store) {
              var idx;
              store.active_records = store.record_count - store.delete_count;

              // service.index[store.number.toString()] = store;
              idx = stores.push(store) - 1;
              service.index[store.number] = stores[idx];

              if (store.exclude !== '1') {
                service.included.push(stores[idx]);
              } else {
                service.excluded.push(stores[idx]);
              }
            });
          }
          service.all = stores;
        })
        .finally(function () {
          service.loaded = true;
        });
    }

    function getAll (force) {
      if (!force && service.loaded) {
        return $q.resolve(service.all);
      }

      return load()
        .then(function () {
          return service.all;
        });
    }

    function getExcluded (force) {
      if (!force && service.loaded) {
        return $q.resolve(service.excluded);
      }

      return load()
        .then(function () {
          return service.excluded;
        });
    }

    function getIncluded (force) {
      if (!force && service.loaded) {
        return $q.resolve(service.included);
      }

      return load()
        .then(function () {
          return service.included;
        });
    }

    function getRecord (storeid, uri) {
      return $http.get('/api/stores/' + storeid + '/items/' + encodeURIComponent(uri));
    }

    function commitRecords (storeid) {
      return $http.put('/api/stores/' + storeid + '/items', {});
    }

    function deleteStoreData (storeid) {
      return $http.delete('/api/stores/' + storeid + '/items');
    }

    function deleteRecord (storeid, uri) {
      return $http.delete('/api/stores/' + storeid + '/items/' + encodeURIComponent(uri));
    }

    //= ================================
    // Private interface
    //= ================================
  }
})();
