(function () {
  'use strict';

  angular
    .module('vhr')
    .controller('AdvDirModalCtrl', AdvDirModalCtrl);

  AdvDirModalCtrl.$inject = [
    'VHR_REPORT',
    '$scope', '$state', '$log', '$mdDialog', '$q', '$timeout', '$window',
    'psNotification', 'psReports', 'vhrConfigSrv', 'vhrPatientSrv', 'vhrPrintSrv',
    'vhrRecordCollectionSrv', 'vhrUserSrv'
  ];

  function AdvDirModalCtrl (
    VHR_REPORT,
    $scope, $state, $log, $mdDialog, $q, $timeout, $window,
    psNotification, psReports, vhrConfigSrv, vhrPatientSrv, vhrPrintSrv,
    vhrRecordCollectionSrv, vhrUserSrv
  ) {
    var vm = this;

    // Properties
    vm.config = vhrConfigSrv.getPageByState($state.current.name);
    vm.hide_patient_info = false;
    vm.loaded = { record: false, report: false, printView: false };
    vm.patient = vhrPatientSrv;
    vm.pdfLoader = null;
    vm.pdfName = '';
    vm.pdfUrl = '';
    vm.printSource = vhrConfigSrv.getPrintSource();
    vm.record = vhrRecordCollectionSrv.stashed();
    // vm.recordQueued = null;
    vm.report = null;
    vm.reportParams = {};
    vm.user = vhrUserSrv;// Used in print template.
    vm.workspace = 'app.vhr.patient.abstract-grid.grid-advance-directives';// Re-use the generic-grid workspace; we do not load record details.

    // Methods
    vm.cancel = cancel;
    // vm.dequeue = dequeue;
    vm.print = print;
    // vm.queue = queue;
    vm.togglePatientInfo = togglePatientInfo;
    // vm.userCanDownloadDoc = vhrUserSrv.canDownloadOriginalDocuments();
    // vm.userCanViewDoc = vhrUserSrv.canViewOriginalDocuments();

    activate();

    // Implementation

    function activate () {
      // vm.recordQueued = (typeof vhrRecordCollectionSrv.find('printQueue', vm.record) !== 'undefined');
      loadReport()
        .then(function () {
          // UI-2780: Patch advance directive modal to have MRN list formatting for common patient header
          // TODO: Update advance directive service to setup data?
          var reMrnAtFac = /^\s*([^\@]+)\s*\@\s*(.+)\s*$/; // eslint-disable-line no-useless-escape
          var patientLocalId = vm.record.raw.patient_local_id;
          if (patientLocalId && typeof patientLocalId === 'string') {
            vm.record.mrnList = patientLocalId.split(/\|/).map(function (fmrn) { return fmrn.replace(reMrnAtFac, '$1 [$2]'); });
          }
        })
      // .then(function() {
      //   // return loadReportData(angular.copy(vm.reportParams));
      // })
      // .then(function() {
      //   // return loadDataArtifact();
      // })
      // .then(function() {
      //   // if (typeof vm.record.document !== 'undefined' && vhrUserSrv.isAdmin()) {
      //   //   loadDocumentArtifact()
      //   //   .catch(function(reason) {
      //   //     psNotification.error(reason);
      //   //   });
      //   // }
      // })
      // .catch(function(reason) {
      //   reason = reason || 'Failed to load the record.';
      //   psNotification.error(reason);
      // })
        .finally(function () {
          vm.loaded.printView = true;
          vm.pdfName = vm.record.raw.print_label;
          vm.pdfUrl = '/search/results/' + vm.report.results[vm.workspace].resultId + '/output/' + vm.record.raw.file_name;
        });

      vm.loaded.record = true;
      vm.loaded.report = true;

      // Capture Ctrl+P and route through vhrPrintSrv.print().
      angular.element($window.document).on('keyup keydown', function (evt) {
        if (evt.ctrlKey && evt.keyCode === 80) {
          evt.preventDefault();
          print();
          return false;
        }
      });
    }

    function cancel () {
      $mdDialog.cancel();
    }

    // function dequeue() {
    //   vhrRecordCollectionSrv.construct('printQueue').remove(vm.record);
    // }

    // function loadDataArtifact() {
    //   var artifactName = vm.record.getUri() +'.json';
    //   artifactName = 'results.json';

    //   return vm.report.getArtifact(vm.workspace, artifactName)
    //     .then(function(response) {
    //       var raw = response.pop(), rec = angular.merge({}, vm.record.raw, raw);
    //       var reMrnAtFac = /^\s*([^\@]+)\s*\@\s*(.+)\s*$/;

    //       vm.record.set(rec);
    //       if (rec.patient_local_id && typeof rec.patient_local_id === 'string') {
    //         vm.record.mrnList = rec.patient_local_id.split(/\|/).map(function(fmrn) { return fmrn.replace(reMrnAtFac, '$1 [$2]'); });
    //       }
    //     })
    //     .catch(function(reason) {
    //       $log.debug(reason);
    //       return $q.reject('Failed to load the record data.');
    //     });
    // }

    // function loadDocumentArtifact() {
    //   var artifactName = vm.record.getUri() +'.xml';
    //   artifactName = 'results.xml';

    //   return vm.report.getArtifact(vm.workspace, artifactName)
    //     .then(function(response) {
    //       vm.record.setDocument(response.original_response);
    //     })
    //     .catch(function(reason) {
    //       $log.debug(reason);
    //       return $q.reject('Failed to load the record document.');
    //     });
    // }

    function loadReport () {
      var reportId = vm.config.reportId;// vhrConfigSrv.getRecords().reportId;

      return psReports.load(reportId, VHR_REPORT.CALLER, { initParams: vhrConfigSrv.getReportParameters(reportId) })
        .then(function (report) {
          vm.report = report;
          vm.reportParams = report.getParameterDefaults();
        })
        .catch(function (reason) {
          $log.debug(reason);
          return $q.reject('Failed to prepare the record.');
        });
    }

    // function loadReportData(params, force) {
    //   params.records = {};
    //   params.records[vm.record.getUri()] = {
    //     store: vm.record.getStore(),
    //     view_type: vm.record.getType()
    //   };

    //   return vm.report.run(vm.workspace, params, force)
    //     .then(function() {
    //       return vm.report.results[vm.workspace].getPollPromise();
    //     })
    //     .catch(function(reason) {
    //       $log.debug(reason);
    //       return $q.reject('Failed to load the record.');
    //     });
    // }

    function print () {
      vhrPrintSrv.print();
    }

    // function queue() {
    //   vhrRecordCollectionSrv.construct('printQueue').insert(vm.record);
    // }

    function togglePatientInfo () {
      vm.hide_patient_info = !vm.hide_patient_info;
    }
  }
})();
