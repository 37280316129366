(function () {
  'use strict';

  angular.module('vhr.directives')
    .directive('vhrAgeBanner', vhrAgeBanner);

  vhrAgeBanner.$inject = ['$filter', 'vhrConfigSrv', 'vhrPatientSrv'];

  function vhrAgeBanner ($filter, vhrConfigSrv, vhrPatientSrv) {
    var directive;

    directive = {
      link: postLink,
      restrict: 'E',
      scope: true,
      templateUrl: '/ui/vhr/directives/vhrAgeBanner/vhr-age-banner.html'
    };

    return directive;

    function postLink (scope, element) {
      var ageInYears;
      var ageLabels;

      element.toggleClass('deceased', !!vhrPatientSrv.isDeceased());
      element.toggleClass('underage', vhrPatientSrv.isUnderage());

      if (angular.isDefined(vhrConfigSrv.raw.ages)) {
        ageInYears = vhrPatientSrv.getAges(['years'])['years']; // eslint-disable-line dot-notation
        ageLabels = angular.copy(vhrConfigSrv.raw.ages);

        Object.keys(ageLabels).forEach(function (key) { ageLabels[key] = ageLabels[key].label; });

        scope.label = $filter('vhrJsonRange')(ageInYears, ageLabels);

        if (scope.label) {
          element.addClass(String(scope.label).toLowerCase().replace(/\s+/g, '-'));
        }
      }
    }
  }
})();
