(function () {
  'use strict';

  angular
    .module('vhr.services')
    .factory('vhrFootnoteSrv', vhrSrv);

  vhrSrv.$inject = [];

  function vhrSrv () {
    var service;
    var notes = {};

    service = {
      count: count,
      fetch: fetch,
      refer: refer,
      store: store
    };

    function count (context) {
      return (notes[context]) ? notes[context].length : 0;
    }

    function fetch (context) {
      return (notes[context]) ? notes[context] : [];
    }

    function refer (context, note) {
      if (notes[context]) {
        return (notes[context].indexOf(note) + 1);
      }
      return 0;
    }

    function store (context, note) {
      var idx;

      if (note && notes[context]) {
        idx = notes[context].indexOf(note);
        return (idx >= 0) ? idx + 1 : notes[context].push(note);
      }
      if (note) {
        notes[context] = [note];
        return 1;
      }
      return 0;
    }

    return service;
  }
})();
