(function () {
  'use strict';
  angular
    .module('vhr.directives')
    .directive('vhrXsltViewer', vhrXsltViewer);

  function vhrXsltViewer () {
    var directive = {
      restrict: 'E',
      scope: {
        markup: '='
      },
      controller: VhrXsltViewerCtrl,
      controllerAs: 'ctrl'
    };

    return directive;
  }

  VhrXsltViewerCtrl.$inject = ['$anchorScroll', '$compile', '$element', '$scope'];

  function VhrXsltViewerCtrl ($anchorScroll, $compile, $element, $scope) {
    var vm = this;

    // Properties
    vm.sections = {};
    vm.toggle = {};

    // Methods
    vm.collapseAll = collapseAll;
    vm.expandAll = expandAll;
    vm.hideAll = hideAll;
    vm.scroll = scrollToLocation;
    vm.toggleDisplay = toggleDisplay;
    vm.viewAll = viewAll;

    activate();

    // Implementation

    function activate () {
      // watch for template markup xslt result
      $scope.$watch('markup', function (newValue) {
        if (newValue !== null) {
          $element.html(newValue);
          $compile($element.contents())($scope);
        }
      });
    }

    function collapseAll () {
      _setAllPropValue(vm.toggle, false);
    }

    function expandAll () {
      _setAllPropValue(vm.toggle, true);
    }

    function hideAll () {
      _setAllPropValue(vm.sections, false);
    }

    function scrollToLocation (location) {
      $anchorScroll(location);
    }

    function toggleDisplay (section) {
      vm.toggle[section] = !vm.toggle[section];
    }

    function viewAll () {
      _setAllPropValue(vm.sections, true);
    }

    function _setAllPropValue (object, propValue) {
      angular.forEach(object, function (value, key) {
        object[key] = propValue;
      });
    }
  }
})();
