(function () {
  'use strict';

  angular.module('admin')
    .controller('ResetPasswordModalCtrl', ResetPasswordModalCtrl);

  ResetPasswordModalCtrl.$inject = ['$mdDialog', '$scope', '$window'];
  function ResetPasswordModalCtrl ($mdDialog, $scope, $window) {
    $scope.password = '';
    $scope.confirmPassword = '';
    $scope.secureConnection = $window.location.protocol === 'https:';

    $scope.$watch(function () { return [$scope.password, $scope.confirmPassword]; }, function (curr) {
      if (curr[0] && curr[1]) {
        if (curr[0] !== curr[1]) {
          $scope.resetPasswordForm.confirm.$setValidity('notMatching', false);
        } else {
          $scope.resetPasswordForm.confirm.$setValidity('notMatching', true);
        }
      }
    }, true);

    $scope.cancel = function () {
      $mdDialog.cancel();
    };

    $scope.updateUser = function () {
      $mdDialog.hide({ password: $scope.password });
    };
  }
})();
