(function () {
  'use strict';

  angular.module('imat.directives')
    .directive('imatAsciiOnly', imatAsciiOnly);

  imatAsciiOnly.$inject = [];

  function imatAsciiOnly () {
    var NON_PRINTABLE_REGEXP = /[^ -~]/;// ASCII range from space to tilda (32-126)

    return {
      require: 'ngModel',
      link: function (scope, elm, attrs, ctrl) {
        ctrl.$validators.imatAsciiOnly = function (modelValue, viewValue) {
          if (ctrl.$isEmpty(modelValue)) {
            return true;
          }
          if (viewValue.match(NON_PRINTABLE_REGEXP) === null) {
            return true;
          }
          return false;
        };
      }
    };
  }
})();
