(function () {
  'use strict';

  angular.module('vhr.services')
    .service('vhrXmlRecordClass', vhrXmlRecordClass);

  vhrXmlRecordClass.$inject = ['$filter', 'VhrRecordClass'];

  function vhrXmlRecordClass ($filter, VhrRecordClass) {
    function fixEntities (doc) {
      return doc.replace(/&amp;amp;/g, '&amp;');
    }

    function removeComments (doc) {
      return doc.replace(/\s*<!--(?:.|\n)+?-->/g, '');
    }

    function XmlRecord (cfg) {
      VhrRecordClass.call(this, cfg);
      this.controller = 'XmlModalCtrl';
      this.document = '';
      this.printTemplateUrl = '/ui/vhr/templates/xml/print/xml.html';
      this.templateUrl = '/ui/vhr/templates/xml/xml-modal.html';
      this.xmlDocument = '';
    }

    XmlRecord.prototype = Object.create(VhrRecordClass.prototype);
    XmlRecord.prototype.constructor = XmlRecord;

    XmlRecord.prototype.documentRegex = /^\s*(<\?xml(?: |\n|\r)(?:.|\n|\r)+?\?>(?:\n|\r|\r\n)?)?((?:\s+|(?:<!--(?:.|\n|\r)+?-->)|(?:<\?xml-(?:.|\n|\r)+?\?>))*)?(<([a-zA-Z]+)(?:.|\n|\r)*?>(?:.|\n|\r)+?<\/\4>)/;
    XmlRecord.prototype.formatDocument = function (doc) { return doc; };
    XmlRecord.prototype.getDocument = function () { return this.document; };
    XmlRecord.prototype.getXmlDocument = function () { return this.xmlDocument; };
    XmlRecord.prototype.setDocument = function (data, xslt) {
      var match = data.match(this.documentRegex);
      var prolog;
      var xml;

      if (match && match.length) {
        prolog = removeComments((match[1] || '') + (match[2] || ''));
        xml = removeComments(match[3] || '');

        this.xmlDocument = fixEntities(prolog + xml);
        this.document = this.formatDocument(xml) || '';

        if (xslt) {
          this.document = $filter('xslt')(this.document, xslt);
        }

        return this.document;
      }
    };

    return XmlRecord;
  }
})();
