(function () {
  'use strict';

  angular
    .module('vhr.directives')
    .directive('vhrRecordPrintLayout', vhrRecordPrintLayout);

  function vhrRecordPrintLayout () {
    var directive = {
      controller: vhrRecordPrintLayoutController,
      controllerAs: 'modal',
      templateUrl: '/ui/vhr/directives/vhrRecordPrintLayout/vhr-record-print-layout.html',
      restrict: 'E',
      scope: {
        concepts: '=',
        gridApi: '=',
        pageBreak: '=',
        record: '=',
        report: '=',
        workspace: '='
      }
    };

    return directive;
  }

  vhrRecordPrintLayoutController.$inject = [
    '$scope', 'uiGridExporterConstants', 'uiGridExporterService',
    'vhrConfigSrv', 'vhrUserSrv'
  ];

  function vhrRecordPrintLayoutController (
    $scope, uiGridExporterConstants, uiGridExporterService,
    vhrConfigSrv, vhrUserSrv
  ) {
    var ctrl = this; // eslint-disable-line no-invalid-this

    ctrl.concepts = $scope.concepts;
    ctrl.gridApi = $scope.gridApi;
    ctrl.gridConfig = {};
    ctrl.gridExport = {};
    ctrl.pageBreak = $scope.pageBreak;
    ctrl.printSource = vhrConfigSrv.getPrintSource();
    ctrl.record = $scope.record;
    ctrl.report = $scope.report;
    ctrl.user = vhrUserSrv;
    ctrl.workspace = $scope.workspace;

    angular.forEach(ctrl.concepts, function (gridMeta) {
      setupConfigs(gridMeta);
      setupPrintView(gridMeta);
    });

    function setupConfigs (meta) {
      var gridApi = ctrl.gridApi[meta.id];

      ctrl.gridConfig[meta.id] = gridApi.grid.options;
    }

    function setupPrintView (meta) {
      var gridApi = ctrl.gridApi[meta.id];
      var colTypes = uiGridExporterConstants.ALL;
      var rowTypes = uiGridExporterConstants.ALL;
      var applyCellFilters = true;

      ctrl.gridExport[meta.id] = {
        cols: uiGridExporterService.getColumnHeaders(gridApi.grid, colTypes),
        rows: uiGridExporterService.getData(gridApi.grid, rowTypes, colTypes, applyCellFilters)
      };

      meta.ready.exported = true;
    }
  }
})();
