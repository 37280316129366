(function () {
  'use strict';

  angular.module('account')
    .factory('errorService', errorService);

  errorService.$inject = ['$location', 'psNotification'];

  function errorService ($location, psNotification) {
    var vm = this; // eslint-disable-line no-invalid-this

    vm.message = function (ret) {
      if (!ret || !ret.data || [200, 400, 500].indexOf(ret.status) < 0) {
        return 'missing';
      }

      if (ret.status === 200) {
        psNotification.show(ret.data.message || 'Success.');
      } else if (ret.data.error && ret.data.error.message) {
        psNotification.error(ret.data.error.message);
      } else {
        psNotification.error('Unable to process your request.');
      }
    };

    return {
      message: vm.message
    };
  }
})();
