(function () {
  'use strict';

  angular.module('imat.filters')
    .filter('urlTitle', [function () {
      return function (value) {
        return value.replace(/\s/g, '-').toLowerCase();
      };
    }]);
})();
