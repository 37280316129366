(function () {
  'use strict';

  angular
    .module('vhr.filters')
    .filter('vhrJsonDictionary', function () {
      return function (input, dictionary, unknown, path) { // eslint-disable-line complexity
        var key = input;

        if (typeof dictionary === 'undefined' && typeof unknown === 'undefined') { return key; }
        if (typeof dictionary === 'undefined' && typeof unknown !== 'undefined') { key = unknown; }
        if (typeof key !== 'string') { key += ''; }
        if (typeof path === 'string' && typeof dictionary[key] === 'object') {
          if (typeof dictionary[key][path] !== 'undefined') {
            return dictionary[key][path];
          }
        } else {
          if (typeof dictionary[key] !== 'undefined') {
            return dictionary[key];
          }
        }
        if (typeof unknown !== 'undefined') {
          return unknown;
        }
        return key;
      };
    });
})();
