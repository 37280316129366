(function () {
  'use strict';

  angular.module('queries')
    .controller('QueryResultsCtrl', QueryResultsCtrl);

  QueryResultsCtrl.$inject = [
    'QUERY',
    '$mdDialog', '$q', '$scope', '$state', '$transition$', '$window', 'imatConfig', 'psNotification', 'QueriesSrv', 'ResultDisplaySrv'
  ];

  function QueryResultsCtrl (
    QUERY,
    $mdDialog, $q, $scope, $state, $transition$, $window, imatConfig, psNotification, QueriesSrv, ResultDisplaySrv
  ) {
    var vm = this;

    // Properties
    vm.currentPage = 1;
    vm.helpURL = imatConfig.get('apps.queries.customerHelpLink');
    vm.loading = { results: false };
    vm.sortOrder = QueriesSrv.resultsSortOrder;
    vm.pageLabel = {
      of: 'of',
      page: 'Page:',
      rowsPerPage: 'Rows per page:'
    };
    QUERY.PAGE_SIZE = 100;
    vm.limit = 100;
    vm.query = QueriesSrv.query;
    vm.queries = QueriesSrv.queries;
    vm.result = {
      name: 'Unnamed Results',
      description: '',
      saved: false,
      exported: false,
      hits: [],
      meta: {
        _total: 0
      },
      parameters: {
      }
    };
    vm.pageNumbers = [1];
    vm.resultDisplayFields = ResultDisplaySrv.getFields(vm.query.resultDisplay, false);
    vm.selectedRecords = [];
    vm.topIndex = 0;

    // Methods
    vm.editQuery = editQuery;
    vm.exportResults = exportResults;
    vm.firstPage = firstPage;
    vm.isDefaultState = isDefaultState;
    vm.limitChange = limitChange;
    vm.newQuery = newQuery;
    vm.nextPage = nextPage;
    vm.selectRecord = selectRecord;
    vm.showQueryResultsInfo = showQueryResultsInfo;

    activate();

    function activate () {
      // Check if query id passed in with run action
      if ($transition$.params().id && $transition$.params().action === 'run') {
        QueriesSrv.resetResults();
        runQueryById($transition$.params().id);
      }

      vm.result = QueriesSrv.result;

      if ($window.localStorage.getItem('query-results-top-index')) {
        vm.topIndex = parseInt($window.localStorage.getItem('query-results-top-index'), 10);
        $window.localStorage.removeItem('query-results-top-index');
      }

      $scope.$watch(function () { return QueriesSrv.result; }, function (newValue, oldValue) {
        if (newValue !== oldValue) {
          vm.result.saved = QueriesSrv.result.saved;
          vm.result.hits = QueriesSrv.result.hits;
          vm.result.meta = QueriesSrv.result.meta;
          vm.result.info = QueriesSrv.result.info;
        }
      }, true);
    }

    function editQuery () {
      $state.go('app.queries.builder');
    }

    function exportResults () {
      $mdDialog.show({
        parent: angular.element(document.body),
        controller: 'ExportResultsModalCtrl',
        controllerAs: 'vm',
        templateUrl: '/ui/queries/query-results/export-results-modal.html'
      });
    }

    function isDefaultState () {
      return (!vm.loading.results && angular.isDefined(vm.result) && !Object.keys(vm.result.info).length && !Object.keys(vm.result.meta).length && !vm.result.hits.length);
    }

    function newQuery () {
      QueriesSrv.resetQuery();
      $state.go('app.queries.builder');
    }

    function selectRecord (record) {
      // Save selected record in service
      // Navigate to record details screen
      $window.localStorage.setItem('query-results-top-index', vm.topIndex);
      QueriesSrv.setRecord(record)
        .then(function () {
          $state.go('app.queries.records');
        });
    }

    function showQueryResultsInfo () {
      $mdDialog.show({
        controller: 'QueryResultsInfoModalCtrl',
        controllerAs: 'ctrl',
        locals: { info: vm.result.info },
        templateUrl: '/ui/queries/query-results/query-results-info-modal.html',
        parent: angular.element(document.body),
        clickOutsideToClose: true
      });
    }

    // -------------------------------

    function firstPage () {
      _renderPages(0, 0)
        .then(function () {
          vm.currentPage = 1;
        });
    }

    function nextPage () {
      _renderPages(vm.result.parameters.onspace, vm.result.parameters.onref)
        .then(function () {
          vm.currentPage++;
        });
    }

    function limitChange (limit) {
      vm.limit = limit || vm.limit;
      firstPage();
    }

    // -------------------------------

    function runQuery (onSpace, onRef) {
      var parameters = {
        limit: vm.limit,
        onspace: onSpace,
        onref: onRef,
        q: vm.result.parameters.q || vm.query.built,
        report: 'exacttotal',
        store: vm.result.parameters.store || vm.query.stores,
        usesession: true,
        fields: vm.result.parameters.fields || vm.query.fields
      };

      return QueriesSrv.runQuery(parameters)
        .then(function (hits) {
          vm.result.hits = QueriesSrv.result.hits;
          vm.result.meta = QueriesSrv.result.meta;
          vm.result.info = QueriesSrv.result.info;
          if (hits.length === 0) {
            psNotification.warn('No results found.');
          }
          vm.topIndex = 0;
        });
    }

    function runQueryById (id) {
      vm.loading.results = true;
      QueriesSrv.getSavedQuery(id)
        .then(function (loadedQuery) {
          // Set result fields based on query data or use current result display fields if none exist
          if (Array.isArray(loadedQuery.fields) && loadedQuery.fields.length) {
            ResultDisplaySrv.setFields(loadedQuery.resultDisplay, loadedQuery.fields);
            vm.resultDisplayFields = ResultDisplaySrv.getFields(loadedQuery.resultDisplay, false);// update view to latest fields
          }
          QueriesSrv.setActiveId('query_' + id);
          QueriesSrv.setQuery(QueriesSrv.getQueryTemplate(loadedQuery));
          // Check if using quickfilter search or not
          if (loadedQuery.resultDisplay === QUERY.RESULT_DISPLAY_DEFAULT) {
            return runQuery(1);
          }
          // quickfilter search -> run the 2 queries
          return runQuickFilterQuery(loadedQuery.resultDisplay);
        })
        .catch(function (errorResponse) {
          psNotification.error(errorResponse);
        })
        .finally(function () {
          vm.loading.results = false;
        });
    }

    function runQuickFilterQuery (quickFilter) {
      // Use patientidn quickfilter to return unique patient list
      // SET documentation: /ui/documentation/#/reports/basic/sets
      // create set name for record hit results
      var setName = QUERY.SET_PREFIX + Date.now(); // [not sure if what set per name yet] + '_' + vm.query.name.replace(' ', '_');
      var recordParams = {
        q: vm.query.built,
        store: vm.query.stores,
        limit: 1,
        usesession: true,
        fields: 'uri',
        // add quickfilter to set param
        outputset: setName + '(' + quickFilter + ')',
        result_display: quickFilter
      };

      // Patient search with set - search?q=()FILTER(PatientIDN:SET:LastNameSmith)&usesession&fields=mpid&store=mpi-persona
      var patientParams = {
        q: '()FILTER(' + quickFilter + ':SET:' + setName + ')',
        store: ResultDisplaySrv.getStores(quickFilter),
        limit: 10,
        usesession: true,
        fields: ResultDisplaySrv.getFields(quickFilter, true),
        report: 'exacttotal',
        result_display: quickFilter,
        initial_query: recordParams.q,
        initial_store: recordParams.store
      };

      return QueriesSrv.runQuery(recordParams)
        .then(function (initialResults) {
          return initialResults.length ? QueriesSrv.runQuery(patientParams) : [];
        })
        .then(function (secondResults) {
          if (!secondResults.length) {
            psNotification.warn('No results found.');
          }
        })
        .catch(function (errorResponse) {
          if (errorResponse.indexOf('Missing required parameter q.') > -1) {
            errorResponse = 'Query filter values are empty.';
          }
          return $q.reject(errorResponse);
        });
    }

    function _renderPages (onspace, onref) {
      vm.loading.results = true;
      return runQuery(onspace, onref)
        .catch(function (errorResponse) {
          psNotification.error(errorResponse);
        })
        .finally(function () {
          vm.loading.results = false;
        });
    }
  }
})();
