(function () {
  'use strict';

  angular.module('admin')
    .controller('DeleteIndexRecordModalCtrl', DeleteIndexRecordModalCtrl);

  DeleteIndexRecordModalCtrl.$inject = ['$scope', '$mdDialog'];

  function DeleteIndexRecordModalCtrl ($scope, $mdDialog) {
    // Variables.

    // Methods.
    $scope.closeDialog = closeDialog;
    $scope.deleteRecord = deleteRecord;

    activate();

    function activate () {
      // ...
    }

    function closeDialog () {
      $mdDialog.cancel();
    }

    function deleteRecord () {
      $mdDialog.hide();
    }
  }
})();
