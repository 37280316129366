(function () {
  'use strict';

  angular.module('vhr')
    .controller('DicomCtrl', DicomCtrl);

  DicomCtrl.$inject = [
    '$log', '$timeout', '$transition$','$window',
    'psNotification', 'vhrDicomSrv'
  ];

  function DicomCtrl (
    $log, $timeout, $transition$, $window,
    psNotification, vhrDicomSrv
  ) {
    var vm = this;

    // Properties
    vm.loading = true;
    vm.message = "Connecting to image viewer";
    vm.reasonMsg = "";
    vm.params = $transition$.params();
    
    // Methods

    activate();

    // Implementation

    function activate () {
      //if record store/uri not passed in, get worklist
      if (vm.params.store && vm.params.uri) {
        getStudyViewerURL(vm.params.mpid, vm.params.store, vm.params.uri)
        .then(function (viewerURL) {
          if (viewerURL) {
            $window.location.href = viewerURL;
          } else {
            throw "Provided Viewer URL is not valid.";
          }
        })
        .catch(function (reason) {
          $log.debug(reason || 'There was a problem connecting to the viewer.');
          vm.message = 'There was a problem connecting to the viewer.';
          if (reason && reason.data) {
            vm.reasonMsg = reason.data.reason || reason.data;
          }
          psNotification.error('There was a problem connecting to the viewer.');
        })
        .finally(function () {
          $timeout(function () {
            vm.loading = false;
          }, 5000);
        });
      } else {
        getWorklistViewerURL(vm.params.mpid)
        .then(function (viewerURL) {
          if (viewerURL) {
            $window.location.href = viewerURL;
          } else {
            throw "Provided Viewer URL is not valid.";
          }
        })
        .catch(function (reason) {
          $log.debug(reason || 'There was a problem connecting to the viewer.');
          vm.message = 'There was a problem connecting to the viewer.';
          if (reason && reason.data) {
            vm.reasonMsg = reason.data.reason || reason.data;
          }
          psNotification.error('There was a problem connecting to the viewer.');
        })
        .finally(function () {
          $timeout(function () {
            vm.loading = false;
          }, 5000);
        });
      }
    }

    function getStudyViewerURL(mpid, store, uri) {
      return vhrDicomSrv.getStudyViewerURL(mpid, store, uri)
        .then(function (url) {
          return url;
        });
    }

    function getWorklistViewerURL(mpid) {
      return vhrDicomSrv.getWorklistViewerURL(mpid)
        .then(function (url) {
          return url; 
        });
    }

  }
})();
