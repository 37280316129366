(function () {
  'use strict';

  angular.module('queries', [
    /* Angular modules */
    'ngAnimate',
    'ngMaterial',
    'ngMessages',
    'ngSanitize',

    /* IMAT modules */
    'imat.directives', // materialBanner
    'imat.filters', // imatLabelize
    'imat.services', // dateTimeSrv, psNotification, userSession

    /* 3rd party modules */
    'md.data.table',
    'ui.router',

    /* Queries modules */
    'queries.services',
    'queries.constants',
    'queries.utils'
  ]);
})();
