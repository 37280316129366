(function () {
  'use strict';

  angular.module('mpiApp.personas')
    .controller('personasCtrl', personasCtrl);

  personasCtrl.$inject = [
    'MPI_CLIENT_NAME',
    '$mdDialog', '$mdMenu', '$q', '$scope', '$transition$', '$window',
    'mpiApiSrv', 'mpiConfigSrv', 'mpiPersona', 'mpiUtilitySrv', 'psNotification'
  ];

  function personasCtrl (
    MPI_CLIENT_NAME,
    $mdDialog, $mdMenu, $q, $scope, $transition$, $window,
    mpiApiSrv, mpiConfigSrv, mpiPersona, mpiUtilitySrv, psNotification
  ) {
    var vm = this;

    // Properties
    vm.clinicalRecordTableData = [];
    vm.clinicalRecordTableHeaders = [];
    vm.hide_all_info = false;
    vm.hide_persona_info = false;
    vm.hide_pi_records = false;
    vm.hide_clinical_records = true;
    vm.isColHidden = {};
    vm.linkedPersonInfoColumns = {
      showId: true,
      showEdit: true,
      showFacilityName: true,
      showFacilityMrn: true,
      showFullName: true,
      showGender: false,
      showDob: true,
      showSsn: false,
      showPhoneNumber: false,
      showAddress: false,
      showBehavior: true,
      showLastUpdated: true
    };
    vm.loading = true;
    vm.mpid = $transition$.params().id;
    vm.persona = null;
    vm.piRecords = [];
    vm.selected = [];
    vm.selectedBehavior = null;
    vm.tableOptions = {
      rowSelection: true,
      multiSelect: true,
      boundaryLinks: true,
      pageSelect: true
    };
    vm.tableQuery = { limit: 10, page: 1 };
    vm.viewOptions = {};

    // Methods
    vm.changeBehavior = changeBehavior;
    vm.deletePiRecord = deletePiRecord;
    vm.positionMenu = positionMenu;
    vm.selectAllPiRecords = selectAllPiRecords;
    vm.selectPiRecord = selectPiRecord;
    vm.toggleActive = toggleActive;
    vm.toggleAllInfo = toggleAllInfo;
    vm.toggleClinicalRecords = toggleClinicalRecords;
    vm.togglePersonaInfo = togglePersonaInfo;
    vm.togglePiRecords = togglePiRecords;

    activate();

    function activate () {
      vm.viewOptions = mpiConfigSrv.getPersonaView();
      load(vm.mpid);

      $scope.$on('$mdMenuOpen', function (event, element) {
        positionMenu(element);
      });
    }

    //= ================================
    // Public interface
    //= ================================

    function changeBehavior (event) {
      if (vm.selected.length) {
        $mdDialog.show({
          parent: angular.element($window.document.body),
          controller: 'PiEntryBehaviorModalCtrl',
          controllerAs: 'vm',
          bindToController: true,
          locals: { selected: vm.selected },
          targetEvent: event,
          templateUrl: '/ui/mpi/personas/pi-entry-behavior-modal.html'
        })
          .then(function (response) {
            var pidList = getPiEntryIdList(vm.selected).join(',');

            vm.selectedBehavior = response.behavior;
            return mpiApiSrv.updateLevel(pidList, response.behavior, response.comment)
              .catch(function () {
                psNotification.error('Failed to change the behavior(s).');
              });
          })
          .then(function () {
            vm.selected.forEach(function (piRecord) {
              var idx = vm.piRecords.indexOf(piRecord);
              if (idx >= 0) {
                vm.piRecords[idx].behavior = vm.selectedBehavior;
              }
            });
            clearSelectedPiRecords();
            _sortPiRecords();
            psNotification.success('Behavior(s) changed.');
          });
      }
    }

    function deletePiRecord () {
      if (!vm.selected.length) {
        return;
      }

      $mdDialog.show({
        parent: angular.element($window.document.body),
        controller: 'PiRecordDeleteModalCtrl',
        controllerAs: 'vm',
        locals: { selected: vm.selected },
        templateUrl: '/ui/mpi/personas/pi-record-delete-modal.html'
      })
        .then(function (response) {
          var scrub = response.scrub;
          var comment = response.comment;

          var selectedPis = [];
          angular.forEach(vm.selected, function (pi) {
            selectedPis.push(pi.pid.split('.PI')[0]);
          });

          mpiApiSrv.deletePi(selectedPis.join(','), scrub, comment)
            .then(function () {
              vm.selected.forEach(function (pi) {
                vm.piRecords.splice(vm.piRecords.indexOf(pi), 1);
              });

              vm.selected.splice(0);
              _sortPiRecords();
              psNotification.success('Records deleted.');
            })
            .catch(function () {
              psNotification.error('Unable to delete records.');
            });
        });
    }

    function positionMenu () {
      $scope.$applyAsync(function () {
        var openMenu = angular.element('.md-open-menu-container.md-active');
        var windowHeight = document.documentElement.scrollTop + $window.innerHeight;
        var menuTop = parseInt(openMenu.css('top'), 10);
        var menuBottom = menuTop + openMenu.height();
        var menuBottomMargin = 1;

        if ((menuBottom + menuBottomMargin) > windowHeight) {
          openMenu.css({
            top: menuTop + (windowHeight - menuBottom) - menuBottomMargin
          });
        }
      });
    }

    function selectAllPiRecords () {
      if (!vm.allPiSelected) {
        clearSelectedPiRecords();
      } else {
        vm.piRecords.forEach(function (pi) {
          pi.selected = true;
        });
        vm.selected = vm.piRecords;
      }
    }

    function selectPiRecord (pi) {
      if (pi.selected) {
        checkAllPiSelected();
      } else {
        if (vm.allPiSelected) {
          vm.allPiSelected = false;
        }
      }
      updateSelectedCount();
    }

    function updateSelectedCount () {
      vm.selected = vm.piRecords.filter(function (pi) {
        return pi.selected === true;
      });
    }

    function toggleActive (button_scope) { // eslint-disable-line
      var deferred = $q.defer();
      var confirm;
      var assigner_display = button_scope.facility.mrn;  // eslint-disable-line
      var deactivate = button_scope.facility.isActive === false;
      if (Object.prototype.hasOwnProperty.call(button_scope.facility, 'assigner') && Object.prototype.hasOwnProperty.call(button_scope.facility.assigner, 'display')) {
        assigner_display = button_scope.facility.assigner.display;  // eslint-disable-line
      }

      confirm = $mdDialog.confirm()
        .title('Confirm ' + (deactivate ? 'Deactivation' : 'Activation'))
        .textContent('Are you sure you want to ' + (deactivate ? 'deactivate ' : 'activate ') + assigner_display + '? ' + (deactivate ? 'Deactivating ' : 'Activating ') + 'values can affect the preferred persona view.')  // eslint-disable-line
        .targetEvent(button_scope)
        .ok((deactivate ? 'Deactivate ' : 'Activate '))
        .cancel('Cancel');

      $mdDialog.show(confirm)
        .then(function () {
          mpiPersona.activate(!deactivate, vm.mpid, MPI_CLIENT_NAME, button_scope.facility)
            .then(function () {
              // Change the values on this page
              button_scope.facility.isActive = !deactivate;
              // Display a success message to the user
              psNotification.success('Facility ' + assigner_display + ' ' + (deactivate ? 'deactivated' : 'activated') + '. This change may take several minutes to fully propagate through the system.'); // eslint-disable-line
              deferred.resolve();
            }, function () {
              // Display an error message to the user
              psNotification.warn('There was an error ' + (deactivate ? 'deactivating' : 'activating') + ' this ID. Please reload the page and try again. If errors continue, contact your system administrator.');
              deferred.reject();
            });
        }, function () {
        // Switch back on cancel of dialog
          button_scope.facility.isActive = deactivate;
        });

      return deferred.promise;
    }

    function toggleAllInfo () {
      vm.hide_all_info = !vm.hide_all_info;
    }

    function toggleClinicalRecords () {
      vm.hide_clinical_records = !vm.hide_clinical_records;
    }

    function togglePersonaInfo () {
      vm.hide_persona_info = !vm.hide_persona_info;
    }

    function togglePiRecords () {
      vm.hide_pi_records = !vm.hide_pi_records;
    }

    //= ================================
    // Private interface
    //= ================================

    function checkAllPiSelected () {
      let tempAllSelected = true;
      for (let i = 0; i < vm.piRecords.length; i++) {
        if (!vm.piRecords[i].selected) {
          tempAllSelected = false;
        }
      }
      vm.allPiSelected = tempAllSelected;
    }

    function clearSelectedPiRecords () {
      vm.piRecords.forEach(function (pi) {
        delete pi.selected;
      });
      vm.selected = [];
    }

    function getClinicalRecordCountString (count) {
      if (count == null) return '?';
      if (count < 1000) return '' + count;
      return (count / 1000).toFixed(1) + ' k';
    }

    function getPiEntryIdList (piRecordList) {
      return piRecordList.map(function (piRecord) { return parseInt(piRecord.pid, 10); });
    }

    function load (mpid) {
      vm.loading = true;

      mpiPersona.load(mpid)
        .then(function (persona) {
          vm.persona = persona;
          vm.persona.contained.facilities = _filterFacilities(vm.persona.contained.facilities);
          clearSelectedPiRecords();
          return mpiPersona.loadRecords(mpid);
        })
        .then(function (piRecords) {
          vm.piRecords = piRecords;
          _sortPiRecords();
        })
        .then(function () {
          // Do not return (i.e., wait on) this, it may take a while.
          vm.persona.getClinicalRecordCount().then(parseClinicalRecordCount);
        })
        .finally(function () {
          vm.loading = false;
        });
    }

    function parseClinicalRecordCount (count) {
      // count = {
      //   pidA: { 'pid': pidA, 'total': <total>, 'counts': { 'fooStore': <count>, 'barStore': <count>, ... }},
      //   pidB: { 'pid': pidB, 'total': <total>, 'counts': { 'fooStore': <count>, 'barStore': <count>, ... }},
      //   ...
      // }
      var rows = [];

      angular.forEach(count, function (summary) {
        rows.push(angular.extend({ ID: summary.pid, Total: getClinicalRecordCountString(summary.total) }, summary.counts));
      });

      vm.clinicalRecordTableData = rows;
      vm.clinicalRecordTableHeaders = rows.length ? Object.keys(rows[0]) : ['ID', 'Total'];
      vm.isColHidden = {};
    }

    function _filterFacilities (facilities) {
      return facilities.filter(mpiUtilitySrv.removeDuplicateOlderFacilities);
    }

    function _sortPiRecords () {
      const order = ['2', '3']; // =[ 'FMRNOnly', 'Blocked']
      vm.piRecords.sort(function (a, b) {
        const aIndex = order.indexOf(a.behavior);
        const bIndex = order.indexOf(b.behavior);
        if (aIndex > bIndex) {
          return 1;
        }
        if (aIndex < bIndex) {
          return -1;
        }

        return b.last_updated - a.last_updated;
      });
    }
  }
})();
