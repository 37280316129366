(function () {
  'use strict';

  angular.module('vhr.services')
    .service('vhrCcdRecordClass', vhrCcdRecordClass);

  vhrCcdRecordClass.$inject = [
    '$filter',
    'vhrXmlRecordClass'
  ];

  function vhrCcdRecordClass (
    $filter,
    vhrXmlRecordClass
  ) {
    function humanizeDate (date) {
      return date.toISOString().slice(0, 19).replace('T', ' ');
    }

    function CcdRecord (cfg) {
      vhrXmlRecordClass.call(this, cfg);
      this.controller = 'CcdModalCtrl';
      this.document = '';
      this.printTemplateUrl = '/ui/vhr/templates/ccd/print/ccd.html';
      this.templateUrl = '/ui/vhr/templates/ccd/ccd-modal.html';
    }

    CcdRecord.prototype = Object.create(vhrXmlRecordClass.prototype);
    CcdRecord.prototype.constructor = CcdRecord;

    // Pass undefined to show most non-empty fields.
    // Pass true to set up with default fields.
    // Pass an array of key names to set up with only those fields.
    // Pass false or an empty array to set up without fields.
    CcdRecord.prototype.set = function (raw, setFields) {
      if (setFields === true) {
        setFields = ['facility'];
      }
      setCcdRecord.call(this, raw, setFields);
    };

    function setCcdRecord (raw, setFields) {
      var excluded = ['rawrecord', 'store', 'uri'];
      var included;
      var reAngularAttached = /^\$+/;
      var reImpliedBoolean = /^(?:can|has|is)_/;
      var reImpliedDatetime = /^(?:date|datetime|time)_|_(?:date|datetime|time)_|_(?:date|datetime|time)$/;
      var reMultiWord = /_/;
      var reTrailingId = / id$/;
      var reTruthy = /^(?:true|yes|t|y|1)$/i;
      var reFalsey = /^(?:false|no|f|n|0)$/i;

      vhrXmlRecordClass.prototype.set.call(this, raw, false); // eslint-disable-line no-invalid-this

      if (angular.isUndefined(setFields)) {
        included = Object.keys(raw).filter(function (key) { return !reAngularAttached.test(key); }).sort();
      } else if (angular.isArray(setFields) && setFields.length) {
        included = setFields;
      } else {
        return;
      }

      excluded.forEach(function (key) {
        var idx = included.indexOf(key);

        if (idx >= 0) { included.splice(idx, 1); }
      });

      this.fields = included.map(function (key) { // eslint-disable-line no-invalid-this, complexity
        var label = $filter('imatLabelize')(key);
        var value = raw[key];

        // Remove the cruft...
        if (!setFields) { // Showing most fields except...
          // Skip values that are falsey, empty arrays, or white-space only.
          if (!value || !value.length || (value.trim && !value.trim())) { return void (0); } // eslint-disable-line no-void
        } else { // Showing specified fields except...
          // Skip values that are no longer defined (i.e., safeguard against getting out of sync with the report).
          if (typeof value === 'undefined') { return void (0); } // eslint-disable-line no-void
        }

        if (typeof value === 'boolean') {
          value = !!value ? 'Yes' : 'No'; // eslint-disable-line no-extra-boolean-cast
        } else if (reImpliedBoolean.test(key)) { // Assume the value is boolean if the key implies it.
          if (reTruthy.test(value)) {
            value = 'Yes';
          } else if (reFalsey.test(value)) {
            value = 'No';
          } else if (!!value) { // eslint-disable-line no-extra-boolean-cast
            value = 'Yes';
          } else {
            value = 'No';
          }
        } else if (angular.isDate(value)) {
          value = humanizeDate(value);
        } else if (reImpliedDatetime.test(key)) { // Assume the value is a date if the key implies it.
          if (!isNaN(Date.parse(value))) {
            value = humanizeDate(new Date(value));
          } else if (angular.isString(value) && /\|/.test(value)) {
            value = value.split('|')
              .map(function (v) {
                var d = Date.parse(v);
                return !isNaN(d) ? humanizeDate(new Date(d)) : v;
              })
              .filter(function (v, idx, arr) {
                if (v) {
                  for (var i = 0; i < idx; ++i) {
                    if (v === arr[i]) {
                      return false;// Remove duplicates.
                    }
                  }
                  return true;
                }
              })
              .join(', ');
          }
        }

        if (key.length <= 4 && !reMultiWord.test(key) && ['name'/* more words */].indexOf(key) < 0) {
          // Assume the key is an acronym if it's a short, single, unknown word.
          label = label.toUpperCase();
        }

        label = label.replace(reTrailingId, ' ID');
        return { isArray: angular.isArray(value), key: key, label: label, value: value };
      });

      this.fields = this.fields.filter(function (f) { return typeof f !== 'undefined'; }); // eslint-disable-line no-invalid-this
    }

    return CcdRecord;
  }
})();
