(function () {
  'use strict';

  angular.module('imat.shared.constants', [])
    .constant('ACCESS_LEVELS', {
      FULL: 7,
      READ_WRITE: 6,
      READ_EXECUTE: 5,
      READ: 4,
      WRITE_EXECUTE: 3,
      WRITE: 2,
      EXECUTE: 1
    })
    .constant('APP_NAME', 'IMAT')
    .constant('APP_TIMEOUT', {
      SESSION_URL: '/api/account/whoami',
      COUNTDOWN: 180, // In seconds. Must be less than the buffer.
      BUFFER: 300 // In seconds. Amount of time we trim off the session duration to assure the user is logged in...so we can log them out.
    })
    .constant('_lodash', _) // eslint-disable-line no-undef
    .constant('_moment', moment) // eslint-disable-line no-undef
    .constant('SET_STATUSES', {
      CREATED: 'CREATED',
      OUTDATED: 'OUTDATED',
      PENDING: 'PENDING',
      REQUESTED: 'REQUESTED',
      FAILED: 'FAILED'
    })
    .constant('STATUSES', {
      ABORTED: 'ABORTED',
      COMPLETE: 'COMPLETE',
      FAILED: 'FAILED',
      QUEUED: 'QUEUED',
      RUNNING: 'RUNNING',
      UNKNOWN: 'UNKNOWN'
    })
    .constant('STORES', {
      ALL: '-1',
      LIMBO: 'Limbo',
      MPI_ALERT: 'mpi-alert',
      MPI_ARCHIVE: 'mpi-archive',
      MPI_PERSONAS: 'mpi-persona',
      MPI_PI_RECORDS: 'mpi-patientinfo',
      MPI_SPI_RECORDS: 'mpi-sourceinfo',
      MPI_TRANSACTION: 'mpi-transaction'
    });
})();
