(function () {
  'use strict';

  angular.module('admin')
    .controller('ReportViewModalCtrl', ReportViewModalCtrl);

  ReportViewModalCtrl.$inject = ['$mdDialog', 'reportId', 'ReportsSrv', 'reportName'];

  function ReportViewModalCtrl ($mdDialog, reportId, ReportsSrv, reportName) {
    var vm = this;

    vm.loading = true;
    vm.report = {
      name: reportName,
      query_string: ''
    };

    vm.close = close;

    activate();

    function activate () {
      ReportsSrv.getReport(reportId, { admin: true })
        .then(function (report) {
          vm.report = report;
          // Turn date strings into UTC datetime.
          vm.report.created_on = new Date(report.created_on.replace('-', '/') + 'Z');
          vm.report.modified_on = new Date(report.modified_on.replace('-', '/') + 'Z');
        })
        .finally(function () {
          vm.loading = false;
        });
    }

    function close () {
      $mdDialog.cancel();
    }
  }
})();
