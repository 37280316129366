(function () {
  'use strict';

  angular.module('imat.services')
    .factory('Preferences', Preferences);

  Preferences.$inject = ['$http'];

  function Preferences ($http) {
    var baseUrl = '/api/prefs';
    var service = {
      getPreferences: getPreferences,
      load: load,
      setPreference: setPreference,
      deletePreference: deletePreference
    };

    let preferences = {};

    return service;

    // ======================
    //    Public Methods
    // ======================

    function getPreferences () {
      return preferences;
    }

    function load () {
      return $http.get(baseUrl).then(function (resp) {
        preferences = resp.data;
        return preferences;
      });
    }

    function setPreference (prefName, pref) {
      return $http.put(baseUrl + '/' + prefName, { value: pref }).then(function () {
        preferences[prefName] = pref;
      });
    }

    function deletePreference (prefName) {
      return $http.delete(baseUrl + '/' + prefName).then(function () {
        delete preferences[prefName];
      });
    }
  }
})();
