(function () {
  'use strict';

  angular.module('admin')
    .controller('cloneDashboardModalCtrl', cloneDashboardModalCtrl);

  cloneDashboardModalCtrl.$inject = [
    '$mdDialog', '$scope', '$timeout', 'DashboardsSrv', 'GroupsSrv', 'modalOwner', 'modalLevel', 'modalDashboards', 'ProvidersSrv', 'UsersSrv'
  ];

  function cloneDashboardModalCtrl (
    $mdDialog, $scope, $timeout, DashboardsSrv, GroupsSrv, modalOwner, modalLevel, modalDashboards, ProvidersSrv, UsersSrv
  ) {
    var vm = this;// eslint-disable-line no-invalid-this

    $scope.LEVELS = DashboardsSrv.LEVELS;

    vm.currentOwner = modalOwner;
    vm.dashboard = DashboardsSrv.construct({ level: modalLevel, provider: 'default' });
    vm.filteredOwners = [];
    vm.listOfOwners = [];
    vm.ownerFinder = '';
    vm.providers = [];

    vm.acOwner = { // autocomplete
      changeItem: selectOwner,
      changeText: filterOwners,
      items: [],
      searchText: '',
      selectedItem: null
    };

    // Methods.
    vm.cancel = $mdDialog.cancel;
    vm.create = $mdDialog.hide;

    activate();

    function activate () {
      if (modalLevel !== $scope.LEVELS.SYSTEM) {
        ProvidersSrv.getProviderList()
          .then(function (providers) {
            vm.providers = providers;
            vm.dashboard.provider = providers[0];

            if (modalLevel === $scope.LEVELS.USER) {
              UsersSrv.getList()
                .then(function (users) {
                  var usernames = modalDashboards.map(function (d) { return d.owner; });
                  vm.listOfOwners = users.filter(function (user) {
                    return usernames.indexOf(user.username + '@' + user.provider) === -1;
                  });
                });
            } else {
              GroupsSrv.getList()
                .then(function (groups) {
                  var groupnames = modalDashboards.map(function (d) { return d.group + '@' + d.provider; });
                  vm.listOfOwners = groups.filter(function (group) {
                    return groupnames.indexOf(group.name + '@' + group.provider) === -1;
                  });
                });
            }
          });
      }
    }

    function filterOwners (filter, force) {
      filter = filter || '';
      force = !!force;

      var rexFilter = new RegExp(filter, 'i');

      if (modalLevel === $scope.LEVELS.USER) {
        vm.acOwner.items = vm.listOfOwners.filter(function (owner) {
          return owner.provider === vm.dashboard.provider && (rexFilter.test(owner.username) || rexFilter.test(owner.name));
        });
      } else {
        vm.acOwner.items = vm.listOfOwners.filter(function (owner) {
          return owner.provider === vm.dashboard.provider && (rexFilter.test(owner.name) || rexFilter.test(owner.description));
        });
      }

      if (force) {
        // Trick the autocomplete into doing its thing without visual jank.
        vm.acOwner.searchText = vm.acOwner.searchText + ' ';
        $timeout(function () { vm.acOwner.searchText = filter; });
      }
    }

    function selectOwner (owner) {
      if (owner) {
        if (modalLevel === $scope.LEVELS.USER) {
          vm.dashboard.owner = owner.username;
        } else {
          vm.dashboard.group = owner.name;
        }
      }
    }
  }
})();
