(function () {
  'use strict';

  angular.module('imat.directives')
    .directive('imatVirtualList', imatVirtualList);

  imatVirtualList.$inject = [];

  function imatVirtualList () {
    return {
      controller: DomCtrl,
      priority: 89,
      require: 'mdList',
      restrict: 'A',
      scope: false,
      template: templater
    };

    function templater (tElement, tAttrs) { // eslint-disable-line complexity
      var contents = tElement.find('md-list-item');
      var itemSize = parseInt(tAttrs.imatVirtualList, 10) || 49;
      var rexTrackBy = / track by .+$/i;
      var tContainer = angular.element('<md-virtual-repeat-container></<md-virtual-repeat-container>');
      var tListItem;
      var tRepeater;

      if (Object.prototype.hasOwnProperty.call(tAttrs, 'imatSizer') && !tAttrs.imatSizer) {
        tAttrs.imatSizer = 'md-virtual-repeat-container';
      }

      if (contents) {
        for (var i = 0, ii = contents.length; i < ii; ++i) {
          if (contents[i].getAttribute('ng-repeat')) {
            tListItem = contents.eq(i);
            break;
          }
        }
      }

      if (!tListItem) {
        tElement.append(tContainer);
      } else {
        if (itemSize > 0) {
          tListItem.attr('md-item-size', itemSize);
        }

        tRepeater = tListItem.attr('ng-repeat') || '';

        if (rexTrackBy.test(tRepeater)) {
          tRepeater = tRepeater.replace(rexTrackBy, '');
        }

        tListItem.attr('md-virtual-repeat', tRepeater);
        tListItem.removeAttr('ng-repeat');
        tListItem.wrap(tContainer);
      }

      return tElement.html();
    }

    function DomCtrl ($scope, $element) {
      var $mdvrc;
      var listMinHeight = 0;
      var listMaxHeight = 0;
      var unsized = false;
      var vrcCtl;// XXX Colossal hack to get the thing to reorganize itself...

      this.$doCheck = function () {
        var calcHeight;
        var rectHeight;

        if (unsized && $mdvrc && $mdvrc.length) {
          calcHeight = getHeight($element, $mdvrc);

          if (calcHeight >= listMinHeight && calcHeight <= listMaxHeight) {
            rectHeight = Math.floor($mdvrc.get(0).getBoundingClientRect().height);

            // Fudge factor approx. equal to 2px border.
            // calcHeight may use content-box or border-box.
            if (Math.abs(calcHeight - rectHeight) > 2) {
              $mdvrc.css('height', calcHeight + 'px');
              vrcCtl.updateSize();
            }
          }
        }
      };

      this.$postLink = function () {
        var jQueryKeys;

        unsized = !$element.hasClass('imat-sized');

        if (unsized) {
          $mdvrc = $element.find('md-virtual-repeat-container');
          listMinHeight = parseInt($element.css('min-height'), 10);
          listMaxHeight = parseInt($element.css('max-height'), 10);

          jQueryKeys = Object.keys($mdvrc.get(0)).filter(function (key) { return /^jQuery\d+$/.test(key); });

          for (var i = 0, ii = jQueryKeys.length; i < ii; ++i) {
            if ($mdvrc[0][jQueryKeys[i]]) {
              vrcCtl = $mdvrc[0][jQueryKeys[i]].$mdVirtualRepeatContainerController;
              break;
            }
          }
        }
      };
    }

    function getHeight ($element, $mdvrc) {
      var $targets = $element.children();
      var height = 0;

      // Calculate available space in the mdList.
      for (var i = 0, ii = $targets.length; i < ii; ++i) {
        if ($targets[i] !== $mdvrc[0]) {
          height += $targets[i].getBoundingClientRect().height;
        }
      }
      return Math.floor($element[0].clientHeight - height);
    }
  }
})();
