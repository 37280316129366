(function () {
  'use strict';

  angular.module('imat.filters')
    .filter('spacesToUnderscores', function () {
      return function (key) {
        return key.replace(/\s/g, '_');
      };
    });
})();
