(function () {
  'use strict';

  angular
    .module('mpiApp.search')
    .controller('limboSearchCtrl', limboSearchCtrl);

  limboSearchCtrl.$inject = [
    '$interval', '$mdDialog', '$mdSidenav', '$q', '$scope', '$state', '$timeout',
    'dateTimeSrv', 'mpiApiSrv', 'mpiConfigSrv', 'mpiSearchSrv', 'psNotification', 'simpleQueryBuilder'
  ];

  function limboSearchCtrl (
    $interval, $mdDialog, $mdSidenav, $q, $scope, $state, $timeout,
    dateTimeSrv, mpiApiSrv, mpiConfigSrv, mpiSearchSrv, psNotification, simpleQueryBuilder
  ) {
    var config = {};
    var vm = this; // eslint-disable-line no-invalid-this

    $scope.dateLocale = {
      formatDate: function (dateObj) { // Datepicker dates.
        return dateTimeSrv.getIsoDate(dateObj, true);
      },
      parseDate: function (dateStr) { // Hand-entered dates.
        // The time component of the datepicker is NOT affected by this.
        return new Date(dateTimeSrv.normalizeDatePart(dateStr) + 'T12:00:00' + dateTimeSrv.getTimezoneOffsetStr(dateStr));
      }
    };

    // Properties
    vm.cached = {};
    vm.current = {};
    vm.pendingDeletion = [];
    vm.results = [];
    vm.selected = [];

    vm.currentPage = 1;
    vm.limit = 1;
    vm.total = 1;

    vm.columns = {
      'Date of Service': true,
      'Record Type': false,
      'Facility/MRN': true,
      'Full Name': true,
      Gender: true,
      'Birth Date': true,
      SSN: false,
      Phone: false,
      Address: false,
      City: false,
      State: false,
      Zip: false,
      Race: false,
      Ethnicity: false,
      URI: false
    };
    vm.fields = {};
    vm.loading = { fields: true, results: false };
    vm.recordText = 'record';
    vm.searched = false;
    vm.sections = { patientDemographics: false, patientIds: false, recordDetails: false };
    vm.selectedStatus = 'check_box_outline_blank';
    vm.topIndex = 0;

    // Methods
    vm.checkIfSelected = checkIfSelected;
    vm.clear = clear;
    vm.clearResults = clearResults;
    vm.firstPage = firstPage;
    vm.goToDetails = goToDetails;
    vm.hideDetails = hideDetails;
    vm.limitChange = limitChange;
    vm.nextPage = nextPage;
    vm.openMenu = openMenu;
    vm.renderPages = renderPages;
    vm.reviewSelected = reviewSelected;
    vm.search = search;
    vm.selectRecord = selectRecord;
    vm.showDetails = showDetails;
    vm.toggleSelectAll = toggleSelectAll;

    activate();

    // Public Methods

    function activate () {
      _getFields();
      $scope.$watch(function () { return vm.selected; }, function () {
        vm.recordText = vm.selected.length > 1 ? 'records' : 'record';
      });
    }

    function checkIfSelected (record) {
      var recordIndex = -1;
      var i = 0;
      for (i; i < vm.selected.length; i++) {
        if (vm.selected[i].uri === record.uri && !record.pendingDeletion) {
          recordIndex = i;
          break;
        }
      }
      return recordIndex;
    }

    function clear () {
      vm.fields = angular.copy(config.form_fields) || {};
    }

    function clearResults () {
      _clearSelection();
      hideDetails();
      mpiSearchSrv.clearCachedResults(config.store);
      vm.results = [];
      vm.searched = false;
      _scrollToElement('.search-view', '.mpi-search-form');
    }

    function goToDetails (id) {
      $state.go('app.mpi.limboQuery.limboRecord', { id: id });
    }

    function hideDetails () {
      vm.current = {};
      mpiSearchSrv.clearSelection(config.store);
      $mdSidenav('limboDetails').close();
    }

    function openMenu ($mdMenu, ev) {
      $mdMenu.open(ev);
    }

    function firstPage () {
      vm.loading.results = true;
      return _run(vm.fields, 1);
    }

    function nextPage () {
      vm.loading.results = true;
      return _run(vm.fields, vm.currentPage + 1);
    }

    function limitChange (limit) {
      vm.limit = limit || vm.limit;
      return _run(vm.fields, vm.currentPage);
    }

    function renderPages (page) {
      vm.loading.results = true;
      _setSelectedStatus();
      return _run(vm.fields, page);
    }

    function reviewSelected (ev) {
      $mdDialog.show({
        controller: 'reviewSelectionCtrl',
        controllerAs: 'ctrl',
        templateUrl: '/ui/mpi/search/review-selection-dialog.html',
        parent: angular.element('.page-content'),
        targetEvent: ev,
        locals: { records: vm.selected },
        clickOutsideToClose: true
      })
        .then(function (response) {
          return _deleteLimboRecords(response.records, response.comment, response.scrub)
            .catch(function (res) {
              psNotification.error((res.error.message || 'Unable to delete the record(s).'));
              return $q.reject();
            });
        })
        .then(function () {
          vm.results.forEach(function (record) {
            if (checkIfSelected(record) > -1) {
              record.pendingDeletion = true;
            }
          });
          vm.selected = [];
          _setSelectedStatus();
          psNotification.success('Delete request received. The record(s) may appear in search results until the request has been processed.');
        });
    }

    function search () {
      if (!_checkFields()) {
        psNotification.show('Please enter a value to search.');
        return;
      }

      _clearSelection();
      hideDetails();
      _run(vm.fields, 1)
        .then(function () {
          _scrollToElement('.search-view', '.search-results');
        });
    }

    function selectRecord (record) {
      var idx = checkIfSelected(record);
      if (idx > -1) {
        vm.selected.splice(idx, 1);
      } else {
        vm.selected.push(angular.copy(record));
      }
      _setSelectedStatus();
    }

    function showDetails (record, index) {
      vm.current = record;
      mpiSearchSrv.selectRecord(config.store, record, index);
      $mdSidenav('limboDetails').open();
    }

    function toggleSelectAll () {
      var resultsSubset = vm.results.filter(function (result) {
        return !result.pendingDeletion;
      });

      switch (vm.selectedStatus) { // eslint-disable-line default-case
        case 'check_box_outline_blank':
          vm.selected = vm.selected.concat(resultsSubset);
          break;

        case 'check_box':
          _clearSelectionsFromPage();
          break;

        case 'indeterminate_check_box':
          _clearSelectionsFromPage();
          vm.selected = vm.selected.concat(resultsSubset);
          break;
      }
      _setSelectedStatus();
    }

    // Private Methods

    function _checkFields () {
      for (var field in vm.fields) {
        if (vm.fields[field].value) {
          return true;
        }
      }
      return false;
    }

    function _checkPendingDelete (results, force) {
      return mpiApiSrv.getRecordsPendingDelete(force)
        .then(function (response) {
          vm.pendingDeletion = response;
          results.forEach(function (record) {
            if (vm.pendingDeletion.indexOf(record.uri) > -1) {
              record.pendingDeletion = true;
            }
          });
          return results;
        });
    }

    function _clearSelection () {
      vm.selected = [];
      _setSelectedStatus();
    }

    function _clearSelectionsFromPage () {
      vm.results.forEach(function (element) {
        for (var i = 0; i < vm.selected.length; i++) {
          if (vm.selected[i].uri === element.uri) {
            vm.selected.splice(i, 1);
          }
        }
      });
    }

    function _deleteLimboRecords (records, comment, scrub) {
      return mpiApiSrv.deleteLimboRecords(records, comment, scrub);
    }

    function _getFields () { // eslint-disable-line complexity
      config = mpiConfigSrv.getLimboConfig();
      vm.limit = config.limit;
      vm.cached = mpiSearchSrv.getCachedResults(config.store);

      if (angular.isDefined(vm.cached.formFields)) {
        vm.fields = angular.copy(vm.cached.formFields) || {};
      } else {
        vm.fields = angular.copy(config.form_fields) || {};
      }

      if (!vm.fields.default_fields || !vm.fields.default_fields.length) {
        for (var section in vm.sections) {
          if (Object.prototype.hasOwnProperty.call(vm.sections, section)) {
            vm.sections[section] = true;
          }
        }
      }

      if (vm.cached.results && vm.cached.results.length) {
        _processResults(vm.cached, vm.currentPage);
      }

      if (vm.cached.selected && vm.cached.selected.index) {
        $timeout(function () {
          showDetails(vm.cached.selected.data, vm.cached.selected.index);
          _scrollToElement('.search-view', '.search-results md-list-item:nth-child(' + vm.cached.selected.index + ')');
        }, 0);
      }

      vm.loading.fields = false;
    }

    function _processResults (res, page) {
      vm.results = (Array.isArray(res.results) ? res.results : (res.results ? [res.results] : []));
      vm.currentPage = page || 1;
      vm.topIndex = 0;
      vm.total = res.meta._total;
      _setSelectedStatus();
      vm.searched = true;
      return vm.results;
    }

    function _run (parameters, page) {
      hideDetails();
      vm.loading.results = true;

      var newQuery = {};
      var q = '()' + simpleQueryBuilder.getSimpleQuery(parameters);

      newQuery.formFields = parameters;
      newQuery.caller = config.caller; // What UI is the search coming from?
      newQuery.store = config.store; // Which store should we query?
      newQuery.report = config.report; // Do we need an exacttotal or not?
      newQuery.onspace = page === 1 ? 0 : parseInt(config.onspace, 10);
      newQuery.onref = page === 1 ? 0 : parseInt(config.onref, 10);
      newQuery.limit = vm.limit; // Configurable limit
      newQuery.fields = [
        'date_of_service',
        'patient_city',
        'patient_date_of_birth',
        'patient_ethnic_group',
        'patient_full_name',
        'patient_local_id',
        'patient_phone',
        'patient_race',
        'patient_sex',
        'patient_ssn',
        'patient_state',
        'patient_street_address',
        'patient_zip',
        'record_type',
        'uri'
      ].join(',');
      newQuery.q = q;

      if (newQuery.q === '()') {
        vm.loading.results = false;
        psNotification.show('Please enter a value to search.');
        return $q.reject();
      }

      return mpiSearchSrv.run(newQuery)
        .then(function (res) {
          config.onspace = res.meta.onspace;
          config.onref = res.meta.onref;
          return _processResults(res, page);
        })
        .then(function (res) {
          return _checkPendingDelete(res);
        })
        .catch(function () {
          psNotification.error('There was a problem with the search.');
        })
        .finally(function () {
          vm.loading.results = false;
          if (!vm.results.length) {
            psNotification.show('No results found.');
          }
        });
    }

    function _scrollToElement (scroller, scrollTo, speed) {
      if (!scrollTo) {
        return;
      }
      var iterations = speed || 70;
      var scrollStart = document.querySelector(scroller).scrollTop;
      var amount = scrollStart;
      var offsetTop = document.querySelector(scrollTo).offsetTop;

      $interval(function () {
        var scrollTop = document.querySelector(scroller).scrollTop;

        switch (true) {
          case offsetTop < scrollStart:
            amount = scrollTop - Math.floor(scrollStart / iterations);
            break;

          case scrollTop > 0 && scrollTop > offsetTop:
            amount = scrollTop;
            break;

          default:
            amount = amount + Math.floor(offsetTop / iterations);
            break;
        }

        document.querySelector(scroller).scrollTop = amount;
      }, 0, iterations);
    }

    function _setSelectedStatus () {
      var status;
      var resultsSelected = vm.results.filter(function (element) {
        return checkIfSelected(element) > -1;
      });
      var activeSubset = vm.results.filter(function (element) {
        return !element.pendingDeletion;
      });

      if (!resultsSelected.length) {
        status = 'check_box_outline_blank';
      }

      if (resultsSelected.length === activeSubset.length) {
        status = 'check_box';
      }

      if (resultsSelected.length && resultsSelected.length !== activeSubset.length) {
        status = 'indeterminate_check_box';
      }

      vm.selectedStatus = status;
      vm.recordText = vm.selected.length > 1 ? 'records' : 'record';
    }
  }
})();
