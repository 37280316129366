(function () {
  'use strict';

  angular
    .module('vhr.test')
    .factory('vhrRecordFix', vhrRecordFix);

  vhrRecordFix.$inject = ['$q'];

  function vhrRecordFix ($q) {
    var service;

    service = {
      getCxmlRecordClassConfig: getCxmlRecordClassConfig,
      getRecordClassConfig: getRecordClassConfig,
      getRecordTypeConfig: getRecordTypeConfig
    };

    function getCxmlRecordClassConfig (promise) {
      var fix = {
        type: 'ADT',
        config: getRecordTypeConfig().RECORD_TYPE.ADT
      };
      return (promise) ? $q.resolve(fix) : fix;
    }

    function getRecordClassConfig (promise) {
      var fix = {
        type: 'ADVANCE_DIRECTIVE',
        config: getRecordTypeConfig().RECORD_TYPE.ADVANCE_DIRECTIVE
      };
      return (promise) ? $q.resolve(fix) : fix;
    }

    function getRecordTypeConfig (promise) {
      var fix = {
        RECORD_TYPE: {
          ADT: {
            identifiers: ['adt', 'siu'],
            label: 'ADT',
            report: 'vhr_recordLookup',
            parameters: {},
            titleFormat: 'ADT results from {{facility}}'
          },
          ADVANCE_DIRECTIVE: {
            identifiers: ['advanceDirective'],
            label: 'Advance Directive',
            report: 'vhr_advanceDirectives',
            parameters: {},
            titleFormat: 'Advance Directive [{{print_label}}] from {{facility}}'
          },
          BOGUS: {
            identifiers: ['bogus'],
            label: 'Bogus',
            report: 'vhr_bogusTypes',
            parameters: {},
            titleFormat: 'Bogus record from {{facility}}'
          },
          LAB: {
            identifiers: [{ store: 'labs' }],
            label: 'Lab',
            report: 'vhr_recordLookup',
            parameters: {},
            titleFormat: 'Lab results from {{facility}}'
          },
          PATHOLOGY: {
            identifiers: ['Pathology'],
            label: 'Pathology',
            report: 'vhr_recordLookup',
            parameters: {},
            titleFormat: 'Pathology results from {{facility}}'
          },
          RADIOLOGY: {
            identifiers: ['Radiology'],
            label: 'Radiology',
            report: 'vhr_recordLookup',
            parameters: {},
            titleFormat: 'Radiology results from {{facility}}'
          },
          TRANSCRIPTION: {
            identifiers: ['report', { store: 'records', record_type: 'lab' }],
            label: 'Transcription',
            report: 'vhr_recordLookup',
            parameters: {},
            titleFormat: 'Transcription results from {{facility}}'
          },
          UNKNOWN: {
            identifiers: [],
            label: 'Unknown',
            report: 'vhr_recordLookup',
            parameters: {},
            titleFormat: 'Unknown record from {{facility}}'
          }
        }
      };
      return (promise) ? $q.resolve(fix) : fix;
    }

    return service;
  }
})();
