(function () {
  'use strict';

  angular.module('imat.services')
    .factory('DashboardsSrv', DashboardsSrv);

  DashboardsSrv.$inject = ['DASHBOARD', '$q', 'CachingClass', 'DashboardClass', 'DashboardItemsSrv', 'dashboardsApiSrv', 'ProvidersSrv', 'userSession', 'x2js'];

  function DashboardsSrv (DASHBOARD, $q, CachingClass, DashboardClass, DashboardItemsSrv, dashboardsApiSrv, ProvidersSrv, userSession, x2js) {
    var groupPriorities;
    var userList = [];

    var service = {
      ACCESS: DashboardClass.prototype.ACCESS,
      LEVELS: DashboardClass.prototype.LEVELS,

      construct: construct,
      delete: deleteDashboard,
      getCompleteList: getAllDashboards,
      getDashboard: getDashboard,
      getList: getDashboards,
      getPriorities: getGroupDashboardPriorities,
      getSelectedItem: getSelectedItem,
      getSettings: getDashboardSettings,
      reset: reset,
      save: saveDashboard,
      saveDisplayName: saveDisplayName,
      savePermissions: savePermissions,
      setPriorities: setGroupDashboardPriorities,

      // Helpers
      buildUrl: buildUrl,
      noneToNull: noneToNull,
      prepareDashboardData: prepareDashboardData
    };

    service = angular.extend((new CachingClass()), service);
    return service;

    function construct (data) {
      return new DashboardClass(data);
    }

    function deleteDashboard (dashboard) {
      return buildUrl(dashboard, '/delete')
        .then(function (url) {
          return dashboardsApiSrv.deleteDashboard(url);
        })
        .then(function () {
          service.ejectCachedItem(dashboard.id, true);
          dashboard.id = null;
          return dashboard;
        });
    }

    function getAllDashboards (force) {
      if (!force && service.isCached()) {
        return $q.resolve(service.getCached());
      }

      return ProvidersSrv.getProviderList()
        .then(function (providers) {
          var promises = [];

          providers.forEach(function (provider) {
            promises.push(
              getDashboards({
                admin: true,
                provider: provider
              })
            );
          });

          return $q.all(promises)
            .then(function (res) {
              var dashboards = [];
              res.forEach(function (dashboard) {
                dashboards = dashboards.concat(dashboard);
              });
              return service.cache(dashboards);
            });
        });
    }

    function getDashboard (params, force) {
      var id = (angular.isString(params) ? params : params.id);

      if (!force && id && service.isCachedItem(id)) {
        return $q.resolve(service.getCachedItem(id));
      }

      return (params instanceof DashboardClass ? loadDashboard(angular.copy(params)) : loadDashboard(construct(params)));
    }

    function getDashboards (params) {
      var noParams = params === undefined;

      if (noParams && userList.length) {
        return $q.resolve(userList);
      }

      params = params || { users: null };

      var url = '/extensions/dashboard/conf';
      var querystring = {};

      if (params.admin) {
        querystring.admin = 'true';// All dashboards for given provider; other filtering is ignored.
      }

      if (params.group) {
        querystring.group = params.group;// dashboard.group.
      }

      if (params.users && params.name) {
        querystring.name = params.name;// dashboard.name.
      }

      if (params.provider) {
        querystring.provider = params.provider;// dashboard.provider.
      }

      if (params.user) {
        querystring.user = params.user;// dashboard.owner.
      }

      return dashboardsApiSrv.getDashboards({ url: url, params: querystring }, params.users)
        .then(function (res) {
          var list;

          if (!params.users) {
            list = res.data.map(construct);
            userList = (noParams ? list : []);
            return list;
          }

          for (var user in res.data) {
            if (Array.isArray(res.data[user])) {
            // Multi-group responses embed dashboard data in the info property
              res.data[user] = res.data[user].map(extractInfo).map(construct);
            } else if (res.data[user] != null) {
              res.data[user] = [construct(res.data[user])];
            }
          }
          return res.data;
        });

      function extractInfo (data) {
        return data.info;
      }
    }

    function getDashboardSettings () {
      return dashboardsApiSrv.getDashboardSettings()
        .then(function (response) {
          return response.data;
        });
    }

    function getGroupDashboardPriorities (force) {
      if (!force && groupPriorities) {
        return $q.resolve(groupPriorities);
      }
      return dashboardsApiSrv.getGroupDashboardPriorities()
        .then(function (response) {
          groupPriorities = response.data;
          return groupPriorities;
        });
    }

    function getSelectedItem (ctx, force) {
      return CachingClass.prototype.getSelectedItem.call(service, ctx, force)
        .catch(function () {
          var id = service.getSelectedId(ctx);
          if (id) { return getDashboard(id, force); }
          return $q.reject();
        });
    }

    function reset () {
      userList = [];
    }

    function saveDashboard (dashboard) {
      return buildUrl(dashboard)
        .then(function (params) {
          return dashboardsApiSrv.saveDashboard(params, JSON.stringify(dashboard));
        })
        .then(function () {
          return getDashboard(dashboard, true);
        });
    }

    function saveDisplayName (dashboard) {
      return saveDashboard(dashboard);
    }

    function savePermissions (dashboard) {
      var authentication_type = 'username';  // eslint-disable-line
      var authentication_id = dashboard.owner;  // eslint-disable-line

      if (dashboard.level === service.LEVELS.GROUP) {
        authentication_type = 'groupname';   // eslint-disable-line
        authentication_id = dashboard.group;  // eslint-disable-line
      }

      var permissionXml = x2js.json2xml_str({
        permissions: {
          permission: {
            authentication_type: authentication_type,
            authentication_id: authentication_id,
            access_rights: dashboard.owner_permissions
          }
        }
      });

      return buildUrl(dashboard, '/permissions')
        .then(function (url) {
          return dashboardsApiSrv.savePermissions(url, permissionXml);
        })
        .then(function () {
          return getDashboard(dashboard, true);
        });
    }

    function setGroupDashboardPriorities (priorities) {
      return dashboardsApiSrv.setGroupDashboardPriorities(priorities)
        .then(function (response) {
          groupPriorities = priorities;
          return response.data;
        });
    }

    //= ================================
    // Helpers
    //= ================================

    function buildUrl (params, subpath) { // eslint-disable-line complexity
      params = params || {};

      var querystring = {};
      var url = '/extensions/dashboard/conf/';
      var user = userSession.getUserData();
      var isOwner = (params.owner && params.owner.toLowerCase() === user.systemName.toLowerCase());

      if (params.id) {
        url += 'id/' + params.id;
      } else if (params.name) {
        if ((params.level === service.LEVELS.GROUP) || params.group) {
          url += 'group/' + params.group + '/';
        } else if (params.level === service.LEVELS.SYSTEM) {
          querystring.admin = true;
          url += 'system/';
        } else if (params.level === service.LEVELS.BASE) {
          url += 'base/';
        } else {
          url += 'user/';
        }

        url += params.name;
      }
      if (subpath && subpath !== '/delete') {
        url += subpath;
      }

      if (params.provider && !params.id) {
        querystring.provider = params.provider;
      }

      if (params.owner && !params.id) {
        querystring.user = params.owner;
        querystring.admin = true;
      }

      if ((isOwner && subpath === '/permissions') || (!isOwner && params.owner)) {
        querystring.admin = true;
      }

      if (params.group || (params.effective_permissions == params.ACCESS.FULL && (params.effective_permissions !== params.owner_permissions))) { // eslint-disable-line eqeqeq
        querystring.admin = true;
      }

      if ((params.id && !isOwner) && user.roles.indexOf('system') >= 0) {
        querystring.admin = true;
      }

      return $q.resolve({ url: url, params: querystring });
    }

    function loadDashboard (dashboard) {
      var infoUrl = buildUrl(dashboard, '/info');// Includes all data except tiles.
      var mainUrl = buildUrl(dashboard);// Includes display_name, name, and tiles.

      return $q.all([infoUrl, mainUrl])
        .then(function (all) {
          var promises = [];

          all.forEach(function (url) {
            promises.push(dashboardsApiSrv.loadDashboard(url));
          });

          return $q.all(promises);
        })
        .then(function (all) {
          var infoUrlResponse = all[0];
          var mainUrlResponse = all[1];
          var promises = [];

          if (infoUrlResponse !== undefined || mainUrlResponse !== undefined) {
            if (angular.isArray(infoUrlResponse.data) && angular.isArray(mainUrlResponse.data)) {
              infoUrlResponse.data.forEach(function (infoUrlData, index) {
                promises.push(
                  prepareDashboardData(angular.extend({}, infoUrlData.info, mainUrlResponse.data[index].data))
                    .then(construct)
                );
              });
              return $q.all(promises).then(function (dashboards) {
                return dashboards.map(function (dashboard) { return service.cacheItem(dashboard); }); // eslint-disable-line no-shadow
              });
            }
            return prepareDashboardData(angular.extend({}, infoUrlResponse.data, mainUrlResponse.data))
              .then(construct).then(function (dashboard) { return service.cacheItem(dashboard); }); // eslint-disable-line no-shadow
          }
          return $q.reject('no_dashboard');
        });
    }

    function noneToNull (obj) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key) && obj[key] == 'None') { // eslint-disable-line eqeqeq
          obj[key] = null;
        }
      }
      return obj;
    }

    function prepareDashboardData (data) {
      var filter, sequence;

      noneToNull(data);

      data.tiles = data.tiles || [];

      filter = data.tiles.filter(function (t) {
        return t.type === DASHBOARD.ITEMS.TYPES.FILTER;
      });

      sequence = data.tiles.filter(function (t) {
        return t.type === DASHBOARD.ITEMS.TYPES.SEQUENCE;
      });

      data.tiles = data.tiles.filter(function (t) {
        return t.type && [DASHBOARD.ITEMS.TYPES.FILTER, DASHBOARD.ITEMS.TYPES.SEQUENCE].indexOf(t.type) === -1;
      });

      if (!sequence) {
        data.tiles.sort(compareItemPosition);
      }

      return $q.all(data.tiles.map(DashboardItemsSrv.loadItem))
        .then(function (all) {
          all.forEach(function (item) {
            item.ui.id = data.id + ':' + item.type + ':' + (item.link.sref || item.link.href || item.link.text);
          });

          if (!sequence) {
            sequence = {
              type: DASHBOARD.ITEMS.TYPES.SEQUENCE,
              items: data.tiles.map(function (i) { return i.ui.id; })
            };
          }

          data.tiles = all.concat(filter, sequence);
          return data;
        });
    }

    function compareItemPosition (a, b) {
      // Since gridster needs the tiles in order of row, col asc
      // Place null positions at the end of the array
      if (!a.data.position || a.data.position[0] === null || !b.data.position || b.data.position[0] === null) {
        if (a.data.position && a.data.position[0] === null && b.data.position && b.data.position[0] !== null) {
          return 1;
        }
        return 0;
      }

      var compare = a.data.position[0] - b.data.position[0];
      if (compare === 0) {
        return a.data.position[1] - b.data.position[1];
      }
      return compare;
    }
  }
})();
