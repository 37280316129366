(function () {
  'use strict';

  angular.module('imat')
    .run(init);

  init.$inject = [
    '$log', '$state', '$templateRequest', '$transitions', '$urlService', '$window', 'imatApp', 'psNotification', '$http'
  ];

  function init (
    $log, $state, $templateRequest, $transitions, $urlService, $window, imatApp, psNotification, $http
  ) {
    var MSG_ABORT_UNKNOWN = 'There was a problem loading the requested page.';
    var MSG_ABORT_NO_ROLE = 'You are not allowed to load the requested page.';
    var iconUrls = ['/ui/_shared/icons/ps.svg'];

    //= ================================
    // Routing
    //= ================================

    // Ensure URLs include the trailing slash.
    $urlService.rules.when(/[^/]\?|[^/]$/, function (matched, urlParts) {
      var url = $urlService.url().replace(urlParts.path, urlParts.path + '/').replace(/\/\//g, '/');
      return $urlService.url(url, true);
    });

    // General Handlers
    $transitions.onBefore({ to: 'app.admin.**' }, rerouteToNew);
    // $transitions.onBefore({ to: 'app.account.**' }, rerouteToNew);
    $transitions.onBefore({ to: 'app.**' }, meetsRoleConstraints);
    $transitions.onSuccess({ to: 'app.**' }, modifyDocument);

    // Default Error Handler
    $state.defaultErrorHandler(function (err) {
      var redirect = getRedirectState($urlService.parts());
      if (redirect) {
        $state.transitionTo(redirect, null, { location: 'replace', reload: true });
      } else if (err && err.detail && err.detail.message) {
        // The original rejection reason is preserved in the detail property.
        psNotification.error(err.detail.message);
      } else {
        psNotification.error(MSG_ABORT_UNKNOWN);
      }
    });

    //= ================================
    // Startup
    //= ================================

    angular.forEach(iconUrls, function (url) {
      $templateRequest(url);
    });

    imatApp.load()
      .then(function () {
        // Redirect if we don't know what's happening.
        $urlService.rules.otherwise(function (matched, urlParts) {
          var redirect = getRedirectState(urlParts);
          if (redirect) {
            $state.transitionTo(redirect, null, { location: 'replace', reload: true });
          } else {
            $state.transitionTo('app.dashboard.main');
          }
        });
        $urlService.listen();
        $urlService.sync();
      })
      .catch(function (err) {
        isLoggedIn().then(function (isLoggedIn) {
          if (!isLoggedIn) {
            $window.location.assign('/account/login');
          } else {
            document.write('Failed to load the app: ' + err);
            $log.error(err);
            throw err;
          }
        });
      });

    //= ================================
    // Helpers
    //= ================================

    function isLoggedIn () {
      return $http.get('/account/whoami', {
        headers: { Accept: 'application/json, text/plain, */*' }
      }).then(function (res) {
        return true;
      }).catch(function () {
        return false;
      });
    }

    function getRedirectState (urlParts) {
      var redirects = [
        { rex: /^\/vhr\/.+/, state: 'app.vhr.lookup' }
      ];

      for (var i = 0, ii = redirects.length; i < ii; ++i) {
        if (redirects[i].rex.test(urlParts.path)) {
          return redirects[i].state;
        }
      }
    }

    function meetsRoleConstraints (trans) {
      var $mdDialog = trans.injector().get('$mdDialog');
      var stateRoles = (trans.to().data && trans.to().data.roles);
      var userSessionSrv = trans.injector().get('userSession');
      if (userSessionSrv.hasRoles(stateRoles || [])) {
        $mdDialog.cancel();
        return true;
      }

      psNotification.error(MSG_ABORT_NO_ROLE);
      return false;
    }

    function modifyDocument (trans) {
      var imatConfig = trans.injector().get('imatConfig');
      var stateAppKey = (trans.to().data && trans.to().data.appKey);

      $window.document.title = imatConfig.get("apps['" + stateAppKey + "'].title") || 'IMAT Solutions';
      angular.element($window.document).find('body').attr('id', trans.to().name.split('.')[1] + '-app');
    }

    function rerouteToNew (trans) {
      $window.location.replace($state.href(trans.to().name, {}, { absolute: true }).replace('/ui/#', '/app/#'));
      return false;
    }
  }
})();
