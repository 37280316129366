(function () {
  'use strict';

  angular
    .module('imat.services')
    .factory('searchApiSrv', searchApiSrv);

  searchApiSrv.$inject = ['$http'];

  function searchApiSrv ($http) {
    var baseUrl = '/search';
    var defaultConfig = { headers: { Accept: 'application/json', 'Content-Type': 'application/json' } };
    var formConfig = { headers: { Accept: 'application/xml, text/plain, */*', 'Content-Type': 'application/x-www-form-urlencoded' } };

    var service = {
      cancelSearch: cancelSearch,
      ensureSession: ensureSession,
      getSearchDetails: getSearchDetails,
      getResultOutput: getResultOutput,
      getSearchesList: getSearchesList,
      getSearchStatus: getSearchStatus,
      post: post
    };

    return service;

    function cancelSearch (id, isAdmin) {
      return $http.delete([baseUrl, 'results', id].join('/'), { params: { admin: !!isAdmin || null } });
    }

    function ensureSession (sessionid) {
      sessionid = Array.isArray(sessionid) ? sessionid : [sessionid];
      return $http.post('/api/search/sessions/ensuresession', { sessionid: sessionid });
    }

    function getSearchDetails (id) {
      return $http.get([baseUrl, 'results', id, 'info'].join('/'), { params: { admin: true } });
    }

    function getResultOutput (id, path) {
      path = path || '';
      return $http.get([baseUrl, 'results', id, 'output', path].join('/'))
        .then(function (res) {
          if (!path) {
            // Expected:
            //  directory._mode = "list"
            //  directory._path = "/output<path>" (<path> usually "/")
            //  directory.files
            //    directory.files._count
            //    directory.files.file (string)
            //      directory.files.file._size
            //  directory.flags (same as files)
            //  directory.folders (same as files)
            res.data = res.data.directory.files.file || [];
            res.data = Array.isArray(res.data) ? res.data : [res.data];
            res.data = res.data.map(function (file) {
              return file.toString();
            });
          }
          return res;
        });
    }

    function getSearchesList (params) {
      return $http.get(baseUrl + '/results', { params: params });
    }

    function getSearchStatus (id, isAdmin) {
      return $http.get([baseUrl, 'results', id, 'status'].join('/'), { params: { admin: !!isAdmin || null } });
    }

    function post (data, config, formEncoded) {
      var payload = angular.copy(data);

      config = formEncoded ? angular.merge({}, angular.copy(formConfig), config) : angular.merge({}, angular.copy(defaultConfig), config);

      if (payload) {
        if (Array.isArray(payload.facets)) {
          payload.facets = payload.facets.join(',');
        }
        if (Array.isArray(payload.fields)) {
          payload.fields = payload.fields.join(',');
        }
      }

      return $http.post(baseUrl, payload, config);
    }
  }
})();
