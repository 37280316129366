(function () {
  'use strict';

  angular.module('admin')
    .controller('ViewIndexRecordModalCtrl', ViewIndexRecordModalCtrl);

  ViewIndexRecordModalCtrl.$inject = ['$mdDialog', '$scope', 'selectedRecord'];

  function ViewIndexRecordModalCtrl ($mdDialog, $scope, selectedRecord) {
    var vm = this;

    // Properties.
    vm.loading = true;
    vm.editorConfig = {
      mode: 'xml',
      lineWrapping: false,
      lineNumbers: true,
      indentUnit: 4,
      theme: 'eclipse',
      readOnly: true,
      extraKeys: {
        'Ctrl-F': 'findPersistent',
        'Cmd-F': 'findPersistent'
      },
      highlightSelectionMatches: {
        showToken: true,
        annotateScrollbar: true
      }
    };
    vm.selectedRecord = selectedRecord;

    // Methods.
    vm._codemirrorLoaded = _codemirrorLoaded;
    vm.closeModal = closeModal;

    activate();

    function activate () {
      // ...
    }

    function closeModal () {
      $mdDialog.cancel();
    }

    // Makes codemirror object events available to tie further logic to.
    function _codemirrorLoaded (editor) {
      vm.codeEditor = editor;
      // Editor part.
      var _doc = vm.codeEditor.getDoc();
      // Options.
      _doc.markClean();
      // Events.
      vm.codeEditor.on('focus', function () {
        vm.editorFocused = true;
        $scope.$apply();
      });
      vm.codeEditor.on('blur', function () {
        vm.editorFocused = false;
        $scope.$apply();
      });
      vm.codeEditor.on('refresh', function () {
        var doc = vm.codeEditor.getDoc();
        doc.markClean();
      });
    }
  }
})();
