(function () {
  'use strict';

  angular.module('mpiApp.services')
    .service('piRecord', piRecord);

  piRecord.$inject = [
    '$http', '$parse', '$q',
    'fhirExtensionSrv', 'fhirValueSetSrv', 'mpiConfigSrv'
  ];

  function piRecord (
    $http, $parse, $q,
    fhirExtensionSrv, fhirValueSetSrv, mpiConfigSrv
  ) {
    var service;

    service = {
      assignMPID: assignMPID,
      lookupMPID: lookupMPID,
      // extractIdentifier: extractIdentifier,
      getPiTemplate: getPiTemplate,
      cxmlToFhir: cxmlToFhir
    };

    var parseMaps = {
      // Each definition must order CXML concepts in document/occurance order.
      name: {
        definitions: [[
          { cxml: 'patient_first_name', fhir: 'given', isArray: true },
          { cxml: 'patient_last_name', fhir: 'family' },
          { cxml: 'patient_middle_name', fhir: 'given', isArray: true }
        ]]
      },
      telecom: {
        definitions: [
          [{ cxml: 'patient_phone', fhir: 'value' }, { value: 'phone', fhir: 'system' }],
          [{ cxml: 'patient_email', fhir: 'value' }, { value: 'email', fhir: 'system' }]
        ]
      },
      address: {
        definitions: [[
          { cxml: 'patient_street_address', fhir: 'line', isArray: true },
          { cxml: 'patient_city', fhir: 'city' },
          { cxml: 'patient_state', fhir: 'state' },
          { cxml: 'patient_zip', fhir: 'postalCode' }
        ]]
      },
      birthDate: {
        definitions: [
          [{ cxml: 'patient_date_of_birth' }]
        ]
      },
      deceasedBoolean: {
        definitions: [
          [{ cxml: 'deceasedBoolean' }],
          [{ cxml: 'patient_death_indicator' }]
        ]
      },
      // 'deceasedDateTime': {
      //   definitions: [
      //     [{ cxml: 'deceasedDateTime' }]
      //   ]
      // },
      multipleBirthBoolean: {
        definitions: [
          [{ cxml: 'multipleBirthBoolean' }]
        ]
      },
      multipleBirthInteger: {
        definitions: [
          [{ cxml: 'patient_birth_order' }]
        ]
      },
      gender: {
        definitions: [
          [{ cxml: 'patient_sex' }]
        ]
      },
      maritalStatus: {
        definitions: [
          [{ cxml: 'patient_marital_status' }]
        ]
      },
      communication: {
        definitions: [
          [{ cxml: 'patient_language' }]
        ]
      },
      identifier: {
        definitions: [
          [{ cxml: 'patient_ssn', fhir: 'value' }, { value: 'http://hl7.org/fhir/sid/us-ssn', fhir: 'system' }, { value: 'SS', fhir: 'type' }],
          [{ cxml: 'patient_local_id', fhir: 'value' }, { value: '__facility__', fhir: 'system' }, { value: 'MR', fhir: 'type' }]
        ]
      },
      extension: {
        definitions: {
          'patient-mothersMaidenName': [
            { cxml: 'mothersMaidenName' },
            { schema: 'PATIENT_MOTHERS_MAIDEN_NAME' }
          ],
          'patient-religion': [
            { cxml: 'patient_ethnicity_code', fhir: 'code' }, // valueCodeableConcept.coding[0].code
            { cxml: 'patient_ethnicity', fhir: 'display' }, // valueCodeableConcept.coding[0].display
            { cxml: 'patient_ethnicity', fhir: 'text' }, // valueCodeableConcept.text
            { schema: 'PATIENT_RELIGION' }
          ],
          'us-core-ethnicity': [
            { cxml: 'patient_ethnicity_code', fhir: 'code' },
            { cxml: 'patient_ethnicity', fhir: 'display' },
            { schema: 'US_CORE_ETHNICITY', binding: 'ombCategory' }
          ],
          'us-core-race': [
            { cxml: 'patient_race_code', fhir: 'code' },
            { cxml: 'patient_race', fhir: 'display' },
            { schema: 'US_CORE_RACE', binding: 'ombCategory' }
          ]
        }
      }
    };

    var parsers = {
      name: function (tpl, recordGroups, definitions) {
        parse('name', tpl, recordGroups, definitions);
      },
      telecom: function (tpl, recordGroups, definitions) {
        parse('telecom', tpl, recordGroups, definitions, function (tmp, record, def) {
          // Callback result: true = exclude; false = append to Patient; null = append to property;
          var system = def.filter(function (d) { return d.fhir === 'system'; }).pop();
          if (system) {
            tmp.system = system.value;
          }
        });
      },
      address: function (tpl, recordGroups, definitions) {
        parse('address', tpl, recordGroups, definitions);
      },
      birthDate: function (tpl, recordGroups, definitions) {
        parse('birthDate', tpl, recordGroups, definitions);
      },
      deceasedBoolean: function (tpl, recordGroups, definitions) {
        parse('deceasedBoolean', tpl, recordGroups, definitions, function (tmp, record, def) { // eslint-disable-line no-unused-vars
          // Callback result: true = exclude; false = append to Patient; null = append to property;
          if (angular.isString(record[0].value)) {
            record[0].value = /^(?:true|yes|1)$/i.test(record[0].value);
          } else {
            record[0].value = !!record[0].value;
          }
        });
      },
      // deceasedDateTime: function(tpl, recordGroups, definitions) {
      //   parse('deceasedDateTime', tpl, recordGroups, definitions, function(tmp, record, def) {
      //     // Callback result: true = exclude; false = append to Patient; null = append to property;
      //     record[0].value = DateTimeSrv.getIsoDatetime(record[0].value);
      //   });
      // },
      multipleBirthBoolean: function (tpl, recordGroups, definitions) {
        parse('multipleBirthBoolean', tpl, recordGroups, definitions, function (tmp, record, def) { // eslint-disable-line no-unused-vars
          // Callback result: true = exclude; false = append to Patient; null = append to property;
          if (angular.isString(record[0].value)) {
            record[0].value = /^(?:true|yes|1)$/i.test(record[0].value);
          } else {
            record[0].value = !!record[0].value;
          }
        });
      },
      multipleBirthInteger: function (tpl, recordGroups, definitions) {
        parse('multipleBirthInteger', tpl, recordGroups, definitions);
      },
      gender: function (tpl, recordGroups, definitions) {
        parse('gender', tpl, recordGroups, definitions);
      },
      maritalStatus: function (tpl, recordGroups, definitions) {
        parse('maritalStatus', tpl, recordGroups, definitions, function (tmp, record, def) { // eslint-disable-line no-unused-vars
          // Callback result: true = exclude; false = append to Patient; null = append to property;
          var coding = fhirValueSetSrv.getValueSetCoding('MARITAL_STATUS', record[0].value);
          if (!coding) {
            return true;// Abort & exclude.
          }
          tmp.coding[0] = coding;
          return false;// Append to Patient.
        });
      },
      communication: function (tpl, recordGroups, definitions) {
        parse('communication', tpl, recordGroups, definitions, function (tmp, record, def) { // eslint-disable-line no-unused-vars
          // Callback result: true = exclude; false = append to Patient; null = append to property;
          var coding = fhirValueSetSrv.getValueSetCoding('LANGUAGES', record[0].value);
          if (!coding) {
            return true;// Abort & exclude.
          }
          tmp.language.coding[0] = coding;
          return false;// Append to Patient.
        });
      },
      identifier: function (tpl, recordGroups, definitions) {
        parse('identifier', tpl, recordGroups, definitions, function (tmp, record, def) {
          // Callback result: true = exclude; false = append to Patient; null = append to property;
          var system, typeCode, typeCoding;

          system = def.filter(function (d) { return d.fhir === 'system'; }).pop();
          if (!system) {
            return true;// Abort & exclude.
          }
          tmp.system = system.value;
          tmp.use = 'official';
          // Identifier.type is not to be preferred over .system + .value.
          // See https://www.hl7.org/fhir/datatypes.html#identifier
          // "The type deals only with general categories of identifiers and
          //  SHOULD not be used for codes that correspond 1..1 with the
          //  Identifier.system."
          typeCode = def.filter(function (d) { return d.fhir === 'type'; }).pop();
          if (typeCode) {
            typeCoding = fhirValueSetSrv.getValueSetCoding('IMAT_IDENTIFIER_TYPE', typeCode.value);
            if (typeCoding) {
              tmp.type.coding[0] = typeCoding;
            }
            if (typeCode.value === 'MR') {
              var systemId = mpiConfigSrv.systemId;
              var split = record[0].value.split(/[\@\:\~]/); // eslint-disable-line
              if (split.length === 2) {
                tmp.assigner.display = split[0];
                tmp.system = systemId.replace(/(https?:\/\/.+?)\/.*/, '$1/facility/') + split[0] || '';
                tmp.value = split[1];
                return tmp;
              }
            }
          }
        });
      },
      extension: function (tpl, recordGroups, definitions) {
        var idx = 0;

        angular.forEach(definitions, function (definition, ext) {
          var part = getPiTemplate("extensions['" + ext + "']");
          if (part && idx < recordGroups.length) {
            parseExtension(tpl, recordGroups[idx], part, definition);
          }
          ++idx;
        });
      }
    };

    return service;

    //= ================================
    // Public interface
    //= ================================

    function assignMPID (piJson, mpid) {
      // TODO: format piRecord to FHIR & assign new pi record to mpid
      // TODO: assign api-> sent FHIR & MPID get back assigned MPID

      /* Brainstorm placeholder for steps need to setup ***********
      return _convertPiJsonToFhir()// convert piRecord json to FHIR
        .then(function(piFhir) {
          return $http({ //assign mpid
            method: 'POST',
            url: 'noidea?mpid=' + mpid,
            headers: {'Content-Type': 'application/json', 'Accept': 'application/json'},
            data: piFhir
          })
          .then(function(response) {
            //TODO: get assignedMPID out of response
            var assignedMPID = response;
            return assignedMPID;
          })
        })
       */

      var assignedMPID = mpid;
      return $q.resolve(assignedMPID);// fake assigned mpid
    }

    function lookupMPID () {
      // TODO: api call to see what mpid will be assigned (look before leap)
      return $q.resolve('1');
    }

    function getPiTemplate (partial) {
      partial = partial || false;

      var finder;
      var t;
      var template = {
        resourceType: 'Patient',
        meta: {
          lastUpdated: ''
        },
        name: [
          {
            family: '',
            given: [],
            use: ''
          }
        ],
        telecom: [
          {
            system: '',
            use: '',
            value: ''
          }
        ],
        address: [
          {
            city: '',
            line: [],
            postalCode: '',
            state: '',
            type: '',
            use: ''
          }
        ],
        birthDate: '',
        multipleBirthBoolean: false,
        multipleBirthInteger: 0,
        deceasedBoolean: false,
        deceasedDateTime: null,
        gender: '',
        maritalStatus: {
          coding: [
            {
              code: '',
              display: '',
              system: ''
            }
          ]
        },
        id: '',
        communication: [
          {
            language: {
              coding: [
                {
                  code: '', // e.g. en-US
                  display: '', // e.g. English
                  system: 'urn:ietf:bcp:47' // Don't think this will ever change.
                }
              ]
            },
            preferred: false
          }
        ],
        identifier: [
          {
            use: '',
            value: '',
            assigner: {
              display: '',
              reference: ''
            },
            system: '',
            type: {
              coding: [{}]
            }
          }
        ],
        extension: [
          {
            extension: [
              {
                url: 'ombCategory',
                valueCoding: {
                  code: '',
                  display: '',
                  system: ''
                }
              }
            ],
            url: ''
          }
        ],
        extensions: { // Must be valid JSON! (Watch trailing commas!)
          'patient-mothersMaidenName': {
            url: 'http://hl7.org/fhir/StructureDefinition/patient-mothersMaidenName',
            valueString: ''
          },
          'patient-religion': {
            url: 'http://hl7.org/fhir/StructureDefinition/patient-religion',
            valueCodeableConcept: {
              coding: [{
                code: '',
                display: '',
                system: ''
              }],
              text: ''
            }
          },
          'us-core-ethnicity': {
            url: 'http://hl7.org/fhir/us/core/StructureDefinition/us-core-ethnicity',
            extension: [
              {
                url: 'detailed',
                valueCoding: {
                  code: '',
                  display: '',
                  system: ''
                }
              },
              {
                url: 'ombCategory',
                valueCoding: {
                  code: '',
                  display: '',
                  system: ''
                }
              },
              {
                url: 'text',
                valueString: ''
              }
            ]
          },
          'us-core-race': {
            url: 'http://hl7.org/fhir/us/core/StructureDefinition/us-core-race',
            extension: [
              {
                url: 'detailed',
                valueCoding: {
                  code: '',
                  display: '',
                  system: ''
                }
              },
              {
                url: 'ombCategory',
                valueCoding: {
                  code: '',
                  display: '',
                  system: ''
                }
              },
              {
                url: 'text',
                valueString: ''
              }
            ]
          }
        }

        // Not being used right now
        //
        // "communication": [
        //   {
        //     "language": {
        //       "coding": [
        //         {
        //           "code": "en-US",
        //           "display": "English",
        //           "system": "urn:ietf:bcp:47"
        //         }
        //       ]
        //     },
        //     "preferred": true
        //   }
        // ],
        // "generalPractitioner": [
        //   {
        //     "display": "Dr. John Smith",
        //     "reference": "https://test.imat.io/fhir/practitioner/john_smith"
        //   }
        // ],
        // "meta": {
        //   "lastUpdated": "2016-07-1015:20:38.777000"
        // },
        // "multipleBirthInteger": 7,
        //
      };

      if (partial) {
        finder = $parse(partial);
        t = finder(template);
        t = (angular.isArray(t) ? t[0] : t);
        t = angular.copy(t);
      } else {
        t = angular.copy(template);
        Object.keys(t).forEach(function (key) {
          if (angular.isArray(t[key])) {
            t[key] = [];
          }
        });
        delete t.extensions;
      }
      return t;
    }

    function cxmlToConceptArray (xml) {
      var concepts = xml.match(/<data( [\w-]+=(['"]).*?\2)+ ?\/?>/g) || [];
      var rexType = / type=(['"])(.*?)\1/;
      var rexValue = / value=(['"])(.*?)\1/;

      // The concepts array contains no capture groups due to the global ("g") flag.
      return concepts.map(function (concept) {
        var conceptType = concept.match(rexType);
        var conceptValue = concept.match(rexValue);

        if (conceptType.length === 3 && conceptValue.length === 3) {
          return { type: conceptType[2], value: conceptValue[2] };
        }
        return null;
      }).filter(function (concept) {
        return concept != null;
      });
    }

    function cxmlToFhir (xml) {
      var concepts = cxmlToConceptArray(xml);
      var template = getPiTemplate();

      for (var fhirConcept in template) {
        if (parsers[fhirConcept]) {
          var parseMap = parseMaps[fhirConcept] || [];
          var recordGroups = getAllGroupedConcepts(concepts, parseMap);

          if (recordGroups.length) {
            parsers[fhirConcept](template, recordGroups, parseMap.definitions);
          }
        }
      }
      return template;
    }

    function getAllGroupedConcepts (concepts, parseMap) {
      if (!concepts) { return []; }
      if (!angular.isObject(parseMap.definitions)) { return []; }

      var grouped = [];

      angular.forEach(parseMap.definitions, function (definition) {
        grouped.push(getGroupedConcepts(concepts, definition));
      });

      return grouped;
    }

    function getGroupedConcepts (concepts, definition) {
      var grouped = [[]];
      var types = definition.map(function (mapping) {
        return mapping.cxml;
      }).filter(function (mapping) {
        return mapping != null;
      });

      var conceptIdx = 0;
      var currentIdx = -1;
      var groupedIdx = 0;

      concepts.forEach(function (concept) {
        var idx = types.indexOf(concept.type);

        if (idx < 0) {
          return;// continue
        }
        conceptIdx = idx;
        if (conceptIdx < currentIdx || (conceptIdx === currentIdx && !definition[idx].isArray)) {
          // We need to start a new group.
          groupedIdx = grouped.push([]) - 1;
        }
        currentIdx = conceptIdx;
        concept.idx = conceptIdx;
        concept.def = definition;
        grouped[groupedIdx].push(concept);
      });

      return grouped;
    }

    function parse (property, tpl, recordGroups, definitions, callback) {
      var part = getPiTemplate(property);
      recordGroups.forEach(function (records, didx) {
        records.forEach(function (record) {
          var tmp = angular.copy(part);
          var cbr = callback && angular.isFunction(callback);

          cbr = cbr && record.length && callback(tmp, record, definitions[didx]);
          // Callback result: true = exclude; false = append to Patient; null = append to property;

          if (!record.length) {
            return;// continue (abort)
          }
          if (cbr === true) {
            return;// continue (abort)
          }
          if (cbr == null) {
            // Append to property.
            record.forEach(function (field) {
              var mapping = field.def[field.idx];
              var prop = mapping.fhir || property;

              if (angular.isArray(tmp[prop])) {
                tmp[prop].push(field.value);
              } else if (angular.isObject(tmp)) {
                tmp[prop] = field.value;
              } else {
                tmp = field.value;
              }
            });
          }
          // Append to Patient.
          if (angular.isArray(tpl[property])) {
            tpl[property].push(tmp);
          } else {
            tpl[property] = tmp;
          }
        });
      });
    }

    function parseExtension (tpl, records, part, definition) {
      records.forEach(function (record) { // eslint-disable-line complexity
        var codingByCode;
        var codingByDisplay;
        var extRef;
        var tmp = angular.copy(part);
        var valueSet;
        var valueType;

        if (!record.length) {
          return;// continue
        }

        var def = definition.filter(function (d) { return Object.prototype.hasOwnProperty.call(d, 'schema'); }).pop();
        var schema = fhirExtensionSrv.getDefinition(def.schema);

        if (!schema) {
          return;// continue
        }

        if (schema.type) {
          extRef = tmp;
          valueType = fhirExtensionSrv.getValueType(schema.type);
        } else if (def.binding) {
          extRef = tmp.extension.filter(function (e) { return e.url === def.binding; })[0];
          valueSet = fhirExtensionSrv.getBinding(schema, def.binding).valueSet;
          valueType = fhirExtensionSrv.getBindingType(schema, def.binding);
        } else {
          return;// continue
        }

        record.forEach(function (field) {
          var mapping = field.def[field.idx];

          if (mapping.fhir) {
            extRef[valueType][mapping.fhir] = field.value;
          } else {
            extRef[valueType] = field.value;
          }
        });

        if (valueSet && valueType === fhirExtensionSrv.getValueType('Coding')) {
          // We can validate values...
          codingByCode = fhirValueSetSrv.getValueSetCoding(valueSet, extRef[valueType].code);
          codingByDisplay = fhirValueSetSrv.getValueSetCoding(valueSet, extRef[valueType].display);
          if (codingByCode && codingByDisplay && !angular.equals(codingByCode, codingByDisplay)) {
            return;// continue
          }
          if (codingByCode) {
            extRef[valueType] = codingByCode;
          } else if (codingByDisplay) {
            extRef[valueType] = codingByDisplay;
          } else {
            return;// continue
          }
        }

        tpl.extension.push(tmp);
      });
    }

    // poc in progress- support for defining ssn vs fmrn in ui
    // function extractIdentifier(record, extract){
    //   var output;
    //   switch (extract){
    //     case 'fmrn':
    //       output = record.identifier.find(function(value, index, array){
    //         return value.type.coding[0].system == MPI_FHIR.SYS.HL7_IDENTIFIER && value.type.coding[0].code == MPI_FHIR.CODE.MRN;
    //       });
    //     break;

    //     case 'ssn':
    //       output = record.identifier.find(function(value, index, array){
    //         return value.system == MPI_FHIR.SYS.HL7_US_SSN && value.type.coding[0].code == MPI_FHIR.CODE.SS;
    //       });
    //     break;

    //     case 'pi':
    //       output = record.identifier.find(function(value, index, array){
    //         return value.type.coding[0].system == MPI_FHIR.SYS.IMAT_MPI && value.type.coding[0].code == MPI_FHIR.CODE.PI;
    //       });
    //     break;
    //   }
    //   return output;
    // }
  }
}());
