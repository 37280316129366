(function () {
  'use strict';

  angular.module('imat.services')
    .factory('SymbolsSrv', SymbolsSrv);

  SymbolsSrv.$inject = ['$filter', '$q', 'symbolsApiSrv', 'x2js'];

  function SymbolsSrv ($filter, $q, symbolsApiSrv, x2js) {
    var categories = [];
    var categorizedList = {};
    var combinedList = [];
    var symbols = [];
    var service = {
      createSymbol: createSymbol,
      createSymbolCategory: createSymbolCategory,
      deleteSymbol: deleteSymbol,
      getCategorizedList: getCategorizedList,
      getCombinedList: getCombinedList,
      getCombinedListCounts: getCombinedListCounts,
      getCategoryList: getCategoryList,
      getList: getSymbolList,
      getSymbol: getSymbol,
      postSymbolFields: postSymbolFields,
      postSymbolValues: postSymbolValues,
      updateSymbol: updateSymbol
    };

    return service;

    //= ================================
    // Public interface
    //= ================================

    function createSymbol (symbol) {
      if (!symbol.type || !symbol.category || !symbol.name) {
        return $q.reject();
      }

      formatSymbolXmlBody(symbol);

      return symbolsApiSrv.putSymbol(symbol);
    }

    function createSymbolCategory (category, body) {
      return symbolsApiSrv.createSymbolCategory(category, body);
    }

    function deleteSymbol (symbol) {
      if (!symbol.type || !symbol.category || !symbol.name) {
        return $q.reject();
      }
      return symbolsApiSrv.deleteSymbol(symbol)
        .catch(function (error) {
          if (error.status === 404) {
            return $q.resolve(true);
          }
          return $q.reject(error);
        });
    }

    function getCategorizedList () {
      return getCombinedList()
        .then(function () {
          return categorizedList;
        });
    }

    function getCombinedList (force) {
      if (!force && combinedList.length) {
        return $q.resolve(combinedList);
      }
      return getSymbolList(force)
        .then(function (symbolsRes) {
          var tempSymbol = {};
          var tempList = [];
          symbolsRes.forEach(function (symbol) {
            if (tempSymbol.category !== symbol.category) {
              categorizedList[symbol.category] = [];
              categorizedList[symbol.category].push(symbol.name + ' ' + symbol.desc);
              tempList.push({ sectionHeader: true, category: symbol.category });
              tempList.push(symbol);
              // Make sure category the same and name not duplicated for combined list
            } else if (tempSymbol.category === symbol.category && tempSymbol.name !== symbol.name) {
              tempList.push(symbol);
              categorizedList[symbol.category].push(symbol.name + ' ' + symbol.desc);
            }
            tempSymbol = symbol;
          });
          combinedList = tempList;
          return combinedList;
        });
    }

    function getCombinedListCounts () {
      return getCombinedList()
        .then(function () {
          return {
            categories: categories.length,
            symbols: combinedList.length - categories.length
          };
        });
    }

    function getCategoryList (force) {
      if (!force && categories.length) {
        return $q.resolve(categories);
      }
      return getSymbolList(force).then(function () {
        return categories;
      });
    }

    function getSymbol (symbol) {
      if (!symbol.type || !symbol.category || !symbol.name) {
        return $q.reject();
      }

      return symbolsApiSrv.getSymbol(symbol)
        .then(function (symbolRes) {
          return symbolRes.data.symbol;
        });
    }

    function getSymbolList (force) {
      if (!force && symbols.length) {
        return $q.resolve(symbols);
      }

      reset();
      return symbolsApiSrv.getList({ cache: false })
        .then(function (symbolsRes) {
          symbols = parseSymbolListResponse(symbolsRes);
          symbols.forEach(function (symbol) {
            if (categories.indexOf(symbol.category) === -1) {
              categories.push(symbol.category);
            }
          });
        })
        .then(function () {
          categories.sort();
          symbols = $filter('orderBy')(symbols, ['category', 'name', 'type']);
          return symbols;
        });
    }

    function postSymbolFields () {
      return symbolsApiSrv.postSymbolFields();
    }

    function postSymbolValues () {
      return symbolsApiSrv.postSymbolValues();
    }

    function updateSymbol (symbol, newValues) {
      if (!symbol.type || !symbol.category || !symbol.name) {
        return $q.reject();
      }

      formatSymbolXmlBody(symbol, newValues);

      return symbolsApiSrv.putSymbol(symbol);
    }

    //= ================================
    // Private interface
    //= ================================

    function formatSymbolXmlBody (symbol, newValues) {
      newValues = newValues || {};
      var jsonObj = {
        symbol: {
          _desc: newValues.desc || symbol.desc,
          _name: newValues.name || symbol.name, // Not sure we want name to be editable, but adding for completeness
          items: {
            item: angular.copy(newValues.items || symbol.items)
          }
        }
      };
      jsonObj.symbol.items.item.forEach(function (item) {
        item._name = item.name;
        delete item.name;
      });

      if (jsonObj.symbol.items.item.length === 0) {
        delete jsonObj.symbol.items.item;
      }
      // convert the json to xml and then prettify string for potential file save
      symbol.body = x2js.json2xml_str(jsonObj);
      symbol.body = symbol.body.replace(/><\/item>/g, '/>');// Convert empty elements to be self-closing.
      symbol.body = symbol.body.replace(/></g, '>\n<');
      symbol.body = symbol.body.replace(/<item /g, '      <item ');
      symbol.body = symbol.body.replace(/<items>/g, '    <items>');
      symbol.body = symbol.body.replace(/<\/items>/g, '    </items>');
      symbol.body = symbol.body.replace(/<\/symbol>/g, '  </symbol>');

      return symbol;
    }

    function parseSymbolListResponse (symbolsRes) {
      var symbolList = [];
      if (symbolsRes.data && symbolsRes.data.symbols && symbolsRes.data.symbols.symbol) {
        symbolList = angular.isArray(symbolsRes.data.symbols.symbol) ? symbolsRes.data.symbols.symbol : [symbolsRes.data.symbols.symbol];
      }
      return symbolList;
    }

    function reset () {
      categories = [];
      categorizedList = {};
      combinedList = [];
      symbols = [];
    }
  }
})();
