(function () {
  'use strict';

  angular.module('imat.fhir')
    .factory('FhirExtensionClass', FhirExtensionClass);

  FhirExtensionClass.$inject = [];

  function FhirExtensionClass () {
    var descriptor = {
      configurable: false,
      enumerable: true
    };
    var rexMetaKey = /^ext[A-Z][A-Za-z]+/;

    function FhirExtension (data, configurator) {
      var self = this;
      var extension;

      Object.defineProperty(self, 'extExtended', angular.extend({}, descriptor, { writable: true, value: false }));
      Object.defineProperty(self, 'extValueKey', angular.extend({}, descriptor, { writable: true, value: '' }));

      if (arguments.length === 0) {
        angular.extend(self, { url: '', valueString: '' });
      } else {
        configureExtension.call(self, data || {});
        if (angular.isFunction(configurator)) {
          configurator.call(self, data);
        }
      }

      // if (!Object.prototype.hasOwnProperty.call(self,'extTemplate')) {
      //   Object.defineProperty(self, 'extTemplate', { value: angular.extend({}, self) });
      // }

      if (!Object.prototype.hasOwnProperty.call(self, 'extValue')) {
        Object.defineProperty(self, 'extValue', angular.extend({}, descriptor, {
          get: function () {
            return (this.extValueKey ? this[this.extValueKey] : null);
          },
          set: function (v) {
            if (this.extValueKey) { this[this.extValueKey] = angular.copy(v); }
          }
        }));
      }

      Object.defineProperty(self, 'extExtension', angular.extend({}, descriptor, {
        writable: true,
        value: function (url) {
          if (extension) {
            for (var i = 0, ii = extension.length; i < ii; ++i) {
              if (url === extension[i].url) {
                return extension[i];
              }
            }
          }
        }
      }));

      extension = self.extension;// Preserve values when property is redefined.

      Object.defineProperty(self, 'extension', angular.extend({}, descriptor, {
        get: function () {
          return extension;
        },
        set: function (v) {
          var replaced = false;

          if (v == null) {
            return;
          }

          if (!this.extExtended) {
            throw new Error('Extension.extension and Extension.value[x] are mutually exclusive properties.');
          }

          if (angular.isArray(v)) {
            if (!v.length) {
              throw new Error('Extension.extension must include at least one extension.');
            }
            extension = v;
            extension.forEach(function (ext, idx) {
              if (!(ext instanceof FhirExtension)) {
                extension[idx] = new FhirExtension(extension[idx]);
              }
            });
            return;
          }

          if (!(v instanceof FhirExtension)) {
            v = new FhirExtension(v);
          }

          for (var i = 0, ii = extension.length; i < ii; ++i) {
            if (v.url === extension[i].url) {
              extension[i] = v;
              replaced = true;
              break;
            }
          }
          if (!replaced) {
            extension.push(v);
          }
        }
      }));
    }

    FhirExtension.prototype.toJSON = function () {
      var clone = angular.extend({}, this);

      Object.getOwnPropertyNames(this).forEach(function (p) {
        if (rexMetaKey.test(p)) {
          delete clone[p];
        }
      });
      return clone;
    };

    function configureExtension (data) { // eslint-disable-line complexity
      var self = this;

      angular.extend(self, data);

      var ext = self.extension;
      var hasExt = Object.prototype.hasOwnProperty.call(self, 'extension');
      var valProps = Object.keys(self).filter(function (key) { return /^value/.test(key); });
      var whitelist = [
        'extension',
        'id',
        'url'
      ];

      if (!Object.prototype.hasOwnProperty.call(self, 'url') || !angular.isString(self.url)) { throw new Error('Extension.url is a required property.'); }
      if (!hasExt && valProps.length === 0) { throw new Error('Extension.extension or Extension.value[x] is a required property.'); }
      if (!hasExt && valProps.length !== 1) { throw new Error('Extension.value[x] must have cardinality of 1.'); }
      if (!hasExt && valProps.length === 1) { self.extValueKey = valProps[0]; }

      if (hasExt) {
        if (!angular.isArray(ext)) {
          ext = self.extension = [self.extension];
        }

        if (ext.length) {
          valProps.forEach(function (prop) {
            delete self[prop];
          });
          ext.forEach(function (e, idx) {
            self.extension[idx] = new FhirExtension(e);
          });
          self.extExtended = true;
        } else if (valProps.length === 1) {
          delete self.extension;
          self.extValueKey = valProps[0];
        } else {
          throw new Error('Extension.extension must include at least one extension.');
        }
      }

      Object.keys(self).forEach(function (key) {
        if (key !== self.extValueKey && whitelist.indexOf(key) < 0 && !rexMetaKey.test(key)) {
          delete self[key];
        }
      });
    }

    return FhirExtension;
  }
})();
