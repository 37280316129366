(function () {
  'use strict';

  angular.module('admin')
    .controller('EditSymbolDescriptionModalCtrl', EditSymbolDescriptionModalCtrl);

  EditSymbolDescriptionModalCtrl.$inject = ['$mdDialog', 'symbolDesc'];
  function EditSymbolDescriptionModalCtrl ($mdDialog, symbolDesc) {
    var vm = this;

    // Properties
    vm.description = symbolDesc;

    // Methods
    vm.cancel = cancel;
    vm.updateSymbolDescription = updateSymbolDescription;

    function cancel () {
      $mdDialog.cancel();
    }

    function updateSymbolDescription () {
      $mdDialog.hide(vm.description);
    }

    //= ================================
    // Private interface
    //= ================================
  }
})();
