(function () {
  'use strict';

  angular.module('reports')
    .controller('RunToolbarCtrl', RunToolbarCtrl);

  RunToolbarCtrl.$inject = [
    '$log', '$mdDialog', '$scope', '$transition$',
    'dateTimeSrv', 'psNotification', 'psReports', 'ReportViewer', 'searchSrv'
  ];

  function RunToolbarCtrl (
    $log, $mdDialog, $scope, $transition$,
    dateTimeSrv, psNotification, psReports, ReportViewer, searchSrv
  ) {
    var vm = this;
    var INPUT_TYPES = psReports.INPUT_TYPES;

    vm.loaded = false;
    vm.report = {};
    vm.reportId = $transition$.params().report_id || '';
    vm.reportName = $transition$.params().report_name || vm.reportId;
    vm.cancelReport = cancelReport;
    vm.showParameters = showParameters;
    vm.workspace = 'report-viewer';

    activate();

    function activate () {
      $scope.$watch(function () { return vm.loaded; }, function (curr) {
        ReportViewer.loading = !curr;
      });
      ReportViewer.raw = !!$transition$.params().raw || false;
      ReportViewer.content = false;
      if (!vm.reportId) {
        vm.loaded = true;
        psNotification.error('A report ID is required.');
        return;
      }
      ReportViewer.artifacts = [];
      psReports.load(vm.reportId, vm.workspace).then(function (report) {
        vm.report = report;
        if (vm.report.mode === 'run') {
          setupContent(vm.report.firstRunResultId);
        } else {
          vm.loaded = true;
          showParameters();
        }
      }, function () {
        vm.loaded = true;
        ReportViewer.content = 'Failed to load report: ' + vm.reportId;
        psNotification.error('Unable to load the report.');
      });
    }

    function cancelReport () {
      vm.report.cancel(vm.workspace);
    }
    function showParameters (ev) {
      $mdDialog.show({
        controller: DialogController,
        templateUrl: '/ui/reports/run/parameters.html',
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: true
      })
        .then(function (params) {
          if (typeof vm.report.run !== 'function') {
            return;
          }
          angular.forEach(params, function (param, idx) {
            if (param.inputType === INPUT_TYPES.DATE) {
              params[idx].value = dateTimeSrv.getIsoDate(param.value);
            }
          });
          loadReportData(params).then(function (result) {
            return setupContent(result.id);
          })
            .catch(function (err) {
              vm.loaded = true;
              if (err.data === null) {
                psNotification.show('Cancelled.');
              } else {
                psNotification.error('Report failed to run.');
              }
              $log.debug(err);
            });
        }).catch(function () {
          vm.loaded = true;
          // Dialog Closed
        });
    }

    function loadReportData (params) {
      vm.loaded = false;
      return vm.report.run(vm.workspace, params, true)
        .then(function () {
          return vm.report.results[vm.workspace].getPollPromise();
        });
    }

    function setupContent (resultId) {
      return loadArtifacts(resultId).then(function (artifacts) {
        vm.loaded = true;
        ReportViewer.content = false;
        ReportViewer.resultId = resultId;
        ReportViewer.artifacts = artifacts;
      })
        .catch(function (err) {
          vm.loaded = true;
          $log.debug(err);
          psNotification.error('Failed to load artifacts.');
        });
    }

    DialogController.$inject = ['$scope', '$mdDialog'];

    function DialogController ($scope, $mdDialog) { // eslint-disable-line no-shadow
      $scope.INPUT_TYPES = INPUT_TYPES;
      $scope.cancel = cancel;
      $scope.getLimits = getLimits;
      $scope.getSelectedText = getSelectedText;
      $scope.hide = hide;
      $scope.params = {};
      $scope.formParams = [];
      $scope.reportName = vm.reportName;
      $scope.run = run;

      activate();

      function activate () { // eslint-disable-line no-shadow
        if (!vm.report || typeof vm.report.getParameters !== 'function') {
          return false;
        }
        var lastParams = vm.report.getLastParameters(vm.workspace, true);
        var defaultParams = vm.report.getParameterDefaults(true);
        var parameters = angular.extend({}, defaultParams, lastParams);
        $scope.params = parameters;

        angular.forEach(parameters, function (param) {
          if (param.inputType === INPUT_TYPES.DATE) {
            param.min = dateTimeSrv.getIsoDate(param.min);
            param.max = dateTimeSrv.getIsoDate(param.max);
          }
          $scope.formParams.push(param);
        });
      }

      function cancel () {
        $mdDialog.cancel();
      }

      function getLimits (formParams) {
        return [
          Math.ceil(formParams.length / 2),
          -Math.floor(formParams.length / 2)
        ];
      }

      function getSelectedText (selection) {
        var text = selection.display_name;
        if (!Array.isArray(selection.value)) {
          return text;
        }
        if (selection.value.length === 1) {
          text = selection.value[0];
        } else if (selection.value.length > 1) {
          text = '"' + selection.value.join('", "') + '"';
        }
        return text;
      }

      function hide () {
        $mdDialog.hide();
      }

      function run () {
        $mdDialog.hide(angular.copy($scope.params));
      }
    }

    function loadArtifacts (resultId) {
      var blacklist = [
        'summary.json',
        'scriptexit.json',
        'script.py',
        'Usage-Logs',
        'stderr.log'
      ];

      return searchSrv.getResultOutput(resultId)
        .then(function (artifacts) {
          return artifacts.filter(function (artifact) {
            return blacklist.indexOf(artifact) === -1;
          });
        });
    }
  }
})();
