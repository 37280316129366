(function () {
  'use strict';

  angular.module('imat.services')
    .factory('sidenavSrv', sidenavSrv);

  sidenavSrv.$inject = ['$state', 'Preferences', 'subscriptionsSrv'];

  function sidenavSrv ($state, Preferences, subscriptionsSrv) {
    var service = {
      collapsed: false,
      getCollapsed: getCollapsed,
      getNavLinks: getNavLinks,
      toggle: toggle,
      checkIfChildState: checkIfChildState
    };

    var navLinks = {
      account: [
        { label: 'Account Home', icon: 'account_circle', state: 'app.account.default' },
        { label: 'Personal Info', icon: 'perm_contact_calendar', state: 'app.account.info' },
        { label: 'Password & Security', icon: 'security', state: 'app.account.security', childStates: true },
        { label: 'App Preferences', icon: 'settings', state: 'app.account.preferences' }
      ],
      admin: [
        { label: 'Admin Home', icon: 'settings', state: 'app.admin.default' },
        { label: 'Data Management', icon: 'storage', state: 'app.admin.data-management', childStates: true },
        { label: 'Uploader', icon: 'cloud_upload', state: 'app.admin.uploader' },
        { label: 'Users', icon: 'person', state: 'app.admin.users' },
        { label: 'Groups', icon: 'group', state: 'app.admin.groups' },
        { label: 'Dashboards', icon: 'apps', state: 'app.admin.dashboards', childStates: true },
        { label: 'Security Collections', icon: 'lock', state: 'app.admin.collections' },
        { label: 'Set Definitions', icon: 'ps_sets', state: 'app.admin.sets' },
        { label: 'Symbols', icon: 'code', state: 'app.admin.symbols' },
        { label: 'Sharing', icon: 'share', state: 'app.admin.sharing' },
        { label: 'Report Containers', icon: 'select_all', state: 'app.admin.report-containers' },
        { label: 'Access Tokens', icon: 'vpn_key', state: 'app.admin.access-tokens' },
        { label: 'Configuration', icon: 'build', state: 'app.admin.configuration' },
        { label: 'NLP Dictionary Editor', icon: 'list_alt', state: 'app.admin.ctakes' }
      ],
      reports: [
        { label: 'Reports', icon: 'list', state: 'app.reports.list', roles: ['search', 'system'] },
        { label: 'Editor', icon: 'build', state: 'app.reports.editor', role: ['search', 'system'] },
        { label: 'Results', icon: 'insert_chart_outlined', state: 'app.reports.results', role: ['search_by_id', 'search', 'system'] }
      ],
      mpi: [
        { label: 'Persona Search', icon: 'ps_persona_search', state: 'app.mpi.query.search', roles: [] },
        { label: 'Limbo Search', icon: 'ps_limbo_search', state: 'app.mpi.limboQuery.limboSearch', roles: [] },
        { label: 'PI Link Editor', icon: 'link', state: 'app.mpi.pi-link-editor', roles: [] }
      ]
    };

    function getCollapsed () {
      service.collapsed = Preferences.getPreferences().sidenavcollapsed;
      return service.collapsed;
    }

    function getNavLinks (appKey) {
      return navLinks[appKey];
    }

    function toggle (state) {
      service.collapsed = state || !service.collapsed;
      Preferences.setPreference('sidenavcollapsed', service.collapsed);
    }

    function checkIfChildState (state) {
      return $state.includes(state);
    }

    subscriptionsSrv.loadConfig()
      .then(function () {
        if (subscriptionsSrv.isEnabled() && subscriptionsSrv.hasAccess()) {
          navLinks.account.push({ label: 'Subscription Alerts', icon: 'notifications', state: 'app.account.subscriptions' });
        }
      });

    return service;
  }
})();
