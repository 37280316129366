(function () {
  'use strict';

  angular
    .module('vhr')
    .controller('GenericDetailsCtrl', GenericDetailsCtrl);

  GenericDetailsCtrl.$inject = [
    'VHR_REPORT', 'uiGridExporterConstants',
    '$filter', '$log', '$q', '$scope', '$state', '$timeout', '$window', 'uiGridExporterService',
    'psNotification', 'psReports', 'vhrApp', 'vhrConfigSrv', 'vhrGridSrv', 'vhrPatientSrv', 'vhrUserSrv'
  ];

  function GenericDetailsCtrl (
    VHR_REPORT, uiGridExporterConstants,
    $filter, $log, $q, $scope, $state, $timeout, $window, uiGridExporterService,
    psNotification, psReports, vhrApp, vhrConfigSrv, vhrGridSrv, vhrPatientSrv, vhrUserSrv
  ) {
    var vm = this;

    // Properties
    vm.config = vhrConfigSrv.getPageByState($state.current.name);
    vm.gridApi = {};
    vm.gridConfig = {};
    vm.gridExport = {};
    vm.gridRouting = {};
    vm.grids = [];
    vm.loaded = { report: false, reportData: { basic: false, advanced: false, gaps: false }, printView: false };
    vm.printSource = vhrConfigSrv.getPrintSource();// Used in the print template.
    vm.printTemplateUrl = '/ui/vhr/templates/generic/print/print-patient-details.html';
    vm.report = {};
    vm.reportParams = {};
    vm.reportData = { basic: [], advanced: [], gaps: [] };
    vm.user = vhrUserSrv;// Used in the print template.
    vm.workspace = vm.config.stateName;
    vm.iconMap = {
      home: { icon: 'home', tooltip: 'Home' },
      mobile: { icon: 'phone_iphone', tooltip: 'Mobile' },
      work: { icon: 'work', tooltip: 'Work' },
      old: { icon: 'info', tooltip: 'Old Number' },
      temp: { icon: 'timelapse', tooltip: 'Temporary Number' }
    };

    // Methods
    vm.print = print;

    activate();

    // Implementation

    function activate () {
      $scope.$on('vhrRefreshed', function () { refresh(); });
      vhrApp.enableFilter(false);
      vhrApp.enableModes(false);
      vhrApp.enableRefresh(false);

      setGridConfig();
      vm.generating = true;

      loadReport()
        .then(function () {
          return loadReportData(angular.copy(vm.reportParams));
        })
        .catch(function (reason) {
          reason = reason || 'Failed to load the report.';
          psNotification.error(reason);
        })
        .finally(function () {
          vm.generating = false;
          ready();
        });
    }

    function formatAdvancedData (response) {
      var data = response;

      data.forEach(function (group) {
        angular.forEach(group, function (records, groupName) {
          var columns;
          var columnDefs = [];
          var fields = [];
          var groupLabel = $filter('imatLabelize')(groupName);
          var gridType = groupName.toUpperCase();// Ignore config/report case mismatches for record types.

          if (records.length) {
            if (Object.prototype.hasOwnProperty.call(vm.gridConfig, gridType)) {
              vm.gridConfig[gridType].data = records;
              vm.gridConfig[gridType].vhr = { label: groupLabel, name: gridType };
            } else {
              // Define columns/columnDefs for groups that
              // have not been set up in the app's config.
              // Use the first record as the template.
              columns = Object.keys(records[0]);
              columns.forEach(function (col, idx) {
                var dot = col.lastIndexOf('.');

                fields[idx] = (dot === -1) ? false : col.substring(dot + 1);
                columnDefs[idx] = { field: (fields[idx] || col), displayName: $filter('imatLabelize')(fields[idx] || col) };
              });
              records.forEach(function (record) {
                columns.forEach(function (col, cIdx) {
                  if (fields[cIdx]) {
                    record[fields[cIdx]] = record[col];
                    delete record[col];
                  }
                });
              });
              vm.reportData.advanced.push({
                columnDefs: columnDefs,
                data: records,
                enableColumnMenus: false,
                vhr: { label: groupLabel, name: gridType }
              });
            }
          }
        });
      });
      // Preprend, in order, the configured grids that have rows.
      vm.grids.reverse().forEach(function (g) {
        if (vm.gridConfig[g].data.length) {
          vm.reportData.advanced.unshift(vm.gridConfig[g]);
        }
      });
      vm.grids.reverse();// Restore it, just for fun.
    }

    function formatBasicData (response) {
      var reDate = /^date_|_date_|_date$/;
      var reDatetime = /^(?:date|datetime|time)$|^(?:date|datetime|time)_|_(?:date|datetime|time)_|_(?:date|datetime|time)$/;
      var reFmrnKey = /FMRNs$/;
      var reHasUse = /^(phone|address)$/;
      // var rePhone = /^phone$/;
      // var reAddress = /^address$/;

      response.forEach(function (obj) {
        var key = Object.keys(obj).pop();
        var value = obj[key];
        var label = $filter('imatLabelize')(key);
        var nested = (value instanceof Array);
        var format = null;

        if (reFmrnKey.test(label)) {
          label = label.replace(reFmrnKey, 'MRNs');
        }

        if (reDate.test(key)) {
          format = 'date';
          value = vhrConfigSrv.getIsoDate(value);
        } else if (reDatetime.test(key)) {
          format = 'datetime';
          value = vhrConfigSrv.getIsoDatetime(value);
        }

        if (nested && reHasUse.test(key)) {
          format = key.match(reHasUse)[0];
          value.forEach(function (object) {
            var use = object.use.toLowerCase();
            object.icon = vm.iconMap[use] ? vm.iconMap[use].icon : 'help_outline';
            object.tooltip = vm.iconMap[use] ? vm.iconMap[use].tooltip : 'Unknown';
          });
        }

        if (nested && !reHasUse.test(key)) {
          value = [];
          obj[key].forEach(function (nobj) {
            var keys = Object.keys(nobj).sort().reverse();
            var values = [];

            keys.forEach(function (propName) {
              var prop = nobj[propName];

              values.push(((propName === 'facility') ? '[' + prop + ']' : prop));
            });
            value.push(values.join(' '));
          });
        }
        vm.reportData.basic.push({ label: label, value: value, nested: nested, format: format });
      });
    }

    function formatGapsData (response) {
      // ["gaps_in_care":{"screenings":[],...}]
      vm.reportData.gaps = response.pop().gaps_in_care.screenings;
    }

    function loadReport () {
      return psReports.load(vm.config.reportId, VHR_REPORT.CALLER, { initParams: vhrConfigSrv.getReportParameters(vm.config.reportId) })
        .then(function (report) {
          vm.report = report;
          vm.reportParams = report.getLastParameters(vm.workspace, true) || report.getParameterDefaults(true);
        })
        .catch(function (reason) {
          $log.debug(reason);
          return $q.reject('Failed to prepare the report.');
        })
        .finally(function () {
          vhrApp.enableRefresh((vm.report && !!vm.report.id));
        });
    }

    function loadReportData (params, force) {
      if (vm.config.parameters) {
        angular.forEach(vm.config.parameters, function (param, paramName) {
          if (typeof params[paramName] === 'undefined') {
            $log.debug('The page configuration is trying to set an unexpected parameter: ' + paramName);
            return;// continue
          }
          params[paramName] = param;
        });
      }

      params.patient_id = vhrPatientSrv.raw.mpid;

      return vm.report.run(vm.workspace, params, force)
        .then(function () {
          return vm.report.results[vm.workspace].getPollPromise();
        })
        .then(function () {
          var promise; var q = [];

          // Basic
          promise = vm.report.getArtifact(vm.workspace, 'basic_data.json')
            .then(formatBasicData)
            .catch(function (reason) {
              $log.debug(reason);
              return $q.reject('Failed to load the patient\'s basic data.');
            })
            .finally(function () {
              vm.loaded.reportData.basic = true;
            });
          q.push(promise);

          // Advanced
          promise = vm.report.getArtifact(vm.workspace, 'advanced_data.json')
            .then(normalizeVhrReportData)
            .then(formatAdvancedData)
            .catch(function (reason) {
              $log.debug(reason);
              return $q.reject('Failed to load the patient\'s advanced data.');
            })
            .finally(function () {
              vm.loaded.reportData.advanced = true;
            });
          q.push(promise);

          // Gaps in care
          promise = vm.report.getArtifact(vm.workspace, 'gaps_in_care_data.json')
            .then(formatGapsData)
            .catch(function (reason) {
              $log.debug(reason);
              return $q.reject('Failed to load the patient\'s gaps in care data.');
            })
            .finally(function () {
              vm.loaded.reportData.gaps = true;
            });
          q.push(promise);

          return $q.all(q);
        })
        .then(function () {
          $timeout(setupPrintView);// Wait a cycle for the grid to set up.
        })
        .catch(function (reason) {
          $log.debug(reason);
          return $q.reject('Failed to load the patient data.');
        });
    }

    function normalizeVhrReportData (data) {
      var normalized = [];

      // Assume legacy/desired format.
      // [{ 'foo': [...] }, { 'bar': [...] }, ...]
      data.forEach(function (obj) {
        angular.forEach(obj, function (rows, source) {
          var packed = {};
          // Ignore config/report case mismatches for record types.
          source = source.toUpperCase();
          packed[source] = Array.isArray(rows) ? rows : [];
          normalized.push(packed);
        });
      });
      return normalized;// [{ 'SOURCEA': [recordsA] }, { 'SOURCEB': [recordsB] }, ...]
    }

    function print () {
      $window.print();
    }

    function ready () {
      vm.loaded.report = true;
      vm.loaded.reportData.basic = true;
      vm.loaded.reportData.advanced = true;
      vm.loaded.reportData.gaps = true;
    }

    function refresh () {
      vm.generating = true;
      vm.loaded.printView = false;
      vm.loaded.report = false;
      vm.loaded.reportData.basic = false;
      vm.loaded.reportData.advanced = false;
      vm.loaded.reportData.gaps = false;
      vm.reportData = { basic: [], advanced: [], gaps: [] };

      loadReportData(angular.copy(vm.reportParams), true)
        .catch(function (reason) {
          psNotification.error(reason);
        })
        .finally(function () {
          vm.generating = false;
          ready();
        });
    }

    function setGridConfig () {
      vm.config.grids2.forEach(function (grid, idx) {
        var gridDef = vhrConfigSrv.raw.grids[grid.grid] || { fieldOrder: [] };
        var gridConfig = gridDef.uiGridConfig || {};
        var gridRoute;
        var gridType = grid.source.toUpperCase();// Ignore config/report case mismatches for source.

        if (Array.isArray(vm.config.gridPages) && vm.config.gridPages.length) {
          gridRoute = vhrConfigSrv.getRouteByPage(vm.config.gridPages[idx]);
        }

        vm.gridConfig[gridType] = {};
        vm.gridRouting[gridType] = (gridRoute || { pageTitle: '', stateName: '' });
        vm.grids.push(gridType);

        gridConfig = vhrGridSrv.getGridConfig($scope, gridType, vhrGridSrv.CONFIG.RECORD_LIST, gridConfig, gridDef.fieldOrder);
        angular.extend(vm.gridConfig[gridType], gridConfig);

        vhrGridSrv.setOnRegisterApi($scope, vm.gridConfig, vm.gridApi, gridType);
      });
    }

    function setupPrintView () {
      angular.forEach(vm.gridApi, function (gridApi, gridType) {
        var rowTypes = uiGridExporterConstants.ALL;
        var colTypes = uiGridExporterConstants.ALL;
        var applyCellFilters = true;

        vm.gridExport[gridType] = {
          cols: uiGridExporterService.getColumnHeaders(gridApi.grid, colTypes),
          rows: uiGridExporterService.getData(gridApi.grid, rowTypes, colTypes, applyCellFilters)
        };
      });
      vm.loaded.printView = true;
    }
  }
})();
