(function () {
  'use strict';

  angular
    .module('vhr.services')
    .factory('vhrGridSrv', vhrSrv);

  vhrSrv.$inject = [
    'MODE', 'uiGridConstants',
    '$mdDialog',
    'vhrApp', 'vhrConfigSrv', 'vhrFootnoteSrv', 'vhrPrintSrv', 'vhrRecordSrv', 'vhrRecordCollectionSrv'
  ];

  function vhrSrv (
    MODE, uiGridConstants,
    $mdDialog,
    vhrApp, vhrConfigSrv, vhrFootnoteSrv, vhrPrintSrv, vhrRecordSrv, vhrRecordCollectionSrv
  ) {
    var service;

    service = {
      CONFIG: {
        CONCEPT: {
          columnDefs: [],
          data: [],
          enableColumnMenus: false,
          enableFullRowSelection: false,
          enableGridMenu: false,
          enableHiding: false,
          enableHorizontalScrollbar: uiGridConstants.scrollbars.NEVER,
          enableRowHeaderSelection: false,
          enableSelectAll: false,
          enableVerticalScrollbar: uiGridConstants.scrollbars.NEVER,
          exporterSuppressColumns: [], // XXX ui-grid has no default?
          exporterFieldCallback: function (grid, row, col, value) { return value; }, // XXX ui-grid has no default?
          exporterFieldFormatCallback: function (grid, row, col, value) { return value; }, // XXX ui-grid has no default?
          // flatEntityAccess: true, // XXX I think we should do this, but I'm not sure.
          infiniteScrollDown: false
        },
        HIE: {
          columnDefs: [],
          data: [],
          enableColumnMenus: false,
          enableFullRowSelection: true,
          enableGridMenu: false,
          enableHiding: false,
          enableRowHeaderSelection: false,
          enableSelectAll: false,
          enableSorting: true,
          // flatEntityAccess: true, // XXX I think we should do this, but I'm not sure.
          infiniteScrollDown: false
        },
        PATIENT_LIST: {
          columnDefs: [],
          data: [],
          enableColumnMenus: false,
          enableFullRowSelection: true,
          enableGridMenu: false,
          enableHiding: false,
          enableRowHeaderSelection: false,
          enableSelectAll: false,
          enableSorting: true,
          // flatEntityAccess: true, // XXX I think we should do this, but I'm not sure.
          infiniteScrollDown: false
        },
        RECORD_LIST: {
          columnDefs: [],
          data: [],
          enableColumnMenus: false,
          enableFullRowSelection: true,
          enableGridMenu: false,
          enableHiding: false,
          enableRowHeaderSelection: true,
          enableSelectAll: false,
          exporterSuppressColumns: ['store', 'uri'], // XXX ui-grid has no default?
          exporterFieldCallback: function (grid, row, col, value) { return value; }, // XXX ui-grid has no default?
          exporterFieldFormatCallback: function (grid, row, col, value) { return value; }, // XXX ui-grid has no default?
          // flatEntityAccess: true, // XXX I think we should do this, but I'm not sure.
          infiniteScrollDown: false
        }
      },
      getGridConfig: getGridConfig,
      handleGridRowSelectionChanged: handleGridRowSelectionChanged,
      selectRowsInPrintQueue: selectRowsInPrintQueue,
      selectRowsInRecentViews: selectRowsInRecentViews,
      setGridFootnotes: setGridFootnotes,
      setGridHeight: setGridHeight,
      setOnRegisterApi: setOnRegisterApi
    };

    return service;

    // ---------------------------------
    // Public interface
    // ---------------------------------

    function getGridConfig (scope, scopeKey, srvGridCfg, ctrlGridCfg, appCfgFields) {
      var gridConfig = { appScopeProvider: scope };

      scopeKey = 'grid-' + scopeKey;
      srvGridCfg = srvGridCfg || {};
      ctrlGridCfg = ctrlGridCfg || {};
      angular.merge(gridConfig, srvGridCfg, ctrlGridCfg);

      if (appCfgFields.length) {
        angular.forEach(appCfgFields, function (fieldName) {
          var fieldDef = vhrConfigSrv.raw.fields[fieldName];
          var colDef = vhrConfigSrv.transformCellForUiGrid(fieldName, fieldDef, scope, scopeKey);

          gridConfig.columnDefs.push(colDef);
        });
      }

      return gridConfig;
    }

    function handleGridRowSelectionChanged (row) {
      switch (vhrApp.getMode()) {
        case MODE.PRINT:
          selectRowIntoPrintQueue(row);
          break;
        case MODE.VIEW:// No break.
        default:
          selectRowIntoRecentViews(row);
          break;
      }
    }

    function selectRowsInPrintQueue (gridApi, gridCfg) {
      var coll;

      if (!gridApi.selection || !gridApi.selection) { //Adding as a reminder - If using isSelectable: false, gridApi undefined
        return;
      }

      coll = vhrRecordCollectionSrv.construct('printQueue').findAll();
      coll = angular.copy(coll);// Selecting a row mutates the collection...
      coll.forEach(function (rec) {
        var i, row;

        for (i = gridCfg.data.length - 1; i >= 0; --i) {
          row = gridCfg.data[i];
          if (row.store === rec.store && row.uri === rec.uri) {
            gridApi.selection.selectRow(row);
            break;
          }
        }
      });
    }

    function selectRowsInRecentViews (gridApi) {
      if (!gridApi.selection || !gridApi.selection) { //If using isSelectable: false, gridApi undefined
        return;
      }
      gridApi.selection.clearSelectedRows();
    }

    function setGridFootnotes (grid, gridMeta) {
      var context = 'uiGrid' + grid.id;

      gridMeta = gridMeta || {
        hasNotes: false,
        noteContext: context,
        noteCount: 0
      };

      grid.options.columnDefs.forEach(function (col) {
        if (col.configType === 'footnote') {
          gridMeta.hasNotes = true;
          grid.options.data.forEach(function (row) {
            var ref = vhrFootnoteSrv.store(context, row[col.field]);
            if (ref) {
              row[col.field] = 'See note ' + ref;
            }
          });
        }
        gridMeta.noteContext = context;
        gridMeta.noteCount = vhrFootnoteSrv.count(context);
      });

      return gridMeta;
    }

    function setGridHeight (grid) {
      var borderHeight = grid.element[0].offsetHeight - grid.element[0].clientHeight;
      var contentHeight = grid.options.rowHeight * grid.options.data.length;
      var footerHeight = grid.footerHeight;
      var headerHeight = grid.headerHeight;
      var scrollbarHeight = (typeof grid.scrollbarHeight === 'number' ? grid.scrollbarHeight : 17);// Fallback due to problems calculating scrollbar size on Firefox.
      var total = borderHeight + contentHeight + footerHeight + headerHeight + scrollbarHeight;

      grid.element.css({ height: 1 + total + 'px' });
    }

    function setOnRegisterApi (scope, ctrlCfgColl, ctrlApiColl, collKey) {
      ctrlCfgColl[collKey].onRegisterApi = function (gridApi) {
        if (gridApi.selection) {
          gridApi.selection.on.rowSelectionChanged(scope, handleGridRowSelectionChanged);
        }
        ctrlApiColl[collKey] = gridApi;
      };
    }

    // ---------------------------------
    // Private interface
    // ---------------------------------

    function selectRowIntoPrintQueue (row) {
      var pqColl = vhrRecordCollectionSrv.construct('printQueue');
      var record = vhrRecordSrv.construct(row.entity.store, row.entity.record_type, row.entity);

      if (row.isSelected) {
        pqColl.insert(record);
      } else {
        pqColl.remove(record);
      }
    }

    function selectRowIntoRecentViews (row) {
      var cfg;
      var gridApi = row.grid.api;
      var record = vhrRecordSrv.construct(row.entity.store, row.entity.record_type, row.entity);

      vhrRecordCollectionSrv.stash(record);
      gridApi.selection.clearSelectedRows();

      cfg = record.getModalConfig();
      cfg.onComplete = vhrPrintSrv.hideContent;
      cfg.onRemoving = vhrPrintSrv.showContent;

      $mdDialog.show(cfg)
        .then(function () {
        // Interaction
        }, function () {
        // Cancel
        })
        .finally(function () {
          vhrRecordCollectionSrv.construct('recentViews').resequence(record);
        });
    }
  }
})();
