(function () {
  'use strict';

  angular
    .module('vhr')
    .controller('HieDocumentCtrl', HieDocumentCtrl);

  HieDocumentCtrl.$inject = [
    '$anchorScroll', '$filter', '$location', '$log', '$q', '$scope', '$state', '$timeout', '$transition$', '$window',
    'psNotification', 'vhrConfigSrv', 'vhrHieApi', 'vhrPersistence', 'vhrPrintSrv', 'vhrUserSrv', 'vhrXsl'
  ];

  function HieDocumentCtrl ( // eslint-disable-line complexity
    $anchorScroll, $filter, $location, $log, $q, $scope, $state, $timeout, $transition$, $window,
    psNotification, vhrConfigSrv, vhrHieApi, vhrPersistence, vhrPrintSrv, vhrUserSrv, vhrXsl
  ) {
    var vm = this;

    // If xslt fails, will output this string
    var NO_XML = 'No XML';

    // Messages
    var PAGE_CONTENT_LOAD_ERROR = 'Unable to load the document.';
    var PURPOSE_OF_USE_ERROR = 'Unable to retrieve the purpose of use.';
    var XML_NOT_AVAILABLE = 'Unable to retrieve the document.';

    // Properties
    vm.communityId = $transition$.params().community || vhrPersistence.getData('hieDocumentCommunityId') || null;
    vm.documentsLookupOid = $transition$.params().documentsLookupOid || vhrPersistence.getData('hieDocumentsLookupOid') || null;
    vm.documentData = null;
    vm.documentXsl = null;
    vm.documentId = $transition$.params().document || vhrPersistence.getData('hieDocumentDocumentId') || null;
    vm.loaded = { api: false };
    vm.markup = null;
    vm.printSource = vhrConfigSrv.getPrintSource();
    vm.repositoryId = $transition$.params().repositoryId || vhrPersistence.getData('hieRepositoryId') || null;
    vm.targetOid = $transition$.params().targetOid || vhrPersistence.getData('hieDocumentTargetOid') || null;
    vm.timestamp = null;
    vm.user = vhrUserSrv;

    // Methods
    vm.back = back;
    vm.print = print;
    vm.scrollToLocation = scrollToLocation;

    activate();

    // Implementation

    function activate () {
      vm.config = vhrConfigSrv.getPageByState($state.current.name);

      // Catch refresh event thrown by hie.controller.js
      $scope.$on('vhrHieRefreshed', refresh);

      vhrHieApi.getPurpose()
        .then(function () {
          loadPageContent();

          vhrPersistence.setData('hieDocumentsLookupOid', vm.documentsLookupOid);
          vhrPersistence.setData('hieDocumentCommunityId', vm.communityId);
          vhrPersistence.setData('hieDocumentDocumentId', vm.documentId);
          vhrPersistence.setData('hieDocumentTargetOid', vm.targetOid);
          vhrPersistence.setData('hieRepositoryId', vm.repositoryId);
          vhrPersistence.setData('hieState', $state.current.name);

          vm.timestamp = new Date();
        })
        .catch(function () {
          psNotification.error(PURPOSE_OF_USE_ERROR);
        });

      // Capture Ctrl+P and route through vhrPrintSrv.print().
      angular.element($window.document).on('keyup keydown', function (evt) {
        if (evt.ctrlKey && evt.keyCode === 80) {
          evt.preventDefault();
          print();
          return false;
        }
      });
    }

    // ---------------------------------
    // Public interface
    // ---------------------------------

    function back () {
      $state.go('app.vhr.patient.hie.hie-documents', { patient: vhrPersistence.getData('hieDocumentsPatientId'), community: vm.documentsLookupOid }, { location: 'replace' });
      return false;
    }

    function print () {
      vhrPrintSrv.print();
    }

    // ---------------------------------
    // Private interface
    // ---------------------------------

    function loadDocument (targetOid, communityId, repositoryId, documentId, force) {
      return vhrHieApi.getDocument(targetOid, communityId, repositoryId, documentId, force)
        .then(function (response) {
          // Munge response...
          return response;
        })
        .catch(function (reason) {
          $log.debug(reason);
          return $q.reject();
        });
    }

    function loadPageContent (force) {
      vm.loaded.api = false;

      loadXslDocument()
        .then(function (response) {
          vm.documentXsl = response;

          return response;
        })
        .then(function () {
          return loadDocument(vm.targetOid, vm.communityId, vm.repositoryId, vm.documentId, force);
        })
        .then(function (response) {
          vm.documentData = response;
          // vm.documentData = '<?xml version="1.0" encoding="UTF-8"?><root><name>Testing</name></root>';
          return response;
        })
        .then(function () {
          var markupString = _fixEntities($filter('xslt')(vm.documentData, vm.documentXsl));
          if (markupString === NO_XML) {
            vm.markup = XML_NOT_AVAILABLE;
            return $q.reject();
          }
          vm.markup = markupString;
          return vm.markup;
        })
        .catch(function (reason) {
          reason = reason || PAGE_CONTENT_LOAD_ERROR;
          psNotification.error(reason);
        })
        .finally(function () {
          vm.loaded.api = true;
        });
    }

    function loadXslDocument (xslPath) {
      return vhrXsl.getXslFile(xslPath)
        .then(function (response) {
          return response;
        })
        .catch(function (reason) {
          $log.debug(reason);
          return $q.reject();
        });
    }

    function refresh () {
      vm.documentXsl = '';
      loadPageContent(true);
    }

    function scrollToLocation (location) {
      $anchorScroll(location);
    }

    function _fixEntities (htmlString) {
      var cleanUp = htmlString.replace(/&amp;amp;/g, '&');
      return cleanUp.replace(/&amp;/g, '&');
    }
  }
})();
