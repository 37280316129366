(function () {
  'use strict';

  angular.module('vhr')
    .controller('LayoutCtrl', LayoutCtrl);

  LayoutCtrl.$inject = [
    '$scope', '$state',
    'vhrApp', 'vhrConfigSrv', 'vhrHieApi', 'vhrPatientSrv', 'vhrUserSrv', '$timeout'
  ];

  function LayoutCtrl (
    $scope, $state,
    vhrApp, vhrConfigSrv, vhrHieApi, vhrPatientSrv, vhrUserSrv, $timeout
  ) {
    var vm = this;

    // Properties
    vm.links = [];
    vm.loaded = { nav: false };
    vm.navItems = [];
    vm.patient = vhrPatientSrv;
    vm.active = $state.current.name;

    // Methods
    vm.isAttested = vhrPatientSrv.isAttested;
    vm.user = {};
    vm.hieAutoLookupEnabled = false;
    vm.recordCount = vhrHieApi.hieResultsCount;
    vm.toggleSidenav = toggleSidenav;

    activate();

    // Implementation

    function activate () {
      vm.navItems = vhrConfigSrv.getRoutes();
      vm.loaded.nav = true;
      vm.userDisplayName = vhrUserSrv.getDisplayName() || 'User';

      var autoLookup = vhrConfigSrv.getHieAutoLookup(); // Autolookup set to true in vhr-app.json.
      var hieAccess = angular.isDefined(vhrConfigSrv.getRouteByPage('hie'));

      vm.hieAutoLookupEnabled = autoLookup && hieAccess;

      if (vm.hieAutoLookupEnabled) {
        $scope.$watch(function () { return vhrHieApi.hieResultsCount; }, function (currCount) {
          vm.recordCount = currCount;
        });
      }

      vm.sidenavCollapsed = vhrApp.getCollapsed();

      vhrApp.enableFilter(false);
      vhrApp.enableRefresh(false);
    }

    function toggleSidenav () {
      var sidenav = angular.element('md-sidenav');
      var action = vm.sidenavCollapsed ? 'expanding' : 'collapsing';

      sidenav.addClass(action);

      $timeout(function () {
        sidenav.removeClass(action);
      }, 200);

      vm.sidenavCollapsed = !vm.sidenavCollapsed;
      vhrApp.toggleSidenav();
    }
  }
})();
