(function () {
  'use strict';

  angular.module('account')
    .controller('AccountInfoCtrl', AccountInfoCtrl);

  AccountInfoCtrl.$inject = ['$scope', '$transition$', 'imatConfig', 'psNotification', 'subscriptionsSrv', 'userSession'];

  function AccountInfoCtrl ($scope, $transition$, imatConfig, psNotification, subscriptionsSrv, userSession) {
    var vm = this;
    vm.copy = {};
    vm.user = {};

    vm.reset = reset;
    vm.submit = submit;

    activate();

    function activate () {
      vm.user = userSession.getUserData();
      reset();
    }

    function reset () {
      vm.copy = angular.copy(vm.user);
    }

    function submit () {
      userSession.changeName(vm.copy.name)
        .then(function () {
          reset();
          psNotification.success('Name updated.');
        }, function () {
          psNotification.error('Unable to save your account, please try again later.');
        });
    }
  }
})();
