/* eslint-disable no-multi-str */
(function () {
  'use strict';

  angular.module('imat.shared.test')
    .factory('imatStoreFixtures', imatStoreFixtures);

  imatStoreFixtures.$inject = [];

  function imatStoreFixtures () {
    var service;

    service = {
      getStores: getStores
    };

    return service;

    function getStores () {
      return [
        {
          name: 'DefaultStore',
          number: 0,
          record_count: 104,
          delete_count: 0,
          bytes_indexed: 1096870,
          uncommitted_count: 0,
          exclude: '0',
          state: 'read write'
        },
        {
          name: 'MedCodes',
          number: 63,
          record_count: 2837429,
          delete_count: 0,
          bytes_indexed: 420200646,
          uncommitted_count: 0,
          exclude: '1',
          state: 'read write'
        }
      ];
      // return x2js.xml_str2json(
      //   '\
      //     <response xmlns="http://www.perfectsearchcorp.com/sdk/xmldefs-v1">\
      //       <feeds>\
      //         <feed>\
      //           <name type="str">DefaultStore</name>\
      //           <number type="int">0</number>\
      //           <record_count type="int">104</record_count>\
      //           <delete_count type="int">0</delete_count>\
      //           <bytes_indexed type="int">1096870</bytes_indexed>\
      //           <uncommitted_count type="int">0</uncommitted_count>\
      //           <exclude type="bool">0</exclude>\
      //           <state type="str">read write</state>\
      //         </feed>\
      //         <feed>\
      //           <name type="str">MedCodes</name>\
      //           <number type="int">63</number>\
      //           <record_count type="int">2837429</record_count>\
      //           <delete_count type="int">0</delete_count>\
      //           <bytes_indexed type="int">420200646</bytes_indexed>\
      //           <uncommitted_count type="int">0</uncommitted_count>\
      //           <exclude type="bool">1</exclude>\
      //           <state type="str">read write</state>\
      //         </feed>\
      //       </feeds>\
      //     </response>\
      //   '
      // );
    }
  }
})();
