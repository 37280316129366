(function () {
  'use strict';

  angular.module('mpiApp.services', [
    'imat.constants', // STORES
    'imat.fhir', // FHIR_EXTENSION_URLS, fhirExtensionSrv, fhirIdentifierSrv, fhirResourceSrv, fhirValueSetSrv
    'imat.services', // imatConfig, imatFieldsSrv, imatStoresSrv, searchSrv
    'mpiApp.constants' // MPI_CLIENT_NAME, MPI_FHIR
  ]);
})();
