(function () {
  'use strict';

  angular
    .module('mpiApp.services')
    .factory('mpiConfigSrv', mpiConfigSrv);

  mpiConfigSrv.$inject = ['$parse', '$q', 'fhirIdentifierSrv', 'imatConfig'];

  function mpiConfigSrv ($parse, $q, fhirIdentifierSrv, imatConfig) {
    var service;
    var raw = false;
    var personaSearchConfig = {};
    var limboSearchConfig = {};

    service = {
      loaded: false,
      systemId: '',
      getLimboConfig: getLimboConfig,
      getPersonaConfig: getPersonaConfig,
      getPersonaView: getPersonaView,
      getRawRecordNode: getRawRecordNode,
      getRaw: getRaw,
      load: load
    };

    return service;

    //= ================================
    // Public interface
    //= ================================

    function getPersonaView () {
      return raw.personaView;
    }

    function getRawRecordNode () {
      return raw.rawRecordNode;
    }

    function getPersonaConfig () {
      return personaSearchConfig;
    }

    function getLimboConfig () {
      return limboSearchConfig;
    }

    function getRaw (prop) {
      return $parse(prop)(raw);
    }

    function load () {
      return $q.all([
        _loadConfig(),
        _loadSystemId(),
        _loadPersonaConfig(),
        _loadLimboConfig()
      ]);
    }

    //= ================================
    // Private interface
    //= ================================

    function _loadConfig () {
      // Load imatconfig if it isn't already loaded
      return imatConfig.load()
        .then(function () {
          raw = imatConfig.get('apps.mpi') || {};
        });
    }

    function _loadPersonaConfig () {
      return imatConfig.loadConfigFile('persona-search.json')
        .then(function (res) {
          personaSearchConfig = res || {};
        });
    }

    function _loadLimboConfig () {
      return imatConfig.loadConfigFile('limbo-search.json')
        .then(function (res) {
          limboSearchConfig = res || {};
        });
    }

    function _loadSystemId () {
      return fhirIdentifierSrv.load()
        .then(function () {
          service.systemId = fhirIdentifierSrv.SYSTEMS.IMAT_MPI;
        });
    }
  }
})();
