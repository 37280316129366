(function () {
  'use strict';

  angular
    .module('admin')
    .controller('ContainerCtrl', ContainerCtrl);

  ContainerCtrl.$inject = ['STATUSES', '$mdDialog', '$mdSidenav', '$scope', '$state', '$timeout', '$transition$', 'ContainersSrv', 'psNotification', 'searchSrv'];

  function ContainerCtrl (STATUSES, $mdDialog, $mdSidenav, $scope, $state, $timeout, $transition$, ContainersSrv, psNotification, searchSrv) {
    var ctrl = this;
    var ctx = this.constructor.name;

    ctrl.clearReport = false;
    ctrl.loadDetails = false;
    ctrl.reloadReports = false;
    ctrl.reports = [];
    ctrl.reportStatus = STATUSES.RUNNING;
    ctrl.searchReportText = '';
    ctrl.selected = {};
    ctrl.STATUSES = STATUSES;
    ctrl.statusFilters = [
      { name: 'All reports', value: '' },
      { name: 'Running reports', value: STATUSES.RUNNING },
      { name: 'Queued reports', value: STATUSES.QUEUED },
      { name: 'Aborted reports', value: STATUSES.ABORTED },
      { name: 'Failed reports', value: STATUSES.FAILED },
      { name: 'Unknown status', value: STATUSES.UNKNOWN },
      { name: 'Completed reports', value: STATUSES.COMPLETE }
    ];

    ctrl.clearSelection = clearSelection;
    ctrl.confirmDeleteReport = confirmDeleteReport;
    ctrl.deleteReport = deleteReport;
    ctrl.filterReports = filterReports;
    ctrl.getRunTime = getRunTime;
    ctrl.loadReports = loadReports;
    ctrl.resetFilters = resetFilters;
    ctrl.selectReport = selectReport;
    ctrl.viewReportSource = viewReportSource;

    activate();

    function activate () {
      $state.$current.data.pageTitle = 'Reports for ' + $transition$.params().id;
      ctrl.id = $transition$.params().id;
      loadReports();
    }

    function clearSelection () {
      searchSrv.clearSelected(ctx);
      ctrl.selected = null;
      $mdSidenav('reportDetails').close();
    }

    function confirmDeleteReport (ev, report) {
      var confirm = $mdDialog.confirm()
        .title('Delete Report Results?')
        .textContent('Running reports will be terminated and results will be removed.')
        .ariaLabel('Confirm Delete')
        .targetEvent(ev)
        .ok('Delete')
        .cancel('Cancel');

      $mdDialog.show(confirm)
        .then(function () {
          deleteReport(report);
        })
        .catch(function () {
          return false;
        });
    }

    function deleteReport (report) {
      ctrl.reloadReports = true;
      searchSrv.cancel(report.id)
        .then(function () {
          clearSelection();
        })
        .finally(function () {
          ctrl.reloadReports = false;
        });
    }

    function filterReports (report) {
      return (ctrl.reportStatus === '' || (report && report.status === ctrl.reportStatus));
    }

    function getRunTime (start) {
      var diff = moment().diff(moment(start), 'milliseconds');
      var duration = moment.duration(diff);
      return (duration.hours() ? duration.hours() + ' hr ' : '') + duration.minutes() + ' min';
    }

    function loadReports () {
      ctrl.reports = [];
      ctrl.reloadReports = true;
      searchSrv.getSearches()
        .then(function (data) {
          ctrl.reports = data.searches.filter(function (search) {
            return search.type === 'python' && search.owner === ctrl.id;
          });

          searchSrv.bulkPollStatus(ctrl.reports.map(function (search) { return search.id; }), { admin: true });
        })
        .catch(function () {
          psNotification.error('There was a problem loading the report list.');
        })
        .finally(function () {
          ctrl.reloadReports = false;
        });
    }

    function resetFilters () {
      ctrl.searchReportText = '';
      ctrl.reportStatus = STATUSES.RUNNING;
    }

    function selectReport (report) {
      ctrl.loadDetails = true;
      searchSrv.setSelected(ctx, report.id);
      searchSrv.getSelectedItem(ctx, true)
        .then(function (searchDetails) {
          ctrl.selected = searchDetails;
          $mdSidenav('reportDetails').open();
        })
        .finally(function () {
          ctrl.loadDetails = false;
        });
    }

    function viewReportSource (report) {
      $mdDialog.show({
        controller: 'ReportSourceModalCtrl',
        controllerAs: 'vm',
        locals: { selectedReport: report },
        templateUrl: '/ui/admin/report-containers/container/report-source-modal.html'
      });
    }

    $scope.$on('$destroy', function () {
      searchSrv.stopPolling();
    });
  }
})();
