(function () {
  'use strict';

  angular.module('mpiApp.piLinkEditor')
    .controller('piLinkEditorCtrl', piLinkEditorCtrl);

  piLinkEditorCtrl.$inject = ['$interval', '$mdDialog', '$q', '$scope', '$timeout', '$transition$', 'mpiApiSrv', 'psNotification', 'mpiPersona'];

  function piLinkEditorCtrl ($interval, $mdDialog, $q, $scope, $timeout, $transition$, mpiApiSrv, psNotification, mpiPersona) {
    var vm = this; // eslint-disable-line no-invalid-this
    var UDPATE_PENDING = 'Pending';
    var mpidList = [];
    var newMpidCounter = 1;
    var listRect;
    var dragging = !!window.MSInputMethodContext && !!document.documentMode ? 'mousemove' : 'drag';

    // Properties
    vm.columns = {
      'Clinical Records': false,
      'Last Updated': false,
      'Facility Name': true,
      MRN: true,
      'Full Name': true,
      'Date of Birth': true,
      Gender: false,
      'Multiple Birth': false,
      'Multiple Birth Order': false,
      SSN: false,
      Address: false,
      Phone: false,
      Behavior: false
    };
    vm.loading = false;
    vm.mpid_to_load = '';
    vm.original = {};
    vm.personas = {};
    vm.preventCommit = true;
    vm.toggleAllState = false;
    vm.dragging = null;
    vm.comparisonMode = false;

    // Methods
    vm.countHidden = countHidden;
    vm.createNewMPID = createNewMPID;
    vm.loadFromInput = loadFromInput;
    vm.moveRecord = moveRecord;
    vm.mpidToClassName = mpidToClassName;
    vm.parseTimestamp = parseTimestamp;
    vm.reloadMPIDs = reloadMPIDs;
    vm.removeMpids = removeMpids;
    vm.scrollToElement = scrollToElement;
    vm.getSelectedStatusIcon = getSelectedStatusIcon;
    vm.showCommitDialog = showCommitDialog;
    vm.toggleAll = toggleAll;
    vm.togglePIList = togglePIList;
    vm.toggleSelect = toggleSelect;
    vm.toggleSelectAll = toggleSelectAll;
    vm.dragRecordStart = dragRecordStart;
    vm.dragRecordEnd = dragRecordEnd;

    activate();

    //= =========================
    // Public Interface
    //= =========================

    function countHidden (persona) {
      var hidden = persona.pi_entries.filter(function (pi) {
        return !pi.selected;
      });

      return hidden.length;
    }

    function createNewMPID () {
      var mpid = 'New-' + newMpidCounter;

      vm.personas[mpid] = {
        mpid: mpid,
        pi_entries: [],
        last_updated: UDPATE_PENDING
      };

      vm.original[mpid] = angular.copy(vm.personas[mpid]);

      vm.personas = sortObject(vm.personas);
      vm.original = sortObject(vm.original);

      newMpidCounter++;

      $timeout(function () { scrollToElement('.mpid-list', '.' + mpidToClassName(mpid), 1); }, 0);
    }

    // eslint-disable-next-line complexity
    function getSelectedStatusIcon (persona) {
      var ret; var statuses = [];

      if (!persona) {
        // If persona is not specified it is assumed that we are determining the status of all persona checkbox
        statuses = [];

        for (var record in vm.personas) {
          if (Object.prototype.hasOwnProperty.call(vm.personas, record) && vm.personas[record].selectedStatus) {
            statuses.push(vm.personas[record].selectedStatus);
          }
        }

        if (!statuses.length) {
          return 'check_box_outline_blank';
        }

        if (statuses.length !== Object.keys(vm.personas).length) {
          ret = 'indeterminate_check_box';
        } else if (statuses.length === Object.keys(vm.personas).length && statuses.indexOf('partial') === -1) {
          ret = 'check_box';
        } else if (statuses.indexOf('partial') > -1) {
          ret = 'indeterminate_check_box';
        } else {
          ret = 'check_box_outline_blank';
        }

        return ret;
      }

      if (!Object.prototype.hasOwnProperty.call(persona, 'selectedStatus')) {
        return 'check_box_outline_blank';
      }

      switch (persona.selectedStatus) {
        case 'all':
          ret = 'check_box';
          break;

        case 'partial':
          ret = 'indeterminate_check_box';
          break;

        default:
          ret = 'check_box_outline_blank';
      }

      return ret;
    }

    function loadFromInput () {
      var mpidsToLoad = vm.mpid_to_load.split(',');
      return loadMpids(mpidsToLoad)
        .finally(function () {
          $timeout(function () { scrollToElement('.mpid-list', '.' + mpidToClassName(mpidsToLoad[mpidsToLoad.length]), 1); }, 0);
        });
    }

    function moveRecord (srcPersona, srcIndex, targetPersona, type) {
      var originalTarget, moved, lookup;

      // Don't do anything if just dropping inside the same persona
      if (srcPersona.mpid === targetPersona.mpid || !srcPersona.pi_entries.length) {
        return;
      }

      originalTarget = vm.original[parseMpid(targetPersona.mpid)];
      moved = type === 'mpid' ? srcPersona.pi_entries : srcPersona.pi_entries.splice(srcIndex, 1);

      moved.forEach(function (pi) {
        // Check if moved pi entries were in the original persona
        lookup = originalTarget.pi_entries.filter(function (record) {
          return record.pid === pi.pid;
        });

        // If pi entries were moved back to the persona they originated from,
        // remove the moved status and reset the timestamp, otherwise add it
        // and update the timestamp for sorting.
        if (lookup.length && pi.moved) {
          delete pi.moved;
        } else {
          pi.moved = Date.now();
        }

        pi.highlighted = true;
        $timeout(function () { delete pi.highlighted; }, 1000);
      });

      // Move pi entries from src to target persona. If merging personas,
      // the src persona pi_entries are emptied and the persona is hidden in the ui.
      if (type === 'mpid') {
        targetPersona.pi_entries = targetPersona.pi_entries.concat(moved);
        targetPersona.pi_entries = targetPersona.pi_entries.sort(comparePiLastUpdated);
        vm.personas[parseMpid(srcPersona.mpid)].pi_entries = [];
        // vm.personas[ parseMpid(srcPersona.mpid) ].merged = true;
      } else {
        targetPersona.pi_entries = targetPersona.pi_entries.concat(moved);
        targetPersona.pi_entries = targetPersona.pi_entries.sort(comparePiLastUpdated);
      }

      // Check equality of src and target personas and add or delete
      // the altered flag accordingly
      [srcPersona, targetPersona].forEach(function (persona) {
        if (piEntriesEqual(vm.original[parseMpid(persona.mpid)].pi_entries, persona.pi_entries) && persona.altered) {
          delete persona.altered;
        } else {
          persona.altered = true;
        }
        persona.selectedStatus = getPersonaSelectedStatus(persona.pi_entries);
      });
      recalc();
      vm.preventCommit = !changed();
    }

    function mpidToClassName (mpid) {
      return /^New-/.test(mpid) ? 'mpid-' + mpid.toLowerCase() : 'mpid-' + parseInt(mpid, 10);
    }

    function parseTimestamp (stamp) {
      return (stamp === UDPATE_PENDING) ? stamp : moment(stamp).format('M/D/YYYY, h:mm:ss A');
    }

    function reloadMPIDs () {
      vm.loading = true;
      vm.preventCommit = true;
      vm.mpid_to_load = '';
      for (var mpid in vm.personas) {
        if (Object.prototype.hasOwnProperty.call(vm.personas, mpid)) {
          mpiPersona.reset(mpid);
        }
      }
      vm.personas = angular.copy(vm.original);
      recalc();
      vm.loading = false;
    }

    function removeMpids (mpid) {
      var id = mpid ? parseMpid(mpid) : false; var confirm;

      if (mpid) {
        if (vm.personas[id].altered) {
          psNotification.warn(id + ' could not be removed from the editor because it has been altered.');
          return;
        }
        mpiPersona.reset(id);
        delete vm.personas[id];
      } else {
        confirm = $mdDialog.confirm()
          .title('Remove all personas?')
          .textContent('This will remove all personas and you will lose any uncommitted changes.')
          .ok('Remove')
          .cancel('Cancel');

        $mdDialog.show(confirm)
          .then(function () {
            for (var key in vm.personas) {
              if (Object.prototype.hasOwnProperty.call(vm.personas, key)) {
                mpiPersona.reset(parseMpid(key));
              }
            }
            vm.personas = {};
          });
      }
      recalc();
    }

    function scrollToElement (scroller, scrollTo, iterations) {
      var scrollStart, amount, offsetTop, scrollTop;

      if (document.querySelector(scroller) === null || document.querySelector(scrollTo) === null) {
        return;
      }

      scrollStart = document.querySelector(scroller).scrollTop;
      amount = scrollStart;
      offsetTop = document.querySelector(scrollTo).offsetTop;

      // Jump to element if iterations is less than 2 or not specified
      if (iterations < 2) {
        document.querySelector(scroller).scrollTop = document.querySelector(scrollTo).offsetTop - 82;
        return;
      }

      $interval(function () {
        scrollTop = document.querySelector(scroller).scrollTop;

        if (offsetTop < scrollStart) {
          amount = scrollTop - Math.floor(scrollStart / iterations);
        } else if (scrollTop > 0 && scrollTop > offsetTop) {
          amount = 0;
        } else {
          amount = amount + Math.floor(offsetTop / iterations);
        }

        document.querySelector(scroller).scrollTop = amount;
      }, 0, iterations);
    }

    function showCommitDialog (ev) {
      var message = ''; var confirm; var newMpids;

      confirm = $mdDialog.prompt()
        .title('Confirm Changes')
        .htmlContent('Are you sure you want to commit these changes?<br><br>Please enter a commit message:')
        .placeholder('Commit message')
        .ariaLabel('Commit message')
        .targetEvent(ev)
        .ok('Confirm')
        .cancel('Cancel');

      $mdDialog.show(confirm)
        .then(function (comment) {
          vm.preventCommit = true;
          mpiApiSrv.commitMpids(comment, vm.personas)
            .then(function (result) {
              if (result && result.data && result.data.mpi && result.data.mpi.transaction) {
                newMpids = result.data.mpi.transaction.new_mpids;
              }

              // Clear the data before reinitializing.
              // IMPORTANT: We're not reloading the list of MPIDs and PIDS on purpose so that the user isn't messing with them again while the system is propagating changes
              // Reset cached personas
              for (var persona in vm.personas) {
                if (Object.prototype.hasOwnProperty.call(vm.personas, persona)) {
                  mpiPersona.reset(persona);
                }
              }

              mpidList = [];
              vm.mpid_to_load = '';
              vm.personas = {};
              vm.original = {};

              // Display a success message to the user
              message = 'Changes committed. ';
              if (angular.isDefined(newMpids)) {
                message += 'The following MPIDs were created: ' + newMpids + '. ';
              }
              message += 'You can perform a search and view your changes in a few seconds. Updates to clinical records can take several minutes to propagate. ';
              psNotification.success('Request completed.' + message, true);
            })
            .catch(function () {
              psNotification.warn('There was an error committing your changes. Please reload the page and try again. If errors continue, contact your system administrator. ');
            });
        });
    }

    function toggleAll (toggleState) {
      vm.toggleAllState = toggleState;
      for (var persona in vm.personas) {
        if (Object.prototype.hasOwnProperty.call(vm.personas, persona)) {
          vm.personas[persona].collapsed = vm.toggleAllState;
        }
      }
      recalc();
    }

    function togglePIList (persona) {
      persona.collapsed = !persona.collapsed;
      recalc();
    }

    // eslint-disable-next-line complexity
    function toggleSelect (persona, idx) {
      var entries;

      if (!persona) { return; }

      entries = persona.pi_entries;

      if (!angular.isDefined(idx)) {
        persona.selectedStatus = !persona.selectedStatus || persona.selectedStatus === 'partial' ? 'all' : false;
        entries.forEach(function (pi) {
          pi.selected = persona.selectedStatus === 'all';
        });
        return;
      }

      entries[idx].selected = !entries[idx].selected;

      persona.selectedStatus = getPersonaSelectedStatus(entries);
    }

    function toggleSelectAll () {
      var status = getSelectedStatusIcon();

      for (var persona in vm.personas) {
        if (Object.prototype.hasOwnProperty.call(vm.personas, persona)) {
          if (status === 'indeterminate_check_box' || status === 'check_box_outline_blank') {
            vm.personas[persona].selectedStatus = false;
          }
          toggleSelect(vm.personas[persona]);
        }
      }
    }

    //= =========================
    // Private Functions
    //= =========================

    function activate () {
      var urlMpids = $transition$.params().mpids;

      mpidList = urlMpids ? extractIds(urlMpids.split(','), mpidList) : [];

      if (mpidList.length) {
        loadMpids(mpidList);
      }

      // Watch scroll to hide/show 'Back to Top' button
      document.querySelector('.mpid-list').addEventListener('scroll', function () {
        if (this.scrollTop > this.offsetHeight) { // eslint-disable-line no-invalid-this
          angular.element('#scroll-to-top').addClass('active');
        } else {
          angular.element('#scroll-to-top').removeClass('active');
        }
      });

      // Clear added ui properties of personas when navigating away
      // this may change later when we start saving state in pi link editor
      $scope.$on('$destroy', function () {
        for (var persona in vm.personas) {
          if (Object.prototype.hasOwnProperty.call(vm.personas, persona)) {
            // eslint-disable-next-line no-loop-func
            ['altered', 'collapsed', 'selectedStatus', 'toggleFacility'].forEach(function (prop) {
              if (Object.prototype.hasOwnProperty.call(vm.personas[persona], prop)) {
                delete vm.personas[persona][prop];
              }
            });
          }
        }
      });
    }

    function changed () {
      var changes = false;

      for (var persona in vm.original) {
        if (Object.prototype.hasOwnProperty.call(vm.original, persona)) {
          if (!piEntriesEqual(vm.original[persona].pi_entries, vm.personas[persona].pi_entries)) {
            changes = true;
            break;
          }
        }
      }

      return changes;
    }

    function checkDuplicates (mpid) {
      var dupes = Object.keys(vm.personas).filter(function (key) {
        return parseMpid(key) === parseMpid(mpid);
      });

      return dupes.length ? dupes[0] : false;
    }

    function checkValid(entries) { // eslint-disable-line
      var list = entries.length ? entries : [entries]; var warnings = []; var ret; var dupe; var error;

      ret = list.filter(function (mpid) {
        dupe = checkDuplicates(mpid);
        error = '';

        if (!/^\d+/.test(mpid)) {
          error = 'The MPID "' + mpid + '" is not valid.';
        } else if (dupe && dupe === mpid) {
          error = 'The MPID "' + mpid + '" is already loaded.';
        } else if (dupe && dupe !== mpid) {
          error = 'The MPID "' + mpid + '" is already loaded as "' + dupe + '".';
        }

        if (dupe && dupe.merged) {
          error += ' It has been merged with another MPID and is no longer visible.';
        }

        if (error) {
          warnings.push(error);
          return false;
        }
        return true;
      });

      if (warnings.length) {
        warnings.forEach(function (warning) {
          psNotification.customShow({ message: warning, type: 'warn' });
        });
      }

      return ret;
    }

    function compareMpid (a, b) { // eslint-disable-line complexity, no-unused-vars
      var reNew = /^New-/;
      // Force a & b values to integers. If the MPID is a new (New-X) MPID, force it to a negative
      // integer to be ordered to the top of the list.
      var parsedA = (reNew.test(a)) ? parseInt(a.replace(reNew, ''), 10) * -1 : parseInt(a, 10);
      var parsedB = (reNew.test(b)) ? parseInt(b.replace(reNew, ''), 10) * -1 : parseInt(b, 10);

      // Compare integers and sort New-X MPIDs to the top of the list
      if (parsedA > 0 && parsedB > 0) { return parsedA - parsedB; }// Most common.
      if (parsedA > 0 && parsedB < 0) { return 1; }
      if (parsedA < 0 && parsedB > 0) { return -1; }
      if (parsedA < 0 && parsedB < 0) { return parsedB - parsedA; }
      if (parsedA === parsedB) { return 0; }// Least common.
    }

    function comparePiLastUpdated (a, b) {
      if (a.moved && !b.moved) { return 1; }
      if (!a.moved && b.moved) { return -1; }

      if (a.moved && b.moved) {
        return b.moved - a.moved;
      }

      return b.last_updated - a.last_updated;// Descending.
    }

    function drag (e) { // eslint-disable-line no-unused-vars
      var amt;

      if (!vm.dragging || e.clientY === 0) { // e.clientY check is for Firefox inconsistencies
        return;
      }

      amt = 0;

      if (e.clientY < (listRect.top + 150)) {
        amt = Math.floor(((listRect.top + 150) - e.clientY) * -0.5);
        scroll(amt);
      }

      if (e.clientY > ((listRect.top + listRect.height) - 150)) {
        amt = Math.floor((((listRect.top + listRect.height) - 150) - e.clientY) * -0.5);
        scroll(amt);
      }
    }

    function dragRecordEnd () {
      vm.dragging.removeEventListener(dragging, drag);
      vm.dragging = null;
    }

    function dragRecordStart () {
      vm.dragging = event.target;
      vm.dragging.addEventListener(dragging, drag);
    }

    function extractIds (idArray, ref) {
      var id;
      return idArray.map(function (element) {
        id = parseInt(element, 10);
        if (!isNaN(id) && ref.indexOf(element) < 0) {
          return element;
        }
      });
    }

    function getClinicalRecordCountString (count) {
      if (count == null) return '?';
      if (count < 1000) return '' + count;
      return (count / 1000).toFixed(1) + ' k';
    }

    function getPersonaSelectedStatus (entries) {
      var selectedPiRecords, diff, ret;

      if (!entries.length) {
        return false;
      }

      // Check status of all PI records to determine status of persona
      selectedPiRecords = entries.filter(function (pi) {
        return pi.selected;
      });
      diff = entries.length - selectedPiRecords.length;

      if (diff === 0) {
        ret = 'all';
      } else if (diff > 0 && diff < entries.length) {
        ret = 'partial';
      } else {
        ret = false;
      }

      return ret;
    }

    function loadMpids (mpid) {
      var sanitized = checkValid(mpid);
      var promises = [];
      var warnings = [];

      vm.loading = true;

      if (!sanitized.length) {
        vm.loading = false;
        return $q.reject();
      }

      sanitized.forEach(function (mpidInner) {
        var persona;
        var summary;
        var promise = mpiPersona.load(mpidInner)
          .then(function (response) {
            persona = response;
            persona.pi_entries = [];
            return persona.getClinicalRecordCount();
          })
          .then(function (count) {
            summary = count;
            return mpiPersona.loadRecords(mpidInner);
          })
          .then(function (piRecords) {
            if (piRecords.length) {
              persona.pi_entries = piRecords;
              persona.pi_entries.sort(comparePiLastUpdated);
              persona.pi_entries.forEach(function (p) {
                p.clinical_records = getClinicalRecordCountString(summary[p.pid].total);
              });
            }
            return persona;
          })
          .then(function (finalRecord) {
            vm.personas[parseMpid(finalRecord.mpid)] = finalRecord;
            vm.original[parseMpid(finalRecord.mpid)] = angular.copy(finalRecord);
          })
          .catch(function () {
            warnings.push('The MPID "' + mpidInner + '" does not exist or could not be loaded.');
          });
        promises.push(promise);
      });

      return $q.all(promises)
        .then(function (response) {
          vm.personas = sortObject(vm.personas);
          vm.original = angular.copy(vm.personas);
          return $q.resolve(response);
        })
        .catch(function () {
          return $q.reject();
        })
        .finally(function () {
          if (warnings.length) {
            psNotification.warn(warnings.join(''));
          }
          recalc();
          vm.loading = false;
        });
    }

    function parseMpid (mpid) {
      if (mpid.search(/^New/) > -1) {
        return mpid;
      }
      return parseInt(mpid, 10) + '.MPID';
    }

    function piEntriesEqual (a, b) {
      var equal = true; var aSrt = angular.copy(a).sort(); var bSrt = angular.copy(b).sort();

      // If length is not the same, return false
      if (aSrt.length !== bSrt.length) {
        return !equal;
      }

      // If length is the same, check contents
      for (var i = 0; i < aSrt.length; i++) {
        if (aSrt[i].pid !== bSrt[i].pid) {
          equal = false;
          break;
        }
      }

      return equal;
    }

    function recalc () {
      $timeout(function () {
        listRect = document.querySelector('.mpid-list').getBoundingClientRect();
      }, 0);
    }

    function scroll (step) {
      var scrollY = document.querySelector('.mpid-list').scrollTop;
      document.querySelector('.mpid-list').scrollTop = scrollY + step;
    }

    function sortObject (obj) {
      var ret = {};
      Object.keys(obj).sort(compareMpid).forEach(function (key) {
        ret[key] = obj[key];
      });
      return ret;
    }
  }
})();
