(function () {
  'use strict';

  angular.module('imat.services')
    .factory('imatFieldsSrv', imatFieldsSrv);

  imatFieldsSrv.$inject = ['$parse', 'fieldsApiSrv'];

  function imatFieldsSrv ($parse, fieldsApiSrv) {
    var service;
    var indexByExtract = {};
    var indexByFieldId = {};

    service = {
      loaded: false,

      get: get,
      getFieldsById: getFieldsById,
      getFieldsByExtract: getFieldsByExtract,
      load: load
    };

    return service;

    //= ================================
    // Public interface
    //= ================================

    function get (field) {
      return (field ? $parse(field)(indexByFieldId) : angular.copy(indexByFieldId));
    }

    function load () {
      return fieldsApiSrv.getFieldMap()
        .then(function (response) {
          var byExtract = {};
          var byFieldId = {};

          if (angular.isArray(response.data)) {
            response.data.forEach(function (field) {
              byExtract[field.extract] = field;
              byFieldId[field.id] = field;
            });
          }
          indexByExtract = byExtract;
          indexByFieldId = byFieldId;
        });
    }

    function getFieldsById (fields) {
      if (angular.isArray(fields)) {
        return fields.map(function (field) {
          return (Object.prototype.hasOwnProperty.call(indexByFieldId, field) ? indexByFieldId[field] : null);
        });
      }
      return (Object.prototype.hasOwnProperty.call(indexByFieldId, fields) ? indexByFieldId[fields] : null);
    }

    function getFieldsByExtract (extracts) {
      if (angular.isArray(extracts)) {
        return extracts.map(function (extract) {
          return (Object.prototype.hasOwnProperty.call(indexByExtract, extract) ? indexByExtract[extract] : null);
        });
      }
      return (Object.prototype.hasOwnProperty.call(indexByExtract, extracts) ? indexByExtract[extracts] : null);
    }

    //= ================================
    // Private interface
    //= ================================
  }
})();
