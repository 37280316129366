(function () {
  'use strict';

  angular.module('imat.fhir')
    .factory('fhirIdentifierSrv', fhirIdentifierSrv);

  fhirIdentifierSrv.$inject = ['$http', 'fhirValueSetSrv'];

  function fhirIdentifierSrv ($http, fhirValueSetSrv) {
    var service = {
      isLoaded: false,
      SYSTEMS: {
        IMAT_MPI: '',
        US_MEDICARE: 'http://hl7.org/fhir/sid/us-medicare',
        US_SSN: 'http://hl7.org/fhir/sid/us-ssn'
      },

      hasTypeCode: hasTypeCode,
      isFacilityMrn: isFacilityMrn,
      isImatMpid: isImatMpid,
      isImatPi: isImatPi,
      isUsMedicare: isUsMedicare,
      isUsSsn: isUsSsn,

      load: load
    };
    var SYSTEMS = [];
    // var SYSTEMS_BY_TYPE = {
    //   'http://hl7.org/fhir/identifier-type/SB': [
    //     {display: 'HICN (Medicare)',       system: service.SYSTEMS.US_MEDICARE},
    //     {display: 'SSN (Social Security)', system: service.SYSTEMS.US_SSN},
    //   ],
    //   'http://hl7.org/fhir/v2/0203/MR': [
    //     // Facility system values are not knowable or predictable.
    //     // Could we look up the existing system values in MPI?
    //   ],
    //   'http://hl7.org/fhir/v2/0203/SS': [// Legacy
    //     {display: 'SSN (Social Security)', system: service.SYSTEMS.US_SSN},
    //   ],
    //   'http://imat.us/fhir/codes/mpi/MPID': [
    //     {display: 'IMAT MPI', system: service.SYSTEMS.IMAT_MPI},
    //   ],
    //   'http://imat.us/fhir/codes/mpi/PI': [
    //     {display: 'IMAT MPI', system: service.SYSTEMS.IMAT_MPI},
    //   ],
    // };

    return service;

    //= ================================
    // Public interface
    //= ================================

    function hasTypeCode (identifier, code) {
      return compareTypeCode(identifier, code);
    }

    function isFacilityMrn (identifier) {
      // Must not be a known system. Facility system values are not knowable or predictable.
      if (SYSTEMS.indexOf(identifier.system) >= 0) { return false; }
      return compareTypeCode(identifier, 'MR');
    }

    function isImatMpid (identifier) {
      if (identifier.system !== service.SYSTEMS.IMAT_MPI) { return false; }
      return compareTypeCode(identifier, 'MPID');
    }

    function isImatPi (identifier) {
      if (identifier.system !== service.SYSTEMS.IMAT_MPI) { return false; }
      return compareTypeCode(identifier, 'PI');
    }

    function isUsMedicare (identifier) {
      return identifier.system === service.SYSTEMS.US_MEDICARE;// Coding is irrelevant.
    }

    function isUsSsn (identifier) {
      return identifier.system === service.SYSTEMS.US_SSN;// Coding is irrelevant.
    }

    function load () {
      service.isLoaded = false;
      return loadMpiSystemUrl()
        .then(function (system) {
          service.SYSTEMS.IMAT_MPI = system;
          Object.keys(service.SYSTEMS).forEach(function (key) {
            SYSTEMS.push(service.SYSTEMS[key]);
          });
        })
        .finally(function () {
          service.isLoaded = true;
        });
    }

    //= ================================
    // Private interface
    //= ================================

    function compareTypeCode (identifier, code) { // eslint-disable-line complexity
      var coding = [];
      var fhirCoding, imatCoding;
      var matched = false;

      if (!code) { return false; }
      if (!identifier.type || !identifier.type.coding || !identifier.type.coding.length) { return false; }

      fhirCoding = fhirValueSetSrv.getValueSetValue('IDENTIFIER_TYPE', code);
      imatCoding = fhirValueSetSrv.getValueSetValue('IMAT_IDENTIFIER_TYPE', code);

      if (imatCoding) { coding = coding.concat(imatCoding); }
      if (fhirCoding) { coding = coding.concat(fhirCoding); }

      if (coding.length) {
        finder: { // eslint-disable-line no-labels
          for (var i = 0, ii = identifier.type.coding.length; i < ii; ++i) {
            for (var j = 0, jj = coding.length; j < jj; ++j) {
              if (identifier.type.coding[i].system === coding[j].system && identifier.type.coding[i].code === coding[j].code) {
                matched = true;
                break finder; // eslint-disable-line no-labels
              }
            }
          }
        }
      }
      return matched;
    }

    function loadMpiSystemUrl () {
      return $http.get('/extensions/mpi/system-id', {
        headers: { Accept: 'application/xml, text/xml, xml' }
      })
        .catch(function () {
          return { data: { mpi: '' } };
        })
        .then(function (response) {
          return response.data.mpi;
        });
    }
  }
})();
