(function () {
  'use strict';

  angular.module('imat.services')
    .factory('configurationApiSrv', configurationApiSrv);

  configurationApiSrv.$inject = ['$http'];

  function configurationApiSrv ($http) {
    var BASE_URL = '/api/conf/';
    var service = {
      getConfigFile: getConfigFile,
      getConfigFiles: getConfigFiles,
      saveConfigFile: saveConfigFile
    };

    return service;

    function getConfigFiles () {
      return $http({
        method: 'GET',
        url: BASE_URL
      });
    }

    function getConfigFile (fileName) {
      return $http({
        method: 'GET',
        url: BASE_URL + encodeURIComponent(fileName)
      });
    }

    function saveConfigFile (fileName, fileContent) {
      return $http({
        method: 'PUT',
        url: BASE_URL + encodeURIComponent(fileName),
        data: { content: fileContent }
      });
    }
  }
})();
