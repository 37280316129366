(function () {
  'use strict';

  angular.module('imat.services')
    .factory('searchSrv', searchSrv);

  searchSrv.$inject = ['$interval', '$q', 'STATUSES', 'CachingClass', 'searchApiSrv'];

  function searchSrv ($interval, $q, STATUSES, CachingClass, searchApiSrv) {
    var polling = {};
    var service = {
      bulkPollStatus: bulkPollStatus,
      cancel: cancel,
      getResultOutput: getResultOutput,
      getSearches: getSearches,
      getSelectedItem: getSelectedItem,
      pollStatus: pollStatus,
      search: search,
      stopPolling: stopPolling
    };

    service = angular.extend(new CachingClass(), service);

    return service;

    function bulkPollStatus (ids, options) {
      var interval = (options && options.interval) || 3000;
      var admin = (options && options.admin) || false;
      ids
        .filter(function (id) {
          var entry = service.getCachedListEntry(id);
          return entry && (entry.status === STATUSES.QUEUED || entry.status === STATUSES.RUNNING);
        })
        .forEach(function (id) {
          pollStatus(id, { interval: interval, admin: admin });
        });
    }

    function cancel (id, isAdmin) {
      return searchApiSrv.cancelSearch(id, isAdmin)
        .then(function (res) {
          _stopPollingById(id);
          return res.data;
        });
    }

    function getResultOutput (id, path) {
      return searchApiSrv.getResultOutput(id, path)
        .then(function (res) {
          return res.data;
        });
    }

    function getSearches (params) {
      params = params || {};
      params.admin = params.admin == null || params.admin;
      return searchApiSrv.getSearchesList(params)
        .then(function (res) {
          if (!res.data || !res.data.results || !res.data.results.result) {
            throw new Error('Data format error. Unexpected response from server.');
          }

          var searches = res.data.results.result || [];
          searches = Array.isArray(searches) ? searches : [searches];

          service.cache(searches);
          return {
            total: parseInt(res.data.results.total, 10),
            searches: searches
          };
        });
    }

    function getSelectedItem (ctx, force) {
      return CachingClass.prototype.getSelectedItem.call(service, ctx, force)
        .catch(function () {
          var id = service.getSelectedId(ctx);
          if (!id) { return $q.reject(); }

          return _getSearchDetails(id)
            .then(function (item) { return service.cacheItem(item); });
        });
    }

    function pollStatus (id, options) {
      var interval = (options && options.interval) || 1000;
      var admin = (options && options.admin) || false;
      var deferred = $q.defer();

      polling[id] = $interval(function () {
        searchApiSrv.getSearchStatus(id, admin)
          .then(function (res) {
            if (res.data.result.exit_code === 'None') {
              return;
            }

            var status = res.data.result.status;
            var entry = service.getCachedListEntry(id);
            if (entry) { entry.status = status; }
            var item = service.getCachedItem(id);
            if (item) { item.status = status; }

            if (res.data.result.exit_code === '0') {
              deferred.resolve(true);
            } else {
              deferred.reject(res.data.result.exit_message);
            }
            _stopPollingById(id);
          })
          .catch(function (err) {
            _stopPollingById(id);
            deferred.reject(err);
          });
      }, interval);

      return deferred.promise;
    }

    function search (data, config, formEncoded) {
      return searchApiSrv.post(data, config, formEncoded)
        .then(function (res) {
          return res.data;
        });
    }

    function stopPolling () {
      Object.keys(polling).forEach(_stopPollingById);
    }

    function _stopPollingById (id) {
      if (polling[id]) {
        $interval.cancel(polling[id]);
        delete polling[id];
      }
    }

    function _getSearchDetails (id) {
      // Get additional search details not available from search list object
      return searchApiSrv.getSearchDetails(id)
        .then(function (res) {
          var search = angular.copy(service.getCachedListEntry(id));
          var result = res.data && res.data.result;
          if (!result) { return search; }
          search.createdOn = result.created_on;
          search.queryString = result.query_string || null;
          search.queryId = result.query_id || null;
          return search;
        });
    }
  }
})();
