(function () {
  'use strict';

  angular
    .module('vhr.services')
    .factory('vhrPrintSrv', vhrPrintSrv);

  vhrPrintSrv.$inject = ['$rootScope', '$timeout', '$window', 'vhrApp', 'vhrUserSrv', 'vhrConfigSrv'];

  function vhrPrintSrv ($rootScope, $timeout, $window, vhrApp, vhrUserSrv, vhrConfigSrv) {
    var service;
    var printPromise;
    var fakePrintMedia = {};
    var hasFakePrintMedia = 0;
    var printingAvailable = false;

    service = {
      HIDDEN: 'print-hidden',

      cancel: cancel,
      enterFakePrintMedia: enterFakePrintMedia,
      exitFakePrintMedia: exitFakePrintMedia,
      hideContent: hideContent,
      print: print,
      printed: printed,
      showContent: showContent,
      printingAvailable: printingAvailable
    };

    activate();

    function activate () {
      var userGroups = vhrUserSrv.getGroups();
      var printGroups = vhrConfigSrv.getPrintGroups();
      printingAvailable = printGroups.length === 0 || printGroups.filter(function (group) { return userGroups.indexOf(group) > -1; }).length > 0;

      vhrApp.enablePrinting(printingAvailable);
      // Capture Ctrl+P and route through vhrPrintSrv.print().
      angular.element($window.document).on('keyup keydown', function (evt) {
        if ((evt.ctrlKey && evt.keyCode === 80) || (evt.metaKey && evt.keyCode === 80)) {
          evt.preventDefault();
          if (printingAvailable) { print(); }
          return false;
        }
      });
    }

    function cancel () {
      $timeout.cancel(printPromise);
    }

    function hideContent () {
      angular.element(document.querySelector('body > [ui-view]')).addClass(service.HIDDEN);
      angular.element(document.querySelector('md-dialog.record-modal')).addClass(service.HIDDEN);
    }

    function print () {
      $timeout.cancel(printPromise);
      var listeners = $rootScope.$$listenerCount.vhrPrinted;

      $rootScope.vhrPrinters = (listeners) ? new Array(listeners) : [];
      $rootScope.$broadcast('vhrPrinted');
      checkAllPrintersReady();
    }

    function printed () {
      if (!$rootScope.vhrPrinters) { return; }
      $rootScope.vhrPrinters.pop();
    }

    function showContent () {
      angular.element(document.querySelector('body > [ui-view]')).removeClass(service.HIDDEN);
      angular.element(document.querySelector('md-dialog.record-modal')).removeClass(service.HIDDEN);
    }

    function enterFakePrintMedia () {
      if (++hasFakePrintMedia > 1) { return; }

      fakePrintMedia.toastNodes = nodeListToArray($window.document.documentElement.querySelectorAll('md-toast'));
      fakePrintMedia.dialogNodes = nodeListToArray($window.document.documentElement.querySelectorAll('.md-dialog-container'));
      fakePrintMedia.hidingNodes = nodeListToArray($window.document.documentElement.querySelectorAll('.print-hidden'));
      fakePrintMedia.styleNodes = nodeListToArray($window.document.documentElement.querySelectorAll('style'));
      fakePrintMedia.showingNodes = nodeListToArray($window.document.documentElement.querySelectorAll('.print-version'));
      fakePrintMedia.toastStyles = [];
      fakePrintMedia.dialogStyles = [];
      fakePrintMedia.hidingStyles = [];
      fakePrintMedia.styleStyles = [];
      fakePrintMedia.showingStyles = [];

      fakePrintMedia.toastNodes.forEach(function (node) {
        var n = angular.element(node);
        fakePrintMedia.toastStyles.push(n.css('display'));
        n.css({ display: 'none!important' });
      });
      fakePrintMedia.dialogNodes.forEach(function (node) {
        var n = angular.element(node);
        fakePrintMedia.dialogStyles.push(n.css('display'));
        fakePrintMedia.dialogStyles.push(n.css('position'));
        fakePrintMedia.dialogStyles.push(n.css('visibility'));// Try to reduce visual jankiness.
        n.css({ display: 'block', position: 'relative!important', visibility: 'hidden' });
      });
      fakePrintMedia.hidingNodes.forEach(function (node) {
        var n = angular.element(node);
        fakePrintMedia.hidingStyles.push(n.css('display'));
        n.css({ display: 'none!important' });
      });
      fakePrintMedia.styleNodes.forEach(function (node) {
        var n = angular.element(node);
        fakePrintMedia.styleStyles.push(n.css('display'));
        n.css({ display: 'none!important' });
      });
      fakePrintMedia.showingNodes.forEach(function (node) {
        var n = angular.element(node);
        fakePrintMedia.showingStyles.push(n.css('display'));
        fakePrintMedia.showingStyles.push(n.css('backgroundColor'));// Try to reduce visual jankiness.
        fakePrintMedia.showingStyles.push(n.css('color'));// Try to reduce visual jankiness.
        n.css({ display: 'block', backgroundColor: 'transparent', color: 'transparent' });
      });
    }

    function exitFakePrintMedia () {
      if (--hasFakePrintMedia > 1) { return; }
      hasFakePrintMedia = 0;

      fakePrintMedia.showingNodes.forEach(function (node, idx) {
        var n = angular.element(node);
        var i = 3 * idx;
        n.css('display', fakePrintMedia.showingStyles[i + 0]);
        n.css('backgroundColor', fakePrintMedia.showingStyles[i + 1]);
        n.css('color', fakePrintMedia.showingStyles[i + 2]);
      });
      fakePrintMedia.styleNodes.forEach(function (node, idx) {
        var n = angular.element(node);
        n.css('display', fakePrintMedia.styleStyles[idx]);
      });
      fakePrintMedia.hidingNodes.forEach(function (node, idx) {
        var n = angular.element(node);
        n.css('display', fakePrintMedia.hidingStyles[idx]);
      });
      fakePrintMedia.dialogNodes.forEach(function (node, idx) {
        var n = angular.element(node);
        var i = 3 * idx;
        n.css('display', fakePrintMedia.dialogStyles[i + 0]);
        n.css('position', fakePrintMedia.dialogStyles[i + 1]);
        n.css('visibility', fakePrintMedia.dialogStyles[i + 2]);
      });
      fakePrintMedia.toastNodes.forEach(function (node, idx) {
        var n = angular.element(node);
        n.css('display', fakePrintMedia.toastStyles[idx]);
      });
    }

    // ---------------------------------

    function checkAllPrintersReady () {
      if ($rootScope.vhrPrinters.length <= 0) {
        $rootScope.vhrPrinters.splice(0);
        $window.print();
      } else {
        printPromise = $timeout(checkAllPrintersReady, 0, false);
      }
    }

    function nodeListToArray (nodeList) {
      var i; var arr = [];

      for (i = 0; i < nodeList.length; ++i) {
        arr.push(nodeList[i]);
      }
      return arr;
    }

    return service;
  }
})();
