(function () {
  'use strict';

  angular.module('admin')
    .controller('ConfigCtrl', ConfigCtrl);

  ConfigCtrl.$inject = ['$filter', '$mdDialog', '$q', '$scope', '$transitions', 'ConfigurationSrv', 'imatConfig', 'psNotification', 'imatStoresSrv'];
  function ConfigCtrl ($filter, $mdDialog, $q, $scope, $transitions, ConfigurationSrv, imatConfig, psNotification, imatStoresSrv) {
    var vm = this;
    var SELECT_FILE_MESSAGE = 'Please select a file to configure.';
    var GET_FILE_FAILURE_MESSAGE = 'Could not find the selected file.';
    var GET_STORES_ERROR = 'Unable to retrieve the stores.';
    var SAVE_FILE_ERROR = 'Unable to save the file.';
    var deregisterTransitions = $transitions.onExit({ exiting: 'app.admin.configuration' }, confirmExit);

    // Variables.
    vm.cats = [];
    vm.catsList = [];
    vm.currContentStr = null;
    vm.editor; // eslint-disable-line no-unused-expressions
    vm.editorConfig = {
      mode: 'python',
      lineWrapping: true,
      lineNumbers: true,
      indentUnit: 4,
      theme: 'eclipse',
      extraKeys: {
        'Ctrl-F': 'findPersistent',
        'Cmd-F': 'findPersistent',
        'Ctrl-S': function () { saveConfigFile(vm.selectedItem, vm.currContentStr); },
        'Cmd-S': function () { saveConfigFile(vm.selectedItem, vm.currContentStr); }
      },
      highlightSelectionMatches: {
        showToken: true,
        annotateScrollbar: true
      }
    };

    vm.flags = { invalidJson: false };
    vm.listItems; // eslint-disable-line no-unused-expressions
    vm.numberOfFiles = 0;
    vm.loadingFile = false;
    vm.loadingFiles = true;
    vm.modified = false;
    vm.origContentStr = null;
    vm.searchFilterText = '';
    vm.selectedItem; // eslint-disable-line no-unused-expressions
    vm.stores; // eslint-disable-line no-unused-expressions

    // Methods.
    vm.clearFilter = clearFilter;
    vm.createConfigFile = createConfigFile;
    vm.getConfigFile = getConfigFile;
    vm.saveConfigFile = saveConfigFile;
    vm._codemirrorLoaded = _codemirrorLoaded;
    vm.reloadList = reloadList;

    activate();

    function activate () {
      $scope.$on('$destroy', function () {
        deregisterTransitions();
      });

      $scope.$watch(function () { return vm.searchFilterText; }, function (curr) {
        vm.cats = filterList(vm.listItems, curr || '');
      });

      _getStores();
      _getConfigFiles();
    }

    //= ================
    // Public Interface
    //= ================

    function clearFilter () {
      vm.searchFilterText = '';
    }

    function createConfigFile () {
      $mdDialog.show({
        scope: $scope,
        preserveScope: true,
        clickOutsideToClose: false,
        templateUrl: '/ui/admin/configuration/create-config-modal.html',
        controller: 'ConfigDialogController',
        locals: {
          serverResponse: null,
          stores: vm.stores,
          configFiles: vm.listItems,
          reload: reloadList
        }
      });
    }

    function getConfigFile (item) {
      vm.selectedItem = item;

      return confirmExit()
        .then(function (res) {
          if (res) {
            return loadConfigFile(item);
          }
        });
    }

    function loadConfigFile (item) {
      if (!item) {
        psNotification.show(SELECT_FILE_MESSAGE);
        return $q.reject();
      }

      vm.flags.invalidJson = false;
      vm.loadingFile = true;
      vm.modified = false;
      return ConfigurationSrv.getConfigFile(item.name)
        .then(function (response) { // eslint-disable-line complexity
          let fileContent;
          const mode = response.data.type;
          fileContent = response.data.content.replace(/\r\n/g, '\n');
          if (mode === 'application/json') {
            try {
            // Convert from the unknown JSON format to a 4 space indentation format.
              fileContent = JSON.stringify(JSON.parse(fileContent), null, 4);
            } catch (e) {
              vm.flags.invalidJson = true;
            }
          }

          if (vm.editor.getOption('mode') !== mode) {
            vm.editor.setOption('mode', mode);
          }
          if (vm.editor.getOption('mode') !== mode) {
            vm.editor.setOption('mode', 'text/plain');
          }
          vm.currContentStr = fileContent;
          vm.origContentStr = fileContent;
          vm.editor.focus();
        })
        .catch(function (error) {
          vm.currContentStr = null;
          vm.origContentStr = null;
          if (error.status === 404) {
            psNotification.show(GET_FILE_FAILURE_MESSAGE);
          }
        })
        .finally(function () {
          vm.loadingFile = false;
        });
    }

    function saveConfigFile (item, contentStr) {
      if (!item) {
        psNotification.show(SELECT_FILE_MESSAGE);
        return $q.reject();
      }

      return ConfigurationSrv.saveConfigFile(item.name, contentStr)
        .then(function (response) {
          if (response.status === 204) {
            if (/^imat\.json$/.test(item.name)) {
              imatConfig.load(true);
            }

            $mdDialog.show({
              scope: $scope,
              preserveScope: true,
              clickOutsideToClose: true,
              templateUrl: '/ui/admin/configuration/save-config-modal.html',
              controller: 'ConfigDialogController',
              locals: {
                serverResponse: {
                  status: 200,
                  data: item.name + ' was saved. Contact professional services to apply your changes.'
                },
                stores: vm.stores,
                configFiles: vm.listItems,
                reload: vm.reloadList
              }
            })
              .then(function () {
                return loadConfigFile(item);
              });
          }
        })
        .catch(function () {
          psNotification.error(SAVE_FILE_ERROR);
          return $q.reject();
        });
    }

    function reloadList () {
      _getConfigFiles();
      _getStores();
    }

    //= =================
    // Private Interface
    //= =================

    function confirmExit () {
      var confirm = $mdDialog.confirm()
        .title('Unsaved Changes')
        .textContent('You have unsaved changes. Are you sure you want to leave?')
        .ariaLabel('Unsaved Changes')
        .ok('Leave')
        .cancel('Cancel');

      if (!vm.modified) {
        return $q.resolve(true);
      }

      return $mdDialog.show(confirm)
        .then(function () {
          return true;
        })
        .catch(function () {
          return false;
        });
    }

    function _getConfigFiles () {
      vm.loadingFiles = true;
      ConfigurationSrv.getConfigFiles()
        .then(function (response) {
          vm.listItems = response.data;
          vm.catsList = vm.listItems.map(function (item) {
            return item.group;
          });
          vm.cats = filterList(vm.listItems, '');
          vm.numberOfFiles = vm.listItems.reduce(function (count, groups) { return count + groups.files.length; }, 0);
        })
        .finally(function () {
          vm.loadingFiles = false;
        });
    }

    function _getStores () {
      imatStoresSrv.getAll()
        .then(function (stores) {
          vm.stores = stores;
        })
        .catch(function () {
          psNotification.error(GET_STORES_ERROR);
        });
    }

    function _codemirrorLoaded (editor) {
      vm.editor = editor;
      vm.editor.getDoc().on('change', function (doc) {
        if (vm.selectedItem && vm.origContentStr !== null) {
          $scope.$applyAsync(function () {
            vm.modified = vm.origContentStr !== doc.getValue();
          });
        }
      });
    }

    function filterList (list, filter) {
      if (!list) {
        return [];
      }

      const rexFilter = new RegExp(filter.replace(/([^\w-])/g, '\\$1'), 'i');
      const filteredList = list.reduce(function (groups, item) {
        if (rexFilter.test(item.group)) {
          // If the group matches then take the whole group
          const show = item.files.map(function (name) {
            return { group: item.group, name: name };
          });

          groups.push({ group: item.group, show: show });
        } else {
          // Check each file
          const show = item.files.reduce(function (showing, name) {
            if (rexFilter.test(name)) {
              showing.push({ group: item.group, name: name });
            }

            return showing;
          }, []);

          if (show.length > 0) {
            // If any files match then take this group with those files
            groups.push({ group: item.group, show: show });
          }
        }
        return groups;
      }, []);

      return filteredList;
    }
  }
})();
