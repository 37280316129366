(function () {
  'use strict';

  angular.module('imat.services')
    .factory('subscriptionsSrv', subscriptionsSrv);

  subscriptionsSrv.$inject = ['$filter', '$http', '$log', '$q', 'imatConfig', 'profileApiSrv', 'queriesApiSrv'];

  function subscriptionsSrv ($filter, $http, $log, $q, imatConfig, profileApiSrv, queriesApiSrv) {
    var IMAT_CONFIG_LOAD_FAIL = 'Error loading IMAT config.';
    var NOTIFICATION_SETTINGS_CHECK_FAIL = 'Error checking notification settings.';
    var NOTIFICATION_SETTINGS_GROUP_CHECK_FAIL = 'Error checking access groups for subscriptions.';
    var NOTIFICATION_SETTINGS_TEST_FAIL = 'Error sending test notification.';
    var NOTIFICATION_SETTINGS_UPDATE_FAIL = 'Error updating user notification settings.';
    var PATIENT_SUBSCRIPTION_CHECK_FAIL = 'Error checking patient subscriptions.';
    var PATIENT_SUBSCRIPTION_GET_LIST_FAIL = 'Error retrieving patient subscriptions.';
    var PATIENT_SUBSCRIPTION_GET_FMRN_FAIL = "Error retrieving patient FMRN's from %s.";
    var PATIENT_SUBSCRIPTION_SUB_FAIL = 'Error subscribing to patient identified by %s.';
    // var PATIENT_SUBSCRIPTION_UNSUB_FAIL = 'Error unsubscribing from patient identified by %s.';
    var QUERY_NO_EXIST_OR_PRIVILEGE_FAIL = 'Query "%s" does not exist or user has insufficient privileges.';

    var config = null;
    var NOTIFICATIONS_BASE_PATH = '/extensions/notifications';
    var userSettings = null;

    var service = {
      checkPatientSubscriptions: checkPatientSubscriptions,
      checkNotificationSettings: checkNotificationSettings,
      editUserNotificationSettings: editUserNotificationSettings,
      getReports: getReports,
      getUserNotificationSettings: getUserNotificationSettings,
      hasAccess: hasAccess,
      isEnabled: isEnabled,
      isTestable: isTestable,
      loadConfig: loadConfig,
      testNotifications: testNotifications,
      toggleSubscription: toggleSubscription
    };

    return service;

    function checkPatientSubscriptions (mpids) {
      return _getPatientSubscriptions(mpids)
        .then(function (response) {
          var sortedResponse;

          if (response.length) {
            sortedResponse = $filter('orderBy')(response, 'effective_time', true);
            var subscriptionStatus = JSON.parse(sortedResponse[0].active);
            return subscriptionStatus;
          }
          return false;
        })
        .catch(function (error) {
          $log.debug(PATIENT_SUBSCRIPTION_CHECK_FAIL, error);
          return $q.reject(error);
        });
    }

    function checkNotificationSettings () {
      return $http.get(NOTIFICATIONS_BASE_PATH + '/settings')
        .then(function (response) {
          return _getHitList(response).length > 0;
        })
        .catch(function (error) {
          $log.debug(NOTIFICATION_SETTINGS_CHECK_FAIL, error);
          return $q.reject(error);
        });
    }

    function editUserNotificationSettings (settings) {
      if (Object.keys(config.contactMeans).filter(function (key) { return config.contactMeans[key] && !Object.prototype.hasOwnProperty.call(settings, key); }).length) {
        $log.debug(NOTIFICATION_SETTINGS_UPDATE_FAIL, 'Missing required fields');
        return $q.reject('Missing required fields');
      }

      if (!Object.keys(config.contactMeans).filter(function (key) { return config.contactMeans[key] && settings[key] !== ''; }).length) {
        $log.debug(NOTIFICATION_SETTINGS_UPDATE_FAIL, 'At least one means of contact needs to be provided');
        return $q.reject('At least one means of contact needs to be provided');
      }

      if (settings.phone) {
        _formatPhone(settings.phone);
      }
      settings.report_ids = [];
      if (settings.alerts) {
        settings.alerts.map(function (alert) {
          if (alert.subscribed) {
            settings.report_ids.push(alert.id);
          }
        });
      }
      return $http.post(NOTIFICATIONS_BASE_PATH + '/settings', settings)
        .then(function (response) {
          userSettings = settings;
          return response.data;
        })
        .catch(function (error) {
          $log.debug(NOTIFICATION_SETTINGS_UPDATE_FAIL, error);
          return $q.reject(error);
        });
    }

    function getReports () {
      return $q.all([
        _getSubscriptionDetails(),
        _getConfigQueries()
      ])
        .then(function (values) {
          return { alerts: _parseSubscriptions(values[0], values[1]) };
        });
    }

    function getUserNotificationSettings () {
      if (userSettings !== null) {
        return $q.resolve(userSettings);
      }

      return $q.all([
        _getUserSubscriptionInfo(),
        _getSubscriptionDetails(),
        _getConfigQueries()
      ])
        .then(function (values) {
          var activeSubscriptions = values[0];
          var queryObjects = values[1];
          var configQueries = values[2];

          activeSubscriptions.alerts = _parseSubscriptions(queryObjects, configQueries, activeSubscriptions.report_ids);
          activeSubscriptions.phone = _formatPhone(activeSubscriptions.phone);
          userSettings = activeSubscriptions;

          return activeSubscriptions;
        });
    }

    function hasAccess () {
      if (config == null) {
        return;
      }
      return config.hasAccess;
    }

    function isEnabled () {
      if (config == null) {
        return;
      }
      return !!(config.enabled && config.contactMeans && Object.keys(config.contactMeans).filter(function (key) { return config.contactMeans[key]; }).length);
    }

    function isTestable () {
      if (config == null) {
        return;
      }
      return config.testable;
    }

    function loadConfig () {
      if (config !== null) {
        return $q.resolve(config);
      }
      return imatConfig.load()
        .then(function () {
          config = imatConfig.get('subscriptions');
          if (config) {
            return _checkAccessGroups()
              .then(function (access) {
                config.hasAccess = access;
                return config;
              });
          }
          return $q.reject();
        })
        .catch(function () {
          return $q.reject(IMAT_CONFIG_LOAD_FAIL);
        });
    }

    function toggleSubscription (mpid) {
      var request = {
        method: 'POST',
        url: NOTIFICATIONS_BASE_PATH + '/patients/' + mpid,
        data: { active: true } // Assume initial subscription.
      };

      return _getPatientFmrns(mpid)
        .then(function (fmrns) {
          if (fmrns.length) {
            request.data.patient_local_ids = fmrns;
          }
          return _getPatientSubscriptions(mpid);
        })
        .then(function (response) {
          var sortedResponse;

          if (response.length) {
            sortedResponse = $filter('orderBy')(response, 'effective_time', true);
            request.data.active = sortedResponse[0].active !== 'true';
          }
        })
        .then(function () {
          return $http(request)
            .then(function (response) {
              return response.data;
            })
            .catch(function (error) {
              $log.debug(PATIENT_SUBSCRIPTION_SUB_FAIL.replace('%s', mpid), error);
              return $q.reject(error);
            });
        });
    }

    function testNotifications () {
      return $http.post(NOTIFICATIONS_BASE_PATH + '/test')
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          $log.debug(NOTIFICATION_SETTINGS_TEST_FAIL, error);
          return $q.reject(error);
        });
    }

    function _getPatientSubscriptions (mpids) {
      var request = {
        method: 'GET',
        url: NOTIFICATIONS_BASE_PATH + '/patients'
      };
      if (mpids) {
        request.params = { mpids: mpids };
      }
      return $http(request)
        .then(function (response) {
          return _getHitList(response);
        })
        .catch(function (error) {
          $log.debug(PATIENT_SUBSCRIPTION_GET_LIST_FAIL, error);
          return $q.reject(error);
        });
    }

    function _getUserSubscriptionInfo () {
      return $http.get(NOTIFICATIONS_BASE_PATH + '/settings')
        .then(function (response) {
          var res = _getHitList(response)[0];
          res.report_ids = res.report_id.split('|').map(function (value) { return value.trim(); });
          return res;
        });
    }

    function _getPatientFmrns (mpid) {
      var fmrns = [];
      if (!config.includeFmrns) {
        return $q.resolve(fmrns);
      }
      return $http.get(NOTIFICATIONS_BASE_PATH + '/patients/' + mpid + '/fmrns')
        .then(function (response) {
          var hits = _getHitList(response);

          if (hits.length && Object.prototype.hasOwnProperty.call(hits[0], 'patient.fmrn.active') && hits[0]['patient.fmrn.active'].trim() !== '') {
            fmrns = hits[0]['patient.fmrn.active'].split('|').map(function (value) {
              return value.split('/').pop().trim().split(' ').join('~');
            });
          }
          return fmrns;
        })
        .catch(function (error) {
          $log.debug(PATIENT_SUBSCRIPTION_GET_FMRN_FAIL.replace('%s', mpid), error);
          return $q.reject(error);
        });
    }

    function _getSubscriptionDetails () {
      return queriesApiSrv.getList()
        .then(function (res) {
          return res.data;
        });
    }

    function _getHitList (res) {
      if (Object.prototype.hasOwnProperty.call(res, 'data') && Object.prototype.hasOwnProperty.call(res.data, 'response') && Object.prototype.hasOwnProperty.call(res.data.response, 'hits')) {
        if (!angular.isArray(res.data.response.hits.hit)) {
          res.data.response.hits.hit = [res.data.response.hits.hit];
        }
        if (angular.isArray(res.data.response.hits.hit) && res.data.response.hits.hit[0] === undefined) {
          res.data.response.hits.hit = [];
        }
        return res.data.response.hits.hit;
      }
      return [];
    }

    function _parseSubscriptions (reportDetails, configQueries, userSubscriptions) {
      var returnArray = [];
      configQueries = configQueries || [];

      if (userSubscriptions) {
        // Filter out user queries that are not in the imat.config (if any)
        var subscriptions = userSubscriptions.filter(function (query) {
          return configQueries.indexOf(query) > -1;
        });
      }

      // Get detailed query object
      angular.forEach(configQueries, function (query) {
        var detail = reportDetails.filter(function (queryDetails) {
          return queryDetails.id === query;
        })[0];
        // Pass through if query doesn't exist in list of query objects.
        if (!detail) {
          $log.debug(QUERY_NO_EXIST_OR_PRIVILEGE_FAIL.replace('%s', query));
          return;
        }
        if (userSubscriptions) {
          detail.subscribed = subscriptions.indexOf(detail.id) > -1;
        }
        // Get rid of "garbage" reports
        returnArray.push(detail);
      });
      return returnArray;
    }

    function _getConfigQueries () {
      return $q.resolve(imatConfig.get('subscriptions.alertReportIds'));
    }

    function _formatPhone (phone) {
      return phone.replace(/[\-()\s+]/gi, ''); // eslint-disable-line
    }

    function _checkAccessGroups () {
      // Unrestricted access by default.
      if (!angular.isArray(config.groups) || !config.groups.length) {
        return $q.resolve(true);
      }
      // Otherwise the user and feature must have at least one group in common.
      return profileApiSrv.getProfileGroups()
        .then(function (response) {
          if (!response.data || !response.data.groups || !response.data.groups.group) {
            return $q.reject();
          }
          if (!Array.isArray(response.data.groups.group)) {
            response.data.groups.group = [response.data.groups.group];
          }
          return response.data.groups.group.filter(function (group) {
            return config.groups.indexOf(group) >= 0;
          }).length > 0;
        })
        .catch(function (error) {
          $log.debug(NOTIFICATION_SETTINGS_GROUP_CHECK_FAIL, error);
          return $q.reject(error);
        });
    }
  }
})();
