(function () {
  'use strict';

  angular
    .module('vhr.test')
    .factory('vhrUserFix', vhrUserFix);

  vhrUserFix.$inject = ['$q'];

  function vhrUserFix ($q) {
    var service;

    service = {
      getUserGroups: getUserGroups,
      getUserProfile: getUserProfile
    };

    function getUserGroups (promise) {
      var fix = ['searchappliance_vhr_admin'];
      return (promise) ? $q.resolve(fix) : fix;
    }

    function getUserProfile (promise) {
      var fix = {
        groups: ['searchappliance_vhr_admin'],
        name: 'Testy McTester',
        provider: 'foo',
        username: 'searchadmin'
      };
      return (promise) ? $q.resolve(fix) : fix;
    }

    return service;
  }
})();
