(function () {
  'use strict';

  angular
    .module('vhr')
    .controller('CcdaCtrl', CcdaCtrl);

  CcdaCtrl.$inject = [
    'VHR_REPORT',
    '$scope', '$state', '$anchorScroll', '$filter', '$log', '$q', '$timeout', '$window',
    'psNotification', 'psReports', 'vhrApp', 'vhrConfigSrv', 'vhrPatientSrv', 'vhrPrintSrv', 'vhrUserSrv', 'vhrXsl'
  ];

  function CcdaCtrl (
    VHR_REPORT,
    $scope, $state, $anchorScroll, $filter, $log, $q, $timeout, $window,
    psNotification, psReports, vhrApp, vhrConfigSrv, vhrPatientSrv, vhrPrintSrv, vhrUserSrv, vhrXsl
  ) {
    var vm = this;
    var workspace = $state.current.name;
    // If xslt fails, will output this string
    var NO_XML = 'No XML';
    var XML_NOT_AVAILABLE = 'Unable to retrieve the document.';
    var CCDA = 'ccda.xml';

    // Properties
    vm.config = vhrConfigSrv.getPageByState($state.current.name);
    vm.documentData = null;
    vm.documentXsl = null;
    vm.generating = false;
    vm.isCancelable = false;
    vm.loaded = { page: false, report: false, document: false };
    vm.markup = null;
    vm.printSource = vhrConfigSrv.getPrintSource();
    vm.recordHref = '';
    vm.report = {};
    vm.reportParams = {};
    vm.timestamp = null;
    vm.user = vhrUserSrv;
    // Methods
    vm.cancelCcdaReport = cancelCcdaReport;
    vm.generateCcdaReport = generateCcdaReport;
    vm.print = print;
    vm.scrollToLocation = scrollToLocation;

    activate();

    // Implementation

    function activate () {
      vhrApp.enableFilter(false);
      vhrApp.enableModes(false);
      vhrApp.enableRefresh(false);

      loadReport()
        .catch(function () {
          psNotification.error('Failed to prepare the report.');
        })
        .finally(function () {
          if (Object.prototype.hasOwnProperty.call(vm.report.results, workspace)) {
            if (Object.prototype.hasOwnProperty.call(vm.report.results[workspace], 'resultId') && vm.report.results[workspace].status === 1) {
              vm.recordHref = '/search/results/' + vm.report.results[workspace].resultId + '/output/' + CCDA;
              vm.generating = false;
              vm.loaded.report = true;
              loadDisplay();
            }
            if (vm.report.results[workspace].status === 0) {
              vm.generating = true;
              vm.loaded.report = false;
              waitForResults();
            }
          }
        });

      // Capture Ctrl+P and route through vhrPrintSrv.print().
      angular.element($window.document).on('keyup keydown', function (evt) {
        if (evt.ctrlKey && evt.keyCode === 80) {
          evt.preventDefault();
          print();
          return false;
        }
      });
    }

    function generateCcdaReport () {
      vm.generating = true;
      vm.isCancelable = false;
      vm.loaded.report = false;
      vm.loaded.document = false;
      loadReportData(angular.copy(vm.reportParams))
        .catch(function (reason) {
          psNotification.error(reason);
        });
    }

    function cancelCcdaReport () {
      if (vm.isCancelable) {
        vm.generating = false;
        vm.loaded.report = false;
        vm.loaded.document = false;
        vm.recordHref = '';
        vm.report.deleteResult(workspace)
          .then(function () { vm.isCancelable = false; });
      }
    }

    function loadDisplay () {
      vm.loaded.document = false;
      loadXslDocument()
        .then(function (response) {
          vm.documentXsl = response;
          return response;
        })
        .then(loadDocument)
        .then(function (response) {
          vm.documentData = response;
          return response;
        })
        .then(function () {
          var markupString = _fixEntities($filter('xslt')(vm.documentData, vm.documentXsl));
          if (markupString === NO_XML) {
            vm.markup = XML_NOT_AVAILABLE;
            return $q.reject('Document not in expected format.');
          }
          vm.markup = markupString;
          return vm.markup;
        })
        .catch(function (reason) {
          psNotification.error(reason);
        })
        .finally(function () {
          vm.loaded.document = true;
        });
    }

    function loadDocument () {
      return vm.report.getArtifact(workspace, CCDA)
        .then(function (response) {
          return response.original_response;
        })
        .catch(function (reason) {
          $log.debug(reason);
          return $q.reject('Failed to load the record.');
        });
    }

    function loadReport () {
      return psReports.load(vm.config.reportId, VHR_REPORT.CALLER, { initParams: vhrConfigSrv.getReportParameters(vm.config.reportId) })
        .then(function (report) {
          vm.report = report;
          vm.reportParams = report.getParameterDefaults();

          vm.report.parameters.patient_id = { value: '0', type: 'str' };// XXX Temporary
          vm.loaded.page = true;
        });
    }

    function loadReportData (params) {
      params.patient_id = vhrPatientSrv.raw.mpid;

      return vm.report.run(workspace, params, true)
        .then(function () {
          vm.isCancelable = true;
          return waitForResults();
        })
        .catch(function (reason) {
          $log.debug(reason);
          return $q.reject('Failed to load the ' + vm.config.docLabel + ' report.');
        })
        .finally(function () {
          vm.generating = false;
        });
    }

    function loadXslDocument (xslPath) {
      return vhrXsl.getXslFile(xslPath)
        .then(function (response) {
          return response;
        })
        .catch(function (reason) {
          $log.debug(reason);
          return $q.reject();
        });
    }

    function print () {
      vhrPrintSrv.print();
    }

    function scrollToLocation (location) {
      $timeout(function () {
        $anchorScroll(location);
      });
    }

    function waitForResults () {
      var result = vm.report.results[workspace].getPollPromise();

      return result
        .then(function () {
          vm.recordHref = '/search/results/' + vm.report.results[workspace].resultId + '/output/ccda.xml';
          vm.loaded.report = true;
          loadDisplay();
        });
    }

    function _fixEntities (htmlString) {
      var cleanUp = htmlString.replace(/&amp;amp;/g, '&');
      return cleanUp.replace(/&amp;/g, '&');
    }
  }
})();
