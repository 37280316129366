(function () {
  'use strict';

  angular.module('imat.filters')
    .filter('underscoresToSpaces', function () {
      return function (key) {
        return key.replace(/_/g, ' ');
      };
    });
})();
