(function () {
  'use strict';

  angular.module('mpiApp.search', [
    'imat.constants', // STORES
    'imat.services', // dateTimeSrv, psNotification
    'mpiApp.services', // mpiApiSrv, mpiConfigSrv, mpiSearchSrv, simpleQueryBuilder
    'ngMaterial', // $mdDialog, $mdSidenav
    'ui.router'
  ]);
})();
