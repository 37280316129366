(function () {
  'use strict';

  angular.module('imat.services')
    .factory('ProvidersSrv', ProvidersSrv);

  ProvidersSrv.$inject = ['$q', 'providersApiSrv'];

  function ProvidersSrv ($q, providersApiSrv) {
    var PROVIDERS_GET_ERROR = 'Error retrieving providers';
    var providers = [];

    var service = {
      providers: [],
      getProviderList: getProviderList
    };

    return service;

    function getProviderList (force) {
      if (!force && providers.length) {
        return $q.resolve(service.providers);
      }

      return providersApiSrv.getList()
        .then(parseProviderListResponse)
        .then(function (providerList) {
          providers = providerList.filter(function (p) { return !!p.name; });
          service.providers = providers.map(function (p) { return p.name; });
          return service.providers;
        })
        .catch(function () {
          return $q.reject(PROVIDERS_GET_ERROR);
        });
    }

    function parseProviderListResponse (response) {
      var providerList = [];

      if (response.data && response.data.providers && response.data.providers.provider) {
        providerList = angular.isArray(response.data.providers.provider) ? response.data.providers.provider : [response.data.providers.provider];
      }
      return providerList;
    }
  }
})();
