(function () {
  'use strict';

  angular.module('queries')
    .controller('QueriesLayoutCtrl', QueriesLayoutCtrl);

  QueriesLayoutCtrl.$inject = [
    '$log', '$mdDialog', '$mdSidenav', '$scope', '$state',
    'psNotification', 'QueriesSrv', 'ResultDisplaySrv', 'userSession'
  ];

  function QueriesLayoutCtrl (
    $log, $mdDialog, $mdSidenav, $scope, $state,
    psNotification, QueriesSrv, ResultDisplaySrv, userSession
  ) {
    var vm = this;

    var forceUpdate = false;

    // Properties
    vm.active = QueriesSrv.active;
    vm.loading = {
      exports: false,
      savedQueries: false
    };
    vm.nav = {
      savedQueries: QueriesSrv.savedQueries,
      savedQueriesMenuOpen: QueriesSrv.queriesMenu.savedQueries,
      exports: QueriesSrv.exports,
      exportsMenuOpen: QueriesSrv.queriesMenu.exports
    };

    vm.allUsers = QueriesSrv.admin;
    vm.isAdmin = userSession.userHasRole('system');
    vm.state = $state.current.name;
    vm.username = userSession.getUserData().username;
    vm.sidenavOpen = true;

    // Methods
    vm.deleteExport = deleteExport;
    vm.deleteSavedQuery = deleteSavedQuery;
    vm.downloadExport = downloadExport;
    vm.loadExport = loadExport;
    vm.loadSavedQuery = loadSavedQuery;
    vm.runSavedQuery = runSavedQuery;
    vm.setupExports = setupExports;
    vm.setupSavedQueries = setupSavedQueries;
    vm.toggleQueriesMenu = toggleQueriesMenu;
    vm.toggleExportsMenu = toggleExportsMenu;
    vm.toggleSidenav = toggleSidenav;
    vm.updateActiveId = updateActiveId;
    vm.updateAll = updateAll;

    activate();

    // Implementation

    function activate () {
      setupSavedQueries();
      setupExports();

      // update state for menu selection highlight
      $scope.$watch(function () { return $state.current.name; }, function (curr) {
        vm.state = curr;
      });
    }

    function deleteExport (exportObj) {
      var confirm = $mdDialog.confirm()
        .title('Confirm Delete')
        .textContent('Delete export ' + exportObj.name + '?')
        .ariaLabel('Confirm Delete')
        .ok('Delete')
        .cancel('Cancel');

      $mdDialog.show(confirm)
        .then(function () {
          QueriesSrv.deleteExport(exportObj.id)
            .then(function () {
              psNotification.success('Export deleted.');
            }, function (errorResponse) {
              psNotification.error('Unable to delete the export.');
              $log.debug('Deleting the export failed', errorResponse);
            });
        });
    }

    function deleteSavedQuery (query) {
      // TODO: refactor to share delete between different areas? (menu, builder, results)

      var confirm = $mdDialog.confirm()
        .title('Confirm Delete')
        .textContent('Delete query ' + query.name + '?')
        .ariaLabel('Confirm Delete')
        .ok('Delete')
        .cancel('Cancel');

      $mdDialog.show(confirm)
        .then(function () {
          QueriesSrv.deleteQuery(query.id)
            .then(function () {
              psNotification.success('Query deleted.');
            }, function (errorResponse) {
              psNotification.error('Unable to delete the query.');
              $log.debug('Deleting the query failed', errorResponse);
            });
        });
    }

    function downloadExport (exportId) {
      // TODO: error handling for when file doesn't exist ect
      var uri = '/search/results/' + exportId + '?admin=' + vm.isAdmin;
      var iframe = document.createElement('iframe');

      iframe.css = 'visibility:hidden;';
      iframe.src = uri;
      document.body.appendChild(iframe);
      iframe.click();
      psNotification.customShow({ message: 'The query may not be finished processing results. Verify that all data is present before using the exported file.', hideDelay: 12000 });
    }

    function loadSavedQuery (id) {
      updateActiveId(id, 'query');
      QueriesSrv.getSavedQuery(id)
        .then(function (loadedQuery) {
          // Set result fields based on query data or use current result display fields if none exist
          if (loadedQuery.fields && loadedQuery.fields.length > 0) {
            ResultDisplaySrv.setFields(loadedQuery.resultDisplay, loadedQuery.fields);
          }
          QueriesSrv.setQuery(QueriesSrv.getQueryTemplate(loadedQuery));
          if ($state.current.name !== 'app.queries.builder') {
            $state.go('app.queries.builder');
          }
        })
        .catch(function () {
          QueriesSrv.resetQuery();
          psNotification.error('Unable to load the query.');
        });
    }

    function loadExport (id) {
      updateActiveId(id, 'export');
      QueriesSrv.setExport({ id: id, info: [], name: '' });
      $state.go('app.queries.exports', { action: 'loading', id: id, cache: Date.now() });
    }

    function runSavedQuery (id) {
      $state.go('app.queries.results', { action: 'run', id: id, cache: Date.now() });
    }

    function setupSavedQueries () {
      vm.loading.savedQueries = true;
      return QueriesSrv.getSavedQueries(forceUpdate)
        .catch(function () {
          psNotification.error('Unable to load the query list.');
        })
        .finally(function () {
          vm.loading.savedQueries = false;
          forceUpdate = false;
        });
    }

    function setupExports () {
      vm.loading.exports = true;
      return QueriesSrv.getExports(forceUpdate)
        .catch(function () {
          psNotification.error('Unable to load the export list.');
        })
        .finally(function () {
          vm.loading.exports = false;
          forceUpdate = false;
        });
    }

    function toggleSidenav () {
      vm.sidenavOpen = !vm.sidenavOpen;
      $mdSidenav('queries-sidenav').toggle();
    }

    function updateActiveId (id, type) {
      QueriesSrv.setActiveId(type + '_' + id);
    }

    function updateAll () {
      QueriesSrv.setAdmin(vm.allUsers);
      forceUpdate = true;
      setupExports();
      setupSavedQueries();
    }

    function toggleQueriesMenu () {
      vm.nav.savedQueriesMenuOpen = !vm.nav.savedQueriesMenuOpen;
      QueriesSrv.setQueriesMenu(vm.nav.savedQueriesMenuOpen, vm.nav.exportsMenuOpen);
    }

    function toggleExportsMenu () {
      vm.nav.exportsMenuOpen = !vm.nav.exportsMenuOpen;
      QueriesSrv.setQueriesMenu(vm.nav.savedQueriesMenuOpen, vm.nav.exportsMenuOpen);
    }
  }
})();
