(function () {
  'use strict';

  angular.module('imat.services')
    .factory('Permissions', Permissions);

  Permissions.$inject = ['$http', 'GroupsSrv', 'UsersSrv', 'x2js'];

  function Permissions ($http, GroupsSrv, UsersSrv, x2js) {
    var service = {
      getUsers: getUsers,
      getGroups: getGroups,
      getCurrentAccess: getCurrentAccess,
      getPermissions: getPermissions,
      savePermissions: savePermissions
    };

    return service;

    function getUsers (provider, params) {
      return UsersSrv.getList(provider, params)
        .then(function (response) {
          response.map(function (user) {
            return angular.extend(user, { permission: fromUser(user) });
          });
          return response;
        });
    }

    function getGroups () {
      return GroupsSrv.getList()
        .then(function (response) {
          response.map(function (group) {
            return angular.extend(group, { permission: fromGroup(group) });
          });
          return response;
        });
    }

    function getCurrentAccess (reportId) {
      return service.getPermissions(reportId, { admin: true })
        .then(function (response) {
          var permissions = response.data.permissions.permission;
          permissions = angular.isArray(permissions) ? permissions : [permissions];
          return permissions.map(function (permission) {
            return new Permission(
              permission.authentication_type,
              permission.authentication_id,
              parseInt(permission.access_rights, 10) || 0);
          });
        });
    }

    function getPermissions (reportId, params) {
      return $http({
        method: 'GET',
        url: '/search/queries/' + reportId + '/permissions',
        params: params
      });
    }

    function savePermissions (reportId, permissions, params) {
      var preparedPermissions = permissions.map(function (permission) {
        return permission.prepare();
      });
      preparedPermissions = x2js.json2xml_str({ permissions: { permission: preparedPermissions } });
      return $http({
        method: 'POST',
        url: '/search/queries/' + reportId + '/permissions',
        params: params,
        headers: { 'Content-Type': 'xml/text' },
        data: preparedPermissions
      })
        .then(function () {
          permissions.map(function (permission) {
            permission.syncAccessRights();
          });
        });
    }

    //= ==================================
    // Private interface
    //= ==================================

    function fromGroup (group) {
      /*
       * Creates a Permission object from the group and returns it
       *
       * Params:
       *  group (Object): group object (name, description, modifiable, removable, membership_modifiable, profider)
       *
       * Returns:
       *  Permission object
       */

      if (!(group && Object.prototype.hasOwnProperty.call(group, 'name') && Object.prototype.hasOwnProperty.call(group, 'provider'))) {
        return null;
      }
      return new Permission('groupname', group.name + '@' + group.provider, Permission.DEFAULT_ACCESS);
    }

    function fromUser (user) {
      /*
       * Creates a Permission object from the user and returns it
       *
       * Params:
       *  user (String): xml user
       *    (username, name, removable, modifiable, membership_modifiable, locked, disabled, password_expires, password_warned, provider)
       *
       * Returns:
       *  Permission object
       */
      if (!(user && Object.prototype.hasOwnProperty.call(user, 'username') && Object.prototype.hasOwnProperty.call(user, 'provider'))) {
        return null;
      }
      return new Permission('username', user.username + '@' + user.provider, Permission.DEFAULT_ACCESS);
    }
  }

  function Permission (authentication_type, authentication_id, access_rights) {   // eslint-disable-line
    /*
     * Permission class constructor
     * Params:
     *  authentication_type (string): groupname or username
     *  authentication_id (string): the group/user name @ default
     *  access_rights (string): an int representing a 3 bit permission for R W X
     */
    this.ACCESS_READ = 4;
    this.ACCESS_WRITE = 2;
    this.ACCESS_EXECUTE = 1;
    this.DEFAULT_ACCESS = this.ACCESS_EXECUTE;

    this.authentication_type = authentication_type;  // eslint-disable-line
    this.authentication_id = authentication_id;  // eslint-disable-line
    this.access_rights = access_rights;  // eslint-disable-line
    /* jshint bitwise: false */
    this.read = !!(access_rights & this.ACCESS_READ);  // eslint-disable-line
    this.write = !!(access_rights & this.ACCESS_WRITE);  // eslint-disable-line
    this.execute = !!(access_rights & this.ACCESS_EXECUTE);  // eslint-disable-line
    /* jshint bitwise: true */

    // properties used for holding temporary update information
    this.updatedRead = this.read;
    this.updatedWrite = this.write;
    this.updatedExecute = this.execute;

    // property used for disabling a row while sending a request
    this.updating = false;
  }

  Permission.prototype.calculateAccessRights = function (read, write, execute) {
    var r = (+!!read) * this.ACCESS_READ;
    var w = (+!!write) * this.ACCESS_WRITE;
    var x = (+!!execute) * this.ACCESS_EXECUTE;

    return r + w + x;
  };

  Permission.prototype.prepare = function () {
    /*
     * Prepare the object for post to MW
     * returns: object in the format the MW needs
     */
    return {
      authentication_type: this.authentication_type,
      authentication_id: this.authentication_id,
      access_rights: this.calculateAccessRights(this.updatedRead, this.updatedWrite, this.updatedExecute)
    };
  };

  Permission.prototype.syncAccessRights = function () {
    /*
     * sync the temporary updated permissions to active permissions
     */
    this.read = this.updatedRead;
    this.write = this.updatedWrite;
    this.execute = this.updatedExecute;
    this.access_rights = this.calculateAccessRights(this.read, this.write, this.execute);
  };
})();
