(function () {
  'use strict';

  angular
    .module('vhr')
    .config(config);

  config.$inject = ['$stateRegistryProvider'];

  function config ($stateRegistryProvider) {
    $stateRegistryProvider.register({
      name: 'app.vhr',
      abstract: true,
      data: {
        appKey: 'vhr',
        roles: ['search_by_id']
      },
      resolve: [{
        token: 'vhrBootstrap',
        policy: { when: 'EAGER' },
        deps: ['vhrApp'],
        resolveFn: function (vhrApp) {
          return vhrApp.isLoaded();
        }
      }],
      views: {
        'content@app': {
          templateUrl: '/ui/vhr/layout/layout.html',
          controller: 'LayoutCtrl',
          controllerAs: 'ctrl'
        }
      },
      url: '/vhr'
    });
    $stateRegistryProvider.register({
      name: 'app.vhr.lookup',
      url: '/',
      views: {
        'vhr-content@app.vhr': {
          templateUrl: '/ui/vhr/lookup/lookup.html',
          controller: 'PatientLookupCtrl',
          controllerAs: 'ctrl'
        }
      }
    });
    $stateRegistryProvider.register({
      name: 'app.vhr.links',
      url: '/links/',
      views: {
        'vhr-content@app.vhr': {
          templateUrl: '/ui/vhr/templates/generic/generic-links.html',
          controller: 'GenericLinksCtrl',
          controllerAs: 'ctrl'
        }
      }
    });
    $stateRegistryProvider.register({
      name: 'app.vhr.patient',
      abstract: true,
      url: '/patient/',
      views: {
        'vhr-content@app.vhr': {
          templateUrl: '/ui/vhr/patient/patient.html',
          controller: 'PatientCtrl',
          controllerAs: 'ctrl'
        }
      },
      resolve: [{
        token: 'phiAccess',
        policy: { when: 'EAGER' },
        deps: ['$q', 'psPhiAccess', 'vhrPatientSrv', 'vhrConfigSrv', 'vhrHieApi', 'vhrPersistence'],
        resolveFn: function ($q, psPhiAccess, vhrPatientSrv, vhrConfigSrv, vhrHieApi, vhrPersistence) {
          return psPhiAccess.verify(vhrPatientSrv.raw.mpid)
            .then(function (res) {
              if (vhrConfigSrv.getHieAutoLookup() && angular.isDefined(vhrConfigSrv.getRouteByPage('hie'))) {
                if (!res) {
                  vhrPersistence.reset();
                }
                vhrHieApi.lookup(vhrPatientSrv.raw.mpid);
              }
              return res;
            }, function (err) {
              vhrPatientSrv.reset();
              return $q.reject(err);
            });
        }
      }]
    });
    $stateRegistryProvider.register({
      name: 'app.vhr.patient.abstract-summary',
      abstract: true,
      views: {
        'vhr-patient-content@app.vhr.patient': {
          templateUrl: '/ui/vhr/templates/generic/generic-summary.html',
          controller: 'GenericSummaryCtrl',
          controllerAs: 'ctrl'
        }
      }
    });
    $stateRegistryProvider.register({
      name: 'app.vhr.patient.patient-details',
      url: 'details/',
      views: {
        'vhr-patient-content@app.vhr.patient': {
          templateUrl: '/ui/vhr/templates/generic/generic-details.html',
          controller: 'GenericDetailsCtrl',
          controllerAs: 'ctrl'
        }
      }
    });
    $stateRegistryProvider.register({
      name: 'app.vhr.patient.abstract-grid',
      abstract: true,
      views: {
        'vhr-patient-content@app.vhr.patient': {
          templateUrl: '/ui/vhr/templates/generic/generic-grid.html',
          controller: 'GenericGridCtrl',
          controllerAs: 'ctrl'
        }
      }
    });
    $stateRegistryProvider.register({
      name: 'app.vhr.patient.patient-report',
      url: 'report/',
      views: {
        'vhr-patient-content@app.vhr.patient': {
          templateUrl: '/ui/vhr/templates/generic/generic-record.html',
          controller: 'GenericRecordCtrl',
          controllerAs: 'ctrl'
        }
      }
    });
    $stateRegistryProvider.register({
      name: 'app.vhr.patient.ccda',
      url: 'ccda/',
      views: {
        'vhr-patient-content@app.vhr.patient': {
          templateUrl: '/ui/vhr/patient/ccda/ccda.html',
          controller: 'CcdaCtrl',
          controllerAs: 'ctrl'
        }
      }
    });
    $stateRegistryProvider.register({
      name: 'app.vhr.patient.print',
      url: 'print/',
      views: {
        'vhr-patient-content@app.vhr.patient': {
          templateUrl: '/ui/vhr/patient/print/record-collection.html',
          controller: 'RecordCollectionCtrl',
          controllerAs: 'ctrl'
        }
      }
    });
    $stateRegistryProvider.register({
      name: 'app.vhr.patient.hie',
      url: 'hie/',
      views: {
        'vhr-patient-content@app.vhr.patient': {
          template: '<div ui-view></div>',
          controller: 'HieCtrl',
          controllerAs: 'ctrl'
        }
      }
    });
    $stateRegistryProvider.register({
      name: 'app.vhr.patient.hie.hie-search',
      url: 'search/',
      views: {
        '@app.vhr.patient.hie': {
          templateUrl: '/ui/vhr/hie/hie-search.html',
          controller: 'HieSearchCtrl',
          controllerAs: 'ctrl'
        }
      }
    });
    $stateRegistryProvider.register({
      name: 'app.vhr.patient.hie.hie-communities',
      url: 'communities/',
      params: {
        targetCommunities: null
      },
      views: {
        '@app.vhr.patient.hie': {
          templateUrl: '/ui/vhr/hie/hie-communities.html',
          controller: 'HieCommunitiesCtrl',
          controllerAs: 'ctrl'
        }
      }
    });
    $stateRegistryProvider.register({
      name: 'app.vhr.patient.hie.hie-documents',
      url: 'documents/:patient/',
      params: {
        community: { value: null },
        patient: { value: null },
        targetOid: { value: null }
      },
      views: {
        '@app.vhr.patient.hie': {
          templateUrl: '/ui/vhr/hie/hie-documents.html',
          controller: 'HieDocumentsCtrl',
          controllerAs: 'ctrl'
        }
      }
    });
    $stateRegistryProvider.register({
      name: 'app.vhr.patient.hie.hie-document',
      url: 'document/:document/',
      params: {
        community: { value: null },
        document: { value: null },
        repositoryId: { value: null },
        targetOid: { value: null }
      },
      views: {
        '@app.vhr.patient.hie': {
          templateUrl: '/ui/vhr/hie/hie-document.html',
          controller: 'HieDocumentCtrl',
          controllerAs: 'ctrl'
        }
      }
    });
    $stateRegistryProvider.register({
      name:'app.vhr.study',
      url: '/dicom/:mpid/:store/:uri',
      views: {
        '@': {
          templateUrl: '/ui/vhr/dicom/dicom.html',
          controller: 'DicomCtrl',
          controllerAs: 'ctrl'
        }
      }
    });
    $stateRegistryProvider.register({
      name:'app.vhr.worklist',
      url: '/dicom/:mpid',
      views: {
        '@': {
          templateUrl: '/ui/vhr/dicom/dicom.html',
          controller: 'DicomCtrl',
          controllerAs: 'ctrl'
        }
      }
    });

  }
})();
