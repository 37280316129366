(function () {
  'use strict';

  angular
    .module('vhr')
    .controller('UnprintedRecordModalCtrl', unprintedRecordModalCtrl);

  unprintedRecordModalCtrl.$inject = [
    '$scope', '$mdDialog',
    'vhrRecordCollectionSrv'
  ];

  function unprintedRecordModalCtrl (
    $scope, $mdDialog,
    vhrRecordCollectionSrv
  ) {
    var vm = this; // eslint-disable-line no-invalid-this
    var pqColl;

    // Properties
    vm.queueSize = null;

    // Methods
    vm.cancel = cancel;
    vm.proceed = proceed;

    activate();

    // Implementation

    function activate () {
      pqColl = vhrRecordCollectionSrv.construct('printQueue');
      vm.queueSize = pqColl.findAll().length;
    }

    function cancel () {
      $mdDialog.cancel();
    }

    function proceed () {
      $mdDialog.hide();
    }
  }
})();
