(function () {
  'use strict';

  angular
    .module('vhr')
    .controller('GenericRecordCtrl', GenericRecordCtrl);

  GenericRecordCtrl.$inject = [
    '$scope', '$state',
    'vhrRecordCollectionSrv'
  ];

  function GenericRecordCtrl (
    $scope, $state,
    vhrRecordCollectionSrv
  ) {
    var vm = this;

    // Properties
    vm.record = vhrRecordCollectionSrv.stashed();
    // Methods
    vm.queue = queue;

    activate();

    // Implementation

    function activate () {
      // ...
    }

    function queue () {
      vhrRecordCollectionSrv.construct('printQueue').insert(vm.record);
    }
  }
})();
