(function () {
  'use strict';

  angular.module('imat.services')
    .factory('dashboardsApiSrv', dashboardsApiSrv);

  dashboardsApiSrv.$inject = ['$http'];

  function dashboardsApiSrv ($http) {
    var baseUrl = '/extensions/dashboard/';
    var service = {
      deleteDashboard: deleteDashboard,
      getDashboards: getDashboards,
      getDashboardSettings: getDashboardSettings,
      getGroupDashboardPriorities: getGroupDashboardPriorities,
      loadDashboard: loadDashboard,
      saveDashboard: saveDashboard,
      savePermissions: savePermissions,
      setGroupDashboardPriorities: setGroupDashboardPriorities
    };

    return service;

    function deleteDashboard (params) {
      return $http({
        method: 'DELETE',
        url: params.url,
        params: params.params
      });
    }

    function getDashboards (config, data) {
      return $http({
        method: data ? 'POST' : 'GET',
        url: config.url,
        data: data || null,
        params: config.params
      });
    }

    function getDashboardSettings () {
      return $http({
        method: 'GET',
        url: baseUrl + 'settings',
        cache: true
      });
    }

    function getGroupDashboardPriorities () {
      return $http({
        method: 'GET',
        url: baseUrl + 'priority/groups',
        repsonseType: 'json'
      });
    }

    function loadDashboard (params) {
      return $http({
        method: 'GET',
        url: params.url,
        params: params.params,
        headers: { 'Content-Type': 'application/json' }
      });
    }

    function saveDashboard (params, data) {
      return $http({
        method: 'PUT',
        url: params.url,
        params: params.params,
        data: data,
        headers: { 'Content-Type': 'application/json' }
      });
    }

    function savePermissions (params, permissionXml) {
      return $http({
        method: 'POST',
        url: params.url,
        params: params.params,
        headers: { 'Content-Type': 'application/xml' },
        data: '<?xml version="1.0" encoding="utf-8"?>' + permissionXml
      });
    }

    function setGroupDashboardPriorities (priorities) {
      return $http({
        method: 'PUT',
        url: baseUrl + 'priority/groups',
        data: priorities
      });
    }
  }
})();
