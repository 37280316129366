(function () {
  'use strict';
  /*

  Element
  |
  --------------------------------------------------------------------
   |            |                  |                   |             |
  <Primitive>  <Generic Complex>  <Metadata Complex>  <Special Use>  BackboneElement

*/
  angular.module('imat.fhir.constants')
    .constant('FHIR_DATATYPES', {
    // -------------------------------
    // Primitive
    // -------------------------------
      base64Binary: 'base64Binary',
      Base64Binary: 'base64Binary',
      boolean: 'boolean',
      Boolean: 'boolean',
      canonical: 'canonical', // uri
      Canonical: 'canonical',
      code: 'code', // string
      Code: 'code',
      date: 'date',
      Date: 'date',
      dateTime: 'dateTime',
      DateTime: 'dateTime',
      decimal: 'decimal',
      Decimal: 'decimal',
      id: 'id', // string
      Id: 'id',
      instant: 'instant',
      Instant: 'instant',
      integer: 'integer',
      Integer: 'integer',
      markdown: 'markdown', // string
      Markdown: 'markdown',
      oid: 'oid', // uri
      Oid: 'oid',
      positiveInt: 'positiveInt', // integer
      PositiveInt: 'positiveInt',
      string: 'string',
      String: 'string',
      time: 'time',
      Time: 'time',
      unsignedInt: 'unsignedInt', // integer
      UnsignedInt: 'unsignedInt',
      uri: 'uri',
      Uri: 'uri',
      url: 'url', // uri
      Url: 'url',
      uuid: 'uuid',
      Uuid: 'uuid',
      // -------------------------------
      // Generic Complex
      // -------------------------------
      Address: 'Address',
      Age: 'Age', // Quantity
      Annotation: 'Annotation',
      Attachment: 'Attachment',
      CodeableConcept: 'CodeableConcept',
      Coding: 'Coding',
      ContactPoint: 'ContactPoint',
      Count: 'Count', // Quantity
      Distance: 'Distance', // Quantity
      Duration: 'Duration', // Quantity
      HumanName: 'HumanName',
      Identifier: 'Identifier',
      Money: 'Money', // Quantity
      Period: 'Period',
      Quantity: 'Quantity',
      Range: 'Range',
      Ratio: 'Ratio',
      SampledData: 'SampledData',
      Signature: 'Signature',
      SimpleQuantity: 'SimpleQuantity', // Quantity
      Timing: 'Timing',
      // -------------------------------
      // Metadata Complex // http://hl7.org/fhir/metadatatypes.html
      // -------------------------------
      ContactDetail: 'ContactDetail',
      Contributor: 'Contributor',
      DataRequirement: 'DataRequirement',
      ParameterDefinition: 'ParameterDefinition',
      RelatedArtifact: 'RelatedArtifact',
      TriggerDefinition: 'TriggerDefinition',
      UsageContext: 'UsageContext',
      // -------------------------------
      // Special Use
      // -------------------------------
      Dosage: 'Dosage', // http://hl7.org/fhir/dosage.html#definition
      // ElementDefinition: 'ElementDefinition',// FHIRv4
      Extension: 'Extension', // http://hl7.org/fhir/extensibility.html#extension
      Meta: 'Meta', // http://hl7.org/fhir/resource.html#Meta
      Narrative: 'Narrative', // http://hl7.org/fhir/narrative.html
      Reference: 'Reference'// http://hl7.org/fhir/references.html
    })

  /*

  Resource
  |
  -------------------------------------
  |               |       |           |
  DomainResource  Bundle  Parameters  Binary
  |
  Patient

*/
    .constant('FHIR_RESOURCES', {
      Patient: 'Patient'
    });
})();
