(function () {
  'use strict';

  angular.module('reports')
    .controller('ReportsListCtrl', ReportsListCtrl);

  ReportsListCtrl.$inject = [
    '$filter', '$mdDialog', '$mdSidenav', '$scope',
    'psNotification', 'Permissions', 'ReportsSrv', 'userSession'
  ];

  function ReportsListCtrl (
    $filter, $mdDialog, $mdSidenav, $scope,
    psNotification, Permissions, ReportsSrv, userSession
  ) {
    var vm = this;
    var ctx = 'app.reports';// this.constructor.name;

    var GET_CURRENT_ACCESS_LIST_ERROR = 'Error retrieving current access list.';
    var ERROR_RUNNING_REPORT = 'There was an error running the report.';

    vm.canAdd = ['search', 'system'];
    vm.canDelete = ['system', 'owner'];
    vm.canEdit = ['search', 'system'];
    vm.canEditSharing = ['system'];
    vm.canRun = ['search_by_id', 'search', 'system'];
    vm.filterText = '';
    vm.hasAdminRole = false;
    vm.loaded = { list: true, detail: true };
    vm.reportParams = {};
    vm.reports = [];
    vm.selected = {};
    vm.selectedDetail = {};
    vm.shared = true;
    vm.userData = userSession.getUserData();
    vm.viewAllReports = false;

    // Methods
    vm.clearSelection = clearSelection;
    vm.deleteReport = deleteReport;
    vm.filterReports = filterReports;
    vm.getReports = getReports;
    vm.hasPermission = hasPermission;
    vm.isOwner = isOwner;
    vm.resetFilters = resetFilters;
    vm.selectReport = selectReport;

    vm.access = {
      none: 0,
      read: 4,
      write: 2,
      execute: 1,
      full: 7
    };

    // Implementation
    activate();

    function activate () {
      vm.hasAdminRole = userSession.userHasRole('system');

      if (ReportsSrv.refreshing) {
        vm.loaded.list = false;
        _pollQueriesList();
      } else {
        getReports();
      }
    }

    function clearSelection () {
      ReportsSrv.clearSelected(ctx);
      vm.selected = {};
      clearSelectionDetail();
    }

    function clearSelectionDetail () {
      vm.selectedDetail = angular.copy(vm.selected) || {};
      vm.selectedDetail.empty = true;
      $mdSidenav('reportDetails').close();
    }

    function deleteReport (reportObj) {
      var confirm = $mdDialog.confirm()
        .title('Confirm Delete')
        .ariaLabel('Confirm Delete')
        .ok('Delete')
        .cancel('Cancel');

      if (vm.hasAdminRole && !isOwner(vm.selected.owner)) {
        confirm.htmlContent('Delete report ' + reportObj.name + '? <br><strong class="text-warn">NOTE: YOU ARE USING ELEVATED PERMISSIONS.</strong>');
      } else {
        confirm.textContent('Delete report ' + reportObj.name + '?');
      }

      $mdDialog.show(confirm)
        .then(function () {
          ReportsSrv.deleteReport(reportObj.id)
            .then(function () {
              psNotification.success(['Report', reportObj.name, 'deleted.'].join(' '));
              clearSelection();
              getReports();
            })
            .catch(function () {
              psNotification.error(['Error deleting', reportObj.name + '.'].join(' '));
            });
        });
    }

    function filterReports (report) {
      var text;
      if (!vm.filterText) {
        return true;
      }
      // Check if entered input found in name, id or description of report (case insensitive)
      text = vm.filterText.toLowerCase();
      return (report.name.toLowerCase().indexOf(text) !== -1) ? true
        : (report.id.toLowerCase().indexOf(text) !== -1) ? true
          : (report.description.toLowerCase().indexOf(text) !== -1);
    }

    function getReports (force) {
      vm.loaded.list = false;
      force = force || false;

      return ReportsSrv.getList({ admin: vm.viewAllReports }, force)
        .then(function (res) {
          if (res && res.length) {
            // TODO: Remove .map function below when clinical-reports is phased out.
            res = res.map(function (report) {
              report.name = report.name.trim();
              return report;
            });
            vm.reports = $filter('filter')(res, function (value) {
              return value.type === 'python';
            });
          }
        })
        .finally(function () {
          vm.loaded.list = true;
          if (ReportsSrv.getSelectedId(ctx)) {
            selectReport(ReportsSrv.getSelected(ctx));
          }
        });
    }

    function resetFilters () {
      vm.filterText = '';
    }

    function runReportAsAdmin (report) {
      /* jslint bitwise: true */
      return vm.hasAdminRole && ((report.permission & vm.access.execute) === vm.access.execute);
      /* jslint bitwise: false */
    }

    function hasPermission (roles) {
      return roles.some(function (role) {
        return userSession.userHasRole(role);
      });
    }

    function isOwner (owner) {
      return owner === (vm.userData.username + '@' + vm.userData.provider);
    }

    function selectReport (report, admin) {
      vm.loaded.detail = ReportsSrv.isCachedItem(report.id);
      vm.shared = true;

      vm.selected = report;
      ReportsSrv.setSelected(ctx, report.id);
      ReportsSrv.getSelectedItem(ctx, { admin: admin })
        .then(function (res) {
          vm.reportParams = {
            report_id: res.id,
            report_name: res.name,
            admin: runReportAsAdmin(res)
          };

          vm.selectedDetail = res;
          // XXX This modification corrupts the cache, although it may not
          // matter if nothing else uses ReportsSrv?
          vm.selectedDetail.created_on = new Date(res.created_on);
          vm.selectedDetail.modified_on = new Date(res.modified_on);

          if (!vm.selectedDetail.permissions) {
            vm.loaded.detail = false;
            return Permissions.getCurrentAccess(vm.selectedDetail.id)
              .then(function (permissions) {
                vm.selectedDetail.permissions = $filter('orderBy')(permissions, 'authentication_id');
                ReportsSrv.cacheItem({ query: vm.selectedDetail });
              })
              .catch(function () {
              // XXX Should't we force perms to read-only or none?
                return psNotification.error(GET_CURRENT_ACCESS_LIST_ERROR);
              });
          }
        })
        .catch(function (res) {
          clearSelectionDetail();
          if (res.status === 404) {
            vm.shared = false;
          } else {
            psNotification.error(ERROR_RUNNING_REPORT);
          }
        })
        .finally(function () {
          vm.loaded.detail = true;
        });

      $mdSidenav('reportDetails').open();
    }

    function _pollQueriesList () {
      var unwatch = $scope.$watch(function () { return ReportsSrv.refreshing; }, function (curr) {
        if (curr === false) {
          unwatch();
          getReports();
        }
      });
    }
  }
})();
