(function () {
  'use strict';

  angular.module('mpiApp.personas')
    .controller('PiEntryBehaviorModalCtrl', PiEntryBehaviorModalCtrl);

  PiEntryBehaviorModalCtrl.$inject = ['$mdDialog', 'selected'];

  function PiEntryBehaviorModalCtrl ($mdDialog, selected) {
    var vm = this;

    vm.behaviors = {
      0: { display: 'Normal' },
      1: { display: 'Ambiguous' },
      2: { display: 'FMRN only' },
      3: { display: 'Blocked' }
    };
    // vm.selected = selected;// bindToController=true
    vm.selectedBehavior = null;
    vm.selectedBehaviorComments = null;

    vm.cancelDialog = cancelDialog;
    vm.submitBehavior = submitBehavior;

    // If one record selected, have behavior value set to current one
    if (selected.length === 1) {
      vm.selectedBehavior = selected[0].behavior;
    }

    function cancelDialog () {
      $mdDialog.cancel();
    }

    function submitBehavior () {
      $mdDialog.hide({ behavior: vm.selectedBehavior, comment: vm.selectedBehaviorComments });
    }
  }
})();
