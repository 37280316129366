(function () {
  'use strict';

  angular
    .module('vhr.services')
    .factory('vhrPersistence', vhrPersistence);

  vhrPersistence.$inject = [];

  function vhrPersistence () {
    var data = {};
    var service = {
      setData: setData,
      getData: getData,
      reset: reset
    };
    return service;

    // Interface
    function setData (name, value) {
      data[name] = value;
    }

    function getData (name) {
      return data[name];
    }

    function reset (name) {
      if (name) {
        delete data[name];
        return;
      }
      Object.keys(data).forEach(function (property) {
        delete data[property];
      });
    }
  }
})();
