(function () {
  'use strict';

  angular.module('account')
    .controller('AccountQuestionsCtrl', AccountQuestionsCtrl);

  AccountQuestionsCtrl.$inject = ['$scope', 'psNotification', 'userSession'];

  function AccountQuestionsCtrl ($scope, psNotification, userSession) {
    var vm = this;

    vm.changed = false;
    vm.copy = [];
    vm.loaded = false;
    vm.questionData = [];
    vm.questionPool = true;
    vm.responseQuota = 0;
    vm.responses = [];

    vm.isQuestionDisabled = isQuestionDisabled;
    vm.reset = reset;
    vm.save = save;

    activate();

    function activate () {
      getSecurityQuestions()
        .then(function () {
          $scope.$watch(function () { return vm.copy; }, function (curr) {
            vm.changed = !angular.equals(curr, vm.responses);
          }, true);
        });
    }

    function getIndexOfQuestion (questions, q) {
      for (var i = questions.length - 1; i >= 0; --i) {
        if (questions[i].question === q) { return i; }
      }
      return -1;
    }

    function getSecurityQuestions () {
      return userSession.getSecurityQuestions()
        .then(function (data) {
          vm.questionData = data.questions;
          vm.responseQuota = Math.max((data.question_limit || 0), 0);
        })
        .then(reset)
        .finally(function () {
          vm.loaded = true;
        });
    }

    function isQuestionDisabled (responseIndex, questionDataIndex) {
      var q = vm.questionData[questionDataIndex];
      var i = getIndexOfQuestion(vm.copy, q.question);

      return (i >= 0 && i !== responseIndex);
    }

    function reset () {
      if (vm.questionData.length <= vm.responseQuota || vm.responseQuota === 0) {
        vm.questionPool = false;
        vm.responseQuota = vm.questionData.length || 1;
      }

      if (vm.questionPool) {
        vm.responses = vm.questionData.filter(function (d) { return !!d.answer; }).slice(0, vm.responseQuota);
      } else {
        vm.responses = vm.questionData;
      }

      vm.copy = angular.copy(vm.responses);
      vm.changed = false;
    }

    function save () {
      return userSession.postSecurityQuestions(vm.copy)
        .then(function () {
          psNotification.success('Security questions updated.');
        })
        .catch(function () {
          psNotification.error('There was an error updating security questions.');
        })
        .finally(function () {
          getSecurityQuestions();
        });
    }
  }
})();
