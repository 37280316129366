(function () {
  'use strict';

  angular.module('imat.services')
    .factory('HashSrv', HashSrv);

  function HashSrv () {
    return {
      hash: function (str) {
        var chr;
        var hash = 0;
        var i;

        if (str.length === 0) {
          return hash;
        }
        for (i = 0; i < str.length; ++i) {
          chr = str.charCodeAt(i);
          hash = ((hash << 5) - hash) + chr;
          hash |= 0; // Convert to 32bit integer
        }
        return hash;
      }
    };
  }
})();
