(function () {
  'use strict';

  angular.module('reports')
    .config(config);

  config.$inject = ['$mdDateLocaleProvider'];

  function config ($mdDateLocaleProvider) {
    $mdDateLocaleProvider.parseDate = function (dateString) {
      // replace -, ., or space with / and convert to date
      var m = moment(dateString.replace(/[-\.\s]/g, '/'), ['M/D/YYYY', 'MM/DD/YYYY'], true); // eslint-disable-line no-useless-escape
      return m.isValid() ? m.toDate() : new Date(NaN);
    };
  }
})();
