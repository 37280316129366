(function () {
  'use strict';

  angular.module('vhr.services')
    .service('vhrCxmlTranscriptionRecordClass', vhrCxmlTranscriptionRecordClass);

  vhrCxmlTranscriptionRecordClass.$inject = [
    'VhrCxmlRecordClass'
  ];

  function vhrCxmlTranscriptionRecordClass (
    VhrCxmlRecordClass
  ) {
    function CxmlTranscriptionRecord (cfg) {
      VhrCxmlRecordClass.call(this, cfg);
      this.printTemplateUrl = '/ui/vhr/templates/cxml/print/transcription.html';
      this.templateUrl = '/ui/vhr/templates/cxml/cxml-transcription-modal.html';
    }

    CxmlTranscriptionRecord.prototype = Object.create(VhrCxmlRecordClass.prototype);
    CxmlTranscriptionRecord.prototype.constructor = CxmlTranscriptionRecord;

    CxmlTranscriptionRecord.prototype.set = function (raw, setFields) {
      if (setFields === true) {
        setFields = ['result', 'ordered_by', 'clinical_date', 'attending_physician'];
      }
      VhrCxmlRecordClass.prototype.set.call(this, raw, setFields);
    };

    return CxmlTranscriptionRecord;
  }
})();
